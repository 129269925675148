import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { logout } from '../../Store/userSlice';
import useBrowserQuery from '../../Hooks/useBrowserQuery';

const Logout = () => {
  const destination = useBrowserQuery().get('destination') || '/app';

  const dispatch = useDispatch();
  dispatch(logout());

  useEffect(() => {
    window.location.href = destination ? `/app/login?destination=${destination}` : '/app';
  }, []);

  return (
    <Skeleton loading />
  );
};

export default Logout;
