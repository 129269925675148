import React from 'react';
import { Link } from 'react-router-dom';
import HeaderTitle from './HeaderTitle';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';

const BrokerApplicationsHeader = () => (
  <HeaderTitle
    title="Applications"
    comment={(
      <Paragraph>
        Below is a list of loan applications currently in progress.
        <br />
        To submit a new application, please go to
        {' '}
        <Link
          to="/app/broker/clients"
        >
          Clients
        </Link>
        {' '}
        and select a borrower from your list of clients.
      </Paragraph>
    )}
  />

);

export default BrokerApplicationsHeader;
