import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Row, Col } from 'antd';
import { dealer } from '../../Hooks/useLocalStorage';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import StaticDocumentSimpleLink from '../../Atoms/Navigation/Links/StaticDocumentSimpleLink';
import './InvestmentTerms.less';

// @todo: Refactor. Move checkbox content into resources / brand config.
const InvestmentTerms = ({ allCheckedCallback, loanState }) => {
  const checkboxesAu = [
    {
      key: 'confirm_funds',
      title: (
        <>
          the investment described in this Part C of the Investor Agreement (Investor Loan Terms Sheet) is made in terms of:
          {' '}
          (1) the Investor Agreement, which will apply to this and each other investment I/we have made with Zagga Investments;
          {' '}
          (2) the Trust Deed; and (3) any other conditions or requirements specified in this Investor Loan Terms Sheet
          {' '}
          or otherwise notified to me/us in writing by Zagga Investments
        </>
      ),
      errorMessage: 'Investment confirmation is not checked',
      checked: false,
    },
    {
      key: 'agree_to_pay',
      title: (
        <>
          sufficient funds are available to meet my/our funding commitment
          {' '}
          and I/we understand that proof of funds may be required at any time
        </>
      ),
      checked: false,
    },
    {
      key: 'agree_to_fee',
      title: 'I/We shall pay a Loan Management Fee in relation to the Loan, and',
      checked: false,
    },
    {
      key: 'agree_to_appoint',
      title: 'Zagga Investments Pty Limited be appointed as my/our trustee in respect of the Loan and the associated securities.',
      checked: false,
    },
  ];

  const checkboxesNz = [
    {
      key: 'confirm_funds',
      title: (
        <>
          I confirm that sufficient funds are available to meet my investment commitment
          {' '}
          and I understand that proof of funds may be required at anytime.
        </>
      ),
      errorMessage: 'Investment confirmation is not checked',
      checked: false,
    },
    {
      key: 'agree_to_pay',
      title: (
        <>
          I agree to pay Magna Trust Company Limited a loan administration fee in accordance with the
          {' '}
          <StaticDocumentSimpleLink docKey="investor-agreement">
            Investor Agreement
          </StaticDocumentSimpleLink>
          {' '}
          in relation to the loan.
        </>
      ),
      checked: false,
    },
    {
      key: 'agree_to_appoint',
      title: (
        <>
          Pursuant to the
          {' '}
          <StaticDocumentSimpleLink docKey="investor-agreement">
            Investor Agreement
          </StaticDocumentSimpleLink>
          {' '}
          I hereby agree to appoint Magna Trust Company Limited as my trustee in respect of the Loan and the securities
          pursuant to the terms of the
          {' '}
          <StaticDocumentSimpleLink docKey="declaration-of-trust">
            Declaration of Trust
          </StaticDocumentSimpleLink>
          .
        </>
      ),
      checked: false,
    },
  ];

  if (loanState === 'backfill') {
    checkboxesNz.push({
      key: 'agree_to_secondary_market',
      title: (
        <>
          I confirm that I have read and agree withZagga’s
          {' '}
          <StaticDocumentSimpleLink docKey="secondary-market-rules">
            Secondary Market Rules
          </StaticDocumentSimpleLink>
          .
        </>
      ),
      checked: false,
    });
    checkboxesNz.push({
      key: 'secondary_market_unlicensed',
      title: (
        <>
          I confirm that I am aware that the Secondary Market is not a licenced market.
          {' '}
          As a result, the investor protection mechanisms in Part 5 of the Financial Market Conduct Act 2013 do not apply.
        </>
      ),
      checked: false,
    });
  }

  let checkboxes = checkboxesAu;
  if (dealer() === 'znz') {
    checkboxes = checkboxesNz;
  }

  const [checkboxState, setCheckboxState] = useState(checkboxes);

  const clickHandler = (event) => {
    const newCheckboxState = checkboxState.map((checkbox) => {
      const updatedCheckbox = checkbox;
      if (checkbox.key === event.target.name) {
        updatedCheckbox.checked = !updatedCheckbox.checked;
        return updatedCheckbox;
      }
      return checkbox;
    });

    const allChecked = newCheckboxState.reduce((carry, checkbox) => carry && checkbox.checked, true);

    if (typeof allCheckedCallback === 'function') {
      allCheckedCallback(allChecked);
    }

    setCheckboxState(newCheckboxState);
  };


  const renderedCheckboxes = checkboxes.map((checkbox, index) => (
    <>
      <label
        htmlFor={`checkbox-item-for-${checkbox.key}`}
        className={`theme-checkbox ant-checkbox-wrapper ${checkboxState[index].checked && 'ant-checkbox-wrapper-checked'}`}
      >
      <span className={`ant-checkbox ${checkboxState[index].checked && 'ant-checkbox-checked'}`}>
        <input
          className="ant-checkbox-input"
          key={`checkbox-for-${checkbox.key}`}
          type="checkbox"
          id={`checkbox-item-for-${checkbox.key}`}
          defaultChecked={checkboxState[checkbox.key]}
          name={checkbox.key}
          onClick={clickHandler}
        />
        <span className="ant-checkbox-inner" />
      </span>
        <span>{checkbox.title}</span>
      </label>
    </>
  ));

  return (
    <Row className="theme-investment-terms">
      <Col span={12}><Paragraph><strong>I ACKNOWLEDGE:</strong></Paragraph></Col>
      <Col span={12} className="checkbox-wrapper">
        { renderedCheckboxes }
      </Col>

    </Row>
  );
};

InvestmentTerms.propTypes = {
  allCheckedCallback: func,
  loanState: string,
};

InvestmentTerms.defaultProps = {
  allCheckedCallback: () => {},
  loanState: 'funding',
};

export default InvestmentTerms;
