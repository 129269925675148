import React, { useContext } from 'react';
import { func, shape } from 'prop-types';
import Address from '../Address/Address';
import { dealerDefaultCountry } from '../../../Hooks/useLocalStorage';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Title from '../../../Atoms/Typography/Title/Title';
import LangContext from '../../../Context/Lang/LangContext';
import InputNumber from '../../../Atoms/Forms/Input/InputNumber';
import PropertyTypeField from './PropertyTypeField';
import { setNestedProp } from '../../../Helpers/Object/Object';

const PrimarySecurity = (props) => {
  const { form } = props;
  const { t } = useContext(LangContext);

  return (
    <div className="theme-primary-security">
      <Title level={4}>Primary security</Title>

      <Address
        form={form}
        parents={['securities', 'primary', 'address']}
        requireCountry={false}
        defaultCountry={dealerDefaultCountry()}
        configCallback={config => setNestedProp(config, ['spec', 'address_line1', 'required'], true)}
      />

      <PropertyTypeField />

      <FormItem
        label="Current Valuation"
        name={['securities', 'primary', 'currentValuation']}
      >
        <InputNumber
          prefix="$"
          formatter={value => (value ? Number(value)?.toLocaleString('en-NZ') : value)}
        />
      </FormItem>
      <FormItem
        label="Registered Valuation"
        name={['securities', 'primary', 'registeredValuation']}
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <InputNumber
          prefix="$"
          formatter={value => (value ? Number(value)?.toLocaleString('en-NZ') : value)}
        />
      </FormItem>
    </div>
  );
};

PrimarySecurity.propTypes = {

  form: shape({
    setFieldsValue: func,
  }),
};

PrimarySecurity.defaultProps = {
  form: null,
};

export default PrimarySecurity;
