import { gql } from '@apollo/client';

export const CREATE_DRAFT = gql`
  mutation draftCreate($input: DraftInput!) {
    draftCreate(input: $input) {
      success
      message
      json
    }
  }
`;

export const UPDATE_DRAFT = gql`
  mutation draftUpdate($input: DraftUpdateInput!) {
    draftUpdate(input: $input) {
      success
      message
      json
    }
  }
`;

export const DELETE_DRAFT = gql`
  mutation draftDelete($input: DraftDeleteInput!) {
    draftDelete(input: $input) {
      success
      message
    }
  }
`;
