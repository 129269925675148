import React, { useContext, useEffect, useState } from 'react';
import {
  arrayOf, func, number, oneOfType, string,
} from 'prop-types';
import { Col, Form, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import Input from '../../../Atoms/Forms/Input/Input';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import EntitiesSelector from '../../../Molecules/EntitiesSelector/EntitiesSelector';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import LangContext from '../../../Context/Lang/LangContext';
import './AccreditationOfIntroducerStatus.less';
import { getFieldName } from '../../../Helpers/Forms';
import DraggerFormItem from '../../../Molecules/Forms/DraggerFormItem/DraggerFormItem';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import {
  findStepEntityByStepKey,
  getCurrentStep,
  getPrimaryClientEntity,
  getSteps,
  setSelected,
} from '../../../Store/clientRegistrationSlice';
import useShouldUpdateFiles from '../../../Hooks/useShouldUpdateFiles';

const AccreditationOfIntroducerStatus = ({
  onFinishLastStep, onGoBack, stepKey, parents,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LangContext);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const entities = useSelector(getPrimaryClientEntity);
  const steps = useSelector(getSteps);
  const step = useSelector(getCurrentStep);
  if (steps[step]?.component === stepKey) {
    dispatch(setSelected(entities[0]?.id));
  }

  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuid() };

  useEffect(() => {
    setFieldsValue(stepData);
  }, [stepData]);

  const [showAclNumber, setShowAclNumber] = useState(false);

  useEffect(() => {
    setFieldsValue(stepData);
  }, [stepData]);

  const fileUploadComment = (
    <>
      Please ensure you have uploaded all required documents including:
      <br />
      <ul>
        <li>Australian Credit Licence certificate (or authorisation as credit representative)</li>
        <li>Certificate of Registration or ABN Confirmation</li>
        <li>Certificate of Currency for your active Professional Indemnity insurance policy</li>
        <li>Membership certificate for your indicated External Dispute Resolution (EDR) scheme</li>
      </ul>
    </>
  );

  return (
    <div className="theme-accreditation-of-introducer-status">
      <HeaderTitle
        title="Accreditation status"
      />
      <Row>
        <Col span={12}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinishLastStep}
            name="accreditationOfIntroducerStatusForm"
            initialValues={stepData}
          >
            <EntitiesSelector
              label="For the following"
              allowNew={false}
              entities={entities}
              defaultValue={0}
            />
            <StepInfo id={uuid()} stepKey={stepKey} parentId={entities[0].id} />
            <FormItem
              label="I engage in credit activities"
              name={getFieldName('introducerAuthorisation', parents)}
              rules={[{ required: true, message: t('validation.required_option') }]}
            >
              <RadioGroup className="has-3-columns box-style" onChange={event => setShowAclNumber(event.target.value === 'ACL')}>
                <div className="theme-radio-wrapper"><Radio className="entity-radio" value="ACL">Under my own Australian Credit Licence (ACL)</Radio></div>
                <div className="theme-radio-wrapper"><Radio className="entity-radio" value="CR">As a Credit representative</Radio></div>
                <div className="theme-radio-wrapper"><Radio className="entity-radio" value="NONE">Under an exemption </Radio></div>
              </RadioGroup>
            </FormItem>
            { showAclNumber && (
              <FormItem
                label="Please provide ACL number"
                name=""
              >
                <Input />
              </FormItem>
            )}
            <FormItem
              label="I am a member of Australian Financial Complaints Authority (AFCA)"
              name={getFieldName('introducerScheme', parents)}
              rules={[{ required: true, message: t('validation.required_option') }]}
            >
              <RadioGroup className="has-3-columns box-style">
                <div className="theme-radio-wrapper"><Radio className="entity-radio" value="Yes">Yes</Radio></div>
                <div className="theme-radio-wrapper"><Radio className="entity-radio" value="No">No</Radio></div>
              </RadioGroup>
            </FormItem>
            <DraggerFormItem
              comment={fileUploadComment}
              fieldName={getFieldName('introducerAccreditationDocuments', parents)}
              rules={[item => useValidationRules.softRequired(item, 'Accreditation document')]}
              shouldUpdateComponent={
                (
                  { errors: prevErrors, introducerStatus: prevIntroducerStatus },
                  { errors: currentErrors, introducerStatus: currentIntroducerStatus },
                ) => useShouldUpdateFiles(
                  prevIntroducerStatus,
                  currentIntroducerStatus,
                  'introducerAccreditationDocuments',
                ) || prevErrors !== currentErrors
              }
            />
            <FormItem hidden noStyle name="errors" />
            <ClientRegistrationActions form={form} onGoBack={onGoBack} />
          </Form>
        </Col>
      </Row>
    </div>

  );
};

AccreditationOfIntroducerStatus.propTypes = {
  onFinishLastStep: func,
  onGoBack: func,
  stepKey: string,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

AccreditationOfIntroducerStatus.defaultProps = {
  onFinishLastStep: () => {},
  onGoBack: () => {},
  stepKey: 'accreditation-of-introducer-status-form',
  parents: ['introducerStatus'],
};

export default AccreditationOfIntroducerStatus;
