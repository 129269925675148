import React, { useState } from 'react';
import AccountPanel from '../../Molecules/Panel/AccountPanel';
import { haveEntityPermission } from '../../Helpers/Helpers';
import AccountTabs from '../../Organisms/Control/AccountTabs';
import { InvestmentDocuments } from '../../Components/Account/AccountDocuments';
import AccountClientDetails from '../../Organisms/Cards/Accounts/AccountClientDetails';
import { formatCurrencyFull, formatPercentage } from '../../Hooks/useNumberFormatter';
import Card from '../../Molecules/Cards/Card';
import Title from '../../Atoms/Typography/Title/Title';
import Fields from '../../Molecules/DataDisplay/Fields/Fields';
import Date from '../../Atoms/DataDisplay/Field/Date';
import ManagedFundInvestmentHeader from '../../Organisms/Headers/ManagedFundInvestmentHeader';

const InvestmentManagedFundPage = (props) => {
  const {
    context,
    fund,
    investment,
    managedFundLoans,
    documents,
  } = props;

  const {
    fieldLmsPoolId,
    fieldTargetRate,
    fieldFundState,
    fieldOpenDate,
    label,
  } = fund;

  const {
    fieldLmsAccountId,
    fieldClient,
    fieldAmount,
    fieldState,
  } = investment;

  const [currentTab, setCurrentTab] = useState('details');

  const investmentIsActive = fieldState !== 'closed';

  const managedFundLoanDetails = fundLoans => fundLoans.map((fundInvestment) => {
    const fundLoan = fundInvestment?.fieldLoan;
    const loanTitle = fundLoan?.loanTitle;
    const loanImage = fundLoan?.fieldPrimarySecurity?.fieldImages?.[0];

    return (
      <AccountPanel
        context={context}
        key={fundLoan.uuid}
        loanId={fundLoan.fieldLmsAccountId}
        link={`/app/${context}/application/${fundLoan.uuid}`}
        risk={fundLoan.fieldRisk}
        rate={haveEntityPermission(fundLoan, 'View rates') && fundInvestment.investmentRate}
        loanState={fundLoan.fieldFundState}
        loanType={fundLoan.label}
        loanAmount={fundLoan.fieldAmount}
        loanImage={loanImage}
        pageContext="managed_fund"
        title={loanTitle}
      />
    );
  });

  let fundFields = [
    { label: 'Investment amount', value: formatCurrencyFull(fieldAmount) },
    { label: 'Target Rate', value: formatPercentage(fieldTargetRate) },
  ];

  if (fieldOpenDate) {
    fundFields = [...fundFields, { label: 'As at', value: <Date value={fieldOpenDate} /> }];
  }

  return (
    <div className="theme-investment-managed-fund page-inner">
      <ManagedFundInvestmentHeader
        amount={fieldAmount}
        fundId={fieldLmsPoolId}
        fundState={fieldFundState}
        investmentId={fieldLmsAccountId}
        name={label}
        state={fieldState}
      />
      <AccountTabs setDisplay={setCurrentTab} currentTab={currentTab} account={{ data: investment, from: 'investment' }} />
      {
        currentTab === 'details' && (
          <div className="wrapper">
            <AccountClientDetails title="Investor details" client={fieldClient} />
            <Card
              title={(<Title level={4}>Investment fund details</Title>)}
            >
              <Fields fields={fundFields} />
            </Card>

            {
              managedFundLoans && investmentIsActive
              && (
                <Card title={(<Title level={4}>Currently funded loans</Title>)}>
                  <div className="inner-wrapper flex-direction-column">
                    {managedFundLoanDetails(managedFundLoans)}
                  </div>
                </Card>
              )
            }
          </div>
        )
      }
      {currentTab === 'documents' && (
        <InvestmentDocuments media={documents} />
      )}
    </div>
  );
};

export default InvestmentManagedFundPage;
