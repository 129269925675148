import React from 'react';
import { func, node } from 'prop-types';
import { InputNumber as AntdInputNumber } from 'antd';
import './InputNumber.less';

const InputNumber = props => <AntdInputNumber {...props} className="theme-input-number" />;

InputNumber.propTypes = {
  addonAfter: node,
  addonBefore: node,
  formatter: func,
  prefix: node,

};

InputNumber.defaultProps = {
  addonAfter: undefined,
  addonBefore: undefined,
  formatter: undefined,
  prefix: undefined,
};

export default InputNumber;
