import React from 'react';
import {
  func, bool, string, oneOfType, number, shape, node, oneOf,
} from 'prop-types';
import { Tabs as AntdTabs } from 'antd';
import './Tabs.less';

const Tabs = (props) => {
  const { className } = props;
  return <AntdTabs {...props} className={className !== undefined ? `theme-tabs ${className}` : 'theme-tabs'} />;
};


Tabs.propTypes = {
  addIcon: node,
  animated: oneOfType([bool, shape({
    inkBar: bool,
    tabPane: bool,
  })]),
  centered: bool,
  className: string,
  defaultActiveKey: string,
  hideAdd: bool,
  moreIcon: node,
  size: oneOf(['large', 'default', 'small']),
  tabBarExtraContent: oneOfType([node, shape({
    left: oneOfType([undefined, node]),
    right: oneOfType([undefined, node]),
  })]),
  tabBarGutter: number,
  tabBarStyle: shape({}),
  tabPosition: oneOf(['top', 'bottom', 'right', 'left']),
  destroyInactiveTabPane: bool,
  type: oneOf(['line', 'card', 'editable-card']),
  onChange: func,
  onEdit: func,
  onTabClick: func,
  onTabScroll: func,
};

Tabs.defaultProps = {
  addIcon: undefined,
  animated: undefined,
  centered: false,
  className: '',
  defaultActiveKey: '',
  hideAdd: false,
  moreIcon: undefined,
  size: 'default',
  tabBarExtraContent: undefined,
  tabBarGutter: undefined,
  tabBarStyle: undefined,
  tabPosition: 'top',
  destroyInactiveTabPane: false,
  type: 'line',
  onChange: () => {},
  onEdit: () => {},
  onTabClick: () => {},
  onTabScroll: () => {},
};

export default Tabs;
