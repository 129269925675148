import React, {
  useEffect, useRef,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../Atoms/Forms/Button/Button';
import Drawer from '../../../Atoms/Layout/Drawer/Drawer';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import ClientRegistrationListItem
  from '../../../Molecules/ViewModes/ClientRegistrationListItem/ClientRegistrationListItem';
import './AccountOverview.less';
import Badge from '../../../Atoms/DataDisplay/Badge/Badge';
import Image from '../../../Atoms/Media/Image/Image';
import { dealer } from '../../../Hooks/useLocalStorage';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import {
  getAllStepEntities,
  getRegisterEntities,
  getSteps, removeEntity,
  setShowAccountOverviewDrawer,
  showAccountOverviewDrawer,
  getSelectedType,
  getClientStructureStepData,
  setStep,
  setReduxSubStep,
  setSelected,
} from '../../../Store/clientRegistrationSlice';
import IconButton from '../../../Atoms/Forms/IconButton/IconButton';
import { errorsForEntityById, getEntityName } from '../../../Hooks/useEntity';
import Title from '../../../Atoms/Typography/Title/Title';

const AccountOverview = () => {
  const visible = useSelector(showAccountOverviewDrawer);
  const dispatch = useDispatch();
  const entities = useSelector(getRegisterEntities);
  const allStepEntities = useSelector(getAllStepEntities);
  const steps = useSelector(getSteps);
  const type = useSelector(getSelectedType);
  const primaryClient = useSelector(getClientStructureStepData);
  const brand = dealer();

  const prevVisible = useRef();
  useEffect(() => {
    prevVisible.current = visible;
  });

  const onDelete = ({ id }) => {
    dispatch(removeEntity(id));
  };

  const entitiesList = [];
  if (entities !== undefined && entities.length > 0) {
    entitiesList.push(entities.map(
      (entity) => {
        const { id, name } = getEntityName(entity);
        return (
          <ClientRegistrationListItem
            key={`entity-${id}`}
            entity={entity}
            validationErrors={errorsForEntityById(allStepEntities, steps, id)}
            deleteable={(
              <Popconfirm
                title={`Are you sure you want to delete ${name}`}
                placement="topRight"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDelete(entity)}
              >
                <IconButton
                  type="delete"
                  size="small"
                />
              </Popconfirm>
            )}
          />
        );
      },
    ));
  }

  return (
    <div className="theme-account-overview-drawer">
      <div className="theme-account-overview-float-wrapper">
        <Button type="secondary" htmlType="submit" onClick={() => dispatch(setShowAccountOverviewDrawer(true))}>
          <FontAwesomeIcon icon={faUser} size="2x" />
          <Badge count={entities?.length || 0} showZero />
          Account overview
        </Button>
      </div>
      <Drawer
        title={<Title level={2}>Account overview</Title>}
        open={visible}
        onClose={() => dispatch(setShowAccountOverviewDrawer(false))}
        destroyOnClose
      >
        <div className="theme-account-overview-comment">
          <Paragraph>
            This is where you will see the directors and principals of your entity as they are created.
          </Paragraph>
          <Paragraph>
            To check that you have entered all required information, return to this screen by clicking on the Account overview tab as shown.
          </Paragraph>
          <Paragraph>
            To make changes, click on the grey button and follow the prompts.
          </Paragraph>
          <Image alt="Account overview icon" src={`/assets/images/account-overview-icon-${brand}.svg`} preview={false} />
        </div>

        <div className="theme-panel-wrapper slide-out">
          {entitiesList}
          {entitiesList.length === 0 && (<span>No entities have been created yet.</span>)}
        </div>
        {type !== 'individual' && entities.length > 1 && (
        <div className="theme-confirm-wrapper">
          <Paragraph>Have all entities been added?</Paragraph>
          <Button
            ghost
            type="secondary"
            onClick={() => {
              dispatch(setStep(1));
              dispatch(setReduxSubStep(primaryClient?.trusteeType === 'corporate' ? 1 : 0));
              dispatch(setSelected('new'));
              dispatch(setShowAccountOverviewDrawer(false));
            }}
          >
            Add Entity
          </Button>
        </div>
        )}
      </Drawer>
    </div>
  );
};

export default AccountOverview;
