import { gql } from '@apollo/client';

export const SYNC_ACCOUNT = gql`
  mutation SyncAccount($uuid: String!) {
    syncAccount(uuid: $uuid) {
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation DeleteAccount($uuid: String!) {
    deleteAccount(uuid: $uuid) {
      success
      message
    }
  }
`;

export const UPDATE_LOAN = gql`
  mutation UpdateLoan($uuid: String!, $input: LoanInput) {
    loanUpdate(uuid: $uuid, input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_INVESTMENT = gql`
  mutation UpdateInvestment($uuid: String!, $input: InvestmentInput) {
    investmentUpdate(uuid: $uuid, input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_LOAN_POOL = gql`
  mutation UpdateLoanPool($uuid: String!, $input: LoanPoolInput) {
    loanPoolUpdate(uuid: $uuid, input: $input) {
      success
      message
    }
  }
`;

export const LOAN_VIEWED = gql`
  mutation LoanViewed($uuid: String!) {
    loanViewed(uuid: $uuid) {
      success
      message
    }
  }
`;

export const INVESTMENT_ATTACH_IM = gql`
  mutation InvestmentAttachIm($uuid: String! $mediaUuid: String!) {
    attachIm(uuid: $uuid mediaUuid: $mediaUuid) {
      success
      message
    }
  }
`;

export default {
  LOAN_VIEWED,
  SYNC_ACCOUNT,
  UPDATE_LOAN,
  UPDATE_INVESTMENT,
  UPDATE_LOAN_POOL,
};
