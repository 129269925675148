import React from 'react';
import { shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import Link from '../../Atoms/Navigation/Links/Link';
import './ClientLoanApplicationButton.less';
import HasEntityPermission from '../../Molecules/Control/HasEntityPermission';

const ClientLoanApplicationButton = ({ client }) => {
  const { context } = useParams();

  return (
    <HasEntityPermission name="Can apply for a loan" entity={client}>
      <Link
        key={`${client?.uuid}-apply`}
        className="theme-client-loan-application-button"
        href={`/app/${context}/apply?client=${client?.uuid}`}
      >
        Create new application
      </Link>
    </HasEntityPermission>
  );
};

ClientLoanApplicationButton.propTypes = {
  client: shape({
    uuid: string.isRequired,
  }).isRequired,
};

export default ClientLoanApplicationButton;
