import React, {
  useState, useContext,
} from 'react';
import {
  func, shape, number, bool, string,
} from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { CREATE_REDUCTION } from '../../../../../GraphQL/Mutations/Reduction';
import Alert from '../../../../../Atoms/Feedback/Alert/Alert';
import FormItem from '../../../../../Atoms/Forms/FormItem/FormItem';
import Button from '../../../../../Atoms/Forms/Button/Button';
import RadioGroup from '../../../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../../../Atoms/Forms/Radio/Radio';
import LangContext from '../../../../../Context/Lang/LangContext';
import Currency from '../../../../../Atoms/DataDisplay/Field/Currency';
import Policy from '../../../Policies/Policy';
import Result from '../../../../../Atoms/Feedback/Result/Result';
import ButtonGroup from '../../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import DatePicker from '../../../../../Atoms/Forms/DatePicker/DatePicker';
import Text from '../../../../../Atoms/Typography/Text/Text';
import './RequestReductionForm.less';
import { getGraphQlErrorMessage } from '../../../../../Helpers/Error';
import Divider from '../../../../../Atoms/Layout/Divider/Divider';
import { formatDateAsTimestamp } from '../../../../../Helpers/FormatDate';
import { calculateBreakFeeWithState } from '../../../../../Hooks/useFees';
import BreakFee from '../../../../../Molecules/DataDisplay/Fields/BreakFee';
import RemainingInvestment from '../../../../../Molecules/DataDisplay/Fields/RemainingInvestment';

const RequestReductionForm = ({
  onCancel, investment: {
    entityPermissions, fieldAmount, type, uuid, latestReductionDate,
  },
}) => {
  const minAllowedAmount = 1000;
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const [CreateReduction, { loading }] = useMutation(CREATE_REDUCTION);
  const [message, setMessage] = useState(false);
  const [desiredReduction, setDesiredReduction] = useState(minAllowedAmount);
  const [decreaseOption, setDecreaseOption] = useState('partial');
  const [breakFee, setBreakFee] = useState(75);

  const { permitted } = entityPermissions.find(({ label }) => label === 'Can reduce investment') || { permitted: false };

  if (!permitted || type !== 'investment') {
    return (<Result status="error" title="Sorry this investment can not be reduced!" />);
  }

  const onFinish = (input) => {
    const { amount, expire } = input;
    CreateReduction({
      variables: {
        investmentUuid: uuid,
        fieldAmount: amount,
        fieldBreakFee: breakFee,
        fieldAction: 'accept_on_expiry',
        fieldExpiry: formatDateAsTimestamp(expire),
        fieldStatus: 'pending',
      },
    })
      .then(() => {
        setMessage(false);
        onCancel();
      })
      .catch((error) => {
        setMessage(getGraphQlErrorMessage(error));
      });
  };

  const disabledDate = current => current < moment.unix(latestReductionDate).add(3, 'days').endOf('day')
    || current > moment.unix(latestReductionDate).add(7, 'days').endOf('day');
  const preferredPeriod = moment().add(7, 'days');

  return (
    <div className="theme-request-reduction-form">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ expire: preferredPeriod, amount: minAllowedAmount }}
      >
        { message && <Alert message={message} type="error" /> }

        <Text type="comment">
          Decreasing your loan commitment will open a new investment opportunity on the
          {' '}
          { process.env.REACT_APP_BRAND }
          {' '}
          platform.
          Your opportunity will remain in a pending state until approved by the
          {' '}
          { process.env.REACT_APP_BRAND }
          {' '}
          credit assessment team.
        </Text>

        <FormItem
          label="Decrease"
          name="decreaseOption"
        >
          <RadioGroup
            defaultValue={decreaseOption}
            onChange={({ target: { value } }) => {
              form.setFieldsValue({ decreaseOption: value });
              setDecreaseOption(value);
              if (value === 'full') {
                form.setFieldsValue({ amount: fieldAmount });
                setDesiredReduction(fieldAmount);
              }
            }}
          >
            <Radio value="full">Full amount</Radio>
            <Radio value="partial">Partial</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem
          label="Amount"
          name="amount"
          hidden={decreaseOption === 'full'}
          className="theme-form-amount"
          rules={[
            { required: true, message: t('validation.required', { attribute: 'field' }) },
            { type: 'number', min: minAllowedAmount, message: `The minimum must be greater than ${minAllowedAmount}` },
            { type: 'number', max: fieldAmount, message: `The amount can not be greater than $${fieldAmount}` },
          ]}
        >
          <span className="input-group-addon">$</span>
          <Currency
            className="form-control"
            placeholder="Enter additional amount"
            thousandSeparator
            displayType="input"
            value={desiredReduction}
            allowNegative={false}
            prefix=""
            onValueChange={({ floatValue }) => {
              const amount = floatValue || 0;
              form.setFieldsValue({ amount, fieldBreakFee: calculateBreakFeeWithState(amount, setBreakFee) });
              setDesiredReduction(amount);
            }}
          />
        </FormItem>

        <RemainingInvestment title="Your remaining investment" available={fieldAmount} selectedOption="partial" commitment={desiredReduction} />

        <FormItem
          label="Offering to expire on"
          name="expire"
          rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            disabledDate={disabledDate}
            defaultValue={preferredPeriod}
          />
        </FormItem>

        <FormItem
          name="fieldBreakFee"
          hidden
          className="theme-form-amount"
        >
          <Currency
            className="form-control"
            placeholder="Enter additional amount"
            thousandSeparator
            displayType="input"
            value={breakFee}
            allowNegative={false}
            prefix=""
          />
        </FormItem>

        <BreakFee breakFee={breakFee} />

        <Divider />

        <Policy policyKey="ReductionPolicy" />

        { message && <Alert message={message} type="error" /> }

        <ButtonGroup direction="right" className="set-3-style">
          <Button
            type="ghost"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? 'Please wait' : 'Create marketplace offering' }
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

RequestReductionForm.propTypes = {
  onCancel: func.isRequired,
  investment: shape({
    uuid: string,
    entityPermissions: shape([
      { label: string, permitted: bool },
    ]),
    fieldAmount: number,
    fieldState: string,
    type: string,
  }).isRequired,
};

export default RequestReductionForm;
