import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { AutoComplete, Input } from 'antd';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { USERS_FOR_BROKER, USERS_FOR_DEALER } from '../../../GraphQL/Queries/User';
import { isDealer as _isDealer } from '../../../Store/userSlice';

const UserSearchFormItem = ({ fieldName, label, className }) => {
  const [options, setOptions] = useState([]);
  const isDealer = useSelector(_isDealer);

  const { data, refetch } = useQuery(isDealer ? USERS_FOR_DEALER : USERS_FOR_BROKER);

  useEffect(() => {
    if (data?.users) {
      setOptions(data?.users?.results?.map(row => ({ label: row.email, value: row.email, uuid: row.uuid })));
    }
  }, [data]);

  const userSearch = (event) => {
    const { target: { value } } = event;
    if (value !== undefined && value.length > 3) {
      refetch({ emailFilter: value });
    }
  };

  return (
    <>
      <FormItem
        label={label}
        className={className}
        name={`${fieldName}Select`}
        onChange={userSearch}
      >
        <AutoComplete
          dataSource={options}
        />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(previous, next) => !previous || (previous[`${fieldName}Select`] !== next[`${fieldName}Select`])}
      >
        {({ getFieldValue, setFieldValue }) => {
          const selectVal = getFieldValue(`${fieldName}Select`);
          const option = options.filter(item => item.value === selectVal).pop()?.uuid;
          if (option || !selectVal) {
            setFieldValue(fieldName, option);
          }
          return (
            <FormItem hidden name={fieldName}>
              <Input />
            </FormItem>
          );
        }}
      </FormItem>
    </>
  );
};

UserSearchFormItem.propTypes = {
  className: string,
  fieldName: string.isRequired,
  label: string.isRequired,
};

UserSearchFormItem.defaultProps = {
  className: null,
};

export default UserSearchFormItem;
