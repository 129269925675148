import React from 'react';
import {
  arrayOf,
  bool, func, node, number, oneOf, oneOfType, shape, string,
} from 'prop-types';
import { Select as AntdSelect } from 'antd';
import './Select.less';

const Select = args => <AntdSelect {...args} className="theme-select" />;

Select.propTypes = {
  allowClear: bool,
  autoClearSearchValue: bool,
  autoFocus: bool,
  bordered: bool,
  // clearIcon: node,
  defaultActiveFirstOption: bool,
  // defaultOpen: bool,
  // defaultValue: oneOfType([string, arrayOf(string), number, arrayOf(number)]),
  disabled: bool,
  // dropdownClassName: string,
  dropdownMatchSelectWidth: oneOfType([bool, string]),
  filterOption: oneOfType([bool, func]),
  // filterSort: func,
  // getPopupContainer: func,
  labelInValue: bool,
  listHeight: number,
  loading: bool,
  maxTagCount: oneOf(['number', 'responsive']),
  // maxTagTextLength: number,
  // menuItemSelectedIcon: node,
  mode: oneOf(['multiple', 'tags']),
  notFoundContent: node,
  // open: bool,
  optionFilterProp: string,
  optionLabelProp: string,
  options: arrayOf(shape({ label: string, value: oneOfType([string, number]) })),
  // placeholder: node,
  // removeIcon: node,
  // searchValue: string,
  // showArrow: bool,
  showSearch: bool,
  size: oneOf(['large', 'middle', 'small']),
  // suffixIcon: node,
  // tagRender: func,
  // tokenSeparators: arrayOf(string),
  // value: oneOfType([string, arrayOf(string), number, arrayOf(number)]),
  virtual: bool,
  // onBlur: func,
  onChange: func,
  // onClear: func,
  // onDeselect: func,
  // onDropdownVisibleChange: func,
  // onFocus: func,
  // onInputKeyDown: func,
  // onMouseEnter: func,
  // onMouseLeave: func,
  // onPopupScroll: func,
  // onSearch: func,
  // onSelect: func,
};

Select.defaultProps = {
  allowClear: false,
  autoClearSearchValue: true,
  autoFocus: false,
  bordered: true,
  // clearIcon: undefined,
  defaultActiveFirstOption: true,
  // defaultOpen: undefined,
  // defaultValue: undefined,
  disabled: false,
  // dropdownClassName: undefined,
  dropdownMatchSelectWidth: true,
  filterOption: (input, { label }) => label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  // filterSort: undefined,
  // getPopupContainer: () => document.body,
  labelInValue: false,
  listHeight: 256,
  loading: false,
  maxTagCount: 'responsive',
  // maxTagTextLength: undefined,
  // menuItemSelectedIcon: undefined,
  mode: undefined,
  notFoundContent: 'Not Found',
  // open: undefined,
  optionFilterProp: 'label',
  optionLabelProp: 'label',
  options: [],
  // placeholder: undefined,
  // removeIcon: undefined,
  // searchValue: undefined,
  // showArrow: undefined,
  showSearch: false,
  size: 'middle',
  // suffixIcon: undefined,
  // tagRender: undefined,
  // tokenSeparators: undefined,
  // value: undefined,
  virtual: true,
  // onBlur: undefined,
  onChange: undefined,
  // onClear: undefined,
  // onDeselect: undefined,
  // onDropdownVisibleChange: undefined,
  // onFocus: undefined,
  // onInputKeyDown: undefined,
  // onMouseEnter: undefined,
  // onMouseLeave: undefined,
  // onPopupScroll: undefined,
  // onSearch: undefined,
  // onSelect: undefined,
};
export default Select;
