import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  arrayOf, number, oneOfType, string,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LangContext from '../../../Context/Lang/LangContext';
import { getRoles } from '../../../Store/clientRegistrationSlice';
import { isBroker as _isBroker } from '../../../Store/userSlice';
import Input from '../../../Atoms/Forms/Input/Input';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Group from '../../../Molecules/Forms/Group/Group';
import Company from '../../../Molecules/Forms/Company/Company';
import Trust from '../../../Molecules/Forms/Trust/Trust';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import ClientConfigContext from '../../../Context/ClientConfig/ClientConfigContext';
import { getFieldName } from '../../../Helpers/Forms';
import Person from '../../../Molecules/Forms/Person/Person';
import { dealer } from '../../../Hooks/useLocalStorage';

const ClientStructureSelector = ({ parents }) => {
  const { t } = useContext(LangContext);
  const { typesForRole } = useContext(ClientConfigContext);
  const roles = useSelector(getRoles);
  const [types, setTypes] = useState([]);
  const [role, setRole] = useState(null);
  const componentsParent = [...parents, 'client'];
  const { context } = useParams();
  const isBroker = useSelector(_isBroker);

  const filterOutRoles = (inputRoles) => {
    if (inputRoles) {
      if (context === 'borrower') return inputRoles.filter(({ value }) => value === 'borrower');
      if (context === 'investor') return inputRoles.filter(({ value }) => value === 'investor');
      if (context === 'broker') {
        if (!isBroker) return inputRoles.filter(({ value }) => ['broker', 'introducer'].includes(value));
        if (isBroker && dealer() === 'apm') return inputRoles.filter(({ value }) => value === 'borrower');

        return inputRoles.filter(({ value }) => !['broker', 'introducer'].includes(value));
      }
    }
    return inputRoles;
  };

  const availableComponents = [
    { name: 'individual', component: <Person type="omit" bundle="omit" /> },
    { name: 'individuals', component: <Group /> },
    { name: 'company', component: <Company parents={componentsParent} /> },
    { name: 'trust', component: <Trust /> },
  ];

  /* Select type if there is only one option available after selecting role */
  const selectTypeWhenSingleOption = (newRole, newTypes, values, setFieldValue) => {
    if (newTypes.length === 1) {
      setFieldValue(getFieldName('type', parents), newTypes[0].value);
    }
  };

  const onChangeSelectedRole = ({ getFieldValue, setFieldValue, getFieldsValue }) => {
    const newRole = getFieldValue(getFieldName('role', parents));
    setRole(newRole);
    selectTypeWhenSingleOption(newRole, typesForRole(newRole), getFieldsValue(), setFieldValue);
  };

  useEffect(() => {
    if (role) {
      setTypes(typesForRole(role));
    }
  }, [role]);

  return (
    <>
      <FormItem hidden name={getFieldName('id', parents)}>
        <Input />
      </FormItem>

      <FormItem
        label={dealer() === 'znz' && ['dealer', 'broker'].includes(context) && !(context === 'broker' && !isBroker) ? 'I am registering a' : 'I am registering as'}
        name={getFieldName('role', parents)}
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <RadioGroup name={getFieldName('role', parents)} options={filterOutRoles(roles)} />

      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(previous, next) => previous.role !== next.role && next.role}
      >
        {onChangeSelectedRole}
      </FormItem>

      <FormItem
        label="I am registering the following entity"
        name={getFieldName('type', parents)}
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
        hidden={!role}
      >
        <RadioGroup name="type" options={types} />
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(previous, next) => previous.type !== next.type}
      >
        { ({ getFieldValue }) => {
          const { component } = availableComponents.find(item => item.name === getFieldValue('type')) || { component: '' };
          return (component);
        }}
      </FormItem>
    </>
  );
};

ClientStructureSelector.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

ClientStructureSelector.defaultProps = {
  parents: ['structure'],
};


export default ClientStructureSelector;
