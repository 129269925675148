import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../Atoms/Forms/Button/Button';
import ReportDrawer from '../../Drawers/ReportDrawer/ReportDrawer';

const GenerateReportButton = (props) => {
  const { children, onClick, text } = props;
  const [visible, setVisible] = useState(false);


  const handleClick = (event) => {
    setVisible(true);
    onClick?.(event);
  };

  return (
    <>
      <Button type="primary" onClick={handleClick}>
        {text}
      </Button>
      <ReportDrawer
        visible={visible}
        onClose={() => setVisible(false)}
      >
        { children }
      </ReportDrawer>

    </>
  );
};

GenerateReportButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

GenerateReportButton.defaultProps = {
  children: null,
  onClick: null,
  text: 'Reports',
};

export default GenerateReportButton;
