import React from 'react';
import { Col, Form, Row } from 'antd';
import { func, shape } from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import DraggerFormItem from '../../../../Molecules/Forms/DraggerFormItem/DraggerFormItem';
import LoanApplicationActions from '../../../../Molecules/Forms/Actions/LoanApplicationActions';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import StepInfo from '../../../../Molecules/Forms/StepInfo/StepInfo';
import { getStep } from '../../../../Store/loanApplicationSlice';
import useShouldUpdateFiles from '../../../../Hooks/useShouldUpdateFiles';
import { getFieldName } from '../../../../Helpers/Forms';

const LoanDocumentStep = (props) => {
  const [form] = Form.useForm();
  const { initialValues, onClickBack, onFinish } = props;
  const step = useSelector(getStep);

  return (
    <Row>
      <HeaderTitle
        title="Document upload"
        commentType="grey-box"
        comment={(
          <Paragraph>
            Please upload a copy of the supporting documentation for your loan application. You may skip this step and
            {' '}
            <b>provide</b>
            {' '}
            at a later stage.
          </Paragraph>
        )}
      />
      <Col className="gutter-row" span={12}>
        <Form
          initialValues={initialValues}
          form={form}
          layout="vertical"
          name="loan-documents-form"
          onFinish={onFinish}
        >
          <StepInfo id={uuid()} stepKey={step} />
          <FormItem hidden noStyle name="errors" />

          <DraggerFormItem
            comment="Please upload copy of your document"
            maxCount={10}
            fieldName={getFieldName('documentsUpload', 'documents')}
            shouldUpdateComponent={
              (
                { errors: prevErrors, documents: prevDocs },
                { errors: currentErrors, documents: currentDocs },
              ) => useShouldUpdateFiles(prevDocs, currentDocs, 'documentsUpload')
                || (prevErrors !== currentErrors)
            }
          />

          <LoanApplicationActions onClickBack={onClickBack} form={form} />

        </Form>
      </Col>
    </Row>

  );
};

LoanDocumentStep.propTypes = {
  initialValues: shape({}),
  onClickBack: func,
  onFinish: func,
};

LoanDocumentStep.defaultProps = {
  initialValues: {},
  onClickBack: () => {},
  onFinish: () => {},
};

export default LoanDocumentStep;
