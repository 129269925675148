import React from 'react';
import {
  arrayOf, func, number, oneOfType, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { v4 as uuid } from 'uuid';
import {
  getInitialFormValuesForSubStep,
  getParentId,
  setSelected,
} from '../../../Store/clientRegistrationSlice';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../Helpers/Forms';
import FullName from '../../../Molecules/Forms/FullName/FullName';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import usePersonVerification from '../../../Hooks/usePersonVerification';
import './PersonVerification.less';
import { getAppVerificationOptions } from '../../../Store/appConfigurationSlice';

const PersonVerification = ({
  parents, stepKey, subStepKey, onFinish, onGoBack,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const parentId = useSelector(getParentId(subStepKey));
  const { options: verificationOptions } = useSelector(getAppVerificationOptions);
  const { softRequired } = useValidationRules;
  const { shouldUpdateComponent, getSelectedComponent } = usePersonVerification({ parents, verificationOptions });
  const initialValues = useSelector(getInitialFormValuesForSubStep(parentId, subStepKey));
  if (!initialValues.personVerification) {
    initialValues.personVerification = {
      fieldCountryOfBirth: 'NZ',
    };
  }

  const goBackPersonVerification = () => {
    dispatch(setSelected(parentId));
    onGoBack();
  };

  return (
    <div className="theme-person-verification">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        name="registerAndVerifyEntitiesForm"
      >
        <StepInfo id={uuid()} parentId={parentId} stepKey={stepKey} subStepKey={subStepKey} />

        <FormItem
          label="Please select one of the following"
          name={getFieldName('selected', parents)}
          rules={[item => softRequired(item, 'Identification')]}
        >
          <RadioGroup className={`has-${verificationOptions.length}-columns box-style`}>
            {verificationOptions.map(option => (
              <div className="theme-radio-wrapper" key={option.value}>
                <Radio value={option.value}>{option.label}</Radio>
              </div>
            ))}
          </RadioGroup>
        </FormItem>

        <FormItem
          className="theme-form-item-wrapper"
          shouldUpdate={shouldUpdateComponent}
        >
          {({ getFieldValue }) => (
            <>
              <FormItem hidden={!getFieldValue(getFieldName('selected', parents))}>
                <FullName parents={parents} />
              </FormItem>
            </>
          )}
        </FormItem>

        <div className="theme-input-group-wrapper">
          <FormItem
            noStyle
            shouldUpdate={shouldUpdateComponent}
          >
            {getSelectedComponent}
          </FormItem>
        </div>

        <FormItem hidden noStyle name="errors" />
        <ClientRegistrationActions form={form} onGoBack={goBackPersonVerification} />
      </Form>
    </div>
  );
};

PersonVerification.propTypes = {
  onFinish: func,
  onGoBack: func,
  stepKey: string.isRequired,
  subStepKey: string.isRequired,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

PersonVerification.defaultProps = {
  onFinish: () => {},
  onGoBack: () => {},
  parents: ['personVerification'],
};

export default PersonVerification;
