import { gql } from '@apollo/client';
import { INVESTMENT_FIELDS_FOR_PANEL, LOAN_FIELDS_FOR_PANEL } from '../Fragments/AccountFragments';
import { PERMISSIONS_FRAGMENT, USER_FRAGMENT, ADDRESS_FRAGMENT } from '../Fragments/Fragments';

export const ACCOUNTS = gql`
    query Accounts($uuids: [String], $search: String, $types: [AccountType]) {
        accounts(uuids: $uuids, search: $search, types: $types) {
            results {
                type
                uuid
                fieldLmsAccountId
                label
                ...on Loan {
                  loanTitle
                }
            }
        }
    }
`;

export const ACCOUNT_OPTIONS = gql`
    query Accounts(
        $client: String
        $limit: Int = 25
        $offset: Int = 0
        $search: String
        $sort: AccountsSortOrder = fieldLmsAccountId
        $sortDirection: SortDirection = DESC
        $states: [String]
        $types: [AccountType]
        $user: String
        $uuids: [String]
    ) {
        accounts(
            client: $client
            limit: $limit
            offset: $offset
            search: $search
            sort: $sort
            sortDirection: $sortDirection
            states: $states
            types: $types
            user: $user
            uuids: $uuids
        ) {
            results {
                value: uuid
                label
            }
        }
    }

`;

export const ACCOUNTS_WITH_LOAN_AND_INVESTMENT = gql`
    ${LOAN_FIELDS_FOR_PANEL}
    ${INVESTMENT_FIELDS_FOR_PANEL}
    query AccountsWithLoanAndInvestment(
        $client: String
        $limit: Int
        $offset: Int
        $user: String
        $states: [String]
        $types: [AccountType]
    ) {
        accounts(
            client: $client
            limit: $limit
            offset: $offset
            user: $user
            states: $states
            types: $types
        ) {
            total
            results {
                type
                uuid
                fieldLmsAccountId
                ... on Loan {
                  ...LoanFieldsForPanel
                }
                ... on Investment {
                  ...InvestmentFieldsForPanel
                }
            }
        }
    }
`;

export const INVESTMENT_BY_UUID = gql`
  ${PERMISSIONS_FRAGMENT}
  ${USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  query investmentQuery($uuid: String!) {
    investment(uuid: $uuid) {
      id
      borrowerRate
      commencementDate
      investmentRate
      entityPermissions {
        ...permissionsFragment
      }
      fieldAmount
      fieldCommencementDate
      fieldCommitmentDate
      fieldFundsReceived
      fieldHideForClient
      fieldLmsAccountId
      fieldRate
      fieldState
      fieldTerm
      investmentRate
      latestReductionDate
      lmsAccount
      type
      uuid
      owner {
        ...userFragment
      }
      fieldPool {
        fieldAmount
        fieldDelta
        fieldInvestmentRate
        fieldOpenDate
        fieldPoolTotal
        fieldRate
        term
        type
      }
      fieldClient {
        name
        type
        fieldBpayKey
        fieldLmsClientId
      }
      fieldLoan {
        uuid
        lastCreditAssessmentDate
        loanConditions
        repaymentsToDate
        entityPermissions {
          ...permissionsFragment
        }
        fieldAmount
        fieldCurrentRaiseAmount
        fieldInvestorReturn
        fieldLineFee
        fieldLmsAccountId
        fieldLvr
        fieldMaturityDate,
        fieldMaximumTerm
        fieldMinimumTerm
        fieldNumberOfTranches
        fieldPurpose
        fieldRisk
        fieldState
        fieldSecuritiesTotal
        loanTitle
        offerExpiryDate
        fieldLoanProduct {
          label
          fieldRepaymentReturn
        }
        currentPool {
          fieldAmount
          fieldDelta
          fieldInvestmentRate
          fieldOpenDate
          fieldPoolTotal
          fieldRate
          fundingAvailable
          term
          totalFunded
        }
        currentFundingPool {
          fieldAmount
          fieldDelta
          fieldInvestmentRate
          fieldOpenDate
          fieldPoolTotal
          fieldRate
          fundingAvailable
          term
          totalFunded
        }
        initialPool {
          fieldOpenDate
        }
        fieldPrimarySecurity {
          fieldAddress {
            ...addressFragment
          }
          fieldImages {
            propertyThumbnail
            original
          }
        }
        fieldClient {
          fieldLmsClientId
          name
          type
        }
      }
      fieldManagedFund {
        uuid
        fieldFundState
        fieldLmsPoolId
        fieldTargetRate
        fieldImages {
          propertyThumbnail
        }
      }
      reductions {
        uuid
        amountFunded
        created
        fieldAction
        fieldAmount
        fieldBreakFee
        fieldExpiry
        fieldStatus
        entityPermissions {
          ...permissionsFragment
        }
      }
    }
    documents(accountUuid: $uuid) {
      results {
        uuid
        label
        fieldMediaFiles {
          filename
          filemime
          url
          uuid
        }
      }
    }
  }
`;

export const INVESTMENTS = gql`
  query investmentsQuery(
      $amount: Range
      $loanBundle: String
      $loanUuid: String
      $showAllForClient: Boolean
      $user: String
      $search: String
      $states: [String]
      $offset: Int
      $limit: Int
      $sort: AccountsSortOrder
      $sortDirection: SortDirection
    ) {
      investments(
        amount: $amount
        loanBundle: $loanBundle
        loanUuid: $loanUuid
        showAllForClient: $showAllForClient
        user: $user
        search: $search
        states: $states
        offset: $offset
        limit: $limit
        sort: $sort
        sortDirection: $sortDirection
      ) {
        total
        results {
          uuid
          investmentRate
          displayState
          fieldAmount
          fieldLmsAccountId
          fieldState
          fieldLoan {
            uuid
            entityPermissions {
              label
              permitted
            }
            fieldAmount
            fieldLmsAccountId
            fieldState
            currentPool {
              fieldRate
              fieldInvestmentRate
              term
            }
            fieldPrimarySecurity {
              fieldAddress {
                shortAddress
                value
              }
              fieldImages {
                propertyThumbnail
                original
              }
            }
            fieldClient {
              name
            }
            fieldLoanProduct {
              label
            }
          }
          fieldManagedFund {
             uuid
              label
              fieldFundState
              fieldLmsPoolId
              fieldTargetRate
              fieldImages {
                propertyThumbnail
              }
          }
          fieldClient {
            name
          }
          fieldPool {
            fieldRate
            fieldInvestmentRate
            term
          }
          reductions {
            fieldAmount
            fieldExpiry
            fieldStatus
          }
        }
      }
    }
`;

export const LOANS = gql`
    ${LOAN_FIELDS_FOR_PANEL}
    query loansQuery(
        $amount: Range
        $client: String
        $showAllForClient: Boolean
        $showAllForLoanBook: Boolean
        $limit: Int = 25
        $offset: Int = 0
        $user: String
        $productGroups: [String]
        $search: String
        $states: [String]
    ) {
        loans(
            amount: $amount
            client: $client
            showAllForClient: $showAllForClient
            showAllForLoanBook: $showAllForLoanBook
            user: $user
            productGroups: $productGroups
            search: $search
            states: $states
            offset: $offset
            limit: $limit
        ) {
            total
            results {
                type
                uuid
                fieldLmsAccountId
                ... on Loan {
                  ...LoanFieldsForPanel
                }
            }
        }
    }
`;

export const Account = {
  ACCOUNTS,
  ACCOUNT_OPTIONS,
  INVESTMENT_BY_UUID,
  INVESTMENTS,
  LOANS,
};

export default Account;
