import React from 'react';
import { arrayOf, string } from 'prop-types';
import { useSelector } from 'react-redux';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Select from '../../../Atoms/Forms/Select/Select';
import { getAllStepEntities, getSteps } from '../../../Store/loanApplicationSlice';
import { dealerDefaultCountry } from '../../../Hooks/useLocalStorage';

const PropertyTypeField = (props) => {
  const { parents } = props;
  const isAu = dealerDefaultCountry() === 'AU';
  const steps = useSelector(getSteps);
  const allEntities = useSelector(getAllStepEntities);
  const details = allEntities?.find(item => item?.stepKey === steps?.findIndex(step => step?.title === 'Details'));
  const display = isAu && (!details || details?.application?.type !== 'business_residential__bridging_finance');
  if (!display) return null;

  return (
    <FormItem
      label="Property Type"
      name={[...parents, 'type']}
    >
      <Select options={[
        'Free standing house',
        'Semi detached house',
        'Apartment',
        'Vacant land with DA',
        'Vacant land without DA',
        'Agricultural land',
        'Commercial property',
        'Industrial property',
      ].map(item => ({ label: item, value: item }))}
      />
    </FormItem>
  );
};

PropertyTypeField.propTypes = {
  parents: arrayOf(string),
};

PropertyTypeField.defaultProps = {
  parents: ['securities', 'primary'],
};

export default PropertyTypeField;
