import React from 'react';
import { Col, Row } from 'antd';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import Text from '../../Atoms/Typography/Text/Text';
import Link from '../../Atoms/Navigation/Links/Link';

const JoinSuccess = () => (
  <Row className="theme-registration-success">
    <Col className="theme-description-wrapper" span={24}>
      <HeaderTitle
        title={`Thank you for joining the ${process.env.REACT_APP_BRAND} platform.`}
      />
    </Col>
    <Text type="small" className="align-center">
      We have sent you a confirmation email from
      {' '}
      <Link href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>{process.env.REACT_APP_BRAND_EMAIL}</Link>
      .
      {' '}
      If you don’t receive an email in the next 30 minutes, please check your spam folder and contact
      {' '}
      <Link href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>Client Services</Link>
      .
    </Text>
  </Row>
);

export default JoinSuccess;
