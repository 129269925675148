/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'antd';
import Menu from '../../../Atoms/Navigation/Menu/Menu';
import Image from '../../../Atoms/Media/Image/Image';
import Link from '../../../Atoms/Navigation/Links/Link';
import './TopMenus.less';

const TopMenus = (props) => {
  const { brand, items } = props;
  return (
    <div className="theme-top-menus">
      <Row className="theme-top-menus-contents" justify="space-between">
        <Col span={brand === 'apm' ? 3 : 2}>
          <Link to="/app">
            <Image
              alt={process.env.REACT_APP_BRAND_LEGAL_NAME}
              src={`/assets/logos/menu-logo-${brand}.svg`}
              preview={false}
            />
          </Link>
        </Col>
        <Col span={brand === 'apm' ? 9 : 10}>
          <Menu mode="horizontal" theme="dark" items={items} />
        </Col>
      </Row>
    </div>
  );
};

export default TopMenus;
