import React from 'react';
import { func, shape } from 'prop-types';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Col, Form, Row } from 'antd';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import LoanApplicationActions from '../../../../Molecules/Forms/Actions/LoanApplicationActions';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Title from '../../../../Atoms/Typography/Title/Title';
import StepBlock from '../../../../Atoms/DataDisplay/StepBlock/StepBlock';
import Select from '../../../../Atoms/Forms/Select/Select';
import Button from '../../../../Atoms/Forms/Button/Button';
import Divider from '../../../../Atoms/Layout/Divider/Divider';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import StepInfo from '../../../../Molecules/Forms/StepInfo/StepInfo';
import { getStep } from '../../../../Store/loanApplicationSlice';
import { CLIENTS } from '../../../../GraphQL/Queries/Client';
import './LoanBorrowingEntityStep.less';
import Input from '../../../../Atoms/Forms/Input/Input';
import useBrowserQuery from '../../../../Hooks/useBrowserQuery';
import Skeleton from '../../../../Atoms/Feedback/Skeleton/Skeleton';
import { userUuid } from '../../../../Store/userSlice';
import BrokerSelect from '../../../../Molecules/Inputs/BrokerSelect';
import HasPermission from '../../../../Molecules/Control/HavePermission';

const LoanBorrowingEntityStep = (props) => {
  const { initialValues, onFinish } = props;
  const clientUuid = useBrowserQuery().get('client');
  const history = useHistory();
  const { context } = useParams();
  const [form] = Form.useForm();
  const step = useSelector(getStep);
  const storedUserUuid = useSelector(userUuid);

  const { data, loading } = useQuery(CLIENTS, {
    variables: {
      user: ['borrower', 'broker'].indexOf(context) !== false ? storedUserUuid : undefined,
      roles: ['borrower'],
      verified: true,
      limit: 500,
    },
    skip: !storedUserUuid,
  });

  const options = data?.clients?.results?.map(item => ({ label: `${item?.name} (${item?.clientType})`, value: item?.uuid }));
  const clientName = clientUuid && options?.filter(item => item.value === clientUuid).pop()?.label;

  const stepBlocks = [
    {
      title: 'Application',
      dataSource: [
        'Complete a loan application form',
        'Provide supporting documents',
      ],
    },
    {
      title: 'Approval',
      dataSource: [
        'Credit assessment and valuation',
        'If approved, loan offer and acceptance',
        'Document issuance',
      ],
    },
    {
      title: 'Settlement',
      dataSource: [
        'Sign documents, satisfy conditions',
        'Register security',
        'Get funded',
      ],
    },
  ];

  return (
    <div className="theme-loan-borrowing-entity-step">
      <Title level={2}>How it works</Title>
      <Row gutter={[32, 16]} className="theme-step-block-wrapper">
        { stepBlocks?.map(item => <StepBlock key={item.title} title={item.title} dataSource={item.dataSource} />) ?? []}
      </Row>
      <Col className="gutter-row" span={12}>
        <Skeleton loading={loading} paragraph={2}>
          <Form
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              clientUuid, clientName, ...initialValues,
            }}
            form={form}
          >
            <StepInfo id={uuid()} stepKey={step} />
            <FormItem
              name="clientName"
              noStyle
              hidden
              shouldUpdate={(prev, current) => {
                if (!prev?.clientUuid
                  || (prev?.clientUuid !== current?.clientUuid)
                  || (current?.clientUuid && !current?.clientName)
                ) {
                  const value = current.clientUuid;
                  const titleForDraft = value && options.filter(item => item.value === value).pop()?.label;
                  form.setFieldValue('clientName', titleForDraft);
                  return true;
                }
                return false;
              }}
            >
              <Input />
            </FormItem>

            <FormItem
              label=" Choose a borrowing entity"
              name="clientUuid"
              rules={[{ required: true, message: 'Please select a borrowing entity' }]}
            >
              <Select options={options} placeholder="- Select -" />
            </FormItem>

            <HasPermission name={`${context} register client`}>
              <Divider />
              <Paragraph>If you would like to borrow as another entity.</Paragraph>
              <Button type="secondary" onClick={() => history.push(`/app/${context}/client/add`)}>Register another entity</Button>
            </HasPermission>

            <BrokerSelect />

            <LoanApplicationActions onClickBack={null} form={form} />
          </Form>
        </Skeleton>
      </Col>
    </div>

  );
};

LoanBorrowingEntityStep.propTypes = {
  initialValues: shape({}),
  onFinish: func,
};

LoanBorrowingEntityStep.defaultProps = {
  initialValues: {},
  onFinish: () => {},
};


export default LoanBorrowingEntityStep;
