import React from 'react';
import { Radio as AntdRadio } from 'antd';
import {
  bool, string, shape, number, func, oneOf, oneOfType, arrayOf,
} from 'prop-types';
import './RadioGroup.less';

const RadioGroup = (args) => {
  const { className, inline } = args;
  const classes = [
    'theme-radio-group',
    className !== undefined ? className : '',
    inline ? 'theme-radio-group-inline' : '',
  ];
  return <AntdRadio.Group {...args} className={classes.join(' ')} />;
};

RadioGroup.propTypes = {
  buttonStyle: oneOf(['outline', 'solid']),
  defaultValue: oneOfType([string, number]),
  disabled: bool,
  className: string,
  inline: bool,
  name: oneOfType([string, arrayOf(string)]),
  options: oneOfType([
    string,
    arrayOf(shape({
      label: string,
      value: string,
    })),
  ]),
  optionType: oneOf(['default', 'button']),
  size: oneOf(['large', 'middle', 'small']),
  value: oneOfType([string, number]),
  onChange: func,
};

RadioGroup.defaultProps = {
  buttonStyle: 'outline',
  defaultValue: undefined,
  disabled: false,
  className: undefined,
  name: 'Radio Group Name',
  inline: false,
  options: undefined,
  optionType: 'default',
  size: 'middle',
  value: undefined,
  onChange: () => {},

};

export default RadioGroup;
