import React from 'react';
import {
  bool, func, node, oneOf,
} from 'prop-types';
import { Alert as AntdAlert } from 'antd';
import './Alert.less';

const Alert = args => <AntdAlert {...args} className="theme-alert" />;

Alert.propTypes = {
  action: node,
  afterClose: func,
  banner: bool,
  closable: bool,
  closeText: node,
  description: node,
  icon: node,
  message: node,
  showIcon: bool,
  type: oneOf(['success', 'info', 'warning', 'error']),
  onClose: func,
};

Alert.defaultProps = {
  action: undefined,
  afterClose: () => {},
  banner: false,
  closable: true,
  closeText: undefined,
  description: undefined,
  icon: undefined,
  message: '',
  showIcon: true,
  type: 'warning',
  onClose: () => {},
};

export default Alert;
