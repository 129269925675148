import { gql } from '@apollo/client';

export const AUTH = gql`
    mutation auth($input: AuthInput!) {
      auth(input: $input) {
        user {
          ... on User {
            uuid
            email
            id
            roles
            registrations
            dealer
            preferredName
            broker {
              uuid
            }
          }
        }
        auth {
          token_type
          expires_in
          access_token
          refresh_token
        }
      }
    }
`;

const Auth = {
  AUTH,
};

export default Auth;
