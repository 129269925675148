import { gql } from '@apollo/client';

export const INVESTMENT_CERTIFICATE_PDF = gql`
    mutation InvestmentCertificatePdf($uuid: String!, $saveDocument: Boolean) {
        investmentCertificatePdf(uuid: $uuid, saveDocument: $saveDocument) {
            success
            message
            url
            filename
        }
    }
`;

export const INVESTMENT_STATEMENT_PDF = gql`
    mutation InvestmentStatementPdf($uuid: String!, $startDate: String, $endDate: String, $saveDocument: Boolean) {
        investmentStatementPdf(uuid: $uuid, startDate: $startDate, endDate: $endDate, saveDocument: $saveDocument) {
            success
            message
            url
            filename
        }
    }
`;

export const TAX_STATEMENT_PDF = gql`
    mutation TaxStatementPdf($uuid: String!, $startDate: String, $endDate: String, $saveDocument: Boolean) {
        taxStatementPdf(uuid: $uuid, startDate: $startDate, endDate: $endDate, saveDocument: $saveDocument) {
            success
            message
            url
            filename
        }
    }
`;

export const BULK_INVESTMENT_STATEMENTS = gql`
  mutation BulkInvestmentStatements($uuid: String!) {
    bulkInvestmentStatements(
      uuid: $uuid
    ) {
      success
      message
    }
  }
`;

export default {
  BULK_INVESTMENT_STATEMENTS,
  INVESTMENT_CERTIFICATE_PDF,
  INVESTMENT_STATEMENT_PDF,
  TAX_STATEMENT_PDF,
};
