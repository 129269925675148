import React from 'react';
import RegisterAndVerifyEntities from '../Organisms/Forms/RegisterAndVerifyEntities/RegisterAndVerifyEntities';
import ClientStructure from '../Organisms/Forms/ClientStructure/ClientStructure';
import DeclareTax from '../Organisms/Forms/DeclareTax/DeclareTax';
import VerificationOfInvestorStatus
  from '../Organisms/Forms/VerificationOfInvestorStatus/VerificationOfInvestorStatus';
import AccreditationOfIntroducerStatus
  from '../Organisms/Forms/AccreditationOfIntroducerStatus/AccreditationOfIntroducerStatus';
import UploadTrustDeed from '../Organisms/Forms/UploadTrustDeed/UploadTrustDeed';
import NzAgreeAndSubmit from '../Molecules/Forms/NzAgreeAndSubmit/NzAgreeAndSubmit';
import AttachToUser from '../Organisms/Forms/AttachToUser/AttachToUser';
import ClientReview from '../Molecules/Forms/ClientReview/ClientReview';

const mainRegistrationComponents = (
  onChange,
  onClickProceed,
  onGoBack,
  isLastStep,
  allowProceed,
  onFinish,
  onFinishLastStep,
  steps,
  step,
) => ([
  {
    name: 'set-account-structure-form',
    component: <ClientStructure
      key="set-account-structure-form"
      stepKey="set-account-structure-form"
      onChange={onChange}
      onProceed={onClickProceed}
    />,
  },
  {
    name: 'register-and-verify-entities-form',
    component: <RegisterAndVerifyEntities
      key="register-and-verify-entities-form"
      stepKey="register-and-verify-entities-form"
      onFinishLastStep={onFinishLastStep('register-and-verify-entities-form')}
      onProceed={onClickProceed}
      onGoBack={onGoBack}
      isLastStep={isLastStep('register-and-verify-entities-form')}
      allowProceed={allowProceed('register-and-verify-entities-form')}
      steps={steps[step] ? steps[step].subSteps : null}
    />,
  },
  {
    name: 'upload-trust-deed-form',
    component: <UploadTrustDeed
      key="upload-trust-deed-form"
      stepKey="upload-trust-deed-form"
      onGoBack={onGoBack}
      onFinishLastStep={onFinishLastStep('upload-trust-deed-form')}
    />,
  },
  {
    name: 'declare-tax-status-form',
    component: <DeclareTax
      key="declare-tax-status-form"
      stepKey="declare-tax-status-form"
      onProceed={onClickProceed}
      onGoBack={onGoBack}
    />,
  },
  {
    name: 'verification-of-investor-status-form',
    component: <VerificationOfInvestorStatus
      key="verification-of-investor-status-form"
      stepKey="verification-of-investor-status-form"
      onFinishLastStep={onFinishLastStep('verification-of-investor-status-form')}
      onGoBack={onGoBack}
    />,
  },
  {
    name: 'accreditation-of-introducer-status-form',
    component: <AccreditationOfIntroducerStatus
      key="accreditation-of-introducer-status-form"
      stepKey="accreditation-of-introducer-status-form"
      onFinishLastStep={onFinishLastStep('accreditation-of-introducer-status-form')}
      onGoBack={onGoBack}
    />,
  },
  {
    name: 'nz-agree-and-submit',
    component: <NzAgreeAndSubmit
      key="nz-agree-and-submit"
      stepKey="nz-agree-and-submit"
      onFinishLastStep={onFinishLastStep('nz-agree-and-submit')}
      onGoBack={onGoBack}
    />,
  },
  {
    name: 'attach-to-user',
    component: <AttachToUser
      key="attach-to-user"
      stepKey="attach-to-user"
      onFinishLastStep={onFinishLastStep('attach-to-user')}
      onGoBack={onGoBack}
    />,
  },
  {
    name: 'review',
    component: <ClientReview
      key="review"
      stepKey="review"
      onFinishLastStep={onFinishLastStep('review')}
      onGoBack={onGoBack}
    />,
  },
]);

export default mainRegistrationComponents;
