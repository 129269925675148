import React from 'react';
import Paragraph from '../Atoms/Typography/Paragraph/Paragraph';

export const OFFER_SENT_MESSAGE = {
  title: 'Loan offer sent',
  message: (
    <>
      <Paragraph>
        Your client has been sent a link to view your loan offer.
      </Paragraph>

      <Paragraph>
        You will be notified once your client has accepted or declined the offer.
      </Paragraph>
    </>
  ),
};

export const OFFER_ACCEPTED_MESSAGE = {
  title: 'Offer accepted',
  message: (
    <>
      <Paragraph>
        Your loan is now approved and you have entered into a binding Borrower Agreement with us.
        Please activate your account to view and download your agreement.
      </Paragraph>

      <Paragraph>
        We will let you know once we have matched investors to fund your loan.
      </Paragraph>

      <Paragraph>
        If you withdraw your loan after it has been fully subscribed by investors and before settlement, a Platform Recovery Fee is payable.
      </Paragraph>
    </>
  ),
};

export const OFFER_DECLINED_MESSAGE = {
  title: 'Offer declined',
  message: (
    <Paragraph>
      This offer has now been declined.
    </Paragraph>
  ),
};

export default {
  OFFER_SENT_MESSAGE,
  OFFER_ACCEPTED_MESSAGE,
  OFFER_DECLINED_MESSAGE,
};
