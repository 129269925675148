import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import InvestmentPanel from '../../Components/Common/InvestmentPanel';
import Title from '../../Atoms/Typography/Title/Title';

const LoanInvestmentsList = ({ investments, loan }) => {
  const { context } = useParams();

  if (Array.isArray(investments)) {
    const renderedInvestments = investments.map(
      investment => (
        <InvestmentPanel
          context={context}
          investment={investment}
          key={investment?.uuid}
          loan={loan}
        />
      ),
    );

    return (
      <div className="theme-loan-investments-list">
        <Title level={3}>Investments</Title>
        {renderedInvestments}
      </div>
    );
  }
  return '';
};

LoanInvestmentsList.propTypes = {
  investments: arrayOf(shape({
    uuid: string,
  })),
  loan: shape({}).isRequired,
};

LoanInvestmentsList.defaultProps = {
  investments: [],
};

export default LoanInvestmentsList;
