import React, { useContext, useState } from 'react';
import {
  arrayOf, number, oneOfType, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getCountry, getSelectedType } from '../../../Store/clientRegistrationSlice';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../Helpers/Forms';
import Select from '../../../Atoms/Forms/Select/Select';
import Link from '../../../Atoms/Navigation/Links/Link';
import Space from '../../../Atoms/Layout/Space/Space';
import Tooltip from '../../../Atoms/DataDisplay/Tooltip/Tooltip';
import Input from '../../../Atoms/Forms/Input/Input';
import countriesOptions from '../../../Resources/Countries';
import LangContext from '../../../Context/Lang/LangContext';
import DraggerFormItem from '../DraggerFormItem/DraggerFormItem';
import './TaxNZFields.less';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import useShouldUpdateFiles from '../../../Hooks/useShouldUpdateFiles';

const TaxNZFields = ({ parents }) => {
  const { t } = useContext(LangContext);
  const homeCountry = useSelector(getCountry);
  const [fileUpload, setFileUpload] = useState(false);
  const type = useSelector(getSelectedType);

  const countrySelector = (form) => {
    const { getFieldValue } = form;
    if (getFieldValue('homelandTax') !== 'No' || homeCountry !== 'NZ') {
      return null;
    }

    return (
      <FormItem
        label="Overseas country"
        name="country"
        rules={[{ required: true, message: t('validation.required_option') }]}
      >
        <Select
          showSearch
          placeholder="Select or search"
          options={countriesOptions.filter(item => (item.value !== homeCountry))}
        />
      </FormItem>
    );
  };

  const rwtRate = ({ getFieldValue }) => {
    const homelandTax = getFieldValue('homelandTax');

    if (!homelandTax || homelandTax === 'No') return null;

    const defaultTaxRate = [
      { label: '10.5%', value: 'R10.5' },
      { label: '17.5%', value: 'R17.5' },
      { label: '30%', value: 'R30' },
      { label: '33%', value: 'R33' },
      { label: '39%', value: 'R39' },
      { label: 'Certificate of Exemption', value: 'RE' },
    ];

    const companyTaxRate = [
      { label: '28%', value: 'RC28' },
      { label: '33%', value: 'RC33' },
      { label: '39%', value: 'RC39' },
      { label: 'Certificate of Exemption', value: 'RE' },
    ];

    const shouldUpdateComponent = (prev, current) => {
      const { errors: prevErrors, tax: prevTax } = prev;
      const { errors: currentErrors, tax: currentTax } = current;
      return !prev
        || (prev && prev.rwtRate !== current.rwtRate)
        || useShouldUpdateFiles(prevTax, currentTax, 'certificateOfExemption')
        || prevErrors !== currentErrors;
    };

    return (
      <Space direction="vertical">
        <FormItem
          className="theme-rwt-form-item"
          label="RWT Rate"
          name={getFieldName('rwtRate', parents)}
          rules={[{ required: true, message: t('validation.required_option') }]}
        >
          <Select
            placeholder="Select a RWT rate"
            options={type === 'company' ? companyTaxRate : defaultTaxRate}
            onChange={item => setFileUpload(item === 'RE')}
          />
        </FormItem>
        <Tooltip title="Useful information">
          <Link
            label="Check your RWT rate"
            href="https://www.ird.govt.nz/income-tax/withholding-taxes/resident-withholding-tax-rwt/using-the-right-rwt-tax-rate"
            target="_blank"
            title="Check your RWT rate"
          >
            Check your RWT rate
          </Link>
        </Tooltip>
        { (fileUpload || getFieldValue('rwtRate') === 'RE') && (
          <DraggerFormItem
            comment="Please upload a copy of your Certificate of Exemption"
            fieldName={getFieldName('certificateOfExemption', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Tax certificate document')]}
            label="Certificate of Exemption"
            shouldUpdateComponent={shouldUpdateComponent}
          />
        ) }
      </Space>
    );
  };

  const nrwtRate = ({ getFieldValue }) => {
    const homelandTax = getFieldValue('homelandTax');

    if (!homelandTax || homelandTax === 'Yes') return null;
    if (!getFieldValue('country')) return null;

    return (
      <Space align="center">
        <FormItem
          label="NRWT Rate"
          name={getFieldName('nrwtRate', parents)}
          rules={[{ required: true, message: t('validation.required_option') }]}
        >
          <Select
            placeholder="Select"
            options={[
              { label: '10%', value: 'NR10' },
              { label: '15%', value: 'NR15' },
            ]}
          />
        </FormItem>
        <Tooltip title="Useful information">
          <Link
            label="Check your NRWT rate"
            href="https://www.ird.govt.nz/income-tax/withholding-taxes/non-resident-withholding-tax-nrwt/deduct-nrwt-at-the-right-rate/nrwt-rates-for-dta-countries"
            target="_blank"
            title="Check your RWT rate"
          >
            Check your NRWT rate
          </Link>
        </Tooltip>
      </Space>
    );
  };

  return (
    <div className="theme-tax-nz-fields">
      <FormItem
        noStyle
        shouldUpdate={(previous, next) => previous.country === next.country}
      >
        { rwtRate }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(previous, next) => (previous.country === next.country || previous.homelandTax !== next.homelandTax)}
      >
        { countrySelector }
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(previous, next) => (previous.country === next.country || previous.homelandTax !== next.homelandTax)}
      >
        { nrwtRate }
      </FormItem>
      <FormItem
        label="IRD Number"
        name={getFieldName('ird', parents)}
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <Input />
      </FormItem>
    </div>
  );
};

TaxNZFields.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

TaxNZFields.defaultProps = {
  parents: ['tax'],
};

export default TaxNZFields;
