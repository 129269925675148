import React from 'react';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import InvestmentStatementOrganism from '../../../Organisms/Reporting/InvestmentStatement/InvestmentStatement';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentStatement = () => (
  <>
    <HeaderTitle
      title={`Reporting / ${brandLabelConfig(dealer(), 'reportingInvestmentStatements', 'Investment Statements')}`}
      comment="Generate and manage your reports."
    />
    <InvestmentStatementOrganism />
  </>
);

export default InvestmentStatement;
