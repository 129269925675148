import React from 'react';
import { useParams } from 'react-router-dom';
import { shape, node } from 'prop-types';
import SectionTitle from '../../Molecules/DataDisplay/Sections/SectionTitle';
import ClientListItem from '../../Molecules/ViewModes/Clients/ClientListItem';
import './RelatedClients.less';

const RelatedClients = ({ organisations, children }) => {
  const { context } = useParams();

  if (!organisations?.length) {
    return null;
  }

  return (
    <div className="theme-related-clients">
      <SectionTitle title={`Related ${organisations?.length > 1 ? 'entities' : 'entity'}`} level={3} />
      {organisations?.map(client => (
        <ClientListItem key={client.uuid} client={client} context={context} />
      ))}
      { !organisations && null }
      {children}
    </div>
  );
};

RelatedClients.propTypes = {
  organisations: shape([]).isRequired,
  children: node,
};

RelatedClients.defaultProps = {
  children: null,
};

export default RelatedClients;
