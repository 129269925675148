import React, { useContext } from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getCountry } from '../../../../Store/clientRegistrationSlice';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../../Helpers/Forms';
import Input from '../../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import LangContext from '../../../../Context/Lang/LangContext';
import Select from '../../../../Atoms/Forms/Select/Select';
import config from '../../../../Resources/AddressConfigs';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';

const AuDriverLicence = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => {
  const { t } = useContext(LangContext);
  const country = useSelector(getCountry) || 'AU';
  const { softRequired, customValidation, validateStatus } = useValidationRules;

  const cardNumberRequired = (val, form) => {
    const auState = form.getFieldValue([...parents, 'licenceState']);
    if (auState === 'QLD' || auState === 'VIC' || auState === 'WA') {
      return false;
    }
    return !val;
  };

  return (
    <>
      <InputGroup className="theme-driver-licence">
        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev.errors !== current.errors}
        >
          {({ getFieldValue }) => (
            <FormItem
              label={t('entity.person.drivers_licence.number')}
              name={getFieldName('licenceNumber', parents)}
              rules={[item => softRequired(item, 'Driver\'s licence number', deleteExcept)]}
              validateStatus={useValidationRules.validateStatus(getFieldName('licenceNumber', parents), getFieldValue('errors'))}
            >
              <Input />
            </FormItem>
          )}
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev.errors !== current.errors}
        >
          {({ getFieldValue }) => (
            <FormItem
              label={t('entity.person.drivers_licence.card_number')}
              name={getFieldName('cardNumber', parents)}
              rules={[item => customValidation(item, 'Card number', cardNumberRequired, deleteExcept)]}
              validateStatus={useValidationRules.validateStatus(getFieldName('cardNumber', parents), getFieldValue('errors'))}
            >
              <Input />
            </FormItem>
          )}
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev.errors !== current.errors}
        >
          {({ getFieldValue }) => (
            <FormItem
              label={t('entity.person.drivers_licence.state_of_issue')}
              name={getFieldName('licenceState', parents)}
              rules={[item => softRequired(item, 'Driver\'s licence state', deleteExcept)]}
              validateStatus={validateStatus(getFieldName('licenceState', parents), getFieldValue('errors'))}
            >
              <Select
                showSearch
                placeholder="Select or search"
                options={config.find(item => item.countryCode === country).spec.administrative_area.options}
              />
            </FormItem>
          )}
        </FormItem>
      </InputGroup>

      <DraggerFormItem
        comment="Please upload a copy of your drivers licence."
        fieldName={getFieldName('driverLicenceDocument', parents)}
        label="Upload identification"
        rules={[item => softRequired(item, 'Driver\'s licence document', deleteExcept)]}
        shouldUpdateComponent={shouldUpdateComponent}
      />
    </>
  );
};

AuDriverLicence.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

AuDriverLicence.defaultProps = {
  parents: ['au_driver_licence'],
  deleteExcept: [
    'personVerification.licenceNumber',
    'personVerification.licenceState',
    'personVerification.cardNumber',
    'personVerification.driverLicenceDocument',
  ],
};

export default AuDriverLicence;
