import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOAN_FACETS } from '../GraphQL/Queries/Loan';

const loanProductGroups = {
  pr: 'Personal residential',
  br: 'Business residential',
  bc: 'Business commercial',
  pl: 'Personal loan',
  ru: 'Rural',
};

const useLoanProductGroups = (states) => {
  const { data } = useQuery(LOAN_FACETS, {
    variables: { states },
  });
  const [facets, setFacets] = useState([]);

  useEffect(() => {
    const newFacets = data?.loans?.facets
      ?.filter(item => loanProductGroups[item.name] !== undefined && item.count > 0)
      ?.map(item => ({ label: loanProductGroups[item.name], value: item.name }))
    ?? [];

    setFacets(newFacets);
  }, [data]);

  return { data: facets };
};

export default useLoanProductGroups;
