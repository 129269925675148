export const calculateBreakFee = (amount) => {
  let fee;
  if (amount < 5000 && amount > 0) {
    fee = 75;
  } else if (amount >= 5000 && amount <= 19999.99) {
    fee = 125;
  } else if (amount >= 20000 && amount <= 99999.99) {
    fee = 200;
  } else if (amount > 99999.99) {
    fee = 300;
  } else {
    fee = 0;
  }
  return fee;
};

export const calculateBreakFeeWithState = (amount, stateCallback) => {
  const fee = calculateBreakFee(amount);
  stateCallback(fee);
  return fee;
};
