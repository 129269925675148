import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { getCountry } from '../../../Store/clientRegistrationSlice';
import MaskedInput from '../../../Atoms/Forms/Input/MaskedInput';
import Input from '../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import './Company.less';
import LangContext from '../../../Context/Lang/LangContext';
import ParentId from '../ParentId/ParentId';
import EntityType from '../EntityType/EntityType';

const Company = ({ parentId }) => {
  const { t } = useContext(LangContext);
  const country = useSelector(getCountry);
  const requiredRule = { required: true, message: t('validation.required', { attribute: 'field' }) };
  const isAu = country === 'AU';

  return (
    <div className="theme-company">
      <EntityType type="client" bundle="company" />
      {parentId && (<ParentId parentId={parentId} />)}

      <FormItem
        label="Company name"
        name="name"
        rules={[requiredRule]}
      >
        <Input />
      </FormItem>

      <InputGroup>
        <FormItem
          label={isAu ? 'ACN (or Company Registration Number)' : 'Company number'}
          name="companyNumber"
          rules={[requiredRule]}
        >
          { isAu ? (<MaskedInput mask="000000000" maskOptions={{ lazy: true, placeholderChar: '0' }} />) : (<Input />) }
        </FormItem>

        <FormItem
          label={isAu ? 'ABN' : 'NZBN'}
          name="businessNumber"
        >
          <MaskedInput mask={isAu ? '00000000000' : '0000000000000'} maskOptions={{ lazy: true, placeholderChar: '0' }} />
        </FormItem>

      </InputGroup>
    </div>
  );
};

Company.propTypes = {
  parentId: string,
};

Company.defaultProps = {
  parentId: '',
};

export default Company;
