import React from 'react';
import { func, string } from 'prop-types';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Button from '../../../../Atoms/Forms/Button/Button';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import useNestedDrawer from '../../../../Hooks/useNestedDrawer';
import UpdatePersonVerification from '../../../Forms/UpdatePersonVerification/UpdatePersonVerification';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import { CLIENT_IDENTIFICATION } from '../../../../GraphQL/Queries/Client';
import Skeleton from '../../../../Atoms/Feedback/Skeleton/Skeleton';
import './EditIdentityDetailsDrawer.less';

const EditIdentityDetailsDrawer = ({ clientUuid, onSuccess }) => {
  const { context } = useParams();
  const { loading, data } = useQuery(CLIENT_IDENTIFICATION, { variables: { clientUuid } });
  const action = data?.verified ? 'View' : 'Edit';
  const selected = data?.clientIdentification?.identification?.type?.replaceAll('_', '-');
  const {
    visible, setVisible, success, width,
  } = useNestedDrawer({ onSuccess });

  if (['borrower', 'broker', 'investor']?.includes(context)) return null;

  return (
    <Row className="drawer-button">
      <Button type="primary" onClick={() => setVisible(true)}>
        {`${action} identity details`}
      </Button>

      <Drawer
        title={`${action} identity`}
        width={width}
        closable
        onClose={() => setVisible(false)}
        open={visible}
        className="theme-edit-identity-details-drawer"
      >
        <Skeleton paragraph={1} loading={loading}>
          <HeaderTitle title="Identity details">
            <Paragraph>Amend the identity details for this client.</Paragraph>
          </HeaderTitle>

          <UpdatePersonVerification
            identity={{
              clientUuid, selected, ...data?.clientIdentification?.identification,
            }}
            files={data?.clientIdentification?.files}
            onSuccess={success}
            parents={[]}
          />
        </Skeleton>
      </Drawer>
    </Row>
  );
};

EditIdentityDetailsDrawer.propTypes = {
  clientUuid: string.isRequired,
  onSuccess: func.isRequired,
};

export default EditIdentityDetailsDrawer;
