import { bool, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import {
  USER_REFRESH_ONETIME_LOGIN_LINK,
} from '../../../GraphQL/Mutations/User';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import './ExpiredOneTimeLoginLink.less';
import Title from '../../../Atoms/Typography/Title/Title';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import Divider from '../../../Atoms/Layout/Divider/Divider';
import Image from '../../../Atoms/Media/Image/Image';

const ExpiredOneTimeLoginLink = ({ token, logo: { alt, preview } }) => {
  const [theMessage, setTheMessage] = useState('Request new activation link');
  const [disabledButton, setDisabledButton] = useState(false);
  const [userRefreshLoginLink, { loading: mutationLoading }] = useMutation(USER_REFRESH_ONETIME_LOGIN_LINK);
  const logoSrc = `/assets/logos/logo-${process.env.REACT_APP_X_DEALER}.svg`;

  const handleSubmit = () => {
    userRefreshLoginLink({ variables: { token } })
      .then(() => {
        setTheMessage('A new link was emailed to you.');
        setDisabledButton(true);
      })
      .catch(mutationError => setTheMessage(getGraphQlErrorMessage(mutationError)));
  };

  return (
    <div className="theme-activate-login theme-expired-login-link ant-result">
      <Image alt={alt} src={logoSrc} preview={preview} />
      <Title level={1}>Activation link expired</Title>

      <Paragraph>Your one time activation link has expired.</Paragraph>
      <Button
        block
        disabled={disabledButton}
        loading={mutationLoading}
        type="primary"
        onClick={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
      >
        {!mutationLoading ? theMessage : 'Processing'}
      </Button>

      <Divider type="dashed" />

      <Paragraph>Have an account already?</Paragraph>
      <Button block href="/app/login" ghost type="primary">
        Go to the login page
      </Button>
    </div>
  );
};

ExpiredOneTimeLoginLink.propTypes = {
  token: string.isRequired,
  logo: shape({
    alt: string,
    preview: bool,
  }),
};

ExpiredOneTimeLoginLink.defaultProps = {
  logo: {
    alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
    preview: false,
  },
};

export default ExpiredOneTimeLoginLink;
