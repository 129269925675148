import { useParams } from 'react-router-dom';
import { dealer } from './useLocalStorage';

const useClientVerification = ({ client }) => {
  const { context } = useParams();

  let matchingContexts = [context];
  if (context === 'dealer') {
    matchingContexts = ['borrower', 'investor'];
  }
  if (context === 'broker') {
    matchingContexts.push('borrower');
  }

  const isVerified = client?.acting?.reduce(
    (carry, entity) => {
      if (context === 'broker') {
        if (dealer() === 'zagga') {
          return carry || entity?.fieldBroker?.fieldAccredited;
        }
        return carry || entity.fieldBroker?.fieldVerified;
      }
      return carry || (matchingContexts.indexOf(entity.role) !== -1 && entity.fieldVerified);
    },
    false,
  );

  return { isVerified };
};

useClientVerification.propTypes = {

};

export default useClientVerification;
