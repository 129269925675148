import React from 'react';
import { string, shape, node } from 'prop-types';
import SectionTitle from '../../Molecules/DataDisplay/Sections/SectionTitle';
import ClientDetails from '../Cards/Clients/ClientDetails';
import ClientViewLink from '../../Molecules/Navigation/Links/ClientViewLink';
import './Members.less';

const Members = ({ members, title, children }) => {
  if (!members) {
    return null;
  }

  return (
    <div className="theme-members">
      <SectionTitle title={title} level={3} />
      {members?.map(member => (
        <ClientDetails
          key={member?.uuid}
          title={member?.name}
          client={member}
          operations={<ClientViewLink uuid={member?.uuid} />}
          hideFieldsByKeys={['entityType']}
        />
      ))}
      { !members && null }
      {children}
    </div>
  );
};

Members.propTypes = {
  title: string,
  members: shape([]).isRequired,
  children: node,
};

Members.defaultProps = {
  title: 'Member details',
  children: null,
};

export default Members;
