import { gql } from '@apollo/client';

export const ACCOUNT_DOCUMENTS = gql`
  query documents(
    $accountUuid: String!
    $categories: [String]
  ) {
    documents(
      accountUuid: $accountUuid
      categories: $categories
    ) {
      results {
        uuid
        label
        fieldMediaFiles {
          filename
          filemime
          url
          uuid
        }
      }
    }
  }
`;

export const Documents = {
  ACCOUNT_DOCUMENTS,
};

export default Documents;
