import React, { useState } from 'react';
import { func, string, shape } from 'prop-types';
import { Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../../Atoms/Forms/Button/Button';
import LoanEditForm from '../../../Forms/Accounts/Loans/LoanEditForm';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import { capitalize } from '../../../../Helpers/Helpers';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import InvestmentEditForm from '../../../../Molecules/Forms/InvestmentEditForm/InvestmentEditForm';

const AccountEdit = ({ account, entityType, success }) => {
  const [accountEditVisible, setAccountEditVisible] = useState(false);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const onSuccess = () => {
    setAccountEditVisible(false);
    success();
  };

  const forms = {
    loan: <LoanEditForm onSuccess={onSuccess} entityData={account} />,
    investment: <InvestmentEditForm onSuccess={onSuccess} entityData={account} />,
  };

  return (
    <>
      <Row className="drawer-button">
        <Button type="primary" onClick={() => setAccountEditVisible(true)}>
          Edit
        </Button>
      </Row>
      <Drawer
        title={`${capitalize(entityType)} edit`}
        width={isMdScreen ? '80vw' : '40vw'}
        closable
        onClose={() => setAccountEditVisible(false)}
        open={accountEditVisible}
      >
        <div className="sections loan-edit">
          <div className="section">
            <HeaderTitle
              title={`Edit ${entityType} settings`}
              comment={(
                <Paragraph>
                  Amend the platform settings for this
                  {' '}
                  { entityType }
                  .
                </Paragraph>
              )}
            />
            { forms[entityType] }
          </div>
        </div>
      </Drawer>
    </>
  );
};

AccountEdit.propTypes = {
  account: shape({
    uuid: string.isRequired,
  }).isRequired,
  entityType: string.isRequired,
  success: func,
};

AccountEdit.defaultProps = {
  success: () => {},
};

export default AccountEdit;
