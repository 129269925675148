import { number, bool, oneOf } from 'prop-types';
import NumberFormat from 'react-number-format';
import React from 'react';
import { countDecimals } from '../../../Hooks/useNumberFormatter';

const Percentage = ({
  value, showEmpty, minDecimals, maxDecimals, displayType,
}) => {
  // Always show minimum number of decimals, while avoiding any floating point
  // math issues. Eg: defaults will show 7 as 7.0%, and 6.2499999 as 6.25%.
  const higherOf = (first, second) => (first > second ? first : second);
  const lowerOf = (first, second) => (first < second ? first : second);
  const decimals = lowerOf(higherOf(countDecimals(value || 0), minDecimals), maxDecimals);

  return (
    (value || showEmpty) && (
      <NumberFormat
        allowNegative
        suffix="%"
        displayType={displayType}
        thousandSeparator
        decimalScale={decimals}
        fixedDecimalScale
        value={parseFloat(value) || 0}
      />
    )) || null;
};

Percentage.propTypes = {
  displayType: oneOf(['text', 'input']),
  maxDecimals: number,
  minDecimals: number,
  showEmpty: bool,
  value: number,
};

Percentage.defaultProps = {
  displayType: 'text',
  maxDecimals: 2,
  minDecimals: 1,
  showEmpty: false,
  value: null,
};

export default Percentage;
