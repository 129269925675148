import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  OFFER_ACCEPT, OFFER_DECLINE, OFFER_SEND,
} from '../../../GraphQL/Mutations/Loan';
import LoanOfferSent from '../../../Molecules/Forms/Offer/LoanOfferSent';
import LoanOfferDeclined from '../../../Molecules/Forms/Offer/LoanOfferDeclined';
import LoanOfferAccepted from '../../../Molecules/Forms/Offer/LoanOfferAccepted';
import OfferCheckboxes from '../../../Molecules/Forms/Offer/OfferCheckboxes';
import OfferSubmit from '../../../Molecules/Forms/Offer/OfferSubmit';
import {
  defaultPlatformPaymentType,
  platformPaymentType as _platformPaymentType,
} from '../../../Helpers/lmsAccountShortcuts';
import { dealer } from '../../../Hooks/useLocalStorage';

const Offer = ({
  loanUuid,
  lmsAccount,
}) => {
  const { context } = useParams();
  const isBorrowerPath = !context || context === 'borrower';
  const isBrokerPath = context === 'broker';
  const isDealerPath = context === 'dealer';

  const [showSentMessage, setShowSentMessage] = useState(false);
  const [showAcceptedMessage, setShowAcceptedMessage] = useState(false);
  const [showDeclinedMessage, setShowDeclinedMessage] = useState(false);

  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState(!(context === 'borrower'));
  const [SendOffer, { loading: sendLoading }] = useMutation(OFFER_SEND);
  const [AcceptOffer, { loading: acceptLoading }] = useMutation(OFFER_ACCEPT);
  const [DeclineOffer, { loading: declineLoading }] = useMutation(OFFER_DECLINE);

  const platformPaymentType = _platformPaymentType(lmsAccount) || defaultPlatformPaymentType(dealer());

  const modalClose = () => {
    setShowSentMessage(false);
    setShowAcceptedMessage(false);
    setShowDeclinedMessage(false);
    switch (context) {
      case 'dealer':
        window.location.href = '/app/dealer/loans';
        break;

      default:
        window.location.href = `/app/${context}/applications`;
    }
  };

  const acceptOffer = () => {
    AcceptOffer({ variables: { uuid: loanUuid, platformPaymentType } })
      .then(() => setShowAcceptedMessage(true));
  };

  const sendOffer = () => {
    SendOffer({ variables: { uuid: loanUuid, platformPaymentType } })
      .then(() => setShowSentMessage(true));
  };

  const declineOffer = () => {
    DeclineOffer({ variables: { uuid: loanUuid, role: context } })
      .then(() => setShowDeclinedMessage(true));
  };

  return (
    <>
      {isBorrowerPath && (
        <OfferCheckboxes allCheckedCallback={value => setAllCheckboxesChecked(value)} />
      )}

      <OfferSubmit
        acceptLoading={acceptLoading}
        acceptOffer={acceptOffer}
        canSend={isBrokerPath || isDealerPath}
        canAccept={isBorrowerPath}
        canDecline={isBorrowerPath || isBrokerPath}
        declineLoading={declineLoading}
        declineOffer={declineOffer}
        sendLoading={sendLoading}
        sendOffer={sendOffer}
        termsAccepted={allCheckboxesChecked}
      />

      {showSentMessage && (<LoanOfferSent modalClose={modalClose} />)}
      {showAcceptedMessage && (<LoanOfferAccepted modalClose={modalClose} />)}
      {showDeclinedMessage && (<LoanOfferDeclined modalClose={modalClose} />)}
    </>
  );
};

Offer.propTypes = {
  loanUuid: string.isRequired,
  lmsAccount: shape({
    platform_payment_type: string,
  }).isRequired,
};

export default Offer;
