import React from 'react';
import {
  number, oneOf, string, func, bool,
} from 'prop-types';
import NumberFormat from 'react-number-format';

const Currency = ({
  accountingFormat, allowNegative, decimals, displayType, onValueChange, prefix, showEmpty, value,
}) => {
  const workingValue = parseFloat(value) || 0;
  const usePrefix = (accountingFormat && workingValue < 0) ? `(${prefix}` : prefix;
  const useSuffix = (accountingFormat && workingValue < 0) ? ')' : '';
  const useValue = (accountingFormat && workingValue < 0) ? (0 - workingValue) : workingValue;

  return (
    ((value || showEmpty) && (
      <NumberFormat
        prefix={usePrefix}
        suffix={useSuffix}
        thousandSeparator
        displayType={displayType}
        decimalScale={decimals}
        fixedDecimalScale
        value={useValue}
        onValueChange={onValueChange}
        allowNegative={allowNegative}
      />
    )) || null
  );
};

Currency.propTypes = {
  accountingFormat: bool,
  allowNegative: bool,
  decimals: number,
  displayType: oneOf(['text', 'input']),
  onValueChange: func,
  prefix: string,
  showEmpty: bool,
  value: number.isRequired,
};

Currency.defaultProps = {
  accountingFormat: false,
  allowNegative: true,
  decimals: 0,
  displayType: 'text',
  onValueChange: () => {},
  prefix: '$',
  showEmpty: true,
};

export default Currency;
