import React from 'react';
import { shape, bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Text from '../../../Atoms/Typography/Text/Text';
import './IdentityField.less';
import useClientVerification from '../../../Hooks/useClientVerification';

const VerifiedField = ({ client }) => {
  const { isVerified } = useClientVerification({ client });
  if (!isVerified) return null;

  return (
    <div className="theme-identity-field">
      <Text type="label">
        Verified
        {' '}
        <FontAwesomeIcon icon={faCheckCircle} />
      </Text>
    </div>
  );
};

VerifiedField.propTypes = {
  client: shape({
    fieldIdentification: shape({
      type: string,
      fieldVerified: bool,
    }),
  }).isRequired,
};

export default VerifiedField;
