import React, { useEffect } from 'react';
import LoanFilter, { useDebouncedLoanFilter, loanListGetLoans } from './Components/LoanFilter';
import NoResult from '../../Molecules/NoResult/NoResult';
import LoanList from './Components/LoanList';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import useLoanProductGroups from '../../Hooks/useLoanProductGroups';

const Loans = (props) => {
  const { match: { params: { context } } } = props;
  const { data: searchProductOptions } = useLoanProductGroups(
    context !== 'dealer' ? [
      'live',
      'live-subscribed',
      'live-complete',
      'closed_pending',
      'backfill',
    ] : [],
  );

  const showAllForClient = (context !== 'borrower') && (context !== 'broker');

  const {
    searchProductGroup,
    searchState,
    searchText,
    pageNumber,
    pageSize,
    setContext,
    setSearchText,
    setSearchProductGroup,
    setSearchState,
    setPageNumber,
    search,
  } = useDebouncedLoanFilter(loanListGetLoans, showAllForClient, true);

  useEffect(() => {
    setContext(context);
  }, [context, setContext]);


  const setLimitedSearchState = (value) => {
    setSearchState(value);
    if (!value && context !== 'dealer') {
      setSearchState('live,live-subscribed,live-complete,closed_pending,backfill');
    }
  };

  const clearFilter = () => {
    setSearchText('');
    setSearchProductGroup('');
    setLimitedSearchState('');
  };

  const noResult = (
    <NoResult
      title="No loans found."
      body={(context !== 'borrower' && 'Please try a different filter.')}
    />
  );

  let pageDescription = 'Below is a list of active loans.';
  if (context === 'dealer') {
    pageDescription = 'Below is a list of all loans.';
  }

  return (
    <div className="theme-loans page-inner">
      <HeaderTitle
        title="Loans"
        comment={pageDescription}

      />
      <LoanFilter
        clearFilter={clearFilter}
        context={context}
        setSearchText={setSearchText}
        setSearchProductGroup={setSearchProductGroup}
        setSearchState={setLimitedSearchState}
        setPageNumber={setPageNumber}
        searchProductGroup={searchProductGroup}
        searchProductOptions={searchProductOptions}
        searchState={searchState}
        searchText={searchText}
      />
      <LoanList
        context={context}
        noResult={noResult}
        page={pageNumber}
        pageSize={pageSize}
        rateFieldName="fieldRate"
        search={search}
        setPage={setPageNumber}
      />
    </div>
  );
};

export default Loans;
