import axios from '../Vendor/Axios';
import apiGraphQlService from '../Helpers/ApiGraphQLService';
import {
  axiosParams,
  eventingParams,
} from '../Helpers/Helpers';
import { getCsrfToken } from './Auth.service';

export const loanFragmentForPanel = `
  uuid
  entityPermissions {
    label
    permitted
  }
  fieldAmount
  fieldLmsAccountId
  fieldState
  currentPool {
    fieldRate
    fieldInvestmentRate
    term
  }
  fieldPrimarySecurity {
    fieldAddress {
      shortAddress
      value
    }
    fieldImages {
      propertyThumbnail
      original
    }
  }
  fieldClient {
    name
  }
  fieldLoanProduct {
    label
  }
  loanTitle
`;

export const fundFragmentForPanel = `
  uuid
  label
  fieldFundState
  fieldLmsPoolId
  fieldTargetRate
  fieldImages {
    propertyThumbnail
  }
`;

export const investmentFragmentForPanel = `
  uuid
  investmentRate
  displayState
  fieldAmount
  fieldLmsAccountId
  fieldState
  fieldLoan {
    ${loanFragmentForPanel}
  }
  fieldManagedFund {
    ${fundFragmentForPanel}
  }
  fieldClient {
    name
  }
  fieldPool {
    fieldRate
    fieldInvestmentRate
    term
  }
  reductions {
    fieldAmount
    fieldExpiry
    fieldStatus
  }
`;

function accountsQuery(filters = '', page = 0, pageSize = 25) {
  const variables = filters;
  variables.offset = page;
  variables.limit = pageSize;

  const query = `
    query accountsQuery(
      $client: String
      $limit: Int
      $offset: Int
      $user: String
      $states: [String]
    ) {
      accounts(
        client: $client
        limit: $limit
        offset: $offset
        user: $user
        states: $states
      ) {
        total
        results {
          type
          uuid
          fieldLmsAccountId
          ... on Loan {
            ${loanFragmentForPanel}
          }
          ... on Investment {
            ${investmentFragmentForPanel}
          }
        }
      }
    }
  `;
  return apiGraphQlService.persistedQuery(query, variables);
}

function accountsLookup(search) {
  const query = `
    query accountsQuery(
      $search: String
    ) {
      accounts(
        search: $search
        sort: created
        sortDirection: ASC
      ) {
        results {
          type
          uuid
          fieldLmsAccountId
          label
        }
      }
    }
  `;
  return apiGraphQlService.persistedQuery(query, { search, offset: 0, limit: 10 });
}

function getAccounts(client = null, context = null, isCurrent = false) {
  const filters = { client };
  if (context !== 'investor' && context !== 'dealer') {
    filters.states = ['pending', 'assessed', 'funding', 'live', 'live-subscribed', 'live-complete', 'closed_pending', 'backfill'];
  }
  return accountsQuery(filters, 0, -1, isCurrent);
}

async function update(id, data) {
  const token = await getCsrfToken();
  return axios.patch(`/account/${id}`, data, {
    headers: {
      'X-CSRF-Token': token,
    },
  })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(console.log(error)));
}

function acceptOffer(accountUuid, paymentType, postSubmit = null) {
  return axios
    .post(`/eventing/lendme_dealer_accept_offer${eventingParams()}`,
      {
        // eslint-disable-next-line
        account_uuid: accountUuid,
        platform_payment_type: paymentType,
      })
    .then((response) => {
      const { data } = response;
      if (typeof postSubmit === 'function') {
        postSubmit(response);
      }
      return Promise.resolve(data);
    });
}

function sendOffer(accountLmsId, selectedValue, postSubmit = null, errorHandler = null) {
  return axios
    .post(`/eventing/lendme_dealer_broker_send_offer${eventingParams()}`,
      {
        // eslint-disable-next-line
        account_id: accountLmsId,
        // eslint-disable-next-line
        platform_payment_type: selectedValue,
      })
    .then((response) => {
      const { data } = response;
      if (typeof postSubmit === 'function') {
        postSubmit(response);
      }
      return Promise.resolve(data);
    })
    .catch((error) => {
      if (typeof errorHandler === 'function') {
        errorHandler(error);
      }
    });
}

function sendToDealer(accountUuid, dealerKey) {
  return axios
    .post(`/api/loan-dealer-association${axiosParams()}`, {
      accountUuid,
      fromDealerKey: process.env.REACT_APP_X_DEALER,
      toDealerKey: dealerKey,
    })
    .then((response) => {
      const { data } = response;
      return Promise.resolve(data);
    });
}

function declineOffer(accountUuid, paymentType, event, postSubmit = null) {
  return axios
  // eslint-disable-next-line
      .post(`/eventing/lendme_dealer_${event}_decline_offer${eventingParams()}`,
      {
        // eslint-disable-next-line
        account_uuid: accountUuid,
        platform_payment_type: paymentType,
      })
    .then((response) => {
      const { data } = response;
      if (typeof postSubmit === 'function') {
        postSubmit(response);
      }
      return Promise.resolve(data);
    });
}

function declineOpportunity(loanUuid, userId, postSubmit = null) {
  return axios
    .post(`/api/opportunity-decline${axiosParams()}`,
      {
        loanUuid,
        uid: userId,
      })
    .then((response) => {
      if (typeof postSubmit === 'function') {
        postSubmit(response);
      }
    });
}

const accountService = {
  acceptOffer,
  accountsLookup,
  accountsQuery,
  declineOffer,
  declineOpportunity,
  getAccounts,
  sendOffer,
  sendToDealer,
  update,
};

export default accountService;
