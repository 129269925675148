import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import Table from '../../Atoms/DataDisplay/Table/Table';
import { titleCase } from '../../Hooks/useStringFormatter';
import { REFERRALS } from '../../GraphQL/Queries/Referral';
import { isDealer as _isDealer, userUuid as _userUuid } from '../../Store/userSlice';
import { formatUnixDate } from '../../Helpers/FormatDate';
import Result from '../../Atoms/Feedback/Result/Result';

import InfiniteScroll from '../../Molecules/InfiniteScroll';

const ReferralsTable = () => {
  const uuid = useSelector(_userUuid);
  const isDealer = useSelector(_isDealer);
  const limit = 25;
  const {
    loading, error, data, fetchMore,
  } = useQuery(REFERRALS, {
    variables: {
      offset: 0, limit, sort: 'created', sortDirection: 'DESC', ...((!isDealer && { uuid }) || {}),
    },
  });
  const isSmScreen = useMediaQuery({ query: '(max-width: 480px)' });
  const columns = [
    {
      title: 'Name',
      align: 'left',
      responsive: ['xs'],
      render: record => (
        <>
          {record.fieldFirstName}
          {' '}
          {record.fieldLastName}
        </>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'fieldFirstName',
      render: value => titleCase(value),
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'Last Name',
      dataIndex: 'fieldLastName',
      render: value => titleCase(value),
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'Contact',
      align: 'left',
      responsive: ['xs'],
      render: record => (
        <>
          {record.fieldEmail.toLowerCase()}
          <br />
          {record.fieldMobile}
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'fieldEmail',
      render: value => value.toLowerCase(),
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'Phone',
      dataIndex: 'fieldMobile',
      render: value => value,
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'Roles',
      dataIndex: 'fieldReferredUser',
      render: value => value?.roles?.filter(role => ['borrower', 'investor'].includes(role))?.map(role => titleCase(role)).join(', '),
      align: 'left',
    },
    {
      title: 'Has joined',
      dataIndex: 'fieldReferredUser',
      render: value => (value?.primary?.fieldLmsClientId ? 'Yes' : 'No'),
      align: 'left',
      width: isSmScreen ? '3.5rem' : '10%',
    },
    {
      title: 'Client ID',
      dataIndex: 'fieldReferredUser',
      render: value => value?.primary?.fieldLmsClientId,
      align: 'left',
    },
    {
      title: 'Post date',
      dataIndex: 'created',
      responsive: ['sm'],
      render: value => formatUnixDate(value),
      align: 'left',
    },
  ];

  if (isDealer) {
    columns.push({
      title: 'Broker email',
      dataIndex: 'owner',
      render: value => value?.email,
      align: 'left',
    });
  }

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.referrals?.results}
        pagination={false}
        rowKey="uuid"
      />
      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />
    </>
  );
};

export default ReferralsTable;
