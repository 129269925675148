import React from 'react';
import {
  string, node,
} from 'prop-types';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Button from '../../../Atoms/Forms/Button/Button';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const SimpleDashBoardCard = ({
  title, children, btnText, btnLink,
}) => (
  <Card
    title={title}
    type="dashboard"
    actions={[
      <Button key={btnText} block href={btnLink} type="primary">
        {btnText}
      </Button>,
    ]}
  >
    <div className="text-area">
      {children}
    </div>
  </Card>
);

export default SimpleDashBoardCard;

SimpleDashBoardCard.propTypes = {
  btnLink: string,
  btnText: string,
  title: node,
  children: node,
};

SimpleDashBoardCard.defaultProps = {
  btnLink: '/app/borrower/apply',
  btnText: 'Apply',
  title: (<Title level={4}>Apply for a loan</Title>),
  children: (
    <Paragraph>
      We accept applications for loans from $500,000. To avoid unnecessary delays, please ensure you have the following documents ready:
      <ul>
        <li>verification of your identity</li>
        <li>proof of income</li>
      </ul>
    </Paragraph>),
};
