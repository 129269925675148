// Converts an object into dot notation
export const toDotNot = (input, parentKey) => Object.keys(input || {}).reduce((acc, key) => {
  const value = input[key];
  const outputKey = parentKey ? `${parentKey}.${key}` : `${key}`;

  if (value && typeof value === 'object' && (!Array.isArray(value) || value.length)) return ({ ...acc, ...toDotNot(value, outputKey) });

  return ({ ...acc, [outputKey]: value });
}, {});

export const sanitize = obj => Object.entries(obj)
// eslint-disable-next-line no-unused-vars
  .filter(([_, v]) => v != null)
  .reduce(
    (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? sanitize(v) : v }),
    {},
  );

export const setNestedProp = (obj = {}, [first, ...rest], value) => ({
  ...obj,
  [first]: rest.length
    ? setNestedProp(obj[first], rest, value)
    : value,
});

export const getNestedProp = (obj, [first, ...rest]) => {
  if (obj === undefined || obj === null) {
    return null;
  }
  return (!rest.length ? obj[first] : getNestedProp(obj[first], rest));
};

const objectIsEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const firstKey = obj => Object?.keys(obj)?.find(key => !['total', '__typename'].includes(key));

export const Obj = {
  sanitize, setNestedProp, objectIsEmpty, firstKey,
};

export default Obj;
