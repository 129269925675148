import React from 'react';
import PropTypes from 'prop-types';
import DocumentEntitySearch from './DocumentEntitySearch';

const DocumentUserSelect = (props) => {
  const {
    accounts,
    clients,
    entitySelect,
  } = props;

  return (
    <>
      { entitySelect === 'client' ? (
        <DocumentEntitySearch
          entityType="client"
          validationMessage="Please select a client."
          {...clients}
        />
      ) : null}
      { entitySelect === 'account' ? (
        <DocumentEntitySearch
          entityType="account"
          validationMessage="Please select an account."
          {...accounts}
        />
      ) : null}
    </>
  );
};

const subPropTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string, value: PropTypes.string })),
  value: PropTypes.string,
  searchEvent: PropTypes.func.isRequired,
  selectEvent: PropTypes.func,
};

DocumentUserSelect.propTypes = {
  accounts: PropTypes.shape(subPropTypes).isRequired,
  clients: PropTypes.shape(subPropTypes).isRequired,
  entitySelect: PropTypes.string.isRequired,
};

export default DocumentUserSelect;
