import React from 'react';
import {
  arrayOf, number, oneOfType, string,
} from 'prop-types';
import useShouldUpdateFiles from './useShouldUpdateFiles';
import NzDriverLicence from '../Molecules/Forms/DocumentsVerification/NzDriverLicence/NzDriverLicence';
import AuDriverLicence from '../Molecules/Forms/DocumentsVerification/AuDriverLicence/AuDriverLicence';
import NzPassport from '../Molecules/Forms/DocumentsVerification/NzPassport/NzPassport';
import AuPassport from '../Molecules/Forms/DocumentsVerification/AuPassport/AuPassport';
import AustralianCitizen from '../Molecules/Forms/DocumentsVerification/AustralianCitizen/AustralianCitizen';
import NewZealandCitizen from '../Molecules/Forms/DocumentsVerification/NewZealandCitizen/NewZealandCitizen';
import ForeignPassport from '../Molecules/Forms/DocumentsVerification/ForeignPassport/ForeignPassport';
import BirthCertificate from '../Molecules/Forms/DocumentsVerification/BirthCertificate/BirthCertificate';
import { getFieldName } from '../Helpers/Forms';
import { findChildAttribute } from './useDataParsing';

const UsePersonVerification = (props) => {
  const { parents, verificationOptions } = props;

  const shouldUpdateComponent = (prev, next) => {
    const prevVerification = findChildAttribute(prev, parents);
    const nextVerification = findChildAttribute(next, parents);

    return !prevVerification
            || (prevVerification && prevVerification.selected !== nextVerification.selected)
            || (prevVerification && prevVerification?.errors !== nextVerification?.errors)
            || useShouldUpdateFiles(prevVerification, nextVerification, 'driverLicenceDocument')
            || useShouldUpdateFiles(prevVerification, nextVerification, 'passportDocument')
            || useShouldUpdateFiles(prevVerification, nextVerification, 'citizenshipDocument')
            || useShouldUpdateFiles(prevVerification, nextVerification, 'foreignPassportDocument')
            || useShouldUpdateFiles(prevVerification, nextVerification, 'birthCertificateDocument');
  };

  const availableComponents = [
    {
      name: 'nz-driver-licence-verification',
      component: <NzDriverLicence parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'au-driver-licence-verification',
      component: <AuDriverLicence parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'nz-passport-verification',
      component: <NzPassport parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'au-passport-verification',
      component: <AuPassport parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'australian-citizen-verification',
      component: <AustralianCitizen parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'nz-citizen-verification',
      component: <NewZealandCitizen parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'foreign-passport-verification',
      component: <ForeignPassport parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
    {
      name: 'birth-certificate',
      component: <BirthCertificate parents={parents} shouldUpdateComponent={shouldUpdateComponent} />,
    },
  ];


  const getPerson = (getFieldValue) => {
    if (!getFieldValue([parents, 'firstName'])) {
      return {
        firstName: getFieldValue(['person', 'firstName']),
        middleName: getFieldValue(['person', 'middleName']),
        lastName: getFieldValue(['person', 'lastName']),
        dateOfBirth: getFieldValue(['dateOfBirth']),
      };
    }

    return null;
  };

  const getSelectedOptionAttribute = (formComponent, attribute) => {
    const { getFieldValue, getFieldsValue, setFieldValue } = formComponent;
    if (typeof getFieldValue === 'function') {
      const selected = getFieldValue(getFieldName('selected', parents));
      const person = getPerson(getFieldValue);
      if (verificationOptions && verificationOptions.length && selected) {
        const selectedOption = verificationOptions.find(({ value }) => value === selected);
        const componentOption = availableComponents.find(element => selectedOption && element.name === selectedOption.component);
        if (componentOption && componentOption[attribute]) {
          return componentOption[attribute];
        }
      }
      const values = getFieldsValue();
      if (person) {
        setFieldValue('personVerification', { ...person, ...values.personVerification });
      }
    }

    return null;
  };

  const getSelectedComponent = formComponent => getSelectedOptionAttribute(formComponent, 'component');

  const getDocumentFieldName = (selected) => {
    const documentFieldMap = {
      'nz-driver-licence': 'driverLicenceDocument',
      'au-driver-licence': 'driverLicenceDocument',
      'nz-passport': 'passportDocument',
      'au-passport': 'passportDocument',
      'au-citizenship-certificate': 'citizenshipDocument',
      'nz-citizenship-certificate': 'citizenshipDocument',
      'overseas-passport': 'foreignPassportDocument',
      'birth-certificate': 'birthCertificateDocument',
    };

    return documentFieldMap[selected] || null;
  };

  return { shouldUpdateComponent, getSelectedComponent, getDocumentFieldName };
};
UsePersonVerification.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

UsePersonVerification.defaultProps = {
  parents: ['personVerification'],
};

export default UsePersonVerification;
