import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import { draftName } from '../../Hooks/useDraft';
import { getGraphQlErrorMessage } from '../../Helpers/Error';
import {
  getAllStepEntities,
  getDraftUuid, setDraftLoading,
  setDraftUuid,
} from '../../Store/clientRegistrationSlice';
import {
  CREATE_DRAFT,
  UPDATE_DRAFT,
} from '../../GraphQL/Mutations/Draft';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { userUuid } from '../../Hooks/useLocalStorage';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import { convertDraftMomentsToDate } from '../../Hooks/useCommonConvertedEntityData';

const RegistrationError = ({ context, createError }) => {
  const dispatch = useDispatch();
  const allEntities = useSelector(getAllStepEntities);
  const draftUuid = useSelector(getDraftUuid);

  const [SaveDraft, { loading: createDraftLoading, error: createDraftError }] = useMutation(CREATE_DRAFT);
  const [UpdateDraft, { loading: updateDraftLoading, error: updateDraftError }] = useMutation(UPDATE_DRAFT);

  const statusCode = createError?.networkError?.statusCode || 500;
  const message = createError?.networkError?.result?.errors[0]?.message;

  const draftSaved = !(createDraftLoading || createDraftError || updateDraftLoading || updateDraftError);
  const fatalError = !draftSaved || (statusCode >= 500);

  dispatch(setDraftLoading(createDraftLoading || updateDraftLoading));

  useEffect(() => {
    // @todo Refactor uuid to use redux.
    const uuid = userUuid();

    const draftInput = {
      owner: uuid,
      data: JSON.stringify(convertDraftMomentsToDate(allEntities)),
      title: draftName.client(allEntities),
    };

    if (typeof draftUuid !== 'undefined' && draftUuid) {
      UpdateDraft({ variables: { input: { ...draftInput, uuid: draftUuid } } })
        .catch(updateError => console.log(getGraphQlErrorMessage(updateError)));
      return;
    }

    SaveDraft({ variables: { input: { ...draftInput, type: 'client' } } })
      .then((response) => {
        const { data: { draftCreate: { json } } } = response;
        const { uuid: responseUuid } = JSON.parse(json);
        dispatch(setDraftUuid(responseUuid));
      })
      .catch(saveError => console.log(getGraphQlErrorMessage(saveError)));
  }, []);

  if (createDraftLoading || updateDraftLoading) {
    return <Skeleton loading={createDraftLoading || updateDraftLoading} />;
  }

  return (
    <Row className="theme-registration-success">
      <Col className="theme-description-wrapper" span={12}>
        <HeaderTitle
          title="Whoops! An error occurred during registration."
          commentType="grey-box"
          comment={(
            <>
              { message && (<Paragraph>{message}</Paragraph>) }
              { draftSaved && (
                <Paragraph>
                  Your draft client registration was saved, and can be viewed from
                  {' '}
                  <a href={`/app/${context}/clients/drafts`}>your drafts page</a>
                  .
                </Paragraph>
              )}
              { fatalError && (
                <Paragraph>
                  Please contact
                  {' '}
                  <a href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>Client Services</a>
                  {' '}
                  to help you complete your registration
                  .
                </Paragraph>
              )}
            </>
          )}
        />
      </Col>
    </Row>
  );
};

RegistrationError.propTypes = {
  context: string.isRequired,
  createError: shape({}),
};

RegistrationError.defaultProps = {
  createError: {},
};

export default RegistrationError;
