import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import AppConfigContext from './AppConfigContext';
import { setSchemaDateFields, schemaDateFields } from '../../Store/schemaSlice';
import { setConfiguration } from '../../Store/appConfigurationSlice';
import { CONFIGURATIONS, SCHEMA } from '../../GraphQL/Queries/Configuration';

const AppConfigProvider = ({ children }) => {
  const { loading, error, data } = useQuery(CONFIGURATIONS, {
    variables: {
      keys: [
        'client_registration',
        'DealerSpecificAppConfiguration',
        'InvestmentConditions',
      ],
    },
  });

  const { loading: schemaLoading, data: schemaData } = useQuery(SCHEMA);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.configurations) {
      const configData = data.configurations.results;
      dispatch(setConfiguration(configData));
    }
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (schemaData && schemaData.__schema) {
      // eslint-disable-next-line no-underscore-dangle
      dispatch(setSchemaDateFields(schemaDateFields(schemaData.__schema)));
    }
  }, [schemaData]);

  return (
    <AppConfigContext.Provider value={{
      loading: loading && schemaLoading,
      error,
    }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppConfigProvider;
