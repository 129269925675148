import React from 'react';
import { string, oneOfType, objectOf } from 'prop-types';
import NoResultIcon from './NoResultIcon';
import Title from '../../Atoms/Typography/Title/Title';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import './NoResult.less';

const NoResult = (props) => {
  const { title, body, className } = props;

  return (
    <div className={['theme-no-result', className].join(' ')}>
      <NoResultIcon />
      <div className="content">
        <Title level={4} className="title">{title}</Title>
        <Paragraph>{body}</Paragraph>
      </div>
    </div>
  );
};

NoResult.propTypes = {
  title: string,
  body: oneOfType([objectOf(React.fragment), string]),
  className: string,
};

NoResult.defaultProps = {
  title: '',
  body: '',
  className: '',
};

export default NoResult;
