import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { arrayOf, shape, string } from 'prop-types';
import { useMutation } from '@apollo/client';
import { Prompt, useLocation } from 'react-router';
import LoanDetailsStep from '../../Organisms/Forms/Loan/LoanDetailsStep/LoanDetailsStep';
import LoanDocumentStep from '../../Organisms/Forms/Loan/LoanDocumentStep/LoanDocumentStep';
import LoanSecurityDetailsStep from '../../Organisms/Forms/Loan/LoanSecurityDetailsStep/LoanSecurityDetailsStep';
import MultiStepForm from '../../Molecules/Forms/MultiStepForm/MultiStepForm';
import LoanReviewStep from '../../Organisms/Forms/Loan/LoanReviewStep/LoanReviewStep';
import {
  getStep,
  initFromDraft,
  setDraftUuid,
  setStep,
  setSteps,
  setEntity,
  getSteps,
  getRegisterEntities,
  getAllStepEntities,
  setCreateError,
  setCreateLoading,
  reset,
} from '../../Store/loanApplicationSlice';
import LoanBorrowingEntityStep from '../../Organisms/Forms/Loan/LoanBorrowingEntityStep/LoanBorrowingEntityStep';
import { errorsForEntityById, getEntityName } from '../../Hooks/useEntity';
import { CREATE_LOAN } from '../../GraphQL/Mutations/Loan';
import useLoanConvertedInputData from '../../Hooks/useLoanConvertedInputData';
import { dealerDefaultCountry } from '../../Hooks/useLocalStorage';
import LoanCompletedStep from '../../Organisms/Forms/Loan/LoanCompletedStep/LoanCompletedStep';

const LoanApplication = ({ InitialFormState, queryDraftUuid }) => {
  const { key } = useLocation();
  const dispatch = useDispatch();
  const allStepEntities = useSelector(getAllStepEntities);
  const entities = useSelector(getRegisterEntities);
  const step = useSelector(getStep);
  const steps = useSelector(getSteps);
  const [CreateLoan, { loading: createLoading }] = useMutation(CREATE_LOAN);

  if (queryDraftUuid !== undefined) {
    dispatch(setDraftUuid(queryDraftUuid));
  }

  useEffect(() => {
    dispatch(reset);

    dispatch(setSteps([
      { title: 'Borrowing Entity', component: LoanBorrowingEntityStep },
      { title: 'Details', component: LoanDetailsStep },
      { title: 'Security', component: LoanSecurityDetailsStep },
      { title: 'Documents', component: LoanDocumentStep },
      { title: 'Review', component: LoanReviewStep },
      { title: 'Complete', component: LoanCompletedStep, clickable: false },
    ]));
  }, [key]);

  useEffect(() => {
    if (InitialFormState) {
      dispatch(initFromDraft(InitialFormState));
    }
  }, [InitialFormState]);

  useEffect(() => {
    dispatch(setCreateLoading(createLoading));
  }, [createLoading]);

  const stepStatus = (status) => {
    const entity = entities?.find(item => item?.stepKey === status?.step);
    if (status?.step >= steps.length || step >= steps.length - 1) return 'finish';
    if (!entity && step === status?.step) return 'process';
    if (!entity && entities.length >= steps.length && status?.step <= steps.length) return 'finish';
    if (!entity) return 'wait';
    const { id } = getEntityName(entity);
    const error = errorsForEntityById(allStepEntities, steps, id);
    if (error?.length) return 'error';
    return 'finish';
  };

  const isLastInputStep = checkStep => checkStep >= steps.length - 2;

  const onFinish = () => {
    const variables = useLoanConvertedInputData(entities, dealerDefaultCountry());
    CreateLoan({
      variables,
    })
      .then(() => {
        dispatch(setCreateError(null));
        dispatch(setStep(step + 1));
      })
      .catch((error) => {
        dispatch(setCreateError(error));
        dispatch(setStep(step + 1));
      });
  };

  const onProceed = (values) => {
    if (isLastInputStep(step)) {
      onFinish();
      return;
    }
    dispatch(setEntity(values));

    dispatch(setStep(step + 1));
  };

  return (
    <>
      <Prompt
        when
        message={() => {
          dispatch(reset());
          return true;
        }}
      />
      <MultiStepForm
        steps={useSelector(getSteps)}
        onClickProceed={onProceed}
        prevStep={() => dispatch(setStep(step - 1))}
        setStep={value => dispatch(setStep(value))}
        step={step}
        stepStatus={stepStatus}
        initialValues={stepKey => entities?.find(entity => parseInt(entity?.stepKey, 10) === stepKey) ?? {}}
      />
    </>
  );
};

LoanApplication.propTypes = {
  queryDraftUuid: string,
  InitialFormState: arrayOf(shape({})),
};

LoanApplication.defaultProps = {
  queryDraftUuid: undefined,
  InitialFormState: [],
};

export default LoanApplication;
