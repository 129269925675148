import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import { DELETE_ACCOUNT } from '../../../GraphQL/Mutations/Account';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';

const DeleteAccountButton = ({ type, uuid, onSuccess }) => {
  const [DeleteAccount, { loading }] = useMutation(DELETE_ACCOUNT, { variables: { uuid } });
  const [message, setMessage] = useState(false);

  const deleteAccount = () => {
    DeleteAccount()
      .then((result) => {
        setMessage(false);
        onSuccess(result);
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <>
      { message && <Alert message={message} type="error" /> }
      <Popconfirm
        title={`Accounts must be deleted from LMS first. Are you sure you want to delete this ${type}?`}
        placement="topRight"
        okText="Yes"
        cancelText="No"
        onConfirm={deleteAccount}
      >
        <Button type="cancel" loading={loading}>
          Delete
          {' '}
          {type}
        </Button>
      </Popconfirm>

    </>
  );
};

DeleteAccountButton.propTypes = {
  type: string.isRequired,
  uuid: string.isRequired,
  onSuccess: func,
};

DeleteAccountButton.defaultProps = {
  onSuccess: () => {},
};

export default DeleteAccountButton;
