import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import Modal from '../../../Atoms/Feedback/Modal/Modal';
import Button from '../../../Atoms/Forms/Button/Button';

const SessionTimeout = () => {
  const [events] = useState(['click', 'load', 'scroll']);
  const [isOpen, setOpen] = useState(false);

  let timeStamp;
  const warningInactiveInterval = useRef();
  const startTimerInterval = useRef();

  const logout = () => {
    clearInterval(warningInactiveInterval.current);
    sessionStorage.removeItem('lastTimeStamp');
    window.location.href = '/app/logout';
  };

  const warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    setOpen(true);

    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 20; // Maximum ideal time given before logout
      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();

      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
        logout();
      }
    }, 1000);
  };

  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 60000 * 15);
  };

  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    timeStamp = moment();
    sessionStorage.setItem('lastTimeStamp', timeStamp);
    timeChecker();
  }, []);


  const handleClose = () => {
    setOpen(false);
    resetTimer();
  };

  useEffect(() => {
    events.forEach(event => window.addEventListener(event, resetTimer));
    timeChecker();
    return () => clearTimeout(startTimerInterval.current);
  }, [resetTimer, events, timeChecker]);

  if (!isOpen) return null;

  return (
    <Modal
      title="Your session is about to timeout"
      open={isOpen}
      footer={[
        <Button key="submit" type="primary" onClick={handleClose}>
          Yes
        </Button>,
        <Button key="back" ghost onClick={logout}>
          No
        </Button>,
      ]}
    >
      Your session is about to expire. Would you like more time?
    </Modal>
  );
};

export default SessionTimeout;
