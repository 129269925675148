import React from 'react';
import { bool, node, string } from 'prop-types';
import { Tabs } from 'antd';
import './TabPane.less';

const TabPane = (props) => {
  const { className } = props;
  return <Tabs.TabPane {...props} className={className !== undefined ? `theme-tab-pane ${className}` : 'theme-tab-pane'} />;
};

TabPane.propTypes = {
  className: string,
  closeIcon: node,
  forceRender: bool,
  tab: node,
};

TabPane.defaultProps = {
  className: undefined,
  closeIcon: undefined,
  forceRender: false,
  tab: undefined,
};

export default TabPane;
