import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';

const StepInfo = ({
  id, stepKey, subStepKey, parentId,
}) => (
  <>
    <FormItem hidden name="id" initialValue={id}>
      <Input value={id} />
    </FormItem>
    <FormItem hidden name="stepKey" initialValue={stepKey}>
      <Input />
    </FormItem>
    {subStepKey && (
    <FormItem hidden name="subStepKey" initialValue={subStepKey}>
      <Input />
    </FormItem>
    )}
    {parentId && (
      <FormItem hidden name="parentId" initialValue={parentId}>
        <Input value={parentId} />
      </FormItem>
    )}
  </>
);

StepInfo.propTypes = {
  stepKey: oneOfType([string, number]).isRequired,
  id: string.isRequired,
  subStepKey: string,
  parentId: string,
};

StepInfo.defaultProps = {
  subStepKey: undefined,
  parentId: undefined,
};

export default StepInfo;
