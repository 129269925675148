import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import { INVESTMENT_BY_UUID } from '../../../GraphQL/Queries/Account';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import InvestmentCertificateForm from '../../../Molecules/Forms/InvestmentCertificateForm/InvestmentCertificateForm';
import { INVESTMENT_CERTIFICATE_PDF } from '../../../GraphQL/Mutations/Pdf';
import NoResult from '../../../Molecules/NoResult/NoResult';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import InvestmentCertificateCard from '../../Cards/Reporting/InvestmentCertificateCard';
import SaveToPortalButton from '../SaveToPortalButton';
import useFile from '../../../Hooks/useFile';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentCertificate = () => {
  const [investment, setInvestment] = useState();
  const [formValues, setFormValues] = useState({});
  const [savedToPortal, setSavedToPortal] = useState(false);
  const [query, { data, loading }] = useLazyQuery(INVESTMENT_BY_UUID);
  const [mutation, { loading: mutating }] = useMutation(INVESTMENT_CERTIFICATE_PDF);
  const { view } = useFile();

  useEffect(() => data && setInvestment(data?.investment), [data]);

  const onClickPreview = (values) => {
    setSavedToPortal(false);
    setFormValues(values);

    return query({
      variables: { uuid: values?.account },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      skip: !values?.account,
    });
  };

  const onClickDownloadPdf = () => {
    mutation({
      variables: { uuid: formValues?.account },
    }).then(({ data: responseData }) => {
      view(responseData?.investmentCertificatePdf);
    });
  };

  const onClickSaveToDocumentPortal = () => {
    mutation({
      variables: {
        uuid: formValues?.account,
        saveDocument: true,
      },
    }).then(() => setSavedToPortal(true));
  };

  return (
    <>
      <InvestmentCertificateForm onFinish={onClickPreview} loading={loading} />

      <Skeleton loading={loading}>
        { investment && (
          <>
            <Row>
              <Col flex={24}>
                <InvestmentCertificateCard investment={investment} />
              </Col>
            </Row>
            <ButtonGroup direction="bottom-right" className="set-3-style">
              <Button
                type="secondary"
                onClick={onClickDownloadPdf}
                disabled={mutating}
              >
                Download PDF
              </Button>

              <SaveToPortalButton callBack={onClickSaveToDocumentPortal} loading={mutating} saved={savedToPortal} />
            </ButtonGroup>
          </>
        )}

        { !data && !investment && (
          <NoResult
            title={`No ${brandLabelConfig(dealer(), 'reportingInvestmentCertificate', 'investment certificate')} found.`}
            body="Please try a different filter."
          />
        )}
      </Skeleton>
    </>
  );
};


export default InvestmentCertificate;
