export const getGraphQlErrorMessage = (error) => {
  const { networkError, message } = error;

  if (networkError) {
    const { result } = networkError;
    if (result) {
      const { errors } = result;
      if (errors && errors.length) {
        const { message: errorMessage } = errors[0];
        if (errorMessage) {
          return errorMessage;
        }
      }
    }
  }

  if (message) {
    return message;
  }

  return false;
};

const Error = {
  getGraphQlErrorMessage,
};

export default Error;
