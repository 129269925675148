import React from 'react';
import {
  bool, node, oneOf, oneOfType, string,
} from 'prop-types';
import Title from '../../Typography/Title/Title';
import Text from '../../Typography/Text/Text';
import './Field.less';

const Field = (props) => {
  const {
    className,
    label,
    value,
    displayNull,
    type,
  } = props;

  if (displayNull || (value && !displayNull)) {
    return (
      <div className={`theme-field theme-field-${type} ${className || ''}`}>
        <Title className="theme-field-title info-title" level={5}>{label}</Title>
        <Text className="theme-field-value info-value">{value === null ? 'n/a' : value}</Text>
      </div>
    );
  }

  return null;
};

Field.propTypes = {
  className: string,
  label: string.isRequired,
  value: oneOfType([string, null, undefined, node]),
  displayNull: bool,
  type: oneOf(['default', 'inner']),
};

Field.defaultProps = {
  className: null,
  value: null,
  displayNull: false,
  type: 'default',
};

export default Field;
