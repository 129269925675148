import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { accessToken as _accessToken } from '../../Store/userSlice';
import AccessDenied from '../../Templates/Error/AccessDenied';
import Result from '../../Atoms/Feedback/Result/Result';

const Sso = () => {
  const token = useSelector(_accessToken);
  const [success, setSuccess] = useState(true);

  if (token) {
    const url = new URL(`${process.env.REACT_APP_FINTECH_URL}/sso`);
    url.searchParams.append('token', token);

    fetch(url.href)
      .then(response => response.json())
      .then((data) => {
        if (data?.url) {
          window.open(data.url, '_blank');
        }
        if (!data?.url) {
          setSuccess(false);
        }
      })
      .catch(() => setSuccess(false));
  }

  return (
    <div className="zagga-error">
      { success ? (
        <Result
          status="success"
          subTitle="A new browser window has been opened with the requested page."
        />
      ) : <AccessDenied /> }
    </div>
  );
};

export default Sso;
