import React from 'react';
import './IconButton.less';
import { func, oneOf } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes, faEllipsisH, faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const IconButton = (args) => {
  const { className, type, size } = args;
  const Icon = (param) => {
    switch (param) {
      case 'close': return <FontAwesomeIcon icon={faTimes} />;
      case 'delete': return <FontAwesomeIcon icon={faTrashAlt} />;
      case 'add': return <FontAwesomeIcon icon={faPlus} />;
      default: return <FontAwesomeIcon icon={faEllipsisH} />;
    }
  };

  return (
    <div
      {...args}
      role="button"
      className={className !== undefined ? `theme-icon-button size-${size} ${className}` : `theme-icon-button size-${size}`}
    >
      {Icon(type)}
    </div>
  );
};

IconButton.propTypes = {
  type: oneOf(['default', 'primary', 'secondary', 'cancel', 'ghost', 'add']),
  onClick: func,
  onKeyPress: func,
  size: oneOf(['default', 'small']),
};

IconButton.defaultProps = {
  type: 'default',
  onClick: () => {},
  onKeyPress: () => {},
  size: 'default',
};

export default IconButton;
