import React, { useContext, useEffect } from 'react';
import { func, string } from 'prop-types';
import Parser from 'html-react-parser';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import Text from '../../../Atoms/Typography/Text/Text';
import ClientStructureSelector from '../ClientStructureSelector/ClientStructureSelector';
import ClientStructureContext from '../../../Context/ClientStructure/ClientStructureContext';
import {
  findStepEntityByStepKey,
  setShowAccountOverviewDrawer,
} from '../../../Store/clientRegistrationSlice';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const ClientStructure = ({
  onChange, onProceed, stepKey,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { notes } = useContext(ClientStructureContext);
  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuid() };

  useEffect(() => {
    form.setFieldsValue(stepData);
  }, [stepData]);

  const onFinish = (values) => {
    onProceed(values);
  };

  const onChangeLocal = ({ target: { name, value } }) => {
    if (['type', 'role'].includes(name)) {
      onChange(name, value);
    }
  };

  return (
    <Row className="theme-client-structure">
      <Col className="theme-description-wrapper" span={12}>
        <HeaderTitle
          title="Create account"
          commentType="grey-box"
          comment={(
            <Paragraph>
              {`Thank you for your interest in ${process.env.REACT_APP_BRAND}`}
              .
              <br />
              <br />
              {`This registration process can take up to 20 minutes to complete. You can save your progress at any
              time and return to it later by logging in from the ${process.env.REACT_APP_BRAND} home page.`}
            </Paragraph>
          )}

        />

        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            dispatch(setShowAccountOverviewDrawer(true));
            onFinish(values);
          }}
          initialValues={stepData}
          onChange={onChangeLocal}
          name="clientStructureForm"
        >
          <StepInfo id={stepData.id} stepKey={stepKey} />
          <ClientStructureSelector parents={[]} />
          { notes && (
            <Text type="section-comment">
              {Parser(notes)}
            </Text>
          )}
          <ClientRegistrationActions form={form} />
        </Form>
      </Col>
    </Row>
  );
};

ClientStructure.propTypes = {
  onChange: func,
  onProceed: func,
  stepKey: string,
};

ClientStructure.defaultProps = {
  onChange: () => {},
  onProceed: () => {},
  stepKey: 'set-account-structure-form',
};

export default ClientStructure;
