import React, { useContext, useState } from 'react';
import { func } from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import Button from '../../Atoms/Forms/Button/Button';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';
import './EntityConfirmation.less';
import Popconfirm from '../../Atoms/Forms/Popconfirm/Popconfirm';
import ClientRegistrationActions from '../../Molecules/Forms/Actions/ClientRegistrationActions';
import LangContext from '../../Context/Lang/LangContext';
import { setSelected } from '../../Store/clientRegistrationSlice';

const EntityConfirmation = ({ onClick, onGoBack, onFinish }) => {
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const dispatch = useDispatch();
  const onVisibleChange = () => {
    setConfirmVisible(true);
  };

  const submitWithPopConfirm = (
    <Popconfirm
      title="Please ensure you have added all individuals associated with the entity."
      visible={confirmVisible}
      onVisibleChange={onVisibleChange}
      okText="Yes"
      cancelText="No"
      onConfirm={() => { dispatch(setSelected('new')); onFinish(undefined); }}
      onCancel={onClick}
    >
      <Button type="secondary" htmlType="submit">{t('forms.proceed')}</Button>
    </Popconfirm>
  );

  return (
    <div className="theme-entity-confirmation">
      <Form
        layout="vertical"
        form={form}
        onFinish={() => { dispatch(setSelected('new')); onFinish(undefined); }}
        name="registerAndVerifyEntitiesForm"
      >
        <FormItem label="Have you added all the individuals associated with this entity?">
          <Button type="secondary" onClick={onClick} icon={<PlusCircleOutlined />}>Add</Button>
        </FormItem>
        <ClientRegistrationActions form={form} onGoBack={onGoBack} customSubmit={submitWithPopConfirm} />
      </Form>
    </div>
  );
};

EntityConfirmation.propTypes = {
  onClick: func.isRequired,
  onGoBack: func,
  onFinish: func,
};

EntityConfirmation.defaultProps = {
  onGoBack: () => {},
  onFinish: () => {},
};

export default EntityConfirmation;
