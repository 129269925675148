import React, { useState } from 'react';
import {
  shape, number, string, func,
} from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import { CANCEL_REDUCTION } from '../../../../../GraphQL/Mutations/Reduction';
import Alert from '../../../../../Atoms/Feedback/Alert/Alert';
import Button from '../../../../../Atoms/Forms/Button/Button';
import Popconfirm from '../../../../../Atoms/Forms/Popconfirm/Popconfirm';
import { getGraphQlErrorMessage } from '../../../../../Helpers/Error';

const CancelRedunctionRequestForm = ({ reduction, refetch }) => {
  const { uuid } = reduction;
  const [form] = Form.useForm();
  const [UpdateReduction, { loading }] = useMutation(CANCEL_REDUCTION);
  const [message, setMessage] = useState(false);

  const onFinish = () => {
    UpdateReduction({
      variables: { uuid },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-cancel-reduction-request-form">
      <Form
        form={form}
        onFinish={onFinish}
      >
        { message && <Alert message={message} type="error" /> }
        <Popconfirm
          title="Please confirm you wish to cancel your marketplace offer?"
          okText="I confirm"
          cancelText="Cancel"
          onConfirm={onFinish}
        >
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? 'Please wait' : 'Cancel marketplace offering'}
          </Button>
        </Popconfirm>
      </Form>
    </div>
  );
};

CancelRedunctionRequestForm.propTypes = {
  reduction: shape({
    uuid: string,
    fieldStatus: 'pending',
    created: number,
    changed: number,
    fieldExpiry: number,
  }).isRequired,
  refetch: func,
};

CancelRedunctionRequestForm.defaultProps = {
  refetch: () => {},
};

export default CancelRedunctionRequestForm;
