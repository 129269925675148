import React from 'react';
import PropTypes, { string } from 'prop-types';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';
import { ucwords } from '../../../Helpers/Helpers';

const TaxStatementCard = ({ client, period }) => {
  const fields = [
    { label: 'Investment Entity', value: client?.name },
    { label: 'Client ID', value: client?.fieldLmsClientId },
    { label: 'Registered Address', value: client?.owner?.primary?.fieldAddress?.value },
    { label: 'Period', value: period },
  ];

  return (
    <Card
      title={(<Title level={4}>{ucwords(brandLabelConfig(dealer(), 'reportingTaxStatement', 'Tax Statement'))}</Title>)}
    >
      <Fields fields={fields} />
    </Card>
  );
};

export default TaxStatementCard;

TaxStatementCard.propTypes = {
  client: PropTypes.shape({
    name: string,
    fieldLmsClientId: string,
    owner: PropTypes.shape({
      primary: PropTypes.shape({
        fieldAddress: PropTypes.shape({
          value: string,
        }),
      }),
    }),
  }),
  period: string,
};

TaxStatementCard.defaultProps = {
  client: {},
  period: '',
};
