import React, { useContext } from 'react';
import {
  bool, func, shape, string,
} from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Button from '../../../Atoms/Forms/Button/Button';
import {
  getCreateLoading,
  getDraftDeleting,
  getDraftLoading,
  getDraftUuid,
  setDraftDeleting,
  setDraftLoading,
  setDraftUuid,
  getAllStepEntities,
} from '../../../Store/loanApplicationSlice';
import LangContext from '../../../Context/Lang/LangContext';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import useDraft from '../../../Hooks/useDraft';

const LoanApplicationActions = ({
  form, canProceed, alternateButtonText, onClickBack,
}) => {
  const { context } = useParams();
  const entities = useSelector(getAllStepEntities);
  const createLoading = useSelector(getCreateLoading);
  const dispatch = useDispatch();
  const draftLoading = useSelector(getDraftLoading);
  const draftUuid = useSelector(getDraftUuid);
  const draftDeleting = useSelector(getDraftDeleting);
  const { t } = useContext(LangContext);

  const {
    onSaveDraft,
    onDeleteDraft,
    createDraftLoading,
    deleteDraftLoading,
    updateDraftLoading,
  } = useDraft('application', draftUuid, context, form, entities, setDraftUuid);

  dispatch(setDraftLoading(createDraftLoading || updateDraftLoading));
  dispatch(setDraftDeleting(deleteDraftLoading));

  return (
    <ButtonGroup direction="bottom-right" className="set-3-style">
      { onClickBack && (
        <Button
          ghost
          htmlType="button"
          className="mr-auto"
          onClick={onClickBack}
        >
          Back
        </Button>
      )}

      {draftUuid && (
      <Popconfirm
        title="Are you sure you want to delete this draft?"
        okText="Yes"
        cancelText="No"
        onConfirm={onDeleteDraft}
      >
        <Button
          ghost
          type="secondary"
          Cancel
          loading={draftDeleting}
        >
          {draftDeleting ? 'Please wait' : t('forms.discard_draft')}
        </Button>
      </Popconfirm>
      )}

      <Button ghost type="secondary" loading={draftLoading} onClick={onSaveDraft('application')}>{t('forms.save_draft')}</Button>

      <Button
        loading={createLoading}
        disabled={!canProceed}
        type={canProceed ? 'secondary' : 'ghost'}
        htmlType="submit"
      >
        {canProceed ? alternateButtonText || t('forms.proceed') : t('forms.can_not_proceed')}
      </Button>
    </ButtonGroup>
  );
};

LoanApplicationActions.propTypes = {
  form: shape([]),
  canProceed: bool,
  onClickBack: func,
  alternateButtonText: string,
};

LoanApplicationActions.defaultProps = {
  form: {},
  canProceed: true,
  onClickBack: () => {},
  alternateButtonText: '',
};

export default LoanApplicationActions;
