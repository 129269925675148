import React, {
  useState, useContext,
} from 'react';
import {
  func, shape, number, bool, string,
} from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { UPDATE_REDUCTION, DELETE_REDUCTION } from '../../../../../GraphQL/Mutations/Reduction';
import Alert from '../../../../../Atoms/Feedback/Alert/Alert';
import FormItem from '../../../../../Atoms/Forms/FormItem/FormItem';
import Button from '../../../../../Atoms/Forms/Button/Button';
import RadioGroup from '../../../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../../../Atoms/Forms/Radio/Radio';
import LangContext from '../../../../../Context/Lang/LangContext';
import Currency from '../../../../../Atoms/DataDisplay/Field/Currency';
import Result from '../../../../../Atoms/Feedback/Result/Result';
import ButtonGroup from '../../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import DatePicker from '../../../../../Atoms/Forms/DatePicker/DatePicker';
import { getGraphQlErrorMessage } from '../../../../../Helpers/Error';
import { formatDateAsTimestamp } from '../../../../../Helpers/FormatDate';
import Divider from '../../../../../Atoms/Layout/Divider/Divider';
import { calculateBreakFeeWithState } from '../../../../../Hooks/useFees';
import Popconfirm from '../../../../../Atoms/Forms/Popconfirm/Popconfirm';
import BreakFee from '../../../../../Molecules/DataDisplay/Fields/BreakFee';
import RemainingInvestment from '../../../../../Molecules/DataDisplay/Fields/RemainingInvestment';

const EditReductionForm = ({
  onCancel, reduction, refetch,
}) => {
  const {
    uuid, fieldAmount, fieldExpiry, entityPermissions, fieldReducedInvestment, fieldBreakFee,
  } = reduction;

  const { fieldAmount: investmentAmount } = fieldReducedInvestment;
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const [UpdateReduction, { loading }] = useMutation(UPDATE_REDUCTION);
  const [DeleteReduction, { loading: deleteLoading }] = useMutation(DELETE_REDUCTION);
  const [message, setMessage] = useState(false);
  const [desiredReduction, setDesiredReduction] = useState(fieldAmount);
  const [breakFee, setBreakFee] = useState(fieldBreakFee);

  const { permitted } = entityPermissions.find(({ label }) => label === 'Can edit reduction') || { permitted: false };

  if (!permitted) {
    return (<Result status="error" title="Sorry this investment can not be reduced!" />);
  }

  const onFinish = (data) => {
    const { fieldExpiry: formFieldExpire } = data;
    UpdateReduction({
      variables: {
        ...data,
        fieldAmount: desiredReduction,
        fieldExpiry: formatDateAsTimestamp(formFieldExpire),
        uuid,
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
        onCancel();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const handleDelete = () => {
    DeleteReduction({
      variables: {
        uuid,
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
        onCancel();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-request-reduction-form">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{ ...reduction, fieldExpiry: moment.unix(fieldExpiry) }}
      >
        { message && <Alert message={message} type="error" /> }

        <FormItem
          label="Status"
          name="fieldStatus"
        >
          <RadioGroup>
            <Radio value="pending">Pending</Radio>
            <Radio value="active">Active</Radio>
            <Radio value="complete">Complete</Radio>
            <Radio value="cancelled">Cancelled</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem
          label="Action"
          name="fieldAction"
        >
          <RadioGroup>
            <Radio value="cancel_now">Cancel now</Radio>
            <Radio value="cancel_on_expiry">Cancel at expiry</Radio>
            <Radio value="accept_now">Accept now</Radio>
            <Radio value="accept_on_expiry">Accept at expiry</Radio>
          </RadioGroup>
        </FormItem>

        <FormItem
          label="Amount"
          name="fieldAmount"
          className="theme-form-amount"
          initialValues={desiredReduction}
          rules={[
            { required: true, message: t('validation.required', { attribute: 'field' }) },
          ]}
        >
          <span className="input-group-addon">$</span>
          <Currency
            className="form-control"
            placeholder="Enter additional amount"
            thousandSeparator
            displayType="input"
            value={desiredReduction}
            allowNegative={false}
            prefix=""
            onValueChange={({ floatValue }) => {
              const amount = floatValue || 0;
              form.setFieldsValue({ amount, fieldBreakFee: calculateBreakFeeWithState(amount, setBreakFee) });
              setDesiredReduction(amount);
            }}
          />
        </FormItem>

        <RemainingInvestment title="Your remaining investment" available={investmentAmount} selectedOption="partial" commitment={desiredReduction} />

        <FormItem
          label="Break fee"
          name="fieldBreakFee"
          className="theme-form-amount"
          fieldBreakFee
        >
          <Currency
            className="form-control"
            placeholder="Enter additional amount"
            thousandSeparator
            displayType="input"
            value={breakFee}
            allowNegative={false}
            prefix=""
          />
        </FormItem>

        <BreakFee breakFee={breakFee} />

        <FormItem
          label="Expiry"
          name="fieldExpiry"
          rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
        >
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
          />
        </FormItem>

        <Divider />

        <ButtonGroup direction="right" className="set-3-style">
          <Button
            type="ghost"
            onClick={() => onCancel()}
          >
            Cancel
          </Button>

          <Popconfirm
            title="Are you sure you want to delete this reduction?"
            placement="topRight"
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="secondary"
              htmlType="submit"
              loading={deleteLoading}
            >
              {deleteLoading ? 'Please wait' : 'Delete' }
            </Button>
          </Popconfirm>
          <Button type="primary" htmlType="submit" loading={loading}>
            {loading ? 'Please wait' : 'Update' }
          </Button>
        </ButtonGroup>
      </Form>
    </div>
  );
};

EditReductionForm.propTypes = {
  onCancel: func.isRequired,
  reduction: shape({
    uuid: string,
    fieldStatus: string,
    fieldAction: string,
    created: number,
    changed: number,
    fieldExpiry: number,
    fieldAmount: number,
    fieldBreakFee: number,
    amountFunded: number,
    entityPermissions: shape([
      { label: string, permitted: bool },
    ]),
    type: string,
  }).isRequired,
  refetch: func,
};

EditReductionForm.defaultProps = {
  refetch: () => {},
};

export default EditReductionForm;
