import React from 'react';
import { number, shape, string } from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import useFile from '../../../Hooks/useFile';
import Button from '../Button/Button';
import './PdfDownload.less';

const PdfDownload = ({ file }) => {
  const { view } = useFile();

  return (
    <div className="theme-pdf-download-button">
      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={() => file.url && view(file)}
        onKeyDown={() => file.url && view(file)}
      >
        Download
      </Button>
    </div>
  );
};

PdfDownload.propTypes = {
  file: shape({
    filesize: number,
    filemime: string,
    path: string,
    url: string.isRequired,
  }).isRequired,
};

export default PdfDownload;
