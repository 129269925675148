import React from 'react';
import { func, string } from 'prop-types';
import { Form, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import Button from '../../../../Atoms/Forms/Button/Button';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import useNestedDrawer from '../../../../Hooks/useNestedDrawer';
import BankAccountNumber from '../../../../Molecules/Forms/BankAccountNumber/BankAccountNumber';
import { dealerDefaultCountry } from '../../../../Hooks/useLocalStorage';
import { ACTING_CLIENT_BANK_ACCOUNT } from '../../../../GraphQL/Queries/Client';
import Skeleton from '../../../../Atoms/Feedback/Skeleton/Skeleton';
import { BANK_ACCOUNT_UPDATE } from '../../../../GraphQL/Mutations/Client';
import './EditBankDetailsDrawer.less';
import { firstKey } from '../../../../Helpers/Object/Object';
import useGraphQlNotifications from '../../../../Hooks/useGraphQLNotifications';

const EditBankDetailsDrawer = ({ actingClientUuid, onSuccess }) => {
  const country = useSelector(dealerDefaultCountry);
  const { context } = useParams();
  const action = context === 'investor' ? 'View' : 'Edit';
  const query = ACTING_CLIENT_BANK_ACCOUNT;
  const variables = { actingClientUuid };
  const { loading, data } = useQuery(query, { variables });
  const [BankAccountUpdate, { loading: updateLoading }] = useMutation(BANK_ACCOUNT_UPDATE, { refetchQueries: [{ query, variables }] });
  const {
    visible, setVisible, success, width,
  } = useNestedDrawer({ onSuccess });
  const { notificationFromResponse, notificationFromError } = useGraphQlNotifications();

  if (context === 'borrower') return null;

  const onFinish = (values) => {
    const {
      bankAccountNumber, bankAccountBsb, ...otherInput
    } = values;

    BankAccountUpdate({
      variables: {
        actingClientUuid,
        input: {
          bankAccountNumber: `${bankAccountBsb || ''} ${bankAccountNumber}`.trim(),
          ...otherInput,
        },
      },
    })
      .then((response) => {
        const passed = response?.data?.[firstKey(response?.data)]?.success;
        notificationFromResponse(
          response,
          'Bank account details saved.',
          'Bank account details could not be saved.',
        );
        if (passed) success();
      }).catch(notificationFromError);
  };

  return (
    <Row className="drawer-button">
      <Button type="primary" onClick={() => setVisible(true)}>
        {`${action} bank account details`}
      </Button>

      <Drawer
        title={`${action} bank account details`}
        width={width}
        closable
        onClose={() => setVisible(false)}
        open={visible}
        className="theme-edit-bank-details-drawer"
      >
        <Skeleton paragraph={1} loading={loading}>
          <div className="theme-update-client-form">
            <Form
              layout="vertical"
              onFinish={onFinish}
              initialValues={{ ...(data?.actingClientBankAccount) }}
            >
              <BankAccountNumber country={country} editable={action === 'Edit'} />


              { action === 'Edit' && (
              <Button
                type="primary"
                htmlType="submit"
                loading={updateLoading}
              >
                Save
              </Button>
              )}

            </Form>
          </div>
        </Skeleton>
      </Drawer>
    </Row>
  );
};

EditBankDetailsDrawer.propTypes = {
  actingClientUuid: string.isRequired,
  onSuccess: func.isRequired,
};

export default EditBankDetailsDrawer;
