import React from 'react';
import { string } from 'prop-types';
import { Col, Row } from 'antd';
import { useQuery } from '@apollo/client';
import { INVESTMENT_STATEMENT_BY_UUID_AND_DATE } from '../../../GraphQL/Queries/Investment';
import InvestmentStatementTable from '../../../Atoms/Tables/InvestmentStatementTable/InvestmentStatementTable';
import NoResult from '../../../Molecules/NoResult/NoResult';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';

const InvestmentSummaryStatement = ({ uuid }) => {
  const { data, loading } = useQuery(INVESTMENT_STATEMENT_BY_UUID_AND_DATE, { variables: { uuid, startDate: '', endDate: '' } });

  return (
    <>
      <Skeleton loading={loading}>
        { data?.investment?.transactions && (
          <div className="theme-investment-summary-statement">
            <Row gutter={[16, 32]}>
              <Col flex={24}>
                <InvestmentStatementTable
                  transactions={data.investment?.transactions}
                />
              </Col>
            </Row>
          </div>
        )}

        { !data
          && (
            <NoResult
              title="No transactions."
            />
          )}
      </Skeleton>
    </>
  );
};

InvestmentSummaryStatement.propTypes = {
  uuid: string.isRequired,
};

export default InvestmentSummaryStatement;
