import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import InvestmentsSummaryForm from '../../../Molecules/Forms/InvestmentsSummaryForm/InvestmentsSummaryForm';
import {
  INVESTMENTS_SUMMARY_BY_LOAN_UUID,
} from '../../../GraphQL/Queries/Investment';
import { BULK_INVESTMENT_STATEMENTS } from '../../../GraphQL/Mutations/Pdf';
import NoResult from '../../../Molecules/NoResult/NoResult';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import InvestmentsSummaryTable from '../../../Atoms/Tables/InvestmentsSummaryTable/InvestmentsSummaryTable';
import InvestmentSummaryStatement from './InvestmentSummaryStatement';
import SaveToPortalButton from '../SaveToPortalButton';

const InvestmentsSummary = () => {
  const [loan, setLoan] = useState();
  const [formValues, setFormValues] = useState({});
  const [savedToPortal, setSavedToPortal] = useState(false);
  const [query, { data, loading }] = useLazyQuery(INVESTMENTS_SUMMARY_BY_LOAN_UUID);
  const [mutation, { loading: mutating }] = useMutation(BULK_INVESTMENT_STATEMENTS);

  useEffect(() => data && setLoan(data?.loan), [data]);

  const onClickPreview = (values) => {
    setFormValues(values);
    setSavedToPortal(false);
    return query({
      variables: {
        loanUuid: values?.account,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      skip: !values?.account,
    });
  };

  const onClickGenerateStatements = () => {
    mutation({
      variables: {
        uuid: formValues?.account,
      },
    }).then(() => setSavedToPortal(true));
  };

  const renderStatement = record => (<InvestmentSummaryStatement key="stmt-{record.uuid}" uuid={record.uuid} />);

  return (
    <>
      <InvestmentsSummaryForm onFinish={onClickPreview} loading={loading} />
      <Skeleton loading={loading}>
        { data?.investmentsSummary && (
          <>
            <Row>
              <Col flex={24}>
                <InvestmentsSummaryTable
                  investments={data.investmentsSummary?.reportRows || []}
                  renderStatement={renderStatement}
                />
              </Col>
            </Row>
            <ButtonGroup direction="bottom-right" className="set-3-style">
              <SaveToPortalButton
                callBack={onClickGenerateStatements}
                loading={mutating}
                saved={savedToPortal}
              >
                Generate Investment Statements
              </SaveToPortalButton>
            </ButtonGroup>
          </>
        )}

        { !data && !loan
          && (
            <NoResult
              title="No loan found."
              body="Please try a different filter."
            />
          )}
      </Skeleton>
    </>
  );
};


export default InvestmentsSummary;
