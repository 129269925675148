import React from 'react';
import { Switch as AntdSwitch } from 'antd';
import {
  bool, func, node, oneOf, string,
} from 'prop-types';
import './Switch.less';

const Switch = ({ ...args }) => (
  <AntdSwitch {...args} />
);

Switch.propTypes = {
  autoFocus: bool,
  checked: bool,
  checkedChildren: node,
  className: string,
  defaultChecked: bool,
  disabled: bool,
  loading: bool,
  size: oneOf(['default', 'small']),
  unCheckedChildren: node,
  onChange: func,
  onClick: func,
};

Switch.defaultProps = {
  autoFocus: false,
  checked: false,
  checkedChildren: '',
  className: 'theme-switch',
  defaultChecked: false,
  disabled: false,
  loading: false,
  size: 'default',
  unCheckedChildren: '',
  onChange: () => {},
  onClick: () => {},
};

export default Switch;
