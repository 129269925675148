import React from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import Table from '../../Atoms/DataDisplay/Table/Table';
import { titleCase } from '../../Hooks/useStringFormatter';
import { USERS } from '../../GraphQL/Queries/User';
import Result from '../../Atoms/Feedback/Result/Result';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const UsersTable = () => {
  const limit = 50;
  const {
    loading, error, data, fetchMore,
  } = useQuery(USERS, {
    variables: {
      offset: 0, limit, sort: 'name', sortDirection: 'ASC',
    },
  });
  const isSmScreen = useMediaQuery({ query: '(max-width: 480px)' });
  const columns = [
    {
      title: 'Preferred Name',
      dataIndex: 'preferredName',
      render: value => titleCase(value || ''),
      align: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: value => value.toLowerCase(),
      align: 'left',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      render: value => value?.filter(role => !['anonymous', 'unauthenticated', 'authenticated'].includes(role))?.map(role => titleCase(role)).join(', '),
      align: 'left',
    },
    {
      title: 'Client ID',
      dataIndex: ['primary', 'fieldLmsClientId'],
      align: 'left',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: value => (value ? 'Active' : 'Blocked'),
      align: 'left',
      width: isSmScreen ? '4rem' : '12%',
    },
  ];


  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  return (
    <>
      <Table
        columns={columns}
        loading={loading}
        dataSource={data?.users?.results}
        pagination={false}
        rowKey="uuid"
      />

      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />

    </>
  );
};

export default UsersTable;
