import React from 'react';
import PropTypes from 'prop-types';
import { DocumentSelectIcon } from '../../../Resources/Icons';
import { convertByteCount } from '../../../Helpers/Helpers';
import { formatUnixDateTime } from '../../../Helpers/FormatDate';
import Drawer from '../../../Atoms/Layout/Drawer/Drawer';
import Image from '../../../Atoms/Media/Image/Image';
import Title from '../../../Atoms/Typography/Title/Title';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import Text from '../../../Atoms/Typography/Text/Text';
import './DocumentItemDrawer.less';
import DocumentLink from '../../../Atoms/Navigation/Links/DocumentLink';
import DocumentButtonControl from '../../../Molecules/Control/DocumentButtonControl';

const DocumentItemDrawer = (props) => {
  const {
    mediaUuid: uuid, title, files, isVisible, onClose, editable,
  } = props;

  const fileList = files.map(file => (
    <div key={file.uuid}>
      <Panel hasLink={false} type="noPadding background" className="theme-document-item">
        <DocumentLink
          localDocPath={file.path}
          file={file}
          tabIndex={0}
          className="contents"
          key={`drawer-file-${file.uuid}`}
        >
          <div className="img-wrapper">
            <Image
              preview={false}
              src={DocumentSelectIcon(file.filemime)}
              alt={file.uuid}
            />
          </div>
          <div className="content-wrapper">
            <Title level={5}>{file.filename}</Title>
            <div className="details-wrapper">
              <Text className="file-name">{convertByteCount(file.filesize)}</Text>
              <Text>{formatUnixDateTime(file.created)}</Text>
            </div>
          </div>
        </DocumentLink>
      </Panel>
    </div>

  ));

  return (
    <Drawer
      className="theme-document-item-drawer"
      title={title}
      open={isVisible}
      onClose={onClose}
      destroyOnClose
      footer={<DocumentButtonControl useCase="drawer" uuid={uuid} editable={editable} onCancel={onClose} />}
    >

      {fileList}
    </Drawer>
  );
};

DocumentItemDrawer.defaultProps = {
  isVisible: false,
  files: [],
};

DocumentItemDrawer.propTypes = {
  isVisible: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({
    mediaUuid: PropTypes.string,
    uuid: PropTypes.string,
    index: PropTypes.number,
  })),
  onClose: PropTypes.func.isRequired,
};

export default DocumentItemDrawer;
