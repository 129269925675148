import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { useHistory } from 'react-router';
import Tabs from '../../Atoms/DataDisplay/Tabs/Tabs';
import TabPane from '../../Atoms/DataDisplay/TabPane/TabPane';
import ClientsOrganism from '../../Organisms/Clients/Clients';
import DraftsOrganism from '../../Organisms/Clients/Drafts';
import ClientsHeader from '../../Molecules/HeaderTitle/ClientsHeader';

const Clients = ({ match: { params: { context, tabs } } }) => {
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(tabs);
  return (
    <div className="theme-clients page-inner">
      <ClientsHeader context={context} tabs={activeTab} history={useHistory()} />
      <Tabs
        defaultActiveKey={tabs || 'clients'}
        type="card"
        onChange={(active) => {
          history.push(`/app/${context}/clients/${active}`);
          setActiveTab(active);
        }}
      >
        <TabPane tab="Clients" key="clients">
          <ClientsOrganism />
        </TabPane>
        <TabPane tab="Saved drafts" key="drafts">
          <DraftsOrganism />
        </TabPane>
      </Tabs>
    </div>
  );
};

Clients.propTypes = {
  match: shape({
    params: shape({
      context: string.isRequired,
      tabs: string,
    }).isRequired,
  }).isRequired,
};

export default Clients;
