import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import fundService from '../../Services/ManagedFunds.service';
import AccountPanel from '../../Molecules/Panel/AccountPanel';

const ManagedFunds = () => {
  const [funds, setFunds] = useState([]);
  const context = 'dealer';

  useEffect(() => {
    fundService.managedFundsQuery()
      .then((response) => {
        if (response.data.data !== undefined) {
          const { data } = response.data;
          setFunds(data.funds.results);
        }
      });
  }, []);


  const managedFunds = funds.map((fund) => {
    const image = fund?.fieldImages?.[0];
    return (
      <AccountPanel
        key={fund.uuid}
        loanId={fund.fieldLmsPoolId}
        link={`/app/${context}/investment-funds/${fund.uuid}`}
        title={fund.label}
        rate={fund.fieldTargetRate}
        context={context}
        loanImage={image}
      />
    );
  });

  return (
    <div className="theme-managed-funds">
      <HeaderTitle
        title="Investment Funds"
        comment="Below is a list of your investment funds."
      />
      <div className="client-list">
        {managedFunds}
      </div>
    </div>
  );
};

export default ManagedFunds;
