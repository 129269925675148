import React from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../../Helpers/Forms';
import Input from '../../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';

const NzDriverLicence = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => (
  <>
    <InputGroup className="theme-driver-licence">
      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Licence number (5a)"
            name={getFieldName('licence5a', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Driver\'s licence version number (5a)', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('licence5a', parents), getFieldValue('errors'))}
          >
            <Input />
          </FormItem>
        )}
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Version number (5b)"
            name={getFieldName('licence5b', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Driver\'s licence version number (5b)', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('licence5b', parents), getFieldValue('errors'))}
          >
            <Input />
          </FormItem>
        )}
      </FormItem>
    </InputGroup>

    <DraggerFormItem
      comment="Please upload a copy of your drivers licence."
      fieldName={getFieldName('driverLicenceDocument', parents)}
      label="Upload identification"
      rules={[item => useValidationRules.softRequired(item, 'Driver\'s licence document', deleteExcept)]}
      shouldUpdateComponent={shouldUpdateComponent}
    />
  </>
);

NzDriverLicence.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

NzDriverLicence.defaultProps = {
  parents: ['nz_driver_licence'],
  deleteExcept: ['personVerification.licence5a', 'personVerification.licence5b', 'personVerification.driverLicenceDocument'],
};

export default NzDriverLicence;
