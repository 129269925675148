import React from 'react';
import { Col, Form, Row } from 'antd';
import { func, shape } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import Divider from '../../../../Atoms/Layout/Divider/Divider';
import LoanApplicationActions from '../../../../Molecules/Forms/Actions/LoanApplicationActions';
import IconButton from '../../../../Atoms/Forms/IconButton/IconButton';
import Popconfirm from '../../../../Atoms/Forms/Popconfirm/Popconfirm';
import {
  removeEntity, getAllStepEntities, getSteps, getRegisterEntities,
} from '../../../../Store/loanApplicationSlice';
import { errorsForEntityById, getEntityName } from '../../../../Hooks/useEntity';
import LoanApplicationListItem from '../../../../Molecules/ViewModes/LoanApplicationListItem/LoanApplicationListItem';
import LoanApplicationTCs from './LoanApplicationTCs';
import './LoanReviewStep.less';

const LoanReviewStep = (props) => {
  const { initialValues, onClickBack, onFinish } = props;
  const dispatch = useDispatch();
  const entities = useSelector(getRegisterEntities);
  const allStepEntities = useSelector(getAllStepEntities);
  const steps = useSelector(getSteps);
  const [form] = Form.useForm();

  const onDelete = ({ id }) => dispatch(removeEntity(id));
  const canProceed = allErrors => allStepEntities?.length >= steps?.length - 2 && !allErrors?.length;

  const entitiesList = [];
  const allErrors = [];
  if (entities !== undefined && entities.length > 0) {
    entitiesList.push(
      entities
        ?.sort((a, b) => Number(a?.stepKey) - Number(b?.stepKey))
        ?.map(
          (entity) => {
            const { id } = getEntityName(entity);
            const name = steps?.[entity?.stepKey]?.title ?? 'Step title';

            const validationErrors = errorsForEntityById(allStepEntities, steps, id);
            if (validationErrors.length) {
              allErrors.push({ step: id, validationErrors });
            }

            return (
              <>
                <LoanApplicationListItem
                  key={`entity-${id}`}
                  entity={{ ...entity, name }}
                  validationErrors={validationErrors}
                  deleteable={(
                    <Popconfirm
                      title={`Are you sure you want to delete ${name}`}
                      placement="topRight"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => onDelete(entity)}
                    >
                      <IconButton
                        type="delete"
                        size="small"
                      />
                    </Popconfirm>
              )}
                />
              </>
            );
          },
        ),
    );
  }

  return (
    <Row className="theme-loan-review-step">
      <Col className="gutter-row" span={12}>
        <HeaderTitle
          title="Review"
          commentType="grey-box"
          comment={(
            <Paragraph>
              Please review your loan application and complete any outstanding information. You can click on the status bar to return to each page of the application at any time.
            </Paragraph>
          )}
        />

        <div className="theme-panel-wrapper">
          {entitiesList}
        </div>

        <Form
          initialValues={initialValues}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          name="reviewForm"
        >
          <LoanApplicationTCs />
          <Divider />
          <LoanApplicationActions
            onClickBack={onClickBack}
            canProceed={canProceed(allErrors)}
            alternateButtonText={canProceed ? 'Agree and apply' : 'Fields required to proceed'}
            form={form}
          />
        </Form>
      </Col>
    </Row>
  );
};

LoanReviewStep.propTypes = {
  initialValues: shape({}),
  onClickBack: func,
  onFinish: func,
};

LoanReviewStep.defaultProps = {
  initialValues: {},
  onClickBack: () => {},
  onFinish: () => {},
};

export default LoanReviewStep;
