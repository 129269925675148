/* eslint-disable */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const applicationAdapter = createEntityAdapter({
  selectId: item => item.id,
});

const initialState = applicationAdapter.getInitialState({
  configuration: {},
  steps: [
    { title: 'Details' },
    { title: 'Security' },
    { title: 'Documents' },
    { title: 'Review' },
  ],
  step: 0,
  selected: 'new',
  stepHistory: [0],
  draftUuid: null,
  draftLoading: false,
  draftDeleting: false,
  createLoading: false,
});

export const getAllStepEntities = (state) => {
  if (state === undefined) {
    return [];
  }
  return applicationAdapter.getSelectors().selectAll(state.loanApplication);
};

export const loanApplicationSlice = createSlice({
  name: 'loanApplication',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    initFromDraft: (state, action) => {
      const draftData = action.payload;
      applicationAdapter.setAll(state, draftData);
    },
    setDraftUuid: (state, action) => {
      state.draftUuid = action.payload;
    },
    setDraftLoading: (state, action) => {
      state.draftLoading = action.payload;
    },
    setDraftDeleting: (state, action) => {
      state.draftDeleting = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
    setCreateError: (state, action) => {
      state.createError = action.payload;
    },
    setEntity: (state, action) => {
      const values = action.payload;
      // It is no values in EntityConfirmation step.
      if (values === undefined) {
        return;
      }

      applicationAdapter.setOne(state, values);
    },
    removeEntity: (state, action) => {
      const { payload: id } = action;
      const entities = applicationAdapter.getSelectors().selectAll(state);

      const ids = entities.reduce((carry, item) => {
        if (item.id === id) {
          carry.push(item.id);
        }
        if (item.parentId === id) {
          carry.push(item.id);
        }
        return carry;
      }, []);

      applicationAdapter.removeMany(state, ids);
    },
    navigateToStep: (state, action) => {
      const { payload: stepEntityId } = action;
      const stepEntity = applicationAdapter.getSelectors().selectAll(state).find(entity => entity.id === stepEntityId);
      state.step = parseInt(stepEntity.stepKey);
    },
    reset: (state) => {
      applicationAdapter.removeAll(state);
      state.configuration = initialState.configuration;
      state.steps = initialState.steps;
      state.step = initialState.step;
      state.selected = initialState.selected;
      state.stepHistory = initialState.stepHistory;
      state.draftUuid = initialState.draftUuid;
      state.draftLoading = initialState.draftLoading;
      state.draftDeleting = initialState.draftDeleting;
      state.createLoading = initialState.createLoading;
    }
  },
});

export const {
  setStep,
  setSteps,
  initFromDraft,
  setDraftUuid,
  setCreateLoading,
  setCreateError,
  setDraftLoading,
  setDraftDeleting,
  setEntity,
  removeEntity,
  navigateToStep,
  reset,
} = loanApplicationSlice.actions;

export const getRegisterEntities = (state) => {
  if (state === undefined) {
    return [];
  }
  return applicationAdapter
    .getSelectors()
    .selectAll(state.loanApplication);
};

export const getStep = state => state.loanApplication.step;
export const getSteps = state => state.loanApplication.steps;

export const getDraftUuid = state => state.loanApplication.draftUuid;
export const getDraftLoading = state => state.loanApplication.draftLoading;
export const getDraftDeleting = state => state.loanApplication.draftDeleting;
export const getCreateLoading = state => state.loanApplication.createLoading;
export const getCreateError = state => state.loanApplication.createError;

export default loanApplicationSlice.reducer;
