import React from 'react';
import {
  node, number, string, oneOf,
} from 'prop-types';
import Title from '../../../Atoms/Typography/Title/Title';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';


const SectionTitle = ({
  title, level, comment, children, commentType,
}) => (
  <div className="theme-section-title">
    <Title level={level}>{title}</Title>
    { comment && (
      <Paragraph className="comment" type={commentType}>
        {comment}
      </Paragraph>
    )}
    { children }
  </div>
);

SectionTitle.propTypes = {
  title: string.isRequired,
  level: number,
  comment: node,
  commentType: oneOf(['default', 'grey-box']),
  children: node,
};

SectionTitle.defaultProps = {
  level: 4,
  comment: undefined,
  commentType: 'default',
  children: undefined,
};

export default SectionTitle;
