import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Title from '../../../Atoms/Typography/Title/Title';
import Table from '../../../Atoms/DataDisplay/Table/Table';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import Card from '../../../Molecules/Cards/Card';
import { REDUCTIONS } from '../../../GraphQL/Queries/Reduction';
import Result from '../../../Atoms/Feedback/Result/Result';
import Button from '../../../Atoms/Forms/Button/Button';
import Drawer from '../../../Atoms/Feedback/Drawer/Drawer';
import EditReductionForm from '../../../Organisms/Forms/Accounts/Investments/SecondaryMarket/EditReductionForm';
import { formatUnixDate } from '../../../Helpers/FormatDate';

import InfiniteScroll from '../../../Molecules/InfiniteScroll';

const Reductions = ({ investmentUuid }) => {
  const limit = 50;
  const [showForm, setShowForm] = useState(false);
  const [currentReduction, setCurrentReduction] = useState(null);
  let variables = { offset: 0, limit };
  if (investmentUuid) variables = { ...variables, investmentUuid };
  const {
    loading, error, data, fetchMore, refetch,
  } = useQuery(REDUCTIONS, { variables });

  const onOpenEditForm = (reduction) => {
    setCurrentReduction(reduction);
    setShowForm(true);
  };

  const onClose = () => {
    setShowForm(false);
  };

  const isSmScreen = useMediaQuery({ query: '(max-width: 480px)' });

  const columns = [
    {
      title: 'Investment ID',
      dataIndex: 'fieldReducedInvestment',
      align: 'left',
      render: (_, { fieldReducedInvestment: { uuid, fieldLmsAccountId } }) => (<Link to={`/app/dealer/investment/${uuid}`}>{fieldLmsAccountId}</Link>),
    },
    {
      title: 'Status \n /Action',
      align: 'left',
      width: '5.2rem',
      render: record => (
        <>
          {record.fieldStatus?.toUpperCase()}
          <br />
          {record.fieldAction?.replace(/_/g, ' ')}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Status',
      dataIndex: 'fieldStatus',
      render: value => value?.toUpperCase(),
      responsive: ['sm'],
      defaultFilteredValue: [
        'pending',
        'active',
        'complete',
      ],
      filters: [
        { text: 'Pending', value: 'pending' },
        { text: 'Active', value: 'active' },
        { text: 'Complete', value: 'complete' },
        { text: 'Cancelled', value: 'cancelled' },
      ],
      onFilter: (value, { fieldStatus }) => fieldStatus.includes(value),
    },
    {
      title: 'Action',
      dataIndex: 'fieldAction',
      render: value => value?.replace(/_/g, ' '),
      responsive: ['sm'],
      filters: [
        { text: 'Cancel now', value: 'cancel_now' },
        { text: 'Cancel on expiry', value: 'cancel_on_expiry' },
        { text: 'Accept now', value: 'accept_now' },
        { text: 'Accept on expiry', value: 'accept_on_expiry' },
      ],
      onFilter: (value, { fieldAction }) => fieldAction.includes(value),
    },
    {
      title: 'Created \n /Expires',
      align: 'right',
      render: record => (
        <>
          {formatUnixDate(record.created)}
          <br />
          {formatUnixDate(record.fieldExpiry)}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Created',
      dataIndex: 'created',
      align: 'right',
      render: value => formatUnixDate(value),
      responsive: ['sm'],
    },
    {
      title: 'Expires',
      dataIndex: 'fieldExpiry',
      align: 'right',
      render: value => formatUnixDate(value),
      responsive: ['sm'],
    },
    {
      title: 'Offered',
      dataIndex: 'fieldAmount',
      align: 'right',
      render: value => <Currency value={value} />,
    },
    {
      title: 'Funded \n /Fee',
      align: 'right',
      width: '3.9rem',
      render: record => (
        <>
          <Currency value={record.amountFunded} />
          <br />
          <Currency value={record.fieldBreakFee} />
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Funded',
      dataIndex: 'amountFunded',
      align: 'right',
      responsive: ['sm'],
      render: value => <Currency value={value} />,
    },
    {
      title: 'Fee',
      dataIndex: 'fieldBreakFee',
      align: 'right',
      responsive: ['sm'],
      render: value => <Currency value={value} />,
    },
    {
      title: 'OP',
      dataIndex: 'entityPermissions',
      align: 'right',
      width: isSmScreen ? '3rem' : '7rem',
      render: (value, row) => {
        const { permitted: canBeEdited } = value.find(({ label }) => label === 'Can edit reduction') || { permitted: false };
        return canBeEdited && (
          <Button size={isSmScreen ? 'small' : 'middle'} type="secondary" onClick={() => onOpenEditForm(row)}>
            { isSmScreen ? <FontAwesomeIcon icon={faPenToSquare} /> : 'Edit' }
          </Button>
        );
      },
    },
  ];

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  return (
    <Card
      title={(<Title level={4}>Reductions</Title>)}
      className="theme-all-reductions"
    >
      <Table
        type="inner"
        columns={columns}
        loading={loading}
        dataSource={data?.investmentReductions?.results}
        pagination={false}
        rowKey="uuid"
      />


      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />

      <Drawer
        title={<Title level={2}>Edit reduction</Title>}
        size="large"
        destroyOnClose
        open={showForm}
        onClose={() => setShowForm(false)}
      >
        <EditReductionForm reduction={currentReduction} onCancel={onClose} refetch={refetch} />
      </Drawer>
    </Card>
  );
};

Reductions.propTypes = {
  investmentUuid: string,
};

Reductions.defaultProps = {
  investmentUuid: undefined,
};

export default Reductions;
