import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  bool, shape, string,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Atoms/Forms/Button/Button';
import Image from '../../Atoms/Media/Image/Image';
import Title from '../../Atoms/Typography/Title/Title';
import Text from '../../Atoms/Typography/Text/Text';
import './ActivateLogin.less';
import { USER_ONETIME_LOGIN_LINK } from '../../GraphQL/Mutations/User';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { getGraphQlErrorMessage } from '../../Helpers/Error';
import Divider from '../../Atoms/Layout/Divider/Divider';
import SetPassword from '../SetPassword/SetPasword';
import ExpiredOneTimeLoginLink
  from '../../Organisms/Forms/ExpiredToken/ExpiredOneTimeLoginLink';
import { setAuth, userUuid as _userUuid } from '../../Store/userSlice';

const ActivateLogin = ({
  template: {
    logo,
  },
}) => {
  const { alt, preview } = logo;
  const { token } = useParams();
  const [userOneTimeLoginLink, { loading, error }] = useMutation(USER_ONETIME_LOGIN_LINK);
  const [setPasswordForm, setSetPasswordForm] = useState({ visibility: false });
  const dispatch = useDispatch();
  // @todo Implement test after redux new uuid.
  const userUuid = useSelector(_userUuid);

  const logoSrc = `/assets/logos/logo-${process.env.REACT_APP_X_DEALER}.svg`;

  useEffect(() => {
    userOneTimeLoginLink({ variables: { token } })
      .then((result) => {
        const { data: { userOneTimeLoginLink: { newPassword, auth: { auth, user } } } } = result;
        dispatch(setAuth({ user, auth }));
        setSetPasswordForm({ visibility: true, user, newPassword });
      });
  }, []);

  const errorMessage = (errorTitle, errorSubTitle, showPasswordReset = true) => (
    <div className="theme-activate-login">
      <Image alt={alt} src={logoSrc} preview={preview} />
      <Title level={1}>{errorTitle}</Title>
      <Text>
        {errorSubTitle}
      </Text>
      <Divider dashed />
      {!userUuid && showPasswordReset && (
        <Button block href="/app/forgot-password" ghost type="primary">
          Request a new password
        </Button>
      )}
      {userUuid && (
        <Button block href="/app" ghost type="primary">
          Back to app
        </Button>
      )}
    </div>
  );

  if (error) {
    const { networkError: { statusCode } } = error;
    const theMessage = getGraphQlErrorMessage(error);

    // Expired token.
    if (statusCode === 422) {
      return <ExpiredOneTimeLoginLink token={token} />;
    }

    if (theMessage === 'Blocked or invalid user.') {
      return errorMessage(
        'Invalid activation link.',
        '',
        false,
      );
    }

    if (statusCode === 403) {
      return errorMessage(
        'You are already logged in.',
        '',
      );
    }

    return errorMessage('Sorry, something went wrong!', getGraphQlErrorMessage(error));
  }

  if (setPasswordForm.visibility) {
    return (
      <SetPassword user={setPasswordForm.user} tempPassword={setPasswordForm.newPassword} />
    );
  }

  return (<Skeleton loading={loading} />);
};

ActivateLogin.propTypes = {
  template: shape({
    title: string,
    description: string,
    logo: shape({
      src: string.isRequired,
      alt: string,
      preview: bool,
    }),
  }),
};

// This should change because props would come from an api call
ActivateLogin.defaultProps = {
  template: {
    title: 'Set an account password',
    logo: {
      src: null,
      alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
      preview: false,
    },
  },
};

export default ActivateLogin;
