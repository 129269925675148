import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import Title from '../../Atoms/Typography/Title/Title';
import Text from '../../Atoms/Typography/Text/Text';
import Button from '../../Atoms/Forms/Button/Button';
import { dealer } from '../../Hooks/useLocalStorage';
import './PortalSwitcher.less';
import LangContext from '../../Context/Lang/LangContext';

const PortalSwitcher = () => {
  const { t } = useContext(LangContext);
  const brand = dealer() || 'zagga';
  return (
    <div className="theme-portal-switcher">
      <Title>
        Welcome to my
        {' '}
        { process.env.REACT_APP_BRAND }
      </Title>
      <Text>
        To go to your my
        {' '}
        { process.env.REACT_APP_BRAND }
        {' '}
        account, please select one of the options below.
      </Text>
      <Row gutter={[24, 12]}>
        <Col xs={12} sm={4}>
          <Button ghost block href="/app/borrower/dashboard" type="secondary">{t(`portalSwitcher.${brand}.borrower`)}</Button>
        </Col>
        <Col xs={12} sm={4}>
          <Button block href="/app/investor/dashboard" type="secondary">{t(`portalSwitcher.${brand}.investor`)}</Button>
        </Col>
        <Col xs={12} sm={4}>
          <Button block href={`/app/broker/${brand === 'apm' ? 'clients' : 'dashboard'}`} ghost type="secondary">
            {t(`portalSwitcher.${brand}.broker`)}
          </Button>
        </Col>
      </Row>

    </div>
  );
};

export default PortalSwitcher;
