/* eslint-disable no-underscore-dangle */
import moment from 'moment';

// Common methods used by client & loan input data conversion.

export const appendMissingCountryCode = (input, dealerCountry) => {
  const { country_code: countryCode, ...returnData } = input;
  // eslint-disable-next-line camelcase
  return { country_code: (countryCode || dealerCountry), ...returnData };
};

export const flattenEntities = entities => entities.reduce((previous, next) => ({ ...previous, ...next }));

export const extractDocument = (files, categories, access = 'private') => files && files.length > 0 && { categories, access, files: files.map(({ fid }) => fid) };

export const filterByProperties = (input, properties) => properties
  .map(property => (input[property] && { [property]: input[property] }))
  .reduce((accumulator, current) => ({ ...accumulator, ...current }), {});

export const filterChildren = (children, step = null, substep = null) => (children && children.filter(
  items => (step && items?.stepKey === step) || (substep && items?.subStepKey === substep),
)) || [];

export const findChild = (children, step = null, substep = null) => filterChildren(children, step, substep).pop();

const recurseInput = (input, callback, param = {}) => {
  if (Array.isArray(input)) {
    return input.map(item => callback(item, param));
  }
  if (input && (input.constructor.name === 'Object')) {
    return Object.entries(input).reduce((p, [k, v]) => ({ ...p, [k]: callback(v, param) }), {});
  }
  return input;
};

export const convertMomentsInInputData = (input) => {
  if (input && moment.isMoment(input)) {
    return input.format('YYYY-MM-DD');
  }
  return recurseInput(input, convertMomentsInInputData);
};

export const convertOutputDatesToMoment = (data, schemaDateFields) => {
  if (!data || !schemaDateFields) {
    return data;
  }
  if (data?.__schema) {
    return data;
  }

  const convertValue = (key, fields, val) => {
    if (!val) {
      return val;
    }
    if (fields.includes(key)) {
      return moment(val);
    }
    return convertOutputDatesToMoment(val, schemaDateFields);
  };

  // eslint-disable-next-line no-prototype-builtins
  if (data.hasOwnProperty('__typename') && schemaDateFields.hasOwnProperty(data.__typename)) {
    const dateFields = schemaDateFields[data.__typename];
    if (dateFields.length > 0) {
      return Object.entries(data).reduce((prev, [key, val]) => ({ ...prev, [key]: convertValue(key, dateFields, val) }), {});
    }
  }

  return recurseInput(data, convertOutputDatesToMoment, schemaDateFields);
};

export const convertDraftDatesToMoment = (input) => {
  if (input && (typeof input === 'string') && input.startsWith('moment:')) {
    return moment(input.replace('moment:', ''));
  }
  return recurseInput(input, convertDraftDatesToMoment);
};

export const convertDraftMomentsToDate = (input) => {
  if (input && moment.isMoment(input)) {
    return `moment:${input.format('YYYY-MM-DD')}`;
  }
  return recurseInput(input, convertDraftMomentsToDate);
};

export const preprocessInputData = (input, dealerCountry) => {
  // eslint-disable-next-line no-prototype-builtins
  if (input && input.hasOwnProperty('address_line1')) {
    return appendMissingCountryCode(input, dealerCountry);
  }
  return recurseInput(input, preprocessInputData, dealerCountry);
};

const useCommonConvertedInputData = {
  appendMissingCountryCode,
  extractDocument,
  filterByProperties,
  filterChildren,
  findChild,
  flattenEntities,
  convertMomentsInInputData,
  convertOutputDatesToMoment,
  convertDraftDatesToMoment,
  convertDraftMomentsToDate,
  preprocessInputData,
};

export default useCommonConvertedInputData;
