import React from 'react';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import Text from '../../../../Atoms/Typography/Text/Text';
import { dealerDefaultCountry } from '../../../../Hooks/useLocalStorage';

const LoanApplicationTCs = () => {
  const isAu = dealerDefaultCountry() === 'AU';
  if (!isAu) return null;

  return (
    <div className="theme-loan-application-tcs">
      <Paragraph>
        By clicking
        <Text italic> Agree and apply </Text>
        you confirm that you have read Zagga’s
        <a href="/static-document/privacy-policy"> privacy policy </a>
        including the notification regarding how Zagga may use your credit information (notifiable matters) and the
        website
        <a href="/static-document/website-terms"> terms and conditions </a>
        and agree to our collection, storage, use and disclosure of your personal, credit and credit eligibility
        information (e.g. your CAS) in this manner, including to assess your loan application, obtain a credit report,
        conduct a valuation, source funding, and identify you.
      </Paragraph>
      <Paragraph>
        You further consent to receiving notices and other documents from us by email to the email address you provided,
        and you understand that if you give this consent:
      </Paragraph>
      <ul>
        <li>we may no longer send you paper copies of notices and other documents</li>
        <li>
          you should regularly check your email account for documents, including the spam folder, as an email will
          be deemed received once it enters your computer system
        </li>
        <li> you may withdraw your consent to receiving documents by email at any time by contacting us.</li>
      </ul>
    </div>
  );
};

LoanApplicationTCs.propTypes = {

};

export default LoanApplicationTCs;
