import { gql } from '@apollo/client';

export const SHAREABLE_DOCUMENT = gql`
  query shareable($uuid: String! $token: String!) {
    shareableDocument(uuid: $uuid, token: $token) {
      name
      description
      files {
        uuid
        path
        url
        filename
        filesize
        filemime
      }
    }
  }
`;

export const STATIC_DOCUMENT = gql`
  query staticDocument($documentKey: StaticDocumentKey) {
      staticDocument(documentKey: $documentKey) {
          uuid
          dealer
          file {
            uuid
            filename
            filemime
            url
          }
      }
  }
`;


export const DOCUMENT = gql`
  query document($uuid: String!) {
    document(uuid: $uuid) {
      uuid
      fieldMediaFiles {
        filemime
        filename
        filesize
        path
        url
        uuid
      }
    }
  }
`;

const Document = {
  SHAREABLE_DOCUMENT,
  STATIC_DOCUMENT,
  DOCUMENT,
};

export default Document;
