import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';
import './Link.less';

const Link = ({ ...args }) => {
  const { children, to, className } = args;
  const newArgs = { ...args };
  delete newArgs.className;

  if (!to) return <a className={['theme-link', className].join(' ')} {...newArgs}>{children}</a>;
  if (/^https?:\/\//.test(to)) return <a className={['theme-link', className].join(' ')} href={to} {...newArgs}>{children}</a>;
  return <ReactLink className={['theme-link', className].join(' ')} {...newArgs}>{children}</ReactLink>;
};

Link.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  to: PropTypes.string,
  target: PropTypes.string,
};

Link.defaultProps = {
  children: null,
  title: null,
  target: null,
  to: null,
};

export default Link;
