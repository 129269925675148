import React from 'react';
import {
  arrayOf, number, oneOfType, string,
} from 'prop-types';
import { Col } from 'antd';
import Title from '../../Typography/Title/Title';
import './StepBlock.less';

const StepBlock = (props) => {
  const { title, dataSource } = props;
  return (
    <Col xs={12} md={4} className="theme-step-block">
      <div className="inner">
        <Title level={4}>{title}</Title>
        {dataSource.map(item => <li key={item}>{item}</li>)}
      </div>
    </Col>
  );
};

StepBlock.propTypes = {
  title: string,
  dataSource: oneOfType([arrayOf(string), arrayOf(number)]),
};

StepBlock.defaultProps = {
  title: '',
  dataSource: [],
};

export default StepBlock;
