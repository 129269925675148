import { gql } from '@apollo/client';

export const CREATE_REDUCTION = gql`
  mutation CreateReduction(
    $investmentUuid: String!
    $fieldAmount: Float!
    $fieldAction: InvestmentReductionAction
    $fieldStatus: InvestmentReductionStatus
    $fieldExpiry: Int!
    $fieldBreakFee: Float
  ) {
    reductionRequest(
      investmentUuid: $investmentUuid
      fieldAmount: $fieldAmount
      fieldAction: $fieldAction
      fieldStatus: $fieldStatus
      fieldExpiry: $fieldExpiry
      fieldBreakFee: $fieldBreakFee
  ) {
      reduction {
        uuid
        fieldStatus
        created
      }
      errors
    }
  }
`;

export const UPDATE_REDUCTION = gql`
  mutation UpdateReduction(
    $uuid: String!
    $fieldAmount: Float
    $fieldAction: InvestmentReductionAction
    $fieldStatus: InvestmentReductionStatus
    $fieldExpiry: Int
    $fieldBreakFee: Float
  ) {
    reductionUpdate(
      uuid: $uuid
      fieldAmount: $fieldAmount
      fieldAction: $fieldAction
      fieldStatus: $fieldStatus
      fieldExpiry: $fieldExpiry
      fieldBreakFee: $fieldBreakFee
  ) {
      reduction {
        uuid
        fieldStatus
        created
      }
      errors
    }
  }
`;

export const CANCEL_REDUCTION = gql`
  mutation UpdateReduction(
    $uuid: String!
  ) {
    reductionUpdate(
      uuid: $uuid
      fieldStatus: cancelled
      fieldAction: cancel_on_expiry
  ) {
      reduction {
        uuid
        fieldStatus
        created
      }
      errors
    }
  }
`;

export const DELETE_REDUCTION = gql`
  mutation DeleteReduction(
    $uuid: String!
  ) {
    reductionDelete(
      uuid: $uuid
  ) {
      success  
      message
    }
  }
`;

export const Reduction = {
  CREATE_REDUCTION,
  UPDATE_REDUCTION,
  CANCEL_REDUCTION,
  DELETE_REDUCTION,
};

export default Reduction;
