/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AutoComplete,
} from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { capitalize } from '../../../Helpers/Helpers';
import accountService from '../../../Services/Accounts.service';
import clientService from '../../../Services/Clients.service';
import useDebounceSearch from '../../../Hooks/useDebounceSearch';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../Atoms/Forms/Input/Input';

const { Option } = AutoComplete;

const accountLookup = async (searchTerm) => {
  const response = await accountService.accountsLookup(searchTerm);
  const { data: responseData } = response;
  if (responseData.data !== undefined && responseData.data.accounts.results !== undefined) {
    return responseData.data.accounts.results.map(account => ({ value: account.uuid, text: account.label }));
  }
  return [];
};

const clientLookup = async (searchTerm) => {
  const response = await clientService.clientCollection(searchTerm, '', '');
  const { data: responseData } = response;
  if (responseData.data && responseData.data.clients.results) {
    return responseData.data.clients.results.map(client => ({ value: client.uuid, text: `${client.fieldLmsClientId} ${client.name}` }));
  }
  return [];
};

const renderOptions = ({ text, value }) => <Option key={value} text={text}>{text}</Option>;

const DocumentEntitySearch = (props) => {
  const {
    entityType,
    searchEvent,
    setValue,
    setText,
    text,
    validationMessage,
    value,
  } = props;

  const onSelect = (passedValue, element) => {
    setValue(passedValue);
    setText(element.text);
  };

  const entityLabel = capitalize(entityType);

  const { setInputText, search } = useDebounceSearch(searchEvent);

  const hasSelectedValue = text && value;

  let data = [];

  if (search.status === 'loading') {
    data = [{
      text: 'Loading ...',
      value: '*',
    }];
  }

  if (search.result !== undefined) {
    data = search.result;
  }

  if (hasSelectedValue) {
    data = [{ text, value }];
  }

  return (
    <FormItem
      label={entityLabel}
      className="account-search"
      name={`${entityType}Value`}
      rules={!hasSelectedValue && ([{
        required: true,
        message: validationMessage || 'This field is required',
      }])}
    >
      {!hasSelectedValue
        ? (
            <AutoComplete
              dataSource={data.map(renderOptions)}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              onChange={setInputText}
              onSelect={onSelect}
              placeholder={`Search for ${entityType}`}
            />
        )
        : (
          <>
            <Input
              allowClear={{
                clearIcon:
                  <div
                    onClick={() => onSelect(null, { text: null })}
                    onKeyDown={() => onSelect(null, { text: null })}
                  >
                    <CloseCircleFilled />
                  </div>,
              }}
              value={text}
            />
          </>
        )}
    </FormItem>
  );
};

DocumentEntitySearch.propTypes = {
  entityType: PropTypes.string.isRequired,
  setValue: PropTypes.func,
  setText: PropTypes.func,
  searchEvent: PropTypes.func.isRequired,
  text: PropTypes.string,
};

DocumentEntitySearch.defaultProps = {
  setValue: null,
  setText: null,
  text: null,
};

export {
  accountLookup,
  clientLookup,
};

export default DocumentEntitySearch;
