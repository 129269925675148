export const isOfBundle = (entity) => {
  const { entityBundle, type } = entity || { entityBundle: undefined, type: undefined };
  return entityBundle || type;
};

export const isIndividual = entity => isOfBundle(entity) === 'individual';
export const isIndividuals = entity => isOfBundle(entity) === 'individuals';
export const isCompany = entity => isOfBundle(entity) === 'company';
export const isTrust = entity => isOfBundle(entity) === 'trust';

export const getEntityName = (entity) => {
  if (!entity) return { name: undefined, type: undefined };

  if (isIndividual(entity)) {
    const { firstName, lastName } = entity;
    const name = `${firstName} ${lastName}`;
    return { ...entity, name };
  }

  return entity;
};

export const entitiesToTree = (entities) => {
  if (!entities) return [];

  const entitiesWithoutParents = entities.filter(entity => !entity.parentId);

  const getChildren = (parentId, contextEntities) => {
    if (contextEntities.length === 0) {
      return [];
    }
    const children = contextEntities.filter(entity => entity.parentId === parentId);
    return (children.length && parentId && children.map(child => ({
      ...child,
      children: getChildren(child.id, contextEntities),
    }))) || [];
  };

  return entitiesWithoutParents.map(entity => ({
    ...entity,
    children: getChildren(entity.id, entities),
  }));
};

export const errorsForEntityById = (allStepEntities, steps, id) => {
  const directChildrenEntities = allStepEntities.filter(entity => entity.id === id || (entity.parentId === id && !entity.entityBundle));

  return directChildrenEntities.reduce((acc, entity) => {
    const { errors, id: entityId } = entity;
    if (errors) {
      return [...acc, ...errors.map(error => ({ ...error, id: entityId }))];
    }
    return acc;
  }, []);
};
