import React from 'react';
import {
  bool, func, node, oneOf, number, shape, string,
} from 'prop-types';
import { Progress as AntdProgress } from 'antd';
import './Progress.less';

const Progress = args => <AntdProgress {...args} className="theme-progress" />;

Progress.propTypes = {
  format: func,
  percent: number,
  showInfo: bool,
  status: string,
  strokeColor: node,
  strokeLinecap: oneOf(['round', 'square']),
  success: shape({
    percent: number,
    strokeColor: string,
  }),
  trailColor: string,
  type: oneOf(['line', 'circle', 'dashboard']),
  steps: number,
};

Progress.defaultProps = {
  format: percent => `${percent}%`,
  percent: 0,
  showInfo: true,
  status: '',
  strokeColor: '',
  strokeLinecap: '',
  success: '',
  trailColor: '',
  type: 'line',
  steps: '',
};

export default Progress;
