import React, { createElement, useState } from 'react';
import {
  arrayOf, elementType, func, number, shape, string,
} from 'prop-types';
import { Form } from 'antd';
import Steps from '../../Steps/Steps';
import Step from '../../../Atoms/Navigation/Step/Step';

const MultiStepForm = (props) => {
  const {
    initialValues, step, steps, onClickProceed, prevStep, setStep, stepStatus,
  } = props;
  const [formsData, setFormsData] = useState([]);

  const onFormFinish = (formName, info) => {
    if (onClickProceed) return onClickProceed?.(info?.values);

    const prevFormDataIndex = formsData.findIndex(formData => formData.step === step);
    const nextFormsData = formsData;
    const { values } = info;

    if (prevFormDataIndex !== -1) {
      nextFormsData[prevFormDataIndex].values = values;
    }

    if (prevFormDataIndex === -1) {
      nextFormsData.push({ form: steps[step].title, values, step });
    }

    setFormsData(nextFormsData);
    return null;
  };

  const onClickBack = step > 0 ? () => prevStep() : null;

  const getStepStatus = (item) => {
    const itemStep = steps.findIndex(element => element?.title === item?.title);

    if (stepStatus) return stepStatus?.({ ...item, step: itemStep });

    if (itemStep === step) return 'process';
    const itemData = formsData?.find(formData => formData?.step === itemStep);
    if (itemData?.values?.errors?.length) return 'error';
    if (itemData?.values) return 'finish';
    return 'wait';
  };

  const getInitialValues = () => {
    if (initialValues) return initialValues(step);
    return formsData?.find(formData => formData.step === step)?.values;
  };

  return (
    <>
      <Steps
        size="default"
        current={step}
        onChange={step ? value => setStep(value) : null}
      >
        { steps.map(item => (
          <Step
            key={item?.title}
            title={item?.title}
            disabled={steps.length <= item || item?.clickable === false}
            status={getStepStatus(item)}
          />
        )) }
      </Steps>

      <Form.Provider onFormFinish={onFormFinish}>
        { steps?.[step]?.component && createElement(steps?.[step]?.component, {
          onClickBack,
          initialValues: getInitialValues(step),
        })}
      </Form.Provider>
    </>
  );
};

MultiStepForm.propTypes = {
  steps: arrayOf(shape({
    title: string,
    component: elementType,
  })).isRequired,
  step: number,
  onClickProceed: func,
  prevStep: func,
  setStep: func,
  stepStatus: func,
  initialValues: func,
};

MultiStepForm.defaultProps = {
  step: undefined,
  onClickProceed: undefined,
  prevStep: undefined,
  setStep: undefined,
  stepStatus: undefined,
  initialValues: undefined,
};

export default MultiStepForm;
