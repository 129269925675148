import React, { useState } from 'react';
import {
  string, shape,
} from 'prop-types';
import { Markup } from 'react-render-markup';
import Text from '../../../Atoms/Typography/Text/Text';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import Modal from '../../../Atoms/Feedback/Modal/Modal';

const UpcomingOpportunitiesListItem = ({ opportunity }) => {
  const {
    title,
    fieldDescription,
  } = opportunity;


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Panel>
      <Text onClick={showModal}>{title}</Text>
      <Modal width="800px" mask={false} cancelText="" okText="" visible={isModalVisible} onCancel={handleCancel}>
        <Markup markup={fieldDescription} />
      </Modal>
    </Panel>
  );
};

UpcomingOpportunitiesListItem.propTypes = {
  opportunity: shape({
    title: string,
    changed: string,
    fieldState: string,
    fieldDescription: string,
  }).isRequired,
};

export default UpcomingOpportunitiesListItem;
