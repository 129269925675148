import { number } from 'prop-types';
import React from 'react';
import Title from '../../../Atoms/Typography/Title/Title';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import Tooltip from '../../../Atoms/DataDisplay/Tooltip/Tooltip';
import Link from '../../../Atoms/Navigation/Links/Link';

const BreakFee = ({
  breakFee,
}) => (
  <div className="theme-remaining-investment">
    <Title level={5}>Break fee</Title>
    <Title level={5}>
      <Currency value={breakFee} />
    </Title>
    <Tooltip title="Zagga Secondary Market Rules">
      For more information see our
      {' '}
      <Link
        label="Zagga Secondary Market Rules"
        href="/static-document/secondary-market-rules"
        target="_blank"
        title="Zagga Secondary Market Rules"
      >
        Zagga Secondary Market Rules
      </Link>
    </Tooltip>
  </div>
);

BreakFee.propTypes = {
  breakFee: number.isRequired,
};
export default BreakFee;
