import React from 'react';
import { string } from 'prop-types';
import { Col, Row } from 'antd';
import SimpleDashBoardCard from '../../Molecules/Cards/Dashboard/SimpleDashBoardCard';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import Text from '../../Atoms/Typography/Text/Text';
import ApplicationsDashBoardCard from '../../Molecules/Cards/Dashboard/ApplicationsDashBoardCard';
import UpcomingOpportunitiesDashBoardCard from '../../Molecules/Cards/Dashboard/UpcomingOpportunitiesDashBoardCard';
import MyInvestmentsDashBoardCard from '../../Molecules/Cards/Dashboard/MyInvestmentsDashBoardCard';
import MyInvestmentsDashBoardCardAU from '../../Molecules/Cards/Dashboard/MyInvestmentsDashBoardCardAU';
import { getBrand } from '../../Hooks/useLocalStorage';
import './DashBoardCards.less';

// eslint-disable-next-line react/prop-types
const MyLoginBlock = ({ context: theContext }) => (
  <SimpleDashBoardCard
    title="My login"
    btnText="my login"
    btnLink={`/app/${theContext}/profile`}
  >
    <Paragraph>
      You can update your details such as
      <ul>
        <li>Email</li>
        <li>Preferred name</li>
        <li>Contact number</li>
        <li>Password</li>
      </ul>
    </Paragraph>
  </SimpleDashBoardCard>
);

const MyInvestmentsBlock = () => (
  <MyInvestmentsDashBoardCard>
    <>
      <Paragraph>
        Funding commitments.
      </Paragraph>
      <Paragraph>
        You do not currently have any funding commitments.
      </Paragraph>
    </>
  </MyInvestmentsDashBoardCard>
);

const MyCommitmentsBlock = () => (
  <MyInvestmentsDashBoardCardAU>
    <>
      <Paragraph>
        Funding commitments.
      </Paragraph>
      <Paragraph>
        You do not currently have any funding commitments.
      </Paragraph>
    </>
  </MyInvestmentsDashBoardCardAU>
);

// eslint-disable-next-line react/prop-types
const SearchLoansBlock = ({ title }) => (
  <SimpleDashBoardCard
    title={title || 'Search Loans'}
    btnText="Search"
    btnLink="/app/investor/search"
  >
    <Text>Search and view all investments currently available for funding via our platform.</Text>
  </SimpleDashBoardCard>
);

const DashBoardCards = (props) => {
  const { context } = props;
  const brand = getBrand();

  const config = [
    {
      brand: 'zagga',
      context: 'borrower',
      cards: [
        {
          key: 'apply-for-a-loan',
          title: 'Apply for a loan',
          component:
  <SimpleDashBoardCard
    title="Apply for a loan"
    btnText="Apply"
    btnLink="/app/borrower/apply"
  >
    <>
      <Paragraph>
        We accept applications for loans from $500,000. To avoid unnecessary delays, please ensure you have the
        following documents ready:
        <ul>
          <li>verification of your identity</li>
          <li>proof of income</li>
        </ul>
      </Paragraph>
      <Paragraph>
        All applications for credit products are subject to standard credit approval criteria. Terms,
        conditions, fees and charges apply and may be varied or introduced in the future.
      </Paragraph>
    </>
  </SimpleDashBoardCard>,
        },
        {
          key: 'applications',
          component:
  <ApplicationsDashBoardCard
    title="My application"
    btnText="View my loan application"
    btnLink="/app/borrower/applications"
  >
    <Paragraph>Check on the progress of your loan application:</Paragraph>
  </ApplicationsDashBoardCard>,
        },
        {
          key: 'my-loan',
          component:
  <SimpleDashBoardCard
    title="My loan"
    btnText="View my loan"
    btnLink="/app/borrower/loans"
  >
    <Paragraph>View information on your current loan.</Paragraph>
  </SimpleDashBoardCard>,
        },
      ],
    },
    {
      brand: 'zagga',
      context: 'investor',
      cards: [
        {
          key: 'upcoming-opportunities',
          component:
  <UpcomingOpportunitiesDashBoardCard
    title="Upcoming opportunities"
    btnText="Apply.."
    btnLink="/app/borrower/apply"
  />,
        },
        {
          key: 'available-opportunities',
          component: <SearchLoansBlock title="Available opportunities" />,
        },
        {
          key: 'my-commitments',
          component: <MyCommitmentsBlock />,
        },
      ],
    },
    {
      brand: 'zagga',
      context: 'broker',
      cards: [
        {
          key: 'my-applications',
          component:
  <ApplicationsDashBoardCard
    title="My loan application"
    btnText="View my loan application"
    btnLink="/app/broker/applications"
  />,
        },
        {
          key: 'my-login',
          component: <MyLoginBlock context={context} />,
        },
      ],
    },
    {
      brand: 'znz',
      context: 'borrower',
      cards: [
        {
          key: 'apply-for-a-loan',
          component:
  <SimpleDashBoardCard
    title="Apply for a loan"
    btnText="Apply"
    btnLink="/app/borrower/apply"
  >
    <>
      <Paragraph>
        You can apply to borrow $25,000 to $2million.
      </Paragraph>
      <Paragraph>
        You may need to supply all or some of the following documents.
      </Paragraph>
      <ul>
        <li>A registered valuation of a property or asset</li>
        <li>3 months of bank statements</li>
        <li>A statement of position (form supplied)</li>
        <li>Details of current mortgages (e.g.) name of mortgagee, evidence of amount outstanding.</li>
        <li>A sale and purchase agreement</li>
      </ul>
      <Paragraph>
        All applications for credit products are subject to standard credit approval criteria. Terms,
        conditions, fees and charges apply and may be varied or introduced in the future.
      </Paragraph>
    </>
  </SimpleDashBoardCard>,
        },
        {
          key: 'my-applications',
          component:
  <ApplicationsDashBoardCard
    title="My application"
    btnText="View my loan application"
    btnLink="/app/borrower/applications"
  >
    <Paragraph>
      Manage your loan application, upload supporting documents and track its status after submission.
    </Paragraph>
  </ApplicationsDashBoardCard>,
        },
        {
          key: 'my-loan',
          component:
  <SimpleDashBoardCard
    title="My loan"
    btnText="View my loan"
    btnLink="/app/borrower/loans"
  >
    <Paragraph>
      After your loan(s) has/have been approved and published, the current status, e.g. payments,
      amounts etc. will display here.
    </Paragraph>
    <Paragraph>And you can click below to go to your personal my loans page.</Paragraph>
  </SimpleDashBoardCard>,
        },
      ],
    },
    {
      brand: 'znz',
      context: 'investor',
      cards: [
        {
          key: 'search-loans',
          component: <SearchLoansBlock />,
        },
        {
          key: 'my-investments',
          component: <MyInvestmentsBlock />,
        },
        {
          key: 'upcoming-opportunities',
          component:
  <UpcomingOpportunitiesDashBoardCard
    title="Upcoming opportunities"
    btnText="Apply.."
    btnLink="/app/borrower/apply"
  />,
        },
      ],
    },
    {
      brand: 'znz',
      context: 'broker',
      cards: [
        {
          key: 'my-applications',
          component:
  <ApplicationsDashBoardCard
    title="My loan applications"
    btnText="View my loan applications"
    btnLink="/app/broker/applications"
  >
    <Paragraph>Check on the progress of your loan applications:</Paragraph>
  </ApplicationsDashBoardCard>,
        },
        {
          key: 'my-login',
          component: <MyLoginBlock context={context} />,
        },
      ],
    },
    {
      brand: 'apm',
      context: 'borrower',
      cards: [
        {
          key: 'my-applications',
          component:


  <SimpleDashBoardCard
    title="My application"
    btnText="View my loan application"
    btnLink="/app/borrower/applications"
  >
    <Paragraph>After your loans have been approved and published, the current status, e.g. payments, amounts, etc. will display here.</Paragraph>
    <Paragraph>And you can click below to go to your personal my loans page.</Paragraph>
  </SimpleDashBoardCard>,
        },
        {
          key: 'my-loan',
          component:
  <ApplicationsDashBoardCard
    title="My loan"
    btnText="View my loan"
    btnLink="/app/borrower/loans"
  >
    <Paragraph>View information on your current loan.</Paragraph>
  </ApplicationsDashBoardCard>,
        },
      ],
    },
    {
      brand: 'apm',
      context: 'investor',
      cards: [
        {
          key: 'my-commitments',
          component: <MyCommitmentsBlock />,
        },
      ],
    },
    {
      brand: 'apm',
      context: 'broker',
      cards: [
        {
          key: 'my-applications',
          component:
  <ApplicationsDashBoardCard
    title="My loan application"
    btnText="View my loan application"
    btnLink="/app/broker/applications"
  />,
        },
        {
          key: 'my-login',
          component: <MyLoginBlock context={context} />,
        },
      ],
    },
  ];

  const filterByBrandRole = (brandName, filterRole) => config
    .filter(item => item.brand === brandName)
    .filter(item => item.context === filterRole);

  const filteredConfig = filterByBrandRole(brand, context).at(0);
  const filteredCards = filteredConfig?.cards;

  return (
    <Row gutter={[16, 16]} className="theme-dashboard-cards">
      {filteredCards.map(card => (
        <Col key={card.key} xs={12} sm={6} md={4}>
          { card.component }
        </Col>
      ))}
    </Row>
  );
};

DashBoardCards.propTypes = {
  context: string.isRequired,
};

export default DashBoardCards;
