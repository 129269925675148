import React, { useState } from 'react';
import { Form, InputNumber } from 'antd';
import moment from 'moment';
import { func, shape } from 'prop-types';
import { useMutation } from '@apollo/client';
import { haveEntityPermission } from '../../../../Helpers/Helpers';
import { UPDATE_LOAN } from '../../../../GraphQL/Mutations/Account';
import { getGraphQlErrorMessage } from '../../../../Helpers/Error';
import Alert from '../../../../Atoms/Feedback/Alert/Alert';
import Title from '../../../../Atoms/Typography/Title/Title';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Checkbox from '../../../../Atoms/Forms/Checkbox/Checkbox';
import Input from '../../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import Button from '../../../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import DatePicker from '../../../../Atoms/Forms/DatePicker/DatePicker';
import './LoanEditForm.less';
import { dealer } from '../../../../Hooks/useLocalStorage';

const LoanEditForm = ({ onSuccess, entityData }) => {
  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD';
  const [message, setMessage] = useState('');
  const [UpdateLoan, { loading }] = useMutation(UPDATE_LOAN);

  const { uuid, fieldFundsRequiredDate: fieldFundsRequiredDateRaw, ...otherValues } = entityData;

  let fieldFundsRequiredDate = null;
  if (fieldFundsRequiredDateRaw != null) {
    fieldFundsRequiredDate = moment(fieldFundsRequiredDateRaw, dateFormat);
  }

  const submitForm = (values) => {
    UpdateLoan({
      variables: {
        uuid,
        input: values,
      },
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setMessage(getGraphQlErrorMessage(error));
      });
  };

  return (
    <Form
      form={form}
      name="account"
      onFinish={submitForm}
      initialValues={{ fieldFundsRequiredDate, ...otherValues }}
      className="theme-loan-edit-form"
    >
      { message && <Alert message={message} type="error" /> }
      <div>
        <Title level={3}>Platform settings</Title>
        <FormItem
          name="fieldTitleOverride"
          valuePropName="value"
          label="Override loan title"
        >
          <Input />
        </FormItem>
        { haveEntityPermission(entityData, 'Can edit LVR') && (
          <FormItem
            name="fieldLvr"
            valuePropName="value"
            label="LVR"
          >
            <InputNumber />
          </FormItem>
        )}
        { haveEntityPermission(entityData, 'Can edit borrower rate') && (
          <FormItem
            name="fieldRate"
            valuePropName="value"
            label="Borrower rate"
          >
            <InputNumber />
          </FormItem>
        )}
        { haveEntityPermission(entityData, 'Can edit investment rate') && (
          <FormItem
            name={entityData.type === 'loan' ? 'fieldInvestmentRate' : 'fieldRate'}
            valuePropName="value"
            label="Investment rate"
          >
            <InputNumber />
          </FormItem>
        )}
      </div>
      <div>
        <Title level={4}>Display settings</Title>
        <InputGroup>
          <FormItem
            name="fieldHideForClient"
            valuePropName="checked"
          >
            <Checkbox>Hide from investors</Checkbox>
          </FormItem>

          <FormItem
            name="fieldHideForLoanBook"
            valuePropName="checked"
          >
            <Checkbox>Hide from loan book</Checkbox>
          </FormItem>
          { dealer() === 'znz' && (
            <FormItem
              name="fieldNoSecondaryMarket"
              valuePropName="checked"
            >
              <Checkbox>Hide from secondary market</Checkbox>
            </FormItem>
          )}

        </InputGroup>
      </div>
      <div>
        <Title level={3}>Communication settings</Title>
        <FormItem
          name="fieldFundsRequiredDate"
          valuePropName="value"
          label="Funds due date"
        >
          <DatePicker
            format={dateFormat}
          />
        </FormItem>
      </div>
      {
        haveEntityPermission(entityData, 'View tranche fields') && (
          <div>
            <Title level={3}>Tranche settings</Title>
            <FormItem
              name="fieldMinimumTerm"
              valuePropName="value"
              label="Minimum term"
            >
              <Input />
            </FormItem>
            <FormItem
              name="fieldMaximumTerm"
              valuePropName="value"
              label="Maximum term"
            >
              <Input />
            </FormItem>
            <FormItem
              name="fieldLineFee"
              valuePropName="value"
              label="Line fee"
            >
              <Input />
            </FormItem>
            <FormItem
              name="fieldInvestorReturn"
              valuePropName="value"
              label="Investment return"
            >
              <Input />
            </FormItem>
          </div>
        )
      }

      <ButtonGroup direction="bottom-right">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
      </ButtonGroup>
    </Form>
  );
};

LoanEditForm.propTypes = {
  entityData: shape({}).isRequired,
  onSuccess: func,
};

LoanEditForm.defaultProps = {
  onSuccess: () => {},
};

export default LoanEditForm;
