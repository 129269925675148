import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_DRAFT } from '../GraphQL/Queries/Draft';
import Skeleton from '../Atoms/Feedback/Skeleton/Skeleton';
import { convertDraftDatesToMoment } from '../Hooks/useCommonConvertedEntityData';

// eslint-disable-next-line react/display-name
const WithDraft = Component => (args) => {
  const { match: { params: { draftUuid: queryDraftUuid } } } = args;

  let draftUuid = null;
  if (typeof queryDraftUuid !== 'undefined') {
    draftUuid = queryDraftUuid;
  }
  const { data, loading } = useQuery(
    GET_DRAFT,
    { variables: { uuid: draftUuid } },
  );

  let draftData = [];
  if (data?.draft?.data !== undefined) {
    draftData = JSON.parse(data?.draft?.data);
    draftData = convertDraftDatesToMoment(draftData);
    if (typeof draftData === 'object') {
      draftData = Object.values(draftData);
    }
    const clientStructureStepData = draftData.find(step => step.stepKey === 'set-account-structure-form');
    if (clientStructureStepData) {
      draftData.role = clientStructureStepData.role;
      draftData.type = clientStructureStepData.type;
    }
  }
  return (
    <Skeleton loading={loading}>
      <Component {...args} InitialFormState={draftData} queryDraftUuid={queryDraftUuid} />
    </Skeleton>
  );
};

export default WithDraft;
