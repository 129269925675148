/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getUnixTimeStamp = () => Math.floor(Date.now() / 1000);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    auth: null,
  },
  reducers: {
    setAuth: (state, action) => {
      const { user, auth } = action.payload;
      state.user = user;
      state.auth = auth;
      state.auth.expires = getUnixTimeStamp() + auth?.expires_in;
    },
    addUserRole: (state, action) => {
      if (!state.user?.roles?.includes(action.payload)) {
        state.user.roles = [action.payload, ...(state.user.roles || [])];
      }
    },
    logout: (state) => {
      localStorage.removeItem('persist:user');
      state.user = null;
      state.auth = null;
    },
  },
});

export const { setAuth, addUserRole, logout } = userSlice.actions;

export const userId = state => state.user.user?.id;
export const userUuid = state => state.user.user?.uuid;
export const userRoles = state => state?.user?.user?.roles;
export const getUser = state => state?.user?.user;
export const getPreferredName = state => getUser(state)?.preferredName || '';
export const accessToken = state => state?.user?.auth?.access_token;
export const hasRole = role => state => state?.user?.user?.roles?.includes(role);
export const isSuperAdmin = hasRole('super_administrator');
export const isAdmin = hasRole('super_administrator') || hasRole('administrator');
export const isDealer = hasRole('dealer');
export const isBroker = hasRole('broker');
export const isIntroducer = hasRole('introducer');
export const isBorrower = hasRole('borrower');
export const isInvestor = hasRole('investor');
export const hasRegistration = role => state => state?.user?.user?.registrations?.includes(role);
export const isRegisteredInvestor = hasRegistration('investor');
export const isRegisteredBorrower = hasRegistration('borrower');
export const isRegisteredBroker = hasRegistration('broker');

export const isLoggedIn = (state) => {
  const expires = parseInt(state?.user?.auth?.expires, 10);
  return !!(getUnixTimeStamp() < expires && accessToken);
};

export default userSlice.reducer;
