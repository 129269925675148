import { titleCase } from './useStringFormatter';
import { getBrand } from './useLocalStorage';

export const isBrokerRole = role => role === 'broker' || role === 'introducer';

export const brokerLabel = (plural = false) => {
  if (getBrand() === 'znz') {
    return plural ? 'Brokers' : 'Broker';
  }
  return plural ? 'Introducers' : 'Introducer';
};

export const roleLabel = role => (
  isBrokerRole(role) ? brokerLabel() : titleCase(role)
);
