
export const findChildAttribute = (recursedData, recursedParents) => {
  if (recursedParents.length === 0) {
    return recursedData;
  }
  const [firstIndex, ...restIndex] = recursedParents;
  if (!recursedData[firstIndex]) {
    return null;
  }
  return findChildAttribute(recursedData[firstIndex] ?? [], restIndex);
};

export const filterNulls = (node) => {
  if (Array.isArray(node)) {
    const filteredNodes = node.filter(item => (item !== null));
    return filteredNodes.map(item => filterNulls(item));
  }
  if (node instanceof Object) {
    const newNode = node;
    const keys = Object.keys(node);
    let ind = null;
    for (ind = 0; ind < keys.length; ind += 1) {
      newNode[keys[ind]] = filterNulls(newNode[keys[ind]], keys[ind]);
    }
    return newNode;
  }
  return node;
};

const useDataParsing = {
  findChildAttribute,
  filterNulls,
};

export default useDataParsing;
