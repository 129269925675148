import React from 'react';
import {
  string, node,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import Card from '../Card';
import { INVESTMENTS } from '../../../GraphQL/Queries/Account';
import Title from '../../../Atoms/Typography/Title/Title';
import Button from '../../../Atoms/Forms/Button/Button';
import InvestmentListItem from '../../ViewModes/Accounts/InvestmentListItem';
import Result from '../../../Atoms/Feedback/Result/Result';
import Text from '../../../Atoms/Typography/Text/Text';
import { userUuid as _userUuid } from '../../../Store/userSlice';

const MyInvestmentsDashBoardCardAU = ({
  title, btnText, btnLink,
}) => {
  const userUuid = useSelector(_userUuid);
  const { loading, error, data } = useQuery(INVESTMENTS, {
    variables: {
      user: userUuid, states: ['funding', 'backfill', 'live-subscribed'], offset: 0, limit: 10,
    },
  });

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }
  const fundingInvestments = data?.investments?.results || [];

  return (
    <Card
      title={title}
      loading={loading}
      type="dashboard"
      actions={[
        <Button key={btnText} block href={btnLink} type="primary">
          {btnText}
        </Button>,
      ]}
    >
      <Title level={5}>Pending commencement</Title>
      { !fundingInvestments?.length && <Text>You do not currently have any funding commitments.</Text> }
      { fundingInvestments?.map(investment => (
        <InvestmentListItem
          key={investment?.uuid}
          investment={investment}
        />
      ))
      }
    </Card>
  );
};

export default MyInvestmentsDashBoardCardAU;

MyInvestmentsDashBoardCardAU.propTypes = {
  title: node,
  btnLink: string,
  btnText: string,
};

MyInvestmentsDashBoardCardAU.defaultProps = {
  title: 'My commitments',
  btnLink: '/app/investor/investments',
  btnText: 'View all investments',
};
