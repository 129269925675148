import { gql } from '@apollo/client';
import {
  ADDRESS_FRAGMENT,
  USER_FRAGMENT,
  PERMISSIONS_FRAGMENT,
  IDENTIFICATION_FRAGMENT,
  IDENTIFICATION_FULL_FRAGMENT,
} from '../Fragments/Fragments';
import {
  CLIENT_COMPANY, CLIENT_INDIVIDUAL, BROKER_COMPANY, BROKER_INDIVIDUAL,
} from '../Fragments/ClientFragments';

export const CLIENT_BY_UUID = gql`
  ${CLIENT_INDIVIDUAL}
  ${CLIENT_COMPANY}
  ${ADDRESS_FRAGMENT}
  ${USER_FRAGMENT}
  ${PERMISSIONS_FRAGMENT}
  ${IDENTIFICATION_FRAGMENT}
  ${BROKER_COMPANY}
  ${BROKER_INDIVIDUAL}
  query client($uuid: String!) {
    client(uuid: $uuid) {
      fieldLmsClientId
      name
      type
      uuid
      owner {
        ...userFragment
      }
      entityPermissions {
        ...permissionsFragment
      }
      ...clientCompanyFragment
      ...clientIndividualFragment  
      ... on ClientIndividual {
        organisations {
          fieldLmsClientId
          name
          type
          uuid
          acting {
            role
          }
        }
      }
      ... on ClientIndividuals {
        uuid
        members {
          uuid
          name
          type
          owner {
            ...userFragment
          }
          ...clientIndividualFragment
        }
      }
      ... on ClientPartnership {
        uuid
        members {
          uuid
          name
          type
          owner {
            ...userFragment
          }
          ...clientIndividualFragment
        }
      }
      ... on ClientTrust {
        uuid
        fieldTrustType
        fieldTrusteeType
        members {
          uuid
          name
          type
          owner {
            ...userFragment
          }
          ...clientIndividualFragment
        }
      }
      acting {
        uuid
        role
        fieldVerified
        ... on ActingIndividualBorrower {
          fieldBroker {
            ... on ActingCompanyBroker {
              ...brokerCompanyFragment
            }
            ... on ActingIndividualBroker {
              ...brokerIndividualFragment
            }
          }
        }
        ... on ActingCompanyBorrower {
          fieldAddress {
            ...addressFragment
          }
          fieldBroker {
            ... on ActingCompanyBroker {
              ...brokerCompanyFragment
            }
            ... on ActingIndividualBroker {
              ...brokerIndividualFragment
            }
          }
        }
        ... on ActingCompanyBroker {
          fieldAddress {
            ...addressFragment
          }
          fieldWebsite
        }
        ... on ActingCompanyInvestor {
          fieldFatca
          fieldInvestorClassVerified
          fieldInvestorClassification
          fieldIsAuTaxRegistered
          fieldRegistrationCountry
        }
        ... on ActingIndividualInvestor {
          fieldFatca
          fieldInvestorClassVerified
          fieldInvestorClassification
          fieldIsAuTaxRegistered
          fieldRegistrationCountry
        }
        ... on ActingIndividualsBorrower {
          fieldBroker {
            ... on ActingCompanyBroker {
              ...brokerCompanyFragment
            }
            ... on ActingIndividualBroker {
              ...brokerIndividualFragment
            }
          }
        }
        ... on ActingIndividualsInvestor {
          fieldFatca
          fieldInvestorClassVerified
          fieldInvestorClassification
          fieldIsAuTaxRegistered
          fieldRegistrationCountry
        }
        ... on ActingPartnershipBorrower {
          fieldAddress {
            ...addressFragment
          }
          fieldBroker {
            ... on ActingCompanyBroker {
              ...brokerCompanyFragment
            }
            ... on ActingIndividualBroker {
              ...brokerIndividualFragment
            }
          }
          fieldWebsite
        }
        ... on ActingPartnershipInvestor {
          fieldBusinessNumber
          fieldFatca
          fieldInvestorClassVerified
          fieldInvestorClassification
          fieldIsAuTaxRegistered
          fieldPartnershipType
          fieldRegistrationCountry
        }
        ... on ActingTrustBorrower {
          fieldAddress {
            ...addressFragment
          }
          fieldBroker {
            ... on ActingCompanyBroker {
              ...brokerCompanyFragment
            }
            ... on ActingIndividualBroker {
              ...brokerIndividualFragment
            }
          }
          fieldCorporateAbn
          fieldCorporateAcn
          fieldCorporateEntityName
        }
        ... on ActingTrustInvestor {
          fieldCorporateAbn
          fieldCorporateAcn
          fieldCorporateEntityName
          fieldFatca
          fieldInvestorClassVerified
          fieldInvestorClassification
          fieldIsAuTaxRegistered
          fieldRegistrationCountry
        }
      }
      owner {
        ...userFragment
      }
    }
  }
`;

export const CLIENTS = gql`
    query clients(
        $roles: [ClientRole],
        $search: String,
        $types: [ClientType],
        $user: String,
        $offset: Int = 0,
        $verified: Boolean,
        $limit: Int = 20,
        $sort: ClientsSortOrder,
        $sortDirection: SortDirection
    ) {
        clients(roles: $roles, types: $types, search: $search, user: $user, offset: $offset, verified: $verified, limit: $limit, sort: $sort, sortDirection: $sortDirection) {
            total
            results {
                uuid
                name
                clientType: type
                fieldLmsClientId
                acting {
                    uuid
                    fieldVerified
                    ... on ActingCompanyInvestor {
                        fieldInvestorClassVerified
                    }
                    ... on ActingIndividualInvestor {
                        fieldInvestorClassVerified
                    }
                    ... on ActingIndividualsInvestor {
                        fieldInvestorClassVerified
                    }
                    ... on ActingPartnershipInvestor {
                        fieldInvestorClassVerified
                    }
                    ... on ActingTrustInvestor {
                        fieldInvestorClassVerified
                    }
                    clientRole: role
                }
                owner {
                    email
                }
            }
        }
    }
`;

export const CLIENTS_OPTIONS = gql`
    query clients($roles: [ClientRole], $search: String, $limit: Int = 20, $uuids: [String]) {
        clients(roles: $roles, search: $search, limit: $limit, uuids: $uuids) {
            results {
                value: uuid
                label: name
                clientId: fieldLmsClientId
            }
        }
    }
`;

export const USER_BROKER_OPTIONS = gql`
    query clients($user: String) {
        clients(user: $user, roles: [broker]) {
            results {
                value: uuid
                label: name
            }
        }
    }
`;

export const ACTING_CLIENT_BANK_ACCOUNT = gql`
  query actingClientBankAccount($actingClientUuid: String!) {
    actingClientBankAccount(actingClientUuid: $actingClientUuid) {
      uuid
      bankAccountBsb
      bankAccountNumber
      bankAccountName
    }
  }
`;

export const CLIENT_IDENTIFICATION = gql`
  ${IDENTIFICATION_FULL_FRAGMENT}
  query clientIdentification($clientUuid: String!) {
    clientIdentification(clientUuid: $clientUuid) {
      identification {
        ... identificationFullFragment
      },
      files {
        fid
        filename
      }
    }
  }
`;

export const INVESTOR_CLIENTS = gql`
  query investorClientsQuery(
    $search: String
    $user: String
    $verified: Boolean = true
    $offset: Int = 0
    $limit: Int = 999
    $sort: ClientsSortOrder
    $sortDirection: SortDirection
  ) {
    investorClients(
      search: $search
      user: $user
      verified: $verified
      offset: $offset
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
    ) {
      total,
      results {
        uuid
        entityPermissions {
          label
          permitted
        }
        fieldLmsClientId
        name
        owner {
          email
        }
      }
    }
  }
`;

const Client = {
  ACTING_CLIENT_BANK_ACCOUNT,
  CLIENT_BY_UUID,
  CLIENTS,
  CLIENTS_OPTIONS,
  USER_BROKER_OPTIONS,
  INVESTOR_CLIENTS,
};

export default Client;
