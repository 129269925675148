import React from 'react';
import { Button as AntdButton } from 'antd';
import './Button.less';
import PropTypes from 'prop-types';

const Button = (args) => {
  const { className, shape } = args;
  const theme = (shape && `theme-button theme-${shape}`) || 'theme-button';
  return <AntdButton {...args} className={`${theme} ${className}`} />;
};

Button.propTypes = {
  block: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  href: PropTypes.string,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  loading: PropTypes.bool,
  shape: PropTypes.oneOf(['circle', 'round', 'square', null]),
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  target: PropTypes.string,
  type: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary', 'cancel', 'dashed', 'link', 'text', 'badge', 'danger']),
  icon: PropTypes.node,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  block: false,
  danger: false,
  disabled: false,
  ghost: undefined,
  href: undefined,
  htmlType: 'button',
  loading: undefined,
  shape: null,
  size: 'middle',
  target: undefined,
  type: 'default',
  icon: null,
  onClick: undefined,
};

export default Button;
