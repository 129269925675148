import { gql } from '@apollo/client';

export const INVESTMENT_STATEMENT_BY_UUID_AND_DATE = gql`
    query Investment($uuid: String, $startDate: String, $endDate: String) {
        investment(uuid: $uuid) {
            commencementDate
            fieldLmsAccountId
            fieldAmount
            fieldLoan {
                fieldLmsAccountId
                fieldPrimarySecurity {
                    fieldAddress {
                        locality
                    }
                }
            }
            fieldClient {
                fieldLmsClientId
                name
                owner {
                    primary {
                        name
                        ... on ClientIndividual {
                            fieldAddress {
                                value
                            }
                        }
                    }
                }
            }
            investmentRate
            transactions(startDate: $startDate, endDate: $endDate) {
                table {
                    balance
                    credit
                    debit
                    description
                    transactionDate
                },
                startDate
                endDate
                closingBalance
                principal
                remainingPrincipal
                totalInterest
            }
        }
    }
`;

export const INVESTMENTS_SUMMARY_BY_LOAN_UUID = gql`
query  InvestmentsSummary($loanUuid: String!) {
  investmentsSummary(loanUuid: $loanUuid) {
    loan {
      uuid
      fieldLmsAccountId
      fieldAmount
      fieldOpenDate
    }
    reportRows {
      investment {
        uuid
        commencementDate
        investmentRate
        fieldAmount
        fieldCommencementDate
        fieldLmsAccountId
        fieldClient {
          name
        }
        fieldPool {
          fieldOpenDate
        }
      }
      currentBalance
      currentInterestPaid
    }
  }
}
`;

const Investment = {
  INVESTMENT_STATEMENT_BY_UUID_AND_DATE,
  INVESTMENTS_SUMMARY_BY_LOAN_UUID,
};

export default Investment;
