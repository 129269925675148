import React from 'react';
import {
  string, number, shape,
} from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Table from '../../../Atoms/DataDisplay/Table/Table';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import { formatUnixDate } from '../../../Helpers/FormatDate';

const PastReductions = ({ reductions, title }) => {
  const isSmScreen = useMediaQuery({ query: '(max-width: 480px)' });

  const columns = [
    {
      title: 'Status',
      dataIndex: 'fieldStatus',
      render: value => value.toUpperCase(),
      align: 'center',
      width: isSmScreen ? '5.5rem' : '15%',
    },
    {
      title: 'Created \n /Expires',
      align: 'right',
      render: record => (
        <>
          {formatUnixDate(record.created)}
          <br />
          {formatUnixDate(record.fieldExpiry)}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: value => formatUnixDate(value),
      responsive: ['sm'],
      align: 'center',
    },
    {
      title: 'Expiry',
      dataIndex: 'fieldExpiry',
      align: 'center',
      responsive: ['sm'],
      render: value => formatUnixDate(value),
    },
    {
      title: 'Offer amount',
      dataIndex: 'fieldAmount',
      align: 'center',
      render: value => <Currency value={value} />,
    },
    {
      title: 'Break fee',
      dataIndex: 'fieldBreakFee',
      align: 'center',
      render: value => <Currency value={value} />,
    },
    {
      title: 'Funded amount',
      dataIndex: 'amountFunded',
      align: 'center',
      render: value => <Currency value={value || 0} />,
    },
  ];

  return (
    <Card
      title={(<Title level={4}>{title}</Title>)}
      className="theme-past-reductions"
    >
      <Table type="inner" columns={columns} dataSource={reductions} />
    </Card>
  );
};

PastReductions.propTypes = {
  reductions: shape({
    uuid: string,
    fieldStatus: 'pending',
    created: number,
    changed: number,
    fieldExpiry: number,
    fieldAmount: number,
    amountFunded: number,
  }).isRequired,
  title: string.isRequired,
};

export default PastReductions;
