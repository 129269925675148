import React, { useContext } from 'react';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import LangContext from '../../../Context/Lang/LangContext';
import EntityType from '../EntityType/EntityType';

const Group = () => {
  const { t } = useContext(LangContext);

  return (
    <>
      <FormItem
        label="Name"
        name="name"
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <Input />
      </FormItem>
      <EntityType type="client" bundle="individuals" />
      <FormItem
        name="entityType"
        hidden
        initialValue="client"
      >
        <Input />
      </FormItem>
    </>
  );
};

export default Group;
