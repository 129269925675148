import React, { useState } from 'react';
import NoResult from '../../../Molecules/NoResult/NoResult';
import loadMoreItems from '../../../Helpers/LoadMoreList';
import DocumentItem from './DocumentItem';

const DocumentList = (props) => {
  const [itemList, setItemList] = useState([]);
  const [itemUuid, setItemUuid] = useState('');

  const {
    context,
    documentDelete,
    editable,
    items,
    page,
    status,
  } = props;
  const DocumentSkeleton = () => (
    <DocumentItem
      context={context}
      skeleton
      row={{ uuid: null, name: null, fieldMediaFiles: null }}
    />
  );

  const loadMore = loadMoreItems(itemList, itemUuid, items, page);
  if (loadMore !== null) {
    setItemUuid(loadMore.uuid);
    setItemList(loadMore.list);
  }

  if (itemList.length === 0 && status === 'loading') {
    return (
      <>
        <DocumentSkeleton />
        <DocumentSkeleton />
        <DocumentSkeleton />
      </>
    );
  }

  if (itemList.length === 0) {
    return <NoResult title="No documents found." body="" />;
  }

  const displayList = itemList.map(document => (
    <DocumentItem
      context={context}
      key={`doc-item-${document.uuid}`}
      editable={editable}
      onDelete={documentDelete}
      row={document}
    />
  ));
  if (status === 'loading') {
    displayList.push(
      (
        <DocumentSkeleton />
      ),
    );
  }

  return displayList;
};

export default DocumentList;
