import React, { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col, Row, Form,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../Atoms/Forms/Button/Button';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import ClientSelect from '../Fields/ClientSelect/ClientSelect';
import { getAppDealerConfiguration } from '../../../Store/appConfigurationSlice';

export const getDefaultPeriod = () => {
  // Get Tax Fiscal Start and End Months
  const appConfig = useSelector(getAppDealerConfiguration);
  const taxStartMonth = appConfig?.dealerFlags?.taxStatementStartMonth;
  const taxEndMonth = appConfig?.dealerFlags?.taxStatementEndMonth;

  const currentYear = moment().year();
  const isBeginningOfTheYear = moment().isBefore(`${currentYear}-07-01T00:00:00Z`);
  let currentFinancialYear = currentYear;
  if (isBeginningOfTheYear) {
    currentFinancialYear -= 1;
  }

  let beginningOfYear = `${currentFinancialYear - 1}-07-01T00:00:00Z`;
  let endOfYear = `${currentFinancialYear}-06-30T00:00:00Z`;

  // Override if taxStartMonth and taxEndMonth are set.
  if (taxStartMonth) {
    beginningOfYear = `${currentFinancialYear - 1}-${String(taxStartMonth).padStart(2, '0')}-01T00:00:00Z`;
  }
  if (taxEndMonth) {
    endOfYear = `${currentFinancialYear}-${String(taxEndMonth).padStart(2, '0')}-30T00:00:00Z`;
  }

  return {
    from: moment(beginningOfYear),
    to: moment(endOfYear),
  };
};

const TaxStatementForm = (props) => {
  const { uuid } = useParams();

  const { loading, onFinish } = props;
  const { from, to } = getDefaultPeriod();

  useEffect(() => {
    if (uuid) {
      onFinish({ entity: uuid, from, to });
    }
  }, [uuid]);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Form
      onFinish={onFinish}
      initialValues={{ from, to }}
      className="theme-filter"
    >
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={6}>
          <ClientSelect placeHolder="Entity" />
        </Col>
        <Col xs={12} md={2}>
          <Form.Item
            name="from"
            rules={[{ required: true, message: 'This field is required' }]}
          >
            <DatePicker
              defaultValue={moment(from)}
              format="DD-MM-YYYY"
              bordered
              placeholder="From"
              onOpenChange={() => {}}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={2}>
          <Form.Item
            name="to"
            rules={[
              { required: true, message: 'This field is required' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const fromDate = getFieldValue('from');
                  if (!value || fromDate <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`To date must be greater than ${fromDate?.format('DD-MM-YYYY')} date`));
                },
              }),
            ]}
          >
            <DatePicker
              defaultValue={moment(to)}
              format="DD-MM-YYYY"
              bordered
              placeholder="To"
              onOpenChange={() => {}}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={2}>
          <Button loading={loading} type="secondary" htmlType="submit">
            Preview
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

TaxStatementForm.propTypes = {
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};

TaxStatementForm.defaultProps = {
  loading: false,
  onFinish: () => null,
};

export default TaxStatementForm;
