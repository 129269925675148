import React from 'react';
import {
  Form, Col, Row,
} from 'antd';
import {
  bool, func, string, oneOfType, shape,
} from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import FormItem from 'antd/es/form/FormItem';
import Input from '../../../Atoms/Forms/Input/Input';
import Select from '../../../Atoms/Forms/Select/Select';
import Button from '../../../Atoms/Forms/Button/Button';

const InvestmentSearchForm = (props) => {
  const {
    searchText,
    searchAmount,
    searchState,
    setSearchText,
    setSearchAmount,
    setSearchState,
    setPageNumber,
    stateOptions,
    clearFilter,
  } = props;

  const defaultStateOptions = [
    { value: ':live', label: 'Live' },
    { value: 'closed_pending', label: 'Closing' },
  ];

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Form
      className="theme-filter"
      onSubmit={e => e.preventDefault()}
      onValuesChange={(values) => {
        setPageNumber(0);
        if (values?.search) {
          setSearchText(values.search);
        }
        if (values?.amount) {
          setSearchAmount(values.amount);
        }
        if (values?.state) {
          setSearchState(values.state);
        }
      }}
    >
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={setSearchState ? 4 : 7}>
          <FormItem
            label=""
            name="search"
          >
            <Input
              placeholder="Search"
              value={searchText}
            />
          </FormItem>
        </Col>
        <Col xs={12} md={3}>
          <FormItem
            label=""
            name="amount"
          >
            <Select
              placeholder="Select investment amount"
              value={searchAmount}
              allowClear
              options={[
                { value: 'amt0to200K', label: 'Less than $200,000' },
                { value: 'amt200to500K', label: '$200,000 - $500,000' },
                { value: 'amt500Kto1M', label: '$500,000 - $1,000,000' },
                { value: 'amt1Mplus', label: '$1,000,000 +' },
              ]}
            />
          </FormItem>
        </Col>
        { setSearchState && (
        <Col xs={12} md={3}>
          <FormItem
            label=""
            name="state"
          >
            <Select
              placeholder="Select state"
              value={searchState}
              allowClear
              options={stateOptions || defaultStateOptions}
            />
          </FormItem>
        </Col>
        )}
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={clearFilter}>Clear</Button>
        </Col>
      </Row>
    </Form>
  );
};

InvestmentSearchForm.propTypes = {
  searchText: string,
  searchAmount: string,
  searchState: string,
  setSearchText: func.isRequired,
  setSearchAmount: func.isRequired,
  setSearchState: oneOfType([func, bool]),
  setPageNumber: func.isRequired,
  stateOptions: shape({
    value: string,
    label: string,
  }),
  clearFilter: func.isRequired,
};

InvestmentSearchForm.defaultProps = {
  searchText: '',
  searchAmount: '',
  searchState: '',
  setSearchState: null,
  stateOptions: shape({
    value: '',
    label: '',
  }),
};

export default InvestmentSearchForm;
