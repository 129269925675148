import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import Title from '../../Atoms/Typography/Title/Title';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import Drawer from '../../Atoms/Layout/Drawer/Drawer';
import Button from '../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import UpdateProfile from '../../Organisms/Forms/UpdateProfile/UpdateProfile';
import ChangePassword from '../../Molecules/Forms/ChangePassword/ChangePassword';
import Field from '../../Atoms/DataDisplay/Field/Field';
import { USER_BY_UUID } from '../../GraphQL/Queries/User';
import Alert from '../../Atoms/Feedback/Alert/Alert';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import Result from '../../Atoms/Feedback/Result/Result';
import { userUuid } from '../../Store/userSlice';

const MyProfile = () => {
  const uuid = useSelector(userUuid);
  const [profileFormVisible, setProfileFormVisible] = useState(false);
  const [passwordFormVisible, setPasswordFormVisible] = useState(false);
  const [message, setMessage] = useState(false);

  const { loading, error, data } = useQuery(USER_BY_UUID, { variables: { uuid } });

  if (loading) {
    return (<Skeleton loading={loading} />);
  }

  const { user } = data;

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const onFinish = (result) => {
    const { data: resultData } = result;
    const { userUpdate, userUpdatePassword } = resultData;

    if (userUpdate) {
      setProfileFormVisible(userUpdate.status);
      setMessage(<Alert message={userUpdate.message} type="success" />);
    }

    if (userUpdatePassword) {
      setPasswordFormVisible(userUpdatePassword.status);
      setMessage(<Alert message={userUpdatePassword.message} type="success" />);
    }
  };

  return (
    <div className="theme-my-profile">
      <Title type="pageTop" level={1}>my Profile</Title>
      <Paragraph>
        { message }
      </Paragraph>

      <Field label="Email" value={user.email} />
      <Field label="First name" value={user.preferredName} />
      <Field label="Contact number" value={user.contactNumber} />
      <ButtonGroup direction="bottom-left" className="set-3-style">
        <Button ghost type="secondary" htmlType="submit" onClick={() => setProfileFormVisible(true)}>
          Update profile
        </Button>
        <Button type="secondary" htmlType="submit" onClick={() => setPasswordFormVisible(true)}>
          Change password
        </Button>
      </ButtonGroup>
      <Drawer
        title={<Title level={2}>Update your profile</Title>}
        open={profileFormVisible}
        onClose={() => setProfileFormVisible(false)}
        destroyOnClose
      >
        <UpdateProfile user={user} onFinish={onFinish} />
      </Drawer>
      <Drawer
        title={<Title level={2}>Change your password</Title>}
        open={passwordFormVisible}
        onClose={() => setPasswordFormVisible(false)}
        destroyOnClose
      >
        <ChangePassword user={user} onFinish={onFinish} />
      </Drawer>
    </div>
  );
};

export default MyProfile;
