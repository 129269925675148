import { gql } from '@apollo/client';

export const CREATE_CLIENT = gql`
    mutation CreateClient(
      $actingClient: ActingClientInput
      $client: ClientInput
      $policies: [PolicyInput]
      $user: ClientUserInput
    ) {
      clientCreate(
        actingClient: $actingClient
        client: $client
        policies: $policies
        user: $user
      ) {
        client {
          uuid
          owner {
            uuid
          }
        }
        newRole
        success
        message
      }
    }
`;

export const UPDATE_CLIENT = gql`
    mutation ClientUpdate(
      $actingClient: ActingClientInput
      $client: ClientInput
    ) {
      clientUpdate(
        actingClient: $actingClient
        client: $client
      ) {
        client {
          uuid
        }
        success
        message
      }
    }
`;

export const CLIENT_SET_VERIFIED = gql`
  mutation ClientUpdateFlags(
    $uuid: String!
  ) {
    clientUpdateFlags(
      uuid: $uuid
      flags: { isVerified: true }
    ) {
      client {
        uuid
      }
      success
      message
    }
  }
`;


export const CLIENT_SET_SOPHISTICATED = gql`
  mutation ClientUpdateFlags(
    $uuid: String!
  ) {
    clientUpdateFlags(
      uuid: $uuid
      flags: { isSophisticated: true }
    ) {
      client {
        uuid
      }
      success
      message
    }
  }
`;

export const CLIENT_SET_ACCREDITED = gql`
  mutation ClientUpdateFlags(
    $uuid: String!
  ) {
    clientUpdateFlags(
      uuid: $uuid
      flags: { isAccredited: true }
    ) {
      client {
        uuid
      }
      success
      message
    }
  }
`;

export const CLIENT_SEND_WELCOME_EMAIL = gql`
  mutation ClientSendWelcomeEmail(
    $uuid: String!
  ) {
    clientSendWelcomeEmail(
      uuid: $uuid
    ) {
      client {
        uuid
      }
      success
      message
    }
  }
`;

export const BANK_ACCOUNT_UPDATE = gql`
  mutation BankAccountUpdate(
    $actingClientUuid: String!
    $input: BankAccountInput!
  ) {
    bankAccountUpdate(
      actingClientUuid: $actingClientUuid
      input: $input
    ) {
      success
      message
    }
  }
`;

export const IDENTIFICATION_UPDATE = gql`
  mutation IdentificationUpdate(
    $clientUuid: String!
    $input: IdentificationInput!
    $documents: [DocumentInput]!
  ) {
    identificationUpdate(
      clientUuid: $clientUuid
      input: $input
      documents: $documents
    ) {
      success
      message
    }
  }
`;

export const Client = {
  CREATE_CLIENT,
  UPDATE_CLIENT,
  CLIENT_SET_VERIFIED,
  CLIENT_SET_SOPHISTICATED,
  CLIENT_SET_ACCREDITED,
  CLIENT_SEND_WELCOME_EMAIL,
  BANK_ACCOUNT_UPDATE,
  IDENTIFICATION_UPDATE,
};

export default Client;
