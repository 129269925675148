import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT, IDENTIFICATION_FRAGMENT, USER_FRAGMENT } from './Fragments';

export const CLIENT_INDIVIDUAL = gql`
    ${ADDRESS_FRAGMENT}
    ${IDENTIFICATION_FRAGMENT}
    fragment clientIndividualFragment on ClientIndividual {
      uuid  
      fieldAddress {
        ...addressFragment
      }
      fieldFirstName
      fieldLastName
      fieldMiddleName
      fieldDateOfBirth
      fieldPersonFatca
      fieldPhone
      fieldIdentification {
        ...identificationFragment
      }
}
`;

export const CLIENT_COMPANY = gql`
    ${ADDRESS_FRAGMENT}
    ${IDENTIFICATION_FRAGMENT}
    ${USER_FRAGMENT}
    ${CLIENT_INDIVIDUAL}
    fragment clientCompanyFragment on ClientCompany {
        uuid  
        fieldBusinessNumber
        fieldCompanyNumber
        members {
          uuid
          name
          type
          owner {
            ...userFragment
          }
          ...clientIndividualFragment
        }
}
`;

export const BROKER_COMPANY = gql`
  fragment brokerCompanyFragment on ActingCompanyBroker {
    uuid
    name
    fieldVerified
    fieldAccredited
    fieldClient {
      uuid
      type
      fieldLmsClientId
      name
      ... on ClientCompany {
        members {
          name
          ... on ClientIndividual {
            fieldFirstName
            fieldLastName
            fieldPhone
          }
          owner {
            ...userFragment
          }
        }
      }
    }
  }
`;

export const BROKER_INDIVIDUAL = `
  fragment brokerIndividualFragment on ActingIndividualBroker {
    uuid
    name
    fieldVerified
    fieldClient {
      uuid
      type
      fieldLmsClientId
      name
      ... on ClientIndividual {
        fieldFirstName
        fieldLastName
        fieldPhone
        owner {
          ...userFragment
        }
      }
    }
  }
`;
