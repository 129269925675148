import React from 'react';
import { Row, Col } from 'antd';
import Currency from '../../Atoms/DataDisplay/Field/Currency';
import NoResult from '../../Molecules/NoResult/NoResult';
import Text from '../../Atoms/Typography/Text/Text';
import Title from '../../Atoms/Typography/Title/Title';
import './InvestorsConfirmationList.less';

const InvestorsConfirmationList = (
  {
    data,
    headerTitle,
    noResult,
  },
) => (
    <Row className="theme-investors-conformation-list">
      <Col className="title-wrapper" span={12}>
        <Row align="middle">
          <Title level={5}>
            {headerTitle}
            {' '}
            (
            {data?.length || 0}
            )
          </Title>
        </Row>
      </Col>
      <Col span={12}>
        <Row className="list-wrapper">
          <Col span={12}>
            {
              data?.map(({ name, invested_amount }) => (
                <>
                  <div className="investor">
                    <Text className="name">{name}</Text>
                    {
                      invested_amount && (
                        <Text className="invested-amount">
                          {' '}
                          <Currency value={invested_amount} />
                        </Text>
                      )
                    }
                  </div>
                </>
              ))
            }
            {!data?.length && <NoResult {...noResult} />}
          </Col>
        </Row>
      </Col>

    </Row>
);

export default InvestorsConfirmationList;
