import React from 'react';
import { shape, string, number } from 'prop-types';
import { useParams } from 'react-router-dom';
import Panel from '../../Atoms/Layout/Panel/Panel';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import Currency from '../../Atoms/DataDisplay/Field/Currency';
import PillBadges from '../../Atoms/Markup/PillBadges/PillBadges';
import Link from '../../Atoms/Navigation/Links/Link';

const ApplicationsPanel = ({ loan }) => {
  const { context } = useParams();
  const {
    uuid, loanTitle, fieldState, fieldLmsAccountId, fieldAmount, fieldClient,
  } = loan;

  return (
    <Panel>
      <Link to={`/app/${context}/application/${uuid}`}>
        <PillBadges
          badges={[
            {
              title: 'Status',
              value: fieldState === 'assessed' ? 'offer' : fieldState,
            },
            {
              title: 'Loan',
              value: fieldLmsAccountId,
            },
          ]}
        />
        <Paragraph>{fieldClient?.name}</Paragraph>
        <Paragraph>
          {loanTitle}
          {' '}
          <Currency value={fieldAmount} />
        </Paragraph>
      </Link>
    </Panel>
  );
};

ApplicationsPanel.propTypes = {
  loan: shape({
    loanTitle: string.isRequired,
    fieldState: string.isRequired,
    fieldLmsAccountId: string,
    fieldAmount: number,
    fieldClient: shape({
      name: string,
    }),
  }).isRequired,
};

export default ApplicationsPanel;
