import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { useMutation } from '@apollo/client';
import EditableTable from '../../../Atoms/DataDisplay/EditableTable/EditableTable';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import { formatPercentage } from '../../../Hooks/useNumberFormatter';
import { UPDATE_INVESTMENT } from '../../../GraphQL/Mutations/Account';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import formatDate from '../../../Helpers/FormatDate';
import Date from '../../../Atoms/DataDisplay/Field/Date';
import './LoanInvestmentsTable.less';

const LoanInvestmentsTable = ({ loan, investments }) => {
  const [UpdateInvestment] = useMutation(UPDATE_INVESTMENT);
  const isInvestmentRateEditable = haveEntityPermission(loan, 'Can edit investment rate');
  const isCommencementDateEditable = haveEntityPermission(loan, 'Can edit commencement date');

  const handleSave = (record, dataIndex) => {
    const input = {};
    input[dataIndex] = record[dataIndex] || null;
    UpdateInvestment({
      variables: {
        uuid: record.uuid,
        input,
      },
    })
      .then(() => {})
      .catch(() => {});
  };

  const columns = [
    {
      title: 'ACCOUNT ID',
      dataIndex: 'fieldLmsAccountId',
      align: 'left',
      sorter: (a, b) => a.fieldLmsAccountId.localeCompare(b.fieldLmsAccountId),
      defaultSortOrder: 'descend',
    },
    {
      title: 'CLIENT',
      dataIndex: ['fieldClient', 'name'],
      width: '22%',
      align: 'left',
      render: value => <div className="client-column">{value}</div>,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'fieldAmount',
      align: 'left',
      render: value => <Currency value={value} />,
    },
    {
      title: 'INVESTMENT RATE',
      dataIndex: 'investmentRate',
      align: 'left',
      render: value => formatPercentage(value),
      width: '12%',
    },
    {
      title: 'RATE OVERRIDE',
      dataIndex: 'fieldRate',
      editable: isInvestmentRateEditable,
      required: false,
      align: 'left',
      inputType: 'number',
      render: value => (value && formatPercentage(value)) || '',
      width: '12%',
    },
    {
      title: 'COMMENCEMENT DATE',
      dataIndex: 'commencementDate',
      align: 'left',
      render: value => (value && formatDate(value)) || '',
    },
    {
      title: 'DATE OVERRIDE',
      dataIndex: 'fieldCommencementDate',
      editable: isCommencementDateEditable,
      required: false,
      align: 'left',
      inputType: 'date',
      render: value => <Date value={value} format="YYYY-MM-DD" />,
    },
  ];

  return (
    <EditableTable
      dataSource={investments}
      columns={columns}
      handleSave={handleSave}
      pagination={false}
      rowKey="uuid"
      type="inner"
    />
  );
};

LoanInvestmentsTable.propTypes = {
  loan: shape({}).isRequired,
  investments: arrayOf(shape({})),
};

LoanInvestmentsTable.defaultProps = {
  investments: [],
};

export default LoanInvestmentsTable;
