import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { any, shape } from 'prop-types';
import Table from '../../../Atoms/DataDisplay/Table/Table';
import { IDENTITY_VERIFICATION_LOGS } from '../../../GraphQL/Queries/Logs';
import Result from '../../../Atoms/Feedback/Result/Result';
import { formatFromMoment } from '../../../Helpers/FormatDate';
import { snakeCaseToSentenceCase, titleCase } from '../../../Hooks/useStringFormatter';
import Link from '../../../Atoms/Navigation/Links/Link';
import FailureMessageCell from './FailureMessageCell';
import { timestampToDateTimeString } from '../../../Hooks/useDateFormatter';
import './IdentityVerificationLogsTable.less';
import InfiniteScroll from '../../../Molecules/InfiniteScroll';

const IdentityVerificationLogTable = (props) => {
  const { search } = props;
  const limit = 50;
  const variables = {
    offset: 0, limit, sortBy: 'created', sortDirection: 'DESC',
  };

  const {
    loading, error, data, fetchMore, refetch,
  } = useQuery(IDENTITY_VERIFICATION_LOGS, {
    variables,
  });

  useEffect(() => {
    refetch({
      startDate: search?.startDate ? formatFromMoment(search?.startDate) : undefined,
      endDate: search?.endDate ? formatFromMoment(search?.endDate) : undefined,
    }).then();
  }, [search]);

  const columns = [
    {
      title: 'CLIENT',
      dataIndex: ['fieldClient'],
      render: value => <Link to={`/app/dealer/client/${value?.uuid}`}>{titleCase(value?.name)}</Link>,
    },
    {
      title: 'TYPE',
      dataIndex: 'fieldIdentificationType',
      align: 'left',
      render: value => (value ? titleCase(snakeCaseToSentenceCase(value)) : ''),
    },
    {
      title: 'STATUS',
      dataIndex: 'fieldIsVerified',
      align: 'center',
      width: '5rem',
      render: value => (value ? (<FontAwesomeIcon icon={faCircleCheck} />) : (<FontAwesomeIcon icon={faCircleExclamation} />)),
    },
    {
      title: 'DATE',
      dataIndex: 'created',
      align: 'center',
      render: timestampToDateTimeString,
    },
    {
      title: 'FAILURE MESSAGE',
      dataIndex: 'fieldMessage',
      align: 'center',
      render: value => <FailureMessageCell value={value} />,
    },
  ];

  if (error) return <Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />;

  return (
    <>
      <Table
        className="theme-identity-verification-logs-table"
        type="inner"
        columns={columns}
        loading={loading}
        pagination={false}
        rowKey="uuid"
        dataSource={data?.identityVerificationLogs?.results}
      />
      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />
    </>
  );
};

IdentityVerificationLogTable.propTypes = {
  search: shape({
    // eslint-disable-next-line react/forbid-prop-types
    startDate: any,
    // eslint-disable-next-line react/forbid-prop-types
    endDate: any,
  }),
};

IdentityVerificationLogTable.defaultProps = {
  search: undefined,
};

export default IdentityVerificationLogTable;
