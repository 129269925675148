import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import clientRegistrationReducer from './clientRegistrationSlice';
import appConfigurationReducer from './appConfigurationSlice';
import loanApplicationReducer from './loanApplicationSlice';
import userReducer from './userSlice';
import schemaReducer from './schemaSlice';

const userPersistConfig = {
  key: 'user',
  storage,
};

const schemaPersistConfig = {
  key: 'schema',
  storage,
};

const reducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  schema: persistReducer(schemaPersistConfig, schemaReducer),
  clientRegistration: clientRegistrationReducer,
  loanApplication: loanApplicationReducer,
  appConfiguration: appConfigurationReducer,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);
