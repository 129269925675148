import React from 'react';
import { useHistory } from 'react-router';
import HeaderTitle from './HeaderTitle';
import Button from '../../Atoms/Forms/Button/Button';
import HasPermission from '../Control/HavePermission';

const BorrowerApplicationsHeader = () => {
  const history = useHistory();

  return (
    <HeaderTitle
      title="Applications"
      comment="Below is a list of loan applications currently in progress."
    >
      <HasPermission name="join as client">
        <Button type="primary" onClick={() => history.push('/app/borrower/apply')}>Apply for a loan</Button>
      </HasPermission>
    </HeaderTitle>
  );
};

export default BorrowerApplicationsHeader;
