import React from 'react';
import { string } from 'prop-types';
import Text from '../../Atoms/Typography/Text/Text';
import Modal from '../../Atoms/Feedback/Modal/Modal';

const InvestmentSuccessModal = ({
  agreementToken,
  context,
  loanUuid,
}) => (
  <Modal
    title="Your investment has been accepted"
    open
    onOk={() => {
      window.location.href = `/app/${context}/application/${loanUuid}`;
    }}
    onClose={() => {
      window.location.href = `/app/${context}/application/${loanUuid}`;
    }}
    cancelText=""
  >
    <Text>
      { context !== 'dealer' && agreementToken && (
        <>
          You have now entered into a binding Investor Agreement with us, which you can
          {' '}
          <a href={`/document/${agreementToken}`} rel="noopener noreferrer" target="_blank">download in full</a>
          {' '}
          for your records.
          {' '}
        </>
      )}
      Thank you for your funding commitment. Once your subscription is confirmed, an email will be sent to you
      with details to finalise your investment.
    </Text>
  </Modal>
);

InvestmentSuccessModal.propTypes = {
  agreementToken: string,
  context: string.isRequired,
  loanUuid: string.isRequired,
};

InvestmentSuccessModal.defaultProps = {
  agreementToken: null,
};

export default InvestmentSuccessModal;
