import React from 'react';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import ReferralsTable from '../../../Organisms/Clients/ReferralsTable';

const Referrals = () => (
  <div className="theme-client page-inner">
    <HeaderTitle
      title="Client referrals"
      comment="Please find below a list of your converted referrals."
    />
    <ReferralsTable />
  </div>
);

export default Referrals;
