import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useQuery } from '@apollo/client';
import { shape, string } from 'prop-types';
import { Col, Row } from 'antd';
import Modal from '../../Atoms/Feedback/Modal/Modal';
import { SHAREABLE_DOCUMENT } from '../../GraphQL/Queries/Document';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import DocumentLink from '../../Atoms/Navigation/Links/DocumentLink';
import StaticDocumentInfo from '../../Atoms/Media/DocumentLink/StaticDocumentInfo';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import Result from '../../Atoms/Feedback/Result/Result';

const ShareableMedia = ({ match }) => {
  const [modalVisible, setModalVisible] = useState(false);

  // Old link - redirect to new link.
  const path = window.location.pathname;
  if (path.startsWith('/app')) {
    window.location.href = path.replace('/app', '');
  }

  const { params: { shareableUuid, shareableToken } } = match;
  const { error, loading, data } = useQuery(
    SHAREABLE_DOCUMENT,
    { variables: { uuid: shareableUuid, token: shareableToken } },
  );

  if (error && !loading) {
    setTimeout(() => {
      setModalVisible(true);
    }, 1500);
    return (
      <Modal
        open={modalVisible}
        onCancel={() => { setModalVisible(false); window.location.href = '/'; }}
        onOk={() => { setModalVisible(false); window.location.href = '/'; }}
        cancelText=""
      >
        <Result status="info" title="File unavailable" subTitle="Sorry, this file cannot be viewed" />
      </Modal>
    );
  }

  if (data?.shareableDocument) {
    const {
      name,
      description,
      files,
    } = data.shareableDocument;

    const fileComponents = files && files.map((file) => {
      const { uuid } = file;
      return (
        <Col key={`doc-${uuid}`} xs={12} sm={6} md={4}>
          <DocumentLink key={uuid} localDocPath={file.path} path={file.uri}>
            <StaticDocumentInfo file={file} />
          </DocumentLink>
        </Col>
      );
    });

    return (
      <div className="theme-shareable-media page-inner">
        <HeaderTitle title={name}>
          <Paragraph>
            {ReactHtmlParser(description)}
          </Paragraph>
        </HeaderTitle>
        <Row gutter={[16, 16]} className="legal-documents row">
          {fileComponents}
        </Row>
      </div>
    );
  }

  return <Skeleton loading={loading} paragraph={2} />;
};

ShareableMedia.propTypes = {
  match: shape({
    params: shape({
      shareableUuid: string,
      shareableToken: string,
    }).isRequired,
  }).isRequired,
};

export default ShareableMedia;
