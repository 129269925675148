import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { CLIENT_DRAFTS_BY_DEALER, CLIENT_DRAFTS_BY_USER } from '../../GraphQL/Queries/Draft';
import Result from '../../Atoms/Feedback/Result/Result';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import NoResult from '../../Molecules/NoResult/NoResult';
import { isDealer as _isDealer, userUuid as _userUuid } from '../../Store/userSlice';
import DraftListItem from '../../Molecules/ViewModes/DraftListItem/DraftListItem';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const Drafts = () => {
  const { context } = useParams();
  const limit = 25;
  const variables = {
    offset: 0, limit, sort: 'changed', sortDirection: 'DESC',
  };
  const userUuid = useSelector(_userUuid);
  const isDealer = useSelector(_isDealer);
  const {
    error, loading, data, fetchMore,
  } = useQuery(
    isDealer ? CLIENT_DRAFTS_BY_DEALER : CLIENT_DRAFTS_BY_USER,
    {
      variables: {
        ...variables,
        ...((!isDealer && { owner: userUuid }) || {}),
      },
    },
  );

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { drafts } = data || {};
  const { results } = drafts || [];

  return (
    <div className="theme-org-clients">
      <Skeleton loading={loading}>
        { !results?.length && (
          <NoResult
            title="No drafts found."
            body="Drafts will be listed here when you begin the registration process for a new client."
          />
        )}
        {results?.map(item => (
          <DraftListItem key={item?.uuid} uuid={item?.uuid} name={item?.name} context={context} sublink="client/add" />
        ))}
        <InfiniteScroll
          pageSize={limit}
          loading={loading}
          data={data}
          fetchMore={fetchMore}
        />
      </Skeleton>
    </div>
  );
};


export default Drafts;
