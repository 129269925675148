import React from 'react';
import {
  number, arrayOf, oneOfType, shape,
} from 'prop-types';
import { Row, Col, Image } from 'antd';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import StaticMap from '../../../Molecules/Map/StaticMap';
import './SecurityDetails.less';
import { formatPercentage, formatCurrencyFull } from '../../../Hooks/useNumberFormatter';

const SecurityDetails = ({
  lvr, totalValue, data,
}) => {
  const location = data?.fieldAddress.value;

  let fields = [
    { label: 'Location', value: location },
  ];

  if (lvr) {
    fields = [{ label: 'LVR', value: formatPercentage(lvr) }, ...fields];
  }

  if (totalValue) {
    fields = [...fields, { label: 'Total value', value: formatCurrencyFull(totalValue) }];
  }

  function fileNameFromUrl(url) {
    const matches = url.match(/\/([^/?#]+)[^/]*$/);
    if (matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  function isMapImage(url) {
    return fileNameFromUrl(url).startsWith('MAP-') || fileNameFromUrl(url).startsWith('maps-');
  }

  let imageSources = [];


  // @todo full address flag.
  imageSources = data?.fieldImages?.map((mapUrl) => {
    const {
      propertyThumbnail,
      original,
    } = mapUrl;
    if (!isMapImage(propertyThumbnail)) {
      return { url: propertyThumbnail, fullUrl: original };
    }
    return null;
  }) ?? [];

  if (imageSources.length > 0 && imageSources[0] === null) {
    imageSources = [];
  }


  return (
    <Card
      title={(<Title level={4}>Security details</Title>)}
      className="theme-security-details"
    >
      <Fields fields={fields} />
      <Row>
        <Col span={12} className="image-wrapper">
          {
          imageSources.length > 0
            ? (
              <Image
                width="100%"
                src={imageSources[0].url}
                preview={{ src: imageSources[0].fullUrl }}
              />
            )
            : (
              <StaticMap location={location} />
            )
        }
        </Col>
      </Row>
    </Card>
  );
};

SecurityDetails.propTypes = {
  lvr: number.isRequired,
  totalValue: number.isRequired,
  data: oneOfType(
    shape({
      fieldAddress: Object,
      fieldImages: Object,
    }),
    arrayOf(shape({
      fieldAddress: Object,
      fieldImages: Object,
    })),
  ),
};

SecurityDetails.defaultProps = {
  data: null,
};

export default SecurityDetails;
