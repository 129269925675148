/* eslint-disable max-len */
import React from 'react';

const NoResultIcon = () => (
  <svg width="90" height="89" viewBox="0 0 90 89" xmlns="http://www.w3.org/2000/svg">
    <g id="White-Label" fill="none" fillRule="evenodd">
      <g id="02-Loan-applications-Copy" transform="translate(-518 -511)" fill="#C9C9C9" fillRule="nonzero">
        <g id="UI" transform="translate(209 210)">
          <g id="no-result-icon" transform="translate(309 301)">
            <path d="M30.526699,9.76699029 C37.2953413,2.92229587 45.4319913,-0.5 54.9368932,-0.5 C64.4417951,-0.5 72.5784451,2.92229587 79.3470874,9.76699029 C86.1157296,16.6116847 89.5,24.8397578 89.5,34.4514563 C89.5,44.0631549 86.1157296,52.2912279 79.3470874,59.1359223 C72.5784451,65.9806167 64.4417951,69.4029126 54.9368932,69.4029126 C46.7281143,69.4029126 39.4555333,66.7815796 33.118932,61.538835 L5.68446602,89.5 L0.5,84.2572816 L28.1504854,56.5145631 C22.9659935,50.1067641 20.3737864,42.7524687 20.3737864,34.4514563 C20.3737864,24.8397578 23.7580568,16.6116847 30.526699,9.76699029 Z M73.6875,15.3125 C68.2916397,9.77080562 61.7292053,7 54,7 C46.2707947,7 39.6719023,9.73434766 34.203125,15.203125 C28.7343477,20.6719023 26,27.2707947 26,35 C26,42.7292053 28.7343477,49.3280977 34.203125,54.796875 C39.6719023,60.2656523 46.2707947,63 54,63 C61.7292053,63 68.3280977,60.2656523 73.796875,54.796875 C79.2656523,49.3280977 82,42.7292053 82,35 C82,27.2707947 79.2291944,20.7083603 73.6875,15.3125 Z" id="magnifying-glass" transform="rotate(-90 45 44.5)" />
            <polygon id="mouth" points="28 44 28 43 43 43 43 44" />
            <g id="left-eye" transform="translate(18 25)">
              <path d="M0,12.5 C0,12.1557954 0.063405163,11.8297117 0.190217391,11.5217391 C0.31702962,11.2137666 0.493658288,10.9465591 0.720108696,10.7201087 C0.946559103,10.4936583 1.21376658,10.3170296 1.52173913,10.1902174 C1.82971168,10.0634052 2.15579538,10 2.5,10 C2.84420462,10 3.17028832,10.0634052 3.47826087,10.1902174 C3.78623342,10.3170296 4.0534409,10.4936583 4.2798913,10.7201087 C4.50634171,10.9465591 4.68297038,11.2137666 4.80978261,11.5217391 C4.93659484,11.8297117 5,12.1557954 5,12.5 C5,12.8442046 4.93659484,13.1702883 4.80978261,13.4782609 C4.68297038,13.7862334 4.50634171,14.0534409 4.2798913,14.2798913 C4.0534409,14.5063417 3.78623342,14.6829704 3.47826087,14.8097826 C3.17028832,14.9365948 2.84420462,15 2.5,15 C2.15579538,15 1.82971168,14.9365948 1.52173913,14.8097826 C1.21376658,14.6829704 0.946559103,14.5063417 0.720108696,14.2798913 C0.493658288,14.0534409 0.31702962,13.7862334 0.190217391,13.4782609 C0.063405163,13.1702883 0,12.8442046 0,12.5 Z" id="Path" />
              <path d="M2.04631579,5.46341463 C2.04631579,5.21951098 2.07999966,5.01219598 2.14736842,4.84146341 C2.21473718,4.67073085 2.32842025,4.51219585 2.48842105,4.36585366 C2.72421171,4.14634037 2.94105164,3.95528537 3.13894737,3.79268293 C3.33684309,3.63008049 3.50736771,3.47764299 3.65052632,3.33536585 C3.79368493,3.19308872 3.90526276,3.05894372 3.98526316,2.93292683 C4.06526356,2.80690994 4.10526316,2.67479744 4.10526316,2.53658537 C4.10526316,2.26829134 4.01894823,2.05894384 3.84631579,1.90853659 C3.67368335,1.75812933 3.45684341,1.68292683 3.19578947,1.68292683 C2.79999802,1.68292683 2.4989484,1.78861683 2.29263158,2 C2.08631476,2.21138317 1.97052644,2.46747817 1.94526316,2.76829268 L0,2.64634146 C0.0926320421,1.78454854 0.421049811,1.12805104 0.985263158,0.676829268 C1.54947651,0.2256075 2.27367979,0 3.15789474,0 C3.54526509,0 3.90947198,0.0508125 4.25052632,0.152439024 C4.59158065,0.254065549 4.89263027,0.406503049 5.15368421,0.609756098 C5.41473815,0.813009146 5.62105187,1.06503915 5.77263158,1.36585366 C5.92421128,1.66666817 6,2.01219317 6,2.40243902 C6,2.63821256 5.97473709,2.85162506 5.92421053,3.04268293 C5.87368396,3.23374079 5.78736903,3.42276329 5.66526316,3.6097561 C5.54315728,3.7967489 5.38315888,3.9857714 5.18526316,4.17682927 C4.98736743,4.36788713 4.73684362,4.57723463 4.43368421,4.80487805 C4.23999903,4.95122024 4.10947402,5.09349524 4.04210526,5.23170732 C3.97473651,5.36991939 3.94105263,5.50812939 3.94105263,5.64634146 L3.94105263,6 L2.04631579,6 L2.04631579,5.46341463 Z" id="Path" />
            </g>
            <g id="right-eye" transform="translate(49 25)">
              <path d="M0,12.5 C0,12.1557954 0.063405163,11.8297117 0.190217391,11.5217391 C0.31702962,11.2137666 0.493658288,10.9465591 0.720108696,10.7201087 C0.946559103,10.4936583 1.21376658,10.3170296 1.52173913,10.1902174 C1.82971168,10.0634052 2.15579538,10 2.5,10 C2.84420462,10 3.17028832,10.0634052 3.47826087,10.1902174 C3.78623342,10.3170296 4.0534409,10.4936583 4.2798913,10.7201087 C4.50634171,10.9465591 4.68297038,11.2137666 4.80978261,11.5217391 C4.93659484,11.8297117 5,12.1557954 5,12.5 C5,12.8442046 4.93659484,13.1702883 4.80978261,13.4782609 C4.68297038,13.7862334 4.50634171,14.0534409 4.2798913,14.2798913 C4.0534409,14.5063417 3.78623342,14.6829704 3.47826087,14.8097826 C3.17028832,14.9365948 2.84420462,15 2.5,15 C2.15579538,15 1.82971168,14.9365948 1.52173913,14.8097826 C1.21376658,14.6829704 0.946559103,14.5063417 0.720108696,14.2798913 C0.493658288,14.0534409 0.31702962,13.7862334 0.190217391,13.4782609 C0.063405163,13.1702883 0,12.8442046 0,12.5 Z" id="Path" />
              <path d="M2.04631579,5.46341463 C2.04631579,5.21951098 2.07999966,5.01219598 2.14736842,4.84146341 C2.21473718,4.67073085 2.32842025,4.51219585 2.48842105,4.36585366 C2.72421171,4.14634037 2.94105164,3.95528537 3.13894737,3.79268293 C3.33684309,3.63008049 3.50736771,3.47764299 3.65052632,3.33536585 C3.79368493,3.19308872 3.90526276,3.05894372 3.98526316,2.93292683 C4.06526356,2.80690994 4.10526316,2.67479744 4.10526316,2.53658537 C4.10526316,2.26829134 4.01894823,2.05894384 3.84631579,1.90853659 C3.67368335,1.75812933 3.45684341,1.68292683 3.19578947,1.68292683 C2.79999802,1.68292683 2.4989484,1.78861683 2.29263158,2 C2.08631476,2.21138317 1.97052644,2.46747817 1.94526316,2.76829268 L0,2.64634146 C0.0926320421,1.78454854 0.421049811,1.12805104 0.985263158,0.676829268 C1.54947651,0.2256075 2.27367979,0 3.15789474,0 C3.54526509,0 3.90947198,0.0508125 4.25052632,0.152439024 C4.59158065,0.254065549 4.89263027,0.406503049 5.15368421,0.609756098 C5.41473815,0.813009146 5.62105187,1.06503915 5.77263158,1.36585366 C5.92421128,1.66666817 6,2.01219317 6,2.40243902 C6,2.63821256 5.97473709,2.85162506 5.92421053,3.04268293 C5.87368396,3.23374079 5.78736903,3.42276329 5.66526316,3.6097561 C5.54315728,3.7967489 5.38315888,3.9857714 5.18526316,4.17682927 C4.98736743,4.36788713 4.73684362,4.57723463 4.43368421,4.80487805 C4.23999903,4.95122024 4.10947402,5.09349524 4.04210526,5.23170732 C3.97473651,5.36991939 3.94105263,5.50812939 3.94105263,5.64634146 L3.94105263,6 L2.04631579,6 L2.04631579,5.46341463 Z" id="Path" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NoResultIcon;
