import React from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { formatDateTime } from '../../../Helpers/FormatDate';
import Table from '../../DataDisplay/Table/Table';
import TableSummaryCell from '../../DataDisplay/Table/Summary/TableSummaryCell/TableSummaryCell';
import TableSummaryRow from '../../DataDisplay/Table/Summary/TableSummaryRow/TableSummaryRow';
import Currency from '../../DataDisplay/Field/Currency';

const InvestmentStatementTable = (props) => {
  const { transactions: { table, closingBalance } } = props;
  const isSmallScreen = useMediaQuery({ query: '(max-width: 480px)' });

  const columns = [
    {
      title: 'DATE \n DESCRIPTION',
      align: 'left',
      render: record => (
        <>
          {formatDateTime(record.transactionDate)}
          <br />
          {record.description}
        </>
      ),
      responsive: ['xs'],
    },
    {
      title: 'DATE',
      dataIndex: 'transactionDate',
      align: 'left',
      responsive: ['sm'],
      render: value => formatDateTime(value),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'DEBIT $',
      dataIndex: 'debit',
      align: 'right',
      render: value => <Currency value={value} decimals={2} prefix="" showEmpty={false} />,
    },
    {
      title: 'CREDIT $',
      dataIndex: 'credit',
      align: 'right',
      render: value => <Currency value={value} decimals={2} prefix="" showEmpty={false} />,
    },
    {
      title: 'BALANCE $',
      dataIndex: 'balance',
      align: 'right',
      render: value => <Currency value={value} decimals={2} accountingFormat prefix="" />,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={table ?? []}
      pagination={false}
      summary={() => (
        <TableSummaryRow>
          <TableSummaryCell colSpan={isSmallScreen ? 3 : 4}>CLOSING BALANCE</TableSummaryCell>
          <TableSummaryCell colSpan={1}><Currency value={closingBalance} decimals={2} accountingFormat prefix="" /></TableSummaryCell>
        </TableSummaryRow>
      )}
    />
  );
};

InvestmentStatementTable.propTypes = {
  transactions: shape({
    table: arrayOf(shape({
      balance: number,
      credit: number,
      debit: number,
      description: string,
      transactionDate: string,
    })),
  }),
};

InvestmentStatementTable.defaultProps = {
  transactions: [],
};

export default InvestmentStatementTable;
