import React, { useState } from 'react';
import {
  Form, notification,
} from 'antd';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import documentsService from '../../Services/Documents.service';
import { accountLookup, clientLookup } from './Components/DocumentEntitySearch';
import { documentTypesSelectList } from './Components/DocumentTypeSelect';
import DocumentEditForm from './Components/DocumentEditForm';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';

const DocumentAdd = () => {
  const [documentTypesList, setDocumentTypesList] = useState(null);
  const [isMultiDoc, setIsMultiDoc] = useState(false);
  const [documentTypeValue, setDocumentTypeValue] = useState([]);
  const [entitySelect, setEntitySelect] = useState('');
  const [clientValue, setClientValue] = useState(null);
  const [clientText, setClientText] = useState(null);
  const [accountValue, setAccountValue] = useState(null);
  const [accountText, setAccountText] = useState(null);
  const [currentValue, setCurrentValue] = useState(true);
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();
  const { history } = useHistory();
  const { context } = useParams();

  if (documentTypesList === null) {
    documentTypesSelectList(setDocumentTypesList);
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const documentRecord = {
        access: values.accessValue,
        current: values.currentValue,
        // eslint-disable-next-line camelcase
        client_uuid: entitySelect === 'client' ? values.clientValue : null,
        // eslint-disable-next-line camelcase
        account_uuid: entitySelect === 'account' ? values.accountValue : null,
        categories: values.documentTypeValue,
        files: fileList.map(item => item.fid),
      };
      documentsService.createDocument(
        documentRecord,
        () => {
          notification.open({
            type: 'success',
            message: 'A new document has been created.',
            duration: 2,
            onClose: () => {
              window.location.href = `/app/${context}/documents`;
            },
          });
        },
        () => {
          notification.open({
            type: 'error',
            message: 'An error occurred creating the document.',
            duration: 2,
            onClose: () => {
              window.location.href = `/app/${context}/documents`;
            },
          });
        },
      );
    });
  };

  const clients = {
    searchEvent: clientLookup,
    setValue: setClientValue,
    setText: setClientText,
    value: clientValue,
    text: clientText,
  };
  const accounts = {
    searchEvent: accountLookup,
    setValue: setAccountValue,
    setText: setAccountText,
    value: accountValue,
    text: accountText,
  };

  return (
    <div className="theme-document-add page-inner">
      <HeaderTitle
        title="Upload document"
      />

      <h4 className="section-title">Document Detail</h4>

      <DocumentEditForm
        history={history}
        accessValue="internal"
        accounts={accounts}
        accountValue={accountValue}
        clients={clients}
        clientValue={clientValue}
        currentValue={currentValue}
        defaultFileList={fileList}
        documentTypesList={documentTypesList}
        documentTypeValue={documentTypeValue}
        entitySelect={entitySelect}
        entitySelectEvent={setEntitySelect}
        documentForm={form}
        isMultiDoc={isMultiDoc}
        onFormSubmit={onFormSubmit}
        setDocumentTypeValue={setDocumentTypeValue}
        setFileList={setFileList}
        setCurrentValue={setCurrentValue}
        setIsMultiDoc={setIsMultiDoc}
        showDelete={false}
      />
    </div>
  );
};

export default DocumentAdd;
