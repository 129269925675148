import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faTag,
  faBoxes,
  faClipboardCheck,
  faTh,
  faBullseye,
  faUser,
  faWrench,
  faClock,
  faKeyboard,
  faPlus,
  faMinus,
  faLocationArrow,
  faSearch,
  faArrowLeft,
  faTimes,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCalculator,
  faCheck,
  faPen,
  faExclamationCircle,
  faSpinner,
  faStar,
  faChartLine,
  faCheckCircle,
  faCamera,
  faMinusCircle,
  faTimesCircle,
  faCircleNotch,
  faSignInAlt,
  faUnlockAlt,
  faEnvelope,
  faImage,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import App from './App';
import * as serviceWorker from './serviceWorker';

library.add(
  faAddressCard,
  faTag,
  faBoxes,
  faClipboardCheck,
  faTh,
  faBullseye,
  faUser,
  faWrench,
  faClock,
  faKeyboard,
  faPlus,
  faMinus,
  faLocationArrow,
  faSearch,
  faArrowLeft,
  faTimes,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCalculator,
  faCheck,
  faPen,
  faExclamationCircle,
  faSpinner,
  faStar,
  faChartLine,
  faCheckCircle,
  faCamera,
  faMinusCircle,
  faTimesCircle,
  faCircleNotch,
  faSignInAlt,
  faUnlockAlt,
  faEnvelope,
  faImage,
  faExclamation,
);

const root = document.getElementById('reactApp');

ReactDOM.render(<App {...(root.dataset)} />, root);

serviceWorker.unregister();
