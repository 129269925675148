import { Checkbox, Form } from 'antd';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { func, shape } from 'prop-types';
import { UPDATE_INVESTMENT } from '../../../GraphQL/Mutations/Account';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import Title from '../../../Atoms/Typography/Title/Title';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import InputNumber from '../../../Atoms/Forms/Input/InputNumber';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Button from '../../../Atoms/Forms/Button/Button';

const InvestmentEditForm = ({ onSuccess, entityData }) => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const [UpdateInvestment, { loading }] = useMutation(UPDATE_INVESTMENT);
  const dateFormat = 'YYYY-MM-DD';

  const { uuid, ...inputValues } = entityData;

  const submitForm = (values) => {
    UpdateInvestment({
      variables: {
        uuid,
        input: values,
      },
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setMessage(getGraphQlErrorMessage(error));
      });
  };

  return (
    <Form
      form={form}
      name="account"
      onFinish={submitForm}
      initialValues={inputValues}
    >
      {message && <Alert message={message} type="error" />}
      <Title level={4}>Display settings</Title>
      <div className="checkbox-inline-group">
        <FormItem
          name="fieldHideForClient"
          valuePropName="checked"
          className="form-item-hide-for-client"
        >
          <Checkbox>Hide from investor</Checkbox>
        </FormItem>
      </div>
      {haveEntityPermission(entityData, 'Can edit commencement date') && (
        <FormItem
          name="fieldCommencementDate"
          valuePropName="value"
          label="Commencement date"
        >
          <DatePicker
            format={dateFormat}
          />
        </FormItem>
      )}
      {haveEntityPermission(entityData, 'Can edit investment rate') && (
        <>
          <FormItem
            name="fieldRate"
            valuePropName="value"
            label="Investment rate"
          >
            <InputNumber />
          </FormItem>
          <p>
            * If investment rate is left blank, the
            {entityData?.fieldLoan ? ' pool' : ' fund\'s target'}
            {' '}
            rate will be used.
          </p>
        </>
      )}
      <ButtonGroup direction="bottom-right">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
      </ButtonGroup>
    </Form>
  );
};

InvestmentEditForm.propTypes = {
  entityData: shape({}).isRequired,
  onSuccess: func,
};

InvestmentEditForm.defaultProps = {
  onSuccess: () => {
  },
};
export default InvestmentEditForm;
