import apiGraphQlService from '../Helpers/ApiGraphQLService';
import {
  fragmentAddress,
  fragmentPhone,
  fragmentLmsId,
  fragmentFieldIdentification,
  reverseFieldClientAssociationAssociation, fragmentBrokerCompanyInfo,
} from './Fragments';
import { axiosParams } from '../Helpers/Helpers';
import axios from '../Vendor/Axios';

const clientCollection = (search, types, roles, owner, page = 0, pageSize = 25) => {
  const vars = {
    offset: page * pageSize,
    limit: pageSize,
    sort: 'created',
    sortDirection: 'DESC',
  };
  if (search) {
    vars.search = search;
  }
  if (types) {
    vars.types = [types];
  }
  if (roles) {
    vars.roles = [roles];
  }
  if (owner) {
    vars.user = owner;
  }

  const query = `
    query clients(
      $roles: [ClientRole]
      $search: String
      $types: [ClientType]
      $user: String
      $offset: Int = 0
      $limit: Int = 20
      $sort: ClientsSortOrder
      $sortDirection: SortDirection
    ) {
      clients(
        roles: $roles
        types: $types
        search: $search
        user: $user
        offset: $offset
        limit: $limit
        sort: $sort
        sortDirection: $sortDirection
      ) {
        total,
        results {
          uuid
          name
          clientType: type
          fieldLmsClientId
          acting {
            fieldVerified
            ... on ActingCompanyInvestor {
              fieldInvestorClassVerified
            }
            ... on ActingIndividualInvestor {
              fieldInvestorClassVerified
            }
            ... on ActingIndividualsInvestor {
              fieldInvestorClassVerified
            }
            ... on ActingPartnershipInvestor {
              fieldInvestorClassVerified
            }
            ... on ActingTrustInvestor {
              fieldInvestorClassVerified
            }
            clientRole: role
          }
          owner {
            email
          }
        }
      }
    }
  `;

  return apiGraphQlService.persistedQuery(query, vars);
};

const investorClientCollection = (user = null, search = null, offset = 0, limit = 25) => {
  const vars = {
    offset,
    limit,
    verified: true,
    sort: 'name',
    sortDirection: 'ASC',
  };
  if (user !== null) {
    vars.user = user;
  }
  if (search !== null) {
    vars.search = search;
  }

  const query = `
    query investorClientsQuery(
      $search: String
      $user: String
      $verified: Boolean
      $offset: Int = 0
      $limit: Int = 999
      $sort: ClientsSortOrder
      $sortDirection: SortDirection
    ) {
      investorClients(
        search: $search
        user: $user
        verified: $verified
        offset: $offset
        limit: $limit
        sort: $sort
        sortDirection: $sortDirection
      ) {
        total,
        results {
          uuid
          entityPermissions {
            label
            permitted
          }
          fieldLmsClientId
          name
          owner {
            email
          }
        }
      }
    }
  `;

  return apiGraphQlService.persistedQuery(query, vars);
};

const primaryClientsBroker = () => axios
  .get('/api/broker/contacts')
  .then((response) => {
    const { data } = response;
    return Promise.resolve(data);
  })
  .catch();

const primaryClientsDealer = () => {
  const query = `
    query userQuery() {
      users(
        isPrimary: true
      ) {
        id
        email
        fieldClient {
          uuid
          name
        }
      }
    }
  }
  `;

  return apiGraphQlService.persistedQuery(query, []);
};

const client = (uuid) => {
  const query = `
    query clientPersistedQuery(
      $uuid: String!
    ) {
      client(uuid: $uuid) {
        fieldLmsClientId
        name
        type
        uuid
        owner {
          email
          primary {
            uuid
          }
        }
        entityPermissions {
          label
          permitted
        }
        ... on ClientCompany {
          fieldBusinessNumber
          fieldCompanyNumber
          members {
            uuid
            name
            owner {
              email
              primary {
                uuid
              }
            }
            ... on ClientIndividual {
              fieldAddress {
                value
              }
              fieldFirstName
              fieldLastName
              fieldMiddleName
              fieldPersonFatca
              fieldPhone
              fieldIdentification {
                type
                fieldVerified
              }
            }
          }
        }
        ... on ClientIndividual {
          fieldAddress {
            address_line1
            address_line2
            dependent_locality
            locality
            administrative_area
            postal_code
            country_code
            value
          }
          fieldDateOfBirth
          fieldFirstName
          fieldLastName
          fieldMiddleName
          fieldPersonFatca
          fieldPhone
          fieldIdentification {
            type
            fieldVerified
          }
          organisations {
            fieldLmsClientId
            name
            type
            uuid
            acting {
              role
            }
          }
        }
        ... on ClientIndividuals {
          members {
            uuid
            name
            owner {
              email
              primary {
                uuid
              }
            }
            ... on ClientIndividual {
              fieldAddress {
                value
              }
              fieldFirstName
              fieldLastName
              fieldMiddleName
              fieldPersonFatca
              fieldPhone
              fieldIdentification {
                type
                fieldVerified
              }
            }
          }
        }
        ... on ClientPartnership {
          members {
            uuid
            name
            owner {
              email
              primary {
                uuid
              }
            }
            ... on ClientIndividual {
              fieldAddress {
                value
              }
              fieldFirstName
              fieldLastName
              fieldMiddleName
              fieldPersonFatca
              fieldPhone
              fieldIdentification {
                type
                fieldVerified
              }
            }
          }
        }
        ... on ClientTrust {
          fieldTrustType
          fieldTrusteeType
          members {
            uuid
            name
            owner {
              email
              primary {
                uuid
              }
            }
            ... on ClientIndividual {
              fieldAddress {
                value
              }
              fieldFirstName
              fieldLastName
              fieldMiddleName
              fieldPersonFatca
              fieldPhone
              fieldIdentification {
                type
                fieldVerified
              }
            }
          }
        }
        acting {
          role
          fieldVerified
          ... on ActingIndividualBorrower {
            fieldBroker {
              uuid
              name
              ... on ActingCompanyBroker {
                fieldVerified
                fieldAccredited
                fieldClient {
                  ... on ClientCompany {
                    members {
                      name
                      ... on ClientIndividual {
                        fieldPhone
                      }
                      owner {
                        email
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ActingCompanyBorrower {
            fieldAddress {
              value
            }
            fieldBroker {
              uuid
              name
              ... on ActingCompanyBroker {
                fieldVerified
                fieldAccredited
                fieldClient {
                  ... on ClientCompany {
                    members {
                      name
                      ... on ClientIndividual {
                        fieldPhone
                      }
                      owner {
                        email
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ActingCompanyBroker {
            fieldAddress {
              value
            }
            fieldWebsite
          }
          ... on ActingCompanyInvestor {
            fieldFatca
            fieldInvestorClassVerified
            fieldInvestorClassification
            fieldIsAuTaxRegistered
            fieldRegistrationCountry
          }
          ... on ActingIndividualInvestor {
            fieldFatca
            fieldInvestorClassVerified
            fieldInvestorClassification
            fieldIsAuTaxRegistered
            fieldRegistrationCountry
          }
          ... on ActingIndividualsBorrower {
            fieldBroker {
              uuid
              name
              ... on ActingCompanyBroker {
                fieldVerified
                fieldAccredited
                fieldClient {
                  ... on ClientCompany {
                    members {
                      name
                      ... on ClientIndividual {
                        fieldPhone
                      }
                      owner {
                        email
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ActingIndividualsInvestor {
            fieldFatca
            fieldInvestorClassVerified
            fieldInvestorClassification
            fieldIsAuTaxRegistered
            fieldRegistrationCountry
          }
          ... on ActingPartnershipBorrower {
            fieldAddress {
              value
            }
            fieldBroker {
              uuid
              name
              ... on ActingCompanyBroker {
                fieldVerified
                fieldAccredited
                fieldClient {
                  ... on ClientCompany {
                    members {
                      name
                      ... on ClientIndividual {
                        fieldPhone
                      }
                      owner {
                        email
                      }
                    }
                  }
                }
              }
            }
            fieldWebsite
          }
          ... on ActingPartnershipInvestor {
            fieldBusinessNumber
            fieldFatca
            fieldInvestorClassVerified
            fieldInvestorClassification
            fieldIsAuTaxRegistered
            fieldPartnershipType
            fieldRegistrationCountry
          }
          ... on ActingTrustBorrower {
            fieldAddress {
              value
            }
            fieldBroker {
              uuid
              name
              ... on ActingCompanyBroker {
                fieldVerified
                fieldAccredited
                fieldClient {
                  ... on ClientCompany {
                    members {
                      name
                      ... on ClientIndividual {
                        fieldPhone
                      }
                      owner {
                        email
                      }
                    }
                  }
                }
              }
            }
            fieldCorporateAbn
            fieldCorporateAcn
            fieldCorporateEntityName
          }
          ... on ActingTrustInvestor {
            fieldCorporateAbn
            fieldCorporateAcn
            fieldCorporateEntityName
            fieldFatca
            fieldInvestorClassVerified
            fieldInvestorClassification
            fieldIsAuTaxRegistered
            fieldRegistrationCountry
          }
        }
        owner {
          email
        }
      }
    }
  `;

  return apiGraphQlService.persistedQuery(query, { uuid });
};

const myClientGetCommonFragment = `
  id
  uuid
  entityId
  entityUuid
  clientName: entityLabel
  clientType: entityBundle
  fieldLmsClientId
  uid {
    entity {
      uid
      fieldRealEmail
    }
  }
`;

const myClientGetIndividualFragment = `
  ... on ClientIndividual {
    ${myClientGetCommonFragment}
    clientContact: uid {
      entity {
        ... on User {
          fieldClient {
            entity {
              contactUuid: entityUuid
            }
          }
        }
      }
    }
    reverseFieldClientActingClient {
      entities {
        entityBundle
        ... on ActingClient {
          ${fragmentBrokerCompanyInfo}
        }
        ... on ActingClientIndividualBorrower {
          fieldVerified
        }
        ... on ActingClientIndividualInvestor {
          fieldVerified
        }
      }
    }
    fieldFirstName
    fieldMiddleName
    fieldLastName
    ${fragmentFieldIdentification}
    fieldPhone {
      entity {
        ${fragmentPhone}
      }
    }
    fieldAddress {
      entity {
        ${fragmentAddress}
      }
    }
  }
`;

const myClientGetCompanyFragment = `
  ... on ClientCompany {
    ${myClientGetCommonFragment}
    reverseFieldClientActingClient {
      entities {
        entityBundle
        ... on ActingClient {
          ${fragmentBrokerCompanyInfo}
        }
        ... on ActingClientCompanyBorrower {
          id
          fieldVerified
          fieldAddress {
            entity {
              ${fragmentAddress}
            }
          }
        }
        ... on ActingClientCompanyInvestor {
          fieldVerified
          fieldFatca
          fieldInvestorClassification
          fieldInvestorClassVerified
          fieldRegistrationCountry
          fieldIsAuTaxRegistered
        }
      }
    }
    ${reverseFieldClientAssociationAssociation}
  }
`;

const myClientGetTrustFragment = `
  ... on ClientTrust {
    ${myClientGetCommonFragment}
    fieldTrustType
    fieldTrusteeType
    fieldLmsClientId
    reverseFieldClientActingClient {
      entities {
        entityBundle
        ... on ActingClient {
          ${fragmentBrokerCompanyInfo}
        }
        ... on ActingClientTrustBorrower {
          id
          fieldVerified
          fieldLmsClientId
          fieldCorporateAbn
          fieldCorporateAcn
          fieldCorporateEntityName
          fieldAddress {
            entity {
              ${fragmentAddress}
            }
          }
        }
        ... on ActingClientTrustInvestor {
          fieldVerified
          fieldLmsClientId
          fieldFatca
          fieldInvestorClassification
          fieldInvestorClassVerified
          fieldIsAuTaxRegistered
        }
      }
    }
    reverseFieldClientAssociationAssociation {
      entities {
        entityId
        ... on AssociationClientAssociation {
          fieldClientAssociationMembers {
            entity {
              ... on Client {
                id
                uuid
                clientName: entityLabel
                clientType: entityBundle
              }
            }
          }
        }
      }
    }
    ${reverseFieldClientAssociationAssociation}
  }
`;

const myClientGetOtherFragment = fragment => `
    ... on Client${fragment} {
      ${myClientGetCommonFragment}
      reverseFieldClientActingClient {
        entities {
          entityBundle
          ... on ActingClient {
            ${fragmentBrokerCompanyInfo}
          }
          ... on ActingClient${fragment}Borrower {
            id
            fieldVerified
            fieldLmsClientId
          }
          ... on ActingClient${fragment}Investor {
            fieldVerified
            fieldLmsClientId
            fieldInvestorClassification
            fieldInvestorClassVerified
            fieldIsAuTaxRegistered
          }
        }
      }
      reverseFieldClientAssociationAssociation {
        entities {
          entityId
          ... on AssociationClientAssociation {
            fieldClientAssociationMembers {
              entity {
                clientType: entityBundle
                clientName: entityLabel
                ... on ClientIndividual {
                  id
                  uuid
                  fieldFirstName
                  fieldMiddleName
                  fieldLastName
                  ${fragmentFieldIdentification}
                  uid {
                    entity {
                      fieldRealEmail
                    }
                  }
                  fieldPhone {
                    entity {
                      ${fragmentPhone}
                    }
                  }
                  fieldAddress {
                    entity {
                      ${fragmentAddress}
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

function myClientGet(clientUuid) {
  const query = `
  {
    clientQuery (
      limit: 1
      filter: {
        conditions: [{field: "uuid" value: "${clientUuid}"}]
      }
    ){
      entities {
        clientType: entityBundle
        ${myClientGetIndividualFragment}
        ${myClientGetCompanyFragment}
        ${myClientGetTrustFragment}
        ${myClientGetOtherFragment('Individuals')}
        ${myClientGetOtherFragment('Partnership')}
      }
    }
  }
  `;
  return apiGraphQlService.query(query);
}

function getClientEntities(clientUuid) {
  const query = `
    {
      associationQuery(
        filter: {
          conditions: [
            {
              field: "type",
              value: "client_association",
            }
            {
              operator: EQUAL
              field: "field_client_association_members.entity.uuid"
              value: "${clientUuid}"
            }
          ]
        }
      )
      {
        entities {
          ... on AssociationClientAssociation {
            fieldClientAssociation {
              entity {
                clientName: entityLabel
                clientType: entityBundle
                ${fragmentLmsId}
                ... on Client {
                  id
                  uuid
                  reverseFieldClientActingClient {
                    actingClients: entities {
                      actingBundle: entityBundle
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  return apiGraphQlService.query(query);
}

function dealerInvestors(searchText) {
  const query = `
  {
    clientsQuery: dealerInvestorsGraphqlView(
      contextualFilter: {
        field_key_value: "${process.env.REACT_APP_X_DEALER}"
      }
      filter: {
        search: "${searchText}"
      }
    ){
      count
      results {
        clientName: entityLabel
        uuid
        ... on ClientIndividual {
          fieldLmsClientId
        }
        ... on ClientIndividuals {
          fieldLmsClientId
        }
        ... on ClientCompany {
          fieldLmsClientId
        }
        ... on ClientTrust {
          fieldLmsClientId
        }
        ... on ClientPartnership {
          fieldLmsClientId
        }
      }
    }
  }
  `;

  return apiGraphQlService.query(query);
}

function investorMatchPageData(loanId) {
  return axios
    .get(`api/investor-match/${loanId}${axiosParams()}`)
    .then((response) => {
      const { data } = response;
      return Promise.resolve(data);
    })
    .catch();
}

function investorMatchSubmit(loanUuid, investorIds) {
  return axios
    .post(`api/investor-match-submit/${loanUuid}${axiosParams()}`, { investorIds })
    .then(response => Promise.resolve(response)).catch();
}

function newLoanToInvestorsSubmit(data) {
  return axios
    .post(`api/new-loan-to-investors${axiosParams()}`, { data })
    .then(response => Promise.resolve(response)).catch();
}

const clientService = {
  clientCollection,
  client,
  myClientGet,
  getClientEntities,
  primaryClientsBroker,
  primaryClientsDealer,
  dealerInvestors,
  investorClientCollection,
  investorMatchPageData,
  investorMatchSubmit,
  newLoanToInvestorsSubmit,
};

export default clientService;
