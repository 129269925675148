import React, { useEffect, useState } from 'react';
import NoResult from '../../Molecules/NoResult/NoResult';
import LoanFilter, {
  loanListGetLoans,
  useDebouncedLoanFilter,
} from '../LoanApplications/Components/LoanFilter';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import LoanList from '../LoanApplications/Components/LoanList';
import useLoanProductGroups from '../../Hooks/useLoanProductGroups';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';

const LoanBook = (props) => {
  const { match: { params: { context } } } = props;
  const [searchStateOptions] = useState([
    { value: 'live,live-subscribed,live-complete', label: 'Live' },
    { value: 'closed_pending,closed', label: 'Closed' },
  ]);

  const {
    pageNumber,
    pageSize,
    searchAmount,
    searchState,
    searchText,
    setContext,
    setSearchAmount,
    setSearchState,
    setSearchText,
    setPageNumber,
    search,
  } = useDebouncedLoanFilter(loanListGetLoans, null, false);
  const { data: searchProductOptions } = useLoanProductGroups(searchState.split(','));


  useEffect(() => setContext(context), [context, setContext]);

  const clearFilter = () => {
    setSearchText('');
    setSearchAmount('');
    setSearchState('live,live-subscribed,live-complete,closed_pending,closed');
  };

  const noResult = (
    <NoResult
      title="No loans found."
      body="Please try again later."
    />
  );

  return (
    <div id="LoanBookPage" className="react-page">
      <HeaderTitle title="Loan book">
        <Paragraph>
          Here is a list of all loans previously funded through our platform.
          <br />
          Click on the loan opportunity to view further information.
        </Paragraph>
      </HeaderTitle>
      <LoanFilter
        context={context}
        clearFilter={clearFilter}
        searchAmount={searchAmount}
        searchProductOptions={searchProductOptions}
        searchState={searchState}
        searchStateOptions={searchStateOptions}
        searchText={searchText}
        setSearchAmount={setSearchAmount}
        setSearchState={setSearchState}
        setSearchText={setSearchText}
        setPageNumber={setPageNumber}
      />
      <LoanList
        context={context}
        noResult={noResult}
        page={pageNumber}
        pageSize={pageSize}
        search={search}
        setPage={setPageNumber}
      />
    </div>
  );
};

export default LoanBook;
