import {
  arrayOf, bool, func, number, oneOf, oneOfType, shape, string,
} from 'prop-types';
import {
  Form, Input, Table as AntdTable,
} from 'antd';
import React, {
  useContext, useEffect, useRef,
  useState,
} from 'react';
import './EditableTable.less';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

EditableRow.propTypes = {
  index: number.isRequired,
};

const EditableCell = ({
  title,
  children,
  dataIndex,
  editable,
  handleSave,
  inputType,
  record,
  required,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const form = useContext(EditableContext);
  const inputRef = useRef(null);

  useEffect(() => {
    // NB: can't use antd numeric field because it doesn't support ref.
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      // Input values with decimals are stored as strings. Need to convert before passing to gql.
      const isNumber = inputRef.current.input.type === 'number';
      const values = await form.validateFields();
      const dataValue = values[dataIndex];
      toggleEdit();
      handleSave({
        ...record,
        ...{ [dataIndex]: isNumber ? parseFloat(dataValue) : dataValue },
      }, dataIndex);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = (
      <div
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
        onKeyDown={toggleEdit}
        role="presentation"
      >
        {children}
      </div>
    );
    const validations = (required && [{
      required: true,
      message: `${title} is required.`,
    }]) || [];

    if (editing) {
      switch (inputType) {
        case 'number':
          childNode = (
            <Form.Item
              name={dataIndex}
              rules={validations}
            >
              <Input ref={inputRef} type="number" onPressEnter={save} onBlur={save} />
            </Form.Item>
          );
          break;
        case 'date':
          childNode = (
            <Form.Item
              name={dataIndex}
              rules={validations}
            >
              <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="YYYY-MM-DD" />
            </Form.Item>
          );
          break;
        default:
          childNode = (
            <Form.Item
              name={dataIndex}
              rules={validations}
            >
              <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
          );
          break;
      }
    }
  }
  return <td {...restProps}>{childNode}</td>;
};

EditableCell.propTypes = {
  children: arrayOf(oneOfType([string, number, shape({})])),
  dataIndex: string.isRequired,
  editable: bool,
  handleSave: func,
  inputType: oneOf(['number', 'string', 'date']),
  record: shape({}),
  required: bool,
  title: string,
};

EditableCell.defaultProps = {
  children: [],
  editable: false,
  handleSave: () => {},
  inputType: 'string',
  record: null,
  required: false,
  title: '',
};

const EditableTable = (args) => {
  const {
    className,
    columns: inputColumns,
    dataSource: inputSource,
    handleSave: inputHandleSave,
    type,
  } = args;

  const [dataSource, setDataSource] = useState(inputSource);

  const handleSave = (row, dataIndex) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.uuid === item.uuid);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    inputHandleSave(row, dataIndex);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = inputColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        editable: col.editable,
        inputType: col.inputType,
        required: col.required,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <AntdTable
        {...args}
        className={`theme-table theme-table-${type} ${className || ''}`}
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

EditableTable.propTypes = {
  bordered: bool,
  columns: arrayOf(shape({
    title: string,
    dataIndex: oneOfType([string, arrayOf(string)]),
  })),
  dataSource: arrayOf(shape({})),
  defaultSortOrder: oneOf(['ascend', 'descend']),
  handleSave: func.isRequired,
  loading: bool,
  pagination: oneOfType([bool, shape({})]),
  sorter: oneOfType([func, bool]),
  type: oneOf(['default', 'inner']),
};

EditableTable.defaultProps = {
  bordered: false,
  columns: [],
  dataSource: [],
  defaultSortOrder: undefined,
  loading: false,
  pagination: true,
  sorter: undefined,
  type: 'default',
};

export default EditableTable;
