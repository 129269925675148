import React from 'react';
import PropTypes from 'prop-types';
import './BadgeGroup.less';

const BadgeGroup = ({
  type, className, direction, children,
}) => (
  <div
    className={className !== undefined ? `theme-badge-group theme-badge-group-${direction} ${className} ${type}` : `theme-badge-group theme-badge-group-${direction} ${type}`}
  >
    { children }
  </div>
);

BadgeGroup.propTypes = {
  direction: PropTypes.oneOf(['left', 'right', 'center', 'bottom-right', 'bottom-left', 'vertical']),
  type: PropTypes.oneOf(['inline-block', 'block', 'flex-row', 'flex-column']),
  children: PropTypes.node,
  className: PropTypes.string,
};

BadgeGroup.defaultProps = {
  type: 'inline-block',
  direction: 'left',
  children: null,
  className: undefined,
};

export default BadgeGroup;
