import React, { useState } from 'react';
import PropTypes from 'prop-types';
import loadMoreItems from '../../../Helpers/LoadMoreList';
import NoResult from '../../../Molecules/NoResult/NoResult';
import LoanPanel from '../../../Components/Common/LoanPanel';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import InfiniteScrollLegacy from '../../../Molecules/InfiniteScrollLegacy';

const LoanList = (props) => {
  const [itemList, setItemList] = useState([]);
  const [itemUuid, setItemUuid] = useState('');

  const {
    context,
    linkPattern,
    noResult,
    page,
    pageSize,
    rateFieldName,
    setPage,
    search,
    search: {
      status,
    },
  } = props;

  let totalResults = 0;
  let items = [];
  if (status === 'success') {
    totalResults = search.result.total;
    items = search.result.results;
  }

  const loadMore = loadMoreItems(itemList, itemUuid, items, page);
  if (loadMore !== null) {
    setItemUuid(loadMore.uuid);
    setItemList(loadMore.list);
  }

  if (itemList.length === 0 && status === 'loading') {
    return (
      <Skeleton loading />
    );
  }

  if (itemList.length === 0) {
    return noResult;
  }

  const loanResults = itemList.map((loan) => {
    const link = linkPattern.replace(':context', context);
    return (
      <LoanPanel
        key={loan.uuid}
        loan={loan}
        linkPrefix={link}
        rateFieldName={rateFieldName}
        showClient
        context={context}
      />
    );
  });

  return (
    <>
      {loanResults}
      { status === 'loading' && (
        <Skeleton loading />
      )}
      { !!totalResults && !!setPage && (
        <InfiniteScrollLegacy
          page={page}
          pageSize={pageSize}
          loading={status === 'loading'}
          totalResults={totalResults}
          onClick={() => setPage(page + 1)}
        />
      )}
    </>
  );
};

LoanList.propTypes = {
  context: PropTypes.string.isRequired,
  linkPattern: PropTypes.string,
  noResult: PropTypes.objectOf(NoResult).isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rateFieldName: PropTypes.string,
  setPage: PropTypes.func,
  search: PropTypes.shape({
    status: PropTypes.string.isRequired,
    result: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

LoanList.defaultProps = {
  page: 0,
  pageSize: 25,
  rateFieldName: 'fieldInvestmentRate',
  linkPattern: '/app/:context/application',
  setPage: null,
};

export default LoanList;
