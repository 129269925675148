import React from 'react';
import Meta from '../../../Atoms/Feedback/Meta/Meta';
import { capitalize, getContextLoanState } from '../../../Helpers/Helpers';
import Badge from '../../../Atoms/DataDisplay/Badge/Badge';
import BadgeGroup from '../../../Atoms/DataDisplay/BadgeGroup/BadgeGroup';
import Title from '../../../Atoms/Typography/Title/Title';
import './LoanHeader.less';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import { formatCurrency } from '../../../Hooks/useNumberFormatter';

const LoanHeader = (props) => {
  const {
    referenceId,
    loanState,
    name,
    loanId,
    loanAmount,
    loanHeaderImage,
    metaTitle,
    skeleton,
    context,
  } = props;

  if (skeleton) {
    return <Skeleton />;
  }

  const contextLoanState = getContextLoanState(context, loanState);

  return (
    <div className={`theme-loan-header loan-header__${contextLoanState.toLowerCase().replace(/( |_)/g, '-')}`}>
      <Meta title={metaTitle} />
      <div className="theme-loan-header-loan-state">{capitalize(contextLoanState.replace(/(-|_)/g, ' '))}</div>
      <div className="theme-loan-header-contents-wrapper">
        {
          !!loanHeaderImage && (
            <div
              className="theme-loan-header-banner-image"
              style={{
                backgroundImage: `url(${loanHeaderImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          )
        }
        <div className="theme-loan-header-contents">
          <BadgeGroup>
            {referenceId && (
              <Badge>
                Your ref:
                {' '}
                {referenceId}
              </Badge>
            )}
            <Badge>
              Loan:
              {' '}
              {loanId}
            </Badge>
          </BadgeGroup>
          <Title level={3}>{name}</Title>
          <Title level={5} className="theme-currency-wrapper">
            {formatCurrency(loanAmount)}
          </Title>
        </div>
      </div>
    </div>
  );
};

export default LoanHeader;
