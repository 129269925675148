import React from 'react';
import { func, shape, string } from 'prop-types';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import Button from '../../../../Atoms/Forms/Button/Button';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import EditClientDetailsDrawer from './EditClientDetailsDrawer';
import useNestedDrawer from '../../../../Hooks/useNestedDrawer';
import EditIdentityDetailsDrawer from './EditIdentityDetailsDrawer';
import EditBankDetailsDrawer from './EditBankDetailsDrawer';

const IndividualClientEditDrawer = (props) => {
  const { client, investor, onSuccess } = props;
  const { context } = useParams();
  const action = context === 'investor' && client?.acting?.length ? 'View' : 'Edit';
  const isIndividual = (client?.type === 'individual');
  const {
    visible, setVisible, success, width,
  } = useNestedDrawer({ onSuccess });

  return (
    <Row className="drawer-button">
      <Button type="primary" onClick={() => setVisible(true)}>
        {action}
      </Button>

      <Drawer
        title={action}
        closable
        width={width}
        onClose={() => setVisible(false)}
        open={visible}
      >
        {isIndividual && (<EditClientDetailsDrawer client={client} onSuccess={success} />)}
        {(isIndividual && !client?.fieldIdentification?.verified) && (<EditIdentityDetailsDrawer clientUuid={client?.uuid} parents={[]} onSuccess={success} />)}
        {(investor && !!client?.acting.length) && (<EditBankDetailsDrawer actingClientUuid={investor?.uuid} onSuccess={success} />)}
      </Drawer>
    </Row>
  );
};

IndividualClientEditDrawer.propTypes = {
  client: shape({
    uuid: string.isRequired,
    address: shape({
      address_line1: string,
      address_line2: string,
      dependent_locality: string,
      locality: string,
      administrative_area: string,
      postal_code: string,
      country_code: string,
    }),
  }).isRequired,
  investor: shape({
    uuid: string,
  }),
  onSuccess: func.isRequired,
};

IndividualClientEditDrawer.defaultProps = {
  investor: null,
};

export default IndividualClientEditDrawer;
