import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import axios from '../../../Vendor/Axios';
import Paragraph from '../../Typography/Paragraph/Paragraph';

const Pdf = ({ path }) => {
  const [blobUrl, setBlobUrl] = useState('');

  const requestPath = path ?? `/api${window.location.pathname}`;

  useEffect(() => {
    axios.get(requestPath, { responseType: 'blob' })
      .then(response => response.data && setBlobUrl(window.URL.createObjectURL(response.data)));
  }, [path]);

  return blobUrl && (
    <object data={blobUrl} style={{ width: '100vw', height: '100vh' }}>
      <Paragraph>
        Alternative text - include a link
        {' '}
        <a href={blobUrl}>to the PDF!</a>
      </Paragraph>
    </object>
  );
};

Pdf.propTypes = {
  path: string,
};

Pdf.defaultProps = {
  path: null,
};

export default Pdf;
