import apiGraphQlService from '../Helpers/ApiGraphQLService';
import { fundFragmentForPanel, investmentFragmentForPanel } from './Accounts.service';

function managedFundsQuery(state = '') {
  const variables = {};
  if (state.length > 0) {
    variables.state = state;
  }
  const query = `
    query fundsCollection(
      $state:String
    ) {
      funds(
        state: $state
      ) {
        total
        results {
          ${fundFragmentForPanel}
        }
      }
    }`;
  return apiGraphQlService.persistedQuery(query, variables);
}

function managedFundQuery(uuid) {
  const query = `
    query fundQuery(
      $uuid: String
    ) {
      fund(uuid: $uuid) {
        uuid
        label
        fieldLmsPoolId
        fieldTargetRate
        fieldFundState
        fundInvestments(states: ["live","live-subscribed"]) {
          ${investmentFragmentForPanel}
        }
        clientInvestments {
          ${investmentFragmentForPanel}
        }
      }
    }`;
  return apiGraphQlService.persistedQuery(query, { uuid });
}

const fundService = {
  managedFundsQuery,
  managedFundQuery,
};

export default fundService;
