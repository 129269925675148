import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { unstrailedUrl } from '../../../Helpers/Helpers';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Text from '../../../Atoms/Typography/Text/Text';
import Button from '../../../Atoms/Forms/Button/Button';

const Investment = (props) => {
  const {
    name, amount, currentUrl, clientUuid, canAddAdditionalFunds,
  } = props;


  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Text type="bold">
          {name}
          {' '}
        </Text>
        <Text>{formatCurrencyFull(amount)}</Text>
      </Col>
      {canAddAdditionalFunds && (
        <Col>
          <Button
            type="primary"
            size-small
            href={`${unstrailedUrl(currentUrl)}/fund/${clientUuid}`}
            icon={<FontAwesomeIcon icon={faPlusCircle} />}
          >
            Increase investment
          </Button>
        </Col>
      ) }
    </Row>
  );
};

Investment.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default Investment;
