import React, { useState } from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';
import {
  Form, Row, Col,
} from 'antd';
import { useMutation } from '@apollo/client';
import NumberFormat from 'react-number-format';
import InvestorSelect from '../../Molecules/LoanFunding/InvestorSelect';
import ContextInvestmentInfo from '../../Molecules/LoanFunding/ContextInvestmentInfo';
import Button from '../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import ClientTotalCommitment from '../../Molecules/LoanFunding/ClientTotalCommitment';
import InvestorSearch from '../../Molecules/LoanFunding/InvestorSearch';
import InvestmentTerms from '../../Molecules/LoanFunding/InvestmentTerms';
import Title from '../../Atoms/Typography/Title/Title';
import Card from '../../Molecules/Cards/Card';
import RemainingInvestment from '../../Molecules/DataDisplay/Fields/RemainingInvestment';
import './InvestPanel.less';
import { CREATE_INVESTMENT } from '../../GraphQL/Mutations/Loan';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';
import Radio from '../../Atoms/Forms/Radio/Radio';
import RadioGroup from '../../Atoms/Forms/RadioGroup/RadioGroup';
import Modal from '../../Atoms/Feedback/Modal/Modal';
import InvestmentSuccessModal from '../../Molecules/LoanFunding/InvestmentSuccessModal';
import { dealer } from '../../Hooks/useLocalStorage';

const InvestPanel = ({
  loan,
  investors,
  investments,
  match,
}) => {
  const {
    fundingAvailable, fieldState,
  } = loan;

  const fundingDefaults = {
    zagga: { investmentOption: 'full', amount: fundingAvailable || 0 },
    apm: { investmentOption: 'full', amount: fundingAvailable || 0 },
    znz: { investmentOption: 'partial', amount: 0 },
  };

  const { params: { clientUuid, context, loanUuid } } = match;
  const [form] = Form.useForm();

  const [CreateInvestment, { loading, data }] = useMutation(CREATE_INVESTMENT);
  // Infuriating: NumberFormat sets the form field with the formatted value, not the raw numeric value.
  // Get around this by keeping the value in state, and using that instead.
  const [newInvestmentAmount, setNewInvestmentAmount] = useState(fundingDefaults[dealer()].amount);
  const [investorUuid, setInvestorUuid] = useState(clientUuid || null);
  const [allTermsChecked, setAllTermsChecked] = useState(false);
  const [investmentError, setInvestmentError] = useState(null);

  const handleSubmit = (input) => {
    const { investmentOption } = input;
    CreateInvestment({
      variables: {
        loanUuid, investorUuid, amount: newInvestmentAmount, investmentOption,
      },
    }).catch((e) => {
      const errors = e?.networkError?.result?.errors.map(error => error.message);
      if (errors) {
        setInvestmentError(errors.reduce((err, carry) => `${carry}${err}<br />`));
      }
    });
  };

  const investmentCreate = data?.investmentCreate || null;

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      initialValues={fundingDefaults[dealer()]}
    >
      <div className="theme-invest-panel">
        <Card
          title={<Title level={4}>Invest in this loan</Title>}
        >
          <Row>
            <Col span={12}>
              {
                !clientUuid && context !== 'dealer'
                && <InvestorSelect selectedInvestor={investorUuid} setSelectedInvestor={setInvestorUuid} />
              }
              {
                !clientUuid && context === 'dealer'
                && <InvestorSearch setSelectedInvestor={setInvestorUuid} />
              }
              {
                clientUuid
                && <ContextInvestmentInfo {...{ investments, clientUuid }} />
              }
            </Col>
            <Col span={12}>
              <FormItem
                name="investmentOption"
                label="Amount to invest"
              >
                <RadioGroup name="investmentOption" inline>
                  <Radio key="investmentOptionFull" value="full" inline>Fund remaining amount</Radio>
                  <Radio key="investmentOptionPartial" value="partial" inline>Partial</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem
                noStyle
                shouldUpdate={(prev, current) => prev?.investmentOption !== current.investmentOption}
              >
                {({ getFieldValue, setFieldValue }) => {
                  if (getFieldValue('investmentOption') === 'full') {
                    setFieldValue('amount', fundingAvailable);
                  }
                  return (
                    <div className="theme-input-number ant-input-number-affix-wrapper">
                      <span className="ant-input-number-prefix">$</span>
                      <div className="ant-input-number-input-wrap">
                        <FormItem
                          name="amount"
                          noStyle
                        >
                          <NumberFormat
                            className="ant-input-number-input"
                            placeholder="Enter additional amount"
                            thousandSeparator
                            readOnly={getFieldValue('investmentOption') !== 'partial'}
                            onValueChange={e => setNewInvestmentAmount(e.floatValue)}
                          />
                        </FormItem>
                      </div>
                    </div>
                  );
                }}
              </FormItem>
            </Col>
            <Col span={12}>
              <RemainingInvestment
                available={fundingAvailable}
                commitment={newInvestmentAmount}
              />
            </Col>
            <ClientTotalCommitment
              {...{ investments, investors, clientUuid }}
              newInvestmentAmount={newInvestmentAmount}
            />
          </Row>
          {
            context === 'investor'
            && <InvestmentTerms allCheckedCallback={value => setAllTermsChecked(value)} loanState={fieldState} />
          }
        </Card>
        <ButtonGroup direction="bottom-right" className="set-3-style">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!allTermsChecked && (context === 'investor')}
          >
            Invest
          </Button>
          <Button ghost href={`/app/${context}/application/${loanUuid}`} loading={loading}>
            Cancel
          </Button>
        </ButtonGroup>
        {
          investmentCreate?.success && (
            <InvestmentSuccessModal loanUuid={loanUuid} context={context} agreementToken={investmentCreate?.agreementToken} />
          )
        }
        {
          investmentError && (
            <Modal
              cancelText=""
              open
              title="Failed to make commitment"
              onOk={() => {
                window.location.href = `/app/${context}/application/${loanUuid}`;
              }}
            >
              {investmentError}
            </Modal>
          )
        }
      </div>
    </Form>
  );
};

InvestPanel.propTypes = {
  loan: shape({ fundingAvailable: number, fieldState: string }).isRequired,
  investors: arrayOf(shape({})),
  investments: arrayOf(shape({})),
  match: shape({ params: shape({ clientUuid: string, context: string, loanUuid: string }) }).isRequired,
};

InvestPanel.defaultProps = {
  investors: [],
  investments: [],
};

export default InvestPanel;
