import React from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import history from './Helpers/History';
import './App.less';
import Page from './Components/Layout/Page';
import LangProvider from './Context/Lang/LangProvider';
import ApolloProvider from './Context/Apollo/ApolloProvider';
import ReduxProvider from './Context/ReduxProvider/ReduxProvider';
import { dealer } from './Hooks/useLocalStorage';
import Layout from './Templates/Layouts/Layout';

const setDefaults = () => {
  if (!dealer()) {
    localStorage.setItem('dealer', process.env.REACT_APP_X_DEALER);
  }
};

const GtmWrapper = ({ children }) => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (gtmId === undefined) {
    return (<>{children}</>);
  }

  return (
    <GTMProvider state={({ id: gtmId })}>
      {children}
    </GTMProvider>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    setDefaults();
    const { sentry } = props;
    Sentry.init({ dsn: sentry });
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      apiUrl,
      apiFallbackUrl,
      apiFallbackCacheTime,
    } = nextProps;

    if (window.location.hostname !== 'localhost') {
      localStorage.setItem('fintech_api_url', apiUrl);
      localStorage.setItem('fintech_api_fallback_url', apiFallbackUrl);
      localStorage.setItem('fintech_api_fallback_cache_time', apiFallbackCacheTime);
    }

    return null;
  }

  render() {
    return (
    <GtmWrapper>
      <ReduxProvider>
        <ApolloProvider>
          <Router history={history}>
            <Layout>
              <LangProvider>
                <Page />
              </LangProvider>
            </Layout>
          </Router>
        </ApolloProvider>
      </ReduxProvider>
    </GtmWrapper>
    );
  }
}

export default App;
