import { node, shape, string } from 'prop-types';
import React from 'react';
import { haveEntityPermission as havePermissionHelper } from '../../Helpers/Helpers';

const HasEntityPermission = ({ name, entity, children }) => {
  if (havePermissionHelper(entity, name)) {
    return <>{children}</>;
  }
  return null;
};

HasEntityPermission.propTypes = {
  name: string.isRequired,
  entity: shape({}).isRequired,
  children: node,
};

HasEntityPermission.defaultProps = {
  children: undefined,
};
export default HasEntityPermission;
