import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faImage } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import Panel from '../../Atoms/Layout/Panel/Panel';
import LoanState from '../../Atoms/DataDisplay/LoanState/LoanState';
import BadgeGroup from '../../Atoms/DataDisplay/BadgeGroup/BadgeGroup';
import Badge from '../../Atoms/DataDisplay/Badge/Badge';
import Title from '../../Atoms/Typography/Title/Title';
import Text from '../../Atoms/Typography/Text/Text';
import { formatCurrency, formatPercentage } from '../../Hooks/useNumberFormatter';

const AccountPanel = (props) => {
  const {
    className,
    context,
    clientNameBadge,
    link,
    loanAmount,
    loanId,
    loanState,
    loanImage,
    title,
    subtitle,
    investmentId,
    rate,
  } = props;

  const thumbnail = loanImage?.propertyThumbnail;

  return (
    <Fade>
      <Panel
        className={['loan-panel', className].join(' ')}
        link={link}
        type="background noPadding"
        icon={<FontAwesomeIcon icon={faChevronRight} size="2x" />}
      >
        <div
          className="group-thumbnail"
          style={{
            backgroundImage: `url('${thumbnail}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          { !thumbnail && <FontAwesomeIcon icon={faImage} size="2x" /> }
          { loanState && <LoanState {...{ context, loanState }} /> }
        </div>
        <div className="group-content">
          <div className="group-left">
            <div className="loan-info">
              <BadgeGroup type="block">
                {
                  investmentId && (
                    <Badge>
                      Your ref:
                      {' '}
                      {investmentId}
                    </Badge>
                  )
                }
                {
                  loanId && (
                    <>
                      <Badge>
                        Loan:
                        {' '}
                        {loanId}
                      </Badge>
                    </>
                  )
                }
                {
                  clientNameBadge && (
                    <Badge>
                      {clientNameBadge}
                    </Badge>
                  )
                }
              </BadgeGroup>
              {
                title && (
                  <Title level={4} className="name">{title}</Title>
                )
              }
              <Text className="loan-type">
                {subtitle}
                {!!subtitle && (<>&nbsp;&nbsp;</>)}
                {formatCurrency(loanAmount)}
              </Text>
            </div>
          </div>
          <div className="group-right">
            {
              rate && (
                <Title level={2} className="returns-rate">
                  {formatPercentage(rate)}
                </Title>
              )
            }
          </div>
        </div>
      </Panel>

    </Fade>
  );
};

AccountPanel.propTypes = {
  className: PropTypes.string,
  context: PropTypes.string,
  clientNameBadge: PropTypes.string,
  link: PropTypes.string,
  loanAmount: PropTypes.number,
  loanId: PropTypes.string,
  loanState: PropTypes.string,
  loanImage: PropTypes.shape({ propertyThumbnail: PropTypes.string }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  investmentId: PropTypes.string,
  rate: PropTypes.number,
};

AccountPanel.defaultProps = {
  className: null,
  context: null,
  clientNameBadge: null,
  link: '',
  loanAmount: null,
  loanId: null,
  loanState: null,
  loanImage: null,
  subtitle: null,
  investmentId: null,
  rate: null,
  title: null,
};

export default AccountPanel;
