import React from 'react';
import { func, shape } from 'prop-types';
import { Col, Form, Row } from 'antd';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import LoanApplicationActions from '../../../../Molecules/Forms/Actions/LoanApplicationActions';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import PrimarySecurity from '../../../../Molecules/Forms/PrimarySecurity/PrimarySecurity';
import SecondarySecurity from '../../../../Molecules/Forms/SecondarySecurity/SecondarySecurity';
import StepInfo from '../../../../Molecules/Forms/StepInfo/StepInfo';
import { getStep } from '../../../../Store/loanApplicationSlice';

const LoanSecurityDetailsStep = (props) => {
  const { initialValues, onFinish, onClickBack } = props;
  const step = useSelector(getStep);
  const [form] = Form.useForm();

  return (
    <>
      <HeaderTitle title="Security details" />
      <Row>
        <Col className="gutter-row" span={12}>
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            name="loan-security-details-form"
            onFinish={onFinish}
          >
            <StepInfo id={uuid()} stepKey={step} />
            <FormItem hidden noStyle name="errors" />
            <PrimarySecurity form={form} />
            <SecondarySecurity form={form} initialValues={initialValues} />
            <LoanApplicationActions onClickBack={onClickBack} form={form} />
          </Form>
        </Col>
      </Row>
    </>
  );
};

LoanSecurityDetailsStep.propTypes = {
  initialValues: shape({}),
  onClickBack: func,
  onFinish: func,
};

LoanSecurityDetailsStep.defaultProps = {
  initialValues: {},
  onClickBack: () => {},
  onFinish: () => {},
};


export default LoanSecurityDetailsStep;
