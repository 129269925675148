import { gql } from '@apollo/client';

export const REFERRALS = gql`
  query referrals(
      $uuid: String,
      $offset: Int,
      $limit: Int,
      $sort: ReferralsSortOrder
      $sortDirection: SortDirection
  ) {
    referrals(
        uuid: $uuid,
        offset: $offset,
        limit: $limit,
        sort: $sort
        sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        fieldFirstName
        fieldLastName
        fieldEmail
        fieldMobile
        fieldWechatAccount
        fieldAuthorityToAct
        fieldReferredUser {
          uuid
          email
          roles
          primary {
            fieldLmsClientId
          }
        }
        fieldLanguage
        created
        owner {
          uuid
          email
        }
      }
    }
  }
`;

export const Referral = {
  REFERRALS,
};

export default Referral;
