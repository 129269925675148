import React from 'react';
import { Form } from 'antd';
import {
  arrayOf, bool, node, number, oneOf, oneOfType, string,
} from 'prop-types';
import './FormItem.less';

const FormItem = (args) => {
  const { className } = args;
  return <Form.Item {...args} className={className !== undefined ? `theme-form-item ${className}` : 'theme-form-item'} />;
};

FormItem.propTypes = {
  colon: bool,
  className: string,
  extra: node,
  hasFeedback: bool,
  help: node,
  hidden: bool,
  htmlFor: string,
  label: node,
  labelAlign: oneOf(['left', 'right']),
  name: oneOfType([
    string,
    number,
    arrayOf(string),
    arrayOf(number),
  ]),
  rule: arrayOf([]),
  validateStatus: string,
};

FormItem.defaultProps = {
  colon: true,
  className: undefined,
  extra: undefined,
  hasFeedback: false,
  help: undefined,
  hidden: false,
  htmlFor: undefined,
  label: undefined,
  labelAlign: 'right',
  name: undefined,
  rule: undefined,
  validateStatus: undefined,
};

export default FormItem;
