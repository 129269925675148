import React, { useState } from 'react';
import {
  shape, number, string, func,
} from 'prop-types';
import { Form, Row, Col } from 'antd';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { CheckCircleOutlined } from '@ant-design/icons';
import { UPDATE_REDUCTION } from '../../../../../GraphQL/Mutations/Reduction';
import Alert from '../../../../../Atoms/Feedback/Alert/Alert';
import Button from '../../../../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Popconfirm from '../../../../../Atoms/Forms/Popconfirm/Popconfirm';
import './CloseReductionForm.less';
import { getGraphQlErrorMessage } from '../../../../../Helpers/Error';

const CloseReductionForm = ({
  reduction: {
    uuid,
    fieldExpiry,
    entityPermissions,
    fieldAction,
  },
  refetch,
}) => {
  const [form] = Form.useForm();
  const [CancelNow, { loading: cancelNowLoading }] = useMutation(UPDATE_REDUCTION);
  const [CancelAtExpiry, { loading: cancelExpiryLoading }] = useMutation(UPDATE_REDUCTION);
  const [AcceptNow, { loading: acceptNowLoading }] = useMutation(UPDATE_REDUCTION);
  const [AcceptAtExpiry, { loading: acceptAtExpiryLoading }] = useMutation(UPDATE_REDUCTION);
  const [ExtendOffer, { loading: extendOfferLoading }] = useMutation(UPDATE_REDUCTION);
  const [message, setMessage] = useState(false);
  const expires = moment.unix(fieldExpiry);

  const { permitted: canBeExtended } = entityPermissions.find(({ label }) => label === 'Can be extended') || { permitted: false };

  const onCancelNow = () => {
    CancelNow({
      variables: {
        uuid, fieldStatus: 'cancelled', fieldAction: 'cancel_now',
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const onCancelAtExpiry = () => {
    CancelAtExpiry({
      variables: {
        uuid, fieldAction: 'cancel_on_expiry',
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const onAcceptNow = () => {
    AcceptNow({
      variables: {
        uuid, fieldStatus: 'complete', fieldAction: 'accept_now',
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const onAcceptAtExpiry = () => {
    AcceptAtExpiry({
      variables: {
        uuid, fieldAction: 'accept_on_expiry',
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const onExtend = () => {
    ExtendOffer({
      variables: {
        uuid, fieldExpiry: expires.add(7, 'days'),
      },
    })
      .then(() => {
        setMessage(false);
        refetch();
      })
      .catch(error => console.log(error));
  };

  return (
    <div className="theme-close-reduction-form">
      <Form
        form={form}
      >
        { message && <Alert message={message} type="error" /> }
        <Row>
          <Col>
            {canBeExtended && (
              <Popconfirm
                title="Please confirm you wish to extend your marketplace offer?"
                okText="Accept"
                cancelText="Cancel"
                onConfirm={onExtend}
              >
                <Button type="tertiary" htmlType="submit" loading={extendOfferLoading}>
                  {extendOfferLoading ? 'Please wait' : 'Extend offering'}
                </Button>
              </Popconfirm>
            )}
          </Col>
        </Row>


        <ButtonGroup direction="space-between">
          <div className="theme-button-group-inner left">
            <Popconfirm
              title="Please confirm you wish to cancel your marketplace offer?"
              okText="Accept"
              cancelText="Cancel"
              onConfirm={onCancelNow}
            >
              <Button ghost htmlType="submit" loading={cancelNowLoading}>
                {cancelNowLoading ? 'Cancelling offer' : 'Cancel offering now'}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={`Please confirm you wish to cancel your marketplace offering at the end of ${expires.format('DD/MM/YYYY')}`}
              okText="Accept"
              cancelText="Cancel"
              onConfirm={onCancelAtExpiry}
            >
              <Button ghost htmlType="submit" loading={cancelExpiryLoading} icon={fieldAction === 'cancel_on_expiry' && (<CheckCircleOutlined />)}>
                {cancelExpiryLoading ? 'Please wait' : 'Cancel offering at expiry'}
              </Button>
            </Popconfirm>
          </div>

          <div className="theme-button-group-inner right">
            <Popconfirm
              title="Please confirm you wish to accept the current funded amount?"
              okText="Accept"
              cancelText="Cancel"
              onConfirm={onAcceptNow}
            >
              <Button type="secondary" htmlType="submit" loading={acceptNowLoading}>
                {acceptNowLoading ? 'Please wait' : 'Accept funded amount now'}
              </Button>
            </Popconfirm>

            <Popconfirm
              title={`Please confirm you wish to accept the funded amount at the end of ${expires.format('DD/MM/YYYY')}`}
              okText="Accept"
              cancelText="Cancel"
              onConfirm={onAcceptAtExpiry}
            >
              <Button type="primary" htmlType="submit" loading={acceptAtExpiryLoading} icon={fieldAction === 'accept_on_expiry' && (<CheckCircleOutlined />)}>
                {acceptAtExpiryLoading ? 'Please wait' : 'Accept offering at expiry'}
              </Button>
            </Popconfirm>
          </div>
        </ButtonGroup>

      </Form>
    </div>
  );
};

CloseReductionForm.propTypes = {
  reduction: shape({
    uuid: string,
    fieldStatus: 'pending',
    created: number,
    changed: number,
    fieldExpiry: number,
  }).isRequired,
  refetch: func,
};

CloseReductionForm.defaultProps = {
  refetch: () => {},
};


export default CloseReductionForm;
