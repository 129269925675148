import React, { useState, useEffect } from 'react';
import fundService from '../../Services/ManagedFunds.service';
import ManagedFundHeader from './Components/ManagedFundHeader';
import NoResult from '../../Molecules/NoResult/NoResult';
import InvestmentList from '../Investments/Components/InvestmentList';
import LoanPanel from '../../Components/Common/LoanPanel';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';

const ManagedFund = (props) => {
  const [fund, setFund] = useState(null);

  const { match: { params: { fundUuid, context } } } = props;

  let { match: { params: { displayAccounts } } } = props;
  if (displayAccounts === null || displayAccounts === undefined) {
    displayAccounts = 'investments';
  }

  useEffect(() => {
    fundService.managedFundQuery(fundUuid).then((response) => {
      const fundData = response?.data?.data?.fund;
      if (fundData !== null) {
        setFund(fundData);
      }
    });
  }, [fundUuid]);

  if (fund === null) {
    return (
      <div className="page-inner">
        <ManagedFundHeader skeleton="true" />
        <Skeleton loading />
      </div>
    );
  }

  const {
    fieldLmsPoolId,
    fieldFundState,
    clientInvestments,
    fundInvestments,
    label,
    uuid,
  } = fund;

  let loanList = <NoResult title="No loans found." body="" />;
  if (Array.isArray(fundInvestments) && fundInvestments.length > 0) {
    loanList = fundInvestments.map(investment => (
      <LoanPanel
        key={investment.uuid}
        investmentId={investment.fieldLmsAccountId}
        loan={investment.fieldLoan}
        linkPrefix="/app/dealer/application"
        context={context}
      />
    ));
  }

  return (
    <div className="theme-managed-fund page-inner">
      <ManagedFundHeader
        metaTitle={label}
        name={label}
        poolId={fieldLmsPoolId}
        state={fieldFundState}
      />
      <ul className="tabs-container">
        <li className="tab"><a href={`/app/${context}/investment-funds/${uuid}/investments`} className={displayAccounts === 'investments' ? 'active' : ''}>investments</a></li>
        <li className="tab"><a href={`/app/${context}/investment-funds/${uuid}/loans`} className={displayAccounts === 'loans' ? 'active' : ''}>currently funded loans</a></li>
      </ul>
      { displayAccounts === 'loans' && loanList }
      { displayAccounts === 'investments' && (
          <InvestmentList
            page="0"
            items={clientInvestments}
            context={context}
            showFund={false}
            status="success"
          />
      )}
    </div>
  );
};

export default ManagedFund;
