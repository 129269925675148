import React, { useEffect, useState, useContext } from 'react';
import {
  string, shape, bool,
} from 'prop-types';
import { Form } from 'antd';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Password from '../../../Atoms/Forms/Password/Password';
import './SetPasswordForm.less';
import { USER_UPDATE_PASSWORD } from '../../../GraphQL/Mutations/User';
import Result from '../../../Atoms/Feedback/Result/Result';
import Divider from '../../../Atoms/Layout/Divider/Divider';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import LangContext from '../../../Context/Lang/LangContext';
import Title from '../../../Atoms/Typography/Title/Title';
import Text from '../../../Atoms/Typography/Text/Text';
import Image from '../../../Atoms/Media/Image/Image';

const SetPasswordForm = ({
  user, tempPassword, src, alt, preview,
}) => {
  const { t } = useContext(LangContext);
  const [form] = Form.useForm();
  const [userUpdatePassword, { loading }] = useMutation(USER_UPDATE_PASSWORD);
  const [theMessage, setTheMessage] = useState(false);
  const history = useHistory();

  const onFinish = (input) => {
    const { password } = input;
    userUpdatePassword({
      variables: {
        input: { currentPassword: tempPassword, newPassword: password },
      },
    })
      // @todo Important! As I have changed the old redirection code.
      // .then(redirectToDestination())
      .then(history.push('/app'))
      .catch(error => setTheMessage(getGraphQlErrorMessage(error)));
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  if (theMessage) {
    return (
      <Result
        status="error"
        subTitle={(
          <>
            <Image alt={alt} src={src} preview={preview} />
            <Title level={1}>Activation link expired</Title>
            <Text>Your one time activation link has expired.</Text>
            <Button block href="/app/forgot-password" type="primary">
              Request new activation link
            </Button>
            <Divider dashed />
            <Text>Have an account already?</Text>
            <Button block href="/" ghost type="primary">
              Go to the Login page
            </Button>
          </>
        )}
      />
    );
  }

  return (
    <div className="theme-set-password-form">
      <Form form={form} onFinish={onFinish}>
        <FormItem
          name="password"
          rules={[
            { required: true, message: t('validation.required', { attribute: 'field' }) },
            { min: 8, message: 'Password needs to be at least 8 characters!' },
          ]}
        >
          <Password placeholder="Password *" />
        </FormItem>
        <FormItem
          name="password_confirmation"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: t('validation.required'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Password placeholder="Confirm password *" />
        </FormItem>
        <Button block type="primary" htmlType="submit" loading={loading}>
          {loading ? 'Saving' : 'Save password'}
        </Button>
      </Form>
    </div>
  );
};
SetPasswordForm.propTypes = {
  user: shape({
    email: string,
  }).isRequired,
  tempPassword: string.isRequired,
  src: string.isRequired,
  alt: string,
  preview: bool,
};
SetPasswordForm.defaultProps = {
  alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
  preview: false,
};
export default SetPasswordForm;
