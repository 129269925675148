import React from 'react';
import {
  string, node, arrayOf, bool, oneOf, shape, func,
} from 'prop-types';
import { Card as AntdCard } from 'antd';
import './Card.less';

const Card = (args) => {
  const { className } = args;
  return (
    <AntdCard
      {...args}
      className={className !== undefined ? `theme-card ${className}` : 'theme-card'}
      style={{ display: 'flex', flexDirection: 'column' }}
    />
  );
};

export default Card;

Card.propTypes = {
  actions: arrayOf(node),
  activeTabKey: string,
  defaultActiveTabKey: string,
  tabBarExtraContent: node,
  tabList: arrayOf(shape({
    key: string,
    tab: node,
  })),
  onTabChange: func,
  bordered: bool,
  cover: node,
  extra: node,
  hoverable: bool,
  loading: bool,
  size: oneOf(['default', 'small']),
  title: node,
  type: string,
};

Card.defaultProps = {
  actions: [],
  activeTabKey: '',
  defaultActiveTabKey: '',
  tabBarExtraContent: '',
  tabList: [],
  onTabChange: () => {},
  bordered: false,
  cover: '',
  extra: '',
  hoverable: false,
  loading: false,
  size: 'default',
  title: '',
  type: '',
};
