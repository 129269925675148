import React from 'react';
import PropTypes from 'prop-types';
import './LoanState.less';

const LoanState = ({ loanState }) => (
  <div
    className={`theme-loan-state loan--state__${loanState.toLowerCase().replace(/ |_/g, '-')}`}
  >
    {loanState.replace(/_|-/g, ' ')}
  </div>
);

LoanState.propTypes = {
  loanState: PropTypes.string.isRequired,
};

export default LoanState;
