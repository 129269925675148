import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Col, Row } from 'antd';
import Title from '../../Atoms/Typography/Title/Title';
import Text from '../../Atoms/Typography/Text/Text';
import Currency from '../../Atoms/DataDisplay/Field/Currency';

const ContextInvestmentInfo = ({ investments, clientUuid }) => {
  const investment = investments.filter(inv => inv.fieldClient.uuid === clientUuid)?.pop();
  return (
    <Row gutter={[16, 16]} className="theme-context-investment-info">
      <Col span={12}>
        <Title level={5} className="theme-field-title">Investor</Title>
        <Text>{investment?.fieldClient?.name}</Text>
      </Col>
      <Col span={12}>
        <Title level={5} className="theme-field-title">Invested amount</Title>
        <Currency value={investment?.fieldAmount} decimals="2" />
      </Col>
    </Row>
  );
};

ContextInvestmentInfo.propTypes = {
  clientUuid: string.isRequired,
  investments: arrayOf(shape({})),
};

ContextInvestmentInfo.defaultProps = {
  investments: [],
};

export default ContextInvestmentInfo;
