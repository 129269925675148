import React from 'react';
import './Text.less';
import { Typography as AntdTypography } from 'antd';
import {
  bool, oneOf, func,
} from 'prop-types';

const Text = (args) => {
  const { className } = args;
  return <AntdTypography.Text {...args} className={className !== undefined ? `theme-typography-text ${className}` : 'theme-typography-text'} />;
};

Text.propTypes = {
  code: bool,
  copyable: bool,
  delete: bool,
  disabled: bool,
  editable: bool,
  ellipsis: bool,
  keyboard: bool,
  mark: bool,
  onClick: func,
  strong: bool,
  italic: bool,
  type: oneOf(['success', 'secondary', 'warning', 'danger', 'comment', 'section-comment', 'small', 'bold', 'description-item', 'label']),
  underline: bool,
};

Text.defaultProps = {
  code: false,
  copyable: false,
  delete: false,
  disabled: false,
  editable: false,
  ellipsis: false,
  keyboard: false,
  mark: false,
  onClick: () => {},
  strong: false,
  italic: false,
  type: null,
  underline: false,
};

export default Text;
