import React from 'react';
import { number, shape, string } from 'prop-types';
import { getExtension } from '../../../Helpers/Helpers';
import { DocumentSelectIcon } from '../../../Resources/Icons';
import Title from '../../Typography/Title/Title';
import Text from '../../Typography/Text/Text';
import Image from '../Image/Image';
import './StaticDocumentInfo.less';

const StaticDocumentInfo = ({ file, title }) => {
  const {
    filename,
    filesize: size,
    filemime,
  } = file;

  let extension = filemime;
  if (!extension) {
    extension = getExtension(filename);
  }

  const precise = (num) => {
    if (!Number.isInteger(num)) return Number.parseFloat(num).toPrecision(4);
    return num;
  };

  const getDescription = passedSize => (
    <div className="theme-document-link-description">
      <Text>{precise(passedSize * 0.001)}</Text>
      <Text>
        {' '}
        {passedSize && 'Kb'}
        {' '}
        &nbsp;
        {' '}
      </Text>
    </div>
  );

  return (
    <div className="theme-document-link">
      <div className={`theme-document-link-inner extension-${extension}`}>
        <Title level={5}>{title || filename}</Title>
        <div className="content-wrapper">
          <Image
            preview={false}
            width="3rem"
            src={DocumentSelectIcon(extension)}
            alt="download icon"
          />
          { size && getDescription(size) }
        </div>
      </div>
    </div>
  );
};

StaticDocumentInfo.propTypes = {
  file: shape({
    body: string,
    filename: string.isRequired,
    size: number,
    url: string,
    extension: string,
  }).isRequired,
  title: string,
};

StaticDocumentInfo.defaultProps = {
  title: null,
};

export default StaticDocumentInfo;
