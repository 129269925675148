import React from 'react';
import { Col, Row } from 'antd';
import Investment from './Investment';
import FintechInvestment from '../../../Helpers/FintechInvestment';
import { getLmsAccount, currentFundingPoolSelect, loanHasCompleteFunding } from '../../../Helpers/loanShortcuts';
import PreviousInvestment from './PreviousInvestment';
import BackfillInvestment from './BackfillInvestment';
import { backfillReduction as _backfillReduction } from '../../../Helpers/lmsAccountShortcuts';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Card from '../../../Molecules/Cards/Card';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import Title from '../../../Atoms/Typography/Title/Title';
import './CurrentInvestment.less';

const CurrentInvestments = (props) => {
  const {
    match: { url, params: { context } }, investments: allInvestments, loan,
  } = props;

  const investments = allInvestments.filter(investment => investment !== null);

  const pool = currentFundingPoolSelect(loan);

  let totalInvestmentAmount = 0;
  const totalInvestmentLabel = context === 'dealer' ? 'Total investment' : 'Your total investment';

  let fundingComplete = true;
  let dealerCanFundLoan = false;
  if (loan) {
    fundingComplete = loanHasCompleteFunding(loan);
    dealerCanFundLoan = haveEntityPermission(loan, 'Can make commitment');
  }

  let previousInvestments = '';
  let backfillInvestments = '';
  let quoteInvestments = '';

  if (Array.isArray(investments) && investments.length > 0) {
    const notNullInvestments = investments.filter(investment => investment !== null);

    previousInvestments = notNullInvestments.filter(
      investment => !FintechInvestment.isQuote(investment) && !FintechInvestment.isBackfillInvestment(investment, loan),
    ).map((investment) => {
      const amount = parseInt(investment.fieldAmount, 10);
      const name = FintechInvestment.investmentClientName(investment);
      totalInvestmentAmount += amount;

      return (
        <PreviousInvestment
          key={investment.uuid}
          name={name}
          amount={amount}
          lmsId={investment.fieldLmsAccountId}
        />
      );
    });

    backfillInvestments = notNullInvestments.filter(
      investment => FintechInvestment.isBackfillInvestment(investment, loan),
    ).map((investment) => {
      const amount = parseInt(investment.fieldAmount, 10);
      const name = FintechInvestment.investmentClientName(investment);
      const lmsAccount = getLmsAccount(loan);
      const backfillReduction = _backfillReduction(lmsAccount);

      totalInvestmentAmount += amount - backfillReduction;

      return (
        <BackfillInvestment key={investment.uuid} {...{ name, amount, loan }} />
      );
    });

    quoteInvestments = notNullInvestments.filter(
      investment => FintechInvestment.isQuote(investment),
    ).map((investment) => {
      const amount = parseInt(investment.fieldAmount, 10);
      const name = FintechInvestment.investmentClientName(investment);
      const clientUuid = FintechInvestment.investmentClientUuid(investment);
      const canAddAdditionalFunds = FintechInvestment.isQuote(investment) && dealerCanFundLoan && !fundingComplete;

      totalInvestmentAmount += amount;

      return (
        <Investment
          key={investment.entityUuid}
          currentUrl={url}
          name={name}
          amount={amount}
          clientUuid={clientUuid}
          canAddAdditionalFunds={canAddAdditionalFunds}
        />
      );
    });

    return (
      <Card
        title={'Client\'s investment'}
        className="theme-current-investments"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
          <Paragraph>Below is a list of your investments for this loan.</Paragraph>
          </Col>
          <Col span={12} className="list-wrapper">
            {previousInvestments}
            {backfillInvestments}
            {quoteInvestments}
          </Col>

          <Col span={12}>
            <Title level={5}>
              {totalInvestmentLabel}
              :
              {' '}
              {formatCurrencyFull(context === 'dealer' ? pool.totalFunded : totalInvestmentAmount)}
            </Title>
          </Col>
        </Row>

      </Card>
    );
  }

  return '';
};

export default CurrentInvestments;
