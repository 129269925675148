import React from 'react';
import {
  arrayOf, bool, func, number, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getCountry, getSelected } from '../../Store/clientRegistrationSlice';
import RadioGroup from '../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../Atoms/Forms/Radio/Radio';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';
import './EntitiesSelector.less';

const EntitiesSelector = ({
  allowNew, entities, defaultValue, onChange, isValid, label,
}) => {
  const country = useSelector(getCountry);
  const selected = useSelector(getSelected);

  return (
    <FormItem
      label={label}
      className="theme-entities-selector"
    >
      <RadioGroup className={`has-${entities.length + allowNew}-columns box-style`} onChange={onChange} defaultValue={defaultValue} value={selected}>
        {(entities && entities.length) && entities.map((entity) => {
          if (entity) {
            const {
              id, name, businessNumber, companyNumber, taxDetails, firstName, lastName,
            } = entity;

            const displayedName = name || `${firstName} ${lastName}`;

            const classes = ['entity-radio'];
            if (isValid(entity)) {
              classes.push('radio-valid');
            }

            if (taxDetails) {
              const { verified } = taxDetails;
              if (verified) {
                classes.push('verified');
              }
            }

            return (
              <div className="theme-radio-wrapper" key={`radio-client-${id}`}>
                <Radio className={classes.join(' ')} value={id}>
                  {displayedName}

                  {businessNumber && (
                    <>
                      {`${country === 'AU' ? '' : `\n ABN: ${businessNumber}`}`}
                    </>
                  )}

                  {companyNumber && (
                    <>
                      <br />
                      {`${country === 'AU' ? 'ACN:' : 'Company number:'} ${companyNumber}`}
                    </>
                  )}
                </Radio>
              </div>
            );
          }
          return null;
        })}
        {allowNew && <div className="theme-radio-wrapper"><Radio className="entity-radio" value="new">New Entity</Radio></div>}
      </RadioGroup>
    </FormItem>
  );
};

EntitiesSelector.propTypes = {
  label: string,
  allowNew: bool,
  entities: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
      businessNumber: string,
      registrationNumber: string,
    }),
  ),
  defaultValue: number,
  onChange: func,
  isValid: func,
};

EntitiesSelector.defaultProps = {
  label: '',
  allowNew: true,
  entities: [],
  defaultValue: 'new',
  onChange: () => {},
  isValid: () => false,
};

export default EntitiesSelector;
