import React from 'react';
import { shape, string, arrayOf } from 'prop-types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';
import PillBadge from '../../../Atoms/Markup/PillBadges/PillBadge';
import Title from '../../../Atoms/Typography/Title/Title';
import Text from '../../../Atoms/Typography/Text/Text';
import { titleCase } from '../../../Hooks/useStringFormatter';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import './ClientListItem.less';

const ClientListItem = ({ client, context }) => {
  const {
    uuid, fieldLmsClientId, name, clientType, type, acting,
  } = client || {};

  const typeArray = [];
  typeArray.push(titleCase(clientType || type));
  acting?.map(actingItem => typeArray.push(titleCase(actingItem?.clientRole || actingItem?.role)));

  return (
    <Fade>
      <Panel
        link={`/app/${context}/client/${uuid}`}
        className="theme-client-list-item"
        type="background"
        icon={<FontAwesomeIcon icon={faChevronRight} size="2x" />}
      >
        {
          fieldLmsClientId && (
            <PillBadge
              uniqueKey={fieldLmsClientId}
              title=""
              separator=""
              value={fieldLmsClientId}
            />
          )
        }
        <div className="inner-wrapper">
          {
            name && (
              <Title level={4}>{name}</Title>
            )
          }
          { typeArray && (typeArray.map(entity => (
            <Text key={entity}>
              {entity}
            </Text>
          ))) }
        </div>
      </Panel>
    </Fade>
  );
};

ClientListItem.propTypes = {
  context: string.isRequired,
  client: shape({
    uuid: string,
    fieldLmsClientId: string,
    name: string,
    clientType: string,
    acting: arrayOf(shape({})),
  }).isRequired,
};

export default ClientListItem;
