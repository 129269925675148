import React, { useEffect, useState } from 'react';
import {
  Form, notification,
} from 'antd';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import documentsService from '../../Services/Documents.service';
import { accountLookup, clientLookup } from './Components/DocumentEntitySearch';
import { documentTypesSelectList } from './Components/DocumentTypeSelect';
import DocumentEditForm from './Components/DocumentEditForm';
import { DocumentSelectIcon } from '../../Resources/Icons';
import { getExtension } from '../../Helpers/Helpers';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import Result from '../../Atoms/Feedback/Result/Result';
import Button from '../../Atoms/Forms/Button/Button';

const DocumentEdit = (props) => {
  const [documentTypesList, setDocumentTypesList] = useState(null);
  const [isMultiDoc, setIsMultiDoc] = useState(false);
  const [entitySelect, setEntitySelect] = useState('');
  const [clientValue, setClientValue] = useState(null);
  const [clientText, setClientText] = useState(null);
  const [accountValue, setAccountValue] = useState(null);
  const [accountText, setAccountText] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [documentEntity, setDocumentEntity] = useState(null);
  const [documentTypeValue, setDocumentTypeValue] = useState([]);
  const [accessValue, setAccessValue] = useState('internal');
  const [currentValue, setCurrentValue] = useState(true);
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [error, setError] = useState(false);

  const {
    match: {
      params: {
        mediaUuid,
        pageContext,
      },
    },
  } = props;
  const [form] = Form.useForm();
  const documentCopy = pageContext === 'duplicate';
  const { history } = useHistory();
  const { context } = useParams();

  useEffect(() => {
    documentTypesSelectList(setDocumentTypesList);
  }, []);

  useEffect(() => {
    documentsService.getDocument(mediaUuid)
      .then((query) => {
        if (query.data !== undefined) {
          setDocumentEntity(query.data.data.document);
          return;
        }
        setDocumentEntity([]);
        setError(true);
      })
      .catch(() => {
        setDocumentEntity([]);
        setError(true);
      });
  }, [mediaUuid]);

  useEffect(() => {
    if (documentEntity !== null) {
      const {
        fieldAccount,
        fieldCurrent,
        fieldClient,
        fieldCategories,
        fieldMediaFiles,
        fieldAccess,
      } = documentEntity;

      if (fieldAccount !== null) {
        setEntitySelect('account');
        setAccountText(fieldAccount.label);
        setAccountValue(fieldAccount.uuid);
      }
      if (fieldClient !== null) {
        setEntitySelect('client');
        setClientText(`${fieldClient.fieldLmsClientId} ${fieldClient.name}`);
        setClientValue(fieldClient.uuid);
      }

      if (fieldAccess.length > 0) {
        setAccessValue(fieldAccess);
      }

      if (fieldCurrent !== null) {
        setCurrentValue(fieldCurrent);
      }

      if (fieldCategories.length > 0) {
        setDocumentTypeValue(fieldCategories.map(item => item.fieldCategoryKey));
      }

      if (fieldMediaFiles !== null && fieldMediaFiles.length > 0) {
        setFileList(fieldMediaFiles.map(item => ({ name: item.filename, fid: item.fid, uid: item.fid })));
        const defaults = fieldMediaFiles.map(file => ({
          fid: file.fid,
          uid: file.fid,
          name: file.filename,
          thumbUrl: DocumentSelectIcon(getExtension(file.filename)),
          status: 'initial',
        }));
        setDefaultFileList(defaults);
      }
    }
  }, [documentEntity]);

  useEffect(() => {
    const initialValues = {
      documentTypeValue,
      accessValue,
      documentEntityType: entitySelect === 'account' ? 'account' : 'client',
      documentFiles: defaultFileList,
      clientValue,
      accountValue,
      currentValue,
    };
    form.setFieldsValue(initialValues);
  }, [
    documentTypeValue,
    accessValue,
    entitySelect,
    defaultFileList,
    clientValue,
    accountValue,
    currentValue,
    form,
  ]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const documentRecord = {
        access: values.accessValue,
        current: values.currentValue,
        // eslint-disable-next-line camelcase
        client_uuid: values.documentEntityType === 'client' ? clientValue : null,
        // eslint-disable-next-line camelcase
        account_uuid: values.documentEntityType === 'account' ? accountValue : null,
        categories: values.documentTypeValue,
        files: fileList.map(item => item.fid),
      };

      if (documentCopy) {
        documentsService.createDocument(
          documentRecord,
          () => {
            notification.open({
              type: 'success',
              message: 'A new document has been created.',
              duration: 2,
              onClose: () => {
                window.location.href = `/app/${context}/documents`;
              },
            });
          },
          () => {
            notification.open({
              type: 'error',
              message: 'An error occurred creating the document.',
              duration: 2,
              onClose: () => {
                window.location.href = `/app/${context}/documents`;
              },
            });
          },
        );
        return;
      }

      documentsService.updateDocument(
        mediaUuid,
        documentRecord,
        () => {
          notification.open({
            type: 'success',
            message: 'The document has been updated.',
            duration: 2,
            onClose: () => {
              window.location.href = `/app/${context}/documents`;
            },
          });
        },
        () => {
          notification.open({
            type: 'error',
            message: 'An error occurred creating the document.',
            duration: 2,
            onClose: () => {
              window.location.href = `/app/${context}/documents`;
            },
          });
        },
      );
    });
  };
  const onGoBack = () => {
    window.location.href = `/app/${context}/documents`;
  };

  const clients = {
    searchEvent: clientLookup,
    setValue: setClientValue,
    setText: setClientText,
    value: clientValue,
    text: clientText,
  };
  const accounts = {
    searchEvent: accountLookup,
    setValue: setAccountValue,
    setText: setAccountText,
    value: accountValue,
    text: accountText,
  };

  let docLabel = documentCopy ? 'Duplicate Document' : 'Edit Document';
  if (documentEntity !== null && documentEntity.entityLabel) {
    docLabel = documentEntity.entityLabel;
  }

  return (
    <>
      {error ? (
        <Result
          status="error"
          title="An error occurred loading the document."
          extra={(
          <Button
            onClick={onGoBack}
            type="default"
            ghost
          >
            Back to Documents
          </Button>
        )}
        />
      ) : (
        <div className="theme-document-edit page-inner">
      <HeaderTitle
        title={docLabel}
      />

      <DocumentEditForm
        history={history}
        accessValue={accessValue}
        accounts={accounts}
        accountValue={accountValue}
        clients={clients}
        clientValue={clientValue}
        currentValue={currentValue}
        defaultFileList={defaultFileList}
        documentCopy={documentCopy}
        documentTypesList={documentTypesList}
        documentTypeValue={documentTypeValue}
        documentForm={form}
        entitySelect={entitySelect}
        entitySelectEvent={setEntitySelect}
        isMultiDoc={isMultiDoc}
        onFormSubmit={onFormSubmit}
        setCurrentValue={setCurrentValue}
        setDocumentTypeValue={setDocumentTypeValue}
        setFileList={setFileList}
        setIsMultiDoc={setIsMultiDoc}
        uuid={mediaUuid}
      />
        </div>
      )
        }

    </>
  );
};

export default DocumentEdit;
