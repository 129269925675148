import React from 'react';

import { Link } from 'react-router-dom';
import Result from '../../Atoms/Feedback/Result/Result';
import useBrowserQuery from '../../Hooks/useBrowserQuery';

const MaintenanceMode = () => {
  const destination = useBrowserQuery().get('destination') || '/app';
  const description = (
    <>
      The site is currently undergoing maintenance. Please
      {' '}
      <Link to={destination ?? '/app'}>try again</Link>
      {' '}
      later.
    </>
  );
  return (
    <div className="zagga-error">
      <Result
        status="500"
        title="Site under maintenance"
        subTitle={description}
      />
    </div>
  );
};

export default MaintenanceMode;
