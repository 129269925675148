import VisibilitySensor from 'react-visibility-sensor';
import {
  bool, func, number, oneOfType, shape,
} from 'prop-types';
import React from 'react';
import Skeleton from '../Atoms/Feedback/Skeleton/Skeleton';

const InfiniteScroll = (
  {
    loading,
    pageSize,
    data,
    fetchMore,
  },
) => {
  const dataSet = Object?.keys(data)?.[0];
  const page = Math.ceil(data?.[dataSet]?.results?.length / pageSize);
  const total = data?.[dataSet]?.total;
  const onChange = (isVisible) => {
    if (isVisible) {
      fetchMore({
        variables: {
          offset: data?.[dataSet]?.results?.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            [dataSet]: {
              ...prev?.[dataSet],
              results: [...prev?.[dataSet]?.results, ...fetchMoreResult?.[dataSet]?.results],
            },
          };
        },
      });
    }
  };

  if ((page + 1) * pageSize < total) {
    return (
      <div className="load-more">
        <VisibilitySensor onChange={onChange}>
          <Skeleton loading={!loading} />
        </VisibilitySensor>
      </div>
    );
  }

  return null;
};

InfiniteScroll.propTypes = {
  loading: bool.isRequired,
  pageSize: number.isRequired,
  data: oneOfType([shape({
    total: number,
  })]),
  fetchMore: func.isRequired,
};

InfiniteScroll.defaultProps = {
  data: {},
};
export default InfiniteScroll;
