/* eslint-disable react/prop-types */
import React from 'react';
import Menu from '../../../Atoms/Navigation/Menu/Menu';
import './RoleMenus.less';

const RoleMenus = (props) => {
  const { items } = props;
  return (
    <div className="theme-role-menus">
      <div className="theme-role-menus-contents">
        <Menu mode="horizontal" items={items} />
      </div>
    </div>
  );
};

export default RoleMenus;
