import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { string } from 'prop-types';
import { OFFER_ACCEPT_GUEST, OFFER_DECLINE_GUEST, VERIFY_NEW_BORROWER } from '../../../GraphQL/Mutations/Loan';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Button from '../../../Atoms/Forms/Button/Button';
import LoanOfferDeclined from '../../../Molecules/Forms/Offer/LoanOfferDeclined';
import LoanOfferAccepted from '../../../Molecules/Forms/Offer/LoanOfferAccepted';
import OfferCheckboxes from '../../../Molecules/Forms/Offer/OfferCheckboxes';

const OfferToGuest = ({
  token,
}) => {
  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState(false);

  const [isDeclineModal, setIsDeclineModal] = useState(true);
  const [displayModal, setDisplayModal] = useState(false);

  const [LoanOfferAccept, { loading: acceptLoading }] = useMutation(OFFER_ACCEPT_GUEST);
  const [LoanOfferDecline, { loading: declineLoading }] = useMutation(OFFER_DECLINE_GUEST);
  const [VerifyNewBorrower] = useMutation(VERIFY_NEW_BORROWER);

  const acceptOffer = () => {
    LoanOfferAccept({ variables: { token } }).then(
      () => {
        setDisplayModal(true);
        setIsDeclineModal(false);
      },
    );
  };

  const offerAcceptedVerify = () => {
    VerifyNewBorrower({ variables: { token } })
      .then((response) => {
        const { data: { verifyNewBorrower: { loginToken: login } } } = response;
        window.location.href = `/app/activate-login/${login}`;
      });
  };

  const offerDeclinedLogout = () => {
    window.location.href = '/app/logout';
  };

  const declineOffer = () => {
    // @todo confirmation.
    LoanOfferDecline({ variables: { token } }).then(
      () => {
        setDisplayModal(true);
      },
    );
  };

  return (
    <>
      <OfferCheckboxes allCheckedCallback={value => setAllCheckboxesChecked(value)} />

      <ButtonGroup direction="right" className="set-3-style">
        <Button
          type="primary"
          disabled={!allCheckboxesChecked}
          loading={acceptLoading}
          onClick={acceptOffer}
        >
          Accept
        </Button>
        <Button
          ghost
          loading={declineLoading}
          onClick={declineOffer}
        >
          Decline
        </Button>
      </ButtonGroup>
      {displayModal
        && (
          isDeclineModal ? <LoanOfferDeclined modalClose={offerDeclinedLogout} /> : <LoanOfferAccepted modalClose={offerAcceptedVerify} />
        )
      }
    </>
  );
};

OfferToGuest.propTypes = {
  token: string.isRequired,
};

export default OfferToGuest;
