export const fieldAddressToOneLineAddressString = (fieldAddress) => {
  const fields = ['address_line1', 'address_line2', 'dependent_locality', 'locality', 'administrative_area', 'postal_code'];

  if (!fieldAddress) {
    return '';
  }

  return Object
    .values(fields)
    .filter(key => !!fieldAddress[key])
    .map(key => fieldAddress[key])
    .join(', ');
};

export const formatAddress = (address) => {
  if (typeof address === 'string') {
    return address;
  }

  if (!address || !address?.address_line1) {
    return null;
  }

  return fieldAddressToOneLineAddressString(address);
};
