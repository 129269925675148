import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormContext from './FormContext';

const FormProvider = ({ children }) => {
  const [form, setForm] = useState({});
  const [data] = useState({});
  const [finishLoading, setFinishLoading] = useState(false);
  const [stepCompleteStatus, setStepCompleteStatus] = useState([]);

  // TODO needs to be moved to redux.
  const updateStepCompleteStatus = (stepKey, status) => {
    const existingStepStatus = stepCompleteStatus.find(({ stepKey: currentStepKey }) => currentStepKey === stepKey);
    let updatedStepStatus = stepCompleteStatus;
    if (!existingStepStatus) {
      updatedStepStatus.push({ stepKey, status });
    }
    if (existingStepStatus) {
      updatedStepStatus = stepCompleteStatus.map((stepStatusData) => {
        const { stepKey: currentStepKey } = stepStatusData;
        if (currentStepKey === stepKey) {
          return {
            stepKey: currentStepKey,
            status,
          };
        }
        return stepStatusData;
      });
    }
    setStepCompleteStatus(updatedStepStatus);
  };


  return (
    <FormContext.Provider value={{
      form,
      setForm,
      data,
      stepCompleteStatus,
      setStepCompleteStatus,
      updateStepCompleteStatus,
      finishLoading,
      setFinishLoading,
    }}
    >
      {children}
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormProvider;
