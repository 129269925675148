import React from 'react';
import { bool, func } from 'prop-types';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Button from '../../../Atoms/Forms/Button/Button';

const OfferSubmit = ({
  canAccept,
  canDecline,
  canSend,
  termsAccepted,
  acceptLoading,
  acceptOffer,
  declineLoading,
  declineOffer,
  sendLoading,
  sendOffer,
}) => (
  <ButtonGroup direction="right" className="set-3-style">
    {canSend && (
      <Button
        type="secondary"
        loading={sendLoading || acceptLoading}
        onClick={sendOffer}
      >
        Send to borrower
      </Button>
    )}
    {canAccept && (
      <Button
        type="primary"
        disabled={!termsAccepted}
        loading={acceptLoading}
        onClick={acceptOffer}
      >
        Accept
      </Button>
    )}
    {canDecline && (
      <Button
        ghost
        loading={declineLoading}
        onClick={declineOffer}
      >
        Decline
      </Button>
    )}
  </ButtonGroup>
);

OfferSubmit.propTypes = {
  canAccept: bool,
  canDecline: bool,
  canSend: bool,
  termsAccepted: bool,
  acceptLoading: bool,
  acceptOffer: func,
  declineLoading: bool,
  declineOffer: func,
  sendLoading: bool,
  sendOffer: func,
};

OfferSubmit.defaultProps = {
  canAccept: true,
  canDecline: true,
  canSend: false,
  termsAccepted: false,
  acceptLoading: false,
  acceptOffer: () => {},
  declineLoading: false,
  declineOffer: () => {},
  sendLoading: false,
  sendOffer: () => {},
};

export default OfferSubmit;
