import React from 'react';
import { shape, string } from 'prop-types';
import DashBoardCards from '../../Organisms/DashBoards/DashBoardCards';
import HeaderTitleDashBoard from '../../Organisms/DashBoards/HeaderTitleDashboard';

const Dashboard = (props) => {
  const {
    match: {
      params: {
        context,
      },
    },
  } = props;

  return (
    <div className="theme-dashboard-wrapper">
      <HeaderTitleDashBoard context={context} />
      <DashBoardCards context={context} />
    </div>
  );
};

Dashboard.propTypes = {
  match: shape({
    params: shape({
      context: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Dashboard;
