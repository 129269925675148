import React from 'react';
import {
  bool, string, shape,
} from 'prop-types';
import SetPasswordForm from '../../Molecules/Forms/SetPasswordForm/SetPasswordForm';
import Image from '../../Atoms/Media/Image/Image';
import Title from '../../Atoms/Typography/Title/Title';
import Text from '../../Atoms/Typography/Text/Text';
import './SetPassword.less';

const SetPassword = ({
  data: {
    title, description, info, logo: { alt, preview },
  }, user, tempPassword,
}) => {
  const logoSrc = `/assets/logos/logo-${process.env.REACT_APP_X_DEALER}.svg`;

  return (
    <div className="theme-set-password">
      <Image alt={alt} src={logoSrc} preview={preview} />
      <Title level={1}>{title}</Title>
      <Text>{description}</Text>
      <br />
      <Text>{info}</Text>
      <SetPasswordForm user={user} tempPassword={tempPassword} src={logoSrc} alt={alt} preview={preview} />
    </div>
  );
};

SetPassword.propTypes = {
  data: shape({
    title: string,
    description: string,
    info: string,
    logo: shape({
      src: string.isRequired,
      alt: string,
      preview: bool,
    }),
  }),
  user: shape({
    uuid: string,
    email: string,
  }).isRequired,
  tempPassword: string.isRequired,
};

// This should change because user comes from api call and function onFinish should be defined on this component
SetPassword.defaultProps = {
  data: {
    title: 'Set password',
    description: 'You have used your one-time set password link.',
    info: `Please create a password to log in to your ${
      process.env.REACT_APP_BRAND
    } account.`,
    logo: {
      src: null,
      alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
      preview: false,
    },
  },
};

export default SetPassword;
