import React from 'react';
import { string, arrayOf } from 'prop-types';

const PillBadge = ({
  uniqueKey, title, value, classes, separator,
}) => (
  <div className="theme-badge-group">
    <span key={`badges-${uniqueKey}`} className={classes?.join(' ')}>
      {`${title}${separator}${value}`}
    </span>
  </div>
);

PillBadge.propTypes = {
  title: string,
  value: string.isRequired,
  uniqueKey: string.isRequired,
  classes: arrayOf(string),
  separator: string,
};

PillBadge.defaultProps = {
  title: '',
  classes: ['badge', 'badge-primary'],
  separator: ': ',
};


export default PillBadge;
