import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import { SYNC_ACCOUNT } from '../../../GraphQL/Mutations/Account';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';

const SyncAccountButton = ({ uuid, onSuccess }) => {
  const [SyncAccount, { loading }] = useMutation(SYNC_ACCOUNT, { variables: { uuid } });
  const [message, setMessage] = useState(false);

  const handleClick = () => {
    SyncAccount()
      .then((result) => {
        setMessage(false);
        onSuccess(result);
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <>
      { message && <Alert message={message} type="error" /> }
      <Button type="primary" loading={loading} onClick={handleClick}>
        Sync
      </Button>
    </>
  );
};

SyncAccountButton.propTypes = {
  uuid: string.isRequired,
  onSuccess: func,
};

SyncAccountButton.defaultProps = {
  onSuccess: () => {},
};

export default SyncAccountButton;
