import React from 'react';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import InvestmentCertificateOrganism from '../../../Organisms/Reporting/InvestmentCertificate/InvestmentCertificate';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentCertificate = () => (
  <>
    <HeaderTitle
      title={`Reporting / ${brandLabelConfig(dealer(), 'reportingInvestmentCertificates', 'Investment Certificates')}`}
      comment="Generate and manage your reports."
    />
    <InvestmentCertificateOrganism />
  </>
);

export default InvestmentCertificate;
