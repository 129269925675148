import React from 'react';
import { node } from 'prop-types';
import { useQuery } from '@apollo/client';
import { OPPORTUNITIES } from '../../../GraphQL/Queries/UpcomingOpportunities';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Result from '../../../Atoms/Feedback/Result/Result';
import Text from '../../../Atoms/Typography/Text/Text';
import UpcomingOpportunitiesListItem from '../../ViewModes/Accounts/UpcomingOpportunitiesListItem';

const UpcomingOpportunitiesDashBoardCard = ({ title }) => {
  const { loading, error, data } = useQuery(OPPORTUNITIES, { variables: { offset: 0, limit: 10 } });

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const opportunities = data?.upcomingOpportunities?.results;
  return (
    <Card
      title={title}
      loading={loading}
      type="dashboard"
    >
      { !opportunities?.length && <Text>You do not currently have any upcoming opportunities.</Text> }
      { opportunities?.map(opportunity => (
        <UpcomingOpportunitiesListItem
          key={opportunity.changed}
          opportunity={opportunity}
        />
      ))
      }
    </Card>
  );
};

UpcomingOpportunitiesDashBoardCard.propTypes = {
  title: node,
};

UpcomingOpportunitiesDashBoardCard.defaultProps = {
  title: (<Title level={4}>Upcoming opportunities</Title>),
};

export default UpcomingOpportunitiesDashBoardCard;
