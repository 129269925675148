import { React } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApplicationError from '../../../../Molecules/Error/ApplicationError';
import ApplicationSuccess from '../../../../Molecules/Confirmation/ApplicationSuccess';
import { getCreateError } from '../../../../Store/loanApplicationSlice';

const LoanCompletedStep = () => {
  const { context } = useParams();

  const error = useSelector(getCreateError);
  return (error && <ApplicationError context={context} error={error} />)
  || <ApplicationSuccess />;
};

export default LoanCompletedStep;
