import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Result from '../../Atoms/Feedback/Result/Result';
import ClientListItem from '../../Molecules/ViewModes/Clients/ClientListItem';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import NoResult from '../../Molecules/NoResult/NoResult';
import ClientSearchForm from '../../Molecules/Forms/ClientSearchForm/ClientSearchForm';
import useDebounceSearch from '../../Hooks/useDebounceSearch';
import useClientsQuery from '../../Hooks/useClientsQuery';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const Clients = () => {
  const { context } = useParams();
  const {
    limit, limitBrokerRoles, limitRoles, query, variables,
  } = useClientsQuery({ context });

  const {
    loading, error, data, refetch, fetchMore, networkStatus,
  } = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const { setInputText } = useDebounceSearch(refetch);

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { clients } = data || {};
  const { results } = clients || [];

  return (
    <div className="theme-org-clients">
      <ClientSearchForm
        loading={loading}
        updateVariables={input => setInputText({ ...variables, ...input, search: input?.search })}
        showRoles={!limitRoles && !limitBrokerRoles}
      />
      <Skeleton loading={loading && networkStatus !== 3}>
        { !results?.length && (
          <NoResult
            title="No clients found."
            body="Please try a different search or start by creating a client if you haven't already."
          />
        )}
        {results?.map(client => (
          <ClientListItem key={client?.uuid} client={client} context={context} />
        ))}
        <InfiniteScroll
          pageSize={limit}
          loading={loading}
          data={data}
          fetchMore={fetchMore}
        />
      </Skeleton>
    </div>
  );
};

export default Clients;
