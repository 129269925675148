import React from 'react';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import InvestmentsSummaryOrganism from '../../../Organisms/Reporting/InvestmentsSummary/InvestmentsSummary';

const InvestmentsSummary = () => (
  <>
    <HeaderTitle
      type="page-header"
      title="Reporting / Investment Statements Summary"
      comment="Generate and manage your reports."
    />
    <InvestmentsSummaryOrganism />
  </>
);

export default InvestmentsSummary;
