import React from 'react';
import { bool } from 'prop-types';
import { Skeleton as AntdSkeleton } from 'antd';

const Skeleton = args => <AntdSkeleton {...args} className="theme-skeleton" />;

Skeleton.propTypes = {
  active: bool,
  loading: bool,
};

Skeleton.defaultProps = {
  active: true,
  loading: false,
};

export default Skeleton;
