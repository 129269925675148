import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import DeleteAccountButton from '../../../../Molecules/Forms/Actions/DeleteAccountButton';
import Button from '../../../../Atoms/Forms/Button/Button';
import InvestmentProfileForm from './InvestmentProfileForm';

const InvestmentProfileEdit = ({ account, success }) => {
  const [investmentProfileVisible, setInvestmentProfileVisible] = useState(false);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const onSuccess = () => {
    setInvestmentProfileVisible(false);
    success();
  };

  return (
    <>
      <Row className="drawer-button">
        <Button type="primary" onClick={() => setInvestmentProfileVisible(true)}>
          Investment profile
        </Button>
      </Row>
      <Drawer
        title="Investment profile"
        width={isMdScreen ? '80vw' : '40vw'}
        closable
        onClose={() => setInvestmentProfileVisible(false)}
        open={investmentProfileVisible}
      >
        <div className="sections loan-edit">
          <div className="section">
            <HeaderTitle
              title="Edit investment profile"
              comment={(
                <Paragraph>
                  Amend the investment profile settings for this loan.
                </Paragraph>
              )}
            />
            <InvestmentProfileForm loan={account} onSuccess={onSuccess} />
          </div>
        </div>
      </Drawer>
      <Row className="drawer-button">
        <DeleteAccountButton type={account.type} uuid={account.uuid} />
      </Row>
    </>
  );
};

InvestmentProfileEdit.propTypes = {
  account: shape({
    uuid: string.isRequired,
  }).isRequired,
  success: func,
};

InvestmentProfileEdit.defaultProps = {
  success: () => {},
};

export default InvestmentProfileEdit;
