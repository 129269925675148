import { gql } from '@apollo/client';

export const INVITES = gql`
    query Invites($user: String) {
        invites(user: $user, limit: 100) {
            results {
                type
                uuid
                fieldLoan {
                    fieldState
                    fieldRisk
                    fieldLmsAccountId
                    loanTitle
                    uuid
                    fieldLoanProduct {
                        fieldRepaymentReturn
                        label
                    }
                    fieldPrimarySecurity {
                        fieldAddress {
                            shortAddress
                        }
                    }
                    fieldClient {
                        name
                    }
                }
            }
        }
        investments(
            user: $user
            limit: 100
            loanBundle: "loan"
            states: ["funding", "live-subscribed", "backfill"]
            sort: created
            sortDirection: DESC
        ) {
            results {
                fieldAmount
                fieldLmsAccountId
                fieldState
                investmentRate
                type
                uuid
                owner {
                    uuid
                }
                fieldLoan {
                    fieldState
                    fieldRisk
                    fieldLmsAccountId
                    loanTitle
                    uuid
                    fieldLoanProduct {
                        fieldRepaymentReturn
                        label
                    }
                    fieldPrimarySecurity {
                        fieldAddress {
                            shortAddress
                        }
                    }
                    fieldClient {
                        name
                    }
                }
                fieldClient {
                    name
                }
            }
        }
    }

`;


const Invites = {
  INVITES,
};

export default Invites;
