import React, {
  useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form } from 'antd';
import {
  func,
} from 'prop-types';
import Button from '../../../Atoms/Forms/Button/Button';
import ClientRegistrationListItem
  from '../../ViewModes/ClientRegistrationListItem/ClientRegistrationListItem';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import HeaderTitle from '../../HeaderTitle/HeaderTitle';
import {
  getAllStepEntities,
  getRegisterEntities,
  getSteps, removeEntity,
  setShowAccountOverviewDrawer,
  showAccountOverviewDrawer,
  getSelectedType,
  getClientStructureStepData,
  setStep,
  setReduxSubStep,
  setSelected,
  registrationHaveAnyErrors, getStepsStatus,
} from '../../../Store/clientRegistrationSlice';
import IconButton from '../../../Atoms/Forms/IconButton/IconButton';
import { errorsForEntityById, getEntityName } from '../../../Hooks/useEntity';
import Divider from '../../../Atoms/Layout/Divider/Divider';
import ClientRegistrationActions from '../Actions/ClientRegistrationActions';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import './ClientReview.less';

const ClientReview = ({
  onFinishLastStep, onGoBack,
}) => {
  const [form] = Form.useForm();
  const visible = useSelector(showAccountOverviewDrawer);
  const dispatch = useDispatch();
  const entities = useSelector(getRegisterEntities);
  const allStepEntities = useSelector(getAllStepEntities);
  const steps = useSelector(getSteps);
  const type = useSelector(getSelectedType);
  const primaryClient = useSelector(getClientStructureStepData);
  const stepsStatus = useSelector(getStepsStatus);
  stepsStatus.pop();
  const hasStepsWithErrors = stepsStatus.some(s => ['error', 'wait'].includes(s));

  const registrationHaveErrors = useSelector(registrationHaveAnyErrors) || hasStepsWithErrors;

  const prevVisible = useRef();
  useEffect(() => {
    prevVisible.current = visible;
  });

  const onDelete = ({ id }) => {
    dispatch(removeEntity(id));
  };

  const entitiesList = [];
  if (entities !== undefined && entities.length > 0) {
    entitiesList.push(entities.map(
      (entity) => {
        const { id, name } = getEntityName(entity);
        return (
          <>
            <ClientRegistrationListItem
              key={`entity-${id}`}
              entity={entity}
              validationErrors={errorsForEntityById(allStepEntities, steps, id)}
              deleteable={(
                <Popconfirm
                  title={`Are you sure you want to delete ${name}`}
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onDelete(entity)}
                >
                  <IconButton
                    type="delete"
                    size="small"
                  />
                </Popconfirm>
            )}
            />
          </>
        );
      },
    ));
  }

  return (
    <Row className="theme-client-review theme-review">
      <Col className="theme-client-review-wrapper" span={12}>
        <HeaderTitle
          title="Account review"
          commentType="grey-box"
          comment={(
            <>
              Please review your registration and complete any outstanding information. You can click on the status bar to return to each page of the registration at any time.
            </>
          )}
        />

        <div className="theme-panel-wrapper">
          {entitiesList}
        </div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinishLastStep}
          name="reviewForm"
        >
          {type !== 'individual' && entities.length > 1 && (
            <div className="theme-confirm-wrapper">
              <Paragraph>Have all entities been added?</Paragraph>
              <Button
                ghost
                type="secondary"
                onClick={() => {
                  dispatch(setStep(1));
                  dispatch(setReduxSubStep(primaryClient?.trusteeType === 'corporate' ? 1 : 0));
                  dispatch(setSelected('new'));
                  dispatch(setShowAccountOverviewDrawer(false));
                }}
              >
                Add Entity
              </Button>
            </div>
          )}
          <Divider />
          <ClientRegistrationActions form={form} onGoBack={onGoBack} canProceed={!registrationHaveErrors} alternateButtonText="Submit" />
        </Form>
      </Col>
    </Row>
  );
};

ClientReview.propTypes = {
  onFinishLastStep: func,
  onGoBack: func,
};

ClientReview.defaultProps = {
  onFinishLastStep: () => {},
  onGoBack: () => {},
};

export default ClientReview;
