import React from 'react';
import { func, shape, string } from 'prop-types';
import { Row } from 'antd';
import Button from '../../../../Atoms/Forms/Button/Button';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import UpdateClient from '../../../Forms/UpdateClient/UpdateClient';
import useNestedDrawer from '../../../../Hooks/useNestedDrawer';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import './EditClientDetailsDrawer.less';

const EditClientDetailsDrawer = (props) => {
  const { client, onSuccess } = props;
  const action = 'Edit';
  const {
    visible, setVisible, success, width,
  } = useNestedDrawer({ onSuccess });

  return (
    <Row className="drawer-button">
      <Button type="primary" onClick={() => setVisible(true)}>
        {action}
        {' '}
        client details
      </Button>

      <Drawer
        title="Edit client"
        width={width}
        closable
        onClose={() => setVisible(false)}
        open={visible}
        className="theme-client-details-drawer"
      >
        <HeaderTitle title="Client details">
          <Paragraph>Amend the contact details for this client.</Paragraph>
        </HeaderTitle>
        <UpdateClient
          client={client}
          onSuccess={success}
        />
      </Drawer>
    </Row>
  );
};

EditClientDetailsDrawer.propTypes = {
  client: shape({
    uuid: string.isRequired,
    address: shape({
      address_line1: string,
      address_line2: string,
      dependent_locality: string,
      locality: string,
      administrative_area: string,
      postal_code: string,
      country_code: string,
    }),
  }).isRequired,
  onSuccess: func.isRequired,
};

export default EditClientDetailsDrawer;
