import React, { useState, useContext } from 'react';
import { Form } from 'antd';
import { string, shape, func } from 'prop-types';
import { useMutation } from '@apollo/client';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../Atoms/Forms/Input/Input';
import Button from '../../../Atoms/Forms/Button/Button';
import { USER_UPDATE_PROFILE } from '../../../GraphQL/Mutations/User';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import LangContext from '../../../Context/Lang/LangContext';
import './UpdateProfile.less';

const UpdateProfile = ({ user, onFinish }) => {
  const { t } = useContext(LangContext);
  const [form] = Form.useForm();
  const [UserUpdateEmail, { loading }] = useMutation(USER_UPDATE_PROFILE, { refetchQueries: ['userByUuid'] });
  const [message, setMessage] = useState(false);

  const handleSubmit = (input) => {
    UserUpdateEmail({ variables: { input: { uuid: user.uuid, ...input } } })
      .then((result) => {
        setMessage(false);
        onFinish(result);
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-update-profile-form">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={user}
      >
        { message && <Alert message={message} type="error" /> }
        <FormItem
          label="Email"
          name="email"
          fieldKey="email"
          rules={[
            { type: 'email', message: 'Invalid email address' },
            { required: true, message: t('validation.required', { attribute: 'field' }) },
          ]}
        >
          <Input
            placeholder="Email"
          />
        </FormItem>

        <FormItem
          label="First name"
          name="preferredName"
          fieldKey="preferredName"
        >
          <Input
            placeholder="Name"
          />
        </FormItem>

        <FormItem
          label="Contact number"
          name="contactNumber"
          fieldKey="contactNumber"
        >
          <Input
            placeholder="Contact number"
          />
        </FormItem>
        <Button type="primary" htmlType="submit" loading={loading}>{loading ? 'Updating' : 'Update profile'}</Button>
      </Form>
    </div>
  );
};

UpdateProfile.propTypes = {
  user: shape({
    uuid: string,
    email: string,
    preferredName: string,
    contactNumber: string,
  }).isRequired,
  onFinish: func.isRequired,
};

export default UpdateProfile;
