import React from 'react';
import {
  bool, node, oneOfType, string,
} from 'prop-types';
import './RawText.less';

const RawText = (props) => {
  const {
    value,
    displayNull,
  } = props;

  if (displayNull || (value && !displayNull)) {
    return (<pre className="raw">{value}</pre>);
  }

  return null;
};

RawText.propTypes = {
  value: oneOfType([string, null, undefined, node]),
  displayNull: bool,
};


RawText.defaultProps = {
  value: null,
  displayNull: false,
};

export default RawText;
