/* eslint-disable */
import React, { useEffect } from 'react';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import NoResult from '../../Molecules/NoResult/NoResult';
import { useDebouncedLoanFilter, loanListGetLoans } from '../LoanApplications/Components/LoanFilter';
import LoanList from '../LoanApplications/Components/LoanList';

const InvestorMatch = () => {
  const {
    searchText,
    pageNumber,
    pageSize,
    setContext,
    setSearchText,
    setSearchState,
    setPageNumber,
    search,
  } = useDebouncedLoanFilter(loanListGetLoans, null, null, 'funding,backfill');

  useEffect(() => {
    setContext('dealer');
  }, [setContext]);

  const clearFilter = () => {
    setSearchText('');
    setSearchState('funding,backfill');
  };

  const noResult = (
    <NoResult
      title="No loans found."
      body="Please try a different filter."
    />
  );

  return (
    <div id="InvestorMatchPage" className="page">
      <HeaderTitle
        title="Investor Matcher"
        comment="View matching investors for existing loans."
      />
      <LoanList
        context="dealer"
        linkPattern="/app/dealer/investor-match"
        noResult={noResult}
        page={pageNumber}
        pageSize={pageSize}
        setPage={setPageNumber}
        search={search}
      />
    </div>
  );
};

export default InvestorMatch;
