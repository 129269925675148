import { gql } from '@apollo/client';

export const LOAN = gql`
    query loan($uuid: String $user: String) {
        loan(uuid: $uuid) {
            id
            dealer
            fundingAvailable
            lmsAccount
            lastCreditAssessmentDate
            legalFees
            loanConditions
            loanTitle
            platformFees
            repaymentsToDate
            type
            uuid
            entityPermissions {
                label
                permitted
            }
            fieldAmount
            fieldCreditRating
            fieldCurrentRaiseAmount
            fieldHideForClient
            fieldHideForLoanBook
            fieldNoSecondaryMarket
            fieldDealerAssociations {
                fieldDealer
                fieldForInvestment
                fieldTotalCommitments
            }
            fieldFundingProviders
            fieldFundsRequiredDate
            fieldInvestorReturn
            fieldLineFee
            fieldLmsAccountId
            fieldLvr
            fieldMaturityDate
            fieldMaximumTerm
            fieldMinimumTerm
            fieldNumberOfTranches
            fieldPurpose
            fieldRisk
            fieldState
            fieldSecuritiesTotal
            fieldTitleOverride
            fieldLoanProduct {
                label
                fieldRepaymentReturn
            }
            currentPool {
                fieldAmount
                fieldDelta
                fieldInvestmentRate
                fieldOpenDate
                fieldPoolTotal
                fieldRate
                fundingAvailable
                totalFunded
                term
            }
            currentFundingPool {
                fieldAmount
                fieldDelta
                fieldInvestmentRate
                fieldOpenDate
                fieldPoolTotal
                fieldRate
                fundingAvailable
                term
                totalFunded
            }
            fieldPools {
                uuid
                fieldAmount
                fieldDelta
                fieldInvestmentRate
                fieldOpenDate
                fieldRate
                fieldTermOverride
                term
            }
            fieldPrimarySecurity {
                fieldAddress {
                    shortAddress
                    value
                }
                fieldImages {
                    propertyThumbnail
                    original
                }
            }
            fieldClient {
                fieldLmsClientId
                name
                type
            }
            initialPool {
                fieldAmount
                fieldInvestmentRate
                fieldOpenDate
                fieldPoolTotal
                fieldRate
                term
            }
            offerExpiryDate
            owner {
                uuid
                email
            }
        }
        investments(
            loanUuid: $uuid
            user: $user
            limit: -1
            sort: changed
            sortDirection: DESC
        ) {
            total
            results {
                uuid
                commencementDate
                investmentRate
                fieldAmount
                fieldLmsAccountId
                fieldState
                fieldClient {
                    name
                }
            }
        }
        documents(accountUuid: $uuid) {
            results {
                uuid
                label
                fieldMediaFiles {
                    filename
                    filemime
                    url
                    uuid
                }
            }
        }
    }
`;

export const LOAN_OFFER = gql`
    query loanOfferQuery($token: String!) {
        loanOffer(token: $token) {
            paymentType
            user {
                uuid
                email
            }
            account {
                dealer
                type
                uuid
                lmsAccount
                lastCreditAssessmentDate
                loanConditions
                repaymentsToDate
                entityPermissions {
                    label
                    permitted
                }
                currentPool {
                    fieldAmount
                    fieldDelta
                    fieldInvestmentRate
                    fieldOpenDate
                    fieldPoolTotal
                    fieldRate
                    fundingAvailable
                    term
                    totalFunded
                }
                currentFundingPool {
                    fieldAmount
                    fieldDelta
                    fieldInvestmentRate
                    fieldOpenDate
                    fieldPoolTotal
                    fieldRate
                    fundingAvailable
                    term
                    totalFunded
                }
                fieldClient {
                    name
                    fieldLmsClientId
                }
                fieldBroker {
                    name
                    fieldLmsClientId
                }
                fieldAmount
                fieldInvestorReturn
                fieldLmsAccountId
                fieldLvr
                fieldMaturityDate
                fieldMaximumTerm
                fieldMinimumTerm
                fieldPurpose
                fieldRisk
                fieldState
                fieldSecuritiesTotal
                fieldTitleOverride
                fieldLoanProduct {
                    label
                    fieldRepaymentReturn
                }
                fieldPrimarySecurity {
                    fieldAddress {
                        shortAddress
                        value
                    }
                    fieldImages {
                        propertyThumbnail
                        original
                    }
                }
                fieldClient {
                    fieldLmsClientId
                    name
                    type
                }
                loanTitle
                offerExpiryDate
                owner {
                    uuid
                    email
                }
            }
        }
    }
`;

export const LOAN_FACETS = gql`
    query LoanFacets($states: [String]) {
        loans(states: $states) {
            facets {
                name
                count
            }
        }
    }
`;

export const LOAN_PRODUCTS = gql`
  query loanProducts {
    loanProducts {
      results {
        fieldLoanProductId
        fieldTerms
        fieldProductGroup
        label
        fieldShowSettlementDate
      }
    }
  }
`;

export const LOAN_INVESTMENT_PROFILE = gql`
  query loanInvestmentProfile($uuid: String!) {
    loan(uuid: $uuid) {
      uuid
      investmentProfile {
        name
        label
        description
        ... on InvestmentProfileOption {
          value
        }
        ... on GenerateInvestorAgreementProfileOption {
          optionValue: value
        }
      }
    }
  }
`;

const Loan = {
  LOAN,
  LOAN_FACETS,
  LOAN_OFFER,
  LOAN_PRODUCTS,
  LOAN_INVESTMENT_PROFILE,
};

export default Loan;
