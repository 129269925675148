import { gql } from '@apollo/client';

export const IDENTITY_VERIFICATION_LOGS = gql`
  query IdentityVerificationLogs(
    $types: [IdentificationType]
    $sortBy: IdentityVerificationLogSortBy
    $startDate: String
    $endDate: String
    $sortDirection: SortDirection
    $offset: Int = 0
    $limit: Int = 25
  ) {
    identityVerificationLogs(
      types: $types
      sortBy: $sortBy
      sortDirection: $sortDirection
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
    ) {
      total
      results {
        uuid
        fieldIdentificationType
        fieldMessage
        fieldProvider
        created
        fieldIsVerified
        fieldClient {
          uuid
          name
        }
      }
    }
  }
`;

export const Logs = {
  IDENTITY_VERIFICATION_LOGS,
};

export default Logs;
