import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on Address {
      address_line1
      address_line2
      dependent_locality
      locality
      administrative_area
      postal_code
      country_code
      value
      shortAddress
}
`;

export const USER_FRAGMENT = gql`
  fragment userFragment on User {
      uuid
      email
      primary {
          uuid
      }
  }
`;

export const PERMISSIONS_FRAGMENT = gql`
  fragment permissionsFragment on EntityPermission {
      label
      permitted
  }
`;

export const IDENTIFICATION_FRAGMENT = gql`
  fragment identificationFragment on IdentificationInterface {
      uuid
      type
      verified
  }
`;

export const IDENTIFICATION_FULL_FRAGMENT = gql`
  fragment identificationFullFragment on IdentificationInterface {
    type
    birthdate
    firstName
    lastName
    middleName
    ... on IdentificationAuCitizenshipCertificate {
     certificateDate
     evidenceNumber
    }
    ... on IdentificationAuDriverLicence {
      cardNumber
      licenceNumber
      licenceState
    }
    ... on IdentificationAuPassport {
      passportNumber
    }
    ... on IdentificationBirthCertificate {
      certificateNumber
    }
    ... on IdentificationOverseasPassport {
      passportNumber
      expiryDate
    }
    ... on IdentificationNzCitizenshipCertificate {
     countryOfBirth
     certificateDate
     evidenceNumber
    }
    ... on IdentificationNzDriverLicence {
      licence5a
      licence5b
      expiryDate
    }
    ... on IdentificationNzPassport {
      passportNumber
      expiryDate
    }
  }
`;
