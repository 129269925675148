import { gql } from '@apollo/client';

export const USER_BY_UUID = gql`
  query userByUuid($uuid: String!) {
    user(uuid: $uuid) {
      uuid
      roles
      email
      preferredName
      contactNumber
    }
  }
`;

export const USER_BY_EMAIL = gql`
  query userByEmail($email: String!) {
    user(email: $email) {
      uuid
      roles
      email
      preferredName
      contactNumber
    }
  }
`;

export const USER_VALIDATE_ONETIME_LOGIN_LINK = gql`
  query userValidateOneTimeLoginLink($token: String!) {
    userValidateOneTimeLoginLink(token: $token) {
      success
      message
      json
    }
  }
`;

export const USERS_FOR_DEALER = gql`
  query usersQuery($emailFilter: String) {
    users(emailFilter: $emailFilter sort: email sortDirection: ASC offset: 0 limit: 50) {
      total
      results {
        uuid
        roles
        email
        preferredName
        contactNumber
      }
    }
  }
`;

export const USERS_FOR_BROKER = gql`
  query usersQuery($emailFilter: String) {
    users: brokerClientUsers(emailFilter: $emailFilter) {
      total
      results {
        uuid
        roles
        email
        preferredName
        contactNumber
      }
    }
  }
`;

export const USER_EXISTS = gql`
  query userExists($email: String!) {
    userExists(email: $email) {
      userExists
    }
  }
`;

export const USERS = gql`
  query users(
      $isPrimary: Boolean
      $roles: [String]
      $offset: Int
      $limit: Int
      $sort: UserSortOrder
      $sortDirection: SortDirection
    ) {
      users(
          isPrimary: $isPrimary
          roles: $roles
          offset: $offset
          limit: $limit
          sort: $sort
          sortDirection: $sortDirection
      ) {
        total
        results {
            uuid
            email
            preferredName
            roles
            status
            primary {
                fieldLmsClientId
            }
            __typename
        }
      }
    }
`;

const User = {
  USER_BY_UUID,
  USER_BY_EMAIL,
  USER_VALIDATE_ONETIME_LOGIN_LINK,
  USERS_FOR_DEALER,
  USERS_FOR_BROKER,
  USER_EXISTS,
  USERS,
};

export default User;
