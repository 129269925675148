import React from 'react';
import { Form, Radio } from 'antd';

const Grade = () => {
  const alphaList = ['a', 'b', 'c', 'd', 'e', 'f'];
  const numericList = [1, 2, 3, 4, 5];

  return (
    <div>
      {alphaList.length > 0 && (
      <Form.Item
        label="Grade"
        className="ant-form-item--grade"
        name="gradeLetter"
        rules={[{ required: true, message: 'Please input loan grade letter.' }]}
      >
        <Radio.Group buttonStyle="solid" size="large">
          {alphaList.map(alphabet => (
            <Radio.Button key={alphabet} value={alphabet}>{alphabet.toUpperCase()}</Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      )}

      {numericList.length > 0 && (
      <Form.Item
        label="Grade"
        className="ant-form-item--grade"
        name="gradeNumber"
        rules={[{ required: true, message: 'Please input loan grade number.' }]}
      >
        <Radio.Group buttonStyle="solid" size="large">
          {numericList.map(number => (
            <Radio.Button key={number} value={number}>{number}</Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      )}
    </div>
  );
};

export default Grade;
