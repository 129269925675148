import React, { useState, useContext, useEffect } from 'react';
import {
  Form, notification,
} from 'antd';
import {
  func, shape, bool, number,
} from 'prop-types';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { stringify } from 'yaml';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Button from '../../../Atoms/Forms/Button/Button';
import Checkbox from '../../../Atoms/Forms/Checkbox/Checkbox';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import LangContext from '../../../Context/Lang/LangContext';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import { CREATE_POLICY, DELETE_POLICY, UPDATE_POLICY } from '../../../GraphQL/Mutations/Policy';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import TextArea from '../../../Atoms/Forms/Input/TextArea/TextArea';
import Select from '../../../Atoms/Forms/Select/Select';
import { getAppPoliciesOptions } from '../../../Store/appConfigurationSlice';
import { POLICY_DEFINITION_BY_KEY } from '../../../GraphQL/Queries/Configuration';
import ClientSelect from '../../../Molecules/Forms/Fields/ClientSelect/ClientSelect';
import AccountSelect from '../../../Molecules/Forms/Fields/AccountSelect/AccountSelect';
import Input from '../../../Atoms/Forms/Input/Input';
import { setNestedProp } from '../../../Helpers/Object/Object';

const PolicyForm = ({
  onSuccess, onCancel, policy,
}) => {
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const [message, setMessage] = useState('');
  const [getPolicy, { data: policyData }] = useLazyQuery(POLICY_DEFINITION_BY_KEY);
  const refetchQueries = ['Policies'];
  const [createPolicy, { loading: createLoading }] = useMutation(CREATE_POLICY, { refetchQueries });
  const [updatePolicy, { loading: updateLoading }] = useMutation(UPDATE_POLICY, { refetchQueries });
  const [policyDelete, { loading: deleteLoading }] = useMutation(DELETE_POLICY, { refetchQueries });
  const options = useSelector(getAppPoliciesOptions)?.filter(item => item?.value !== 'JoinPolicy');
  const policyDefinition = policyData?.configuration?.policy ? JSON.parse(policyData?.configuration?.policy) : null;

  const onChangePolicyKey = (key) => {
    getPolicy({ variables: { key } }).then((response) => {
      if (!policy) {
        if (response?.data?.configuration?.policy) {
          const responsePolicy = JSON.parse(response?.data?.configuration?.policy);
          if (responsePolicy?.expiry && responsePolicy?.expiry !== false) {
            form.setFieldValue(['input', 'fieldExpiry'], moment().add(responsePolicy?.expiry, 'seconds'));
            return;
          }
        }
        form.setFieldValue(['input', 'fieldExpiry'], undefined);
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue(
      policy ? { uuid: policy?.uuid, input: { ...policy, fieldExpiry: moment.unix(policy?.fieldExpiry) } } : {},
    );

    if (policy?.fieldPolicyKey) {
      onChangePolicyKey(policy?.fieldPolicyKey);
    }
  }, [policy]);

  useEffect(() => {
    form.setFieldValue(['input', 'fieldPolicyItemText'], stringify(policyDefinition));
  }, [policyDefinition]);

  const handleError = (error) => {
    notification.error({ message: 'An error occurred.', duration: 2 });
    setMessage(getGraphQlErrorMessage(error));
  };


  const submitForm = (values) => {
    const mutation = !policy ? createPolicy : updatePolicy;
    const variables = setNestedProp(values, ['input', 'fieldExpiry'], moment(values.input.fieldExpiry).unix());

    mutation({ variables }).then(() => {
      notification.success({ message: `Policy ${!policy ? 'created' : 'updated'}`, duration: 2 });
      onSuccess();
      onCancel();
    })
      .catch(handleError);
  };

  const handleDelete = () => {
    policyDelete({ variables: { uuid: policy?.uuid } })
      .then(() => {
        notification.success({ message: 'Policy deleted', duration: 2 });
        setMessage(false);
        onCancel();
      })
      .catch(handleError);
  };


  return (
    <Form
      form={form}
      name="policy"
      onFinish={submitForm}
    >
      { message && <Alert message={message} type="error" /> }

      <FormItem
        name="uuid"
        hidden
      >
        <Input />
      </FormItem>


      <FormItem
        label="Policy"
        name={['input', 'fieldPolicyKey']}
      >
        <Select
          disabled={!!policy}
          options={options}
          onChange={onChangePolicyKey}
        />
      </FormItem>

      <FormItem
        name={['input', 'fieldAccepted']}
        valuePropName="checked"
      >
        <Checkbox>Accepted</Checkbox>
      </FormItem>

      <FormItem
        label="Expires"
        name={['input', 'fieldExpiry']}
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="dd/mm/yyyy"
        />
      </FormItem>

      { (policyDefinition?.entity === 'client') && (
      <ClientSelect
        label="Client"
        name={['input', 'fieldClient', 'uuid']}
        roles={null}
        disabled={!!policy}
        uuid={policy?.fieldClient?.uuid}
        options={clients => clients?.map(option => ({ label: `${option?.clientId} - ${option?.label}`, value: option?.value }))}
      />
      )}

      { policyDefinition?.entity === 'account' && (
      <AccountSelect
        label="Account"
        name={['input', 'fieldAccount', 'uuid']}
        disabled={!!policy}
        uuid={policy?.fieldAccount?.uuid}
      />
      )}

      {policyDefinition && (
      <FormItem
        label="Policy Text"
        name={['input', 'fieldPolicyItemText']}
      >
        <TextArea
          autoSize
          disabled
        />
      </FormItem>
      ) }


      <ButtonGroup direction="bottom-right" className="set-2-style">
        {policy && (
        <Popconfirm
          title="Are you sure you want to delete this policy?"
          placement="topRight"
          okText="Yes"
          cancelText="No"
          onConfirm={handleDelete}
        >
          <Button
            type="ghost"
            loading={deleteLoading}
          >
            {deleteLoading ? 'Please wait' : 'Delete' }
          </Button>
        </Popconfirm>
        )}
        <Button type="primary" htmlType="submit" loading={createLoading ?? updateLoading}>
          {(createLoading ?? updateLoading) && 'Please wait' }
          {!(createLoading ?? updateLoading) && !policy ? 'Create' : 'Update'}
        </Button>
      </ButtonGroup>
    </Form>
  );
};

PolicyForm.propTypes = {
  policy: shape({
    accepted: bool,
    expiryDate: number,
  }),
  onSuccess: func,
  onCancel: func,
};

PolicyForm.defaultProps = {
  policy: undefined,
  onSuccess: () => {},
  onCancel: () => {},
};

export default PolicyForm;
