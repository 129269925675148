import React from 'react';
import {
  func, bool, string, oneOfType, number, shape,
} from 'prop-types';
import { AutoComplete as AntdAutoComplete } from 'antd';
import './AutoComplete.less';

const AutoComplete = (props) => {
  const { className } = props;
  return <AntdAutoComplete {...props} className={className !== undefined ? `theme-autocomplete ${className}` : 'theme-autocomplete'} />;
};


AutoComplete.propTypes = {
  allowClear: bool,
  autoFocus: bool,
  backfill: bool,
  className: string,
  defaultActiveFirstOption: bool,
  defaultOpen: bool,
  disabled: bool,
  dropdownClassName: string,
  dropdownMatchSelectWidth: oneOfType([bool, number]),
  filterOption: oneOfType([bool, func]),
  notFoundContent: string,
  options: shape([{
    label: string,
    value: string,
  }]),
  placeholder: string,
  value: string,
  onChange: func,
  onSelect: func,
  onBlur: func,
  onDropdownVisibleChange: func,
  onFocus: func,
  onSearch: func,
};

AutoComplete.defaultProps = {
  allowClear: false,
  autoFocus: false,
  backfill: false,
  className: undefined,
  defaultActiveFirstOption: true,
  defaultOpen: false,
  disabled: false,
  dropdownClassName: '',
  dropdownMatchSelectWidth: false,
  filterOption: true,
  notFoundContent: '',
  options: [],
  placeholder: false,
  value: '',
  onChange: () => {},
  onSelect: () => {},
  onBlur: () => {},
  onDropdownVisibleChange: () => {},
  onFocus: () => {},
  onSearch: () => {},
};

export default AutoComplete;
