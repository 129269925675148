import React from 'react';
import { Badge as AntdBadge } from 'antd';
import {
  arrayOf, bool, node, number, oneOf, string,
} from 'prop-types';
import './Badge.less';

const Badge = (args) => {
  const { status, className } = args;
  const newArgs = { ...args };
  delete newArgs.className;
  delete newArgs.status;
  return (<AntdBadge {...newArgs} className={['theme-badge', className, ` status-${status}`].join('')} />);
};

Badge.propTypes = {
  color: string,
  count: number,
  dot: bool,
  offset: arrayOf(number),
  overflowCount: number,
  showZero: bool,
  size: oneOf(['default', 'small']),
  status: oneOf(['success', 'processing', 'default', 'error', 'warning', 'disable']),
  text: node,
  title: string,
};

Badge.defaultProps = {
  color: undefined,
  count: undefined,
  dot: false,
  offset: undefined,
  overflowCount: 99,
  showZero: false,
  size: 'default',
  status: 'default',
  text: undefined,
  title: undefined,
};

export default Badge;
