import React from 'react';
import './ClientRegistrationListItem.less';
import {
  shape, string, node, arrayOf,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import Title from '../../../Atoms/Typography/Title/Title';
import Tag from '../../../Atoms/DataDisplay/Tag/Tag';
import NavigateToStep from '../../../Atoms/Navigation/NavigateToStep/NavigateToStep';
import { getEntityName } from '../../../Hooks/useEntity';
import { setShowAccountOverviewDrawer } from '../../../Store/clientRegistrationSlice';

const ClientRegistrationListItem = ({
  entity, validationErrors, deleteable, children,
}) => {
  const { id, name } = getEntityName(entity);
  const dispatch = useDispatch();

  const validationErrorsElement = validationErrors.map(({ message, id: stepEntityId }) => (
    <span key={`entity-${id}-error`} className="validation-error-item">
      <NavigateToStep
        stepEntityId={stepEntityId}
        label={message}
        extraHandler={() => dispatch(setShowAccountOverviewDrawer(false))}
      />
    </span>
  ));

  return (
    <Panel className="theme-client-registration-list-item">
      <Title level={4}>{name}</Title>
      { validationErrors.length ? (
        <Tag icon={<FontAwesomeIcon icon={faExclamation} />} type="incomplete">Incomplete</Tag>
      ) : (
        <Tag icon={<FontAwesomeIcon icon={faCheck} />} type="complete">Complete</Tag>
      )}
      {validationErrorsElement}
      { children }
      <div className="panel-icon">
        { deleteable }
      </div>
    </Panel>
  );
};

ClientRegistrationListItem.propTypes = {
  entity: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  deleteable: node,
  validationErrors: arrayOf(string),
  children: node,
};

ClientRegistrationListItem.defaultProps = {
  validationErrors: [],
  deleteable: undefined,
  children: '',
};

export default ClientRegistrationListItem;
