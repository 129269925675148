import React from 'react';
import {
  arrayOf, func, number, shape, string,
} from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Table from '../../DataDisplay/Table/Table';
import {
  formatNumber, formatPercentage,
} from '../../../Hooks/useNumberFormatter';
import { formatDateTime } from '../../../Helpers/FormatDate';

const InvestmentsSummaryTable = ({ investments, renderStatement }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 480px)' });
  const table = investments.map((row) => {
    const {
      investment: {
        uuid,
        commencementDate,
        investmentRate,
        fieldAmount,
        fieldLmsAccountId,
        fieldClient: {
          name: clientName,
        },
      },
      currentBalance,
      currentInterestPaid,
    } = row;
    return {
      key: uuid,
      uuid,
      investmentRate,
      clientName,
      currentBalance,
      currentInterestPaid,
      fieldAmount,
      fieldLmsAccountId,
      commencementDate,
    };
  });

  const columns = [
    {
      title: 'INVESTMENT \n INVESTING ENTITY',
      align: 'left',
      render: record => (
        <>
          {record.fieldLmsAccountId}
          <br />
          {record.clientName}
        </>
      ),
      width: '25%',
      responsive: ['xs'],
    },
    {
      title: 'INVESTMENT',
      dataIndex: 'fieldLmsAccountId',
      align: 'left',
      responsive: ['sm'],
    },
    {
      title: 'INVESTING ENTITY',
      dataIndex: 'clientName',
      align: 'left',
      width: '20%',
      responsive: ['sm'],
    },
    {
      title: 'INVESTOR RETURN',
      dataIndex: 'investmentRate',
      align: 'right',
      width: isSmallScreen ? '15%' : '10%',
      render: value => formatPercentage(value, '', 2),
    },
    {
      title: 'COMMENCEMENT DATE',
      dataIndex: 'commencementDate',
      align: 'right',
      render: value => formatDateTime(value),
      width: '15%',
    },
    {
      title: 'CURRENT PRINCIPAL INVESTED',
      dataIndex: 'currentBalance',
      align: 'right',
      render: value => formatNumber(value, '', 2),
    },
    {
      title: 'TOTAL INTEREST EARNED FYTD',
      dataIndex: 'currentInterestPaid',
      align: 'right',
      render: value => formatNumber(value, '', 2),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={table ?? []}
      pagination={false}
      expandable={{
        expandedRowRender: renderStatement,
      }}
    />
  );
};

InvestmentsSummaryTable.propTypes = {
  investments: arrayOf(shape({
    investment: shape({
      uuid: string.isRequired,
      commencementDate: string,
      investmentRate: number,
      fieldAmount: number,
      fieldLmsAccountId: string,
      fieldClient: shape({
        name: string,
      }),
    }),
    currentBalance: number,
    currentInterestPaid: number,
  })).isRequired,
  renderStatement: func.isRequired,
};

export default InvestmentsSummaryTable;
