import { oneOf } from 'prop-types';
import { useSelector } from 'react-redux';
import { isDealer as _isDealer, userUuid as _userUuid } from '../Store/userSlice';
import { CLIENTS } from '../GraphQL/Queries/Client';

const useClientsQuery = (props) => {
  const { context } = props;
  const query = CLIENTS;
  const userUuid = useSelector(_userUuid);
  const isDealer = useSelector(_isDealer);
  const limit = 25;
  const variables = {
    offset: 0,
    limit,
    ...((!isDealer && { user: userUuid }) || {}),
  };

  const limitRoles = ['borrower', 'investor'].includes(context);
  if (limitRoles) {
    variables.roles = [context];
  }

  const limitBrokerRoles = ['broker', 'introducer'].includes(context);
  if (limitBrokerRoles) {
    variables.roles = ['borrower', 'broker'];
  }

  return {
    limit, limitBrokerRoles, limitRoles, query, variables,
  };
};

useClientsQuery.propTypes = {
  context: oneOf(['broker', 'borrower', 'dealer', 'introducer', 'investor']),
};

export default useClientsQuery;
