import React from 'react';
import {
  node, number, object, oneOfType, shape, string,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import { formatRelativeDays, formatUnixInternalDate } from '../../../Helpers/FormatDate';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import Date from '../../../Atoms/DataDisplay/Field/Date';
import { dealer } from '../../../Hooks/useLocalStorage';
import { formatCurrencyFull, formatPercentage } from '../../../Hooks/useNumberFormatter';
import { brandLabelConfig, brandLoanFields } from '../../../Resources/BrandLabelConfigs';
import RawText from '../../../Atoms/DataDisplay/Field/RawText';
import Term from '../../../Molecules/DataDisplay/Fields/Term';

const LoanDetails = ({
  loan: {
    entityPermissions,
    lastCreditAssessmentDate,
    loanConditions,
    repaymentsToDate,
    legalFees,
    platformFees,
    fieldAmount: loanAmount,
    fieldInvestmentRate,
    fieldInvestorReturn,
    fieldLineFee,
    fieldLoanProduct,
    fieldMaturityDate,
    fieldMaximumTerm,
    fieldMinimumTerm,
    fieldPurpose,
    fieldRate,
    fieldRisk,
    fieldState,
    initialPool,
    offerExpiryDate,
  },
  pool: {
    fieldInvestmentRate: poolReturn,
    fieldOpenDate: offerOpenDate,
    fieldPoolTotal: currentRaise,
    fieldRate: borrowerRate,
    term,
    trancheLabel,
  },
  investment,
  children,
}) => {
  const { context } = useParams();
  const repaymentReturn = fieldLoanProduct?.fieldRepaymentReturn;
  const loanOpenDate = initialPool?.fieldOpenDate;

  const showInvestmentDetails = !!investment?.uuid;
  const investmentRate = investment?.investmentRate;
  const investmentOpenDate = investment?.commencementDate;

  const tranchePerm = haveEntityPermission({ entityPermissions }, 'View tranche fields');
  const viewRatePerm = haveEntityPermission({ entityPermissions }, 'View rates');
  const showCreditHistory = dealer() === 'znz' && fieldState === 'backfill';

  const workingRate = showInvestmentDetails ? investmentRate : (poolReturn || fieldInvestmentRate);
  const workingBorrowerRate = viewRatePerm && (borrowerRate || fieldRate);
  const investorReturnRate = (tranchePerm && fieldInvestorReturn) || (workingRate && formatPercentage(workingRate));
  const commencementDate = (showInvestmentDetails || dealer() !== 'znz') ? investmentOpenDate : loanOpenDate;

  const allFields = [
    { field: 'loanType', value: fieldLoanProduct?.label },
    { field: 'loanPurpose', value: fieldPurpose },
    { field: 'loanAmount', value: formatCurrencyFull(loanAmount) },
    { field: 'repaymentType', value: repaymentReturn },
    { field: 'trancheAmount', value: tranchePerm && formatCurrencyFull(currentRaise) },
    { field: 'tranches', value: tranchePerm && trancheLabel },
    { field: 'capitalisedInterest', value: '' },
    { field: 'creditAssessmentScore', value: fieldRisk },
    { field: 'term', value: (term.length > 0 && <Term entityPermission={entityPermissions} term={term} minTerm={fieldMinimumTerm} maxTerm={fieldMaximumTerm} />) },
    { field: 'borrowerRate', value: viewRatePerm && workingBorrowerRate && formatPercentage(workingBorrowerRate) },
    { field: 'lineFee', value: fieldLineFee && formatPercentage(fieldLineFee) },
    { field: 'investorReturn', value: viewRatePerm && investorReturnRate },
    { field: 'platformFees', value: formatCurrencyFull(platformFees, '') },
    { field: 'commencementDate', value: commencementDate && (<Date value={commencementDate} />) },
    { field: 'legalFees', value: formatCurrencyFull(legalFees, '') },
    { field: 'maturityDate', value: (fieldState === 'live' || fieldState === 'live-subscribed') && fieldMaturityDate && (<Date value={fieldMaturityDate} />) },
    { field: 'latestCreditAssessment', value: (fieldState === 'backfill') && lastCreditAssessmentDate && (<Date value={lastCreditAssessmentDate} />) },
    { field: 'paymentsToDate', value: repaymentsToDate, displayNull: (!['pending', 'assessed', 'funding'].includes(fieldState)) },
    { field: 'creditHistory', value: (showCreditHistory && 'No credit events to report') },
    { field: 'offerOpened', value: !showInvestmentDetails && (offerOpenDate !== loanOpenDate) && formatRelativeDays(offerOpenDate) },
    { field: 'offerCloses', value: !showInvestmentDetails && (offerOpenDate !== loanOpenDate) && offerExpiryDate && (<Date value={formatUnixInternalDate(offerExpiryDate)} />) },
  ];

  const displayFields = brandLoanFields(dealer(), context);
  const fields = displayFields.map(field => ({ label: field.label || '', ...allFields.find(f => f.field === field.field) }));

  return (
    <>
      <Card
        title={(<Title level={4}>{brandLabelConfig(dealer(), 'loanDetails', 'Loan details')}</Title>)}
      >
        <Fields fields={fields} />
        { dealer() === 'znz' && <Fields fields={[{ label: 'Loan conditions', value: (<RawText value={loanConditions} />) }]} numberOfColumns={1} /> }
        {children}
      </Card>
    </>
  );
};

LoanDetails.propTypes = {
  loan: shape({
    uuid: string,
    lastCreditAssessmentDate: string,
    loanConditions: string,
    repaymentsToDate: number,
    legalFees: number,
    platformFees: number,
    fieldAmount: number,
    fieldInvestorReturn: number,
    fieldLineFee: string,
    fieldLmsAccountId: string,
    fieldLoanProduct: shape({
      label: string,
      fieldRepaymentType: string,
    }),
    fieldLvr: number,
    fieldMaturityDate: string,
    fieldMaximumTerm: string,
    fieldMinimumTerm: string,
    fieldPoolTotal: number,
    fieldPurpose: string,
    fieldRisk: string,
    fieldState: string,
    fieldSecuritiesTotal: number,
    fieldTrancheLabel: string,
    initialPool: shape({
      fieldOpenDate: string,
    }),
    offerExpiryDate: string,
  }).isRequired,
  pool: shape({
    fieldRate: number,
    fieldInvestmentRate: number,
    fieldOpenDate: string,
    term: string,
  }).isRequired,
  investment: shape({
    uuid: string,
    investmentRate: number,
    commencementDate: oneOfType([string, object]),
  }),
  children: node,
};

LoanDetails.defaultProps = {
  investment: null,
  children: null,
};

export default LoanDetails;
