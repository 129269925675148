import React, { useState, useEffect } from 'react';
import useConstant from 'use-constant';
import { useHistory } from 'react-router';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import { useSelector } from 'react-redux';
import documentsService from '../../Services/Documents.service';
import DocumentList from './Components/DocumentList';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import DocumentFilter from './Components/DocumentFilter';
import { formatFromMoment } from '../../Helpers/FormatDate';
import DocumentStatus from './Components/DocumentStatus';
import { userUuid } from '../../Store/userSlice';
import Button from '../../Atoms/Forms/Button/Button';
import InfiniteScrollLegacy from '../../Molecules/InfiniteScrollLegacy';

const useDebouncedDocumentFilter = (searchFunction) => {
  const [searchContext, setSearchContext] = useState(null);
  const [searchUuid, setSearchUuid] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchTypes, setSearchTypes] = useState();
  const [searchStartDate, setSearchStartDate] = useState(null);
  const [searchEndDate, setSearchEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(25);

  const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchFunction, 200 + Math.random() * 800));

  const search = useAsync(
    async () => debouncedSearch(
      searchContext,
      searchUuid,
      searchText,
      searchTypes,
      searchStartDate,
      searchEndDate,
      page,
      pageSize,
    ),
    [searchContext, searchUuid, searchText, searchTypes, searchStartDate, searchEndDate, page, pageSize],
  );

  return {
    searchContext,
    searchUuid,
    searchText,
    searchTypes,
    searchStartDate,
    searchEndDate,
    page,
    pageSize,
    setSearchContext,
    setSearchUuid,
    setSearchText,
    setSearchTypes,
    setSearchStartDate,
    setSearchEndDate,
    setPage,
    search,
  };
};

const getDocuments = async (context, uuid, searchText, searchType, searchStartDate, searchEndDate, page, pageSize) => {
  let startDate = null;
  let endDate = null;


  if (searchStartDate) {
    startDate = formatFromMoment(searchStartDate);
  }
  if (searchEndDate) {
    // Add 24 hours so end date is inclusive of documents uploaded on that day.
    endDate = formatFromMoment(searchEndDate.clone().add(24, 'hour'));
  }

  const documentServiceTypes = {
    dealer: 'getDealerDocuments',
    broker: 'getOwnerDocuments',
    investor: 'getOwnerDocuments',
    borrower: 'getOwnerDocuments',
  };
  const documentsServiceType = documentServiceTypes[context];

  const query = await documentsService[documentsServiceType](searchType, searchText, startDate, endDate, page, pageSize, uuid);
  const documentsSearch = query?.data?.data?.documents;
  const documentTypes = query?.data?.data?.documentCategories?.results;
  let filteredTypes = documentsSearch?.facets;

  if (documentsSearch === null) {
    return { documents: [], types: [], count: 0 };
  }
  if (filteredTypes === null) {
    filteredTypes = [];
  }
  filteredTypes = filteredTypes.map(facet => facet.name);
  filteredTypes = documentTypes.filter(category => filteredTypes.includes(category.value));
  return { documents: documentsSearch.results, types: filteredTypes, count: documentsSearch.total };
};

const Documents = (props) => {
  const [statusMessage, setStatusMessage] = useState(null);
  /* eslint-disable-next-line  no-unused-vars */
  const [documentDeleteUuidConfirm, setDocumentDeleteUuidConfirm] = useState(null);

  const {
    match: {
      params: {
        context,
      },
    },
  } = props;

  const canEdit = context === 'dealer';
  const searchUuid = useSelector(userUuid);

  const {
    searchText,
    searchTypes,
    searchStartDate,
    searchEndDate,
    page,
    pageSize,
    setSearchContext,
    setSearchUuid,
    setSearchText,
    setSearchTypes,
    setSearchStartDate,
    setSearchEndDate,
    setPage,
    search,
  } = useDebouncedDocumentFilter(getDocuments);

  useEffect(() => {
    setSearchContext(context);
    setSearchUuid(searchUuid);
  }, [context, searchUuid, setSearchContext, setSearchUuid]);

  let documents = [];
  let documentTypes = [];
  let totalResults = 0;
  if (search.status === 'success' && Array.isArray(search.result.documents)) {
    totalResults = search.result.count;
    // eslint-disable-next-line prefer-destructuring
    documents = search.result.documents;
    documentTypes = search.result.types;
  }

  const history = useHistory();

  return (
    <div className="theme-documents page-inner">
      <HeaderTitle
        title="Documents"
        comment="Below is a list of your documents."
      >
        {canEdit && <Button type="primary" onClick={() => history.push('/app/dealer/document/add')}>Add document</Button>}
      </HeaderTitle>
      <DocumentFilter
        documentTypesList={documentTypes}
        searchText={searchText}
        searchTypes={searchTypes}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        setSearchText={setSearchText}
        setSearchTypes={setSearchTypes}
        setSearchStartDate={setSearchStartDate}
        setSearchEndDate={setSearchEndDate}
        setPage={setPage}
      />
      <DocumentList
        context={context}
        documentDelete={setDocumentDeleteUuidConfirm}
        editable={canEdit}
        items={documents}
        page={page}
        status={search.status}
      />
      <InfiniteScrollLegacy
        page={page}
        pageSize={pageSize}
        loading={search.status === 'loading'}
        totalResults={totalResults}
        onClick={() => setPage(page + 1)}
      />
      <DocumentStatus statusMessage={statusMessage} setStatusMessage={setStatusMessage} />
    </div>
  );
};

export default Documents;
