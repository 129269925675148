import { gql } from '@apollo/client';

export const USER_REGISTER = gql`
    mutation userAdd($input: UserInput!) {
      userAdd(input: $input) {
        success
        message
      }
    }
`;

export const USER_UPDATE_PASSWORD = gql`
    mutation userUpdatePassword($input: UserUpdatePasswordInput!) {
      userUpdatePassword(input: $input) {
        success
        message
      }
    }
`;

export const USER_UPDATE_PROFILE = gql`
  mutation userUpdate($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      success
      message
    }
  }
`;

export const USER_REQUEST_PASSWORD_RESET = gql`
  mutation userRequestPasswordReset($input: UserRequestPasswordResetInput!) {
    userRequestPasswordReset(input: $input) {
      success
      message
    }
  }
`;

export const USER_ONETIME_LOGIN_LINK = gql`
  mutation userOneTimeLoginLink($token: String!) {
    userOneTimeLoginLink(token: $token) {
      success
      message
      timestamp
      newPassword
      auth {
        auth {
          token_type
          expires_in
          access_token
          refresh_token
        }
        user {
          uuid
          roles
        }
      }
    }
  }
`;

export const USER_REFRESH_ONETIME_LOGIN_LINK = gql`
  mutation userRefreshOneTimeLoginLink($token: String!) {
    userRefreshOneTimeLoginLink(token: $token) {
      success
      message
    }
  }
`;

const User = {
  USER_REGISTER,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PROFILE,
  USER_REQUEST_PASSWORD_RESET,
  USER_ONETIME_LOGIN_LINK,
  USER_REFRESH_ONETIME_LOGIN_LINK,
};

export default User;
