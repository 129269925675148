import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Select from '../../../Components/Form/Select';
import { loanOptions } from '../../../Services/Loans.service';
import Button from '../../../Atoms/Forms/Button/Button';

const LoanSearchFilter = (props) => {
  const {
    clearFilter,
    searchAmount,
    searchProductGroup,
    searchProductOptions,
    searchState,
    searchStateOptions,
    setSearchAmount,
    setSearchProductGroup,
    setSearchState,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearFilter(), []);
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Form className="theme-filter">
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={searchStateOptions ? 4 : 5}>
          <Select
            className="form-control"
            placeholder="Select loan category"
            value={searchProductGroup}
            options={searchProductOptions}
            onChange={e => setSearchProductGroup(e.target.value)}
            formGroup
          />
        </Col>
        <Col xs={12} md={searchStateOptions ? 3 : 5}>
          <Select
            className="form-control"
            placeholder="Select loan amount"
            value={searchAmount}
            options={loanOptions()}
            onChange={e => setSearchAmount(e.target.value)}
            formGroup
          />
        </Col>
      { searchStateOptions && (
        <Col xs={12} md={3}>
          <Select
            className="form-control"
            placeholder="State"
            value={searchState}
            options={searchStateOptions}
            onChange={e => setSearchState(e.target.value)}
            formGroup
          />
        </Col>
      )}
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={clearFilter}>Clear</Button>
        </Col>
      </Row>
    </Form>
  );
};

LoanSearchFilter.propTypes = {
  clearFilter: PropTypes.func.isRequired,
  searchAmount: PropTypes.string,
  searchProductGroup: PropTypes.string,
  setSearchAmount: PropTypes.func.isRequired,
  setSearchProductGroup: PropTypes.func.isRequired,
};

LoanSearchFilter.defaultProps = {
  searchAmount: '',
  searchProductGroup: '',
};

export default LoanSearchFilter;
