import { useMediaQuery } from 'react-responsive';
import { useMutation } from '@apollo/client';
import { shape } from 'prop-types';
import React from 'react';
import { UPDATE_LOAN_POOL } from '../../GraphQL/Mutations/Account';
import { haveEntityPermission } from '../../Helpers/Helpers';
import Percentage from '../../Atoms/DataDisplay/Field/Percentage';
import Currency from '../../Atoms/DataDisplay/Field/Currency';
import Date from '../../Atoms/DataDisplay/Field/Date';
import EditableTable from '../../Atoms/DataDisplay/EditableTable/EditableTable';

const LoanPoolsTable = ({ account }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 480px)' });
  const [UpdateLoanPool] = useMutation(UPDATE_LOAN_POOL);
  const isBorrowerRateEditable = haveEntityPermission(account, 'Can edit borrower rate');
  const isInvestmentRateEditable = haveEntityPermission(account, 'Can edit investment rate');
  const isTermEditable = haveEntityPermission(account, 'Can edit term');

  const handleSave = (record, dataIndex) => {
    const input = {};
    input[dataIndex] = record[dataIndex];
    UpdateLoanPool({
      variables: {
        uuid: record.uuid,
        input,
      },
    })
      .then(() => {
      })
      .catch(() => {
      });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'fieldDelta',
      align: 'left',
      width: '5%',
      sorter: (a, b) => a.fieldDelta - b.fieldDelta,
      defaultSortOrder: 'descend',
    },
    {
      title: 'BORROWER RATE',
      dataIndex: 'fieldRate',
      align: 'left',
      editable: isBorrowerRateEditable,
      inputType: 'number',
      render: value => <Percentage value={value} showEmpty={false} />,
    },
    {
      title: 'INVESTMENT RATE',
      dataIndex: 'fieldInvestmentRate',
      editable: isInvestmentRateEditable,
      required: false,
      align: 'left',
      inputType: 'number',
      render: value => <Percentage value={value} showEmpty={false} />,
    },
    {
      title: 'TERM',
      dataIndex: 'term',
      align: 'left',
    },
    {
      title: 'TERM OVERRIDE',
      dataIndex: 'fieldTermOverride',
      align: 'left',
      editable: isTermEditable,
      required: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'fieldAmount',
      align: 'left',
      render: value => <Currency value={value} showEmpty={false} />,
    },
    {
      title: 'OPEN DATE',
      dataIndex: 'fieldOpenDate',
      align: 'left',
      width: isSmallScreen ? '3.8rem' : '15%',
      render: value => <Date value={value} format="YYYY-MM-DD" />,
    },
  ];

  return (
    <EditableTable
      dataSource={account?.fieldPools}
      columns={columns}
      handleSave={handleSave}
      pagination={false}
      rowKey="uuid"
      type="inner"
    />
  );
};

LoanPoolsTable.propTypes = {
  account: shape([]),
};

LoanPoolsTable.defaultProps = {
  account: [],
};
export default LoanPoolsTable;
