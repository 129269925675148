import React from 'react';
import { bool, func, string } from 'prop-types';
import Button from '../../Atoms/Forms/Button/Button';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';

const Modal = ({
  primaryAction, primaryText, primaryLoading, dismiss, dismissAction, dismissText, message, title,
}) => {
  document.body.classList.add('modal-open');

  const modalDismiss = () => {
    document.body.classList.remove('modal-open');
    dismiss();
  };

  const modalOk = () => {
    document.body.classList.remove('modal-open');
    const finalDismiss = dismissAction || dismiss;
    finalDismiss();
  };

  return (
    <>
      <div
        id="modal-messages"
        className="theme-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div>
              <div className="modal-body">
                <div className="container-messages-modal">
                  <h1 className="modal-heading">
                    {title}
                  </h1>
                  <Paragraph>{message}</Paragraph>
                </div>
              </div>
              <div className="modal-footer">
                {
                  primaryText && primaryAction && (
                    <Button
                      loading={primaryLoading}
                      disabled={primaryLoading}
                      type="primary"
                      onClick={primaryAction}
                    >
                      { primaryText }
                    </Button>
                  )
                }
                <Button
                  type="secondary"
                  name="op"
                  htmlType="submit"
                  data-dismiss="modal"
                  value="OK"
                  onClick={modalOk}
                  aria-hidden="true"
                >
                  { dismissText ? `${dismissText}` : 'OK' }
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div role="button" className="modal-backdrop in" tabIndex={0} onClick={modalDismiss} onKeyUp={modalDismiss} />
    </>
  );
};

Modal.propTypes = {
  primaryAction: func,
  primaryText: string,
  primaryLoading: bool,
  dismiss: func,
  dismissAction: func,
  dismissText: string,
  message: string,
  title: string,
};

Modal.defaultProps = {
  primaryAction: null,
  primaryText: null,
  primaryLoading: false,
  dismiss: null,
  dismissAction: null,
  dismissText: null,
  message: null,
  title: null,
};

export default Modal;
