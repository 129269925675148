import React from 'react';
import Meta from '../../../Atoms/Feedback/Meta/Meta';
import { capitalize } from '../../../Helpers/Helpers';
import Title from '../../../Atoms/Typography/Title/Title';
import BadgeGroup from '../../../Atoms/DataDisplay/BadgeGroup/BadgeGroup';
import Badge from '../../../Atoms/DataDisplay/Badge/Badge';

const ManagedFundHeader = (props) => {
  const {
    referenceId,
    poolId,
    name,
    state,
    metaTitle,
    skeleton,
  } = props;

  if (skeleton) return <div className="theme-loan-header skeleton" />;

  return (
    <div className="theme-loan-header">
      <Meta title={metaTitle} />
      <div className="theme-loan-header-loan-state">{capitalize(state)}</div>
      <div className="theme-loan-header-contents-wrapper">
        <div className="theme-loan-header-contents">
          <BadgeGroup>
            {referenceId && (
              <Badge>
                Your ref:
                {referenceId}
              </Badge>
            )
            }

            <Badge>
              {' '}

              Investment Fund:
              {' '}
              {poolId}
            </Badge>

          </BadgeGroup>


          <Title level={3} className="name">{name}</Title>
        </div>
      </div>
    </div>

  );
};

export default ManagedFundHeader;
