import {
  flattenEntities,
  extractDocument,
  preprocessInputData,
} from './useCommonConvertedEntityData';

/**
 * Client GraphQl input conversion from entity tree.
 */
const useLoanConvertedInputData = (dataList, dealerCountry) => {
  const documentOutput = loanDocData => [
    extractDocument(loanDocData?.documents?.documentsUpload, ['loan_supporting_documents'], 'private'),
  ].filter(doc => !!doc);

  const extractApplication = (data) => {
    const {
      application: {
        term: inputTerm,
        ...application
      },
      bank: { bank, number },
      securities: securitiesInput,
      solicitor,
      documents,
      clientName,
      errors,
      id,
      product,
      stepKey,
      ...loanData
    } = data;

    const { primary: primarySecurity, secondary } = securitiesInput;
    const securities = [
      { primary: true, ...primarySecurity },
      ...(secondary || []),
    ];
    const bankAccountNumber = `${bank || ''}${number}`;
    const term = (inputTerm && `term${inputTerm.replace(' ', '')}`) || null;

    return {
      ...application,
      ...loanData,
      ...((solicitor?.name && { solicitor }) || {}),
      term,
      securities,
      bank: { bankAccountNumber },
    };
  };

  const loanData = preprocessInputData(flattenEntities(dataList), dealerCountry);

  return {
    input: {
      ...extractApplication(loanData),
      documents: documentOutput(loanData),
    },
  };
};

export default useLoanConvertedInputData;
