import React from 'react';
import { number, string } from 'prop-types';
import Header from '../../Molecules/Header/Header';

const ManagedFundInvestmentHeader = (props) => {
  const {
    amount,
    fundId,
    fundState,
    investmentId,
    name,
    state,
  } = props;

  const investmentIsActive = state !== 'closed';

  return (
    <Header
      amount={amount}
      metaTitle={`Investment - ${investmentId}`}
      name={name}
      parentLabel="Fund"
      parentId={fundId}
      referenceId={investmentId}
      state={investmentIsActive ? fundState : state}
    />
  );
};

ManagedFundInvestmentHeader.propTypes = {
  amount: number.isRequired,
  fundId: string,
  fundState: string,
  investmentId: string.isRequired,
  name: string,
  state: string.isRequired,
};

ManagedFundInvestmentHeader.defaultProps = {
  name: '',
  fundId: '',
  fundState: '',
};

export default ManagedFundInvestmentHeader;
