import React from 'react';
import { number, shape, string } from 'prop-types';
import Title from '../../../Atoms/Typography/Title/Title';
import formatDate from '../../../Helpers/FormatDate';
import Card from '../../../Molecules/Cards/Card';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import { dealName, ucwords } from '../../../Helpers/Helpers';
import Percentage from '../../../Atoms/DataDisplay/Field/Percentage';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentStatementCard = (props) => {
  const { investment, period } = props;
  const fields = [
    { label: 'Investment Entity', value: investment?.fieldClient?.owner?.primary?.name },
    { label: 'Investor Return (p.a.)', value: <Percentage value={investment?.investmentRate} minDecimals={2} /> },
    { label: 'Commencement Date', value: formatDate(investment?.commencementDate) },
    { label: 'Investment ID', value: investment?.fieldLmsAccountId },
    { label: 'Loan Opportunity', value: dealName(investment?.fieldLoan) },
    { label: 'Current Principal Invested', value: <Currency value={investment?.transactions?.remainingPrincipal} decimals={2} /> },
    { label: 'Investment Address', value: investment?.fieldClient?.owner?.primary?.fieldAddress?.value },
    { label: 'Investment Principal', value: <Currency value={investment?.transactions?.principal} /> },
    { label: 'Total Interest Earned FYTD', value: <Currency value={investment?.transactions?.totalInterest} decimals={2} /> },
    { label: 'Period', value: period },
  ];

  return (
    <Card title={(<Title level={4}>{ucwords(brandLabelConfig(dealer(), 'reportingInvestmentStatement', 'Investment Statement'))}</Title>)}>
      <Fields fields={fields} />
    </Card>
  );
};

InvestmentStatementCard.propTypes = {
  investment: shape({
    commencementDate: string,
    investmentRate: number,
    fieldAmount: number,
    fieldClient: shape({
      owner: shape({
        primary: shape({
          name: string,
          fieldAddress: shape({
            value: string,
            locality: string,
          }),
        }),
      }),
    }),
    fieldLoan: shape({
      fieldLmsAccountId: string,
      fieldPrimarySecurity: shape({
        fieldAddress: shape({
          locality: string,
        }),
      }),
    }),
    transactions: shape({
      totalInterest: number,
    }),
  }).isRequired,
  period: string,
};

InvestmentStatementCard.defaultProps = {
  period: null,
};

export default InvestmentStatementCard;
