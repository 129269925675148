import React from 'react';
import {
  node, shape, string,
} from 'prop-types';
import useFile from '../../../Hooks/useFile';

const DocumentLink = ({
  children, className, localDocPath, file, ...props
}) => {
  const viewableFiles = ['application/pdf', 'text/plain', 'image/png', 'image/jpeg'];
  if (file && !viewableFiles.includes(file.filemime)) {
    const { view } = useFile();
    return (
      <div
        className={`${className} theme-link`}
        onClick={() => file.url && view(file)}
        onKeyDown={() => file.url && view(file)}
        role="button"
        tabIndex={0}
      >
        {children}
      </div>
    );
  }

  return (
    <a
      className={['theme-link', className].join(' ')}
      href={localDocPath}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
    </a>
  );
};

DocumentLink.propTypes = {
  children: node,
  className: string,
  file: shape({
    filename: string,
    filemime: string,
  }),
  localDocPath: string.isRequired,
};

DocumentLink.defaultProps = {
  className: '',
  children: null,
  file: null,
};

export default DocumentLink;
