import React from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../../Atoms/Forms/Input/Input';
import { getFieldName } from '../../../../Helpers/Forms';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';
import DatePickerFormItem from '../../DatePickerFormItem/DatePickerFormItem';

const NzPassport = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => (
  <>
    <InputGroup className="theme-passport-number">
      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Passport number"
            name={getFieldName('passportNumber', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Passport number', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('passportNumber', parents), getFieldValue('errors'))}
          >
            <Input />
          </FormItem>
        )}
      </FormItem>
      <DatePickerFormItem
        dateRestriction="future"
        fieldName="expiryDate"
        label="Date of expiry"
        parents={parents}
        validationRules={[item => useValidationRules.softRequired(item, 'Passport date', deleteExcept)]}
      />
    </InputGroup>
    <DraggerFormItem
      comment="Please upload a copy of your passport."
      fieldName={getFieldName('passportDocument', parents)}
      label="Upload identification"
      rules={[item => useValidationRules.softRequired(item, 'Passport document', deleteExcept)]}
      shouldUpdateComponent={shouldUpdateComponent}
    />
  </>
);

NzPassport.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

NzPassport.defaultProps = {
  parents: ['passport'],
  deleteExcept: ['personVerification.passportNumber', 'personVerification.expiryDate', 'personVerification.passportDocument'],
};

export default NzPassport;
