
const formatNumberWithDecimals = (
  value,
  emptyValue = '0.00',
  decimals = 2,
  options = { style: 'decimal' },
  useParentheses = false,
) => {
  const formatter = new Intl.NumberFormat(
    'en-US',
    { minimumFractionDigits: decimals, maximumFractionDigits: decimals, ...options },
  );
  const working = parseFloat(value);
  const working2 = working ? formatter.format(useParentheses ? Math.abs(working) : working) : emptyValue;
  return useParentheses && working < 0 ? `(${working2})` : working2;
};

export const countDecimals = (value) => {
  if ((value % 1) !== 0) {
    return value.toString().split('.')[1].length;
  }
  return 0;
};

export const formatNumber = (value, emptyValue = 0, decimals = 0) => formatNumberWithDecimals(value, emptyValue, decimals);

export const formatNumberWithParentheses = (value, emptyValue = 0, decimals = 0) => formatNumberWithDecimals(value, emptyValue, decimals, {}, true);

export const formatCurrency = (value, emptyValue = '$0') => formatNumberWithDecimals(value, emptyValue, 0, { style: 'currency', currency: 'USD' }, true);

export const formatCurrencyFull = (value, emptyValue = '$0.00') => formatNumberWithDecimals(value, emptyValue, 2, { style: 'currency', currency: 'USD' }, true);

export const formatPercentage = (value, emptyValue = '0.00') => `${formatNumberWithDecimals(value, emptyValue)}%`;
