import { InboxOutlined } from '@ant-design/icons';
import React from 'react';
import { Form, Upload } from 'antd';
import documentsService from '../../../Services/Documents.service';
import { getExtension } from '../../../Helpers/Helpers';
import { DocumentSelectIcon } from '../../../Resources/Icons';

const { Dragger } = Upload;

const DocumentFileDragger = (props) => {
  const {
    setList,
  } = props;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    if (e && e.fileList) {
      e.fileList.forEach((file, index) => {
        if (!file.thumbUrl) {
          e.fileList[index].thumbUrl = DocumentSelectIcon(getExtension(file.name));
        }
      });
    }

    return e && e.fileList;
  };

  const onFileUploadChange = (data) => {
    const completedUploads = data.fileList.filter(item => item.status === 'done' || item.status === 'initial');

    setList(completedUploads.map((item) => {
      if (item.status === 'initial') {
        return { name: item.name, fid: item.fid, uid: item.fid };
      }
      return { name: item.name, fid: item.response.fid[0].value, uid: item.response.fid[0].value };
    }));
  };

  return (
    <>
      <Form.Item
        label="File upload"
        className="section-file-upload"
        name="documentFiles"
        rules={[{ required: true, message: 'Please upload a file.' }]}
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Dragger
          name="documentFileDragger"
          className="theme-dragger"
          multiple
          accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.txt,.rtf,.csv"
          customRequest={documentsService.uploadFile}
          onChange={onFileUploadChange}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag files to this area to upload</p>
          <p className="ant-upload-hint">You can upload the following file types; pdf,doc,docx,xls,xlsx,png,jpg,jpeg,txt,rtf,csv </p>
        </Dragger>
      </Form.Item>
    </>
  );
};

export default DocumentFileDragger;
