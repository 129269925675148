
export const fragmentAddress = `
  ... on ContactAddress {
    fieldIsPostalAddress
    fieldIsPhysicalAddress
    fieldAddress {
      addressLine1
      addressLine2
      dependentLocality
      locality
      postalCode
    }
  }
`;

export const fragmentPhone = `
  ... on ContactPhone {
    fieldPhoneType
    fieldPhoneNumber
  }
`;

export const fragmentLmsId = `
    ... on ClientIndividual{
      fieldLmsClientId
    }
    ... on ClientCompany{
      fieldLmsClientId
    }
    ... on ClientTrust{
      fieldLmsClientId
    }
    ... on ClientPartnership{
      fieldLmsClientId
    }
    ... on ClientIndividuals{
      fieldLmsClientId
    }
`;

export const fragmentClientBpayKey = `
  ... on Client {
    reverseFieldClientAccounting {
      entities {
        ... on AccountingBpayKey {
          fieldBpayKey
        }
      }
    }
  }
`;

export const fragmentActingClientData = `
  entity {
    client: queryFieldClient {
      entities {
        entityUuid
        clientName: entityLabel
        ${fragmentLmsId}
        ${fragmentClientBpayKey}
      }
    }
  }
`;

export const fragmentFieldIdentification = `
  fieldIdentification {
    entity {
    ... on IdentificationAuDriverLicence {
        verified
      }
    ... on IdentificationAuPassport {
        verified
      }
    ... on IdentificationOverseasPassport {
        verified
      }
    ... on IdentificationAuCitizenshipCertificate {
        verified
      }
    }
  }
`;

export const reverseFieldClientAssociationAssociation = `
  reverseFieldClientAssociationAssociation {
    entities {
    entityId
    entityType
    ... on AssociationClientAssociation {
        entityId
        fieldClientAssociationMembers {
          entity {
            id
            uuid
          ... on ClientIndividual {
              fieldFirstName
              fieldMiddleName
              fieldLastName
              ${fragmentFieldIdentification}
              fieldAddress {
                entity {
                  ${fragmentAddress}
                }
              }
              email: uid {
                entity {
                  fieldRealEmail
                }
              }
              clientContact: uid {
                entity {
                  fieldClient {
                    entity {
                      contactUuid: uuid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const actingClientInfo = `
  fieldClient {
    entity {
      clientType: entityBundle
      clientName: entityLabel
      ${fragmentClientBpayKey}
    }
  }
`;

export const fragmentImageThumbnail = `
  entity {
  ... on MediaImage {
    fieldMediaImage {
      full: derivative(style: PROPERTY_FULL) {
        height
        width
        url
      }
      thumbnail: derivative(style: PROPERTY_THUMBNAIL) {
        height
        width
        url
      }
      height
      width
      url
    }
  }
}
`;

const fragmentSecurity = `
  fieldPropertyType
  fieldFinalValue
  fieldSecurityAddress
  fieldAddress {
    countryCode
    locality
    dependentLocality
    postalCode
    addressLine1
    addressLine2
  }
  fieldImages {
    ${fragmentImageThumbnail}
  }
`;

export const fragmentReverseFieldLoanSecurity = `
    reverseFieldLoanSecurity(
      filter: {
        conditions: [
          {
            field: "type"
            value: "property"
          }
          {
            field: "field_primary"
            value: "1"
          }
        ]
      }
    ) {
      entities {
        ... on SecurityProperty {
          ${fragmentSecurity}
        }
      }
    }
`;

export const fragmentReverseFieldLoanPool = `
  loanPool: reverseFieldLoanPool(filter:{ conditions: [{field:"field_current_pool", value: "1"}]}) {
    entities {
      ... on PoolLoanPool {
        poolAmount: fieldAmount
        TotalFunded
        FundingAvailable
        fieldRate
        fieldInvestmentRate
        fieldOpenDate {
          value
        }
        fieldDelta
        fieldLoanPoolTerm
        fieldPoolTotal
        fieldPoolType
        fieldTrancheLabel
      }
    }
  }
  fundingPool: reverseFieldLoanPool(filter:{ conditions: [{field:"field_currently_funding", value: "1"}]}) {
    entities {
      ... on PoolLoanPool {
        poolAmount: fieldAmount
        TotalFunded
        FundingAvailable
        fieldRate
        fieldInvestmentRate
        fieldOpenDate {
          value
        }
        fieldDelta
        fieldLoanPoolTerm
        fieldPoolTotal
        fieldPoolType
        fieldTrancheLabel
      }
    }
  }
  `;

export const fragmentReverseFieldInvestmentPool = `
  loanPoolAssociation: reverseFieldInvestmentsAssociation {
    entities {
      ... on AssociationInvestmentLoanPoolAssociation {
        fieldLoanPool {
          entity {
            ... on PoolLoanPool {
              poolAmount: fieldAmount
              fieldRate
              fieldInvestmentRate
              fieldTrancheLabel
              fieldOpenDate {
                value
              }
              fieldPoolTotal
              fieldPoolType
              fieldDelta
              fieldLoanPoolTerm
            }
          }
        }
      }
    }
  }
`;

export const fragmentDocuments = (currentOnly) => {
  let currentFilter = '';
  if (currentOnly) {
    currentFilter = `(filter: {
      conditions: [
        {
          field: "field_current"
          value: "1"
        }
      ]})`;
  }
  return (`
    reverseFieldAccountMedia${currentFilter} {
      entities {
        ... on MediaDocument {
          mediaUuid: entityUuid
          fieldMediaFiles {
            entity {
              fileUuid: entityUuid
              filename
              filemime
            }
          }
        }
      }
    }
  `);
};

export const fragmentAccountLoan = (getFullData = false, addAssociation = false) => `
  ... on AccountLoan {
    ${getFullData ? 'LmsAccount' : ''}
    ${getFullData ? 'haveInvite' : ''}
    FintechEntityPermissions
    TotalFunded
    FundingAvailable
    uid {
      entity {
        uuid
      }
    }
    entityBundle
    entityId
    entityUuid
    fieldHideForClient
    fieldHideForLoanBook
    fieldNoSecondaryMarket
    fieldFundsRequiredDate {
      date
      value
    }
    fieldLoanTitle
    fieldLmsAccountId
    fieldState
    fieldAmount
    fieldLvr
    fieldSecuritiesTotal
    fieldRisk
    fieldPurpose
    fieldInvestorReturn
    fieldMinimumTerm
    fieldMaximumTerm
    fieldLineFee
    fieldNumberOfTranches
    fieldTitleOverride
    fieldFundingProviders {
      entity {
        ... on ConfigurationDealer {
          title: entityLabel
          fieldKey
        }
      }
    }
    ${fragmentReverseFieldLoanSecurity}
    ${fragmentReverseFieldLoanPool}
    RequiredLoanDocuments
    ${addAssociation ? `reverseFieldLoanAssociation {
      entities {
        entityId
        ... on AssociationLoanDealerAssociation {
          fieldForInvestment
          fieldDealer {
            entity {
              ... on ConfigurationDealer {
                fieldKey
                title: entityLabel
              }
            }
          }
        }
      }
    }` : ''}
    fieldLoanProduct {
      entity {
        entityLabel
        ... on ProductLoanProduct {
          fieldRepaymentReturn
        }
      }
    }
    fieldActingClient {
      entity {
      ... on ActingClientTrustBorrower {
        ${actingClientInfo}
      }
      ... on ActingClientCompanyBorrower {
        ${actingClientInfo}
      }
      ... on ActingClientIndividualBorrower {
        ${actingClientInfo}
      }
      ... on ActingClientIndividualsBorrower {
        ${actingClientInfo}
      }
      ... on ActingClientPartnershipBorrower {
        ${actingClientInfo}
      }
        uuid
        uid {
          targetId
        }
      }
    }
  }
`;

export const fragmentAccountInvestment = () => `
  fieldActingClient {
    entity {
      ... on ActingClientIndividualInvestor {
        ${actingClientInfo}
      }
      ... on ActingClientIndividualsInvestor {
        ${actingClientInfo}
      }
      ... on ActingClientPartnershipInvestor {
        ${actingClientInfo}
      }
      ... on ActingClientTrustInvestor {
        ${actingClientInfo}
      }
      ... on ActingClientCompanyInvestor {
        ${actingClientInfo}
      }
    }
  }
`;

export const investmentInfo = `
  ...on AccountInvestment {
    isManagedFund
    title: entityLabel
    entityUuid
    entityId
    uuid
    fieldRate
    fieldState
    fieldStatus
    fieldLmsAccountId
    fieldAmount
    uid {
      entity {
        uuid: entityUuid
      }
    }
    ${fragmentDocuments(false)}
    ${fragmentReverseFieldInvestmentPool}
    fieldActingClient {
      ${fragmentActingClientData}
    }
    fieldLoan {
      entity {
        entityLabel
        title: entityLabel
        entityBundle
        entityId
        ... on AccountLoan {
          fieldLoanTitle
          fieldState
          fieldRisk
          fieldLvr
          fieldInvestorReturn
          fieldMinimumTerm
          fieldMaximumTerm
          fieldLineFee
          fieldNumberOfTranches
          fieldSecuritiesTotal
          fieldLmsAccountId
          fieldLoanProduct {
            entity {
              entityLabel
              ... on ProductLoanProduct {
                fieldRepaymentReturn
              }
            }
          }
          fieldActingClient {
            entity {
              client: queryFieldClient {
                entities {
                  entityUuid
                  clientName: entityLabel
                  ${fragmentLmsId}
                }
              }
            }
          }
        }
        ... on AccountManagedFund {
          fieldLmsPoolId
          fieldTargetRate
          fieldImage {
            ${fragmentImageThumbnail}
          }
        }
        ${fragmentReverseFieldLoanSecurity}
      }
    }
  }
`;

export const loanInvestmentsQuery = loanUuid => (loanUuid === null ? '' : `
  investments: investmentsView(
    filter: {
      loan_uuid: { value: "${loanUuid}" }
      hide_for_client: "0"
    }
  ) {
    entities: results {
      ${investmentInfo}
    }
  }
`);

export const fragmentBrokerCompanyInfo = `
  reverseFieldActingClientAssociation {
    entities {
      ... on AssociationBrokerAssociation {
        fieldBroker {
          entity {
          ... on ActingClientCompanyBroker {
            fieldClient {
              entity {
                ... on Client {
                  uuid
                  brokerCompanyName: entityLabel
                  reverseFieldClientAssociationAssociation {
                    entities {
                      entityId
                    }
                  }
                }
                ... on ClientCompany {
                  fieldLmsClientId
                  reverseFieldClientAssociationAssociation {
                    entities {
                      entityId
                      ... on AssociationClientAssociation {
                        fieldClientAssociationMembers {
                          entity {
                            clientName: entityLabel
                            clientType: entityBundle
                            ... on ClientIndividual {
                              id
                              uuid
                              uid {
                                entity {
                                  fieldRealEmail
                                }
                              }
                              fieldPhone {
                                entity {
                                  ... on ContactPhone {
                                    title: entityLabel
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
