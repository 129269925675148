import React from 'react';
import { object, oneOfType, string } from 'prop-types';
import moment from 'moment';

const Date = ({
  className, value, format,
}) => (value && (
  <div className={className}>
    {((value && typeof value === 'string') ? moment(value, 'YYYY-MM-DD') : value).format(format)}
  </div>
));

Date.propTypes = {
  className: string,
  value: oneOfType([string, object]),
  format: string,
};

Date.defaultProps = {
  className: '',
  value: '',
  format: 'D MMMM YYYY',
};

export default Date;
