import { React, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import HeaderTitle from '../HeaderTitle/HeaderTitle';
import { getGraphQlErrorMessage } from '../../Helpers/Error';
import { DELETE_DRAFT } from '../../GraphQL/Mutations/Draft';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { getDraftUuid } from '../../Store/loanApplicationSlice';

const ApplicationSuccess = () => {
  const draftUuid = useSelector(getDraftUuid);
  const [DeleteDraft, { loading }] = useMutation(DELETE_DRAFT);

  useEffect(() => {
    if (draftUuid) {
      DeleteDraft({ variables: { input: { uuid: draftUuid } } })
        .catch(error => console.log(getGraphQlErrorMessage(error)));
    }
  }, [draftUuid]);

  if (loading) {
    return <Skeleton loading={loading} />;
  }

  return (
    <Row className="theme-registration-success">
      <Col className="theme-description-wrapper" span={12}>
        <HeaderTitle
          title="Thank you. Your loan application has been submitted."
          commentType="grey-box"
          comment={(
            <>
              We have sent you a confirmation email from
              {' '}
              {process.env.REACT_APP_BRAND_EMAIL}
              .
              {' '}
              If you don&rsquo;t receive an email in the next 30 minutes, please check your spam folder and
              {' '}
              <a href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>contact Client Services</a>
              .
            </>
          )}
        />
      </Col>
    </Row>
  );
};

export default ApplicationSuccess;
