import { toDotNot } from '../Helpers/Object/Object';

const fieldValidator = (rule, value, formObject, message, returnOnlyFields, validationFailsMethod) => {
  const { field } = rule;
  const theMessage = message || `The ${field} is required.`;
  const previousErrors = formObject.getFieldValue('errors') || [];
  const currentFields = toDotNot(formObject.getFieldsValue());

  // Removes duplicate errors.
  const allErrors = previousErrors.reduce((previousError, currentError) => {
    const found = previousError.find(item => item.field === currentError.field);
    if (!found) {
      if (!(currentError.field in currentFields)) {
        return previousError;
      }
      return previousError.concat([currentError]);
    }
    return previousError;
  }, []);

  const errors = allErrors.filter(error => error.field !== field) || [];

  if (validationFailsMethod(value, formObject)) {
    errors.push({ message: theMessage, field });
  }

  if (returnOnlyFields.length) {
    errors.forEach((error, index) => {
      if (!returnOnlyFields.includes(error.field)) {
        errors.splice(index, 1);
      }
    });
  }

  formObject.setFieldsValue({ errors });

  return Promise.resolve();
};

const softRequired = (formObject, message = null, returnOnlyFields = []) => ({
  validator: (rule, value) => fieldValidator(
    rule,
    value,
    formObject,
    message,
    returnOnlyFields,
    val => !val || (Array.isArray(val) && val.length === 0),
  ),
  message,
});

// Can pass customised validation reqs for individual forms.
const customValidation = (formObject, message = null, validationFailsMethod, returnOnlyFields = []) => ({
  validator: (rule, value) => fieldValidator(
    rule,
    value,
    formObject,
    message,
    returnOnlyFields,
    validationFailsMethod,
  ),
  message,
});

const validateStatus = (fieldName, errors) => {
  if (errors === undefined || errors.length === 0) {
    return undefined;
  }
  return errors.filter(({ field }) => (field === fieldName.join('.'))).length > 0 ? 'warning' : undefined;
};

export const useValidationRules = {
  validateStatus,
  softRequired,
  customValidation,
};

export default useValidationRules;
