import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../Atoms/Forms/Button/Button';
import AccountSelect from '../Fields/AccountSelect/AccountSelect';

const InvestmentCertificateForm = (props) => {
  const { uuid } = useParams();
  const { loading, onFinish } = props;

  useEffect(() => {
    if (uuid) {
      onFinish?.({ account: uuid });
    }
  }, [uuid]);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Form onFinish={onFinish} className="theme-filter">
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={10}>
          <AccountSelect placeHolder="Account" accountType="investment" />
        </Col>
        <Col xs={12} md={2}>
          <Button loading={loading} type="secondary" htmlType="submit">
            Preview
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

InvestmentCertificateForm.propTypes = {
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};

InvestmentCertificateForm.defaultProps = {
  loading: false,
  onFinish: () => null,
};

export default InvestmentCertificateForm;
