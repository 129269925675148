import React, { useState, useContext } from 'react';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import { func, shape } from 'prop-types';
import Button from '../../../Atoms/Forms/Button/Button';
import Title from '../../../Atoms/Typography/Title/Title';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import './SignupForm.less';
import { USER_REGISTER } from '../../../GraphQL/Mutations/User';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import Policy from '../../../Organisms/Forms/Policies/Policy';
import LangContext from '../../../Context/Lang/LangContext';
import { dealer } from '../../../Hooks/useLocalStorage';

const SignupForm = ({ initialValues, onFinish }) => {
  const { t } = useContext(LangContext);
  const [userAdd, { loading }] = useMutation(USER_REGISTER);
  const [message, setMessage] = useState(false);

  const onFinishLocal = (values) => {
    // @todo: get default timezone from dealer config.
    const input = { ...values, timezone: (dealer() === 'znz' ? 'Pacific/Auckland' : '') };
    delete input.policy;

    setMessage(false);
    userAdd({ variables: { input } })
      .then(onFinish)
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-signup-form">
      <Title level={1}>Create your portal login</Title>
      <Form onFinish={onFinishLocal} initialValues={initialValues}>
        { message && <Alert message={message} type="error" /> }
        <FormItem
          name="preferredName"
          rules={[{ required: true, type: 'string', message: t('validation.required', { attribute: 'field' }) }]}
        >
          <Input placeholder="First name" />
        </FormItem>

        <FormItem
          name="email"
          rules={[{ required: true, type: 'email', message: t('validation.required', { attribute: 'field' }) }]}
        >
          <Input placeholder="Email address" />
        </FormItem>

        <FormItem
          name="contactNumber"
          rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
        >
          <Input placeholder="Contact number" />
        </FormItem>

        <Policy policyKey="JoinPolicy" />

        <Button block type="primary" htmlType="submit" loading={loading}>
          Send verification email
        </Button>
      </Form>
    </div>
  );
};

SignupForm.propTypes = {
  initialValues: shape({}),
  onFinish: func,
};

SignupForm.defaultProps = {
  initialValues: {},
  onFinish: () => {},
};

export default SignupForm;
