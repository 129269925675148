import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { Col, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Menu from '../../../Atoms/Navigation/Menu/Menu';
import Image from '../../../Atoms/Media/Image/Image';
import Drawer from '../../../Atoms/Feedback/Drawer/Drawer';
import Link from '../../../Atoms/Navigation/Links/Link';

import Button from '../../../Atoms/Forms/Button/Button';
import './MobileMenus.less';


const MobileMenus = (props) => {
  const { topMenus, roleMenus, brand } = props;
  const isSmallScreen = useMediaQuery({ query: '(max-width: 480px)' });

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  return (
    <div className="theme-mobile-menus">
      <Row className="theme-mobile-menus-contents" justify="space-between" align="middle">
        <Col className="theme-mobile-menus-logo" span={4}>
          <Link to="/app">
            <Image
              alt={process.env.REACT_APP_BRAND_LEGAL_NAME}
              src={`/assets/logos/menu-logo-${brand}.svg`}
              preview={false}
            />
          </Link>
        </Col>
        <Col className="theme-mobile-menus-trigger">
          <Button shape="square" type="primary" onClick={showDrawer} icon={<MenuOutlined />} />
          <Drawer className="theme-mobile-menus-drawer" width={isSmallScreen ? '85vw' : '40vw'} placement="right" onClose={onClose} open={visible}>
            <Menu items={roleMenus} mode="inline" theme="dark" onSelect={onClose} />
            <Menu items={topMenus} mode="inline" theme="dark" onSelect={onClose} />
          </Drawer>
        </Col>
      </Row>
    </div>
  );
};

MobileMenus.propTypes = {
  brand: PropTypes.string,
  topMenus: PropTypes.arrayOf(PropTypes.string),
  roleMenus: PropTypes.arrayOf(PropTypes.string),
};

MobileMenus.defaultProps = {
  brand: undefined,
  topMenus: undefined,
  roleMenus: undefined,
};

export default MobileMenus;
