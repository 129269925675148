/* global google */
import React, { useContext, useEffect, useState } from 'react';
import {
  arrayOf, bool, number, oneOfType, string, shape, func,
} from 'prop-types';
import { useExternal } from 'ahooks';
import LangContext from '../../../Context/Lang/LangContext';
import Input from '../../../Atoms/Forms/Input/Input';
import Select from '../../../Atoms/Forms/Select/Select';
import allCountries from '../../../Resources/Countries';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import addressConfigs from '../../../Resources/AddressConfigs';
import { buildFormValues, getFieldName } from '../../../Helpers/Forms';
import AutoComplete from '../../../Atoms/Forms/AutoComplete/AutoComplete';
import { dealerDefaultCountry } from '../../../Hooks/useLocalStorage';


const Address = ({
  defaultCountry, requireCountry, parents, form, list, configCallback,
}) => {
  const [status] = useExternal(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`, {
    async: false,
    type: 'js',
  });
  const { t } = useContext(LangContext);
  const availableCountries = addressConfigs.map(option => allCountries.find(item => option.countryCode === item.value));
  const [finderOptions, setFinderOptions] = useState([]);
  const [placeIds, setPlaceIds] = useState({});
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);
  const message = t('validation.required', { attribute: 'field' });
  const getAddressConfig = (code) => {
    const config = addressConfigs.find(country => country.countryCode === code);
    return configCallback ? configCallback(config) : config;
  };
  const [country, setCountry] = useState(getAddressConfig(defaultCountry));

  useEffect(() => {
    setCountry(getAddressConfig(defaultCountry));
  }, [defaultCountry]);

  const {
    countryCode, spec: {
      address_line1, address_line2, dependent_locality, locality, administrative_area, postal_code,
    }, googleComponentsMapping,
  } = country;

  useEffect(() => {
    if (typeof google !== 'undefined' && autocompleteService === null) {
      setAutocompleteService(new google.maps.places.AutocompleteService());
      setPlacesService(new google.maps.places.PlacesService(document.createElement('div')));
    }
  }, [status]);

  const displaySuggestions = (predictions) => {
    let options = [];
    const tempPlaceIds = {};
    if (predictions && predictions.length > 0) {
      options = predictions.map((item) => {
        const { description, place_id: placeId } = item;
        tempPlaceIds[description] = placeId;
        return { label: description, value: description };
      });
      setFinderOptions(options);
      setPlaceIds(tempPlaceIds);
    }
  };

  const fillInAddress = (place) => {
    const plainValues = {};
    const { address_components: addressComponents } = place;

    const componentsFormatted = [];

    addressComponents.map((component) => {
      const componentKey = component?.types[0];
      componentsFormatted[componentKey] = component;
      return componentKey;
    });

    const googleComponentsKeys = Object.keys(googleComponentsMapping);
    Object.values(googleComponentsMapping).map((map, key) => {
      plainValues[googleComponentsKeys[key]] = map.reduce((carry, current) => {
        const currentParts = current.split('.');
        let usedPart = componentsFormatted[current]?.long_name;
        if (currentParts.length > 1) {
          usedPart = componentsFormatted[currentParts[0]][currentParts[1]];
        }
        return `${carry + (usedPart || '')} `;
      }, '').trim();
      return plainValues[googleComponentsKeys[key]];
    });

    const newValues = buildFormValues(plainValues, list.concat(parents));

    form.setFieldsValue(newValues);
  };

  const selectPlace = (placeDescription) => {
    const placeId = placeIds[placeDescription];

    if (placeId) {
      placesService.getDetails({
        placeId,
        fields: ['address_components'],
      }, fillInAddress);
    }
  };

  const placesAutocomplete = (event) => {
    const { target: { value } } = event;
    if (value !== undefined && value.length > 5) {
      const request = {
        input: value,
        componentRestrictions: { country: countryCode },
      };

      if (autocompleteService) {
        autocompleteService.getPlacePredictions(request, displaySuggestions);
      }
    }
  };

  return (
    <div className="theme-address">
      <InputGroup>
        { requireCountry && (
          <FormItem
            label="Country"
            name={getFieldName('country_code', parents)}
          >
            <Select
              showSearch
              placeholder="Select or search"
              options={availableCountries}
              defaultValue={countryCode}
              onChange={event => setCountry(getAddressConfig(event))}
            />
          </FormItem>
        )}

        { address_line1.visibility && (
        <FormItem
          label={address_line1.label}
          name={getFieldName('address_line1', parents)}
          rules={[{ required: address_line1.required, message }]}
          onChange={event => placesAutocomplete(event)}
        >
          <AutoComplete
            placeholder="Line 1"
            options={finderOptions}
            onSelect={selectPlace}
          />
        </FormItem>
        )}

        { address_line2.visibility && (
        <FormItem
          label={address_line2.label}
          name={getFieldName('address_line2', parents)}
          className="theme-no-label-form-item"
          rules={[{ required: address_line2.required, message }]}
        >
          <Input placeholder="Line 2" />
        </FormItem>
        )}

      </InputGroup>
      <InputGroup compact>
        { dependent_locality.visibility && (
          <FormItem
            label={dependent_locality.label}
            name={getFieldName('dependent_locality', parents)}
            rules={[{ required: dependent_locality.required, message }]}
          >
            <Input />
          </FormItem>
        )}

        { locality.visibility && (
          <FormItem
            label={locality.label}
            name={getFieldName('locality', parents)}
            rules={[{ required: locality.required, message }]}
          >
            <Input />
          </FormItem>
        )}

        { administrative_area.visibility && (
          <FormItem
            label={administrative_area.label}
            name={getFieldName('administrative_area', parents)}
            rules={[{ required: administrative_area.required, message }]}
          >
            { administrative_area.options
              ? (<Select placeholder="- Select -" options={administrative_area.options} />)
              : (<Input />)}
          </FormItem>
        )}
        { postal_code.visibility && (
          <FormItem
            label={postal_code.label}
            name={getFieldName('postal_code', parents)}
            rules={[{ required: postal_code.required, message }]}
          >
            <Input />
          </FormItem>
        )}
      </InputGroup>
    </div>
  );
};

Address.propTypes = {
  form: shape({
    setFieldsValue: func,
  }),
  list: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  defaultCountry: string,
  requireCountry: bool,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  configCallback: func,
};

Address.defaultProps = {
  defaultCountry: dealerDefaultCountry(),
  requireCountry: true,
  parents: ['address'],
  form: null,
  list: [],
  configCallback: null,
};

export default Address;
