import React from 'react';
import { Drawer as AntdDrawer } from 'antd';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import PropTypes from 'prop-types';
import './Drawer.less';

const Drawer = (args) => {
  const { className } = args;
  const newArgs = { ...args };
  delete newArgs.className;

  return (
    <AntdDrawer {...newArgs} className={['theme-drawer', className].join(' ')} />
  );
};

Drawer.propTypes = {
  afterOpenChange: PropTypes.func,
  bodyStyle: PropTypes.shape({}),
  className: PropTypes.string,
  closable: PropTypes.bool,
  closeIcon: PropTypes.node,
  contentWrapperStyle: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  drawerStyle: PropTypes.shape({}),
  footer: PropTypes.node,
  footerStyle: PropTypes.shape({}),
  forceRender: PropTypes.bool,
  getContainer: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
    PropTypes.bool,
  ]),
  headerStyle: PropTypes.shape({}),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  keyboard: PropTypes.bool,
  mask: PropTypes.bool,
  maskClosable: PropTypes.bool,
  maskStyle: PropTypes.shape({}),
  placement: PropTypes.oneOf([
    'top', 'right', 'bottom', 'left',
  ]),
  push: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      distance: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  style: PropTypes.shape({}),
  title: PropTypes.node,
  visible: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  zIndex: PropTypes.number,
  onClose: PropTypes.func,
};

Drawer.defaultProps = {
  afterOpenChange: undefined,
  bodyStyle: undefined,
  className: null,
  closable: true,
  closeIcon: <CloseOutlined />,
  contentWrapperStyle: undefined,
  destroyOnClose: false,
  drawerStyle: undefined,
  footer: undefined,
  footerStyle: undefined,
  forceRender: false,
  getContainer: 'body',
  headerStyle: undefined,
  height: 256,
  keyboard: true,
  mask: true,
  maskClosable: true,
  maskStyle: {},
  placement: 'right',
  push: { distance: 180 },
  style: undefined,
  title: undefined,
  visible: false,
  width: 256,
  zIndex: 1000,
  onClose: undefined,
};

export default Drawer;
