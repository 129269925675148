import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  bool, func, node, string,
} from 'prop-types';
import Drawer from '../../../Atoms/Feedback/Drawer/Drawer';

const ReportDrawer = (props) => {
  const {
    children, title, visible, onClose,
  } = props;

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <Drawer
      title={title}
      width={isMdScreen ? '80vw' : '40vw'}
      closable
      onClose={onClose}
      open={visible}
    >
      <div className="sections loan-edit">
        <div className="section">
          {children}
        </div>
      </div>
    </Drawer>
  );
};

ReportDrawer.propTypes = {
  children: node,
  onClose: func,
  title: string,
  visible: bool,

};

ReportDrawer.defaultProps = {
  children: null,
  onClose: () => {},
  title: 'Reports',
  visible: false,
};

export default ReportDrawer;
