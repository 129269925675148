import { Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { func, string } from 'prop-types';
import LangContext from '../../../Context/Lang/LangContext';
import {
  findStepEntityByStepKey,
  getPrimaryClientEntity,
} from '../../../Store/clientRegistrationSlice';
import { USER_EXISTS } from '../../../GraphQL/Queries/User';
import Result from '../../../Atoms/Feedback/Result/Result';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Switch from '../../../Atoms/Forms/Switch/Switch';
import Input from '../../../Atoms/Forms/Input/Input';
import ClientRegistrationActions
  from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import BrokerSelect from '../../../Molecules/Inputs/BrokerSelect';
import UserSearchFormItem from '../../../Molecules/Forms/UserSearchFormItem/UserSearchFormItem';

const AttachToUser = ({
  onFinishLastStep, onGoBack, stepKey,
}) => {
  // @todo Implement test after redux new uuid.
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const entities = useSelector(getPrimaryClientEntity);

  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuidv4() };

  useEffect(() => {
    form.setFieldsValue(stepData);
  }, [stepData]);

  const { refetch, error } = useQuery(USER_EXISTS, {
    skip: !form.getFieldValue('email') || true,
  });

  const validator = async (_, email) => {
    try {
      const response = await refetch({ email });
      const userExists = !!response?.data?.userExists?.userExists;
      if (userExists) {
        return Promise.reject(new Error('This account already exists.'));
      }
      return Promise.resolve(true);
    } catch (issue) {
      if (issue.networkError) {
        throw new Error('Sorry, something went wrong.');
      }
      throw error;
    }
  };

  if (error) {
    return (
      <Result
        status="error"
        title="Sorry, something went wrong!"
        subTitle={`${error?.message}`}
      />
    );
  }

  return (
    <div className="theme-nz-attach-to-user">
      <HeaderTitle title="Create my client’s login" />
      <Form
        layout="vertical"
        form={form}
        initialValues={stepData}
        onFinish={onFinishLastStep}
        name="attachToUser"
      >
        <StepInfo
          id={stepData.id}
          stepKey={stepKey}
          parentId={entities[0]?.id}
        />
        <BrokerSelect />
        <FormItem
          label="Are you adding to an existing user/client?"
          name="existingUser"
          valuePropName="checked"
        >
          <Switch
            unCheckedChildren="No"
            checkedChildren="Yes"
          />
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(previous, next) => (previous.existingUser !== next.existingUser)}
        >
          {({ getFieldValue }) => (getFieldValue('existingUser') ? (
            <UserSearchFormItem
              fieldName="forUser"
              label="Attach to user"
              parseResults={data => data?.users?.results || []}
              validationRules={[
                { required: true, message: t('validation.required', { attribute: 'field' }) },
              ]}
            />
          ) : (
            <>
              <FormItem
                label="Email"
                name="email"
                fieldKey="email"
                validateTrigger="onBlur"
                rules={[
                  { type: 'email', message: 'Invalid email address' },
                  { required: true, message: t('validation.required', { attribute: 'field' }) },
                  { validator },

                ]}
              >
                <Input
                  placeholder="Email"
                />
              </FormItem>

              <FormItem
                label="First name"
                name="preferredName"
                fieldKey="preferredName"
              >
                <Input
                  placeholder="Name"
                />
              </FormItem>

              <FormItem
                label="Contact number"
                name="contactNumber"
                fieldKey="contactNumber"
              >
                <Input
                  placeholder="Contact number"
                />
              </FormItem>

              <FormItem
                label="Send new client a welcome email?"
                name="sendWelcomeEmail"
                valuePropName="checked"
              >
                <Switch
                  unCheckedChildren="No"
                  checkedChildren="Yes"
                />
              </FormItem>

            </>
          ))}
        </FormItem>

        <ClientRegistrationActions form={form} onGoBack={onGoBack} />
      </Form>
    </div>
  );
};

AttachToUser.propTypes = {
  stepKey: string.isRequired,
  onFinishLastStep: func,
  onGoBack: func,
};

AttachToUser.defaultProps = {
  onFinishLastStep: () => {
  },
  onGoBack: () => {
  },
};
export default AttachToUser;
