import React from 'react';
import { shape, string } from 'prop-types';
import { dealer } from '../../../Hooks/useLocalStorage';
import ClientDetails from '../Clients/ClientDetails';

const AccountClientDetails = ({ title, client, creditRating }) => client && (
<ClientDetails
  title={title}
  client={client}
  additionalFields={(dealer() === 'znz' && [
    { label: 'Credit rating', value: creditRating },
  ]) || []}
/>
);

AccountClientDetails.propTypes = {
  client: shape({}).isRequired,
  creditRating: string,
  title: string,
};

AccountClientDetails.defaultProps = {
  creditRating: undefined,
  title: 'Borrower details',
};

export default AccountClientDetails;
