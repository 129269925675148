import React from 'react';
import { func } from 'prop-types';
import Modal from '../../../Atoms/Feedback/Modal/Modal';
import Text from '../../../Atoms/Typography/Text/Text';
import { OFFER_DECLINED_MESSAGE } from '../../../Resources/OfferMessage';

const LoanOfferDeclined = ({ modalClose }) => (
  <Modal open title={OFFER_DECLINED_MESSAGE.title} onOk={modalClose} cancelText="" onCancel={modalClose}>
    <Text>{OFFER_DECLINED_MESSAGE.message}</Text>
  </Modal>
);

LoanOfferDeclined.propTypes = {
  modalClose: func.isRequired,
};

export default LoanOfferDeclined;
