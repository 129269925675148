import React from 'react';
import { bool, string } from 'prop-types';
import Input from '../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import MaskedInput from '../../../Atoms/Forms/Input/MaskedInput';
import Text from '../../../Atoms/Typography/Text/Text';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Link from '../../../Atoms/Navigation/Links/Link';
import Title from '../../../Atoms/Typography/Title/Title';
import './BankAccountNumber.less';

const BankAccountNumber = (props) => {
  const { country, editable } = props;
  const isAu = country === 'AU';

  return (
    <div className="theme-bank-account-number">
      <Title level={2}>Bank account details</Title>
      { isAu && (
        <Text type="comment">
          To add an overseas bank account, please email
          {' '}
          <Link href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>{process.env.REACT_APP_BRAND_EMAIL}</Link>
        </Text>
      )}
      <InputGroup>
        <FormItem
          label="Name"
          name="bankAccountName"
        >
          <Input
            placeholder="Name"
            disabled={!editable}
          />
        </FormItem>

        { isAu && (
        <FormItem
          label="BSB"
          name="bankAccountBsb"
        >
          <MaskedInput
            mask="000-000"
            maskOptions={{ placeholderChar: '0', lazy: true }}
            disabled={!editable}
          />
        </FormItem>
        ) }

        <FormItem
          label="Account number"
          name="bankAccountNumber"
        >
          { isAu
            ? <Input disabled={!editable} />
            : (
              <MaskedInput
                mask="00-0000-0000000-000"
                maskOptions={{ placeholderChar: '0', lazy: true }}
                disabled={!editable}
              />
            )
          }
        </FormItem>
      </InputGroup>

    </div>
  );
};

BankAccountNumber.propTypes = {
  country: string.isRequired,
  editable: bool,
};

BankAccountNumber.defaultProps = {
  editable: false,
};

export default BankAccountNumber;
