import React from 'react';
import { number, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import { getContextLoanState } from '../../Helpers/Helpers';
import Header from '../../Molecules/Header/Header';
import './LoanHeader.less';

const LoanHeader = (props) => {
  const {
    state,
    loanId: referenceId,
    ...otherProps
  } = props;

  const { context } = useParams();
  const contextLoanState = getContextLoanState(context, state);

  return <Header state={contextLoanState} referenceId={referenceId} {...otherProps} />;
};

LoanHeader.propTypes = {
  amount: number.isRequired,
  headerImage: string,
  loanId: string.isRequired,
  metaTitle: string,
  name: string,
  state: string.isRequired,
};

LoanHeader.defaultProps = {
  headerImage: '',
  metaTitle: '',
  name: '',
};

export default LoanHeader;
