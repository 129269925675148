import React from 'react';
import { shape, string } from 'prop-types';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import { titleCase } from '../../../Hooks/useStringFormatter';

const InvestorDetails = ({
  client: { name, type },
}) => {
  const fields = [
    { label: 'Name', value: name },
    { label: 'Entity type', value: titleCase(type) },
  ];
  return (
    <Card
      title={(<Title level={4}>Investor details</Title>)}
    >
      <Fields fields={fields} />
    </Card>
  );
};

InvestorDetails.propTypes = {
  client: shape({
    name: string,
    type: string,
  }).isRequired,
};

export default InvestorDetails;
