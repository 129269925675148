import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { STATIC_DOCUMENT } from '../../GraphQL/Queries/Document';
import Result from '../../Atoms/Feedback/Result/Result';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import Pdf from '../../Atoms/DataDisplay/Pdf/Pdf';
import { snakeToCamel } from '../../Helpers/Helpers';
import PdfDownload from '../../Atoms/Forms/PdfDownload/PdfDownload';

const StaticDocument = () => {
  const { documentKey: rawDocumentKey } = useParams();
  const documentKey = snakeToCamel(rawDocumentKey);

  const { error, loading, data } = useQuery(STATIC_DOCUMENT, { variables: { documentKey } });

  const { file } = data?.staticDocument || { file: { path: null, url: null } };
  const { url } = file;

  if (error || (!loading && !url)) {
    return (<Result status="404" title="Sorry, the document was not found!" />);
  }

  return (
    <div id="LegalDocsPage" className="react-page">
      <Skeleton loading={loading}>
        { file && (<PdfDownload file={file} />)}
        <Pdf path={url} />
      </Skeleton>
    </div>
  );
};

export default StaticDocument;
