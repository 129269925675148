import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import {
  AppstoreAddOutlined,
  SettingOutlined,
  ShrinkOutlined,
  RiseOutlined,
  FallOutlined,
  PieChartOutlined,
  FileOutlined,
  UserSwitchOutlined,
  UserOutlined,
  UserAddOutlined,
  LoginOutlined,
  LogoutOutlined,
  DollarOutlined,
  FundViewOutlined,
  BlockOutlined,
  CheckOutlined, DashboardOutlined, AimOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import {
  userId, userRoles,
} from '../../../Store/userSlice';
import { brokerLabel } from '../../../Hooks/useRoleLabelFormatter';
import { dealer, dealerStandardInvestmentDesc } from '../../../Hooks/useLocalStorage';

const { Item, SubMenu } = Menu;

const AdminMenu = () => {
  const uid = useSelector(userId);
  const roles = useSelector(userRoles);
  const submenuPopupClass = 'theme-topheader-dev-submenu';

  const dealerAdminMenu = () => (
    <SubMenu popupClassName={submenuPopupClass} key="dealer" title="Dealer" icon={<PieChartOutlined />}>
      <SubMenu key="dealer-clients" title="Clients" icon={<UserSwitchOutlined />}>
        <Item key="/app/dealer/clients">
          <NavLink to="/app/dealer/clients">Clients</NavLink>
        </Item>
        <Item key="/app/dealer/clients/drafts">
          <NavLink to="/app/dealer/clients/drafts">Drafts</NavLink>
        </Item>
        <Item key="/app/dealer/client/add">
          <NavLink to="/app/dealer/client/add">Add</NavLink>
        </Item>
        <Item key="/app/dealer/referrals">
          <NavLink to="/app/dealer/referrals">Referrals (NZ only)</NavLink>
        </Item>
      </SubMenu>
      <Item key="/app/dealer/loans" icon={<DollarOutlined />}>
        <NavLink to="/app/dealer/loans">Loans</NavLink>
      </Item>
      <SubMenu key="dealer-clients" title="Compliance" icon={<CheckOutlined />}>
        <Item key="/app/dealer/compliance/policies">
          <NavLink to="/app/dealer/compliance/policies">Policies</NavLink>
        </Item>
        <Item key="/app/dealer/compliance/identity-verification-logs">
          <NavLink to="/app/dealer/compliance/identity-verification-logs">Identity Verification Log</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="dealer-investments" title="Investments" icon={<DollarOutlined />}>
        <Item key="/app/dealer/investments">
          <NavLink to="/app/dealer/investments">{ dealerStandardInvestmentDesc() }</NavLink>
        </Item>
        <Item key="/app/dealer/investments/secondary">
          <NavLink to="/app/dealer/investments/secondary">Secondary Market</NavLink>
        </Item>
        <Item key="/app/dealer/investments/closed">
          <NavLink to="/app/dealer/investments/closed">Closed</NavLink>
        </Item>
        <Item key="/app/dealer/investment-funds">
          <NavLink to="/app/dealer/investment-funds">Investment Funds</NavLink>
        </Item>
        <Item key="/app/dealer/reductions">
          <NavLink to="/app/dealer/reductions">Reductions</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="dealer-matcher" title="Matcher" icon={<BlockOutlined />}>
        <Item key="/app/dealer/investor-match">
          <NavLink to="/app/dealer/investor-match">Investor Match</NavLink>
        </Item>
        <Item key="/app/dealer/new-loan-to-investors">
          <NavLink to="/app/dealer/new-loan-to-investors">New Loan Prediction</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="dealer-documents" title="Documents" icon={<FileOutlined />}>
        <Item key="/app/dealer/documents">
          <NavLink to="/app/dealer/documents">Documents</NavLink>
        </Item>
        <Item key="/app/dealer/document/add">
          <NavLink to="/app/dealer/document/add">Add</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="reports" title="Reports" icon={<FundViewOutlined />}>
        <Item key="/app/dealer/reporting/tax-statements">
          <NavLink to="/app/dealer/reporting/tax-statements">Tax Statement</NavLink>
        </Item>
        <Item key="/app/dealer/reporting/investment-certificates">
          <NavLink to="/app/dealer/reporting/investment-certificates">Investment Certificate</NavLink>
        </Item>
        <Item key="/app/dealer/reporting/investment-statements">
          <NavLink to="/app/dealer/reporting/investment-statements">Investment Statement</NavLink>
        </Item>
        <Item key="/app/dealer/reporting/investments-summary">
          <NavLink to="/app/dealer/reporting/investments-summary">Investments Summary</NavLink>
        </Item>
      </SubMenu>
      <Item key="/app/dealer/referrals" icon={<UserAddOutlined />}>
        <NavLink to="/app/dealer/referrals">Referrals</NavLink>
      </Item>
      <Item key="/app/dealer/users" icon={<UserSwitchOutlined />}>
        <NavLink to="/app/dealer/users">Users</NavLink>
      </Item>
    </SubMenu>
  );

  const investorAdminMenu = () => (
    <SubMenu popupClassName={submenuPopupClass} key="investor" title="Investor" icon={<RiseOutlined />}>
      <Item key="/app/investor/dashboard" icon={<DashboardOutlined />}>
        <NavLink to="/app/investor/dashboard">Dashboard</NavLink>
      </Item>
      <SubMenu key="investor-clients" title="Clients" icon={<UserSwitchOutlined />}>
        <Item key="/app/investor/clients">
          <NavLink to="/app/investor/clients">Clients</NavLink>
        </Item>
        <Item key="/app/investor/clients/drafts">
          <NavLink to="/app/investor/clients/drafts">Drafts</NavLink>
        </Item>
        <Item key="/app/investor/client/add">
          <NavLink to="/app/investor/client/add">Add</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="investor-investments" title="Investments" icon={<DollarOutlined />}>
        <Item key="/app/investor/investments">
          <NavLink to="/app/investor/investments">Investments</NavLink>
        </Item>
        <Item key="/app/investor/investments/secondary">
          <NavLink to="/app/investor/investments/secondary">Secondary Market</NavLink>
        </Item>
        <Item key="/app/investor/investments/closed">
          <NavLink to="/app/investor/investments/closed">Closed</NavLink>
        </Item>
      </SubMenu>
      <Item key="/app/investor/opportunities" icon={<AimOutlined />}>
        <NavLink to="/app/investor/opportunities">Opportunities</NavLink>
      </Item>
      <SubMenu popupClassName={submenuPopupClass} key="investor-loans" title="Loans" icon={<DollarOutlined />}>
        <Item key="/app/investor/search">
          <NavLink to="/app/investor/search">Available opportunities</NavLink>
        </Item>
        <Item key="/app/investor/loan-book">
          <NavLink to="/app/investor/loan-book">Loan book</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="investor-documents" title="Documents" icon={<FileOutlined />}>
        <Item key="/app/investor/documents">
          <NavLink to="/app/investor/documents">Documents</NavLink>
        </Item>
        <Item key="/app/investor/legal-docs">
          <NavLink to="/app/investor/legal-docs">Legal Docs</NavLink>
        </Item>
      </SubMenu>
      <Item key="/app/investor/preferences" icon={<SettingOutlined />}>
        <NavLink to="/app/investor/preferences">Preferences</NavLink>
      </Item>
    </SubMenu>
  );

  const borrowerAdminMenu = () => (
    <SubMenu popupClassName={submenuPopupClass} key="borrower" title="Borrower" icon={<FallOutlined />}>
      <Item key="/app/borrower/dashboard" icon={<DashboardOutlined />}>
        <NavLink to="/app/borrower/dashboard">Dashboard</NavLink>
      </Item>
      <SubMenu key="borrower-clients" title="Clients" icon={<UserSwitchOutlined />}>
        <Item key="/app/borrower/clients">
          <NavLink to="/app/borrower/clients">Clients</NavLink>
        </Item>
        <Item key="/app/borrower/clients/drafts">
          <NavLink to="/app/borrower/clients/drafts">Drafts</NavLink>
        </Item>
        <Item key="/app/borrower/client/add">
          <NavLink to="/app/borrower/client/add">Add</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="borrower-loans" title="Loans/Applications" icon={<DollarOutlined />}>
        <Item key="/app/borrower/applications">
          <NavLink to="/app/borrower/applications">Applications</NavLink>
        </Item>
        <Item key="/app/borrower/applications/drafts">
          <NavLink to="/app/borrower/applications/drafts">Drafts</NavLink>
        </Item>
        <Item key="/app/borrower/loans">
          <NavLink to="/app/borrower/loans">Loans</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} key="borrower-documents" title="Documents" icon={<FileOutlined />}>
        <Item key="/app/borrower/documents">
          <NavLink to="/app/borrower/documents">Documents</NavLink>
        </Item>
        <Item key="/app/borrower/legal-docs">
          <NavLink to="/app/borrower/legal-docs">Legal Docs</NavLink>
        </Item>
      </SubMenu>
    </SubMenu>
  );

  const brokerAdminMenu = () => (
    <SubMenu popupClassName={submenuPopupClass} key="broker" title={brokerLabel()} icon={<ShrinkOutlined />}>
      <Item key="/app/broker/dashboard" icon={<DashboardOutlined />}>
        <NavLink to="/app/broker/dashboard">Dashboard</NavLink>
      </Item>
      <SubMenu title="Clients" icon={<UserSwitchOutlined />}>
        <Item key="/app/broker/clients">
          <NavLink to="/app/broker/clients">Clients</NavLink>
        </Item>
        <Item key="/app/broker/clients/drafts">
          <NavLink to="/app/broker/clients/drafts">Drafts</NavLink>
        </Item>
        <Item key="/app/broker/client/add">
          <NavLink to="/app/broker/client/add">Add</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} title="Loans/Applications" icon={<DollarOutlined />}>
        <Item key="/app/broker/applications">
          <NavLink to="/app/broker/applications">Applications</NavLink>
        </Item>
        <Item key="/app/broker/applications/drafts">
          <NavLink to="/app/broker/applications/drafts">Drafts</NavLink>
        </Item>
      </SubMenu>
      <SubMenu popupClassName={submenuPopupClass} title="Documents" icon={<FileOutlined />}>
        <Item key="/app/broker/documents">
          <NavLink to="/app/broker/documents">Documents</NavLink>
        </Item>
      </SubMenu>
      <Item key="/app/broker/referrals" icon={<UserAddOutlined />}>
        <NavLink to="/app/broker/referrals">Referrals</NavLink>
      </Item>
    </SubMenu>
  );

  return (
    <>
      <Menu mode="horizontal" className="navbar navbar-default react-top-header">
        <SubMenu popupClassName={submenuPopupClass} key="app" title="App" icon={<AppstoreAddOutlined />}>
          <Item key="/app/login" icon={<LoginOutlined />}>
            <NavLink to="/app/login">Login</NavLink>
          </Item>
          <Item key="/app/logout" icon={<LogoutOutlined />}>
            <NavLink to="/app/logout">Logout</NavLink>
          </Item>
          <Item key="/app/join" icon={<UserAddOutlined />}>
            <NavLink to="/app/join">Join</NavLink>
          </Item>
          <Item key="/app/profile" icon={<UserOutlined />}>
            <NavLink to="/app/profile">My profile</NavLink>
          </Item>
        </SubMenu>
        {dealerAdminMenu()}
        {investorAdminMenu()}
        {borrowerAdminMenu()}
        {brokerAdminMenu()}
        <Item key="/app/settings" icon={<SettingOutlined />}>
          {`Dealer: ${dealer()}`}
          {' || '}
          {`User ID: ${uid}`}
          {' || '}
          {`Roles: ${roles}`}
        </Item>
      </Menu>
    </>
  );
};

export default withRouter(AdminMenu);
