import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import Text from '../../Atoms/Typography/Text/Text';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import { getBrand } from '../../Hooks/useLocalStorage';
import { getPreferredName } from '../../Store/userSlice';

const HeaderTitleDashBoard = ({ context, brand }) => {
  const config = [
    {
      brand: 'zagga',
      role: 'borrower',
      body: (
        <>
          <Text>
            This page allows you to easily and securely manage your account, borrowing activity and view your
            loans.
          </Text>
          <Paragraph>
            To begin, please go to my Registrations under the my Account tab above to register a new investment
            entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'zagga',
      role: 'investor',
      body: (
        <>
          <Text>
            Access this page to manage your account, investment activity and view all loan opportunities offered or funded.
          </Text>
          <Paragraph>
            To begin, please go to my Registration to register an entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'zagga',
      role: 'broker',
      body: (
        <>
          <Text>
            To begin, please click on My Clients to register a new entity.
          </Text>
          <Paragraph>
            A loan application can be lodged once the Client is registered.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'znz',
      role: 'borrower',
      body: (
        <>
          <Text>
            This page allows you to easily and securely manage your account, borrowing activity and view your loans.
          </Text>
          <Paragraph>To begin, please go to my Registrations under the my Account tab above to register a new investment entity.</Paragraph>
        </>
      ),
    },
    {
      brand: 'znz',
      role: 'investor',
      body: (
        <>
          <Text>
            Access this page to manage your account, investment activity and opportunities offered or funded.
          </Text>
          <Paragraph>
            To begin, please go to my Registrations under the my Account tab above to register a new investment entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'znz',
      role: 'broker',
      body: (
        <>
          <Text>
            This page allows you to easily and securely manage your account.
          </Text>
          <Paragraph>
            To begin, please go to my Registration to register an entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'apm',
      role: 'borrower',
      body: (
        <>
          <Text>
            This page allows you to easily and securely manage your account, borrowing activity and view your loans.
          </Text>
          <Paragraph>
            To begin, please go to my Registration to register an entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'apm',
      role: 'investor',
      body: (
        <>
          <Text>
            Access this page to manage your account, investment activity and view all loan opportunities offered or funded.
          </Text>
          <Paragraph>
            To begin, please go to my Registration to register an entity.
          </Paragraph>
        </>
      ),
    },
    {
      brand: 'apm',
      role: 'broker',
      body: (
        <>
          <Text>
            To begin, please click on My Clients to register a new entity.
          </Text>
          <Paragraph>
            A loan application can be lodged once the Client is registered.
          </Paragraph>
        </>
      ),
    },
  ];

  // eslint-disable-next-line react/prop-types
  const WelcomeMsg = ({ body }) => (
    <Text>
      Welcome to my
      {' '}
      { process.env.REACT_APP_BRAND }
      .
      {' '}
      {' '}
      {body}
    </Text>
  );

  const filterByBrandRole = (brandName, filterRole) => config
    .filter(item => item.brand === brandName)
    .filter(item => item.role === filterRole);

  const filteredConfig = filterByBrandRole(brand, context).at(0);
  const filteredBody = filteredConfig?.body;

  return (
    <HeaderTitle
      title={`Hi ${useSelector(getPreferredName)}`}
      comment={<WelcomeMsg body={filteredBody} />}
    />
  );
};

HeaderTitleDashBoard.propTypes = {
  brand: string,
  context: string.isRequired,
};

HeaderTitleDashBoard.defaultProps = {
  brand: getBrand(),
};

export default HeaderTitleDashBoard;
