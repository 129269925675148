import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import PropTypes, {
  arrayOf, bool, func, oneOfType, string,
} from 'prop-types';
import Select from '../../../../Atoms/Forms/Select/Select';
import { ACCOUNT_OPTIONS } from '../../../../GraphQL/Queries/Account';

const AccountSelect = (props) => {
  const {
    disabled, label, name, options, placeHolder, uuid: uuidProps, accountType,
  } = props;
  const { uuid: uuidParams } = useParams();
  const uuid = uuidProps ?? uuidParams;
  const types = accountType ? { types: [accountType] } : {};
  const [getAccounts, { loading, data }] = useLazyQuery(ACCOUNT_OPTIONS, {
    notifyOnNetworkStatusChange: true,
  });
  const debouncer = AwesomeDebouncePromise(getAccounts, 750);

  useEffect(() => {
    getAccounts({
      variables: uuid
        ? { uuids: [uuid] }
        : { search: '', ...types },
    }).then();
  }, [uuid]);

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={uuid}
      rules={[{ required: true, message: 'This field is required' }]}
    >
      <Select
        placeholder={placeHolder}
        allowClear
        disabled={disabled}
        showSearch
        onSearch={search => debouncer({ variables: { search, ...types } })}
        options={options ? options?.(data?.accounts?.results) : data?.accounts?.results}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent={loading ? 'Loading' : 'No results matching'}
      />
    </Form.Item>
  );
};

AccountSelect.propTypes = {
  disabled: bool,
  label: string,
  name: oneOfType([string, arrayOf(string)]),
  options: func,
  placeHolder: string,
  uuid: string,
  accountType: PropTypes.oneOf(['loan', 'investment']),
};

AccountSelect.defaultProps = {
  disabled: false,
  label: undefined,
  name: 'account',
  options: undefined,
  placeHolder: undefined,
  uuid: undefined,
  accountType: null,
};

export default AccountSelect;
