import React from 'react';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../../Atoms/Feedback/Modal/Modal';
import Text from '../../../Atoms/Typography/Text/Text';
import { OFFER_ACCEPTED_MESSAGE } from '../../../Resources/OfferMessage';
import { userUuid } from '../../../Store/userSlice';

const LoanOfferAccepted = ({ modalClose }) => {
  const isLoggedIn = useSelector(userUuid);
  return (
    <Modal open title={OFFER_ACCEPTED_MESSAGE.title} onOk={modalClose} okText={isLoggedIn ? 'OK' : 'Activate my account'} cancelText="" onCancel={modalClose}>
      <Text>{OFFER_ACCEPTED_MESSAGE.message}</Text>
    </Modal>
  );
};

LoanOfferAccepted.propTypes = {
  modalClose: func.isRequired,
};

export default LoanOfferAccepted;
