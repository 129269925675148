import { capitalize } from '../Helpers/Helpers';
import { entitiesToTree } from './useEntity';
import { snakeCaseToCamelCase } from './useStringFormatter';
import {
  extractDocument,
  filterByProperties,
  filterChildren,
  findChild,
  preprocessInputData,
} from './useCommonConvertedEntityData';
import { dealer } from './useLocalStorage';

const extractClientDocument = (files, categories) => extractDocument(files, categories, dealer() !== 'znz' ? 'internal' : 'private');

/**
 * Client GraphQl input conversion from entity tree.
 */
const useClientConvertedInputData = (dataList, dealerCountry) => {
  const clientInputFields = {
    company: {
      client: ['businessNumber', 'companyNumber', 'name'],
      borrower: ['address', 'website'],
      broker: ['address', 'website'],
      investor: ['address'],
    },
    individual: {
      client: ['contactNumber', 'dateOfBirth', 'email', 'firstName', 'lastName', 'middleName', 'personFatca'],
      borrower: [],
      investor: [],
    },
    individuals: {
      client: ['name'],
      borrower: [],
      investor: ['address'],
    },
    partnership: {
      client: ['name'],
      borrower: ['website'],
      investor: ['address', 'businessNumber', 'partnershipType'],
    },
    trust: {
      client: ['name', 'trusteeType', 'trustType'],
      borrower: ['corporateBusinessNumber', 'corporateCompanyNumber', 'corporateEntityName'],
      investor: ['corporateBusinessNumber', 'corporateCompanyNumber', 'corporateEntityName'],
    },
  };

  const actingType = (type, role) => type + capitalize(role);

  // Everything in onboarding code refers to 'introducer'
  // but Fintech still refers exclusively to 'broker'. We really should tidy
  // this up, but for now, just convert before processing for GQL.
  const convertIntroducerRole = (entities) => {
    const convertRoleToBroker = ({ role, ...other }) => {
      if (!role) {
        return other;
      }
      if (role === 'introducer') {
        return { role: 'broker', ...other };
      }
      return { role, ...other };
    };
    return entities.map(convertRoleToBroker);
  };

  const taxStatusData = (input) => {
    const {
      rwtRate, nrwtRate, ird, country, countryTaxes,
    } = input;

    return {
      countryTaxes: [
        {
          country: country || dealerCountry,
          taxCode: rwtRate || nrwtRate,
          taxNumber: ird,
        },
        ...(countryTaxes || []),
      ],
    };
  };

  const identificationData = (identificationPage) => {
    const {
      children, errors, id, name, parentId, stepKey: sk1, subStepKey: sk2, personVerification, ...details
    } = identificationPage;
    const {
      selected,
      stepKey,
      subStepKey,
      birthCertificateDocument,
      citizenshipDocument,
      driverLicenceDocument,
      foreignPassportDocument,
      passportDocument,
      ...verificationData
    } = personVerification;
    return {
      [snakeCaseToCamelCase(selected)]: {
        details,
        ...verificationData,
      },
    };
  };

  const extractClientInputProperties = input => filterByProperties(input, clientInputFields[input.type || input.entityBundle].client);

  const extractActingClientInputProperties = input => filterByProperties(input, clientInputFields[input.type][input.role]);

  const extractInvestorTaxProperties = (input) => {
    const {
      bankAccountName,
      bankAccountBsb,
      bankAccountNumber: accountNumber,
    } = input;
    const bankAccountNumber = (accountNumber && (`${((bankAccountBsb || '') && `${bankAccountBsb} `)}${accountNumber}`));
    return {
      bankAccountName,
      bankAccountNumber,
    };
  };

  const documentOutput = (entityPage) => {
    const { children } = entityPage;

    const taxPage = findChild(children, 'declare-tax-status-form');
    const certificateOfExemption = taxPage?.certificateOfExemption;

    const trustPage = findChild(children, 'upload-trust-deed-form');
    const trustDeedUpload = trustPage?.trustDeed?.trustDeedUpload;

    const identityPage = findChild(children, null, 'identity-verification-form')?.personVerification;
    const birthCertificateDocument = identityPage?.birthCertificateDocument;
    const citizenshipDocument = identityPage?.citizenshipDocument;
    const driverLicenceDocument = identityPage?.driverLicenceDocument;
    const foreignPassportDocument = identityPage?.foreignPassportDocument;
    const passportDocument = identityPage?.passportDocument;

    const investorStatusPage = findChild(children, 'verification-of-investor-status-form');
    const investorVerificationDocuments = investorStatusPage?.investorStatus?.investorVerificationDocuments;

    return [
      extractClientDocument(certificateOfExemption, ['certificate_of_exemption']),
      extractClientDocument(birthCertificateDocument, ['identification']),
      extractClientDocument(citizenshipDocument, ['identification']),
      extractClientDocument(driverLicenceDocument, ['identification']),
      extractClientDocument(foreignPassportDocument, ['identification']),
      extractClientDocument(passportDocument, ['identification']),
      extractClientDocument(trustDeedUpload, ['trust_deed']),
      extractClientDocument(investorVerificationDocuments, ['investor_status_verification']),
    ].filter(doc => !!doc);
  };

  const clientIndividualOutput = (data) => {
    const {
      children,
      entityType,
      entityBundle,
      errors,
      id,
      name: inputName,
      parentId,
      stepKey,
      subStepKey,
      ...clientFields
    } = data;

    const identificationForm = findChild(children, null, 'identity-verification-form');

    const identification = identificationData(identificationForm);
    const documents = documentOutput(data);

    return {
      identification,
      ...clientFields,
      ...((documents && { documents }) || {}),
    };
  };

  const clientOutput = (clientData) => {
    const { entityBundle, children } = clientData;

    if (entityBundle === 'individual') {
      return clientIndividualOutput(clientData);
    }

    const clientRawData = extractClientInputProperties(clientData);
    const documents = documentOutput(clientData);

    const members = {
      add: [
        ...filterChildren(children, null, 'person-form').map(item => ({ individual: clientOutput(item) })),
        ...filterChildren(children, null, 'company-verification-form').map(item => ({ company: clientOutput(item) }))],
    };

    return {
      members,
      ...clientRawData,
      ...((documents && { documents }) || {}),
    };
  };

  const extractInvestorClassification = (investorStatusPage) => {
    const { investorStatus: { investorClassification } } = investorStatusPage;
    return (investorClassification && { investorClassification }) || {};
  };

  const actingClientInvestorFieldOutput = (role, taxProperties, investorStatus) => (
    role === 'investor' && {
      investor: {
        ...extractInvestorTaxProperties(taxProperties),
        ...taxStatusData(taxProperties),
        ...(investorStatus && extractInvestorClassification(investorStatus)) || {},
      },
    }
  ) || {};

  const actingClientFieldOutput = (role, taxProperties, investorStatus, rawActingProperties) => ({
    ...((rawActingProperties && extractActingClientInputProperties(rawActingProperties)) || {}),
    ...((taxProperties && actingClientInvestorFieldOutput(role, taxProperties, investorStatus)) || {}),
  });

  const actingClientOutput = (data) => {
    const {
      id, stepKey, entityType, entityBundle, errors, ...dataTree
    } = data;

    const { type, role, children } = dataTree;

    const clientEntity = (type === 'individual') ? findChild(children, null, 'person-form') : dataTree;
    const client = clientOutput(clientEntity);

    const { children: clientChildren } = clientEntity;
    const taxChild = findChild(clientChildren, 'declare-tax-status-form');
    const investorStatusChild = findChild(clientChildren, 'verification-of-investor-status-form');
    const actingClientRawFields = (type === 'individual') ? null : dataTree;

    const userFields = findChild(children, 'attach-to-user');
    const broker = (userFields?.broker && { broker: { uuid: userFields.broker } }) || {};

    return {
      [actingType(type, role)]: {
        client,
        ...broker,
        ...actingClientFieldOutput(role, taxChild, investorStatusChild, actingClientRawFields),
      },
    };
  };

  // const policyOutput = (entities) => {
  //   const policies = [];
  //
  //   const investorStatus = findChild(entities, 'verification-of-investor-status-form');
  //   if (investorStatus) {
  //     const {
  //       investorStatus: {
  //         policy: {
  //           key: investorStatusPolicyKey,
  //         },
  //       },
  //     } = investorStatus;
  //     policies.push({ policyKey: investorStatusPolicyKey, items: [{ fieldAccepted: true }] });
  //   }
  //
  //   return policies;
  // };

  const userOutput = (userData) => {
    if (!userData) {
      return null;
    }
    const {
      id, stepKey, parentId, forUser, forUserSelect, existingUser, broker, children, ...userDetails
    } = userData;

    if (existingUser && forUser) {
      return {
        attach: {
          uuid: forUser,
        },
      };
    }

    return {
      add: userDetails,
    };
  };

  // @todo Preferably individuals would already be children of the company.
  const hackTreeForCorporateTrust = (dataTree) => {
    const accountStructure = findChild(dataTree, 'set-account-structure-form');
    if (accountStructure?.type === 'trust' && accountStructure?.trusteeType === 'corporate') {
      const { children: trustChildren, ...modifiedTrust } = accountStructure;
      const childCompany = findChild(trustChildren, 'register-and-verify-entities-form', 'company-verification-form');
      const { children: companyChildren, ...modifiedCompany } = childCompany;

      return [{
        ...modifiedTrust,
        children: [
          ...trustChildren.filter(child => child.stepKey !== 'register-and-verify-entities-form'),
          ...[{
            ...modifiedCompany,
            children: [
              ...companyChildren,
              ...filterChildren(trustChildren, '', 'person-form'),
            ],
          }],
        ],
      }];
    }
    return dataTree;
  };

  const allEntities = convertIntroducerRole(Object.values(dataList));
  const dataTree = hackTreeForCorporateTrust(entitiesToTree(allEntities));
  const accountStructure = findChild(dataTree, 'set-account-structure-form');
  const actingClient = actingClientOutput(accountStructure);
  //  const policies = policyOutput(allEntities);
  const user = userOutput(findChild(allEntities, 'attach-to-user'));

  return preprocessInputData({
    actingClient,
    //    policies,
    ...((user && { user }) || {}),
  }, dealerCountry);
};

export default useClientConvertedInputData;
