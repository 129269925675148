import React from 'react';
import { string } from 'prop-types';
import { Link, Route } from 'react-router-dom';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import { unstrailedUrl } from '../../Helpers/Helpers';

const LoanFundingButton = ({
  url,
}) => (
  <Route
    exact
    path="/app/:context/application/:loanUuid"
    component={() => (
      <ButtonGroup direction="bottom-right">
        <Link
          to={`${unstrailedUrl(url)}/fund`}
          className="ant-btn-primary theme-button"
        >
          Fund this loan
        </Link>
      </ButtonGroup>
    )}
  />
);


LoanFundingButton.propTypes = {
  url: string.isRequired,
};

export default LoanFundingButton;
