import React from 'react';
import {
  arrayOf, bool, func, number, oneOf, string,
} from 'prop-types';
import Dragger from '../../../Atoms/Forms/Dragger/Dragger';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { setNestedProp } from '../../../Helpers/Object/Object';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import './DraggerFormItem.less';

const DraggerFormItem = ({
  comment, fieldName, label, rules, shouldUpdateComponent, maxCount,
}) => {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <FormItem
      shouldUpdate={shouldUpdateComponent}
    >
      {({ getFieldValue, setFieldsValue }) => (
        <FormItem
          className="theme-form-item-wrapper theme-form-dragger"
          getValueFromEvent={normFile}
          label={label}
          name={fieldName}
          rules={rules}
          valuePropName="fileList"
          validateStatus={useValidationRules.validateStatus(typeof fieldName === 'string' ? [fieldName] : fieldName, getFieldValue('errors'))}
        >
          <Dragger
            comment={comment}
            className="theme-dragger"
            maxCount={maxCount}
            defaultFileList={getFieldValue(fieldName)?.map(({ fid, name, status }) => ({
              uid: fid,
              name,
              fid,
              status: status || 'done',
            })) || []}
            setFileField={newValue => setFieldsValue(setNestedProp({}, fieldName, newValue))}
          />
        </FormItem>
      )}
    </FormItem>
  );
};

DraggerFormItem.propTypes = {
  comment: string,
  fieldName: string.isRequired,
  label: string,
  rules: arrayOf(func),
  shouldUpdateComponent: oneOf([func, bool]),
  maxCount: number,
};

DraggerFormItem.defaultProps = {
  comment: 'Please upload a copy of the selected identification.',
  label: 'Upload document',
  rules: [],
  shouldUpdateComponent: true,
  maxCount: 1,
};

export default DraggerFormItem;
