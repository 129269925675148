import React from 'react';
import { useQuery } from '@apollo/client';
import { shape, string } from 'prop-types';
import { DOCUMENT } from '../../GraphQL/Queries/Document';
import Pdf from '../../Atoms/DataDisplay/Pdf/Pdf';
import PdfDownload from '../../Atoms/Forms/PdfDownload/PdfDownload';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';

const DocumentViewPage = ({ match: { params } }) => {
  const { mediaUuid: uuid, fileUuid } = params;
  const { data, loading, error } = useQuery(DOCUMENT, { variables: { uuid } });

  const file = data?.document?.fieldMediaFiles?.filter(item => item.uuid === fileUuid).pop();

  if (error) {
    return (
      <div>Sorry, a problem occurred retrieving this file.</div>
    );
  }

  return (
    <Skeleton loading={loading} paragraph={3}>
      { file && (<PdfDownload file={file} />)}
      { file && (<Pdf path={file?.url} />)}
    </Skeleton>
  );
};

DocumentViewPage.propTypes = {
  match: shape({
    params: shape({
      mediaUuid: string.isRequired,
      fileUuid: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DocumentViewPage;
