import React from 'react';
import Modal from '../../../Components/Common/Modal';

const DocumentStatus = ({ statusMessage, setStatusMessage }) => statusMessage && (
<Modal
  title={statusMessage.title}
  message={statusMessage.message}
  dismissAction={() => {
    if (statusMessage.title === 'Success') {
      window.location = '/app/dealer/documents';
    }

    return setStatusMessage('');
  }}
/>
);

export default DocumentStatus;
