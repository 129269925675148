import React, { useState } from 'react';
import NoResult from '../../../Molecules/NoResult/NoResult';
import loadMoreItems from '../../../Helpers/LoadMoreList';
import InvestmentPanel from '../../../Components/Common/InvestmentPanel';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';

const InvestmentList = (props) => {
  const [itemList, setItemList] = useState([]);
  const [itemUuid, setItemUuid] = useState('');

  const {
    page,
    items,
    context,
    status,
    showFund,
    noResultTitle,
    noResultMessage,
  } = props;

  const loadMore = loadMoreItems(itemList, itemUuid, items, page);
  if (loadMore !== null) {
    setItemUuid(loadMore.uuid);
    setItemList(loadMore.list);
  }

  if (itemList.length === 0 && status === 'loading') {
    return (
      <Skeleton loading={status} />
    );
  }

  if (itemList.length === 0) {
    return (
      <NoResult
        title={noResultTitle || 'No investments found.'}
        body={noResultMessage || (context !== 'borrower' && 'Please try a different filter.')}
      />
    );
  }

  return itemList.map(account => (
    <InvestmentPanel
      key={account.uuid}
      investment={account}
      context={context}
      loan={account.fieldLoan}
      showFund={showFund}
    />
  ));
};

export default InvestmentList;
