import React from 'react';
import {
  arrayOf, func, shape, string,
} from 'prop-types';
import { Form } from 'antd';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import Company from '../../../Molecules/Forms/Company/Company';
import EntitiesSelector from '../../../Molecules/EntitiesSelector/EntitiesSelector';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import { getParentId } from '../../../Store/clientRegistrationSlice';

const CompanyVerification = ({
  companies, onChangeSelected, stepKey, subStepKey, onFinish, onGoBack,
}) => {
  const parentId = useSelector(getParentId(subStepKey));
  const [form] = Form.useForm();
  const onChange = (value) => {
    onChangeSelected(companies.find(company => company.id === value));
  };


  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      name="CompanyVerificationForm"
    >
      {companies.length > 0 && (
        <EntitiesSelector
          label="I am registering"
          allowNew={false}
          entities={companies}
          onChange={onChange}
          parents="company"
        />
      )}
      <StepInfo id={uuid()} stepKey={stepKey} subStepKey={subStepKey} parentId={parentId} />
      <Company parents="company" />
      <ClientRegistrationActions form={form} onGoBack={onGoBack} />
    </Form>
  );
};

CompanyVerification.propTypes = {
  onGoBack: func,
  onFinish: func,
  stepKey: string.isRequired,
  subStepKey: string.isRequired,
  companies: arrayOf(shape({})),
  onChangeSelected: func,
};

CompanyVerification.defaultProps = {
  onGoBack: () => {},
  onFinish: () => {},
  companies: [],
  onChangeSelected: () => {},
};

export default CompanyVerification;
