import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { useHistory } from 'react-router';
import LoanFilter, { loanListGetLoans, useDebouncedLoanFilter } from './Components/LoanFilter';
import NoResult from '../../Molecules/NoResult/NoResult';
import BorrowerApplicationsHeader from '../../Molecules/HeaderTitle/BorrowerApplicationsHeader';
import BrokerApplicationsHeader from '../../Molecules/HeaderTitle/BrokerApplicationsHeader';
import LoanList from './Components/LoanList';
import useLoanProductGroups from '../../Hooks/useLoanProductGroups';
import Tabs from '../../Atoms/DataDisplay/Tabs/Tabs';
import TabPane from '../../Atoms/DataDisplay/TabPane/TabPane';
import ApplicationDrafts from '../../Organisms/Accounts/ApplicationDrafts';

const OldLoanApplications = (props) => {
  const { match: { params: { context } } } = props;
  const { data: searchProductOptions } = useLoanProductGroups(['pending', 'assessed', 'accepted', 'funding']);

  const {
    searchProductGroup,
    searchState,
    searchText,
    pageNumber,
    pageSize,
    setSearchText,
    setSearchProductGroup,
    setSearchState,
    setPageNumber,
    setContext,
    search,
  } = useDebouncedLoanFilter(loanListGetLoans, context === 'dealer', true, 'pending,assessed,accepted,funding');

  const clearFilter = () => setSearchText('') && setSearchProductGroup('') && setSearchState('pending,assessed,accepted,funding');

  useEffect(() => {
    setContext(context);
  }, [context, setContext]);

  const noResult = (
    <NoResult
      title="No loan applications found."
      body={(context !== 'borrower' && 'Please try a different filter.')}
    />
  );

  return (
    <>
      <LoanFilter
        clearFilter={clearFilter}
        context={context}
        setSearchText={setSearchText}
        setSearchProductGroup={setSearchProductGroup}
        setSearchState={setSearchState}
        setPageNumber={setPageNumber}
        searchProductGroup={searchProductGroup}
        searchProductOptions={searchProductOptions}
        searchState={searchState}
        searchStateContext="application"
        searchText={searchText}
      />
      <LoanList
        context={context}
        noResult={noResult}
        page={pageNumber}
        pageSize={pageSize}
        rateFieldName="fieldRate"
        search={search}
        setPage={setPageNumber}
      />
    </>

  );
};

const LoanApplications = (props) => {
  const { match: { params: { context, tabs } } } = props;
  const history = useHistory();

  const headerComponents = {
    borrower: BorrowerApplicationsHeader,
    broker: BrokerApplicationsHeader,
  };

  const Header = headerComponents[context];

  return (
    <div className="theme-loan-applications page-inner">
      <Header />
      <Tabs
        defaultActiveKey={tabs || 'applications'}
        type="card"
        onChange={active => (active === 'drafts' ? history.push(`/app/${context}/applications/${active}`) : history.push(`/app/${context}/applications`))}
      >
        <TabPane tab="Applications/Loans" key="applications">
          <OldLoanApplications {...props} />
        </TabPane>
        <TabPane tab="Saved drafts" key="drafts">
          <ApplicationDrafts />
        </TabPane>
      </Tabs>
    </div>
  );
};

LoanApplications.propTypes = {
  match: shape({
    params: shape({
      context: string.isRequired,
      tabs: string,
    }).isRequired,
  }).isRequired,
};

export default LoanApplications;
