import React, { useContext, useEffect, useState } from 'react';
import {
  bool, arrayOf, string, number, func, shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import EntityConfirmation from '../../EntityConfirmation/EntityConfirmation';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import PersonVerification from '../PersonVerification/PersonVerification';
import LangContext from '../../../Context/Lang/LangContext';
import CompanyVerification from '../CompanyVerification/CompanyVerification';
import FormContext from '../../../Context/Form/FormContext';
import {
  setEntity,
  getSelected,
  setSelected,
  getSelectedRole,
  getClientStructureStepData, trustCompanyCreated, getSubStep, setReduxSubStep, getAllStepEntities,
} from '../../../Store/clientRegistrationSlice';
import PersonForm from '../../../Molecules/Forms/Person/PersonForm';

const RegisterAndVerifyEntities = ({
  onProceed, onFinishLastStep, steps, onGoBack, stepKey, isLastStep,
}) => {
  const dispatch = useDispatch();
  const reduxSubStep = useSelector(getSubStep);
  const { t } = useContext(LangContext);

  // TODO context should be removed in favour if redux.
  const {
    updateStepCompleteStatus,
  } = useContext(FormContext);

  const [subSteps, setSubSteps] = useState(steps);
  const [subStep, setSubStep] = useState(reduxSubStep);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [comment, setComment] = useState(null);
  const selected = useSelector(getSelected);
  const clientStructureStepData = useSelector(getClientStructureStepData);
  const haveTrustCompany = useSelector(trustCompanyCreated);
  const entities = useSelector(getAllStepEntities);

  const onGoBackLocal = () => {
    // eslint-disable-next-line no-unused-expressions
    subStep === 0 ? onGoBack() : setSubStep(prev => prev - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setSubStep(reduxSubStep);
  }, [reduxSubStep]);

  const addNewEntity = () => {
    dispatch(setSelected('new'));
    let newSubstep = 0;

    const { trusteeType } = clientStructureStepData;
    if (trusteeType === 'corporate' && haveTrustCompany) {
      newSubstep = 1;
    }
    setSubStep(newSubstep);
    dispatch(setReduxSubStep(newSubstep));
  };

  useEffect(() => {
    if (selected === null) {
      let newSubstep = 0;

      const { trusteeType } = clientStructureStepData;
      if (trusteeType === 'corporate' && haveTrustCompany) {
        newSubstep = 1;
      }
      setSubStep(newSubstep);
      dispatch(setReduxSubStep(newSubstep));
    }
  }, [selected]);

  useEffect(() => {
    const { trusteeType } = clientStructureStepData;
    if (trusteeType === 'corporate') {
      const newSubSteps = [...steps];
      newSubSteps.unshift({
        title: 'Entity details',
        component: 'company-verification-form',
      });
      setSubSteps(newSubSteps);
    }
  }, [clientStructureStepData]);

  const onFinish = (values) => {
    dispatch(setEntity(values));
    const haveRemainingSteps = subStep + 1 < subSteps.length;
    if (haveRemainingSteps) {
      setSubStep(subStep + 1);
      dispatch(setReduxSubStep(subStep + 1));
    }

    if (!haveRemainingSteps) {
      if (isLastStep) {
        onFinishLastStep(values, entities);
      } else {
        updateStepCompleteStatus(stepKey, 'finish');
        onProceed(values);
      }
    }
    window.scrollTo(0, 0);
  };

  const availableComponents = [
    {
      name: 'person-form',
      component: <PersonForm
        parents={[]}
        requireCountry={useSelector(getSelectedRole) === 'investor'}
        stepKey={stepKey}
        subStepKey="person-form"
        onFinish={onFinish}
        onGoBack={onGoBackLocal}
      />,
    },
    {
      name: 'company-verification-form',
      component: <CompanyVerification
        stepKey={stepKey}
        subStepKey="company-verification-form"
        onFinish={onFinish}
        onGoBack={onGoBackLocal}
      />,
    },
    {
      name: 'identity-verification-form',
      component: <PersonVerification
        stepKey={stepKey}
        subStepKey="identity-verification-form"
        onFinish={onFinish}
        onGoBack={onGoBackLocal}
      />,
    },
    {
      name: 'entity-confirmation',
      component: <EntityConfirmation
        stepKey={stepKey}
        subStepKey="entity-confirmation"
        onClick={addNewEntity}
        onFinish={onFinish}
        onGoBack={onGoBackLocal}
      />,
      comment: t('entity.verification.confirmation.comment'),
    },
  ];

  useEffect(() => {
    if (subSteps.length && subSteps.length >= subStep) {
      const currentStep = subSteps[subStep];

      const selectedConfig = availableComponents.find(config => config.name === currentStep.component);
      if (selectedConfig) {
        setComment(selectedConfig.comment);
        setCurrentComponent(selectedConfig.component);
      }
    }
  }, [subSteps, subStep]);

  return (
    <>
      <HeaderTitle
        title="Register your entity"
        comment={comment}
        commentType="grey-box"
      />
      {currentComponent}
    </>
  );
};

RegisterAndVerifyEntities.propTypes = {
  onProceed: func,
  onFinishLastStep: func,
  onGoBack: func,
  steps: arrayOf(shape({
    step: number.isRequired,
    title: string.isRequired,
    component: string.isRequired,
  })),
  stepKey: string,
  isLastStep: bool,
};

RegisterAndVerifyEntities.defaultProps = {
  onProceed: () => {},
  onFinishLastStep: () => {},
  onGoBack: () => {},
  steps: [],
  stepKey: 'register-and-verify-entities-form',
  isLastStep: () => false,
};

export default RegisterAndVerifyEntities;
