import React from 'react';
import PropTypes from 'prop-types';
import { Radio as AntdRadio } from 'antd';
import './Radio.less';

const RadioButton = (args) => {
  const { children } = args;
  return <AntdRadio.Button {...args} className="theme-radio-button">{children}</AntdRadio.Button>;
};
const Radio = args => <AntdRadio className="theme-radio" {...args} />;

Radio.propTypes = {
  buttonStyle: PropTypes.oneOf(['outline', 'solid']),
  disabled: PropTypes.bool,
  name: PropTypes.string,
  optionType: PropTypes.oneOf(['default', 'button']),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  children: PropTypes.string,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  buttonStyle: 'outline',
  disabled: false,
  name: null,
  optionType: 'default',
  size: 'medium',
  autoFocus: false,
  checked: false,
  defaultChecked: false,
  value: null,
  children: 'Example radio',
  onChange: () => {},
};

export { RadioButton };
export default Radio;
