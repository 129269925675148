import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Table from '../../DataDisplay/Table/Table';
import TableSummaryCell from '../../DataDisplay/Table/Summary/TableSummaryCell/TableSummaryCell';
import TableSummaryRow from '../../DataDisplay/Table/Summary/TableSummaryRow/TableSummaryRow';

const TaxStatementDataTable = ({ data }) => {
  const columns = [
    {
      title: 'NUMBER',
      dataIndex: ['investment', 'fieldLmsAccountId'],
      align: 'left',
    },
    {
      title: 'LOCATION',
      dataIndex: ['investment', 'fieldLoan', 'fieldPrimarySecurity', 'fieldAddress', 'locality'],
    },
    {
      title: 'LOAN',
      dataIndex: ['investment', 'fieldLoan', 'fieldLmsAccountId'],
    },
    {
      title: 'PRINCIPAL',
      dataIndex: ['principal'],
      render: value => formatCurrencyFull(value),
    },
    {
      title: 'CLOSING BAL',
      dataIndex: 'balance',
      align: 'right',
      render: value => formatCurrencyFull(value),
    },
    {
      title: 'INTEREST',
      dataIndex: 'interestPaid',
      align: 'right',
      render: value => formatCurrencyFull(value),
    },
  ];


  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      summary={(tableData) => {
        const total = tableData.reduce((carry, { interestPaid }) => carry + interestPaid, 0);
        return (
          <TableSummaryRow>
            <TableSummaryCell colSpan={5}>TOTAL INTEREST EARNED</TableSummaryCell>
            <TableSummaryCell colSpan={1}>{ formatCurrencyFull(total) }</TableSummaryCell>
          </TableSummaryRow>
        );
      }}
    />
  );
};

TaxStatementDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    investment: PropTypes.shape({
      investmentRate: PropTypes.number,
      fieldLmsAccountId: PropTypes.string,
      fieldAmount: PropTypes.number,
      fieldLoan: PropTypes.shape({
        fieldPrimarySecurity: PropTypes.shape({
          fieldAddress: PropTypes.shape({
            locality: PropTypes.string,
          }),
        }),
      }),
    }),
    balance: PropTypes.number,
    interestPaid: PropTypes.number,
  })).isRequired,
};

export default TaxStatementDataTable;
