import moment from 'moment/moment';

export const dateTimeFormatOutput = 'DD/MM/YYYY hh:mm A';

export const momentToDateTimeString = value => value && value?.format(dateTimeFormatOutput);

export const timestampToMoment = value => value && moment.unix(value);

export const timestampToDateTimeString = value => value && momentToDateTimeString(timestampToMoment(value));

export default momentToDateTimeString;
