
const completeFundingStates = [
  'live', 'live-subscribed', 'live-complete', 'closed_pending', 'closed',
];
export const loanHasCompleteFunding = loan => loan?.fieldState && completeFundingStates.indexOf(loan?.fieldState) !== -1;

export const loanIsFunding = loan => loan?.fieldState && ['funding', 'backfill'].indexOf(loan?.fieldState) !== -1;

export const loanIsBackfill = loan => loan?.fieldState === 'backfill';

export const loanIsOffer = loan => loan?.fieldState && ['assessed'].indexOf(loan?.fieldState) !== -1;

export const haveDealerAssociation = ({ fieldDealerAssociations }, dealerKey) => {
  if (!Array.isArray(fieldDealerAssociations)) {
    return false;
  }
  return fieldDealerAssociations.filter(entity => entity !== null).reduce(
    (carry, { fieldDealer }) => carry || (fieldDealer === dealerKey),
    false,
  );
};

export const getLmsAccount = (loan) => {
  try {
    return JSON.parse(loan.LmsAccount);
  } catch (e) {
    return {};
  }
};

export const fundingProgress = (loan) => {
  if (loanHasCompleteFunding(loan)) {
    return 100;
  }

  const { currentFundingPool, currentPool } = loan;
  let pool = currentPool;
  if (currentFundingPool !== null) {
    pool = currentFundingPool;
  }
  if (!pool) {
    return 0;
  }

  const { fundingAvailable, fieldPoolTotal } = pool;
  let progress = ((fieldPoolTotal - fundingAvailable) * 100) / fieldPoolTotal;
  progress = progress.toFixed(1);
  if (progress >= 10) {
    progress = parseInt(progress, 10);
  }

  return progress > 100 ? 100 : progress;
};

export const currentFundingPoolSelect = ({ currentFundingPool, currentPool }) => (currentFundingPool || currentPool);

export default {
  currentFundingPoolSelect,
  fundingProgress,
  getLmsAccount,
  haveDealerAssociation,
  loanHasCompleteFunding,
  loanIsFunding,
  loanIsOffer,
  loanIsBackfill,
};
