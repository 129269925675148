import React from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import { useMutation } from '@apollo/client';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import { getFieldName } from '../../../Helpers/Forms';
import FullName from '../../../Molecules/Forms/FullName/FullName';
import usePersonVerification from '../../../Hooks/usePersonVerification';
import { getAppVerificationOptions } from '../../../Store/appConfigurationSlice';
import Button from '../../../Atoms/Forms/Button/Button';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { IDENTIFICATION_UPDATE } from '../../../GraphQL/Mutations/Client';
import { snakeCaseToCamelCase } from '../../../Hooks/useStringFormatter';
import { extractDocument } from '../../../Hooks/useCommonConvertedEntityData';
import { CLIENT_IDENTIFICATION } from '../../../GraphQL/Queries/Client';
import { firstKey } from '../../../Helpers/Object/Object';
import useGraphQlNotifications from '../../../Hooks/useGraphQLNotifications';

const UpdatePersonVerification = ({
  parents, identity, files, onSuccess,
}) => {
  const { options: verificationOptions } = useSelector(getAppVerificationOptions);
  const { shouldUpdateComponent, getSelectedComponent, getDocumentFieldName } = usePersonVerification({ parents, verificationOptions });
  const { clientUuid } = identity;
  const variables = { clientUuid };
  const query = CLIENT_IDENTIFICATION;
  const [IdentificationUpdate, { loading: updateLoading }] = useMutation(IDENTIFICATION_UPDATE, { refetchQueries: [{ query, variables }] });
  const [form] = Form.useForm();
  const { notificationFromResponse, notificationFromError } = useGraphQlNotifications();

  const finish = (values) => {
    const {
      firstName,
      middleName,
      lastName,
      birthdate,
      selected,
      name,
      birthCertificateDocument,
      citizenshipDocument,
      driverLicenceDocument,
      foreignPassportDocument,
      passportDocument,
      ...verificationData
    } = values;

    const identityType = snakeCaseToCamelCase(selected);
    const docFieldName = getDocumentFieldName(selected);
    const documents = (values[docFieldName] && [
      extractDocument(values[docFieldName], ['identification']),
    ].filter(doc => !!doc)) || [];

    const identValues = {
      [identityType]: {
        details: {
          firstName, lastName, middleName, birthdate,
        },
        ...verificationData,
      },
    };

    IdentificationUpdate({
      variables: {
        clientUuid,
        input: identValues,
        documents,
      },
    })
      .then((response) => {
        const success = response?.data?.[firstKey(response?.data)]?.success;
        notificationFromResponse(
          response,
          'Identity details updated and successfully verified.',
          'Identity details updated but the verification failed.',
        );
        if (success) onSuccess();
      }).catch(notificationFromError);
  };

  const documentFieldName = getDocumentFieldName(identity?.selected);
  if (files && documentFieldName) {
    const displayFiles = files.map(file => ({ uid: file.fid, name: file.filename, fid: file.fid }));
    form.setFieldValue(documentFieldName, displayFiles);
  }

  return (
    <Form
      layout="vertical"
      initialValues={identity}
      onFinish={finish}
      form={form}
    >
      <FormItem
        label="Please select one of the following"
        name={getFieldName('selected', parents)}
        rules={[{ required: true, message: 'The field is required.' }]}
      >
        <RadioGroup className={`has-${verificationOptions.length}-columns box-style`}>
          {verificationOptions.map(option => (
            <div className="theme-radio-wrapper" key={option.value}>
              <Radio value={option.value}>{option.label}</Radio>
            </div>
          ))}
        </RadioGroup>
      </FormItem>

      <FormItem
        className="theme-form-item-wrapper"
        shouldUpdate={shouldUpdateComponent}
      >
        {({ getFieldValue }) => (
          <>
            <FormItem hidden={!getFieldValue(getFieldName('selected', parents))}>
              <FullName />
            </FormItem>
          </>
        )}
      </FormItem>

      <div className="theme-input-group-wrapper">
        <FormItem
          noStyle
          shouldUpdate={shouldUpdateComponent}
        >
          {getSelectedComponent}
        </FormItem>
      </div>

      <Button type="primary" htmlType="submit" loading={updateLoading}>Update and Re-verify</Button>
    </Form>
  );
};

UpdatePersonVerification.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  files: arrayOf(shape({
    fid: number,
    filename: string,
  })),
  identity: shape({}).isRequired,
  onSuccess: func.isRequired,
};

UpdatePersonVerification.defaultProps = {
  parents: ['personVerification'],
  files: [],
};
export default UpdatePersonVerification;
