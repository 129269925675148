import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

const UseNestedDrawer = (props) => {
  const { onSuccess } = props;

  const [visible, setVisible] = useState(false);
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const width = isMdScreen ? '80vw' : '40vw';

  const success = () => {
    setVisible(false);
    onSuccess?.();
  };

  return {
    visible, setVisible, success, width,
  };
};

UseNestedDrawer.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default UseNestedDrawer;
