import React from 'react';
import { func } from 'prop-types';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleCheck, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import Table from '../../Atoms/DataDisplay/Table/Table';
import { titleCase } from '../../Hooks/useStringFormatter';
import { POLICIES } from '../../GraphQL/Queries/Policies';
import Result from '../../Atoms/Feedback/Result/Result';
import Link from '../../Atoms/Navigation/Links/Link';
import { formatUnixDate } from '../../Helpers/FormatDate';
import Button from '../../Atoms/Forms/Button/Button';
import { getAppPoliciesOptions } from '../../Store/appConfigurationSlice';
import Input from '../../Atoms/Forms/Input/Input';
import Space from '../../Atoms/Layout/Space/Space';
import './PoliciesTable.less';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const PoliciesTable = ({ onOpenEditForm }) => {
  const limit = 50;
  const options = useSelector(getAppPoliciesOptions);
  const {
    loading, error, data, refetch, fetchMore,
  } = useQuery(POLICIES, {
    variables: {
      offset: 0, limit, sortBy: 'fieldExpiry', sortDirection: 'ASC',
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleSearch = (selectedKeys, confirm) => {
    refetch({ search: selectedKeys[0] }).then(() => confirm());
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    handleSearch('', confirm);
  };

  const isSmScreen = useMediaQuery({ query: '(max-width: 480px)' });

  const columns = [
    {
      title: 'POLICY',
      dataIndex: 'fieldPolicyKey',
      align: 'left',
      render: value => options?.find(option => option?.value === value)?.label,
      filters: options?.map(option => ({ text: option?.label, value: option?.value })),
      onFilter: (value, row) => row?.fieldPolicyKey === value,
      sorter: (a, b) => a.fieldPolicyKey.localeCompare(b.fieldPolicyKey),
      width: isSmScreen ? '6rem' : '25%',
    },
    {
      title: 'REF TYPE',
      align: 'left',
      render: (value) => {
        if (value?.fieldClient) return 'Client';
        if (value?.fieldAccount) return 'Account';
        if (value?.owner) return 'User';
        return null;
      },
      filters: ['Client', 'Account', 'User'].map(option => ({ text: option, value: option })),
      onFilter: (value, row) => (value === 'Client' && row?.fieldClient !== null)
        || (value === 'Account' && row?.fieldAccount !== null)
        || (value === 'User' && row?.owner !== null),
    },
    {
      title: 'REF',
      align: 'left',
      render: (value) => {
        if (value?.fieldClient) {
          return (
            <Link
              to={`/app/dealer/client/${value?.fieldClient?.uuid}`}
            >
              {titleCase(value?.fieldClient?.name)}
            </Link>
          );
        }
        if (value?.fieldAccount) return value?.fieldAccount?.fieldLmsAccountId;
        if (value?.owner) return value?.owner?.email;
        return null;
      },
      sorter: (a, b) => {
        const first = a?.fieldClient?.name || a.fieldAccount?.fieldLmsAccountId || a?.owner?.email;
        const second = b?.fieldClient?.name || b.fieldAccount?.fieldLmsAccountId || b?.owner?.email;
        return first.localeCompare(second);
      },
      filterDropdown: ({
        setSelectedKeys, selectedKeys, confirm, clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              loading={loading}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters, confirm)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: 'EXPIRES \n ACCEPTED',
      align: 'center',
      render: record => (
        <>
          {formatUnixDate(record.fieldExpiry)}
          <br />
          {record.fieldAccepted ? (<FontAwesomeIcon className="color-success" icon={faCircleCheck} />) : (<FontAwesomeIcon className="color-warning" icon={faCircleExclamation} />)}
        </>
      ),
      width: '5.5rem',
      responsive: ['xs'],
    },
    {
      title: 'Expires',
      dataIndex: 'fieldExpiry',
      align: 'center',
      responsive: ['sm'],
      render: value => formatUnixDate(value),
      sorter: (a, b) => a.fieldExpiry - b.fieldExpiry,
    },
    {
      title: 'ACCEPTED',
      dataIndex: 'fieldAccepted',
      align: 'center',
      responsive: ['sm'],
      render: value => (value ? (<FontAwesomeIcon className="color-success" icon={faCircleCheck} />) : (<FontAwesomeIcon className="color-warning" icon={faCircleExclamation} />)),
      filters: [{ text: 'Accepted', value: true }, { text: 'Declined', value: false }],
      onFilter: (value, row) => row?.fieldAccepted === value,
      width: '5rem',
    },
    {
      title: '',
      align: 'right',
      width: isSmScreen ? '3rem' : '7rem',
      render: (value, row) => !['JoinPolicy']?.includes(value?.fieldPolicyKey)
        && <Button size={isSmScreen ? 'small' : 'middle'} type="primary" onClick={() => onOpenEditForm(row)}>{ isSmScreen ? <FontAwesomeIcon icon={faPenToSquare} /> : 'Edit' }</Button>,
    },
  ];

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  return (
    <>
      <Table
        className="theme-policies-table"
        rowKey="uuid"
        type="inner"
        columns={columns}
        loading={loading}
        pagination={false}
        dataSource={data?.policies?.results}
      />
      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />
    </>
  );
};

PoliciesTable.propTypes = {
  onOpenEditForm: func.isRequired,
};

export default PoliciesTable;
