import React, { useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise/dist/index';
import useConstant from 'use-constant';
import { useAsync } from 'react-async-hook';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import investmentService, { investmentAmounts } from '../../Services/Investments.service';
import InvestmentSearchForm from '../../Molecules/Forms/InvestmentSearchForm/InvestmentSearchForm';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import InvestmentList from './Components/InvestmentList';
import {
  getAppDealerConfiguration,
  getAppInvestmentConditions,
} from '../../Store/appConfigurationSlice';
import { dealer, dealerStandardInvestmentDesc, userUuid as _userUuid } from '../../Hooks/useLocalStorage';
import InfiniteScrollLegacy from '../../Molecules/InfiniteScrollLegacy';

const useDebouncedInvestmentFilter = (searchFunction) => {
  const [searchText, setSearchText] = useState('');
  const [searchAmount, setSearchAmount] = useState('');
  const [searchState, setSearchState] = useState('');
  const [pageContext, setPageContext] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(25);
  const [context, setContext] = useState('');

  const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchFunction, 200 + Math.random() * 800));

  const search = useAsync(
    async () => debouncedSearch(
      searchText,
      searchAmount,
      searchState,
      pageNumber,
      pageSize,
      context,
      pageContext,
    ),
    [searchText, searchAmount, searchState, pageNumber, pageSize, context, pageContext],
  );

  return {
    context,
    pageNumber,
    pageSize,
    pageContext,
    searchAmount,
    searchState,
    searchText,
    setContext,
    setPageNumber,
    setPageContext,
    setSearchAmount,
    setSearchState,
    setSearchText,
    search,
  };
};

const getInvestments = async (searchText, searchAmount, searchState, page, pageSize, context, pageContext) => {
  const searchMethod = `${context}-${pageContext}`;

  let amount = null;
  if (searchAmount !== null) {
    amount = investmentAmounts[searchAmount];
  }

  let response = null;
  // @todo Refactor uuid to use redux.
  const userUuid = _userUuid();

  const states = (searchState && [searchState]) || null;

  switch (searchMethod) {
    case 'dealer-fund':
      response = await investmentService.getDealerFunds(amount, searchText, page, pageSize);
      break;
    case 'dealer-direct':
      response = await investmentService.getDealerInvestments(amount, searchText, states, page, pageSize);
      break;
    case 'dealer-closed':
      response = await investmentService.getDealerClosedInvestments(amount, searchText, page, pageSize);
      break;
    case 'dealer-secondary':
      response = await investmentService.getDealerSecMarketInvestments(amount, searchText, page, pageSize);
      break;
    case 'dealer-commitments':
      response = await investmentService.getDealerCommitmentInvestments(amount, searchText, page, pageSize);
      break;
    case 'investor-fund':
      response = await investmentService.getInvestorFunds(amount, searchText, userUuid, page, pageSize);
      break;
    case 'investor-closed':
      response = await investmentService.getInvestorClosedInvestments(amount, searchText, userUuid, page, pageSize);
      break;
    case 'investor-secondary':
      response = await investmentService.getInvestorSecMarketInvestments(amount, searchText, userUuid, page, pageSize);
      break;
    case 'investor-commitments':
      response = await investmentService.getInvestorCommitmentInvestments(amount, searchText, userUuid, page, pageSize);
      break;
    default:
      response = await investmentService.getInvestorInvestments(amount, searchText, states, userUuid, page, pageSize);
      break;
  }

  const responseData = response?.data?.data?.investments;
  if (responseData !== null) {
    return responseData;
  }
  return { results: [], count: 0 };
};

const Investments = (props) => {
  const { match: { params: { context: newContext } } } = props;
  let { match: { params: { pageContext: newPageContext } } } = props;

  if (newPageContext === undefined || newPageContext === null) {
    newPageContext = 'direct';
  }

  const appConfig = useSelector(getAppDealerConfiguration);
  const investConfig = useSelector(getAppInvestmentConditions);

  const {
    searchText,
    searchAmount,
    searchState,
    pageNumber,
    pageSize,
    pageContext,
    context,
    setSearchText,
    setSearchAmount,
    setSearchState,
    setPageNumber,
    setPageContext,
    setContext,
    search,
  } = useDebouncedInvestmentFilter(getInvestments);

  if (context !== newContext) {
    setContext(newContext);
  }
  if (pageContext !== newPageContext) {
    setPageContext(newPageContext);
  }

  let entityList = [];
  let totalResults = 0;

  if (search.status === 'success') {
    entityList = search.result.results;
    totalResults = search.result.total;
  }

  const clearFilter = () => {
    setSearchText('');
    setSearchAmount('');
    setPageNumber(0);
    setSearchState('');
  };

  let pageTitle = `${dealerStandardInvestmentDesc()} Investments`;
  let pageDescription = `Below is a list of your ${dealerStandardInvestmentDesc().toLowerCase()} investments.`;
  if (pageContext === 'fund') {
    pageTitle = 'Fund Investments';
    pageDescription = 'Below is a list of your investments in the Fund.';
  }
  if (pageContext === 'commitments') {
    pageTitle = 'Commitments';
    pageDescription = 'Below is a list of commitments.';
  }
  if (pageContext === 'closed') {
    pageTitle = 'Closed Investments';
    pageDescription = 'Below is a list of closed investments.';
  }
  if (pageContext === 'secondary') {
    pageTitle = 'Secondary Market Investments';
    pageDescription = 'Below is a list of your Secondary Market Investments.';
  }

  const dealerStateOptions = [
    { value: 'funding', label: 'Funding' },
    { value: ':live', label: 'Live' },
    { value: 'closed_pending', label: 'Closing' },
  ];

  return (
    <div className="theme-investments page-inner">
      <HeaderTitle
        title={pageTitle}
        comment={pageDescription}
      />
      {/* @todo replace with tab atom */}
      <ul className="tabs-container">
        <li className="tab">
          <Link to={`/app/${context}/investments/direct`} className={newPageContext === 'direct' ? 'active' : ''}>
            {
              (dealer() === 'znz') ? 'active' : 'direct'
            }
          </Link>
        </li>
        {
          appConfig?.dealerFlags?.managedFundEnabled && (
            <li className="tab"><Link to={`/app/${context}/investments/fund`} className={newPageContext === 'fund' ? 'active' : ''}>fund</Link></li>
          )
        }
        {
          investConfig?.secondaryMarketEnabled && (
            <>
              <li className="tab"><Link to={`/app/${context}/investments/commitments`} className={newPageContext === 'commitments' ? 'active' : ''}>commitments</Link></li>
              <li className="tab"><Link to={`/app/${context}/investments/secondary`} className={newPageContext === 'secondary' ? 'active' : ''}>secondary market</Link></li>
            </>
          )
        }
        <li className="tab"><Link to={`/app/${context}/investments/closed`} className={newPageContext === 'closed' ? 'active' : ''}>closed</Link></li>
      </ul>
      <InvestmentSearchForm
        context={context}
        searchText={searchText}
        searchAmount={searchAmount}
        searchState={searchState}
        setSearchText={setSearchText}
        setSearchAmount={setSearchAmount}
        setSearchState={(pageContext === 'direct') && setSearchState}
        setPageNumber={setPageNumber}
        stateOptions={context === 'dealer' && dealerStateOptions}
        clearFilter={clearFilter}
      />
      <InvestmentList
        page={pageNumber}
        items={entityList}
        context={context}
        status={search.status}
        showFund={newPageContext !== 'direct'}
      />
      <InfiniteScrollLegacy
        page={pageNumber}
        pageSize={pageSize}
        loading={search.status === 'loading'}
        totalResults={totalResults}
        onClick={() => setPageNumber(pageNumber + 1)}
      />
    </div>
  );
};

export default Investments;
