import React from 'react';
import { string } from 'prop-types';
import Card from '../Card';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import './ElectronicTransfer.less';

const ElectronicTransfer = ({
  legalNameTrust, accountNumber, accountReference, bsb, brand,
}) => (
  <Card
    title="Electronic transfer"
    className="theme-electronic-transfer"
  >
    If you wish to transfer funds electronically from your bank account, please use the details provided
    below:
    <Paragraph type="label">
      <strong>{ legalNameTrust }</strong>
      {' '}
      (
      { brand }
      &apos;s Trustee)
    </Paragraph>
    {bsb && (
      <Paragraph type="label">
        <strong>BSB:</strong>
        {' '}
        { bsb }
      </Paragraph>
    )}
    <Paragraph type="label">
      <strong>Account number:</strong>
      {' '}
      { accountNumber }
    </Paragraph>
    <Paragraph type="label">
      <strong>Reference:</strong>
      {' '}
      { accountReference }
    </Paragraph>
  </Card>
);

ElectronicTransfer.propTypes = {
  legalNameTrust: string.isRequired,
  accountNumber: string.isRequired,
  accountReference: string.isRequired,
  brand: string.isRequired,
  bsb: string,
};

ElectronicTransfer.defaultProps = {
  bsb: '',
};

export default ElectronicTransfer;
