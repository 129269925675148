import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ScrollToTop, { getHeaderImage } from '../../Helpers/Helpers';
import { LOAN_OFFER } from '../../GraphQL/Queries/Loan';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import LoanHeader from '../../Organisms/Headers/LoanHeader';
import AccountClientDetails from '../../Organisms/Cards/Accounts/AccountClientDetails';
import LoanDetails from '../../Organisms/Cards/Accounts/LoanDetails';
import SecurityDetails from '../../Organisms/Cards/Accounts/SecurityDetails';
import HasEntityPermission from '../../Molecules/Control/HasEntityPermission';
import OfferDetails from '../../Organisms/Cards/Accounts/OfferDetails';
import OfferToGuest from '../../Organisms/Forms/Offer/OfferToGuest';

const LoanOffer = () => {
  const { token } = useParams();

  ScrollToTop();

  const {
    loading, data,
  } = useQuery(LOAN_OFFER, { variables: { token } });

  if (loading) {
    return <Skeleton loading={loading} />;
  }

  if (!data) {
    return (<div />);
  }

  const {
    loanOffer: {
      account: loan,
    },
  } = data;

  const {
    fieldAmount,
    fieldClient,
    fieldCreditRating,
    fieldLmsAccountId,
    fieldLvr,
    fieldPrimarySecurity,
    fieldSecuritiesTotal,
    fieldState,
    currentFundingPool,
    currentPool,
    lmsAccount,
    loanTitle,
  } = loan;
  const lmsAccountData = (lmsAccount && JSON.parse(lmsAccount)) || null;
  const activePool = (currentFundingPool || currentPool);

  return (
    <div className="theme-loan-application page-inner">
      <LoanHeader
        amount={fieldAmount}
        headerImage={getHeaderImage(fieldPrimarySecurity, 'original')}
        loanId={fieldLmsAccountId}
        metaTitle={`Loan - ${fieldLmsAccountId}`}
        name={loanTitle}
        state={fieldState}
      />

      <AccountClientDetails title="Borrower details" client={fieldClient} creditRating={fieldCreditRating} />

      <LoanDetails
        loan={loan}
        pool={activePool}
      />

      { fieldPrimarySecurity && (
        <HasEntityPermission entity={loan} name="View security">
          <SecurityDetails lvr={fieldLvr} totalValue={fieldSecuritiesTotal} data={fieldPrimarySecurity} />
        </HasEntityPermission>
      )}

      <OfferDetails
        lmsAccount={lmsAccountData}
      />
      <OfferToGuest
        token={token}
      />
    </div>
  );
};

export default LoanOffer;
