import React from 'react';

export const FormSections = ({ children, className }) => (
  <div className={`form-sections ${className || ''}`}>
    {children}
  </div>
);

export const FormSection = ({ children, className }) => (
  <div className={`form-section ${className || ''}`}>
    {children}
  </div>
);

export const SectionTitle = ({ children }) => (
  <h4 className="section-title">
    {children}
  </h4>
);

export const FormSectionTitle = ({ children }) => (
  <h4 className="form-section-title">
    {children}
  </h4>
);
