import {
  arrayOf, bool, shape, string,
} from 'prop-types';
import React from 'react';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import Field from '../../../Atoms/DataDisplay/Field/Field';

const Term = ({
  entityPermission,
  term,
  minTerm,
  maxTerm,
  className,
}) => {
  const tranchePermission = haveEntityPermission({ FintechEntityPermissions: entityPermission }, 'View tranche fields');

  if (minTerm && maxTerm && tranchePermission) {
    return (
      <div className={className}>
        <Field type="inner" label="Minimum term" value={minTerm} />
        <Field type="inner" label="Maximum term" value={maxTerm} />
      </div>
    );
  }

  return (
    <Field type="inner" label="Loan term" value={term} className={className} />
  );
};

Term.propTypes = {
  entityPermission: arrayOf(shape({ label: string, permitted: bool })).isRequired,
  term: string,
  minTerm: string,
  maxTerm: string,
  className: string,
};

Term.defaultProps = {
  term: null,
  minTerm: null,
  maxTerm: null,
  className: null,
};
export default Term;
