import React from 'react';
import { shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import Panel from '../../Atoms/Layout/Panel/Panel';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import PillBadges from '../../Atoms/Markup/PillBadges/PillBadges';
import Link from '../../Atoms/Navigation/Links/Link';

const ApplicationDraftPanel = ({ draft }) => {
  const { context } = useParams();
  const { uuid, name } = draft;

  return (
    <Panel>
      <Link to={`/app/${context}/application/${uuid}`}>
        <PillBadges
          badges={[
            {
              title: 'Status',
              value: 'Incomplete',
            },
          ]}
        />
        <Paragraph>
          {name}
        </Paragraph>
      </Link>
    </Panel>
  );
};

ApplicationDraftPanel.propTypes = {
  draft: shape({
    uuid: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};

export default ApplicationDraftPanel;
