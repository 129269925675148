import React, { Fragment, useState } from 'react';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import DocumentItemDrawer from './DocumentItemDrawer';
import { DocumentSelectIcon } from '../../../Resources/Icons';
import { formatUnixDate } from '../../../Helpers/FormatDate';
import BadgeGroup from '../../../Atoms/DataDisplay/BadgeGroup/BadgeGroup';
import Badge from '../../../Atoms/DataDisplay/Badge/Badge';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import './DocumentItem.less';
import Title from '../../../Atoms/Typography/Title/Title';
import Text from '../../../Atoms/Typography/Text/Text';
import Image from '../../../Atoms/Media/Image/Image';
import FileAccessLevel from '../../../Molecules/Documents/FileAccessLevel';
import DocumentLink from '../../../Atoms/Navigation/Links/DocumentLink';
import DocumentButtonControl from '../../../Molecules/Control/DocumentButtonControl';

const DocumentItem = (props) => {
  const {
    onDelete,
    editable,
    skeleton,
    context,
  } = props;

  const { row } = props;
  const {
    uuid: mediaUuid,
    changed,
    fieldAccess,
    label: mediaName,
    fieldMediaFiles,
    fieldAccount,
    fieldClient,
  } = row;

  const isLgScreen = useMediaQuery({ query: '(max-width: 991px)' });
  const [drawerToggle, setDrawerToggle] = useState(false);

  if (skeleton) {
    return (
       <Skeleton loading />
    );
  }

  let mediaFilesPlus = [];
  if (fieldMediaFiles !== null) {
    mediaFilesPlus = fieldMediaFiles.map(
      (item, index) => item !== undefined && { ...item, index, mediaUuid },
    );
  }

  let loan = null;
  let investmentLmsId = '';
  if (fieldAccount !== null) {
    loan = fieldAccount;
    investmentLmsId = null;
    if (fieldAccount.type === 'investment') {
      investmentLmsId = fieldAccount.fieldLmsAccountId;
      // eslint-disable-next-line prefer-destructuring
      loan = fieldAccount.fieldLoan;
    }
  }
  let clientLmsId = fieldClient?.fieldLmsClientId;
  if (fieldAccount !== null && context === 'dealer') {
    clientLmsId = fieldAccount?.fieldClient?.fieldLmsClientId;
  }

  let documentItemDrawer = (
    <DocumentItemDrawer
      key={`drawer-${mediaUuid}`}
      mediaUuid={mediaUuid}
      title={mediaName}
      files={mediaFilesPlus}
      isVisible={drawerToggle}
      onClose={() => setDrawerToggle(!drawerToggle)
      }
      onDelete={onDelete}
      editable={editable}
    />
  );

  let fileType = 'multi';
  if (mediaFilesPlus.length === 1) {
    documentItemDrawer = null;
    fileType = mediaFilesPlus[0].filemime;
  }

  const detailLines = mediaFilesPlus.slice(0, 6).map(({ filename, fid }) => (
    <Text key={fid} className="file-name">
      {filename}
    </Text>
  ));

  detailLines.push(
    <Fragment key="disable">
      <Text>{formatUnixDate(changed)}</Text>
      { mediaFilesPlus.length > 6 && (
        <Badge status="disable">
          {mediaFilesPlus.length - 6}
          {' '}
          more
        </Badge>
      )}
    </Fragment>,
  );

  const address = loan?.primarySecurity?.address?.value;
  if (address !== null) {
    detailLines.push(address);
  }

  const badgeGroups = (
    <BadgeGroup type="flex-row">
      {
        investmentLmsId && (
          <Badge>
            Investment:
            {' '}
            {investmentLmsId}
          </Badge>
        )
      }
      {
        loan && (
          <Badge>
            Loan:
            {' '}
            {loan.fieldLmsAccountId}
          </Badge>
        )
      }
      {
        clientLmsId && (
          <Badge>
            Client:
            {' '}
            {clientLmsId}
          </Badge>
        )
      }
    </BadgeGroup>
  );

  const inner = (
    <>
    <div className="img-wrapper">
      <Image
        preview={false}
        src={DocumentSelectIcon(fileType)}
        alt={mediaUuid}
      />
      { !isLgScreen && (<FileAccessLevel fieldAccess={fieldAccess} />)}
    </div>
    <div className="content-wrapper">
      {badgeGroups}
      { isLgScreen && <FileAccessLevel fieldAccess={fieldAccess} />}
      <Title level={4}>{mediaName}</Title>
      <div className="details-wrapper">{detailLines}</div>
    </div>
    </>
  );

  return (
    <>
      <Fade>
        <Panel hasLink={false} type="noPadding background" className="theme-document-item">
          {
            mediaFilesPlus.length > 1 && (
              <div
                className="contents"
                onClick={() => setDrawerToggle(true)}
                onKeyPress={() => setDrawerToggle(true)}
                role="button"
                tabIndex={0}
              >
                {inner}
              </div>
            )
          }
          {
            mediaFilesPlus.length === 1 && (
              <DocumentLink
                localDocPath={mediaFilesPlus[0].path}
                className="contents"
                file={mediaFilesPlus[0]}
                tabIndex={0}
              >
                {inner}
              </DocumentLink>
            )
          }
          { editable && <DocumentButtonControl useCase="dropdown" key={mediaUuid} uuid={mediaUuid} />}
        </Panel>
      </Fade>
      {documentItemDrawer}
    </>
  );
};

DocumentItem.propTypes = {
  onDelete: PropTypes.func,
  editable: PropTypes.bool,
  skeleton: PropTypes.bool,
  context: PropTypes.string.isRequired,
  row: PropTypes.shape({
    entityUuid: PropTypes.string,
    name: PropTypes.string,
    fieldMediaFiles: PropTypes.arrayOf(PropTypes.shape({
      filename: PropTypes.string,
      filemime: PropTypes.string,
      filesize: PropTypes.number,
      created: PropTypes.string,
    })),
  }).isRequired,
};

DocumentItem.defaultProps = {
  onDelete: null,
  editable: false,
  skeleton: false,
};

export default DocumentItem;
