import React, { useContext } from 'react';
import { bool } from 'prop-types';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import LangContext from '../../../Context/Lang/LangContext';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import Input from '../../../Atoms/Forms/Input/Input';

const FullName = ({ disabled }) => {
  const { t } = useContext(LangContext);
  const requiredRule = { required: true, message: t('validation.required', { attribute: 'field' }) };

  return (
    <InputGroup compact className="theme-full-name">
      <FormItem
        label={t('entity.person.first_name')}
        name="firstName"
        rules={[requiredRule]}
      >
        <Input disabled={disabled} />
      </FormItem>

      <FormItem
        label={t('entity.person.middle_name')}
        name="middleName"
      >
        <Input disabled={disabled} />
      </FormItem>

      <FormItem
        label={t('entity.person.last_name')}
        name="lastName"
        rules={[requiredRule]}
      >
        <Input disabled={disabled} />
      </FormItem>

      <FormItem
        noStyle
        hidden
        shouldUpdate={(previous, next) => previous.firstName !== next.firstName || previous.lastName !== next.lastName}
      >
        { ({ getFieldValue, setFieldValue }) => {
          let fullName = '';
          if (getFieldValue('firstName') && getFieldValue('lastName')) {
            fullName = `${getFieldValue('firstName')} ${getFieldValue('lastName')}`;
          }
          setFieldValue('name', fullName);
          return <FormItem hidden name="name" />;
        }}
      </FormItem>
    </InputGroup>
  );
};

FullName.propTypes = {
  disabled: bool,
};

FullName.defaultProps = {
  disabled: false,
};

export default FullName;
