import axios from '../Vendor/Axios';
import { axiosParams } from '../Helpers/Helpers';

export const getCsrfToken = () => axios
  .get(`/session/token${axiosParams()}`)
  .then(({ data }) => Promise.resolve(data))
  .catch(error => Promise.reject(new Error(error)));

const AuthService = {
  getCsrfToken,
};

export default AuthService;
