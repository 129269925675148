import React from 'react';
import { shape, number, string } from 'prop-types';
import { fundingProgress as _fundingProgress } from '../../../Helpers/loanShortcuts';
import Progress from '../../../Atoms/Feedback/Progress/Progress';
import './FundingProgress.less';
import Text from '../../../Atoms/Typography/Text/Text';
import { formatCurrencyFull, formatPercentage } from '../../../Hooks/useNumberFormatter';
import Title from '../../../Atoms/Typography/Title/Title';

import HasEntityPermission from '../../Control/HasEntityPermission';

const FundingProgress = ({ account }) => {
  const { currentPool } = account;

  if (!account) {
    return null;
  }
  const fundingProgress = _fundingProgress(account);

  return account && (
    <HasEntityPermission entity={account} name="Can view funding progress">
      <div className="theme-funding-progress">
        <Title level={5} className="theme-field-title">Funding progress</Title>
        <Progress percent={fundingProgress} showInfo={false} />
        <Text>
          {`${formatPercentage(fundingProgress)} funded.`}
          {' '}
          {formatCurrencyFull(currentPool?.fundingAvailable)}
          {' '}
          remaining.
        </Text>
      </div>
    </HasEntityPermission>
  );
};

FundingProgress.propTypes = {
  account: shape({
    fieldState: string,
    currentPool: shape({
      fundingAvailable: number,
    }),
  }),
};

FundingProgress.defaultProps = {
  account: shape({
    fieldState: '',
    currentPool: shape({
      fundingAvailable: null,
    }),
  }),
};
export default FundingProgress;
