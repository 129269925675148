// Directly references persisted user slice.
// @todo Is there a more "correct" way to do this?
const persistedUser = () => {
  const persist = localStorage.getItem('persist:user') && JSON.parse(localStorage.getItem('persist:user'));
  return JSON.parse(persist?.user) || null;
};

export const getUserRoles = () => persistedUser()?.roles || [];
export const oldHasRole = role => !!getUserRoles()?.find(checkRole => checkRole === role);
export const userUuid = () => persistedUser()?.uuid || null;

export const dealer = () => localStorage.getItem('dealer');
export const getBrand = () => dealer();

export const dealerDefaultCountry = () => (dealer() === 'znz' ? 'NZ' : 'AU');

export const dealerStandardInvestmentDesc = () => (dealer() === 'znz' ? 'Active' : 'Direct');

const useLocalStorage = {
  oldHasRole,
  userUuid,
  dealer,
  dealerDefaultCountry,
};

export default useLocalStorage;
