/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { func } from 'prop-types';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import { AutoComplete } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import Input from '../../Atoms/Forms/Input/Input';
import clientService from '../../Services/Clients.service';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';

const { Option } = AutoComplete;

// @todo refactor; tidy up.
const useDebouncedInvestorFilter = (searchFunction) => {
  const [searchText, setSearchText] = useState('');

  const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchFunction, 200 + Math.random() * 800));

  const search = useAsync(
    async () => debouncedSearch(
      searchText,
    ),
    [searchText],
  );

  return {
    setSearchText,
    search,
  };
};

const getInvestors = async (searchText) => {
  if (searchText === '') {
    return [];
  }
  const response = await clientService.investorClientCollection(null, searchText);
  const investorData = response?.data?.data?.investorClients?.results;
  if (investorData === null) {
    return [];
  }
  return investorData;
};

const InvestorSearch = ({ setSelectedInvestor }) => {
  const [investorText, setInvestorText] = useState('');

  const {
    setSearchText,
    search,
  } = useDebouncedInvestorFilter(getInvestors);

  let investorList = [];
  if (search.status === 'success' && Array.isArray(search.result)) {
    investorList = search.result;
  }

  const investorOptions = investorList.map(
    ({ fieldLmsClientId, name, uuid }) => (
      <Option key={uuid} text={name}>{`${name} - ${fieldLmsClientId}`}</Option>
    ),
  );

  const onInvestorSelect = (value, element) => {
    setSelectedInvestor(value);
    setInvestorText(element.props.children);
  };

  const onInvestorClear = () => {
    setSelectedInvestor(null);
    setInvestorText('');
  };

  return (
    <FormItem
      name="investorUuid"
      label="Find investor"
    >
      {
        !investorText && (
          <AutoComplete
            dataSource={investorOptions}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            onChange={setSearchText}
            onSelect={onInvestorSelect}
            placeholder="Search for investor by id, email or name"
          />
        )}
      {
        investorText && (
          <Input
            allowClear={{
              clearIcon:
                <div
                  onClick={onInvestorClear}
                  onKeyDown={onInvestorClear}
                >
                  <CloseCircleFilled />
                </div>,
            }}
            value={investorText}
          />
        )}
    </FormItem>
  );
};

InvestorSearch.propTypes = {
  setSelectedInvestor: func,
};

InvestorSearch.defaultProps = {
  setSelectedInvestor: () => {},
};

export default InvestorSearch;
