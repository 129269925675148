import React from 'react';
import PropTypes from 'prop-types';
import { Steps as AntdSteps } from 'antd';
import './Step.less';

const { Step: AntdStep } = AntdSteps;

const Step = args => <AntdStep className="theme-step" {...args} />;

Step.propTypes = {
  description: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  status: PropTypes.oneOf(['wait', 'process', 'finish', 'error']),
  subTitle: PropTypes.node,
  title: PropTypes.node,
  onClick: PropTypes.func,
};

Step.defaultProps = {
  description: undefined,
  disabled: false,
  icon: undefined,
  status: undefined,
  subTitle: undefined,
  title: undefined,
  onClick: () => {},
};

export default Step;
