/**
 * Originally set the slice from AppConfigProvider, and then called useSelector in ApolloProvider,
 * but this caused infinite re-renders whenever the slice's state changed. I guess the solution
 * would be to nest a separate schema provider inside the apollo provider component,
 * but I didn't want to add yet another nesting level.
 *
 * For now, I've left the slice as it is, but I'm keeping the date fields in local storage, so
 * I can avoid using a useSelector call & prevent my re-rendering hell.
 *
 * Originally was storing the entire schema, but we've gone to some lengths to mask the schema from
 * end users, so I've adjusted to just store the date fields. If we need further gql result processing
 * it's a pretty simple task to build on this.
 */

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const schemaSlice = createSlice({
  name: 'schema',
  initialState: {
    schemaDateFields: null,
  },
  reducers: {
    setSchemaDateFields: (state, action) => {
      const { payload } = action;
      state.schemaDateFields = payload;
    },
  },
});

// Callable outside the slice, because of our nested component issues.
export const schemaFields = schema => schema.types?.reduce(
  (prev, item) => ({
    ...prev,
    [item.name]: (item?.fields || item?.inputFields)?.reduce(
      (prevField, itemField) => ({
        ...prevField,
        [itemField.name]: (itemField.type.name || itemField.type.ofType?.name),
      }),
      {},
    ),
  }), {},
) || null;

export const schemaDateFields = (schema) => {
  const fields = schemaFields(schema);
  return (fields && Object.entries(fields)
    ?.reduce(
      (prev, [key, val]) => {
        const dateFields = val && Object.entries(val)?.filter(item => item.includes('DateField'));
        if (dateFields && dateFields.length > 0) {
          return { ...prev, [key]: dateFields.map(item => item[0]) };
        }
        return prev;
      },
      {},
    )) || null;
};

export const { setSchemaDateFields } = schemaSlice.actions;

export const getSchemaDateFields = () => {
  const persist = localStorage.getItem('persist:schema') && JSON.parse(localStorage.getItem('persist:schema'));
  return JSON.parse(persist?.schemaDateFields) || null;
};

export default schemaSlice.reducer;
