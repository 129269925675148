import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EntityCountContext from './EntityCountContext';

const EntityCountProvider = ({ children }) => {
  const [entityCount, setEntityCount] = useState(0);

  return (
    <EntityCountContext.Provider value={{ setEntityCount, entityCount }}>
      {children}
    </EntityCountContext.Provider>
  );
};

EntityCountProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EntityCountProvider;
