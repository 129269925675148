import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Result from '../../Atoms/Feedback/Result/Result';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import NoResult from '../../Molecules/NoResult/NoResult';
import DraftListItem from '../../Molecules/ViewModes/DraftListItem/DraftListItem';
import useApplicationDrafts from '../../Hooks/useApplicationDrafts';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const ApplicationDrafts = () => {
  const { context } = useParams();
  const { query, variables, limit } = useApplicationDrafts();

  const {
    error, loading, data, fetchMore,
  } = useQuery(query, { variables });

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { drafts } = data || {};
  const { results } = drafts || [];

  return (
    <div className="theme-org-clients">
      <Skeleton loading={loading}>
        { !results?.length && (
          <NoResult
            title="No drafts found."
            body="Drafts will be listed here when you begin the application process."
          />
        )}
        {results?.map(item => (
          <DraftListItem key={item?.uuid} uuid={item?.uuid} name={item?.name} context={context} sublink="apply" />
        ))}
        <InfiniteScroll
          pageSize={limit}
          loading={loading}
          data={data}
          fetchMore={fetchMore}
        />
      </Skeleton>
    </div>
  );
};


export default ApplicationDrafts;
