import React, { useState } from 'react';
import { func, shape, string } from 'prop-types';
import { Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import InvestorMemorandumForm from './InvestorMemorandumForm';
import Drawer from '../../../../Atoms/Feedback/Drawer/Drawer';
import Button from '../../../../Atoms/Forms/Button/Button';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../../../Atoms/Typography/Paragraph/Paragraph';
import Link from '../../../../Atoms/Navigation/Links/Link';

const InvestorMemorandumEdit = ({ investment, success }) => {
  const [imEditVisible, setImEditVisible] = useState(false);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const onSuccess = () => {
    setImEditVisible(false);
    success();
  };

  return (
    <>
      <Row className="drawer-button">
        <Button type="primary" onClick={() => setImEditVisible(true)}>
          IM
        </Button>
      </Row>
      <Drawer
        title="Investor memorandum"
        width={isMdScreen ? '80vw' : '40vw'}
        closable
        onClose={() => setImEditVisible(false)}
        open={imEditVisible}
      >
        <HeaderTitle
          title="Investor memorandum"
          comment={(
            <Paragraph>
              Attach a different investor memorandum to this investment.
              <br />
              Visit
              {' '}
              <Link to="/app/dealer/documents">document portal</Link>
              {' '}
              to upload a new investor memorandum for the loan.
            </Paragraph>
          )}
        />
        <InvestorMemorandumForm investment={investment} onSuccess={onSuccess} />
      </Drawer>
    </>
  );
};

InvestorMemorandumEdit.propTypes = {
  investment: shape({
    uuid: string.isRequired,
  }).isRequired,
  success: func,
};

InvestorMemorandumEdit.defaultProps = {
  success: () => {},
};

export default InvestorMemorandumEdit;
