import apiGraphQlService from '../Helpers/ApiGraphQLService';
import { investmentFragmentForPanel } from './Accounts.service';

export const investmentAmounts = {
  amt0to200K: { max: 200000 },
  amt200to500K: { min: 200001, max: 500000 },
  amt500Kto1M: { min: 500001, max: 1000000 },
  amt1Mplus: { min: 1000001 },
};

function investmentsQuery(filters, page = 0, pageSize = 25) {
  const variables = {
    offset: page * pageSize,
    limit: pageSize,
    sort: 'changed',
    sortDirection: 'DESC',
    ...filters,
  };

  const query = `
    query investmentsQuery(
      $amount: Range
      $loanBundle: String
      $loanUuid: String
      $showAllForClient: Boolean
      $user: String
      $search: String
      $states: [String]
      $offset: Int
      $limit: Int
      $sort: AccountsSortOrder
      $sortDirection: SortDirection
    ) {
      investments(
        amount: $amount
        loanBundle: $loanBundle
        loanUuid: $loanUuid
        showAllForClient: $showAllForClient
        user: $user
        search: $search
        states: $states
        offset: $offset
        limit: $limit
        sort: $sort
        sortDirection: $sortDirection
      ) {
        total
        results {
          ${investmentFragmentForPanel}
        }
      }
    }`;
  return apiGraphQlService.persistedQuery(query, variables);
}

function reducedInvestmentsQuery(amount, search, user, page = 0, pageSize = 25) {
  const variables = {
    offset: page * pageSize,
    limit: pageSize,
    user,
  };
  if (amount) {
    variables.amount = amount;
  }
  if (search) {
    variables.search = search;
  }
  const query = `
    query investmentsQuery(
      $amount: Range
      $search: String
      $user: String
      $offset: Int
      $limit: Int
      $sort: AccountsSortOrder
      $sortDirection: SortDirection
    ) {
      investments: reducedInvestments(
        amount: $amount
        search: $search
        user: $user
        offset: $offset
        limit: $limit
        sort: $sort
        sortDirection: $sortDirection
      ) {
        total
        results {
          ${investmentFragmentForPanel}
        }
      }
    }
  `;
  return apiGraphQlService.persistedQuery(query, variables);
}

function getInvestorInvestments(amount, search, states, user, page = 0, pageSize = 25) {
  const filters = {
    loanBundle: 'loan',
    states: states || ['live', 'live-subscribed', 'live-complete', 'closed_pending', 'backfill'],
    user,
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getDealerInvestments(amount, search, states, page = 0, pageSize = 25) {
  const filters = {
    loanBundle: 'loan',
    states: states || ['funding', ':live', 'closed_pending', 'backfill'],
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getInvestorClosedInvestments(amount, search, user, page = 0, pageSize = 25) {
  const filters = {
    user,
    states: ['closed'],
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getDealerClosedInvestments(amount, search, page = 0, pageSize = 25) {
  const filters = {
    states: ['closed'],
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getInvestorSecMarketInvestments(amount, search, user, page = 0, pageSize = 25) {
  return reducedInvestmentsQuery(amount, search, user, page, pageSize);
}

function getDealerSecMarketInvestments(amount, search, page = 0, pageSize = 25) {
  return reducedInvestmentsQuery(amount, search, null, page, pageSize);
}

function getInvestorCommitmentInvestments(amount, search, user, page = 0, pageSize = 25) {
  const filters = {
    user,
    states: ['funding', 'live-subscribed'],
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getDealerCommitmentInvestments(amount, search, page = 0, pageSize = 25) {
  const filters = {
    states: ['funding', 'live-subscribed'],
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getInvestorFunds(amount, search, user, page = 0, pageSize = 25) {
  const filters = {
    user,
    loanBundle: 'managed_fund',
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

function getDealerFunds(amount, search, page = 0, pageSize = 25) {
  const filters = {
    loanBundle: 'managed_fund',
  };
  if (search) {
    filters.search = search;
  }
  if (amount) {
    filters.amount = amount;
  }
  return investmentsQuery(filters, page, pageSize);
}

const investmentService = {
  getDealerClosedInvestments,
  getDealerCommitmentInvestments,
  getDealerFunds,
  getDealerInvestments,
  getDealerSecMarketInvestments,
  getInvestorClosedInvestments,
  getInvestorCommitmentInvestments,
  getInvestorFunds,
  getInvestorInvestments,
  getInvestorSecMarketInvestments,
};

export default investmentService;
