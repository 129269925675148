import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Button from '../../../Atoms/Forms/Button/Button';
import Text from '../../../Atoms/Typography/Text/Text';

const PreviousInvestment = (props) => {
  const {
    name, amount, lmsId,
  } = props;

  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Text type="bold">
          {name}
          {' '}
        </Text>
        <Text>{formatCurrencyFull(amount)}</Text>
      </Col>
     <Col>
       <Button type="primary" size="small" disabled>
         Previous commitment
         {` ${lmsId}`}
       </Button>
     </Col>
    </Row>
  );
};

PreviousInvestment.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  lmsId: PropTypes.string.isRequired,
};

export default PreviousInvestment;
