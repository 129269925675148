export const getFieldName = (name, parents = []) => {
  if (typeof parents === 'string') {
    return [parents, name];
  }

  return [...parents, name];
};

export const buildFormValues = (plainValues, parents = {}) => parents.reverse().reduce((result, current) => {
  const a = typeof current === 'number' ? [] : {};
  a[current] = result;
  return a;
}, { ...plainValues });

export default {
  getFieldName,
  buildFormValues,
};
