import moment from 'moment';
// import { getDataSet } from '../Helpers/Helpers';

const useFallbackURL = () => {
  const useFallback = localStorage.getItem('fintech_fallback_cache');
  if (parseInt(useFallback, 10)) {
    return false;
  }
  // TODO Enable fallbackUrl again [ZLAR-138]
  // const fallbackUrl = getDataSet('apiFallbackUrl', 'fintech_api_fallback_url');
  const fallbackUrl = process.env.REACT_APP_FINTECH_URL;
  if (!fallbackUrl) {
    return false;
  }

  localStorage.setItem('fintech_fallback_cache', moment().unix().toString());
  return fallbackUrl;
};

const response = (axios) => {
  axios.interceptors.response.use(
    data => data,
    (error) => {
      const thisError = error;
      const thisAxios = axios;

      if (error.response !== undefined) {
        if (error.response.status >= 200 && error.response.status <= 500) {
          return Promise.reject(error);
        }
      }
      if (thisError.config) {
        const fallbackUrl = useFallbackURL();
        const { baseURL, url } = thisError.config;
        if (fallbackUrl) {
          thisAxios.defaults.baseURL = fallbackUrl;
          thisError.config.url = url.replace(baseURL, fallbackUrl);
          return axios.request(thisError.config);
        }
      }
      return Promise.reject(error);
    },
  );
};

const interceptors = {
  response,
};

export default interceptors;
