import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import './Divider.less';
import { Divider as AntdDivider } from 'antd';

const Divider = args => (
  <AntdDivider className="theme-divider" {...args} />
);

Divider.propTypes = {
  text: string,
  dashed: bool,
  orientation: oneOf(['left', 'right', 'center']),
  plain: bool,
  type: oneOf(['horizontal', 'vertical']),
};

Divider.defaultProps = {
  text: 'https://youtube.com',
  dashed: false,
  orientation: 'center',
  plain: true,
  type: 'horizontal',
};

export default Divider;
