import { gql } from '@apollo/client';

export const CREATE_POLICY = gql`
  mutation policyAdd($input: PolicyInput!){
    policyAdd(input: $input) {
      success
      message
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation policyUpdate($uuid: String!, $input: PolicyInput!) {
    policyUpdate(uuid: $uuid, input: $input) {
      success
      message
    }
  }
`;

export const DELETE_POLICY = gql`
  mutation policyDelete($uuid: String!) {
    policyDelete(uuid: $uuid) {
      success
      message
    }
  }
`;

export const Policy = {
  CREATE_POLICY,
  UPDATE_POLICY,
  DELETE_POLICY,
};

export default Policy;
