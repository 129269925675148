const useShouldUpdateFiles = (prev, current, element) => {
  if (!prev || !current) {
    return false;
  }
  const prevFiles = prev[element] || [];
  const currentFiles = current[element] || [];

  if (currentFiles.length === 0) {
    return false;
  }

  if (prevFiles.length !== currentFiles.length) {
    return true;
  }

  const prevFileIds = prevFiles.map(({ fid }) => fid);
  const currentFileIds = currentFiles.map(({ fid }) => fid);
  return prevFileIds.toString() !== currentFileIds.toString();
};

export default useShouldUpdateFiles;
