import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntdInput } from 'antd';
import './Input.less';

const Input = props => <AntdInput {...props} className="theme-input" />;

Input.propTypes = {
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
  allowClear: PropTypes.bool,
  bordered: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  prefix: PropTypes.node,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  suffix: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
};

Input.defaultProps = {
  addonAfter: undefined,
  addonBefore: undefined,
  allowClear: false,
  bordered: true,
  defaultValue: undefined,
  disabled: false,
  id: undefined,
  maxLength: undefined,
  prefix: undefined,
  size: 'middle',
  suffix: undefined,
  type: 'text',
  value: undefined,
  onChange: () => {},
  onPressEnter: () => {},
};

export default Input;
