import { getLmsAccount } from './loanShortcuts';
import { backfillInvestmentId } from './lmsAccountShortcuts';

const INVESTMENT_STATUS_QUOTE = 0;

const investorInvestmentsInLoan = (loanInvestments, investorClients) => {
  if (Array.isArray(loanInvestments) && Array.isArray(investorClients)) {
    const notNullIntestments = loanInvestments.filter(investment => investment !== null);
    const clientIds = investorClients.map(client => client.uuid);
    return notNullIntestments.filter(investment => clientIds.includes(investment?.fieldClient?.uuid));
  }

  return null;
};

const investorClientInvestmentInLoan = (loanInvestments, clientUuid) => {
  const clientInvestments = investorInvestmentsInLoan(loanInvestments, [{ uuid: clientUuid }]);

  if (Array.isArray(clientInvestments) && clientInvestments.length > 0) {
    return clientInvestments[0];
  }
  return null;
};

const hasInvestments = (investments, clients) => !!investorInvestmentsInLoan(investments, clients);

const investmentClientName = investment => (investment
  ? investment?.fieldClient?.name
  : '');
const investmentClientUuid = investment => (investment ? investment?.fieldClient?.uuid : '');

const isQuote = investment => investment.fieldStatus === INVESTMENT_STATUS_QUOTE;

const isBackfillInvestment = (investment, loan) => {
  const investmentLmsId = investment.fieldLmsAccountId;
  const lmsAccount = getLmsAccount(loan);
  const backfillInvestmentLmsId = backfillInvestmentId(lmsAccount);
  return investmentLmsId === backfillInvestmentLmsId;
};

export default {
  investmentClientName,
  investmentClientUuid,
  investorInvestmentsInLoan,
  investorClientInvestmentInLoan,
  hasInvestments,
  isQuote,
  isBackfillInvestment,
};
