import React, { useState } from 'react';
import IdentityVerificationLogTable from '../../Organisms/Compliance/IdentityVerificationLog/IdentityVerificationLogTable';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import IdentityVerificationLogSearchForm
  from '../../Organisms/Compliance/IdentityVerificationLog/IdentityVerificationLogSearchForm';

const IdentityVerificationLog = () => {
  const [search, setSearch] = useState(undefined);
  return (
    <div className="theme-policies page-inner">
      <HeaderTitle title="Identity Verification Logs" comment="A list of identity verification attempts." />
      <IdentityVerificationLogSearchForm onChange={setSearch} />
      <IdentityVerificationLogTable search={search} />
    </div>
  );
};


export default IdentityVerificationLog;
