import React, { useEffect } from 'react';
import {
  arrayOf, func, number, oneOfType, string,
} from 'prop-types';
import { Col, Form, Row } from 'antd';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import { getFieldName } from '../../../Helpers/Forms';
import DraggerFormItem
  from '../../../Molecules/Forms/DraggerFormItem/DraggerFormItem';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import { findStepEntityByStepKey, getCountry, getTrustEntity } from '../../../Store/clientRegistrationSlice';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import useShouldUpdateFiles from '../../../Hooks/useShouldUpdateFiles';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const UploadTrustDeed = ({
  onFinishLastStep, parents, onGoBack, stepKey,
}) => {
  const [form] = Form.useForm();
  const parentId = useSelector(getTrustEntity);
  const country = useSelector(getCountry);
  const isAu = country === 'AU';

  const { setFieldsValue } = form;

  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuid() };
  useEffect(() => {
    setFieldsValue(stepData);
  }, [stepData]);

  const draggerVerification = (!isAu && [item => useValidationRules.softRequired(item, 'Trust deed document')]) || [];

  return (
    <Row>
      <HeaderTitle
        title="Certified trust deed"
        commentType="grey-box"
        comment={(
          <Paragraph>
            If you do not have this available, you can still complete this registration and
            {' '}
            <b>provide</b>
            {' '}
            it at a later stage.
          </Paragraph>
        )}
      />
      <Col className="gutter-row" span={12}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinishLastStep}
          name="uploadTrustDeedForm"
          initialValues={stepData}
        >
          <StepInfo id={uuid()} stepKey={stepKey} parentId={parentId} />
          <DraggerFormItem
            comment="Please upload copy of your certified trust deed"
            fieldName={getFieldName('trustDeedUpload', parents)}
            fieldLabel="Certified trust deed"
            rules={draggerVerification}
            shouldUpdateComponent={
              (
                { errors: prevErrors, trustDeed: prevTrustDeed },
                { errors: currentErrors, trustDeed: currentTrustDeed },
              ) => useShouldUpdateFiles(prevTrustDeed, currentTrustDeed, 'trustDeedUpload')
                || (prevErrors !== currentErrors)
            }
          />
          <FormItem hidden noStyle name="errors" />
          <ClientRegistrationActions form={form} onGoBack={onGoBack} />
        </Form>
      </Col>
    </Row>
  );
};

UploadTrustDeed.propTypes = {
  onFinishLastStep: func,
  onGoBack: func,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  stepKey: string,
};

UploadTrustDeed.defaultProps = {
  onFinishLastStep: () => {},
  onGoBack: () => {},
  parents: ['trustDeed'],
  stepKey: 'upload-trust-deed-form',
};

export default UploadTrustDeed;
