import React from 'react';
import Link from '../Atoms/Navigation/Links/Link';

const config = [
  {
    brand: 'zagga',
    role: 'dealer',
    topMenus: [
      {
        label: (
          <Link to="/app/dealer/profile">
            my Account
          </Link>
        ),
        key: 'my-account',
      },
      {
        label: (
          <Link to="/app/dealer/sso">
            To Fintech
          </Link>
        ),
        key: 'to-fintech',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/dealer/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/dealer/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
      {
        label: 'Investments',
        key: 'investments',
        children: [
          {
            label: (
              <Link to="/app/dealer/investments/direct">
                Direct
              </Link>
            ),
            key: 'direct',
          },
          {
            label: (
              <Link to="/app/dealer/investments/fund">
                Fund
              </Link>
            ),
            key: 'fund',
          },
          {
            label: (
              <Link to="/app/dealer/investments/closed">
                Closed
              </Link>
            ),
            key: 'closed',
          },
        ],
      },
      {
        label: (
          <Link to="/app/dealer/investment-funds">
            Funds
          </Link>
        ),
        key: 'funds',
      },
      {
        label: (
          <Link to="/app/dealer/documents">
            Documents
          </Link>
        ),
        key: 'documents',
      },
      {
        label: (
          <Link to="/app/dealer/investor-match">
            Invite Investor
          </Link>
        ),
        key: 'invite-investors',
      },
      {
        label: 'Reporting',
        key: 'reporting',
        children: [
          {
            label: (
              <Link to="/app/dealer/reporting/investment-certificates">
                Investment Certificates
              </Link>
            ),
            key: 'investment-certificates',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/investment-statements">
                Investment Statement
              </Link>
            ),
            key: 'investment-statement',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/investments-summary">
                Investments Summary
              </Link>
            ),
            key: 'investments-summary',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/tax-statements">
                Tax Statement
              </Link>
            ),
            key: 'tax-statement',
          },
        ],
      },
      {
        label: 'Compliance',
        key: 'compliance',
        children: [
          {
            label: (
              <Link to="/app/dealer/compliance/policies">
                Policies
              </Link>
            ),
            key: 'policies',
          },
          {
            label: (
              <Link to="/app/dealer/compliance/identity-verification-logs">
                Identity Verification Log
              </Link>
            ),
            key: 'identity-verification-logs',
          },
        ],
      },
    ],
  },
  {
    brand: 'zagga',
    role: 'investor',
    topMenus: [
      {
        label: 'Investor Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/broker/dashboard">
                Introducer Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
          {
            label: (
              <Link to="/app/borrower/dashboard">
                Borrower Portal
              </Link>
            ),
            key: 'borrower-portal',
          },
        ],
      },
      {
        label: 'my Account',
        key: 'my-account',
        children: [
          {
            label: (
              <Link to="/app/investor/profile">
                my Login
              </Link>
            ),
            key: 'my-login',
          },
          {
            label: (
              <Link to="/app/investor/clients">
                my Registration
              </Link>
            ),
            key: 'my-registration',
          },
        ],
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/investor/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: 'Marketplace',
        key: 'marketplace',
        children: [
          {
            label: (
              <Link to="/app/investor/search">
                Search
              </Link>
            ),
            key: 'search',
          },
          {
            label: (
              <Link to="/app/investor/loan-book">
                Loan book
              </Link>
            ),
            key: 'loan-book',
          },
        ],
      },
      {
        label: 'Investments',
        key: 'investments',
        children: [
          {
            label: (
              <Link to="/app/investor/investments/direct">
                Direct
              </Link>
            ),
            key: 'direct',
          },
          {
            label: (
              <Link to="/app/investor/investments/fund">
                Fund
              </Link>
            ),
            key: 'fund',
          },
          {
            label: (
              <Link to="/app/investor/investments/closed">
                Closed
              </Link>
            ),
            key: 'closed',
          },
        ],
      },
      {
        label: (
          <Link to="/app/investor/opportunities">
            Opportunities
          </Link>
        ),
        key: 'opportunities',
      },
      {
        label: 'Documents',
        key: 'documents',
        children: [
          {
            label: (
              <Link to="/app/investor/documents">
                Documents
              </Link>
            ),
            key: 'sub-documents',
          },
          {
            label: (
              <Link to="/app/investor/legal-docs">
                Legal Docs
              </Link>
            ),
            key: 'legal-docs',
          },
        ],
      },
    ],
  },
  {
    brand: 'zagga',
    role: 'borrower',
    topMenus: [
      {
        label: 'Borrower Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/investor/dashboard">
                Investor Portal
              </Link>
            ),
            key: 'investor-portal',
          },
          {
            label: (
              <Link to="/app/broker/dashboard">
                Introducer Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
        ],
      },
      {
        label: (
          <Link to="/app/borrower/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/borrower/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/borrower/clients">
            Registrations
          </Link>
        ),
        key: 'registrations',
      },
      {
        label: 'Loans',
        key: 'loans',
        children: [
          {
            label: (
              <Link to="/app/borrower/apply">
                Apply for a loan
              </Link>
            ),
            key: 'apply',
          },
          {
            label: (
              <Link to="/app/borrower/applications">
                Applications
              </Link>
            ),
            key: 'applications',
          },
          {
            label: (
              <Link to="/app/borrower/loans">
                My Loans
              </Link>
            ),
            key: 'my-loans',
          },
        ],
      },
      {
        label: 'Documents',
        key: 'documents',
        children: [
          {
            label: (
              <Link to="/app/borrower/documents">
                Documents
              </Link>
            ),
            key: 'sub-documents',
          },
          {
            label: (
              <Link to="/app/borrower/legal-docs">
                Legal Docs
              </Link>
            ),
            key: 'legal-docs',
          },
        ],
      },
    ],
  },
  {
    brand: 'zagga',
    role: 'broker',
    topMenus: [
      {
        label: 'Introducer Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/investor/dashboard">
                Investor Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
          {
            label: (
              <Link to="/app/borrower/dashboard">
                Borrower Portal
              </Link>
            ),
            key: 'borrower-portal',
          },
        ],
      },
      {
        label: (
          <Link to="/app/broker/profile">
            my Account
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/broker/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/broker/applications">
            Applications
          </Link>
        ),
        key: 'applications',
      },
      {
        label: (
          <Link to="/app/broker/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/broker/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
      {
        label: (
          <Link to="/app/broker/documents">
            Documents
          </Link>
        ),
        key: 'documents',
      },
    ],
  },
  {
    brand: 'znz',
    role: 'dealer',
    topMenus: [
      {
        label: (
          <Link to="/app/dealer/profile">
            my Account
          </Link>
        ),
        key: 'my-account',
      },
      {
        label: (
          <Link to="/app/dealer/sso?">
            To Fintech
          </Link>
        ),
        key: 'to-fintech',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/dealer/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/dealer/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
      {
        label: (
          <Link to="/app/dealer/investments">
            Investments
          </Link>
        ),
        key: 'investments',
      },
      {
        label: (
          <Link to="/app/dealer/documents">
            Documents
          </Link>
        ),
        key: 'documents',
      },
      {
        label: (
          <Link to="/app/dealer/reductions">
            Reductions
          </Link>
        ),
        key: 'reductions',
      },
      {
        label: 'Reporting',
        key: 'reporting',
        children: [
          {
            label: (
              <Link to="/app/dealer/reporting/investment-certificates">
                Investment Certificates
              </Link>
            ),
            key: 'investment-certificates',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/investment-statements">
                Investment Statements
              </Link>
            ),
            key: 'investment-statement',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/investor-portfolios">
                Investor Portfolios
              </Link>
            ),
            key: 'investor-portfolio',
          },
        ],
      },
      {
        key: 'compliance',
        label: 'Compliance',
        children: [
          {
            label: (
                <Link to="/app/dealer/compliance/policies">
                  Policies
                </Link>
            ),
            key: 'policies',
          },
          {
            label: (
                <Link to="/app/dealer/compliance/identity-verification-logs">
                  Identity Verification Log
                </Link>
            ),
            key: 'identity-verification-logs',
          },
        ],
      },
      {
        label: (
          <Link to="/app/dealer/referrals">
            Referrals
          </Link>
        ),
        key: 'referrals',
      },
    ],
  },
  {
    brand: 'znz',
    role: 'investor',
    topMenus: [
      {
        label: 'Investor Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/broker/dashboard">
                Broker Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
          {
            label: (
              <Link to="/app/borrower/dashboard">
                Borrower Portal
              </Link>
            ),
            key: 'borrower-portal',
          },
        ],
      },
      {
        label: 'my Account',
        key: 'my-account',
        children: [
          {
            label: (
              <Link to="/app/investor/profile">
                my Login
              </Link>
            ),
            key: 'my-login',
          },
          {
            label: (
              <Link to="/app/investor/clients">
                my Registration
              </Link>
            ),
            key: 'my-registration',
          },
        ],
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/investor/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: 'Search Loans',
        key: 'search-loans',
        children: [
          {
            label: (
              <Link to="/app/investor/search">
                Search Loans
              </Link>
            ),
            key: 'sub-search-loans',
          },
          {
            label: (
              <Link to="/app/investor/loan-book">
                Loan book
              </Link>
            ),
            key: 'loan-book',
          },
        ],
      },
      {
        label: 'Investments',
        key: 'investments',
        children: [
          {
            label: (
              <Link to="/app/investor/investments/commitments">
                Commitments
              </Link>
            ),
            key: 'commitments',
          },
          {
            label: (
              <Link to="/app/investor/investments/direct">
                Investments
              </Link>
            ),
            key: 'sub-investments',
          },
        ],
      },
      {
        label: 'Documents',
        key: 'documents',
        children: [
          {
            label: (
              <Link to="/app/investor/documents">
                Documents
              </Link>
            ),
            key: 'sub-documents',
          },
          {
            label: (
              <Link to="/app/investor/legal-docs">
                Legal Docs
              </Link>
            ),
            key: 'legal-docs',
          },
        ],
      },
    ],
  },
  {
    brand: 'znz',
    role: 'borrower',
    topMenus: [
      {
        label: 'Borrower Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/investor/dashboard">
                Investor Portal
              </Link>
            ),
            key: 'investor-portal',
          },
          {
            label: (
              <Link to="/app/broker/dashboard">
                Broker Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
        ],
      },
      {
        label: (
          <Link to="/app/borrower/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/borrower/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/borrower/clients">
            Registrations
          </Link>
        ),
        key: 'registrations',
      },
      {
        label: 'Loans',
        key: 'loans',
        children: [
          {
            label: (
              <Link to="/app/borrower/apply">
                Apply for a loan
              </Link>
            ),
            key: 'apply',
          },
          {
            label: (
              <Link to="/app/borrower/applications">
                Applications
              </Link>
            ),
            key: 'applications',
          },
          {
            label: (
              <Link to="/app/borrower/loans">
                My Loans
              </Link>
            ),
            key: 'my-loans',
          },
        ],
      },
      {
        label: 'Documents',
        key: 'documents',
        children: [
          {
            label: (
              <Link to="/app/borrower/documents">
                Documents
              </Link>
            ),
            key: 'sub-documents',
          },
          {
            label: (
              <Link to="/app/borrower/legal-docs">
                Legal Docs
              </Link>
            ),
            key: 'legal-docs',
          },
        ],
      },
    ],
  },
  {
    brand: 'znz',
    role: 'broker',
    topMenus: [
      {
        label: 'Broker Portal',
        key: 'portal-switcher',
        children: [
          {
            label: (
              <Link to="/app/investor/dashboard">
                Investor Portal
              </Link>
            ),
            key: 'introducer-portal',
          },
          {
            label: (
              <Link to="/app/borrower/dashboard">
                Borrower Portal
              </Link>
            ),
            key: 'borrower-portal',
          },
        ],
      },
      {
        label: (
          <Link to="/app/broker/profile">
            My Account
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'logout',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/broker/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/broker/applications">
            Applications
          </Link>
        ),
        key: 'applications',
      },
      {
        label: (
          <Link to="/app/broker/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/broker/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
      {
        label: (
          <Link to="/app/broker/referrals">
            Referrals
          </Link>
        ),
        key: 'referrals',
      },
      {
        label: (
          <Link to="/app/broker/documents">
            Documents
          </Link>
        ),
        key: 'documents',
      },
    ],
  },
  {
    brand: 'apm',
    role: 'dealer',
    topMenus: [
      {
        label: (
          <Link to="/app/dealer/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/dealer/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/dealer/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
      {
        label: (
          <Link to="/app/dealer/investments">
            Investments
          </Link>
        ),
        key: 'investments',
      },
      {
        label: (
          <Link to="/app/dealer/documents">
            Documents
          </Link>
        ),
        key: 'documents',
      },
      {
        label: 'Reporting',
        key: 'reporting',
        children: [
          {
            label: (
              <Link to="/app/dealer/reporting/investment-certificates">
                Investment Certificates
              </Link>
            ),
            key: 'investment-certificates',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/investment-statements">
                Investment Statement
              </Link>
            ),
            key: 'investment-statement',
          },
          {
            label: (
              <Link to="/app/dealer/reporting/tax-statements">
                Tax Statement
              </Link>
            ),
            key: 'tax-statement',
          },
        ],
      },
      {
        label: 'Compliance',
        key: 'compliance',
        children: [
          {
            label: (
                <Link to="/app/dealer/compliance/policies">
                  Policies
                </Link>
            ),
            key: 'policies',
          },
          {
            label: (
                <Link to="/app/dealer/compliance/identity-verification-logs">
                  Identity Verification Log
                </Link>
            ),
            key: 'identity-verification-logs',
          },
        ],
      },
    ],
  },
  {
    brand: 'apm',
    role: 'investor',
    topMenus: [
      {
        label: (
          <Link to="/app/investor/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/investor/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/investor/investments">
            Investments
          </Link>
        ),
        key: 'investments',
      },
      {
        label: (
          <Link to="/app/investor/opportunities">
            Opportunities
          </Link>
        ),
        key: 'opportunities',
      },
      {
        label: (
          <Link to="/app/investor/legal-docs">
            Legal Docs
          </Link>
        ),
        key: 'legal-docs',
      },
    ],
  },
  {
    brand: 'apm',
    role: 'borrower',
    topMenus: [
      {
        label: (
          <Link to="/app/borrower/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/borrower/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/borrower/clients">
            Registrations
          </Link>
        ),
        key: 'registrations',
      },
      {
        label: 'Loans',
        key: 'loans',
        children: [
          {
            label: (
              <Link to="/app/borrower/applications">
                Applications
              </Link>
            ),
            key: 'applications',
          },
          {
            label: (
              <Link to="/app/borrower/loans">
                My Loans
              </Link>
            ),
            key: 'my-loans',
          },
        ],
      },
      {
        label: (
          <Link to="/app/borrower/legal-docs">
            Legal Docs
          </Link>
        ),
        key: 'legal-docs',
      },
    ],
  },
  {
    brand: 'apm',
    role: 'broker',
    topMenus: [
      {
        label: (
          <Link to="/app/broker/profile">
            my Login
          </Link>
        ),
        key: 'my-login',
      },
      {
        label: (
          <Link to="/app/logout">
            Log out
          </Link>
        ),
        key: 'log-out',
      },
    ],
    roleMenus: [
      {
        label: (
          <Link to="/app/broker/dashboard">
            Dashboard
          </Link>
        ),
        key: 'dashboard',
      },
      {
        label: (
          <Link to="/app/broker/applications">
            Applications
          </Link>
        ),
        key: 'applications',
      },
      {
        label: (
          <Link to="/app/broker/clients">
            Clients
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/app/broker/loans">
            Loans
          </Link>
        ),
        key: 'loans',
      },
    ],
  },
];

export default config;
