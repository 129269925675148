import React, { useEffect, useState, useContext } from 'react';
import { string, shape, func } from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Password from '../../../Atoms/Forms/Password/Password';
import './ChangePassword.less';
import { USER_UPDATE_PASSWORD } from '../../../GraphQL/Mutations/User';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import LangContext from '../../../Context/Lang/LangContext';

const ChangePassword = ({ user, onFinish }) => {
  const { t } = useContext(LangContext);
  const [form] = Form.useForm();
  const [UserUpdatePassword, { loading }] = useMutation(USER_UPDATE_PASSWORD);
  const [message, setMessage] = useState(false);
  const requiredRule = { required: true, message: t('validation.required', { attribute: 'field' }) };

  const handleSubmit = (input) => {
    UserUpdatePassword({
      variables: {
        input: { currentPassword: input.currentPassword, newPassword: input.newPassword },
      },
    })
      .then((result) => {
        setMessage(false);
        onFinish(result);
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  useEffect(() => {
    form.setFieldsValue(user);
  }, [form, user]);

  return (
    <div className="theme-change-password-form">
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={user}
      >
        { message && <Alert message={message} type="error" /> }
        <FormItem
          name="currentPassword"
          label="Current password"
          rules={[requiredRule]}
        >
          <Password placeholder="Current password *" />
        </FormItem>

        <FormItem
          name="newPassword"
          label="New password"
          rules={[
            requiredRule,
            { min: 8, message: 'Password needs to be at least 8 characters!' },
          ]}
        >
          <Password placeholder="New password *" />
        </FormItem>
        <FormItem
          name="password_confirmation"
          dependencies={['password']}
          hasFeedback
          rules={[
            requiredRule,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Password placeholder="Confirm password *" />
        </FormItem>
        <Button block type="primary" htmlType="submit" loading={loading}>
          {loading ? 'Updating' : 'Update password'}
        </Button>
      </Form>
    </div>
  );
};

ChangePassword.propTypes = {
  user: shape({
    uuid: string.isRequired,
  }).isRequired,
  onFinish: func.isRequired,
};

export default ChangePassword;
