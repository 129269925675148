import React from 'react';
import PropTypes from 'prop-types';

const PillBadges = ({ badges, key }) => {
  if (badges.length < 1) {
    return null;
  }

  const badgesMarkup = badges.map((badge, index) => {
    const { title, value, type } = badge;
    if (typeof value === 'undefined' || value === '' || value === null) {
      return null;
    }
    const badgeClasses = ['theme-badge', 'id'];
    if (typeof type === 'undefined') {
      badgeClasses.push('badge-secondary');
    }
    if (type === 'string') {
      badgeClasses.push(`badge-${type}`);
    }
    return (
      // eslint-disable-next-line react/no-array-index-key
      <span key={`badges-${key}-${index}`} className={badgeClasses.join(' ')}>
        {title}
        {': '}
        {value}
      </span>
    );
  });

  return (
    <div className="theme-badge-group">
      { badgesMarkup }
    </div>
  );
};

PillBadges.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  key: PropTypes.string.isRequired,
};

export default PillBadges;
