import axios from '../Vendor/Axios';
import { axiosParams } from '../Helpers/Helpers';
import apiGraphQlService from '../Helpers/ApiGraphQLService';
import { formatDateAsTimestamp } from '../Helpers/FormatDate';

const getShareable = (uuid, token, errorCallback) => axios
  .get(`/api/share/${uuid}/${token}${axiosParams()}`)
  .then((response) => {
    const { data } = response;
    return Promise.resolve(data);
  })
  .catch((error) => {
    if (typeof errorCallback === 'function') {
      errorCallback(error);
    }
  });

const documentFragment = `
  uuid
  changed
  label
  fieldAccess
  fieldCurrent
  fieldCategories {
    fieldCategoryKey
    name
    uuid
  }
  fieldMediaFiles {
    created
    fid
    filemime
    filename
    filesize
    path
    url
    uuid
  }
  fieldClient {
    fieldLmsClientId
    uuid
    name
  }
  fieldAccount {
    fieldLmsAccountId
    label
    type
    uuid
    ... on Loan {
      fieldAmount
      fieldClient {
        fieldLmsClientId
        name
      }
      fieldPrimarySecurity {
        fieldAddress {
          value
        }
      }
    }
    ... on Investment {
      fieldAmount
      fieldClient {
        fieldLmsClientId
        name
      }
      fieldLoan {
        fieldLmsAccountId
        fieldPrimarySecurity {
          fieldAddress {
            value
          }
        }
      }
    }
  }
  `;

const documentCategoryFragment = `
  documentCategories {
    total
    results {
      uuid
      value: fieldCategoryKey
      label: name
      fieldSupportsMultidoc
    }
  }`;

const getUserDocuments = (
  categories,
  search,
  startDate = null,
  endDate = '2099-01-01',
  page = 0,
  pageSize = 25,
  user = null,
) => {
  const changed = {};
  if (startDate !== null) {
    changed.min = formatDateAsTimestamp(startDate);
  }
  if (endDate !== null) {
    changed.max = formatDateAsTimestamp(endDate);
  }
  const offset = page * pageSize;
  const limit = pageSize;
  const query = `
    query documentsQuery(
      $accountUuid: String
      $categories: [String]
      $created: Range
      $changed: Range
      $clientUuid: String
      $limit: Int
      $offset: Int
      $user: String
      $search: String
    ) {
      documents(
        accountUuid: $accountUuid
        categories: $categories
        created: $created
        changed: $changed
        clientUuid: $clientUuid
        limit: $limit
        offset: $offset
        user: $user
        search: $search
      ) {
        total
        results {
          ${documentFragment}
        }
        facets {
          name
          count
        }
      }
      ${documentCategoryFragment}
    }
  `;
  return apiGraphQlService.persistedQuery(query, {
    categories, search, changed, offset, limit, user,
  });
};

const getDealerDocuments = (
  category,
  search,
  startDate = '0',
  endDate = '2099-01-01',
  page = 0,
  pageSize = 25,
) => getUserDocuments(category, search, startDate, endDate, page, pageSize);

const getOwnerDocuments = (
  category,
  search,
  startDate = '0',
  endDate = '2099-01-01',
  page = 0,
  pageSize = 25,
  uuid,
) => getUserDocuments(category, search, startDate, endDate, page, pageSize, uuid);

const getCategories = () => {
  const query = `
  {
    ${documentCategoryFragment}
  }`;
  return apiGraphQlService.query(query);
};

const getDocument = (uuid) => {
  const query = `
    query documentQuery(
      $uuid: String
    ) {
      document(uuid: $uuid) {
        ${documentFragment}
      }
    }`;
  return apiGraphQlService.persistedQuery(query, { uuid });
};

const uploadFile = (options) => {
  const {
    file, onSuccess, onError, onProgress,
  } = options;

  const reader = new FileReader();
  reader.onload = (loadedFile) => {
    const config = {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `file; filename="${file.name}"`,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    axios.post('/file/upload/media/document/field_media_files', reader.result, config)
      .then((res) => {
        onSuccess(res.data, loadedFile);
      })
      .catch((err) => {
        onError(err);
      });
  };

  reader.readAsArrayBuffer(file);
};

const createDocument = (docs, onSuccess, onError) => {
  axios.post('/api/document', docs)
    .then((response) => {
      if (onSuccess !== undefined) {
        onSuccess(response);
      }
    })
    .catch((error) => {
      if (onError !== undefined) {
        onError(error);
      }
    });
};

const updateDocument = (mediaUuid, docs, onSuccess, onError) => {
  axios.patch(`/api/document/${mediaUuid}`, docs)
    .then((response) => {
      if (onSuccess !== undefined) {
        onSuccess(response);
      }
    })
    .catch((error) => {
      if (onError !== undefined) {
        onError(error);
      }
    });
};

const deleteDocument = (mediaUuid, onSuccess, onError) => {
  axios.delete(`/api/document/${mediaUuid}${axiosParams()}`)
    .then((response) => {
      if (onSuccess !== undefined) {
        onSuccess(response);
      }
    })
    .catch((error) => {
      if (onError !== undefined) {
        onError(error);
      }
    });
};

const documentsService = {
  getDocument,
  createDocument,
  updateDocument,
  deleteDocument,
  uploadFile,
  getShareable,
  getDealerDocuments,
  getOwnerDocuments,
  getCategories,
};

export default documentsService;
