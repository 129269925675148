import React from 'react';
import { useParams } from 'react-router-dom';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import Text from '../../../Atoms/Typography/Text/Text';
import Link from '../../../Atoms/Navigation/Links/Link';
import './ClientViewLink.less';

const ClientViewLink = ({ uuid }) => {
  const { context } = useParams();
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Link
      className="theme-client-view-link"
      to={`/app/${context}/client/${uuid}`}
    >
      {isMdScreen ? <FontAwesomeIcon icon={faEye} /> : <Text>View</Text>}
    </Link>
  );
};

ClientViewLink.propTypes = {
  uuid: string.isRequired,
};

export default ClientViewLink;
