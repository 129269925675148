import React from 'react';
import './AccountDocuments.less';
import { useQuery } from '@apollo/client';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import NoResults from '../../Molecules/NoResult/NoResult';
import { ACCOUNT_DOCUMENTS } from '../../GraphQL/Queries/Documents';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import DocumentLink from '../../Atoms/Navigation/Links/DocumentLink';
import {
  isRegisteredInvestor as _registeredInvestor,
  isRegisteredBorrower as _registeredBorrower,
  isRegisteredBroker as _registeredBroker,
  isDealer as _isDealer,
} from '../../Store/userSlice';

const AccountDocuments = ({ files }) => {
  const { context } = useParams();
  const isDealer = useSelector(_isDealer);
  const isRegisteredBorrower = useSelector(_registeredBorrower);
  const isRegisteredInvestor = useSelector(_registeredInvestor);
  const isRegisteredBroker = useSelector(_registeredBroker);

  const isRegistered = (isRegisteredInvestor && context === 'investor')
    || (isRegisteredBorrower && context === 'borrower')
    || (isRegisteredBroker && context === 'broker');

  if (!Array.isArray(files) || files.length === 0) {
    if (isRegistered || isDealer) {
      return <NoResults title="No documents uploaded yet." />;
    }
    const title = (
      <>
        You need to be a
        {' '}
        <Link to={`/app/${context}/client/add`}>
          registered client
        </Link>
        {' '}
        to view documentation for this account.
      </>
    );
    return <NoResults title={title} />;
  }

  const filesMarkup = files.map(file => (
    <Col xs={6} sm={4} md={3} lg={2} key={file.uuid} className="theme-account-document">
      <DocumentLink
        className="theme-button inner-wrapper"
        file={file}
        localDocPath={`/document/${file.mediaUuid}/${file.uuid}`}
      >
        {file.filename}
      </DocumentLink>
    </Col>
  ));

  return (
    <Row gutter={[16, 16]} className="theme-account-documents">
      {filesMarkup}
    </Row>
  );
};

const adjustedMediaList = (media) => {
  // Remove any null media items from list.
  const filteredMedia = media?.filter(doc => doc !== null) || [];
  let files = [];
  // Append media uuid to individual file items.
  for (let i = 0; i < filteredMedia.length; i += 1) {
    files = files.concat(
      filteredMedia[i].fieldMediaFiles.map(file => ({
        ...file,
        mediaUuid: filteredMedia[i].uuid,
      })),
    );
  }
  return { files };
};

const LoanDocuments = ({ media }) => AccountDocuments(adjustedMediaList(media));

const InvestmentDocuments = ({ media }) => AccountDocuments(adjustedMediaList(media));

const InvestmentPlusLoanDocuments = ({ media, loanUuid }) => {
  const { context } = useParams();
  const isDealer = useSelector(_isDealer);
  const isRegisteredBorrower = useSelector(_registeredBorrower);
  const isRegisteredInvestor = useSelector(_registeredInvestor);
  const isRegisteredBroker = useSelector(_registeredBroker);
  const { loading, data } = useQuery(ACCOUNT_DOCUMENTS, { variables: { accountUuid: loanUuid } });

  if (loading) {
    return (
      <Skeleton loading={loading} paragraph={{ rows: 1 }} />
    );
  }

  const { files } = adjustedMediaList([...media, ...(data?.documents?.results ?? [])]);

  const isRegistered = (isRegisteredInvestor && context === 'investor')
    || (isRegisteredBorrower && context === 'borrower')
    || (isRegisteredBroker && context === 'broker');

  if (!Array.isArray(files) || files.length === 0) {
    if (isRegistered || isDealer) {
      return <NoResults title="No documents uploaded yet." />;
    }
    const title = (
      <>
        You need to be a
        {' '}
        <Link to={`/app/${context}/client/add`}>
          registered client
        </Link>
        {' '}
        to view documentation for this account.
      </>
    );
    return <NoResults title={title} />;
  }

  const filesMarkup = files.map(file => (
    <Col xs={6} sm={4} md={3} lg={2} key={file.uuid} className="theme-account-document">
      <DocumentLink
        className="theme-button inner-wrapper"
        file={file}
        localDocPath={`/document/${file.mediaUuid}/${file.uuid}`}
      >
        {file.filename}
      </DocumentLink>
    </Col>
  ));

  return (
    <Row gutter={[16, 16]} className="theme-account-documents">
      {filesMarkup}
    </Row>
  );
};

export {
  LoanDocuments,
  InvestmentDocuments,
  InvestmentPlusLoanDocuments,
};
