import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getContextLoanState, haveEntityPermission } from '../../Helpers/Helpers';
import AccountPanel from '../../Molecules/Panel/AccountPanel';
import { getAppInvestmentConditions } from '../../Store/appConfigurationSlice';

const LoanPanel = (props) => {
  const investConfig = useSelector(getAppInvestmentConditions);
  const {
    loan, linkPrefix, showClient, rateFieldName, context, investmentId,
  } = props;

  const {
    uuid,
    fieldLmsAccountId,
    fieldAmount,
    fieldState,
    fieldRisk,
    fieldClient: {
      name: clientName,
    },
    fieldPrimarySecurity,
    loanTitle,
  } = loan;

  let loanState = getContextLoanState(context, fieldState);
  if (fieldState === 'backfill' && investConfig?.secondaryMarketEnabled) {
    loanState = 'Secondary Market';
  }

  const rate = loan?.currentPool?.[rateFieldName];
  const image = fieldPrimarySecurity?.fieldImages?.[0];

  return (
    <AccountPanel
      key={uuid}
      context={context}
      investmentId={investmentId}
      loanAmount={fieldAmount}
      loanId={fieldLmsAccountId}
      loanState={loanState}
      loanImage={image}
      link={`${linkPrefix}/${uuid}`}
      rate={(haveEntityPermission(loan, 'View rates') && rate)}
      risk={fieldRisk}
      subtitle={showClient && clientName}
      title={loanTitle}
    />
  );
};

LoanPanel.propTypes = {
  loan: PropTypes.shape({}).isRequired,
  linkPrefix: PropTypes.string.isRequired,
  rateFieldName: PropTypes.string,
  showClient: PropTypes.bool,
  context: PropTypes.string.isRequired,
};

LoanPanel.defaultProps = {
  showClient: false,
  rateFieldName: 'fieldInvestmentRate',
};

export default LoanPanel;
