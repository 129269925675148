import React from 'react';
import { func } from 'prop-types';
import Modal from '../../../Atoms/Feedback/Modal/Modal';
import Text from '../../../Atoms/Typography/Text/Text';
import { OFFER_SENT_MESSAGE } from '../../../Resources/OfferMessage';

const LoanOfferSent = ({ modalClose }) => (
  <Modal open title={OFFER_SENT_MESSAGE.title} onOk={modalClose} onCancel={modalClose} cancelText="">
    <Text>{OFFER_SENT_MESSAGE.message}</Text>
  </Modal>
);

LoanOfferSent.propTypes = {
  modalClose: func.isRequired,
};

export default LoanOfferSent;
