import { gql } from '@apollo/client';
import { PERMISSIONS_FRAGMENT, ADDRESS_FRAGMENT } from './Fragments';

export const LOAN_FIELDS_FOR_PANEL = gql`
    ${PERMISSIONS_FRAGMENT}
    ${ADDRESS_FRAGMENT}
    fragment LoanFieldsForPanel on Loan {
        uuid
        entityPermissions {
            ...permissionsFragment
        }
        fieldAmount
        fieldLmsAccountId
        fieldState
        loanTitle
        currentPool {
            fieldRate
            fieldInvestmentRate
            term
        }
        fieldPrimarySecurity {
            fieldAddress {
                ...addressFragment
            }
            fieldImages {
                propertyThumbnail
                original
            }
        }
        fieldClient {
            uuid
            name
        }
        fieldLoanProduct {
            label
        }
    }
`;

export const MANAGED_FUND_FIELDS_FOR_PANEL = gql`
    fragment ManagedFundFieldsForPanel on ManagedFund {
        uuid
        label
        fieldFundState
        fieldLmsPoolId
        fieldTargetRate
        fieldImages {
            propertyThumbnail
        }
    }
`;

export const INVESTMENT_FIELDS_FOR_PANEL = gql`
    ${LOAN_FIELDS_FOR_PANEL}
    ${MANAGED_FUND_FIELDS_FOR_PANEL}
    fragment InvestmentFieldsForPanel on Investment {
        uuid
        investmentRate
        displayState
        fieldAmount
        fieldLmsAccountId
        fieldState
        fieldLoan {
            ...LoanFieldsForPanel
        }
        fieldManagedFund {
            ...ManagedFundFieldsForPanel
        }
        fieldClient {
            uuid
            name
        }
        fieldPool {
            fieldRate
            fieldInvestmentRate
            term
        }
        reductions {
            fieldAmount
            fieldExpiry
            fieldStatus
        }
    }
`;
