import React from 'react';
import {
  bool, string, shape,
} from 'prop-types';
import { Col, Row } from 'antd';
import ForgotPasswordForm from '../../Molecules/Forms/ForgotPasswordForm/ForgotPasswordForm';
import Image from '../../Atoms/Media/Image/Image';
import './ForgotPassword.less';
import Divider from '../../Atoms/Layout/Divider/Divider';
import Button from '../../Atoms/Forms/Button/Button';

const ForgotPassword = ({
  data: {
    logo: { alt, preview },
  },
}) => {
  const logoSrc = `/assets/logos/logo-white-${process.env.REACT_APP_X_DEALER}.svg`;

  return (
    <div className="theme-forgot-password">
      <Row className="theme-columns-wrapper">
        <Col>
          <Image alt={alt} src={logoSrc} preview={preview} />
        </Col>
        <Col>
          <ForgotPasswordForm />
          <Divider dashed />
          <Button block href="/app/login" ghost type="primary">
            Back to login
          </Button>
        </Col>
      </Row>
    </div>
  );
};

ForgotPassword.propTypes = {
  data: shape({
    title: string,
    logo: shape({
      alt: string,
      preview: bool,
    }),
  }),
};

ForgotPassword.defaultProps = {
  data: {
    logo: {
      alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
      preview: false,
    },
  },
};

export default ForgotPassword;
