import React from 'react';
import { Space as AntdSpace } from 'antd';
import {
  arrayOf, bool, node, number, oneOf,
} from 'prop-types';
import './Space.less';

const Space = props => <AntdSpace {...props} className="theme-space" />;

Space.propTypes = {
  align: oneOf(['start', 'end', 'center', 'baseline']),
  direction: oneOf(['vertical', 'horizontal']),
  size: oneOf(['small', 'middle', 'large', number, arrayOf(
    oneOf(['small', 'middle', 'large', number]),
  )]),
  split: node,
  wrap: bool,
};

Space.defaultProps = {
  align: undefined,
  direction: 'horizontal',
  size: 'small',
  split: undefined,
  wrap: false,
};

export default Space;
