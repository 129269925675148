import React from 'react';
import { Form, InputNumber } from 'antd';
import { moneyFormatterParser } from '../../../Helpers/Helpers';

const LoanAmount = () => (
  <Form.Item
    label="Loan Amount"
    className="loan-amount"
    name="amount"
    rules={[{ required: true, message: 'Please input loan amount.' }]}
    initialValues={{ amount: '1000000' }}
  >
    <InputNumber
      min={100000}
      step={10000}
      {...moneyFormatterParser()}
    />
  </Form.Item>
);

export default LoanAmount;
