import React from 'react';
import { shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import { nextRepayment as _nextRepayment } from '../../../Helpers/lmsAccountShortcuts';
import formatDate from '../../../Helpers/FormatDate';
import { dealer } from '../../../Hooks/useLocalStorage';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const LoanPayments = ({
  accountState, lmsAccount,
}) => {
  if (!lmsAccount) {
    return null;
  }

  const { context, pageContext } = useParams();

  const nextRepayment = _nextRepayment(lmsAccount);
  if (!nextRepayment) {
    return null;
  }

  const showForInvestment = dealer() === 'znz'
    && pageContext === 'investment'
    && ['live', 'live-subscribed', 'live-complete'].indexOf(accountState) !== -1;

  const showForLoan = pageContext !== 'investment'
    && ['borrower', 'dealer'].indexOf(context) !== -1
    && ['live', 'live-subscribed', 'live-complete'].indexOf(accountState) !== -1;

  const { ddate, amount } = nextRepayment;

  const fields = [{
    label: 'Next repayment due',
    value: (
      <Paragraph>
        {formatDate(ddate)}
        <br />
        {formatCurrencyFull(amount)}
      </Paragraph>
    ),
  }];

  return (showForLoan || showForInvestment) && (
    <>
      <Card
        title={(<Title level={4}>Payment details</Title>)}
      >
        <Fields fields={fields} />
      </Card>
    </>
  );
};

LoanPayments.propTypes = {
  accountState: string.isRequired,
  lmsAccount: shape({}),
};

LoanPayments.defaultProps = {
  lmsAccount: null,
};

export default LoanPayments;
