import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getGraphQlErrorMessage } from '../Helpers/Error';
import { userUuid } from './useLocalStorage';
import { CREATE_DRAFT, DELETE_DRAFT, UPDATE_DRAFT } from '../GraphQL/Mutations/Draft';
import { convertDraftMomentsToDate, flattenEntities } from './useCommonConvertedEntityData';
import useApplicationDrafts from './useApplicationDrafts';

export const draftName = {
  client: (values) => {
    const clientStructureStep = values.find(step => step.stepKey === 'set-account-structure-form');
    if (clientStructureStep) {
      const { role, type, name } = clientStructureStep;
      return `${role} ${type} ${name}`;
    }

    return 'Empty draft';
  },
  application: (values) => {
    const applicationData = flattenEntities(values);
    return applicationData.clientName || 'Empty draft';
  },
};

const useDraft = (
  draftType, draftUuid, context, form, entities, setDraftUuid,
) => {
  const { query, variables } = useApplicationDrafts();
  const options = { refetchQueries: [{ query, variables }] };
  const [SaveDraft, { loading: createDraftLoading }] = useMutation(CREATE_DRAFT, options);
  const [UpdateDraft, { loading: updateDraftLoading }] = useMutation(UPDATE_DRAFT, options);
  const [DeleteDraft, { loading: deleteDraftLoading }] = useMutation(DELETE_DRAFT, options);

  const dispatch = useDispatch();

  const onDeleteDraft = useCallback(() => {
    DeleteDraft({ variables: { input: { uuid: draftUuid } } })
      .then(() => { window.location = `/app/${context}/${draftType}s/drafts`; })
      .catch(error => console.log(getGraphQlErrorMessage(error)));
  }, [draftUuid]);

  const onSaveDraft = bundle => () => {
    // @todo Refactor uuid to use redux.
    const uuid = userUuid();
    const formValues = form.getFieldsValue();

    let allEntities = entities;
    if (!entities.find(entity => entity.id === formValues.id)) {
      allEntities.push(formValues);
    }
    if (entities.find(entity => entity.id === formValues.id)) {
      allEntities = entities.map(entity => (entity.id === formValues.id ? formValues : entity));
    }

    const draftInput = {
      owner: uuid,
      data: JSON.stringify(convertDraftMomentsToDate(allEntities)),
      title: draftName[bundle](allEntities),
    };

    if (typeof draftUuid !== 'undefined' && draftUuid) {
      UpdateDraft({ variables: { input: { ...draftInput, uuid: draftUuid } } })
        .catch(error => console.log(getGraphQlErrorMessage(error)));
      return;
    }

    SaveDraft({ variables: { input: { ...draftInput, type: bundle } } })
      .then((response) => {
        const { data: { draftCreate: { json } } } = response;
        const { uuid: responseUuid } = JSON.parse(json);
        dispatch(setDraftUuid(responseUuid));
      })
      .catch(error => console.log(getGraphQlErrorMessage(error)));
  };

  return {
    createDraftLoading,
    updateDraftLoading,
    deleteDraftLoading,
    onSaveDraft,
    onDeleteDraft,
  };
};

export default useDraft;
