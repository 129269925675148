import moment from 'moment';
import { notification } from 'antd';
import axios from '../Vendor/Axios';
import { dealer } from './useLocalStorage';

const useFile = () => {
  const view = (file, callback = null) => {
    const {
      url, filename,
    } = file;

    const brandName = dealer() !== 'apm' ? 'Zagga' : 'APM';
    const defaultFilename = `${brandName}_document_${moment().format('DD-MM-YYYY--HH:m')}`;

    axios.get(url, { responseType: 'blob' })
      .then(response => response.data)
      .then((blob) => {
        const link = document.createElement('a');
        const href = window.URL.createObjectURL(blob);
        link.href = href;
        link.setAttribute('download', filename || defaultFilename);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(href);
        callback?.();
      }).catch(() => {
        notification.open({
          type: 'error',
          message: 'An error occurred opening the document.',
          duration: 2,
        });
      });
  };

  return { view };
};

export default useFile;
