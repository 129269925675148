import React from 'react';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import UsersTable from '../../Organisms/Users/UsersTable';

const Users = () => (
  <div className="theme-client page-inner">
    <HeaderTitle
      title="Users"
      comment="A list of users."
    />
    <UsersTable />
  </div>
);

export default Users;
