import React, { useState } from 'react';
import { shape } from 'prop-types';
import { EyeOutlined } from '@ant-design/icons';
import { stringify } from 'yaml';
import Drawer from '../../../Atoms/Layout/Drawer/Drawer';
import Text from '../../../Atoms/Typography/Text/Text';

const FailureMessageCell = (props) => {
  const { value } = props;
  const [open, setOpen] = useState(false);

  if (!value) return [];

  return (
    <>
      <EyeOutlined onClick={() => setOpen(true)} />
      <Drawer title="Failure message" placement="right" onClose={() => setOpen(false)} open={open}>
        <Text className="theme-field-value info-value">{stringify(JSON.parse(value))}</Text>

      </Drawer>
    </>
  );
};

FailureMessageCell.propTypes = {
  value: shape({}),
};

FailureMessageCell.defaultProps = {
  value: null,
};

export default FailureMessageCell;
