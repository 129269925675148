import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Atoms/Forms/Button/Button';
import Text from '../../Atoms/Typography/Text/Text';
import './InvestorCheckbox.less';

const InvestorCheckbox = (
  {
    name,
    checked,
    onClick,
  },
) => (
  <Button type={`${checked ? 'secondary' : 'default'}`} ghost className={`theme-investor-checkbox ${checked ? 'checked' : 'unchecked'}`} onClick={onClick}>
    <FontAwesomeIcon icon={faCheck} />
    <Text className="name">{ name }</Text>
  </Button>
);

export default InvestorCheckbox;
