import { gql } from '@apollo/client';

export const CONFIGURATION_BY_KEY = gql`
  query configurationByKey($type: ConfigurationType! $key: ConfigurationKey!) {
    configuration(type: $type key: $key) {
      key
      configuration
    }
  }
`;

export const POLICY_DEFINITION_BY_KEY = gql`
  query policyByKey($key: ConfigurationKey!) {
    configuration(type: policy_configuration key: $key) {
      key
      policy: configuration
    }
  }
`;

export const CONFIGURATIONS = gql`
  query configurations($keys: [ConfigurationKey]!) {
    configurations(keys: $keys) {
      results {
        key
        configuration
      }
    }
  }
`;

export const SCHEMA = gql`
  query Introspection {
    __schema {
      types {
        name
        inputFields {
          name
          type {
            name
          }
        }
        fields {
          name
          type {
            name
            ofType {
              name
            }
          }
        }
      }
    }
  }
`;

export const Configuration = {
  CONFIGURATION_BY_KEY,
  POLICY_DEFINITION_BY_KEY,
  CONFIGURATIONS,
  SCHEMA,
};

export default Configuration;
