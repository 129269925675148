import React from 'react';
import { oneOf, node } from 'prop-types';
import { Result as AntdResult } from 'antd';
import './Result.less';

const Result = args => <AntdResult {...args} className="theme-result" />;

Result.propTypes = {
  status: oneOf(['success', 'error', 'info', 'warning', '404', '403', '500']),
  title: node,
  subTitle: node,
  extra: node,
};

Result.defaultProps = {
  status: 'info',
  title: '',
  subTitle: '',
  extra: null,
};

export default Result;
