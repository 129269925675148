import React from 'react';
import {
  bool, func, node, oneOfType, string,
} from 'prop-types';
import { Popconfirm as AntdPopconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import Button from '../Button/Button';
import './Popconfirm.less';

const Popconfirm = args => <AntdPopconfirm {...args} />;

Popconfirm.propTypes = {
  // cancelButtonProps: Button.propTypes,
  cancelText: string,
  disabled: bool,
  icon: node,
  // okButtonProps: Button.propTypes,
  okText: string,
  title: oneOfType([node, func]),
  onCancel: func,
  onConfirm: func,
};

Popconfirm.defaultProps = {
  // cancelButtonProps: undefined,
  cancelText: 'Cancel',
  disabled: false,
  icon: <ExclamationCircleOutlined />,
  // okButtonProps: undefined,
  okText: 'OK',
  title: undefined,
  onCancel: () => {},
  onConfirm: () => {},
};
export default Popconfirm;
