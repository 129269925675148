import { gql } from '@apollo/client';

export const OPPORTUNITIES = gql`
  query upcomingOpportunitiesQuery(
      $offset: Int
      $limit: Int
    ) {
      upcomingOpportunities(
        offset: $offset
        limit: $limit
      ) {
        total
        results {
            title
            changed
            fieldState
            fieldDescription
        }
      }
    }
`;

export const UpcomingOpportunities = {
  OPPORTUNITIES,
};

export default UpcomingOpportunities;
