import React from 'react';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import { func, shape, string } from 'prop-types';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../Atoms/Forms/Input/Input';
import Button from '../../../Atoms/Forms/Button/Button';
import Address from '../../../Molecules/Forms/Address/Address';
import { UPDATE_CLIENT } from '../../../GraphQL/Mutations/Client';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import './UpdateClient.less';
import { dealerDefaultCountry } from '../../../Hooks/useLocalStorage';
import { firstKey } from '../../../Helpers/Object/Object';
import useGraphQlNotifications from '../../../Hooks/useGraphQLNotifications';

const UpdateClient = ({ client, onSuccess }) => {
  const [form] = Form.useForm();
  const [ClientUpdate, { loading }] = useMutation(UPDATE_CLIENT);
  const { notificationFromResponse, notificationFromError } = useGraphQlNotifications();

  const {
    uuid,
    fieldAddress: address,
    fieldPhone: contactNumber,
  } = client;
  const clientData = {
    uuid,
    contactNumber,
    address: address || { country_code: dealerDefaultCountry() },
  };

  const handleSubmit = (inputData) => {
    const input = {
      client: {
        individual: { ...inputData },
      },
    };
    ClientUpdate({ variables: { ...input } })
      .then((response) => {
        const success = response?.data?.[firstKey(response?.data)]?.success;
        notificationFromResponse(
          response,
          'Client details saved.',
          'Client details could not be saved.',
        );
        if (success) onSuccess();
      }).catch(notificationFromError);
  };

  return (
    <div className="theme-update-client-form">
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={clientData}
      >
        <FormItem
          hidden="true"
          name="uuid"
        >
          <Input />
        </FormItem>
        <Address
          form={form}
          parents={['address']}
          defaultCountry={clientData?.address?.country_code || dealerDefaultCountry()}
        />
        <InputGroup>
          <FormItem
            label="Phone"
            name="contactNumber"
            fieldKey="contactNumber"
          >
            <Input
              placeholder="Phone number"
            />
          </FormItem>
        </InputGroup>
        <Button type="primary" htmlType="submit" loading={loading}>{loading ? 'Updating' : 'Update client'}</Button>
      </Form>
    </div>
  );
};

UpdateClient.propTypes = {
  client: shape({
    uuid: string.isRequired,
    address: shape({
      address_line1: string,
      address_line2: string,
      dependent_locality: string,
      locality: string,
      administrative_area: string,
      postal_code: string,
      country_code: string,
    }),
  }).isRequired,
  onSuccess: func.isRequired,
};

export default UpdateClient;
