import React from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../../Helpers/Forms';
import Input from '../../../../Atoms/Forms/Input/Input';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';
import './BirthCertificate.less';
import DatePickerFormItem from '../../DatePickerFormItem/DatePickerFormItem';

const BirthCertificate = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => (
  <>
    <InputGroup className="theme-birth-certificate">
      <DatePickerFormItem
        dateRestriction="past"
        fieldName="birthdate"
        label="Date of birth"
        parents={parents}
        validationRules={[item => useValidationRules.softRequired(item, 'Certificate date', deleteExcept)]}
      />

      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Number"
            name={getFieldName('certificateNumber', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Certificate number', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('certificateNumber', parents), getFieldValue('errors'))}
          >
            <Input />
          </FormItem>
        )}
      </FormItem>
    </InputGroup>
    <DraggerFormItem
      comment="Please upload a copy of your birth certificate."
      fieldName={getFieldName('birthCertificateDocument', parents)}
      label="Upload identification"
      rules={[item => useValidationRules.softRequired(item, 'Certificate document', deleteExcept)]}
      shouldUpdateComponent={shouldUpdateComponent}
    />
  </>
);

BirthCertificate.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

BirthCertificate.defaultProps = {
  parents: ['birth_certificate'],
  deleteExcept: ['personVerification.birthdate', 'personVerification.certificateNumber', 'personVerification.birthCertificateDocument'],
};

export default BirthCertificate;
