import React from 'react';
import { useParams } from 'react-router-dom';
import { shape, node } from 'prop-types';
import SectionTitle from '../../Molecules/DataDisplay/Sections/SectionTitle';
import { brokerLabel } from '../../Hooks/useRoleLabelFormatter';
import ClientViewLink from '../../Molecules/Navigation/Links/ClientViewLink';
import './BrokerDetails.less';
import { titleCase } from '../../Hooks/useStringFormatter';
import Card from '../../Molecules/Cards/Card';
import Title from '../../Atoms/Typography/Title/Title';
import Fields from '../../Molecules/DataDisplay/Fields/Fields';

const BrokerDetails = ({ acting, children }) => {
  const { context } = useParams();

  if (!acting) {
    return null;
  }
  const { fieldClient: client } = acting;
  const { fieldLmsClientId, type, name } = client || { };

  const fields = [
    { uniqueKey: 'name', label: 'Name', value: name },
    { uniqueKey: 'lmsId', label: 'Client ID', value: fieldLmsClientId },
    { uniqueKey: 'entityType', label: 'Entity type', value: titleCase(type) },
  ];

  return (
    <div className="theme-broker-details">
      <SectionTitle title={`${brokerLabel()} details`} level={3} />
      <Card
        className="theme-client-details"
        title={(<Title level={4}>{name}</Title>)}
        extra={(context === 'broker' || context === 'dealer') && <ClientViewLink uuid={client?.uuid} />}
      >
        <Fields key={client?.uuid} fields={fields} />
      </Card>
      {children}
    </div>
  );
};

BrokerDetails.propTypes = {
  acting: shape([]).isRequired,
  children: node,
};

BrokerDetails.defaultProps = {
  children: null,
};

export default BrokerDetails;
