const iconBasePath = () => '/assets/icons';

export const DocumentIcon = () => `${iconBasePath()}/icon-document.svg`;

export const PDFIcon = () => `${iconBasePath()}/icon-pdf.svg`;

export const TextIcon = () => `${iconBasePath()}/icon-text.svg`;

export const SheetIcon = () => `${iconBasePath()}/icon-sheet.svg`;

export const ImageIcon = () => `${iconBasePath()}/icon-image.svg`;

export const MultiIcon = () => `${iconBasePath()}/icon-multi.svg`;

export const DocumentSelectIcon = (extension) => {
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'application/msword':
      return DocumentIcon();
    case 'pdf':
    case 'application/pdf':
      return PDFIcon();
    case 'xls':
    case 'xlsx':
      return SheetIcon();
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'image/png':
    case 'image/jpeg':
      return ImageIcon();
    case 'multi':
      return MultiIcon();
    default:
      return TextIcon();
  }
};
