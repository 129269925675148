/* eslint-disable */
import React from 'react';
import {
  string, shape, number, func,
} from 'prop-types';
import { Col, Row } from 'antd';
import moment from 'moment';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import CloseReductionForm from '../../../Organisms/Forms/Accounts/Investments/SecondaryMarket/CloseReductionForm';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import Progress from '../../../Atoms/Feedback/Progress/Progress';
import Statistic from '../../../Atoms/DataDisplay/Statistic/Statistic';
import Fields from '../../DataDisplay/Fields/Fields';
import { formatUnixDate } from '../../../Helpers/FormatDate';

const ActiveReduction = ({ reduction, title, refetch }) => {
  const {
    fieldStatus, fieldAmount, created, fieldExpiry, amountFunded, fieldBreakFee,
  } = reduction;

  const remaining = moment.unix(fieldExpiry).diff(moment.now(), 'days');

  const progress = (amount, funded) => {
    const output = (funded / amount) * 100;
    return output > 100 ? 100 : output.toFixed(2);
  };

  const fields = [
    { label: 'Status', value: fieldStatus.toUpperCase() },
    { label: 'Offer amount', value: <Currency value={fieldAmount} /> },
    { label: 'Break fee', value: <Currency value={fieldBreakFee} /> },
    { label: 'Created', value: formatUnixDate(created) },
    { label: 'Expires', value: formatUnixDate(fieldExpiry) },
    { label: 'Days remaining', value: <Statistic value={remaining} /> },
    { label: 'Funded', value: <Currency value={amountFunded || 0} /> },
    { label: 'Progress', value: <Progress type="line" percent={progress(fieldAmount, amountFunded)} /> },
  ];


  return (
    <Card
      title={(<Title level={4}>{title}</Title>)}
    >
      <Fields fields={fields} />
      <Row>
        <Col xs={12} md={12}>
          <CloseReductionForm reduction={reduction} refetch={refetch} />
        </Col>
      </Row>
    </Card>
  );
};

ActiveReduction.propTypes = {
  title: string.isRequired,
  reduction: shape({
    uuid: string,
    fieldStatus: 'pending',
    fieldAmount: number,
    created: number,
    changed: number,
    fieldExpiry: number,
  }).isRequired,
  refetch: func,
};

ActiveReduction.defaultProps = {
  refetch: () => {},
};

export default ActiveReduction;
