import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import Button from '../../../Atoms/Forms/Button/Button';
import AccountSelect from '../Fields/AccountSelect/AccountSelect';

export const getDefaultPeriod = () => ({
  from: null,
  to: null,
});

const InvestmentStatementForm = (props) => {
  const { uuid } = useParams();
  const { loading, onFinish } = props;
  const { from, to } = getDefaultPeriod();

  useEffect(() => {
    if (uuid) {
      onFinish({ account: uuid, from, to });
    }
  }, [uuid]);

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Form
      onFinish={onFinish}
      initialValues={{ from, to }}
      className="theme-filter"
    >
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={6}>
          <AccountSelect placeHolder="Account" accountType="investment" />
        </Col>
        <Col xs={12} md={2}>
          <Form.Item
            name="from"
          >
            <DatePicker
              defaultValue={from && moment(from)}
              format="DD-MM-YYYY"
              bordered
              placeholder="From"
              onOpenChange={() => {}}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={2}>
          <Form.Item
            name="to"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  const fromDate = getFieldValue('from');
                  if (!value || fromDate <= value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`To date must be greater than ${fromDate?.format('DD-MM-YYYY')} date`));
                },
              }),
            ]}
          >
            <DatePicker
              defaultValue={to && moment(to)}
              format="DD-MM-YYYY"
              bordered
              placeholder="To"
              onOpenChange={() => {}}
            />
          </Form.Item>
        </Col>
        <Col xs={12} md={2}>
          <Button loading={loading} type="secondary" htmlType="submit">
            Preview
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

InvestmentStatementForm.propTypes = {
  loading: PropTypes.bool,
  onFinish: PropTypes.func,
};

InvestmentStatementForm.defaultProps = {
  loading: false,
  onFinish: () => null,
};

export default InvestmentStatementForm;
