const brandConfigs = [
  {
    brand: 'zagga',
    labels: {
    },
    loanFields: {
      default: [
        { field: 'loanType', label: 'Loan type' },
        { field: 'loanPurpose', label: 'Loan purpose' },
        { field: 'loanAmount', label: 'Loan amount' },
        { field: 'repaymentType', label: 'Repayment type' },
        { field: 'trancheAmount', label: 'Tranche amount - this raise' },
        { field: 'tranches', label: 'Tranches' },
        { field: 'creditAssessmentScore', label: 'Credit Assessment Score' },
        { field: 'term' },
        { field: 'borrowerRate', label: 'Borrower rate' },
        { field: 'lineFee', label: 'Line fee' },
        { field: 'investorReturn', label: 'Investor return' },
        { field: 'commencementDate', label: 'Commencement date' },
      ],
    },
    includeLoanConditions: false,
  },
  {
    brand: 'apm',
    labels: {
    },
    loanFields: {
      default: [
        { field: 'loanType', label: 'Loan type' },
        { field: 'loanPurpose', label: 'Loan purpose' },
        { field: 'loanAmount', label: 'Loan amount' },
        { field: 'repaymentType', label: 'Repayment type' },
        { field: 'trancheAmount', label: 'Tranche amount - this raise' },
        { field: 'tranches', label: 'Tranches' },
        { field: 'creditAssessmentScore', label: 'Credit Assessment Score' },
        { field: 'term' },
        { field: 'borrowerRate', label: 'Borrower rate' },
        { field: 'lineFee', label: 'Line fee' },
        { field: 'investorReturn', label: 'Investor return' },
        { field: 'commencementDate', label: 'Commencement date' },
      ],
    },
    includeLoanConditions: false,
  },
  {
    brand: 'znz',
    labels: {
      reportingTaxStatements: 'Investor Portfolios',
      reportingInvestmentCertificates: 'Investor Certificates',
      reportingInvestmentStatements: 'Investment Statements',
      reportingTaxStatement: 'investor portfolio',
      reportingInvestmentCertificate: 'investor certificate',
      reportingInvestmentStatement: 'investment statement',
      loanDetails: 'Loan term sheet',
    },
    loanFields: {
      default: [
        { field: 'loanType', label: 'Loan type' },
        { field: 'loanPurpose', label: 'Loan purpose' },
        { field: 'repaymentType', label: 'Repayment type' },
        { field: 'loanAmount', label: 'Loan amount' },
        { field: 'platformFees', label: 'Platform fee' },
        { field: 'legalFees', label: 'Legal fees' },
        { field: 'term' },
        { field: 'borrowerRate', label: 'Borrower rate' },
        { field: 'commencementDate', label: 'Commencement date' },
        { field: 'maturityDate', label: 'Maturity date' },
        { field: 'latestCreditAssessment', label: 'Latest credit assessment' },
        { field: 'paymentsToDate', label: 'Payments to date' },
        { field: 'creditHistory', label: 'Credit history' },
        { field: 'offerOpened', label: 'Offer opened' },
        { field: 'offerClosed', label: 'Offer closes' },
      ],
      investor: [
        { field: 'loanType', label: 'Loan type' },
        { field: 'loanPurpose', label: 'Loan purpose' },
        { field: 'loanAmount', label: 'Loan amount' },
        { field: 'repaymentType', label: 'Repayment type' },
        { field: 'creditAssessmentScore', label: 'Credit Assessment Score' },
        { field: 'term' },
        { field: 'borrowerRate', label: 'Borrower rate' },
        { field: 'investorReturn', label: 'Investor return' },
        { field: 'commencementDate', label: 'Commencement date' },
        { field: 'maturityDate', label: 'Maturity date' },
        { field: 'latestCreditAssessment', label: 'Latest credit assessment' },
        { field: 'paymentsToDate', label: 'Payments to date' },
        { field: 'creditHistory', label: 'Credit history' },
        { field: 'offerOpened', label: 'Offer opened' },
        { field: 'offerClosed', label: 'Offer closes' },
      ],
    },
    includeLoanConditions: true,
  },
];

export const brandLabelConfig = (brand, label, defaultValue) => {
  const config = brandConfigs.find(element => element.brand === brand);
  return config.labels[label] ?? defaultValue;
};

export const brandLoanFields = (brand, context) => {
  const config = brandConfigs.find(element => element.brand === brand);
  return config.loanFields[context] || config.loanFields.default;
};

export const brandIncludeLoanConditions = (brand) => {
  const config = brandConfigs.find(element => element.brand === brand);
  return config.includeLoanConditions ?? false;
};
