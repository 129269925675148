/* eslint-disable no-restricted-globals */
import { Form, Row, Col } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DocumentTypeSelect from './DocumentTypeSelect';
import DocumentEntitySelect from './DocumentEntitySelect';
import DocumentAccessSelect from './DocumentAccessSelect';
import DocumentFileDragger from './DocumentFileDragger';
import DocumentUserSelect from './DocumentUserSelect';
import ScrollToTop from '../../../Helpers/Helpers';
import DocumentCurrentSelect from './DocumentCurrentSelect';

import DocumentButtonControl from '../../../Molecules/Control/DocumentButtonControl';

const DocumentEditForm = (props) => {
  const {
    accounts,
    clients,
    documentCopy,
    documentForm,
    documentTypesList,
    documentTypeValue,
    setDocumentTypeValue,
    entitySelectEvent,
    history,
    entitySelect,
    isMultiDoc,
    onFormSubmit,
    match: {
      params: {
        context,
      },
    },
    setFileList,
    setIsMultiDoc,
    showDelete,
    mediaUuid,
  } = props;

  ScrollToTop();

  return (
    <Form onFinish={onFormSubmit} form={documentForm}>

        <Row gutter={[16, 16]}>
          <Col span={12}>
          <DocumentTypeSelect
            list={documentTypesList}
            value={documentTypeValue}
            setValue={setDocumentTypeValue}
            setIsMultiDoc={setIsMultiDoc}
          />
          </Col>
          <Col span={12}>
          <DocumentCurrentSelect
            className="checkbox-current"
            isMultiDoc={isMultiDoc}
          />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <DocumentEntitySelect
              clients={clients}
              accounts={accounts}
              entitySelectEvent={entitySelectEvent}
            />
          </Col>
          <Col xs={12} md={6}>
            <DocumentAccessSelect />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
          <DocumentUserSelect
            clients={clients}
            accounts={accounts}
            entitySelect={entitySelect}
          />
          </Col>
        </Row>

      <DocumentFileDragger setList={setFileList} />
      <DocumentButtonControl showDelete={showDelete} documentCopy={documentCopy} onSubmit={onFormSubmit} uuid={mediaUuid} onCancel={() => { history.push(`/app/${context}/documents`); }} />
    </Form>
  );
};

export default withRouter(DocumentEditForm);
