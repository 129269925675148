import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  bool, func, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { SettingFilled } from '@ant-design/icons';
import { Row, Col } from 'antd';
import Button from '../../Atoms/Forms/Button/Button';
import Tabs from '../../Atoms/DataDisplay/Tabs/Tabs';
import AccountSettings
  from '../Drawers/AccountSettings/AccountSettings';
import TabPane from '../../Atoms/DataDisplay/TabPane/TabPane';
import { isDealer as _isDealer } from '../../Store/userSlice';

const AccountTabs = ({
  setDisplay, refetch, account, hasInvestments,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { context } = useParams();
  const isDealer = useSelector(_isDealer);

  let theAccount = account;
  let bundle = 'loan';
  if (account.from === 'investment') {
    theAccount = account.data;
    bundle = 'investment';
  }

  const onTabChange = (key) => {
    setDisplay(key);
    refetch();
  };

  const operations = (
    <Button
      className="btn-tertiary"
      shape="circle"
      onClick={() => setDrawerVisible(true)}
      icon={<SettingFilled />}
    />
  );

  return (
    <Row justify="space-between">
      <Col span={12}>
        <Tabs
          defaultActiveKey="details"
          type="card"
          onTabClick={key => onTabChange(key)}
          tabBarExtraContent={isDealer && operations}
        >
          <TabPane tab="Details" key="details" />
          <TabPane tab="Documents" key="documents" />
          { isDealer && <TabPane tab="Pools" key="pools" /> }
          { isDealer && hasInvestments && <TabPane tab="Investments" key="investments" />}
        </Tabs>

        <AccountSettings
          account={theAccount}
          context={context}
          entityType={bundle}
          onSuccess={() => window.location.reload()}
          visible={drawerVisible}
          setVisible={setDrawerVisible}
        />
      </Col>
    </Row>
  );
};

AccountTabs.propTypes = {
  account: shape({
    uuid: string,
  }).isRequired,
  setDisplay: func,
  refetch: func,
  hasInvestments: bool,
};

AccountTabs.defaultProps = {
  setDisplay: () => {},
  refetch: () => {},
  hasInvestments: false,
};

export default AccountTabs;
