import React from 'react';
import { string } from 'prop-types';
import FullName from '../FullName/FullName';
import EntityType from '../EntityType/EntityType';
import './Person.less';

const Person = ({ type, bundle }) => (
  <div className="theme-person">
    <EntityType type={type} bundle={bundle} />
    <FullName />
  </div>
);

Person.propTypes = {
  type: string,
  bundle: string,
};

Person.defaultProps = {
  type: 'client',
  bundle: 'individual',
};

export default Person;
