import React, {
  useContext, useEffect, useState,
} from 'react';
import { Prompt } from 'react-router';
import {
  oneOf, arrayOf, string, shape, number,
} from 'prop-types';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Step from '../../Atoms/Navigation/Step/Step';
import Steps from '../../Molecules/Steps/Steps';
import ClientConfigContext from '../../Context/ClientConfig/ClientConfigContext';
import RegistrationSuccess from '../../Molecules/Confirmation/RegistrationSuccess';
import AccountOverview from '../../Organisms/Drawers/AccountOverview/AccountOverview';
import ClientStructureContext from '../../Context/ClientStructure/ClientStructureContext';
import FormContext from '../../Context/Form/FormContext';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { CREATE_CLIENT } from '../../GraphQL/Mutations/Client';
import mainRegistrationComponents from '../../Resources/RegistationComponents';
import useClientConvertedInputData from '../../Hooks/useClientConvertedInputData';
import {
  setStep,
  setEntity,
  getCurrentStep,
  setSteps,
  getSteps,
  getCountry,
  getClientStructureConfig,
  getStepsStatus,
  historyPop,
  initFromDraft,
  setDraftUuid,
  registrationHaveAnyErrors,
  allStepsFinished,
  setShowAccountOverviewDrawer,
  getEntities,
  setCreateLoading,
  setPageContext,
  reset,
} from '../../Store/clientRegistrationSlice';
import RegistrationError from '../../Molecules/Error/RegistrationError';
import { userUuid as _userUuid, addUserRole } from '../../Store/userSlice';
import useClientsQuery from '../../Hooks/useClientsQuery';

const { Provider } = Form;

const ClientRegistration = ({ InitialFormState, queryDraftUuid, match: { params: { context: pageContext } } }) => {
  const { query, variables } = useClientsQuery({ context: pageContext });
  const [CreateClient, { loading: createClientLoading }] = useMutation(CREATE_CLIENT, {
    refetchQueries: [{ query, variables }],
  });

  const { loading } = useContext(ClientConfigContext);
  const configurations = useSelector(getClientStructureConfig);
  const country = useSelector(getCountry);
  const step = useSelector(getCurrentStep);
  const steps = useSelector(getSteps);
  const entities = useSelector(getEntities);
  const dispatch = useDispatch();
  const registrationHaveErrors = useSelector(registrationHaveAnyErrors);
  const allFinished = useSelector(allStepsFinished);
  const userUuid = useSelector(_userUuid);
  const { setNotes } = useContext(ClientStructureContext);
  const [role, setRole] = useState(null);
  const [type, setType] = useState(null);
  const [registrationError, setRegistrationError] = useState(null);

  const {
    setStepCompleteStatus,
  } = useContext(FormContext);

  dispatch(setPageContext(pageContext));

  if (queryDraftUuid !== undefined) {
    dispatch(setDraftUuid(queryDraftUuid));
  }

  useEffect(() => {
    dispatch(setCreateLoading(createClientLoading));
  }, [createClientLoading]);

  const onClickProceed = (values) => {
    dispatch(setEntity(values));
    dispatch(setStep(step + 1));
    window.scrollTo(0, 0);
  };

  const onGoBack = () => {
    dispatch(historyPop());
    window.scrollTo(0, 0);
  };

  const onFinish = () => {
    // NB: final page no longer carries any extra data.
    CreateClient({
      variables: useClientConvertedInputData(entities, country),
    })
      .then((result) => {
        setRegistrationError(null);
        const { data: { clientCreate: { client: { owner: { uuid } }, newRole } } } = result;
        // @todo Implement test after redux new uuid.
        if (uuid === userUuid) {
          dispatch(addUserRole(newRole));
        }
        dispatch(setStep(step + 1));
      })
      .catch((error) => {
        setRegistrationError(error);
        dispatch(setStep(step + 1));
      });
  };

  const onChange = (name, value) => {
    if (name === 'role') {
      if (value === 'introducer') {
        setType('company');
      }
      setRole(value);
    }

    if (name === 'type') {
      setType(value);
    }
  };

  const isLastStep = stepKey => stepKey === steps.reduce((carry, { component }) => component, null);

  const allowProceed = (stepKey) => {
    if (!isLastStep(stepKey)) {
      return true;
    }
    return !registrationHaveErrors && allFinished;
  };

  const onFinishLastStep = stepKey => (values, currentEntities = []) => {
    const lastStep = isLastStep(stepKey);
    const currentStepSoftValidationPass = !(values?.errors && values?.errors.length > 0);
    let usedEntities = currentEntities;
    if (usedEntities.length < 0) {
      usedEntities = Object.values(entities);
    }

    const entitiesHaveErrors = usedEntities.reduce((carry, entity) => {
      if (carry) {
        return carry;
      }
      const { errors } = entity;
      return errors?.length > 0;
    }, false);

    if (lastStep && (entitiesHaveErrors || !allFinished || !currentStepSoftValidationPass)) {
      dispatch(setShowAccountOverviewDrawer(true));
    } else {
      if (lastStep) {
        onFinish(values);
      }
      if (!lastStep) {
        onClickProceed(values);
      }
    }
  };

  const availableComponents = mainRegistrationComponents(
    onChange,
    onClickProceed,
    onGoBack,
    isLastStep,
    allowProceed,
    onFinish,
    onFinishLastStep,
    steps,
    step,
  );

  const [component, setComponent] = useState(availableComponents[0].component);

  useEffect(() => {
    if (steps && steps.length > step) {
      const { component: componentName, notes } = steps[step];
      if (componentName) {
        const selected = availableComponents.find(element => element.name === componentName);
        if (selected) {
          const { component: selectedComponent } = selected;
          if (selectedComponent && selectedComponent.key !== component.key) {
            setComponent(selectedComponent);
          }
        }
      }

      setNotes(notes);
    }

    if (steps && steps.length < step + 1) {
      setComponent((registrationError && <RegistrationError context={role} createError={registrationError} />) || <RegistrationSuccess />);
    }
  }, [steps, step]);

  useEffect(() => {
    if (InitialFormState && InitialFormState.role) {
      dispatch(initFromDraft(InitialFormState));
      const { role: userRole, type: userType } = InitialFormState;

      if (userRole) {
        setRole(userRole);
      }

      if (userType) {
        setType(userType);
      }
    }
  }, [InitialFormState]);

  useEffect(() => {
    if (configurations) {
      const configuration = configurations.find((
        {
          role: { value: roleValue },
          type: { value: typeValue },
        },
      ) => roleValue === role && typeValue === type);
      if (configuration) {
        const { steps: allConfigurationSteps } = configuration;
        const configurationSteps = allConfigurationSteps.filter(
          checkStep => !checkStep?.restrictToRole || checkStep.restrictToRole.includes(pageContext),
        );

        if (configurationSteps && configurationSteps.length) {
          const stepStatus = configurationSteps.map(
            ({ component: currentComponent }) => ({ stepKey: currentComponent, status: 'wait' }),
          );
          setStepCompleteStatus(stepStatus);
          dispatch(setSteps(configurationSteps));
        }
      }
    }
  }, [role, type, configurations]);

  const stepsStatus = useSelector(getStepsStatus);

  return (
    <Provider>
      <Prompt
        when
        message={() => {
          dispatch(reset());
          return true;
        }}
      />
      <Skeleton loading={loading}>
        <Steps
          size="default"
          current={step}
          onChange={step ? value => dispatch(setStep(value)) : null}
        >
          {steps.map(({ title }, index) => (
            <Step
              key={`step-${title}`}
              title={title}
              status={stepsStatus[index]}
              disabled={steps.length <= step}
            />
          ))}
          <Step
            className="theme-step"
            title="Complete"
            status={((steps.length <= step) && 'finish') || 'wait'}
            disabled
          />
        </Steps>
        {!(steps && steps.length < step + 2) && (<AccountOverview />)}
        {component}
      </Skeleton>
    </Provider>
  );
};

ClientRegistration.propTypes = {
  InitialFormState: shape({
    id: number,
    role: oneOf([
      'investor',
      'introducer',
      'borrower',
    ]),
    entityType: oneOf([
      'individual',
      'individuals',
      'company',
      'trust',
    ]),
    group: shape({
      name: string,
    }),
    company: shape({
      name: string,
      businessNumber: string,
      companyNumber: string,
    }),
    trust: shape({
      name: string,
      type: oneOf([
        'smsf',
        'other',
      ]),
      trustee: oneOf([
        'individual',
        'corporate',
      ]),
    }),
    entities: arrayOf(
      shape({
        id: number,
        name: string,
        businessNumber: string,
        companyNumber: string,
      }),
    ),
  }),
  queryDraftUuid: string,
  match: shape({
    params: shape({
      context: oneOf(['borrower', 'investor', 'broker', 'dealer']),
    }),
  }).isRequired,
};

ClientRegistration.defaultProps = {
  InitialFormState: {
    role: null,
    type: null,
    entities: [],
  },
  queryDraftUuid: undefined,
};

export default ClientRegistration;
