import React, { useContext, useEffect } from 'react';
import {
  arrayOf, bool, func, number, oneOfType, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { v4 as uuid } from 'uuid';
import {
  setSelected, getCountry, getParentId, getRegisterPersons, getSelected, getSelectedType, getSteps, getCurrentStep,
} from '../../../Store/clientRegistrationSlice';
import LangContext from '../../../Context/Lang/LangContext';
import Input from '../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import EntitiesSelector from '../../EntitiesSelector/EntitiesSelector';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../Helpers/Forms';
import Address from '../Address/Address';
import './Person.less';
import ClientRegistrationActions from '../Actions/ClientRegistrationActions';
import StepInfo from '../StepInfo/StepInfo';
import Person from './Person';
import { useValidationRules } from '../../../Hooks/useValidationRules';
import DatePickerFormItem from '../DatePickerFormItem/DatePickerFormItem';

const PersonForm = ({
  parents, requireCountry, stepKey, subStepKey, onFinish, onGoBack,
}) => {
  const { t } = useContext(LangContext);
  const type = useSelector(getSelectedType);
  const country = useSelector(getCountry);
  const parentId = useSelector(getParentId(subStepKey));
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const persons = useSelector(getRegisterPersons);
  const steps = useSelector(getSteps);
  const step = useSelector(getCurrentStep);

  const selected = useSelector(getSelected);
  if (steps[step]?.component === stepKey) {
    if (type === 'individual') {
      dispatch(setSelected(persons[0].id));
    } else if (!selected && persons.length > 0) {
      dispatch(setSelected(persons[0].id));
    } else if (!selected && persons.length === 0) {
      dispatch(setSelected('new'));
    }
  }
  const { softRequired, validateStatus } = useValidationRules;

  const getPersonSelected = id => persons.find(person => person.id === id);
  const selectedCountry = getPersonSelected(selected)?.address?.country_code || country;

  useEffect(() => {
    if (selected !== 'new') {
      form.setFieldsValue(getPersonSelected(selected));
    } else {
      form.resetFields();
    }
  }, [selected]);

  const onFinishLocal = (values) => {
    dispatch(setSelected(values.id));
    onFinish(values);
  };

  const isFirstPerson = () => (!persons?.length || selected === 0 || persons[0].id === selected);

  return (
    <div className="theme-person">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinishLocal}
        name="registerAndVerifyEntitiesForm"
        initialValues={selected !== undefined ? getPersonSelected(selected) : {}}
      >
        <EntitiesSelector
          allowNew={(type !== 'individual')}
          label="I am registering"
          entities={persons.map(({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName ?? ''}` }))}
          onChange={(event) => {
            dispatch(setSelected(event.target.value));
          }
        }
          defaultValue={selected}
        />
        <StepInfo id={uuid()} stepKey={stepKey} subStepKey={subStepKey} parentId={parentId} />
        <Person />
        <InputGroup compact>
          <DatePickerFormItem
            dateRestriction="past"
            label="Date of birth"
            fieldName="dateOfBirth"
            parents={parents}
            validationRules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
          />

          <FormItem
            noStyle
            shouldUpdate={(prev, current) => prev.errors !== current.errors}
          >
            {({ getFieldValue }) => (
              <FormItem
                label="Contact number"
                name={getFieldName('contactNumber', parents)}
                rules={[item => isFirstPerson() && softRequired(item, 'Contact number')]}
                validateStatus={validateStatus(getFieldName('contactNumber', parents), getFieldValue('errors'))}
              >
                <Input />
              </FormItem>
            )}
          </FormItem>

          <FormItem
            noStyle
            shouldUpdate={(prev, current) => prev.errors !== current.errors}
          >
            {({ getFieldValue }) => (
              <FormItem
                label="Email"
                name={getFieldName('email', parents)}
                rules={[item => isFirstPerson() && softRequired(item, 'Contact email'), { message: t('validation.email'), type: 'email' }]}
                validateStatus={validateStatus(getFieldName('email', parents), getFieldValue('errors'))}
              >
                <Input placeholder="Enter email address" />
              </FormItem>
            )}
          </FormItem>

          <FormItem hidden noStyle name="errors" />

        </InputGroup>
        <Address
          form={form}
          parents={['address']}
          requireCountry={requireCountry}
          defaultCountry={selectedCountry}
        />
        <ClientRegistrationActions form={form} onGoBack={onGoBack} />
      </Form>
    </div>
  );
};

PersonForm.propTypes = {
  stepKey: string.isRequired,
  subStepKey: string.isRequired,
  onFinish: func,
  onGoBack: func,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  requireCountry: bool,
};

PersonForm.defaultProps = {
  onFinish: () => {},
  onGoBack: () => {},
  parents: 'person',
  requireCountry: true,
};

export default PersonForm;
