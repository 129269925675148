import React from 'react';
import { string } from 'prop-types';
import Card from '../Card';
import Image from '../../../Atoms/Media/Image/Image';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import './Bpay.less';

const Bpay = ({
  billerCode, bpayReference,
}) => (
  <Card
    title="Bpay"
    className="theme-bpay"
  >
    <Image preview={false} alt="Bpay logo" src="/assets/images/bpay-logo.png" />
    <div className="theme-bpay-detail-wrapper">
      Contact your bank or financial institution to make this payment from your cheque, savings, debit or transaction account.
      <Paragraph type="label">
        <strong>Biller code:</strong>
        {' '}
        { billerCode }
      </Paragraph>
      <Paragraph type="label">
        <strong>Ref:</strong>
        {' '}
        { bpayReference }
      </Paragraph>
    </div>
  </Card>
);

Bpay.propTypes = {
  billerCode: string.isRequired,
  bpayReference: string.isRequired,
};


export default Bpay;
