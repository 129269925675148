/* After updating coding standard this file started to fail */
import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvestorCheckbox from './InvestorCheckbox';
import Button from '../../Atoms/Forms/Button/Button';
import Text from '../../Atoms/Typography/Text/Text';
import './InvestorSelection.less';
import Title from '../../Atoms/Typography/Title/Title';
import Badge from '../../Atoms/DataDisplay/Badge/Badge';

class InvestorsSelection extends Component {
  constructor(props) {
    super(props);

    const { data } = props;

    this.state = {
      expandedAccordion: Object.keys(data).map(key => key),
    };
  }

  collapseToggle = (accordionUuid) => {
    const { expandedAccordion } = this.state;
    let updatedAccordionState = expandedAccordion;

    if (expandedAccordion.includes(accordionUuid)) {
      updatedAccordionState = expandedAccordion.filter(uuid => uuid !== accordionUuid);
    } else {
      updatedAccordionState = [...expandedAccordion, accordionUuid];
    }

    this.setState({ expandedAccordion: updatedAccordionState });
  };

  getHeaderTitle = (key) => {
    if (key === 'unmatched') {
      return 'You can choose to invite unmatched investors';
    }
    let chance = 100 * key;
    if (chance < 1) {
      chance = 1;
    }

    return (
      <Fragment>
        Prediction:
        <strong>
          &nbsp;
          { chance }
          %
        </strong>
      </Fragment>
    );
  };


  render() {
    const { expandedAccordion } = this.state;
    const {
      data,
      checkboxHandler,
      bulkCheckboxHandler,
      selectedRanks,
      headerTitle,
    } = this.props;
    const dataArray = Object.keys(data);
    let totalCount = 0;
    let selectionCount = 0;
    let render = null;


    if (dataArray.length > 0) {
      render = dataArray.map((key) => {
        const accordionClasses = ['accordion'];

        if (expandedAccordion.includes(key)) {
          accordionClasses.push('expanded');
        }

        return (
          <div key={key} className="theme-investor-selection-inner">
            <div className={accordionClasses.join(' ')}>
              <Row justify="space-between" className="subtitle-wrapper" align="middle">
                <Text>{ this.getHeaderTitle(key) }</Text>
                <Row gutter={16} align="middle">
                  <Col>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <label
                      className={`theme-checkbox ant-checkbox-wrapper ${selectedRanks.includes(key) && 'ant-checkbox-wrapper-checked'}}`}
                      htmlFor={`group-check--${key}`}
                      onClick={(e) => {
                        e.preventDefault();
                        bulkCheckboxHandler(key);
                      }}
                      onKeyUp={(e) => {
                        e.preventDefault();
                        bulkCheckboxHandler(key);
                      }}
                    >
                    <span className={`ant-checkbox ${selectedRanks.includes(key) && 'ant-checkbox-checked'}`}>
                      <input
                        id={`group-check--${key}`}
                        className="ant-checkbox-input"
                        type="checkbox"
                        value="investor"
                        checked={selectedRanks.includes(key)}
                        onChange={() => null}
                      />
                       <span className="ant-checkbox-inner" />
                    </span>
                    </label>
                  </Col>
                  <Col className="actions">
                    <Button className={accordionClasses.join(' ')} type="badge" onClick={() => this.collapseToggle(key)}>
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Button>
                  </Col>
                </Row>
              </Row>
              <Row className="list-wrapper" gutter={[16, 16]} align="middle">
              {
                data[key].map((
                  {
                    id, checked, name,
                  },
                ) => {
                  totalCount += 1;

                  if (checked) {
                    selectionCount += 1;
                  }

                  return (
                    <Col key={id} xs={12} sm={6} md={4}>
                      <InvestorCheckbox
                        name={name}
                        onClick={() => checkboxHandler(id)}
                        checked={checked}
                      />
                    </Col>
                  );
                })
              }

              </Row>
            </div>
          </div>
        );
      });
    }

    return (
        <Row className="theme-investor-selection-inner">
          <Col className="title-wrapper" span={12}>
            <Row align="middle">
              <Title level={5}>
{headerTitle}
{' '}
(
{totalCount}
)
              </Title>
              <Badge>{selectionCount}</Badge>
            </Row>
          </Col>
          <Col span={12}>
            {render}
          </Col>
        </Row>
    );
  }
}

export default InvestorsSelection;
