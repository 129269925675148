import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import ClientConfigContext from './ClientConfigContext';
import {
  setConfiguration,
} from '../../Store/clientRegistrationSlice';
import { CONFIGURATION_BY_KEY } from '../../GraphQL/Queries/Configuration';

const ClientConfigProvider = ({ children }) => {
  const { loading, error, data } = useQuery(CONFIGURATION_BY_KEY, { variables: { type: 'dealer_configuration_item', key: 'client_registration' } });
  const [configurations, setConfigurations] = useState([]);
  const dispatch = useDispatch();

  const uniqueOptions = options => options.filter(({ value }, index, self) => self.findIndex(({ value: v }) => v === value) === index);

  const typesForRole = (role) => {
    const allTypes = configurations.filter(({ role: { value } }) => value === role).map(({ type }) => type);
    return uniqueOptions(allTypes);
  };


  useEffect(() => {
    if (data) {
      let configData = {};
      if (typeof data !== 'undefined') {
        configData = JSON.parse(data.configuration.configuration);
        dispatch(setConfiguration(configData));
      }
      const {
        configurations: responseConfigurations,
      } = configData;

      if (responseConfigurations) {
        setConfigurations(responseConfigurations);
      }
    }
  }, [data]);

  return (
    <ClientConfigContext.Provider value={{
      loading,
      error,
      typesForRole,
    }}
    >
      {children}
    </ClientConfigContext.Provider>
  );
};

ClientConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientConfigProvider;
