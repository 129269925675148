import React from 'react';
import { Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import PropTypes, {
  bool, func, shape, string,
} from 'prop-types';
import Drawer from '../../../Atoms/Feedback/Drawer/Drawer';
import SyncAccountButton
  from '../../../Molecules/Forms/Actions/SyncAccountButton';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import { isAdmin } from '../../../Store/userSlice';
import DeleteAccountButton from '../../../Molecules/Forms/Actions/DeleteAccountButton';
import Divider from '../../../Atoms/Layout/Divider/Divider';
import AccountEdit from './AccountEdit/AccountEdit';
import InvestmentProfileEdit from './InvestmentProfileEdit/InvestmentProfileEdit';
import InvestorMemorandumEdit from './InvestorMemorandum/InvestorMemorandumEdit';
import { dealer } from '../../../Hooks/useLocalStorage';

const AccountSettings = ({
  account,
  children,
  context,
  entityType,
  onSuccess,
  setVisible,
  visible,
}) => {
  const isEditable = haveEntityPermission(account, 'Can edit account') && context === 'dealer';

  const { uuid } = account;

  const accountSettingsSuccess = () => {
    setVisible(false);
    onSuccess();
  };

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Drawer
      title="Settings "
      width={isMdScreen ? '80vw' : '40vw'}
      closable
      onClose={() => setVisible(false)}
      open={visible}
    >
      { isEditable && (<AccountEdit account={account} success={accountSettingsSuccess} entityType={entityType} />)}

      <Row className="drawer-button">
        <SyncAccountButton
          uuid={uuid}
          onSuccess={accountSettingsSuccess}
        />
      </Row>

      { entityType === 'investment' && dealer() === 'zagga' && (
        <InvestorMemorandumEdit investment={account} success={accountSettingsSuccess} />
      )}

      {children}

      { (isAdmin || (isEditable && entityType === 'loan')) && (
        <Divider />
      )}

      { isEditable && entityType === 'loan' && (
        <InvestmentProfileEdit account={account} success={accountSettingsSuccess} />
      )}

      { isAdmin && (
        <>
          <Row className="drawer-button">
            <DeleteAccountButton type={account.type} uuid={account.uuid} />
          </Row>
        </>
      )}
    </Drawer>
  );
};

AccountSettings.propTypes = {
  account: shape({
    uuid: string.isRequired,
  }).isRequired,
  children: PropTypes.node,
  context: string.isRequired,
  entityType: string.isRequired,
  onSuccess: func,
  setVisible: func.isRequired,
  visible: bool,
};

AccountSettings.defaultProps = {
  children: null,
  onSuccess: () => {},
  visible: false,
};

export default AccountSettings;
