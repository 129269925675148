import React, { useState } from 'react';
import { Form, Checkbox } from 'antd';
import {
  arrayOf, bool, func, oneOfType, shape, string,
} from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { getGraphQlErrorMessage } from '../../../../Helpers/Error';
import Alert from '../../../../Atoms/Feedback/Alert/Alert';
import Title from '../../../../Atoms/Typography/Title/Title';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Button from '../../../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Select from '../../../../Atoms/Forms/Select/Select';
import { LOAN_INVESTMENT_PROFILE } from '../../../../GraphQL/Queries/Loan';
import { UPDATE_LOAN_INVESTMENT_PROFILE } from '../../../../GraphQL/Mutations/Loan';
import Skeleton from '../../../../Atoms/Feedback/Skeleton/Skeleton';
import './InvestmentProfileForm.less';

const InvestmentProfileForm = ({ loan, onSuccess }) => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const { data: profileData, loading: profileLoading } = useQuery(LOAN_INVESTMENT_PROFILE, { variables: { uuid: loan.uuid } });
  const [UpdateInvestmentProfile, { loading }] = useMutation(UPDATE_LOAN_INVESTMENT_PROFILE);

  const convertDataToFormValues = prof => prof && prof.reduce((accumulator, item) => ({ ...accumulator, [item.name]: item?.value || item?.optionValue }), {});

  const submitForm = (values) => {
    UpdateInvestmentProfile({
      variables: {
        loanUuid: loan.uuid,
        input: values,
      },
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setMessage(getGraphQlErrorMessage(error));
      });
  };

  if (profileLoading) {
    return <Skeleton />;
  }

  return (
    <Form
      form={form}
      name="investmentProfile"
      onFinish={submitForm}
      initialValues={convertDataToFormValues(profileData.loan.investmentProfile)}
      className="theme-loan-edit-form"
    >
      { message && <Alert message={message} type="error" /> }
      <div>
        <Title level={3}>Loan settings</Title>
        {
          profileData.loan.investmentProfile.filter(item => !!item.label).map(item => (
            <>
              { item.name === 'generateInvestorAgreementOn' && (
                <FormItem
                  key={`${loan.uuid} ${item.name}`}
                  name={item.name}
                  valuePropName={item.name === 'generateInvestorAgreementOn' ? 'value' : 'checked'}
                  label={item.label}
                >
                  <Select
                    options={[
                      { label: 'Commitment made', value: 'commitment' },
                      { label: 'Fully funded', value: 'fullyFunded' },
                      { label: 'Funds received', value: 'fundsReceived' },
                    ]}
                  />
                </FormItem>
              )}
              { item.name !== 'generateInvestorAgreementOn' && (
                <FormItem
                  key={`${loan.uuid} ${item.name}`}
                  name={item.name}
                  valuePropName={item.name === 'generateInvestorAgreementOn' ? 'value' : 'checked'}
                  label={item.label}
                  className="investment-profile-checkbox"
                >
                  <Checkbox>{item.description}</Checkbox>
                </FormItem>
              )}
            </>
          ))
        }
      </div>

      <ButtonGroup direction="bottom-right">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading && profileLoading}
        >
          Save
        </Button>
      </ButtonGroup>
    </Form>
  );
};

InvestmentProfileForm.propTypes = {
  loan: shape({
    uuid: string.isRequired,
    investmentProfile: arrayOf(shape({
      name: string,
      label: string,
      description: string,
      value: oneOfType([string, bool]),
    })),
  }).isRequired,
  onSuccess: func,
};

InvestmentProfileForm.defaultProps = {
  onSuccess: () => {},
};

export default InvestmentProfileForm;
