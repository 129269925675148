import React, { useContext } from 'react';
import { Form } from 'antd';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import { useSelector } from 'react-redux';
import { getCountry } from '../../../Store/clientRegistrationSlice';
import Input from '../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../Atoms/Forms/InputGroup/InputGroup';
import Button from '../../../Atoms/Forms/Button/Button';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import countriesOptions from '../../../Resources/Countries';
import Select from '../../../Atoms/Forms/Select/Select';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import './TaxNumber.less';
import LangContext from '../../../Context/Lang/LangContext';

const TaxNumber = () => {
  const country = useSelector(getCountry);
  const { t } = useContext(LangContext);
  const isAu = country === 'AU';

  return (
    <div className="theme-tax-number">
      <FormItem
        label="Are you registered to pay tax overseas?"
        name="taxOverseas"
        rules={[{ required: true, message: t('validation.required_option') }]}
      >
        <RadioGroup
          name="taxOverseas"
        >
          <Radio
            key="taxOverseasYes"
            value="Yes"
          >
            Yes
          </Radio>
          <Radio
            key="taxOverseasNo"
            value="No"
          >
            No
          </Radio>
        </RadioGroup>
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(previous, next) => previous.taxOverseas !== next.taxOverseas}
      >
        {({ getFieldValue }) => (getFieldValue('taxOverseas') === 'Yes' ? (
          <Form.List name="countryTaxes">
            {(fields, { add, remove }) => (
              <>
                { fields.map(({
                  key, name, fieldKey, ...restField
                }) => {
                  const selectedCountries = getFieldValue('countryTaxes') !== undefined
                    ? getFieldValue('countryTaxes').map(value => (value !== undefined ? value.country : undefined)) : [];

                  const availableCountries = countriesOptions.filter(
                    option => selectedCountries.indexOf(option.value) === -1
                      && option.label !== country,
                  );

                  return (
                    <>
                      <InputGroup>
                        <FormItem
                          {...restField}
                          label={!key ? 'Select country' : 'Additional country'}
                          name={[name, 'country']}
                          fieldKey={[fieldKey, 'country']}
                          rules={[{ required: true, message: t('validation.required_option') }]}
                        >
                          <Select
                            options={countriesOptions.filter(item => (item.value !== country))}
                            placeholder="Country"
                            showSearch={availableCountries.length > 10}
                          />
                        </FormItem>

                        <FormItem
                          {...restField}
                          label="Tax identification number"
                          name={[name, 'taxNumber']}
                          fieldKey={[fieldKey, 'taxNumber']}
                          rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
                          shouldUpdate={
                            (
                              prevValues,
                              currentValues,
                            ) => prevValues.countryTaxes.length !== currentValues.countryTaxes.length
                          }
                        >
                          <Input
                            placeholder="Tax number"
                          />
                        </FormItem>
                      </InputGroup>
                      <ButtonGroup direction="left">
                        {key > 0 && fields.slice().pop().key === key && (
                          <Button ghost icon={<MinusCircleOutlined />} onClick={() => remove(name)}>

                            Delete country
                          </Button>
                        )}
                        {fields.slice().pop().key === key && (
                        <Button type="secondary" icon={<PlusCircleOutlined />} onClick={() => add()}>
                          Add country
                        </Button>
                        )}
                      </ButtonGroup>
                    </>
                  );
                })}
              </>
            )}
          </Form.List>
        ) : null)
        }
      </FormItem>
      <FormItem
        label={`Are you ${isAu ? 'an Australian' : 'a New Zealand'} resident for tax purposes?`}
        name="homelandTax"
        rules={[{ required: true, message: t('validation.required_option') }]}
      >
        <RadioGroup name="homelandTax">
          <Radio
            key="Yes"
            value="Yes"
          >
            Yes
          </Radio>
          <Radio
            key="No"
            value="No"
          >
            No
          </Radio>
        </RadioGroup>
      </FormItem>
    </div>
  );
};

export default TaxNumber;
