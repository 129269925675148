import { useSelector } from 'react-redux';
import { APPLICATION_DRAFTS } from '../GraphQL/Queries/Draft';
import { isDealer as _isDealer, userUuid as _userUuid } from '../Store/userSlice';

const useApplicationDrafts = () => {
  const limit = 25;
  const userUuid = useSelector(_userUuid);
  const isDealer = useSelector(_isDealer);

  const query = APPLICATION_DRAFTS;
  const variables = {
    offset: 0,
    limit,
    sort: 'changed',
    sortDirection: 'DESC',
    ...((!isDealer && { owner: userUuid }) || {}),
  };

  return {
    query,
    variables,
    limit,
  };
};

export default useApplicationDrafts;
