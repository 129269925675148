import React, { useState } from 'react';
import moment from 'moment';
import Button from '../../../Atoms/Forms/Button/Button';
import { formatFromMoment } from '../../../Helpers/FormatDate';
import useFile from '../../../Hooks/useFile';

const IdentityVerificationLogDownloadButton = (props) => {
  const { startDate, endDate } = props;
  const [loading, setLoading] = useState(false);
  const { view } = useFile();

  const download = () => {
    setLoading(true);
    let url = '/api/csv/verification-log';
    const params = new URLSearchParams();

    if (startDate) params.set('created[min]', formatFromMoment(startDate));
    if (endDate) params.set('created[max]', formatFromMoment(endDate));

    if (params.toString()) url += `?${params.toString()}`;

    view({ url, filename: 'identity-verification-logs.csv' }, () => setLoading(false));
  };

  return (
    <Button type="secondary" loading={loading} onClick={download}>
      Download CSV
    </Button>
  );
};

IdentityVerificationLogDownloadButton.propTypes = {
  startDate: typeof moment,
  endDate: typeof moment,
};

IdentityVerificationLogDownloadButton.defaultProps = {
  startDate: undefined,
  endDate: undefined,
};

export default IdentityVerificationLogDownloadButton;
