import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './Dagger.less';
import documentsService from '../../../Services/Documents.service';
import Text from '../../Typography/Text/Text';

// @todo How to upload files using apollo? (Replace customRequest).
const Dragger = ({
  accept,
  comment,
  defaultFileList,
  hint,
  text,
  maxCount,
  setFileField,
}) => {
  const onFileUploadChange = (data) => {
    const completedUploads = data.fileList.filter(item => item.status === 'done' || item.status !== 'uploading');
    setFileField(completedUploads.map((item) => {
      const { fid, name, response } = item;
      if (fid && !response) {
        return {
          name, fid, uid: fid, status: 'done',
        };
      }
      const { fid: fileId } = response;
      return { name, fid: fileId[0].value, uid: fileId[0].value };
    }));
  };

  return (
    <>
      {comment && (
        <Text type="comment">
          {comment}
        </Text>
      )}
      <Upload.Dragger
        accept={accept}
        className="theme-dragger"
        customRequest={documentsService.uploadFile}
        defaultFileList={defaultFileList}
        maxCount={maxCount}
        multiple
        onChange={onFileUploadChange}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {text}
        </p>
        { (hint && accept)
      && (
        <p className="ant-upload-hint">
          {`${hint} ${accept}`}
        </p>
      )
      }
      </Upload.Dragger>
    </>
  );
};

Dragger.propTypes = {
  accept: PropTypes.string,
  comment: PropTypes.string,
  defaultFileList: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.number,
    fid: PropTypes.number,
    name: PropTypes.string,
  })),
  hint: PropTypes.string,
  maxCount: PropTypes.number,
  setFileField: PropTypes.func.isRequired,
  text: PropTypes.string,
};

Dragger.defaultProps = {
  accept: '.pdf,.jpg,.jpeg,.png',
  comment: null,
  defaultFileList: [],
  hint: 'You can upload the following file types:',
  maxCount: 1,
  text: 'Click or drag file to this area to upload',
};

export default Dragger;
