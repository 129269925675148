import React from 'react';
import {
  string, node,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { LOANS } from '../../../GraphQL/Queries/Account';
import { APPLICATION_DRAFTS } from '../../../GraphQL/Queries/Draft';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Button from '../../../Atoms/Forms/Button/Button';
import ApplicationsPanel from '../../Panel/ApplicationsPanel';
import { userUuid as _userUuid } from '../../../Store/userSlice';
import Result from '../../../Atoms/Feedback/Result/Result';
import ApplicationDraftPanel from '../../Panel/ApplicationDraftPanel';

const ApplicationsDashBoardCard = ({
  title, children, btnText, btnLink,
}) => {
  const userUuid = useSelector(_userUuid);

  const { loading: loansLoading, error: loansError, data: loansData } = useQuery(LOANS, {
    variables: {
      user: userUuid, states: ['pending', 'funding', 'assessed'], offset: 0, limit: 10,
    },
  });

  const { loading: draftsLoading, error: draftsError, data: draftsData } = useQuery(APPLICATION_DRAFTS, {
    variables: {
      user: userUuid, offset: 0, limit: 10,
    },
  });

  if (loansError || draftsError) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${loansError.message} || ${draftsError.message}`} />);
  }

  const { results: loanResults } = loansData?.loans || {};
  const { results: draftResults } = draftsData?.drafts || {};

  return (
    <Card
      title={title}
      loading={loansLoading || draftsLoading}
      type="dashboard"
      actions={[
        <Button key={btnText} block href={btnLink} type="primary">
          {btnText}
        </Button>,
      ]}
    >
      <div className="text-area-applications">
        {children}
        {loanResults?.length >= 1 && loanResults?.map(loan => (
          <ApplicationsPanel
            key={loan?.uuid}
            loan={loan}
          />
        ))}
        {draftResults?.length >= 1 && draftResults?.filter(draft => draft !== null)?.map(draft => (
          <ApplicationDraftPanel
            key={draft?.uuid}
            draft={draft}
          />
        ))}
      </div>
    </Card>
  );
};

export default ApplicationsDashBoardCard;

ApplicationsDashBoardCard.propTypes = {
  btnLink: string,
  btnText: string,
  title: node,
  children: node,
};

ApplicationsDashBoardCard.defaultProps = {
  btnLink: '/app/borrower/apply',
  btnText: 'Apply',
  title: (<Title level={4}>Apply for a loan</Title>),
  children: '',
};
