import React from 'react';
import { Form, InputNumber } from 'antd';

const InterestRate = () => (
  <Form.Item
    label="Interest Rate (Borrower rate)"
    className="loan-amount investment-rate"
    name="interestrate"
    rules={[{ required: true, message: 'Please input loan amount.' }]}
    initialValues={{ interestrate: '9' }}
  >
    <InputNumber
      max={100}
      step={0.25}
    />
  </Form.Item>
);

export default InterestRate;
