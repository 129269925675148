import React from 'react';
import { Popover } from 'antd';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool } from 'prop-types';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import CheckboxNew from '../../../Atoms/Forms/Checkbox/Checkbox';
import Input from '../../../Atoms/Forms/Input/Input';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';


const DocumentCurrentSelect = ({ isMultiDoc }) => {
  const labelTooltip = (
    <>
      <span>Current Document Version </span>
      <Popover
        title={null}
        content={(
          <Paragraph>
            Current version of this document for this category, account and/or client.
          </Paragraph>
        )}
      >
        <FontAwesomeIcon icon={faCircleQuestion} />
      </Popover>
    </>
  );

  if (isMultiDoc) {
    return (
      <>
        <FormItem
          name="currentValue"
          valuePropName="checked"
          label={labelTooltip}
        >
        <CheckboxNew>Current</CheckboxNew>
        </FormItem>
      </>
    );
  }
  return <Input type="hidden" value="1" name="currentValue" />;
};

DocumentCurrentSelect.propTypes = {
  isMultiDoc: bool,
};

DocumentCurrentSelect.defaultProps = {
  isMultiDoc: undefined,
};

export default DocumentCurrentSelect;
