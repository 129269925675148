import React from 'react';
import {
  bool, func, string, node, oneOfType, oneOf, number,
} from 'prop-types';
import { Drawer as AntdDrawer } from 'antd';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';

const Drawer = (args) => {
  const { className = '' } = args;
  const newArgs = { ...args };
  delete newArgs.className;

  return (
    <AntdDrawer {...newArgs} className={`theme-drawer ${className}`} />
  );
};

Drawer.propTypes = {
  autoFocus: bool,
  afterOpenChange: func,
  className: string,
  closable: bool,
  closeIcon: node,
  destroyOnClose: bool,
  extra: node,
  footer: node,
  forceRender: bool,
  height: oneOfType([string, number]),
  keyboard: bool,
  mask: bool,
  maskClosable: bool,
  open: bool,
  placement: oneOf(['top', 'right', 'bottom', 'left', 'right']),
  size: oneOf(['default', 'large']),
  title: node,
  width: oneOfType([string, number]),
  zIndex: number,
  onClose: func,
};

Drawer.defaultProps = {
  autoFocus: true,
  afterOpenChange: () => {},
  className: '',
  closable: true,
  closeIcon: <CloseOutlined />,
  destroyOnClose: true,
  extra: '',
  footer: '',
  forceRender: false,
  height: 256,
  keyboard: true,
  mask: true,
  maskClosable: true,
  open: false,
  placement: 'right',
  size: 'default',
  title: '',
  width: 378,
  zIndex: 1000,
  onClose: () => {},
};

export default Drawer;
