import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import Button from '../../../Atoms/Forms/Button/Button';
import Title from '../../../Atoms/Typography/Title/Title';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import InvestmentStatementForm from '../../../Molecules/Forms/InvestmentStatementForm/InvestmentStatementForm';
import formatDate, { formatFromMoment } from '../../../Helpers/FormatDate';
import { INVESTMENT_STATEMENT_BY_UUID_AND_DATE } from '../../../GraphQL/Queries/Investment';
import InvestmentStatementTable from '../../../Atoms/Tables/InvestmentStatementTable/InvestmentStatementTable';
import { INVESTMENT_STATEMENT_PDF } from '../../../GraphQL/Mutations/Pdf';
import NoResult from '../../../Molecules/NoResult/NoResult';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import InvestmentStatementCard from '../../Cards/Reporting/InvestmentStatementCard';
import SaveToPortalButton from '../SaveToPortalButton';
import useFile from '../../../Hooks/useFile';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentStatement = () => {
  const [investment, setInvestment] = useState();
  const [formValues, setFormValues] = useState({});
  const [savedToPortal, setSavedToPortal] = useState(false);
  const [query, { data, loading }] = useLazyQuery(INVESTMENT_STATEMENT_BY_UUID_AND_DATE);
  const [mutation, { loading: mutating }] = useMutation(INVESTMENT_STATEMENT_PDF);
  const { view } = useFile();

  useEffect(() => data && setInvestment(data?.investment), [data]);

  const onClickPreview = (values) => {
    setSavedToPortal(false);
    setFormValues(values);
    return query({
      variables: {
        uuid: values?.account,
        startDate: formatFromMoment(values.from),
        endDate: formatFromMoment(values.to),
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      skip: !values?.account,
    });
  };

  const onClickDownloadPdf = () => {
    mutation({
      variables: {
        uuid: formValues?.account,
        startDate: formatFromMoment(formValues?.from),
        endDate: formatFromMoment(formValues?.to),
      },
    }).then(({ data: responseData }) => {
      view(responseData?.investmentStatementPdf);
    });
  };

  const onClickSaveToDocumentPortal = () => {
    mutation({
      variables: {
        uuid: formValues?.account,
        startDate: formatFromMoment(formValues?.from),
        endDate: formatFromMoment(formValues?.to),
        saveDocument: true,
      },
    }).then(() => setSavedToPortal(true));
  };

  const statementPeriod = transactions => (transactions && `${formatDate(transactions.startDate)} - ${formatDate(transactions.endDate)}`) || '';

  return (
    <>
      <InvestmentStatementForm onFinish={onClickPreview} loading={loading} />
      <Skeleton loading={loading}>
        { investment && (
          <>
            <Row>
              <Col flex={24}>
                <InvestmentStatementCard investment={investment} period={statementPeriod(investment?.transactions)} />
              </Col>
            </Row>
            <Row>
              <Col flex={24}>
                <Title level={4}>{`${investment?.fieldClient?.fieldLmsClientId} ${investment?.fieldClient?.name}`}</Title>
                <InvestmentStatementTable
                  transactions={investment?.transactions}
                />
              </Col>
            </Row>
            <ButtonGroup direction="bottom-right" className="set-3-style">
              <Button
                type="secondary"
                onClick={onClickDownloadPdf}
                disabled={mutating}
              >
                Download PDF
              </Button>

              <SaveToPortalButton callBack={onClickSaveToDocumentPortal} loading={mutating} saved={savedToPortal} />
            </ButtonGroup>
          </>
        )}

        { !data && !investment
          && (
            <NoResult
              title={`No ${brandLabelConfig(dealer(), 'reportingInvestmentStatement', 'investment statement')} found.`}
              body="Please try a different filter."
            />
          )}
      </Skeleton>


    </>
  );
};


export default InvestmentStatement;
