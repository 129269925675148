import React from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import Text from '../../../Atoms/Typography/Text/Text';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const DocumentAccessSelect = () => {
  const labelTooltip = (
    <>
      <span>Access level </span>
      <Popover
        title={null}
        content={(
          <>
            <Paragraph>
              <Text type="bold">Internal </Text>
              Only visible to dealers
            </Paragraph>
            <Paragraph>
              <Text type="bold">Private </Text>
              Only visible to the owner (Borrower, Investor or Broker) and dealers
            </Paragraph>
            <Paragraph>
              <Text type="bold">Published </Text>
              Visible to the owner, sophisticated investors and dealers
            </Paragraph>
            <Paragraph>
              <Text type="bold">Public </Text>
              Visible to everyone
            </Paragraph>
          </>
        )}
      >
        <FontAwesomeIcon icon={faCircleQuestion} />
      </Popover>
    </>
  );

  return (
      <FormItem
        name="accessValue"
        rules={[{ required: true, message: 'Please select access level.' }]}
        label={labelTooltip}
      >
        <RadioGroup>
          <Radio value="internal">Internal</Radio>
          <Radio value="private">Private</Radio>
          <Radio value="published">Published</Radio>
          <Radio value="public">Public</Radio>
        </RadioGroup>
      </FormItem>
  );
};

export default DocumentAccessSelect;
