import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClientStructureContext from './ClientStructureContext';

const ClientStructureProvider = ({ children }) => {
  const [notes, setNotes] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);

  return (
    <ClientStructureContext.Provider value={{
      notes, setNotes, user, setUser, selectedEntity, setSelectedEntity,
    }}
    >
      {children}
    </ClientStructureContext.Provider>
  );
};

ClientStructureProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientStructureProvider;
