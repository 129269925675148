import React from 'react';
import { shape, bool, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Text from '../../../Atoms/Typography/Text/Text';

import './IdentityField.less';

const IdentityField = ({ client }) => (
  client?.fieldIdentification?.verified ? (
    <div className="theme-identity-field">
      <Text type="label">
        Identity checked
        {' '}
        <FontAwesomeIcon icon={faCheckCircle} />
      </Text>
    </div>
  ) : null
);

IdentityField.propTypes = {
  client: shape({
    fieldIdentification: shape({
      type: string,
      fieldVerified: bool,
    }),
  }).isRequired,
};

export default IdentityField;
