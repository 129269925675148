import React from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../../Helpers/Forms';
import Input from '../../../../Atoms/Forms/Input/Input';
import Select from '../../../../Atoms/Forms/Select/Select';
import countriesOptions from '../../../../Resources/Countries';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';
import './NewZealandCitizen.less';

const NewZealandCitizen = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => (
  <>
    <InputGroup className="theme-nz-citizen">
      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Number"
            name={getFieldName('evidenceNumber', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Certificate number', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('evidenceNumber', parents), getFieldValue('errors'))}
          >
            <Input />
          </FormItem>
        )}
      </FormItem>

      <FormItem
        noStyle
        shouldUpdate={(prev, current) => prev.errors !== current.errors}
      >
        {({ getFieldValue }) => (
          <FormItem
            label="Country of birth"
            name={getFieldName('countryOfBirth', parents)}
            rules={[item => useValidationRules.softRequired(item, 'Certificate country', deleteExcept)]}
            validateStatus={useValidationRules.validateStatus(getFieldName('countryOfBirth', parents), getFieldValue('errors'))}
          >
            <Select
              showSearch
              placeholder="Select or search"
              options={countriesOptions}
            />
          </FormItem>
        )}
      </FormItem>
    </InputGroup>

    <DraggerFormItem
      comment="Please upload a copy of your citizenship certificate."
      fieldName={getFieldName('citizenshipDocument', parents)}
      label="Upload identification"
      rules={[item => useValidationRules.softRequired(item, 'Certificate document', deleteExcept)]}
      shouldUpdateComponent={shouldUpdateComponent}
    />
  </>
);

NewZealandCitizen.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

NewZealandCitizen.defaultProps = {
  parents: ['citizenship'],
  deleteExcept: ['personVerification.evidenceNumber', 'personVerification.countryOfBirth', 'personVerification.citizenshipDocument'],
};

export default NewZealandCitizen;
