import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntdCheckbox } from 'antd';
import './Checkbox.less';

const Checkbox = args => <AntdCheckbox {...args} className="theme-checkbox" />;

Checkbox.propTypes = {
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  autoFocus: false,
  checked: false,
  defaultChecked: false,
  disabled: false,
  indeterminate: false,
  children: null,
  onChange: () => {},
};

export default Checkbox;
