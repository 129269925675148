import React from 'react';
import PropTypes from 'prop-types';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import RadioNew from '../../../Atoms/Forms/Radio/Radio';

const DocumentEntitySelect = ({ entitySelectEvent }) => (
    <FormItem
      label="Upload purpose"
      name="documentEntityType"
      rules={[{ required: true, message: 'Please select upload purpose.' }]}
    >
      <RadioGroup>
        <RadioNew value="client" onChange={e => entitySelectEvent(e.target.value)}>Client</RadioNew>
        <RadioNew value="account" onChange={e => entitySelectEvent(e.target.value)}>Account</RadioNew>
      </RadioGroup>
    </FormItem>
);

DocumentEntitySelect.propTypes = {
  entitySelectEvent: PropTypes.func.isRequired,
};

export default DocumentEntitySelect;
