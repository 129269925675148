import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../../Components/Common/Modal';
import accountService from '../../../Services/Accounts.service';
import { userId } from '../../../Store/userSlice';

class DeclineOpportunityButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { modalTitle: null, modalMessage: null };
  }

  componentDidMount() {
    this.declineOpportunity = this.declineOpportunity.bind(this);
  }

  postDecline = () => {
    this.setState({
      modalTitle: 'Opportunity declined',
      modalMessage: 'If you decide to return to this loan application please use search',
    });
  };

  declineOpportunity = () => {
    const { loanUuid } = this.props;
    const uid = useSelector(userId);
    accountService.declineOpportunity(loanUuid, uid, this.postDecline);
  };

  closeModal = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ modalMessage: null });
    window.location.href = '/app/investor/opportunities';
  };

  render() {
    const { modalMessage, modalTitle } = this.state;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={this.declineOpportunity}
        >
          Decline
        </button>
        {
          modalMessage && <Modal message={modalMessage} title={modalTitle} dismiss={this.closeModal} />
        }
      </React.Fragment>
    );
  }
}

export default DeclineOpportunityButton;
