import React from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import Title from '../../../Atoms/Typography/Title/Title';
import { titleCase } from '../../../Hooks/useStringFormatter';

const DraftListItem = ({
  name, uuid, context, sublink,
}) => (
  <Fade>
    <Panel
      link={`/app/${context}/${sublink}/${uuid}`}
      className="theme-draft-list-item"
      icon={<FontAwesomeIcon className="panel-icon" icon={faChevronRight} size="2x" />}
    >
      <Title level={5}>{titleCase(name)}</Title>
    </Panel>
  </Fade>
);

DraftListItem.propTypes = {
  name: string.isRequired,
  uuid: string.isRequired,
  context: string.isRequired,
  sublink: string.isRequired,
};

export default DraftListItem;
