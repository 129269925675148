/**
 * Adding new items onto a list using "load more".
 *
 * Prevent infinite rendering on state change - only update the list
 * if the items have changed.
 */
const loadMoreItems = (
  currentList,
  currentIdValue,
  newItems,
  pageNumber,
  field = 'uuid',
) => {
  const oldUuids = currentList.map(row => row[field]).join(' ');
  if (pageNumber === 0) {
    const newUuids = newItems.map(row => row[field]).join(' ');
    if (oldUuids !== newUuids) {
      return {
        uuid: newUuids,
        list: newItems,
      };
    }
    return null;
  }

  if (newItems.length === 0) {
    return null;
  }

  const newUuids = newItems.filter(row => oldUuids.indexOf(row[field]) < 0);
  if (newUuids.length > 0) {
    let working = currentList.slice(0);
    working = working.concat(newItems);
    return {
      uuid: working.map(row => row[field]).join(' '),
      list: working,
    };
  }

  return null;
};

export default loadMoreItems;
