import React, { useState, useContext } from 'react';
import { func, shape } from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import Button from '../../../Atoms/Forms/Button/Button';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Password from '../../../Atoms/Forms/Password/Password';
import Title from '../../../Atoms/Typography/Title/Title';
import './LoginForm.less';
import { AUTH } from '../../../GraphQL/Mutations/Auth';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import LangContext from '../../../Context/Lang/LangContext';
import { setAuth, logout } from '../../../Store/userSlice';

const LoginForm = ({ onFinish, initialValues }) => {
  const { t } = useContext(LangContext);
  const [Login, { loading }] = useMutation(AUTH);
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();
  const requiredRule = { required: true, message: t('validation.required', { attribute: 'field' }) };

  const onFinishLocal = async (input) => {
    setMessage(false);
    await dispatch(logout());
    Login({ variables: { input: { ...input } } })
      .then(({ data: { auth: { user, auth } } }) => {
        dispatch(setAuth({ user, auth }));
      })
      .then(onFinish)
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-login-form">
      <Title level={1}>Login to your account</Title>
      <Form onFinish={onFinishLocal} initialValues={initialValues}>
        { message && <Alert message={message} type="error" /> }
        <FormItem
          name="email"
          rules={[
            { type: 'email', message: 'Invalid email address' },
            requiredRule,
          ]}
        >
          <Input placeholder="Email address" />
        </FormItem>

        <FormItem
          className="theme-form-item-pw"
          name="password"
          rules={[requiredRule]}
        >
          <Password placeholder="Password" />
        </FormItem>
        <Button
          href="/app/forgot-password"
          type="link"
          block
        >
          Forgot Password?
        </Button>

        <Button block type="primary" htmlType="submit" loading={loading}>
          Log in
        </Button>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  initialValues: shape({}),
  onFinish: func,
};

LoginForm.defaultProps = {
  initialValues: {},
  onFinish: () => {},
};
export default LoginForm;
