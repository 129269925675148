import React from 'react';
import {
  string, node, bool, arrayOf, oneOfType,
} from 'prop-types';
import './Panel.less';
import Link from '../../Navigation/Links/Link';

const Panel = (props) => {
  const {
    className, link, children, icon, type, aLink, hasLink, state,
  } = props;

  const body = (
    <div className={icon ? 'wrapper-has-icon' : 'wrapper'}>
      <div className="panel-body">{children}</div>
      {icon && (
      <div className="panel-icon">
        {icon}
      </div>
      )}
    </div>
  );

  const newClassName = [className, 'theme-panel', `${type && type}`].join(' ');

  if (aLink) {
    return <a href={link} className={[newClassName, 'aLink'].join(' ')}>{body}</a>;
  }

  if (state) {
    return <Link to={{ pathname: link, state }} className={[newClassName, 'state'].join(' ')}>{body}</Link>;
  }

  if (!hasLink) {
    return <div className={[newClassName, 'noLink'].join(' ')}>{body}</div>;
  }

  return <Link to={link} className={[newClassName].join(' ')}>{body}</Link>;
};

Panel.propTypes = {
  className: string,
  hasLink: bool,
  children: node,
  aLink: string,
  link: string,
  state: string,
  icon: node,
  type: oneOfType([arrayOf(['border', 'background', 'noPadding']), string]),
};

Panel.defaultProps = {
  className: undefined,
  hasLink: true,
  children: null,
  aLink: null,
  link: null,
  state: null,
  icon: null,
  type: ['border'],
};

export default Panel;
