import React from 'react';
import { string, bool, func } from 'prop-types';
import Button from '../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import PendingReduction from '../../Molecules/Cards/Reduction/PendingReduction';
import ActiveReduction from '../../Molecules/Cards/Reduction/ActiveReduction';
import RequestReductionForm from '../Forms/Accounts/Investments/SecondaryMarket/RequestReductionForm';
import PastReductions from '../../Molecules/Cards/Reduction/PastReductions';
import Drawer from '../../Atoms/Feedback/Drawer/Drawer';
import Title from '../../Atoms/Typography/Title/Title';
import { haveEntityPermission } from '../../Helpers/Helpers';

const ReductionControl = ({
  investment, showRequestReductionForm, toggleRequestReductionForm, refetch,
}) => {
  const {
    reductions,
  } = investment;

  const canBeReduced = haveEntityPermission(investment, 'Can reduce investment');
  const currentReduction = reductions && [...reductions]?.shift();
  const pastReductions = reductions.filter(({ fieldStatus }) => fieldStatus === 'cancelled' || fieldStatus === 'complete');
  const availableComponents = [
    {
      status: 'pending',
      component: <PendingReduction reduction={currentReduction} title="Marketplace offering" refetch={refetch} />,
    },
    {
      status: 'active',
      component: <ActiveReduction reduction={currentReduction} title="Marketplace offering" refetch={refetch} />,
    },
    {
      status: 'complete',
      component: null,
    },
    {
      status: 'cancelled',
      component: null,
    },
  ];

  const { component } = availableComponents.find(({ status }) => currentReduction && status === currentReduction?.fieldStatus) || { component: null };

  const cancelHandler = () => {
    toggleRequestReductionForm();
    refetch();
  };

  return (
    <>
      {currentReduction && (component)}

      {canBeReduced && !showRequestReductionForm && (
        <ButtonGroup direction="right">
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={showRequestReductionForm}
            onClick={() => toggleRequestReductionForm()}
          >
            Agree to break investment
          </Button>
        </ButtonGroup>
      )}

      {canBeReduced && showRequestReductionForm && (
        <Drawer
          title={<Title level={2}>Decrease your commitment</Title>}
          size="large"
          open={toggleRequestReductionForm}
          onClose={cancelHandler}
        >
          <RequestReductionForm onCancel={cancelHandler} investment={investment} />
        </Drawer>
      )}

      {pastReductions?.length > 0 && (
      <PastReductions
        reductions={pastReductions}
        title="Historic marketplace offerings"
      />
      )}
    </>
  );
};

ReductionControl.propTypes = {
  investment: string.isRequired,
  showRequestReductionForm: bool.isRequired,
  toggleRequestReductionForm: func.isRequired,
  refetch: func,
};

ReductionControl.defaultProps = {
  refetch: () => {},
};

export default ReductionControl;
