import React from 'react';
import { string, bool, number } from 'prop-types';
import MapStyle from './MapStyle';
import Image from '../../Atoms/Media/Image/Image';

const StaticMap = (props) => {
  const {
    alt, isFullAddress, mapSize, mapScale, location,
  } = props;

  let imageElement = 'Map cannot be loaded';
  let markers = '';
  let mapStyle = '';
  const shapesData = '';

  if (location) {
    if (isFullAddress) {
      markers = `&markers=size:small|${location}`;
    }

    MapStyle.forEach((feature) => {
      const { featureType, elementType, stylers } = feature;

      let styles = '';
      stylers.forEach((property) => {
        const key = Object.keys(property)[0];
        let value = property[key];

        if (typeof value === 'string' && value.includes('#')) {
          value = value.replace('#', '0x');
        }

        styles += `|${key}:${value}`;
      });

      mapStyle += `&style=feature:${featureType}|element:${elementType}`;

      if (styles !== '') {
        mapStyle += styles;
      }
    });

    let src = 'https://maps.googleapis.com/maps/api/staticmap?';
    src += `center=${location}`;
    src += `&scale=${mapScale}&size=${mapSize}&maptype=roadmap`;
    src += markers;
    src += mapStyle;
    src += shapesData;
    src += `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    imageElement = <Image src={src} alt={alt} />;
  }

  return <div className="static-map">{ imageElement }</div>;
};

StaticMap.defaultProps = {
  isFullAddress: false,
  alt: '',
  mapSize: '600x150',
  mapScale: 2,
};

StaticMap.propTypes = {
  location: string.isRequired,
  isFullAddress: bool,
  alt: string,
  mapSize: string,
  mapScale: number,
};

export default StaticMap;
