import { gql } from '@apollo/client';

export const REDUCTIONS = gql`
  query investmentReductions(
      $investmentUuid: String,
      $loanUuid: String,
      $statuses: [InvestmentReductionStatus],
      $offset: Int,
      $limit: Int,
      $sortDirection: SortDirection
  ) {
    investmentReductions(
        investmentUuid: $investmentUuid,
        loanUuid: $loanUuid,
        statuses: $statuses,
        offset: $offset,
        limit: $limit,
        sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        amountFunded
        fieldStatus
        fieldAction
        fieldAmount
        fieldExpiry
        fieldBreakFee
        fieldLoanPool {
          uuid
          fieldLoan {
            fieldLmsAccountId
          }
          fieldAmount
        }
        fieldReducedInvestment {
          uuid
          fieldLmsAccountId
          fieldAmount
        }
        entityPermissions {
          label
          permitted
        }
        investments {
          fieldAmount
        }
        created
      }
    }
  }
`;

export const Reduction = {
  REDUCTIONS,
};

export default Reduction;
