import React from 'react';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isDealer as _isDealer, isLoggedIn as _isLoggedIn, isSuperAdmin } from '../../Store/userSlice';
import AdminMenu from '../../Molecules/Navigation/TopMenus/AdminMenu';
import Header from '../../Organisms/Navigation/Header/Header';
import './Layout.less';
import SessionTimeout from '../../Molecules/Session/SessionTimeout/SessionTimeout';

const Layout = ({ children }) => {
  const isLoggedIn = useSelector(_isLoggedIn);
  const isDealer = useSelector(_isDealer);
  const location = useLocation();
  const isDocView = location?.pathname?.startsWith('/document')
    || location?.pathname?.startsWith('/static-document')
    || (location?.pathname?.startsWith('/share') && location?.pathname?.match(/\//g || [])?.length === 4);
  const isOffer = location?.pathname?.startsWith('/offer');

  const docViewClass = isDocView ? 'document-viewer' : '';
  const loggedInClass = isLoggedIn ? 'logged' : 'anonymous';
  const isOfferClass = isOffer ? 'offer' : '';

  return (
    <div className={`theme-layout ${docViewClass || isOfferClass || loggedInClass}`}>
      {useSelector(isSuperAdmin) && <AdminMenu />}
      {isLoggedIn && !isDocView && <Header />}
      {(!isDealer && isLoggedIn) && <SessionTimeout /> }

      <div className="theme-page container">
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
