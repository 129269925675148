import { useSelector } from 'react-redux';
import React from 'react';
import { node, string } from 'prop-types';
import { hasPermission } from '../../Store/appConfigurationSlice';

const HavePermission = ({ name, children }) => {
  const access = useSelector(hasPermission(name)) || false;
  if (access) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

HavePermission.propTypes = {
  name: string.isRequired,
  children: node,
};

HavePermission.defaultProps = {
  children: null,
};
export default HavePermission;
