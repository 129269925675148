import React, { useEffect, useState } from 'react';
import {
  string, func, arrayOf, shape,
} from 'prop-types';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';
import {
  Col, Row, Form,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { capitalize } from '../../../Helpers/Helpers';
import Select from '../../../Components/Form/Select';
import loanService, { loanAmounts } from '../../../Services/Loans.service';
import Button from '../../../Atoms/Forms/Button/Button';

export const useDebouncedLoanFilter = (searchFunction, showAllForClient = null, showAllForLoanBook = null, defaultAccountStates = '') => {
  const [context, setContext] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchProductGroup, setSearchProductGroup] = useState('');
  const [searchState, setSearchState] = useState(defaultAccountStates);
  const [searchAmount, setSearchAmount] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize] = useState(25);

  const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchFunction, 200 + Math.random() * 800));

  const search = useAsync(
    async () => debouncedSearch(
      {
        search: searchText,
        searchProductGroup,
        states: searchState,
        amount: searchAmount,
        showAllForLoanBook,
        showAllForClient,
      },
      pageNumber,
      pageSize,
      context,
    ),
    [searchText, searchProductGroup, searchState, searchAmount, pageNumber, pageSize, context],
  );

  return {
    pageNumber,
    pageSize,
    searchAmount,
    searchProductGroup,
    searchState,
    searchText,
    setContext,
    setPageNumber,
    setSearchAmount,
    setSearchProductGroup,
    setSearchState,
    setSearchText,
    search,
  };
};

export const loanListFilters = (filterFields) => {
  const {
    search,
    searchProductGroup,
    states,
    amount,
    showAllForClient,
    showAllForLoanBook,
  } = filterFields;

  const filter = {};
  if (search !== undefined && search.length > 0) {
    filter.search = search;
  }
  if (searchProductGroup !== undefined && searchProductGroup.length > 0) {
    filter.productGroups = [searchProductGroup];
  }
  if (states !== undefined && states.length > 0) {
    filter.states = states.split(',');
  }
  if (amount !== undefined && amount.length > 0) {
    filter.amount = loanAmounts[amount];
  }
  if (showAllForClient) {
    filter.showAllForClient = showAllForClient;
  }
  if (showAllForLoanBook) {
    filter.showAllForLoanBook = showAllForLoanBook;
  }
  return filter;
};

export const loanListQuery = async (filter, pageNumber, pageSize, context) => {
  const searchFunc = `get${capitalize(context)}Loans`;

  const response = await loanService[searchFunc](filter, pageNumber, pageSize);
  const responseData = response?.data?.data?.loans;
  if (responseData !== null) {
    return responseData;
  }
  return { entities: [], count: 0 };
};

export const loanListGetLoans = (filterFields, pageNumber, pageSize, context) => {
  const filters = loanListFilters(filterFields);
  return loanListQuery(filters, pageNumber, pageSize, context);
};

const LoanSearchForm = (props) => {
  const {
    clearFilter,
    context,
    searchProductGroup,
    searchProductOptions,
    searchState,
    searchStateContext,
    searchStateOptions: stateOptions,
    searchText,
    setPageNumber,
    setSearchText,
    setSearchProductGroup,
    setSearchState,
  } = props;

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => clearFilter(), []);

  useEffect(() => {
    setPageNumber(0);
  }, [searchProductGroup, searchState, searchText, setPageNumber]);

  let searchStateFilter = null;
  if (setSearchState) {
    const searchStateOptions = stateOptions ?? loanService[`${searchStateContext}StateOptions${capitalize(context)}`];

    searchStateFilter = (
      <Col xs={12} md={context === 'borrower' ? 10 : 3}>
        <Select
          className="form-control"
          placeholder="State"
          value={searchState}
          options={searchStateOptions}
          onChange={e => setSearchState(e.target.value)}
          formGroup
        />
      </Col>
    );
  }

  return (
    <Form className="theme-filter" onSubmit={e => e.preventDefault()}>
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        { (context !== 'borrower') && (
        <Col xs={12} md={setSearchProductGroup ? 4 : 7}>
          {/* @todo please replace input with FormItem Input - check the ClientSearchForm */}
          <input
            placeholder="Search"
            className="form-control"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
        </Col>
        )}
        { (context === 'dealer' || context === 'broker') && setSearchProductGroup && (
        <Col xs={12} md={3}>
          {/* @todo please replace select with the one from Atom */}
          <Select
            placeholder="Type"
            value={searchProductGroup}
            options={searchProductOptions}
            onChange={e => setSearchProductGroup(e.target.value)}
            formGroup
          />
        </Col>
        )}
        {searchStateFilter}
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={clearFilter}>Clear</Button>
        </Col>
      </Row>
    </Form>
  );
};

LoanSearchForm.propTypes = {
  clearFilter: func.isRequired,
  context: string.isRequired,
  setSearchText: func,
  setSearchProductGroup: func,
  setSearchState: func,
  setPageNumber: func,
  searchProductGroup: string,
  searchProductOptions: arrayOf(shape({ label: string, value: string })),
  searchState: string,
  searchStateContext: string,
  searchStateOptions: arrayOf(shape({
    label: string,
    name: string,
  })),
  searchText: string,
};

LoanSearchForm.defaultProps = {
  setSearchText: null,
  setSearchProductGroup: null,
  setSearchState: null,
  setPageNumber: null,
  searchProductGroup: '',
  searchProductOptions: [],
  searchState: '',
  searchStateContext: 'loan',
  searchStateOptions: null,
  searchText: '',
};

export default LoanSearchForm;
