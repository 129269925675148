import React, { useState } from 'react';
import {
  Col, Form, InputNumber, Row, Slider,
} from 'antd';

const LVR = ({ form }) => {
  const [value, setValue] = useState(50);

  return (
    <Form.Item
      label="LVR"
      className="ant-form-item--lvr"
      name="lvr"
      rules={[{ required: true, message: 'Please input loan lvr.' }]}
      initialValues={{ lvr: 50 }}
      setFieldValue={value}
    >
      <Row>
        <Col span={20}>
          <Slider
            min={0}
            max={100}
            step={0.10}
            onChange={val => setValue(val)}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            onChange={(val) => {
              form.setFieldsValue({ lvr: val });
              setValue(val);
            }}
            value={value}
            step={0.5}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};

export default LVR;
