import axios from 'axios';
import moment from 'moment';
import { getDataSet } from '../Helpers/Helpers';
import interceptors from './Axios.interceptors';

const getBaseUrl = () => {
  const baseURL = process.env.REACT_APP_FINTECH_URL;
  const useFallback = localStorage.getItem('fintech_fallback_cache');
  if (!useFallback) {
    return baseURL;
  }

  const cacheTime = getDataSet('apiFallbackCacheTime', 'fintech_api_fallback_cache_time');
  const timeDifference = moment().unix() - parseInt(useFallback, 10);
  if (timeDifference > parseInt(cacheTime, 10)) {
    localStorage.setItem('fintech_fallback_cache', 0);
    return baseURL;
  }
  // TODO Enable fallbackUrl again [ZLAR-138]
  // const fallback = getDataSet('apiFallbackUrl', 'fintech_api_fallback_url');
  const fallback = process.env.REACT_APP_FINTECH_URL;

  if (!fallback) {
    return baseURL;
  }
  return fallback;
};

const headers = {
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'x-dealer': process.env.REACT_APP_X_DEALER,
};

const instance = axios.create({
  baseURL: getBaseUrl(),
  crossDomain: true,
  headers,
});

interceptors.response(instance);

instance.interceptors.request.use((config) => {
  const newConfig = { ...config };
  const persist = localStorage.getItem('persist:user') && JSON.parse(localStorage.getItem('persist:user'));
  const token = persist && persist?.auth && JSON.parse(persist?.auth)?.access_token;

  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  if (!token) {
    delete newConfig.headers.Authorization;
  }

  if (config.url.includes('X-Amz-Security-Token')) {
    delete newConfig.headers.Authorization;
  }

  return newConfig;
});

export default instance;
