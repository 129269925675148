import React from 'react';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';
import HeaderTitle from './HeaderTitle';
import Button from '../../Atoms/Forms/Button/Button';
import { hasPermission } from '../../Store/appConfigurationSlice';
import {
  roleLabel,
} from '../../Hooks/useRoleLabelFormatter';
import HasPermission from '../Control/HavePermission';

const ClientsHeader = ({ context, tabs, history }) => {
  let title = 'Clients';
  let description = 'View and manage your clients.';

  if (tabs === 'drafts') {
    description = 'View and manage your draft clients.';
  }

  const isBrokerOrDealer = ['broker', 'dealer'].includes(context);
  const clientText = isBrokerOrDealer ? 'Register my client' : 'Add entity';

  if (!isBrokerOrDealer && tabs !== 'drafts') {
    title = `${roleLabel(context)} registration`;
    if (useSelector(hasPermission(`${context} register client`))) {
      description = `You may register as more than one type of ${roleLabel(context).toLowerCase()}.`;
    }
  }

  return (
    <HeaderTitle
      title={title}
      comment={description}
    >
      <HasPermission name={`${context} register client`}>
        <Button type="primary" onClick={() => history.push(`/app/${context}/client/add`)}>{clientText}</Button>
      </HasPermission>

    </HeaderTitle>
  );
};

ClientsHeader.propTypes = {
  context: string.isRequired,
  tabs: string.isRequired,
  history: func.isRequired,
};

export default ClientsHeader;
