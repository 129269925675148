import React from 'react';
import { Row, Col, Select } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../Atoms/Forms/Button/Button';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import ListFilter from '../../../Components/Form/ListFilter';

const { Option } = Select;

const DocumentFilter = (props) => {
  const {
    documentTypesList,
    searchText,
    searchTypes,
    searchStartDate,
    searchEndDate,
    setSearchText,
    setSearchTypes,
    setSearchStartDate,
    setSearchEndDate,
    setPage,
  } = props;

  const clearFilter = () => {
    setSearchText('');
    setSearchTypes(undefined);
    setSearchStartDate(null);
    setSearchEndDate(null);
    setPage(0);
  };

  const dateFormat = 'YYYY-MM-DD';
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <ListFilter>
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={3}>
          <input
            className="form-control"
            placeholder="Search Loan, Document or Entity name"
            onChange={(e) => { setPage(0); setSearchText(e.target.value); }}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            value={searchText}
          />
        </Col>
        <Col xs={12} md={3}>
          <Select
            allowClear
            placeholder="Type"
            onChange={(value) => { setPage(0); setSearchTypes(value); }}
            value={searchTypes}
          >
            {documentTypesList.map(type => (
              <Option value={type.value} key={type.uuid}>
                {type.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={12} md={2}>
          <DatePicker
            className="search-date"
            format={dateFormat}
            value={searchStartDate}
            onChange={setSearchStartDate}
            placeholder="Start date"
          />
        </Col>
        <Col xs={12} md={2}>
          <DatePicker
            className="search-date"
            format={dateFormat}
            value={searchEndDate}
            onChange={setSearchEndDate}
            placeholder="End date"
          />
        </Col>
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={clearFilter}>Clear</Button>
        </Col>
      </Row>
    </ListFilter>
  );
};

export default DocumentFilter;
