/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const appConfigurationSlice = createSlice({
  name: 'appConfigurations',
  initialState: {
    configurations: {},
  },
  reducers: {
    setConfiguration: (state, action) => {
      const { payload } = action;
      state.configurations = Object.fromEntries(payload.map(item => [item.key, JSON.parse(item.configuration)]));
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfiguration } = appConfigurationSlice.actions;
export const getAppDealerConfiguration = state => state.appConfiguration.configurations.DealerSpecificAppConfiguration;
export const getAppVerificationOptions = state => state.appConfiguration.configurations.DealerSpecificAppConfiguration.verification;
export const getAppPoliciesOptions = state => state?.appConfiguration?.configurations?.DealerSpecificAppConfiguration?.policies?.options ?? [];
export const getAppInvestmentConditions = state => state.appConfiguration.configurations.InvestmentConditions;
export const hasPermission = name => state => state?.appConfiguration?.configurations?.DealerSpecificAppConfiguration?.permissions?.includes(name);
export const getRoles = state => state?.appConfiguration?.configurations?.DealerSpecificAppConfiguration?.roles;

export default appConfigurationSlice.reducer;
