import { Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  arrayOf, bool, func, shape, string,
} from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import Drawer from '../../../Atoms/Feedback/Drawer/Drawer';
import Button from '../../../Atoms/Forms/Button/Button';
import { haveEntityPermission } from '../../../Helpers/Helpers';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import {
  CLIENT_SET_VERIFIED,
  CLIENT_SET_ACCREDITED,
  CLIENT_SET_SOPHISTICATED,
  CLIENT_SEND_WELCOME_EMAIL,
} from '../../../GraphQL/Mutations/Client';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import { isDealer as _isDealer } from '../../../Store/userSlice';
import IndividualClientEditDrawer from './Individual/IndividualClientEditDrawer';

const ClientSettings = ({
  client, onSuccess, visible, setVisible,
}) => {
  const { context } = useParams();
  const isDealer = useSelector(_isDealer);
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);
  const [actionsDrawerVisible, setActionsDrawerVisible] = useState(false);
  const [message, setMessage] = useState(false);
  const [SetVerified, { loading: vLoading }] = useMutation(CLIENT_SET_VERIFIED);
  const [SetSophisticated, { loading: sLoading }] = useMutation(CLIENT_SET_SOPHISTICATED);
  const [SetAccredited, { loading: aLoading }] = useMutation(CLIENT_SET_ACCREDITED);
  const [SendWelcomeEmail, { loading: emailLoading }] = useMutation(CLIENT_SEND_WELCOME_EMAIL);

  const success = () => {
    setMessage(false);
    setVisible(false);
    onSuccess();
  };

  const clientSet = (mutation) => {
    mutation({
      variables: {
        uuid: client.uuid,
      },
    }).then(() => {
      success();
    }).catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  const hasInvestorRole = ({ role }) => role === 'investor';
  const investor = client?.acting?.filter(hasInvestorRole).pop();

  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const drawerWidth = isMdScreen ? '80vw' : '40vw';

  const permissionSophisticated = haveEntityPermission(client, 'Client can be marked sophisticated');
  const permissionVerified = haveEntityPermission(client, 'Client can be marked verified');
  const permissionAccredited = haveEntityPermission(client, 'Client can be marked accredited');
  const permissionSendEmail = haveEntityPermission(client, 'Can be sent a welcome email');

  return (
    <>
      <Drawer
        title="Settings"
        width={drawerWidth}
        closable
        onClose={() => setVisible(false)}
        open={visible}
      >
        { (client?.type === 'individual' || investor) && <IndividualClientEditDrawer investor={investor} client={client} onSuccess={success} />}

        { investor && isDealer && (
          <Row className="drawer-button">
            <Button type="primary" onClick={() => setReportDrawerVisible(true)}>
              Reports
            </Button>
            <Drawer
              title="Reports"
              width={drawerWidth}
              closable
              onClose={() => setReportDrawerVisible?.(false)}
              open={reportDrawerVisible}
            >
              <Row className="drawer-button">
                <Link to={`/app/${context}/reporting/tax-statements/${client?.uuid}`}>
                  <Button type="primary">
                    Tax Statements
                  </Button>
                </Link>
              </Row>

              {/* <Row className="drawer-button"> */}
              {/*  <Button type="primary"> */}
              {/*    Portfolio Summary */}
              {/*  </Button> */}
              {/* </Row> */}
            </Drawer>
          </Row>
        )}

        <Row className="drawer-button">
          {isDealer && (permissionSophisticated || permissionVerified || permissionAccredited || permissionSendEmail) && (
            <Button type="primary" onClick={() => setActionsDrawerVisible(true)}>
              Client actions
            </Button>
          )}
          <Drawer
            title="Client actions"
            width={drawerWidth}
            closable
            onClose={() => setActionsDrawerVisible?.(false)}
            open={actionsDrawerVisible}
          >
            { message && <Alert message={message} type="error" /> }
            {permissionSophisticated && (
              <Row className="drawer-button">
                <Popconfirm
                  title="Are you sure you want mark this client as sophisticated?"
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => { clientSet(SetSophisticated); }}
                >
                  <Button type="primary" loading={sLoading}>
                    Mark sophisticated
                  </Button>
                </Popconfirm>
              </Row>
            )}

            {permissionVerified && (
              <Row className="drawer-button">
                <Popconfirm
                  title="Are you sure you want mark this client as verified?"
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => { clientSet(SetVerified); }}
                >
                  <Button type="primary" loading={vLoading}>
                    Mark verified
                  </Button>

                </Popconfirm>
              </Row>
            )}

            {permissionAccredited && (
              <Row className="drawer-button">
                <Popconfirm
                  title="Are you sure you want mark this client as accredited?"
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => { clientSet(SetAccredited); }}
                >
                  <Button type="primary" loading={aLoading}>
                    Mark accredited
                  </Button>
                </Popconfirm>
              </Row>
            )}

            <Row className="drawer-button">
              <Popconfirm
                title="Are you sure you want to send the welcome email?"
                placement="topRight"
                okText="Yes"
                cancelText="No"
                onConfirm={() => { clientSet(SendWelcomeEmail); }}
              >
                <Button
                  disabled={!permissionSendEmail}
                  type={!permissionSendEmail ? 'ghost' : 'primary'}
                  loading={emailLoading}
                >
                  {!permissionSendEmail ? 'Unable to send welcome email' : 'Send welcome email'}
                </Button>
              </Popconfirm>
            </Row>
          </Drawer>
        </Row>
      </Drawer>
    </>
  );
};

ClientSettings.propTypes = {
  client: shape({
    uuid: string.isRequired,
    acting: arrayOf(shape({
      role: string.isRequired,
    })).isRequired,
    organisations: arrayOf(shape({
      acting: arrayOf(shape({
        role: string.isRequired,
      })).isRequired,
    })),
  }).isRequired,
  onSuccess: func,
  setVisible: func.isRequired,
  visible: bool,
};

ClientSettings.defaultProps = {
  onSuccess: () => {},
  visible: false,
};

export default ClientSettings;
