import React from 'react';
import {
  shape, string, func, oneOfType, node, bool, arrayOf,
} from 'prop-types';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import { titleCase } from '../../../Hooks/useStringFormatter';
import { formatAddress } from '../../../Hooks/useAddressFormatterFees';
import { roleLabel } from '../../../Hooks/useRoleLabelFormatter';
import IdentityField from '../../../Molecules/DataDisplay/Fields/IdentityField';
import VerifiedField from '../../../Molecules/DataDisplay/Fields/VerifiedField';
import { isIndividual } from '../../../Hooks/useEntity';

const ClientDetails = ({
  client, title, operations, additionalFields, hideFieldsByKeys,
}) => {
  const {
    name, fieldFirstName, fieldMiddleName, fieldLastName,
    fieldLmsClientId, type, owner, fieldPhone, fieldAddress, acting,
  } = client || { };

  const base = [
    { uniqueKey: 'name', label: 'Name', value: type !== 'individual' && name },
    { uniqueKey: 'firstName', label: 'First Name', value: fieldFirstName },
    { uniqueKey: 'middleName', label: 'Middle Name', value: fieldMiddleName },
    { uniqueKey: 'lastName', label: 'Last Name', value: fieldLastName },
    { uniqueKey: 'lmsId', label: 'Client ID', value: fieldLmsClientId },
    { uniqueKey: 'entityType', label: 'Entity type', value: titleCase(type) },
    { uniqueKey: 'roles', label: 'Roles', value: acting?.length && acting?.map(entity => `${roleLabel(entity?.role)} `) },
    { uniqueKey: 'email', label: 'Email', value: owner?.email },
    { uniqueKey: 'phone', label: 'Phone', value: fieldPhone },
    { uniqueKey: 'address', label: 'Address', value: formatAddress(fieldAddress?.value) },
  ];

  const fields = [...base, ...additionalFields].filter(field => !hideFieldsByKeys?.includes(field.uniqueKey));

  const clientStatus = [
    isIndividual(client) && client?.acting?.length && { uniqueKey: 'verified', label: '', value: (<VerifiedField client={client} />) },
    client?.fieldIdentification && { uniqueKey: 'identityVerified', label: '', value: (<IdentityField client={client} />) },
  ];

  return (
    <Card
      className="theme-client-details"
      title={(<Title level={4}>{title}</Title>)}
      extra={operations}
    >
      <Fields key={client?.uuid} fields={fields} />
      { clientStatus && <Fields key={`${client?.uuid}-clientStatus`} fields={clientStatus} />}
    </Card>
  );
};

ClientDetails.propTypes = {
  title: string,
  client: shape({
    name: string,
    fieldFirstName: string,
    fieldMiddleName: string,
    fieldLastName: string,
    fieldLmsClientId: string,
    type: string,
    fieldPhone: string,
    fieldAddress: shape({}),
    acting: arrayOf(shape()),
    owner: shape({
      email: string,
    }),
  }).isRequired,
  operations: oneOfType([func, node]),
  additionalFields: arrayOf(shape({
    uniqueKey: string.isRequired,
    label: string,
    value: oneOfType([string, node]),
    displayNull: bool,
  })),
  hideFieldsByKeys: arrayOf(string),
};

ClientDetails.defaultProps = {
  title: 'Details',
  operations: undefined,
  additionalFields: [],
  hideFieldsByKeys: [],
};

export default ClientDetails;
