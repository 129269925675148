import React, { useEffect, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import { Col, Row } from 'antd';
import ReactGA from 'react-ga4';
import SignupForm from '../../Molecules/Forms/SignupForm/SignupForm';
import Image from '../../Atoms/Media/Image/Image';
import Link from '../../Atoms/Navigation/Links/Link';
import './Signup.less';
import JoinSuccess from '../../Molecules/Confirmation/JoinSuccess';

const Signup = ({ data, initialValues }) => {
  const { to } = data;

  const [showSuccess, setShowSuccess] = useState(false);
  const logoSrc = `/assets/logos/logo-white-${process.env.REACT_APP_X_DEALER}.svg`;
  const onFinish = () => {
    setShowSuccess(true);
  };

  useEffect(() => {
    const gaKey = process.env.REACT_APP_GA_MEASUREMENT_ID;
    if (typeof gaKey === 'string' && gaKey !== '') {
      ReactGA.initialize(gaKey);
      ReactGA.send({ hitType: 'pageview', page: '/app/join', title: 'Signup' });
    }
  }, []);

  return (
    <div className="theme-signup">
      <Row
        className="theme-columns-wrapper"
        gutter={
          {
            xs: 8, sm: 16, md: 24, lg: 32,
          }
        }
      >
        <Col className="gutter-row">
          <div className="theme-contents-wrapper">
            <Link to={to}>
              <Image alt={process.env.REACT_APP_BRAND_LEGAL_NAME} src={logoSrc} preview={false} />
            </Link>
          </div>
        </Col>
        <Col className="gutter-row">
          { showSuccess ? (<JoinSuccess />) : (<SignupForm onFinish={onFinish} initialValues={initialValues} />) }
        </Col>
      </Row>
    </div>
  );
};

Signup.propTypes = {
  data: PropTypes.shape({
    to: PropTypes.string,
  }),
  initialValues: shape({}),
};

Signup.defaultProps = {
  data: {
    to: '/',
  },
  initialValues: {},
};

export default Signup;
