import React from 'react';
import PropTypes from 'prop-types';
import AccountPanel from '../../Molecules/Panel/AccountPanel';
import { getContextInvestmentState } from '../../Helpers/Helpers';

export const InvestmentToFundPanel = (props) => {
  const { investment, context, showFund } = props;

  const {
    uuid,
    fieldAmount,
    fieldLmsAccountId,
    fieldManagedFund,
    fieldClient: {
      name: clientName,
    },
    label,
  } = investment;

  return (
    <AccountPanel
      key={uuid}
      context={context}
      clientName={showFund && label}
      investmentId={fieldLmsAccountId}
      link={`/app/${context}/investment/${uuid}`}
      loanAmount={fieldAmount}
      loanImage={fieldManagedFund?.fieldImages?.[0]}
      title={clientName}
    />
  );
};

export const InvestmentDefaultPanel = (props) => {
  const {
    investment,
    loan,
    context,
    investorName,
  } = props;

  const {
    uuid: investmentUuid,
    investmentRate,
    fieldAmount,
    fieldLmsAccountId: investmentLmsId,
    fieldState,
    displayState,
  } = investment;

  const {
    fieldRisk,
    fieldLmsAccountId: loanId,
    fieldPrimarySecurity,
    loanTitle,
  } = loan;

  const loanImage = fieldPrimarySecurity?.fieldImages?.[0];

  return (
    <AccountPanel
      key={investmentUuid}
      context={context}
      link={`/app/${context}/investment/${investmentUuid}`}
      investmentId={investmentLmsId}
      loanId={loanId}
      loanState={getContextInvestmentState(context, displayState || fieldState)}
      loanAmount={fieldAmount}
      loanImage={loanImage}
      risk={fieldRisk}
      rate={investmentRate}
      subtitle={investorName}
      title={loanTitle}
    />
  );
};

const InvestmentPanel = (props) => {
  const { investment } = props;
  const {
    fieldManagedFund,
    fieldClient: {
      name: investorName,
    },
  } = investment;

  if (fieldManagedFund !== undefined && fieldManagedFund !== null) {
    return (<InvestmentToFundPanel {...props} />);
  }
  return (<InvestmentDefaultPanel {...props} investorName={investorName} />);
};

InvestmentPanel.propTypes = {
  showFund: PropTypes.bool,
  context: PropTypes.string.isRequired,
  investment: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  loan: PropTypes.shape({
    label: PropTypes.string,
    fieldRisk: PropTypes.string,
    fieldLmsAccountId: PropTypes.string,
    fieldPrimarySecurity: PropTypes.shape({
      fieldImages: PropTypes.arrayOf(PropTypes.shape({
        propertyThumbnail: PropTypes.string,
        original: PropTypes.string,
      })),
    }),
  }).isRequired,
};

InvestmentPanel.defaultProps = {
  showFund: true,
};

export default InvestmentPanel;
