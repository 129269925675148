import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, matchPath } from 'react-router-dom';
import TopMenus from '../../../Molecules/Navigation/TopMenus/TopMenus';
import RoleMenus from '../../../Molecules/Navigation/RoleMenus/RoleMenus';
import MobileMenus from '../../../Molecules/Navigation/MobileMenus/MobileMenus';
import './Header.less';
import config from '../../../Resources/BrandMenuConfigs';
import Link from '../../../Atoms/Navigation/Links/Link';
import { dealer } from '../../../Hooks/useLocalStorage';

function useParams(path) {
  const { pathname } = useLocation();
  const match = matchPath(pathname, { path });
  return match?.params || {};
}

const Header = () => {
  const { context } = useParams('/app/:context');
  const isMobileScreen = useMediaQuery({ query: '(max-width: 820px)' });
  const brand = dealer();

  const filterByBrandRole = (brandName, filterRole) => config
    .filter(item => item.brand === brandName)
    .filter(item => item.role === filterRole);

  const filteredConfig = filterByBrandRole(brand, context).at(0);
  const defaultTopMenus = [
    {
      label: (
        <Link to="/app/profile">
          my Login
        </Link>
      ),
      key: 'my-login',
    },
    {
      label: (
        <Link to="/app/logout">
          Log out
        </Link>
      ),
      key: 'log-out',
    },
  ];

  const configTopMenus = filteredConfig?.topMenus || defaultTopMenus;
  const configRoleMenus = filteredConfig?.roleMenus;

  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 2) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <div ref={ref} className={`theme-header ${brand} ${isSticky ? 'sticky' : ''} ${isMobileScreen ? 'mobile' : 'desktop'}`}>
      {isMobileScreen ? <MobileMenus brand={brand} topMenus={configTopMenus} roleMenus={configRoleMenus} />
        : (
          <>
            <TopMenus items={configTopMenus} brand={brand} />
            <RoleMenus items={configRoleMenus} />
          </>
        )}
    </div>
  );
};

export default Header;
