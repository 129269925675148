import React from 'react';
import { node, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { snakeToCamel } from '../../../Helpers/Helpers';
import { STATIC_DOCUMENT } from '../../../GraphQL/Queries/Document';
import DocumentLink from '../../../Atoms/Navigation/Links/DocumentLink';
import StaticDocumentInfo from '../../../Atoms/Media/DocumentLink/StaticDocumentInfo';
import './StaticDocumentBoxLink.less';

const StaticDocumentBoxLink = ({
  docKey, title, children,
}) => {
  const documentKey = snakeToCamel(docKey);
  const { data } = useQuery(STATIC_DOCUMENT, { variables: { documentKey } });
  const file = data?.staticDocument?.file;

  if (!file) return null;

  return (
    <DocumentLink
      className="theme-static-document-link box"
      localDocPath={`/static-document/${documentKey}`}
    >
      {children || (
        <StaticDocumentInfo file={file} title={title} />
      )}
    </DocumentLink>
  );
};

StaticDocumentBoxLink.propTypes = {
  children: node,
  docKey: string.isRequired,
  title: string,
};

StaticDocumentBoxLink.defaultProps = {
  title: null,
  children: null,
};

export default StaticDocumentBoxLink;
