import React from 'react';
import { useQuery } from '@apollo/client';
import { shape, string } from 'prop-types';
import Pdf from '../../Atoms/DataDisplay/Pdf/Pdf';
import PdfDownload from '../../Atoms/Forms/PdfDownload/PdfDownload';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { SHAREABLE_DOCUMENT } from '../../GraphQL/Queries/Document';

const ShareableViewPage = ({ match: { params } }) => {
  const { shareableUuid, shareableToken, fileUuid } = params;
  const { data, loading, error } = useQuery(
    SHAREABLE_DOCUMENT,
    { variables: { uuid: shareableUuid, token: shareableToken } },
  );

  const file = data?.shareableDocument?.files?.filter(item => item.uuid === fileUuid).pop();

  if (error) {
    return (
      <div>Sorry, a problem occurred retrieving this file.</div>
    );
  }

  return (
    <Skeleton loading={loading} paragraph={3}>
      { file && (<PdfDownload file={file} />)}
      { file && (<Pdf path={file?.url} />)}
    </Skeleton>
  );
};

ShareableViewPage.propTypes = {
  match: shape({
    params: shape({
      shareableUuid: string.isRequired,
      shareableToken: string.isRequired,
      fileUuid: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ShareableViewPage;
