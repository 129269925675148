import React from 'react';
import moment from 'moment';
import {
  arrayOf, func, oneOfType, oneOf, string, number,
} from 'prop-types';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { getFieldName } from '../../../Helpers/Forms';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import { useValidationRules } from '../../../Hooks/useValidationRules';

const DatePickerFormItem = (props) => {
  const {
    dateRestriction, fieldName, label, parents, validationRules,
  } = props;

  const noFutureDate = dateRestriction === 'past' && (current => current > moment().endOf('day'));
  const noPastDate = dateRestriction === 'future' && (current => current < moment().endOf('day'));

  return (
    <>
      <FormItem
        noStyle
        shouldUpdate={(prev, current) => (prev?.errors !== current?.errors)}
      >
        {({ getFieldValue }) => (
          <FormItem
            label={label}
            name={getFieldName(fieldName, parents)}
            rules={validationRules}
            validateStatus={useValidationRules.validateStatus(getFieldName(fieldName, parents), getFieldValue('errors'))}
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="dd/mm/yyyy"
              disabledDate={noFutureDate || noPastDate || dateRestriction}
            />
          </FormItem>
        )}
      </FormItem>
    </>
  );
};

DatePickerFormItem.propTypes = {
  dateRestriction: oneOfType([func, oneOf(['past', 'future'])]),
  fieldName: string.isRequired,
  label: string.isRequired,
  parents: oneOfType([
    string,
    arrayOf(string),
    arrayOf(number),
  ]),
  validationRules: arrayOf(func),
};

DatePickerFormItem.defaultProps = {
  dateRestriction: null,
  parents: [],
  validationRules: [],
};

export default DatePickerFormItem;
