import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import LoanHeader from '../../Organisms/Headers/LoanHeader';
import InvestorsSelection from './InvestorsSelection';
import clientService from '../../Services/Clients.service';
import loanService from '../../Services/Loans.service';
import InvestorsConfirmationList from './InvestorsConfirmationList';
import Button from '../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';

class InvestorMatchFormPage extends Component {
  state = {
    selectedInvestors: [],
    selectedRanks: [],
    pageData: [],
    submitInProgress: false,
    account: undefined,
  };

  componentDidMount() {
    const { match: { params: { loanUuid } } } = this.props;
    const pageData = clientService.investorMatchPageData(loanUuid);
    pageData.then((response) => {
      this.setState({ pageData: response });
    });
    const accountPromise = loanService.loanQuery(loanUuid);
    accountPromise.then((response) => {
      const account = response?.data?.data?.loan;
      this.setState({ account });
    });
  }

  investorSelectHandler = (id) => {
    const { selectedInvestors } = this.state;
    let updatedSelection = selectedInvestors;
    if (selectedInvestors.includes(id)) {
      updatedSelection = selectedInvestors.filter(selectedId => selectedId !== id);
    } else {
      updatedSelection = [...selectedInvestors, id];
    }
    this.setState(prevState => ({ ...prevState.selectedInvestors, selectedInvestors: updatedSelection }));
  };

  bulkSelectHandler = (rankId, dataSource) => {
    const { selectedRanks, selectedInvestors } = this.state;
    let updatedSelectedRanks = selectedRanks;
    let updatedSelectedInvestors = selectedInvestors;
    const selectedRankData = dataSource.filter(({ recommendation_rank }) => String(recommendation_rank) === String(rankId));
    const selectedRankIds = selectedRankData.map(({ id }) => id);

    if (!selectedRanks.includes(rankId)) {
      updatedSelectedRanks = [...selectedRanks, rankId];
      updatedSelectedInvestors = [...selectedInvestors, ...selectedRankIds];
    } else {
      updatedSelectedRanks = selectedRanks.filter(rank => rank !== rankId);
      updatedSelectedInvestors = selectedInvestors.filter(id => !selectedRankIds.includes(id));
    }

    this.setState(({
      selectedRanks: updatedSelectedRanks,
      selectedInvestors: updatedSelectedInvestors,
    }));
  };

  getInvestorList = (dataSource) => {
    const { selectedInvestors } = this.state;
    const groupedData = {};
    dataSource?.map((singleData) => {
      const alteredData = singleData;
      const { recommendation_rank, id } = alteredData;
      const formattedRecommendationRank = recommendation_rank !== 'unmatched'
        ? Math.round(recommendation_rank * 100) / 100 : 'unmatched';

      if (groupedData[formattedRecommendationRank] === undefined) {
        groupedData[formattedRecommendationRank] = [];
      }

      alteredData.checked = selectedInvestors.includes(id);
      groupedData[formattedRecommendationRank].push(alteredData);

      return null;
    });

    return groupedData;
  };

  formSubmit = () => {
    const { selectedInvestors } = this.state;
    const { match: { params: { loanUuid } } } = this.props;

    this.setState({ submitInProgress: true });
    const submitPromise = clientService.investorMatchSubmit(loanUuid, selectedInvestors);
    submitPromise
      .then(() => {
        notification.open({
          type: 'success',
          message: 'The invitation has been sent.',
          duration: 2,
          onClose: () => {
            window.location.reload();
          },
        });
      })
      .catch((error) => {
        this.setState({ submitInProgress: true });
        notification.open({
          type: 'error',
          message: 'An error occurred sending the invitation.',
          duration: 2,
          onClose: () => {
            window.location.reload();
          },
        });
        // eslint-disable-next-line no-console
        console.log({ error });
      });
  };

  render() {
    const {
      selectedRanks,
      pageData,
      submitInProgress,
      account,
    } = this.state;

    if (pageData === undefined || account === undefined) {
      return (
        <div className="theme-investor-match-form-page page-inner">
          <Skeleton loading paragraph={3} />
        </div>
      );
    }

    const {
      confirmed, matched, unconfirmed, unmatched,
    } = pageData;

    const {
      fieldState, fieldAmount, fieldLmsAccountId, loanTitle,
    } = account;

    return (
      <div className="theme-investor-match-form-page page-inner">
        <LoanHeader
          amount={fieldAmount}
          context="dealer"
          loanId={fieldLmsAccountId}
          metaTitle="Loan"
          name={loanTitle}
          state={fieldState}
        />
        <Skeleton loading={!(typeof pageData === 'object'
          && !Array.isArray(pageData))}
        >
        <div className="wrapper">
          <InvestorsSelection
            headerTitle="Matched investors"
            data={this.getInvestorList(matched)}
            checkboxHandler={this.investorSelectHandler}
            bulkCheckboxHandler={rankId => this.bulkSelectHandler(rankId, matched)}
            selectedRanks={selectedRanks}
          />

          <InvestorsSelection
            headerTitle="Unmatched investors"
            data={this.getInvestorList(unmatched)}
            checkboxHandler={this.investorSelectHandler}
            bulkCheckboxHandler={rankId => this.bulkSelectHandler(rankId, unmatched)}
            selectedRanks={selectedRanks}
          />

          <Row gutter={[16, 16]}>
            <Col xs={12} sm={6}>
              <InvestorsConfirmationList
                headerTitle="Confirmed investors"
                data={confirmed}
                noResult={{
                  title: 'No investors has invested in this loan.',
                }}
              />
            </Col>


            <Col xs={12} sm={6}>
              <InvestorsConfirmationList
                headerTitle="Unconfirmed investors"
                data={unconfirmed}
                noResult={{
                  title: 'No unconfirmed investors found.',
                  body: 'Start inviting investors to this loan.',
                }}
              />
            </Col>
          </Row>


        </div>
        <ButtonGroup direction="bottom-right">
          <Button
            type="secondary"
            onClick={this.formSubmit}
            loading={submitInProgress}
          >
            Invite investors
          </Button>
        </ButtonGroup>
        </Skeleton>
      </div>
    );
  }
}

export default InvestorMatchFormPage;
