import React from 'react';
import { node, oneOf } from 'prop-types';
import './HeaderTitle.less';
import Meta from '../../Atoms/Feedback/Meta/Meta';
import SectionTitle from '../DataDisplay/Sections/SectionTitle';

const HeaderTitle = ({
  title, comment, children, commentType,
}) => (
  <div className="theme-header-title">
    <Meta title={title} />
    <SectionTitle title={title} level={1} comment={comment} commentType={commentType}>
      { children }
    </SectionTitle>
  </div>
);

HeaderTitle.propTypes = {
  title: node,
  comment: node,
  children: node,
  commentType: oneOf(['default', 'grey-box']),
};

HeaderTitle.defaultProps = {
  title: undefined,
  comment: undefined,
  children: undefined,
  commentType: 'default',
};

export default HeaderTitle;
