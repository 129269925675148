import React from 'react';
import { string } from 'prop-types';

import Result from '../../Atoms/Feedback/Result/Result';

const AccessDenied = ({ title, description }) => (
  <div className="zagga-error">
    <Result
      status="403"
      title={title}
      subTitle={description}
    />
  </div>
);

AccessDenied.propTypes = {
  title: string,
  description: string,
};

AccessDenied.defaultProps = {
  title: 'Access denied',
  description: 'You do not have access to this page',
};

export default AccessDenied;
