import React from 'react';
import { useLocation } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import ClientConfigProvider from '../../Context/ClientConfig/ClientConfigProvider';
import ClientStructureProvider from '../../Context/ClientStructure/ClientStructureProvider';
import FormProvider from '../../Context/Form/FormProvider';
import EntityCountProvider from '../../Context/EntityCount/EntityCountProvider';
import AppConfigProvider from '../../Context/AppConfig/AppConfigProvider';

const Page = () => {
  const location = useLocation();

  return (
    <>
        {location?.pathname?.includes('client/add')
          ? (
              <AppConfigProvider>
                <ClientConfigProvider>
                    <ClientStructureProvider>
                        <FormProvider>
                            <EntityCountProvider>
                                <Routes />
                            </EntityCountProvider>
                        </FormProvider>
                    </ClientStructureProvider>
                </ClientConfigProvider>
              </AppConfigProvider>
          )
          : (
              <AppConfigProvider>
                <Routes />
              </AppConfigProvider>
          )
        }
    </>
  );
};

export default Page;
