import React from 'react';
import { Form } from 'antd';
import MinusCircleOutlined from '@ant-design/icons/lib/icons/MinusCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined';
import { func, shape } from 'prop-types';
import Button from '../../../Atoms/Forms/Button/Button';
import { dealerDefaultCountry } from '../../../Hooks/useLocalStorage';
import Address from '../Address/Address';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../Atoms/Forms/Input/Input';
import './SecondarySecurity.less';
import InputNumber from '../../../Atoms/Forms/Input/InputNumber';
import SectionTitle from '../../DataDisplay/Sections/SectionTitle';

const SecondarySecurity = (props) => {
  const { form } = props;

  return (
    <div className="theme-secondary-security">
      <SectionTitle
        title="Other security offered - optional"
      />
      <Form.List name={['securities', 'secondary']}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(field => (
              <FormItem
                key={field.key}
                {...field}
                noStyle
              >
                <FormItem
                  label="Description"
                  className="theme-form-items-wrapper"
                  name={[field.key, 'description']}
                >
                  <Input />
                </FormItem>

                <Address
                  form={form}
                  list={['securities', 'secondary']}
                  parents={[field.key, 'address']}
                  requireCountry={false}
                  defaultCountry={dealerDefaultCountry()}
                />

                <FormItem
                  label="Current Valuation"
                  name={[field.key, 'currentValuation']}
                >
                  <InputNumber
                    prefix="$"
                    formatter={value => (value ? Number(value)?.toLocaleString('en-NZ') : value)}
                  />
                </FormItem>
                <FormItem
                  label="Registered Valuation"
                  name={[field.key, 'registeredValuation']}
                >
                  <InputNumber
                    prefix="$"
                    formatter={value => (value ? Number(value)?.toLocaleString('en-NZ') : value)}
                  />
                </FormItem>
                <Button ghost icon={<MinusCircleOutlined />} onClick={() => remove(field.name)}>
                  Delete
                </Button>
              </FormItem>
            ))}
            <Button type="secondary" icon={<PlusCircleOutlined />} onClick={() => add()}>
              Add
            </Button>
          </>
        )}
      </Form.List>
    </div>

  );
};

SecondarySecurity.propTypes = {
  form: shape({
    setFieldsValue: func,
  }),
};

SecondarySecurity.defaultProps = {
  form: null,
};

export default SecondarySecurity;
