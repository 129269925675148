import React from 'react';
import { node, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { snakeToCamel } from '../../../Helpers/Helpers';
import { STATIC_DOCUMENT } from '../../../GraphQL/Queries/Document';
import Link from './Link';

const StaticDocumentSimpleLink = ({
  docKey, children,
}) => {
  const documentKey = snakeToCamel(docKey);
  const { data } = useQuery(STATIC_DOCUMENT, { variables: { documentKey } });
  const file = data?.staticDocument?.file;

  if (!file) return null;

  return (
    <Link
      className="theme-static-document-link link"
      to={`/static-document/${documentKey}`}
      target="_blank"
    >
      {children}
    </Link>
  );
};

StaticDocumentSimpleLink.propTypes = {
  children: node,
  docKey: string.isRequired,
};

StaticDocumentSimpleLink.defaultProps = {
  children: null,
};

export default StaticDocumentSimpleLink;
