import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import NoResult from '../../Molecules/NoResult/NoResult';
import LoanPanel from '../../Components/Common/LoanPanel';
import InvestmentPanel from '../../Components/Common/InvestmentPanel';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import { userUuid } from '../../Store/userSlice';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import { INVITES } from '../../GraphQL/Queries/Invites';

const Opportunities = () => {
  const user = useSelector(userUuid);
  const { data, loading } = useQuery(INVITES, { variables: { user } });
  const allEntities = (data?.invites?.results || [])
    ?.concat(data?.investments?.results || [])
    ?.filter(item => !!item);

  return (
      <div className="theme-opportunities page-inner">
        <HeaderTitle
          title="Opportunities"
          comment="Below is a list of opportunities."
        />
        <Skeleton loading={loading}>
        { allEntities.map((entity) => {
          if (entity === null) return null;

          const { fieldLoan: loan } = entity;

          if (loan === null) return null;

          if (entity.type === 'invite') {
            return <LoanPanel key={loan.uuid} loan={loan} linkPrefix="/app/investor/opportunity/" context="investor" />;
          }
          if (entity.type === 'investment') {
            return <InvestmentPanel key={loan.uuid} context="investor" investment={entity} loan={loan} />;
          }

          return null;
        })}
        { !allEntities.length && <NoResult title="No opportunities found." />}
        </Skeleton>
      </div>
  );
};

export default Opportunities;
