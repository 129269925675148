import React from 'react';
import './Image.css';
import { Image as AntdImage } from 'antd';
import PropTypes from 'prop-types';

const Image = ({ ...args }) => (
  <AntdImage {...args} />
);

Image.propTypes = {
  alt: PropTypes.string,
  fallback: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  placeholder: PropTypes.node,
  preview: PropTypes.bool,
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onError: PropTypes.func,
};

Image.defaultProps = {
  alt: undefined,
  fallback: undefined,
  height: undefined,
  placeholder: undefined,
  preview: true,
  width: undefined,
  onError: () => {},
};

export default Image;
