import axios from '../Vendor/Axios';
import { axiosParams } from './Helpers';

const persistedQuery = (query, variables) => axios
  .post(`/graphql${axiosParams()}`, { query, variables })
  .then(response => Promise.resolve(response)).catch();

const query = payload => axios
  .post(`/graphql${axiosParams()}`, { query: payload })
  .then(response => Promise.resolve(response)).catch();

export const checkNull = (entity, elements) => {
  if (entity === null || entity === undefined) {
    return null;
  }
  let working = entity;
  for (let i = 0; i < elements.length; i += 1) {
    if (working[elements[i]] === undefined || working[elements[i]] === null) {
      return null;
    }
    working = working[elements[i]];
  }
  return working;
};

const apiGraphQlService = {
  query,
  persistedQuery,
};

export default apiGraphQlService;
