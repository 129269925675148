import React from 'react';
import { Row } from 'antd';
import { arrayOf, string } from 'prop-types';
import Parser from 'html-react-parser';
import { useQuery } from '@apollo/client';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Checkbox from '../../../Atoms/Forms/Checkbox/Checkbox';
import { POLICY_DEFINITION_BY_KEY } from '../../../GraphQL/Queries/Configuration';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import Switch from '../../../Atoms/Forms/Switch/Switch';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import './Policy.less';
import { getFieldName } from '../../../Helpers/Forms';
import { getNestedProp, setNestedProp } from '../../../Helpers/Object/Object';

const Policy = ({ policyKey: key, parents }) => {
  const { loading, error, data } = useQuery(POLICY_DEFINITION_BY_KEY, { variables: { key } });

  if (loading) {
    return (<Skeleton loading={loading} paragraph={{ rows: 1 }} />);
  }

  if (error) {
    return null;
  }

  const { configuration } = data;

  const renderCheckbox = (element) => {
    const { name, title, required } = element;
    return (
      <FormItem
        name={getFieldName(name, [...parents, 'values'])}
        className="theme-policy"
        valuePropName="checked"
        key={`${key}--${name}`}
        rules={required && [
          { validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('This option must be accepted to proceed.'))) },
        ]}
      >
        <Checkbox>{Parser(title)}</Checkbox>
      </FormItem>
    );
  };

  const renderSwitch = (element) => {
    const {
      name, required, prefix, meta: { uncheckedText, checkedText },
    } = element;
    return (
      <>
        {Parser(prefix)}
        <FormItem
          name={getFieldName(name, [...parents, 'values'])}
          className="theme-policy"
          valuePropName="checked"
          key={`${key}--${name}`}
          rules={required && [
            { validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('This option must be accepted to proceed.'))) },
          ]}
        >
          <Switch unCheckedChildren={uncheckedText} checkedChildren={checkedText} />
        </FormItem>
      </>
    );
  };

  const renderConfirmationCheckbox = (element) => {
    const {
      name, title, required, meta: { confirmationText, confirmationButtonText, cancelButtonText },
    } = element;
    const uniqueKey = `${key}--${name}`;
    const fieldName = getFieldName(name, [...parents, 'policy', 'values']);
    return (
      <FormItem
        noStyle
        shouldUpdate={(prev, next) => getNestedProp(prev, fieldName) !== getNestedProp(next, fieldName)}
      >
        {({ setFieldsValue, getFieldsValue }) => {
          const currentValue = getNestedProp(getFieldsValue(), fieldName);

          return (
            <FormItem
              name={fieldName}
              className="theme-policy"
              valuePropName="checked"
              key={uniqueKey}
              rules={required && [
                { validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('This option must be accepted to proceed.'))) },
              ]}
            >
              <Popconfirm
                title={confirmationText}
                okText={confirmationButtonText}
                cancelText={cancelButtonText}
                onCancel={() => setFieldsValue(setNestedProp({}, fieldName, false))}
                onConfirm={() => setFieldsValue(setNestedProp({}, fieldName, true))}
                placement="topLeft"
              >
                <Checkbox checked={currentValue}>{Parser(title)}</Checkbox>
              </Popconfirm>
            </FormItem>
          );
        }}
      </FormItem>
    );
  };

  if (data && configuration) {
    const { policy: policyString } = configuration;

    const policy = JSON.parse(policyString);

    const { label, policyItems } = policy;

    return (
      <Row className={`theme-policy--${key}`}>
        <FormItem label={label} className="theme-policy-form-wrapper">
          <FormItem
            name={getFieldName('policy', parents)}
            className="theme-policy"
            key={`${key}--policy`}
            initialValue={{ key }}
            hidden
          />
          {
            policyItems && policyItems.map((element) => {
              const { type } = element;

              let renderElement;

              switch (type) {
                case 'checkbox':
                  renderElement = renderCheckbox(element);
                  break;
                case 'switch':
                  renderElement = renderSwitch(element);
                  break;
                case 'confirmationCheckbox':
                  renderElement = renderConfirmationCheckbox(element);
                  break;
                default:
                  renderElement = `${type} has not been implemented yet!`;
              }

              return renderElement;
            })
          }

        </FormItem>
      </Row>
    );
  }

  return '';
};

Policy.propTypes = {
  policyKey: string.isRequired,
  parents: arrayOf(string),
};

Policy.defaultProps = {
  parents: ['policy'],
};

export default Policy;
