import { number, string } from 'prop-types';
import React from 'react';
import Title from '../../../Atoms/Typography/Title/Title';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';

const RemainingInvestment = ({
  title, available, commitment,
}) => {
  const remaining = ((available < commitment) ? 0 : (available - commitment)) || 0;
  return (
    <Title level={5}>
      {title}
      :
      {' '}
      <Currency value={remaining} decimals="2" prefix="$" />
    </Title>
  );
};

RemainingInvestment.propTypes = {
  title: string,
  available: number.isRequired,
  commitment: number,
};

RemainingInvestment.defaultProps = {
  title: 'Remaining investment opportunity',
  commitment: 0,
};
export default RemainingInvestment;
