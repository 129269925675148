import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker as AntdDatePicker } from 'antd';
import './DatePicker.less';

const DatePicker = (args) => {
  const { className } = args;
  return <AntdDatePicker {...args} className={`theme-datepicker ${className}`} />;
};

DatePicker.propTypes = {
  allowClear: PropTypes.bool,
  autoFocus: PropTypes.bool,
  bordered: PropTypes.bool,
  className: PropTypes.string,
  dateRender: PropTypes.func,
  disabled: PropTypes.bool,
  disabledDate: PropTypes.func,
  dropdownClassName: PropTypes.string,
  getPopupContainer: PropTypes.func,
  inputReadOnly: PropTypes.bool,
  mode: PropTypes.oneOf(['time', 'date', 'month', 'year', 'decade']),
  open: PropTypes.bool,
  panelRender: PropTypes.func,
  picker: PropTypes.oneOf(['date', 'week', 'month', 'quarter', 'year']),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  popupStyle: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large', 'middle']),
  style: PropTypes.string,
  // suffixIcon: PropTypes.node,
};

DatePicker.defaultProps = {
  allowClear: true,
  autoFocus: false,
  bordered: true,
  className: '',
  dateRender: undefined,
  disabled: false,
  disabledDate: undefined,
  dropdownClassName: undefined,
  getPopupContainer: undefined,
  inputReadOnly: false,
  mode: undefined,
  open: undefined,
  panelRender: undefined,
  picker: 'date',
  placeholder: undefined,
  popupStyle: undefined,
  size: undefined,
  style: undefined,
  // suffixIcon: undefined,
};

export default DatePicker;
