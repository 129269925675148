import { gql } from '@apollo/client';

export const TAX_STATEMENT_REPORT = gql`
    query TaxStatementReport($uuid: String!, $startDate: String, $endDate: String) {
        taxStatementReport(uuid: $uuid, startDate: $startDate, endDate: $endDate) {
            success
            message
            client {
                fieldLmsClientId
                name
                owner {
                    uuid
                    email
                    primary {
                        name
                        ... on ClientIndividual {
                            fieldFirstName
                            fieldLastName
                            fieldAddress {
                              value
                            }
                        }
                    }
                }
            }
            reportRows {
                investment {
                    uuid
                    commencementDate
                    investmentRate
                    fieldAmount
                    fieldLmsAccountId
                    fieldTermMonths
                    fieldLoan {
                        fieldLmsAccountId
                        fieldPrimarySecurity {
                            fieldAddress {
                                shortAddress
                                locality
                            }
                        }
                    }
                }
                principal
                interestPaid
                balance
            }
        }
    }
`;

const TaxStatementReport = {
  TAX_STATEMENT_REPORT,
};

export default TaxStatementReport;
