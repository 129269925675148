import React from 'react';
import './Paragraph.less';
import { Typography as AntdTypography } from 'antd';
import {
  bool, oneOf, func,
} from 'prop-types';

const Paragraph = (args) => {
  const { type, className } = args;
  const newArgs = { ...args };
  delete newArgs.className;
  delete newArgs.type;

  return (
    <AntdTypography.Paragraph {...newArgs} className={['theme-paragraph', className, type].join(' ')} />
  );
};

Paragraph.propTypes = {
  code: bool,
  copyable: bool,
  delete: bool,
  disabled: bool,
  editable: bool,
  ellipsis: bool,
  mark: bool,
  onClick: func,
  strong: bool,
  italic: bool,
  type: oneOf(['default', 'success', 'secondary', 'warning', 'danger', 'label', 'grey-box']),
  underline: bool,
};

Paragraph.defaultProps = {
  code: false,
  copyable: false,
  delete: false,
  disabled: false,
  editable: false,
  ellipsis: false,
  mark: false,
  onClick: () => {},
  strong: false,
  italic: false,
  type: 'default',
  underline: false,
};

export default Paragraph;
