import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Dropdown, Menu, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  bool, func, number, oneOf, oneOfType, string,
} from 'prop-types';
import documentsService from '../../Services/Documents.service';
import Button from '../../Atoms/Forms/Button/Button';
import Popconfirm from '../../Atoms/Forms/Popconfirm/Popconfirm';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';
import './DocumentButtonControl.less';

const DocumentButtonControl = (props) => {
  const {
    uuid, useCase, editable, onCancel, onSubmit, documentCopy, showDelete,
  } = props;
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { context } = useParams();
  const [loadings, setLoadings] = useState(false);


  const handleClick = () => {
    setOpen(!open);
  };

  const onDelete = () => {
    documentsService.deleteDocument(
      uuid,
      () => {
        notification.open({
          type: 'success',
          message: 'The document has been deleted.',
          duration: 2,
          onClose: () => {
            window.location.href = `/app/${context}/documents`;
          },
        });
      },
      () => {
        notification.open({
          type: 'error',
          message: 'An error occurred deleting the document.',
          duration: 2,
          onClose: () => {
            window.location.href = `/app/${context}/documents`;
          },
        });
      },
    );
  };

  const onSave = (e) => {
    setLoadings(true);

    onSubmit(e);
  };

  const editButton = (
    <Button
      type="secondary"
      htmlType="submit"
      onClick={() => history.push(`/app/${context}/document/${uuid}/edit`)}
    >
      Edit
    </Button>
  );

  const deleteButton = (
    (!documentCopy && showDelete) && (
      <Popconfirm
        title="Are you sure you want to delete this document"
        placement="topRight"
        okText="Yes"
        cancelText="No"
        onConfirm={onDelete}
      >
        <Button danger>
          Delete
        </Button>
      </Popconfirm>
    ));

  const cancelButton = (
    <Button
      ghost
      onClick={onCancel}
      htmlType="submit"
      className="mr-auto"
    >
      Cancel
    </Button>
  );

  const duplicateButton = (
    <Button
      type="tertiary"
      htmlType="submit"
      onClick={() => history.push(`/app/${context}/document/${uuid}/duplicate`)}
    >
      Duplicate
    </Button>
  );

  switch (useCase) {
    case 'dropdown':
      return (
        <div className="theme-document-button-control">
          <Dropdown
            overlay={(
              <Menu items={[
                {
                  label: editButton,
                  key: 'edit',
                },
                {
                  label: duplicateButton,
                  key: 'duplicate',
                },
                {
                  label: deleteButton,
                  key: 'delete',
                },
              ]}
              />
            )}
            trigger={['click']}
            className="theme-document-button-control"
            onOpenChange={() => handleClick()}
          >
            <Button
              size="large"
              shape="circle"
              icon={<FontAwesomeIcon icon={open ? faTimes : faEllipsisH} />}
              onClick={handleClick}
            />
          </Dropdown>
        </div>
      );
    case 'drawer':
      return (
        <ButtonGroup className="set-3-style">
          {cancelButton}
          {
            editable && (
              <>
                {deleteButton}
                {editButton}
              </>
            )}
        </ButtonGroup>
      );
    default:
      return (
        <ButtonGroup direction="bottom-right" className="set-3-style">
          {cancelButton}
          {deleteButton}
          <Button
            className="ant-btn-secondary theme-button"
            htmlType="submit"
            loading={loadings}
            onClick={onSave}
          >
            {documentCopy ? 'Duplicate' : 'Save'}
          </Button>
        </ButtonGroup>
      );
  }
};

DocumentButtonControl.propTypes = {
  uuid: oneOfType([string, number]).isRequired,
  useCase: oneOf(['dropdown', 'drawer', 'edit', 'add']),
  editable: bool,
  onCancel: func,
  onSubmit: func,
  documentCopy: bool,
  showDelete: bool,
};

DocumentButtonControl.defaultProps = {
  useCase: 'edit',
  editable: false,
  showDelete: true,
  documentCopy: null,
  onCancel: () => {
  },
  onSubmit: () => {
  },
};
export default DocumentButtonControl;
