/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  arrayOf, bool, number, oneOf, shape, string, node, oneOfType,
} from 'prop-types';
import { Row, Col } from 'antd';
import Field from '../../../Atoms/DataDisplay/Field/Field';
import './Fields.less';

const Fields = (props) => {
  const { numberOfColumns, fields, groupBy } = props;
  const groups = {};
  groups.columns = [...Array(numberOfColumns).keys()];
  groups.rows = [...Array(Math.ceil(fields.length / numberOfColumns)).keys()];

  const cleanedFields = fields.filter(_ => !!_?.value || !!_?.displayNull);

  const chunks = {
    columns: groups[groupBy].map(
      c => cleanedFields.filter((_, i) => i % numberOfColumns === c),
    ),
    rows: groups[groupBy].map(
      c => cleanedFields.filter((_, i) => Math.floor(i / numberOfColumns) === c),
    ),
  };

  return (
    <div className="theme-fields">
      { chunks[groupBy].map((chunk, index) => (
        <Row key={`row-${index}`} gutter={24}>
          { chunk.map((field, delta) => (
            <Col key={`col--${delta}`} xs={12} md={12 / numberOfColumns}>
              <Field
                key={`field-${field?.label}`}
                type="inner"
                label={field.label}
                value={field.value}
                className={field?.className}
                displayNull={field?.displayNull}
              />
            </Col>
          )) }
        </Row>
      )) }
    </div>
  );
};

Fields.propTypes = {
  numberOfColumns: number,
  groupBy: oneOf(['rows', 'columns']),
  fields: arrayOf(shape({
    label: string.isRequired,
    value: oneOfType([string, null, undefined, node]),
    uniqueKey: string,
    displayNull: bool,
  })).isRequired,
};

Fields.defaultProps = {
  numberOfColumns: 3,
  groupBy: 'rows',
};

export default Fields;
