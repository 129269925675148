const config = [
  {
    countryCode: 'AU',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: false,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'Suburb',
        visibility: true,
        required: false,
      },
      administrative_area: {
        label: 'State',
        visibility: true,
        required: false,
        options: [
          { value: 'ACT', label: 'Australian Capital Territory' },
          { value: 'NSW', label: 'New South Wales' },
          { value: 'NT', label: 'Northern Territory' },
          { value: 'QLD', label: 'Queensland' },
          { value: 'SA', label: 'South Australia' },
          { value: 'TAS', label: 'Tasmania' },
          { value: 'VIC', label: 'Victoria' },
          { value: 'WA', label: 'Western Australia' },
        ],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: false,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      administrative_area: ['administrative_area_level_1.short_name'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'CA',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: 'Province',
        visibility: true,
        required: true,
        options: [
          { value: 'AB', label: 'Alberta' },
          { value: 'BC', label: 'British Columbia' },
          { value: 'MB', label: 'Manitoba' },
          { value: 'NB', label: 'New Brunswick' },
          { value: 'NL', label: 'Newfoundland and Labrador' },
          { value: 'NT', label: 'Northwest Territories' },
          { value: 'NS', label: 'Nova Scotia' },
          { value: 'NU', label: 'Nunavut' },
          { value: 'ON', label: 'Ontario' },
          { value: 'PE', label: 'Prince Edward Island' },
          { value: 'QC', label: 'Quebec' },
          { value: 'SK', label: 'Saskatchewan' },
          { value: 'YT', label: 'Yukon' },
        ],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      administrative_area: ['administrative_area_level_1.short_name'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'CN',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: 'District',
        visibility: true,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: 'Province',
        visibility: true,
        required: true,
        options: [
          { value: 'Anhui Sheng', label: 'Anhui Sheng' },
          { value: 'Macau', label: 'Macau' },
          { value: 'Beijing Shi', label: 'Beijing Shi' },
          { value: 'Chongqing Shi', label: 'Chongqing Shi' },
          { value: 'Fujian Sheng', label: 'Fujian Sheng' },
          { value: 'Gansu Sheng', label: 'Gansu Sheng' },
          { value: 'Guangdong Sheng', label: 'Guangdong Sheng' },
          { value: 'Guangxi Zhuangzuzizhiqu', label: 'Guangxi Zhuangzuzizhiqu' },
          { value: 'Guizhou Sheng', label: 'Guizhou Sheng' },
          { value: 'Hainan Sheng', label: 'Hainan Sheng' },
          { value: 'Hebei Sheng', label: 'Hebei Sheng' },
          { value: 'Henan Sheng', label: 'Henan Sheng' },
          { value: 'Heilongjiang Sheng', label: 'Heilongjiang Sheng' },
          { value: 'Hubei Sheng', label: 'Hubei Sheng' },
          { value: 'Hunan Sheng', label: 'Hunan Sheng' },
          { value: 'Jilin Sheng', label: 'Jilin Sheng' },
          { value: 'Jiangsu Sheng', label: 'Jiangsu Sheng' },
          { value: 'Jiangxi Sheng', label: 'Jiangxi Sheng' },
          { value: 'Liaoning Sheng', label: 'Liaoning Sheng' },
          { value: 'Neimenggu Zizhiqu', label: 'Neimenggu Zizhiqu' },
          { value: 'Ningxia Huizuzizhiqu', label: 'Ningxia Huizuzizhiqu' },
          { value: 'Qinghai Sheng', label: 'Qinghai Sheng' },
          { value: 'Shandong Sheng', label: 'Shandong Sheng' },
          { value: 'Shanxi Sheng', label: 'Shanxi Sheng' },
          { value: 'Shaanxi Sheng', label: 'Shaanxi Sheng' },
          { value: 'Shanghai Shi', label: 'Shanghai Shi' },
          { value: 'Sichuan Sheng', label: 'Sichuan Sheng' },
          { value: 'Taiwan', label: 'Taiwan' },
          { value: 'Tianjin Shi', label: 'Tianjin Shi' },
          { value: 'Xizang Zizhiqu', label: 'Xizang Zizhiqu' },
          { value: 'Hong Kong', label: 'Hong Kong' },
          { value: 'Xinjiang Weiwuerzizhiqu', label: 'Xinjiang Weiwuerzizhiqu' },
          { value: 'Yunnan Sheng', label: 'Yunnan Sheng' },
          { value: 'Zhejiang Sheng', label: 'Zhejiang Sheng' },
        ],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['administrative_area_level_2'],
      dependent_locality: ['locality'],
      administrative_area: ['administrative_area_level_1.short_name'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'HK',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'District',
        visibility: true,
        required: false,
      },
      administrative_area: {
        label: 'Area',
        visibility: true,
        required: true,
        options: [
          { value: 'Kowloon', label: 'Kowloon' },
          { value: 'Hong Kong Island', label: 'Hong Kong Island' },
          { value: 'New Territories', label: 'New Territories' },
        ],
      },
      postal_code: {
        label: null,
        visibility: false,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      administrative_area: ['administrative_area_level_1.short_name'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'IL',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: 'State',
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: false,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'JP',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: false,
      },
      administrative_area: {
        label: 'Prefecture',
        visibility: true,
        required: true,
        options: [
          { value: 'Hokkaido', label: 'Hokkaido' },
          { value: 'Aomori', label: 'Aomori' },
          { value: 'Iwate', label: 'Iwate' },
          { value: 'Miyagi', label: 'Miyagi' },
          { value: 'Akita', label: 'Akita' },
          { value: 'Yamagata', label: 'Yamagata' },
          { value: 'Fukushima', label: 'Fukushima' },
          { value: 'Ibaraki', label: 'Ibaraki' },
          { value: 'Tochigi', label: 'Tochigi' },
          { value: 'Gunma', label: 'Gunma' },
          { value: 'Saitama', label: 'Saitama' },
          { value: 'Chiba', label: 'Chiba' },
          { value: 'Tokyo', label: 'Tokyo' },
          { value: 'Kanagawa', label: 'Kanagawa' },
          { value: 'Niigata', label: 'Niigata' },
          { value: 'Toyama', label: 'Toyama' },
          { value: 'Ishikawa', label: 'Ishikawa' },
          { value: 'Fukui', label: 'Fukui' },
          { value: 'Yamanashi', label: 'Yamanashi' },
          { value: 'Nagano', label: 'Nagano' },
          { value: 'Gifu', label: 'Gifu' },
          { value: 'Shizuoka', label: 'Shizuoka' },
          { value: 'Aichi', label: 'Aichi' },
          { value: 'Mie', label: 'Mie' },
          { value: 'Shiga', label: 'Shiga' },
          { value: 'Kyoto', label: 'Kyoto' },
          { value: 'Osaka', label: 'Osaka' },
          { value: 'Hyogo', label: 'Hyogo' },
          { value: 'Nara', label: 'Nara' },
          { value: 'Wakayama', label: 'Wakayama' },
          { value: 'Tottori', label: 'Tottori' },
          { value: 'Shimane', label: 'Shimane' },
          { value: 'Okayama', label: 'Okayama' },
          { value: 'Hiroshima', label: 'Hiroshima' },
          { value: 'Yamaguchi', label: 'Yamaguchi' },
          { value: 'Tokushima', label: 'Tokushima' },
          { value: 'Kagawa', label: 'Kagawa' },
          { value: 'Ehime', label: 'Ehime' },
          { value: 'Kochi', label: 'Kochi' },
          { value: 'Fukuoka', label: 'Fukuoka' },
          { value: 'Saga', label: 'Saga' },
          { value: 'Nagasaki', label: 'Nagasaki' },
          { value: 'Kumamoto', label: 'Kumamoto' },
          { value: 'Oita', label: 'Oita' },
          { value: 'Miyazaki', label: 'Miyazaki' },
          { value: 'Kagoshima', label: 'Kagoshima' },
          { value: 'Okinawa', label: 'Okinawa' },
        ],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      administrative_area: ['administrative_area_level_1.short_name'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'MU',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: null,
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: false,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'NZ',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: false,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: 'Suburb',
        visibility: true,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: false,
      },
      administrative_area: {
        label: null,
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: false,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      dependent_locality: ['sublocality_level_1'],
      locality: ['administrative_area_level_1'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'SG',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: null,
        visibility: false,
        required: false,
      },
      administrative_area: {
        label: null,
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'ZA',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: 'Suburb',
        visibility: true,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: null,
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      dependent_locality: ['locality'],
      administrative_area: ['administrative_area_level_1'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'GB',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'Post town',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: null,
        visibility: false,
        required: false,
        options: [],
      },
      postal_code: {
        label: 'Postcode',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      postal_code: ['postal_code'],
    },
  },
  {
    countryCode: 'US',
    spec: {
      address_line1: {
        label: 'Street address',
        visibility: true,
        required: true,
      },
      address_line2: {
        label: 'Address line 2',
        visibility: true,
        required: false,
      },
      dependent_locality: {
        label: null,
        visibility: false,
        required: false,
      },
      locality: {
        label: 'City',
        visibility: true,
        required: true,
      },
      administrative_area: {
        label: 'State',
        visibility: true,
        required: true,
        options: [
          { value: 'AL', label: 'Alabama' },
          { value: 'AK', label: 'Alaska' },
          { value: 'AS', label: 'American Samoa' },
          { value: 'AZ', label: 'Arizona' },
          { value: 'AR', label: 'Arkansas' },
          { value: 'AA', label: 'Armed Forces (AA)' },
          { value: 'AE', label: 'Armed Forces (AE)' },
          { value: 'AP', label: 'Armed Forces (AP)' },
          { value: 'CA', label: 'California' },
          { value: 'CO', label: 'Colorado' },
          { value: 'CT', label: 'Connecticut' },
          { value: 'DE', label: 'Delaware' },
          { value: 'DC', label: 'District of Columbia' },
          { value: 'FL', label: 'Florida' },
          { value: 'GA', label: 'Georgia' },
          { value: 'GU', label: 'Guam' },
          { value: 'HI', label: 'Hawaii' },
          { value: 'ID', label: 'Idaho' },
          { value: 'IL', label: 'Illinois' },
          { value: 'IN', label: 'Indiana' },
          { value: 'IA', label: 'Iowa' },
          { value: 'KS', label: 'Kansas' },
          { value: 'KY', label: 'Kentucky' },
          { value: 'LA', label: 'Louisiana' },
          { value: 'ME', label: 'Maine' },
          { value: 'MH', label: 'Marshall Islands' },
          { value: 'MD', label: 'Maryland' },
          { value: 'MA', label: 'Massachusetts' },
          { value: 'MI', label: 'Michigan' },
          { value: 'FM', label: 'Micronesia' },
          { value: 'MN', label: 'Minnesota' },
          { value: 'MS', label: 'Mississippi' },
          { value: 'MO', label: 'Missouri' },
          { value: 'MT', label: 'Montana' },
          { value: 'NE', label: 'Nebraska' },
          { value: 'NV', label: 'Nevada' },
          { value: 'NH', label: 'New Hampshire' },
          { value: 'NJ', label: 'New Jersey' },
          { value: 'NM', label: 'New Mexico' },
          { value: 'NY', label: 'New York' },
          { value: 'NC', label: 'North Carolina' },
          { value: 'ND', label: 'North Dakota' },
          { value: 'MP', label: 'Northern Mariana Islands' },
          { value: 'OH', label: 'Ohio' },
          { value: 'OK', label: 'Oklahoma' },
          { value: 'OR', label: 'Oregon' },
          { value: 'PW', label: 'Palau' },
          { value: 'PA', label: 'Pennsylvania' },
          { value: 'PR', label: 'Puerto Rico' },
          { value: 'RI', label: 'Rhode Island' },
          { value: 'SC', label: 'South Carolina' },
          { value: 'SD', label: 'South Dakota' },
          { value: 'TN', label: 'Tennessee' },
          { value: 'TX', label: 'Texas' },
          { value: 'UT', label: 'Utah' },
          { value: 'VT', label: 'Vermont' },
          { value: 'VI', label: 'Virgin Islands' },
          { value: 'VA', label: 'Virginia' },
          { value: 'WA', label: 'Washington' },
          { value: 'WV', label: 'West Virginia' },
          { value: 'WI', label: 'Wisconsin' },
          { value: 'WY', label: 'Wyoming' },
        ],
      },
      postal_code: {
        label: 'Zip code',
        visibility: true,
        required: true,
      },
    },
    googleComponentsMapping: {
      address_line1: ['street_number', 'route'],
      locality: ['locality'],
      administrative_area: ['administrative_area_level_1'],
      postal_code: ['postal_code'],
    },
  },
];

export default config;
