import React from 'react';
import {
  bool, shape, string,
} from 'prop-types';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router';
import LoginForm from '../../Molecules/Forms/LoginForm/LoginForm';
import Image from '../../Atoms/Media/Image/Image';
import Link from '../../Atoms/Navigation/Links/Link';
import './Login.less';
import Divider from '../../Atoms/Layout/Divider/Divider';
import Text from '../../Atoms/Typography/Text/Text';
import Button from '../../Atoms/Forms/Button/Button';
import useBrowserQuery from '../../Hooks/useBrowserQuery';

const getDestination = () => {
  const destination = useBrowserQuery().get('destination') || '/app';
  if (destination.startsWith('app')) {
    return `/${destination}`;
  }
  return destination;
};

const Login = ({
  logo: {
    alt, preview, href,
  }, initialValues,
}) => {
  const history = useHistory();
  const logoSrc = `/assets/logos/logo-white-${process.env.REACT_APP_X_DEALER}.svg`;
  const destination = getDestination();

  const onFinish = () => history.push(destination);

  return (
    <div className="theme-login">
      <Row className="theme-columns-wrapper">
        <Col>
          <Link to={href}>
            <Image alt={alt} src={logoSrc} preview={preview} />
          </Link>
        </Col>
        <Col>
          <LoginForm initialValues={initialValues} onFinish={onFinish} />
          <Divider dashed />
          <Text>Don&apos;t have an account?</Text>
          <Button block href="/app/join" ghost type="primary">
            Join now
          </Button>
        </Col>
      </Row>
    </div>
  );
};

Login.propTypes = {
  logo: shape({
    alt: string,
    preview: bool,
    href: string,
  }),
  initialValues: shape({}),

};

Login.defaultProps = {
  logo: {
    alt: process.env.REACT_APP_BRAND_LEGAL_NAME,
    preview: false,
    href: process.env.REACT_APP_BRAND_PUBLIC_URL,
  },
  initialValues: {},
};

export default Login;
