import React from 'react';
import PropTypes from 'prop-types';
import Lang from 'lang.js';
import LangContext from './LangContext';
import Source from '../../Resources/Lang/Source.json';

const lang = new Lang({
  messages: Source, // required
  locale: 'en', // optional
  fallback: 'en-nz', // optional
});


const LangProvider = ({ children }) => {
  const t = (key, replacements, locale) => lang.get(key, replacements, locale);

  return (
    <LangContext.Provider value={{ lang, t }}>
      {children}
    </LangContext.Provider>
  );
};
LangProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LangProvider;
