import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Input from '../../../Atoms/Forms/Input/Input';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import LangContext from '../../../Context/Lang/LangContext';
import { getCountry } from '../../../Store/clientRegistrationSlice';
import EntityType from '../EntityType/EntityType';

const Trust = () => {
  const { t } = useContext(LangContext);
  const country = useSelector(getCountry);

  return (
    <>
      <EntityType type="client" bundle="trust" />
      <FormItem
        label="Name of trust"
        name="name"
        rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
      >
        <Input />
      </FormItem>

      {country === 'AU' && (
        <>
          <FormItem
            label="Type of trust"
            name="trustType"
            rules={[{ required: true, message: t('validation.required_option') }]}
          >
            <RadioGroup>
              <Radio value="smsf">SMSF</Radio>
              <Radio value="other">Other</Radio>
            </RadioGroup>
          </FormItem>

          <FormItem
            label="Type of trustee"
            name="trusteeType"
            rules={[{ required: true, message: t('validation.required_option') }]}
          >
            <RadioGroup>
              <Radio value="individual">Individual</Radio>
              <Radio value="corporate">Corporate</Radio>
            </RadioGroup>
          </FormItem>
        </>
      )}
    </>
  );
};

export default Trust;
