import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { Form } from 'antd';
import {
  arrayOf, bool, func, oneOf, oneOfType, string,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import Select from '../../../../Atoms/Forms/Select/Select';
import { CLIENTS_OPTIONS } from '../../../../GraphQL/Queries/Client';

const ClientSelect = (props) => {
  const {
    disabled, label, name = 'entity', options, placeHolder, roles, uuid: uuidProps,
  } = props;
  const { uuid: uuidParams } = useParams();
  const uuid = uuidProps ?? uuidParams;
  const [getClients, { loading, data }] = useLazyQuery(CLIENTS_OPTIONS, {
    variables: { roles },
    notifyOnNetworkStatusChange: true,
  });
  const debouncer = AwesomeDebouncePromise(getClients, 750);

  useEffect(() => {
    getClients({ variables: uuid ? { uuids: [uuid] } : { search: '' } }).then();
  }, [uuid]);

  const formatClientOptions = opts => opts?.map(o => ({ ...o, label: `${o.clientId} ${o.label}` })) || [];

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={uuid}
      rules={[{ required: true, message: 'This field is required' }]}
    >
      <Select
        placeholder={placeHolder}
        allowClear
        disabled={disabled}
        showSearch
        onSearch={search => debouncer({ variables: { search } })}
        options={options ? options?.(formatClientOptions(data?.clients?.results)) : formatClientOptions(data?.clients?.results)}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        notFoundContent={loading ? 'Loading' : 'No results matching'}
      />
    </Form.Item>
  );
};

ClientSelect.propTypes = {
  label: string,
  name: oneOfType([string, arrayOf(string)]),
  disabled: bool,
  roles: oneOfType([
    undefined,
    null,
    arrayOf(oneOf(['investor', 'borrower'])),
  ]),
  options: func,
  placeHolder: string,
  uuid: string,
};

ClientSelect.defaultProps = {
  label: undefined,
  name: 'entity',
  disabled: false,
  roles: ['investor'],
  options: undefined,
  placeHolder: undefined,
  uuid: undefined,
};

export default ClientSelect;
