import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import TaxStatementForm from '../../../Molecules/Forms/TaxStatementForm/TaxStatementForm';
import TaxStatementCard from '../../Cards/Reporting/TaxStatementCard';
import TaxStatementDataTable from '../../../Atoms/Tables/TaxStatementDataTable/TaxStatementDataTable';
import Button from '../../../Atoms/Forms/Button/Button';
import { TAX_STATEMENT_REPORT } from '../../../GraphQL/Queries/TaxStatementReport';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import formatDate, { formatFromMoment } from '../../../Helpers/FormatDate';
import { TAX_STATEMENT_PDF } from '../../../GraphQL/Mutations/Pdf';
import NoResult from '../../../Molecules/NoResult/NoResult';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import SaveToPortalButton from '../SaveToPortalButton';
import useFile from '../../../Hooks/useFile';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const TaxStatement = () => {
  const [reportData, setReportData] = useState();
  const [formValues, setFormValues] = useState();
  const [savedToPortal, setSavedToPortal] = useState(false);
  const [query, { data, loading }] = useLazyQuery(TAX_STATEMENT_REPORT, {
    notifyOnNetworkStatusChange: true,
  });
  const [mutation, { loading: mutating }] = useMutation(TAX_STATEMENT_PDF);
  const { view } = useFile();

  useEffect(() => data && setReportData(data?.taxStatementReport), [data]);

  const onClickPreview = (values) => {
    setSavedToPortal(false);
    setFormValues(values);
    const variables = {
      uuid: values?.entity,
      startDate: formatFromMoment(values.from),
      endDate: formatFromMoment(values.to),
    };

    return query({
      variables,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      skip: !values?.entity,
    });
  };

  const onClickDownloadPdf = () => {
    mutation({
      variables: {
        uuid: formValues?.entity,
        startDate: formatFromMoment(formValues?.from),
        endDate: formatFromMoment(formValues?.to),
      },
    }).then(({ data: responseData }) => {
      view(responseData?.taxStatementPdf);
    });
  };

  const onClickSaveToDocumentPortal = () => {
    mutation({
      variables: {
        uuid: formValues?.entity,
        startDate: formatFromMoment(formValues?.from),
        endDate: formatFromMoment(formValues?.to),
        saveDocument: true,
      },
    }).then(() => setSavedToPortal(true));
  };

  return (
    <>
      <TaxStatementForm onFinish={onClickPreview} loading={loading} />
      <Skeleton loading={loading}>
        { reportData?.client && (
          <Row>
            <Col flex={24}>
              <TaxStatementCard
                client={reportData?.client}
                period={`${formatDate(formValues?.from)} - ${formatDate(formValues?.to)}`}
              />
            </Col>
          </Row>
        )}

        { reportData?.reportRows && (
          <Row>
            <Col flex={24}>
              <TaxStatementDataTable data={reportData?.reportRows} />
            </Col>
          </Row>
        )}

        { !reportData && !data && (
          <NoResult
            title={`No ${brandLabelConfig(dealer(), 'reportingTaxStatement', 'tax statement')} found.`}
            body="Please try a different filter."
          />
        )}

        { reportData && (
        <ButtonGroup direction="bottom-right" className="set-3-style">
          <Button
            type="secondary"
            onClick={onClickDownloadPdf}
            disabled={mutating}
          >
            Download PDF
          </Button>

          <SaveToPortalButton callBack={onClickSaveToDocumentPortal} loading={mutating} saved={savedToPortal} />
        </ButtonGroup>
        )
       }
      </Skeleton>
    </>
  );
};

export default TaxStatement;
