import React, { useContext } from 'react';
import { Col, Form, Row } from 'antd';
import { func, shape } from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Select from '../../../../Atoms/Forms/Select/Select';
import LangContext from '../../../../Context/Lang/LangContext';
import TextArea from '../../../../Atoms/Forms/Input/TextArea/TextArea';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import { capitalize } from '../../../../Helpers/Helpers';
import Input from '../../../../Atoms/Forms/Input/Input';
import LoanApplicationActions from '../../../../Molecules/Forms/Actions/LoanApplicationActions';
import HeaderTitle from '../../../../Molecules/HeaderTitle/HeaderTitle';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import Divider from '../../../../Atoms/Layout/Divider/Divider';
import StepInfo from '../../../../Molecules/Forms/StepInfo/StepInfo';
import './LoanDetailsStep.less';
import { getStep } from '../../../../Store/loanApplicationSlice';
import MaskedInput from '../../../../Atoms/Forms/Input/MaskedInput';
import InformationReleaseToLenders from './InformationReleaseToLenders';
import InputNumber from '../../../../Atoms/Forms/Input/InputNumber';
import { LOAN_PRODUCTS } from '../../../../GraphQL/Queries/Loan';
import { dealerDefaultCountry } from '../../../../Hooks/useLocalStorage';
import DatePickerFormItem from '../../../../Molecules/Forms/DatePickerFormItem/DatePickerFormItem';

const LoanDetailsStep = (props) => {
  const { initialValues, onClickBack, onFinish } = props;
  const { data: loanProductData } = useQuery(LOAN_PRODUCTS);
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const step = useSelector(getStep);
  const isAu = dealerDefaultCountry() === 'AU';
  const requiredRule = { required: true, message: t('validation.required', { attribute: 'field' }) };
  const { softRequired, validateStatus } = useValidationRules;

  const arrayToOptions = items => items?.map(item => ({ label: capitalize(item), value: item }));

  const validateSolicitor = (formInstance, message) => {
    const { getFieldValue, setFieldValue } = formInstance;
    const solicitor = getFieldValue('solicitor');

    if (solicitor) {
      Object?.keys(solicitor).forEach(key => ['', undefined].includes(solicitor[key]) && delete solicitor[key]);

      if (Object?.keys(solicitor).length) {
        return softRequired(formInstance, message);
      }

      setFieldValue('errors', getFieldValue('errors').filter(e => !e?.field?.startsWith('solicitor.')));
    }
    return {};
  };

  const loanProductList = loanProductData?.loanProducts?.results || [];
  const productGroups = [...new Set(loanProductList?.map(item => item?.fieldProductGroup))];

  return (
    <div className="theme-loan-details-step">
      <HeaderTitle title="Loan Details" />
      <Row>
        <Col className="gutter-row" span={12}>
          <Form
            form={form}
            initialValues={Object.keys(initialValues).length ? initialValues : { loanInformationRelease: 'Disclosure' }}
            layout="vertical"
            name="loan-details-form"
            onFinish={onFinish}
          >
            <StepInfo id={uuid()} stepKey={step} />
            <FormItem hidden noStyle name="errors" />
            <InputGroup>
              <FormItem
                label="Select your loan"
                name="product"
                rules={[requiredRule]}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  options={arrayToOptions(productGroups)}
                />
              </FormItem>

              <FormItem
                noStyle
                shouldUpdate={(prev, current) => {
                  const isDifferent = prev.product !== current.product;
                  if (isDifferent || (isDifferent && !current.product)) {
                    form.setFieldValue(['application', 'typeId'], undefined);
                  }

                  return isDifferent;
                }}
              >
                {({ getFieldValue }) => {
                  const product = getFieldValue('product');
                  if (!product) return null;

                  return (
                    <FormItem
                      label="Type of loan"
                      name={['application', 'typeId']}
                      rules={[requiredRule]}
                    >
                      <Select
                        placeholder="Select"
                        allowClear
                        defaultValue={initialValues?.application?.typeId}
                        options={loanProductList?.filter(prod => prod.fieldProductGroup === product).map(prod => ({ label: prod.label, value: prod.fieldLoanProductId }))}
                      />
                    </FormItem>
                  );
                }}

              </FormItem>


              <FormItem
                label="Purpose of loan"
                name={['application', 'purpose']}
                rules={[{ required: true, message: 'This field is required' }]}
                validateStatus={validateStatus(['application', 'purpose'], initialValues?.errors)}
              >
                <TextArea />
              </FormItem>

              <FormItem
                label={isAu ? 'State' : 'Location'}
                name={['application', 'region']}
                rules={[item => softRequired(item, isAu ? 'State' : 'Location')]}
                validateStatus={validateStatus(['application', 'region'], initialValues?.errors)}
              >
                <Select
                  placeholder="Select"
                  allowClear
                  options={isAu ? [
                    { value: 'ACT', label: 'Australian Capital Territory' },
                    { value: 'NSW', label: 'New South Wales' },
                    { value: 'NT', label: 'Northern Territory' },
                    { value: 'QLD', label: 'Queensland' },
                    { value: 'SA', label: 'South Australia' },
                    { value: 'TAS', label: 'Tasmania' },
                    { value: 'VIC', label: 'Victoria' },
                    { value: 'WA', label: 'Western Australia' },
                  ] : arrayToOptions([
                    'Northland',
                    'Auckland',
                    'Waikato',
                    'Bay of Plenty',
                    'Taranaki',
                    'Hawke\'s Bay/Gisborne',
                    'Wellington/Manawatu',
                    'Nelson/Marlborough',
                    'West Coast',
                    'Canterbury',
                    'Otago',
                    'Southland',
                  ])}
                />
              </FormItem>

              <FormItem
                label="Amount of loan"
                name={['application', 'amount']}
                rules={[item => softRequired(item, 'Amount of loan')]}
                validateStatus={validateStatus(['application', 'amount'], initialValues?.errors)}
              >
                <InputNumber
                  prefix="$"
                  formatter={value => (value ? Number(value)?.toLocaleString('en-NZ') : value)}
                />
              </FormItem>

              <FormItem
                noStyle
                shouldUpdate={(prev, current) => prev?.application?.typeId !== current?.application?.typeId}
              >
                {({ getFieldValue }) => {
                  const selected = getFieldValue(['application', 'typeId']);
                  const loanProduct = loanProductList.filter(item => item.fieldLoanProductId === selected).pop();
                  return (loanProduct && loanProduct.fieldTerms.length && (
                    <FormItem
                      label="Term of loan"
                      name={['application', 'term']}
                      rules={[item => softRequired(item, 'Term of loan')]}
                      validateStatus={validateStatus(['application', 'term'], initialValues?.errors)}
                    >
                      <Select
                        placeholder="Select"
                        options={arrayToOptions(loanProduct.fieldTerms)}
                      />
                    </FormItem>
                  )) || null;
                }}
              </FormItem>

              {!isAu && (
                <>
                  <FormItem
                    label="Listing period"
                    name={['application', 'listingQuickOptions']}
                    rules={[item => softRequired(item, 'Listing period')]}
                    validateStatus={validateStatus(['application', 'listingQuickOptions'], initialValues?.errors)}
                    help="Time will commence from the time the loan is accepted and published."
                  >
                    <Select
                      placeholder="Select"
                      options={[
                        { label: '10 working days', value: 10 },
                        { label: '20 working days', value: 20 },
                        { label: '30 working days', value: 30 },
                        { label: 'Choose date', value: -1 },
                      ]}
                    />
                  </FormItem>

                  <FormItem
                    noStyle
                    shouldUpdate={(prev, current) => {
                      const isDifferent = prev?.application?.listingQuickOptions !== current?.application?.listingQuickOptions;
                      if (isDifferent) {
                        if (current.application.listingQuickOptions === -1) {
                          form.setFieldValue(['application', 'listingExpiryDate'], null);
                          return true;
                        }

                        const expiryDate = moment().add(current.application.listingQuickOptions, 'days');
                        form.setFieldValue(['application', 'listingExpiryDate'], expiryDate.format('YYYY-MM-DD'));
                      }
                      return isDifferent;
                    }}
                  >
                    {({ getFieldValue }) => {
                      const selected = getFieldValue(['application', 'listingQuickOptions']);

                      return selected === -1 ? (
                        <DatePickerFormItem
                          dateRestriction="future"
                          label="List until"
                          fieldName="listingExpiryDate"
                          validationRules={[item => softRequired(item, 'List until')]}
                          parents={['application']}
                        />
                      ) : (
                        <FormItem hidden noStyle name={['application', 'listingExpiryDate']}>
                          <Input />
                        </FormItem>
                      );
                    }}
                  </FormItem>
                </>
              )}

              <FormItem
                noStyle
                shouldUpdate={(prev, current) => prev?.application?.typeId !== current?.application?.typeId}
              >
                {({ getFieldValue }) => {
                  const selected = getFieldValue(['application', 'typeId']);
                  const loanProduct = loanProductList.filter(item => item?.fieldLoanProductId === selected).pop();

                  return loanProduct?.fieldShowSettlementDate ? (
                    <DatePickerFormItem
                      dateRestriction="future"
                      label="Settlement date"
                      fieldName="settlementDate"
                      parents={['application']}
                      validationRules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
                    />
                  ) : null;
                }}
              </FormItem>

              { isAu && (
              <FormItem
                label="BSB"
                name={['bank', 'bank']}
              >
                <MaskedInput
                  mask="000-000"
                  maskOptions={{ placeholderChar: '0', lazy: true }}
                />
              </FormItem>
              )}

              <FormItem
                label="Account number"
                name={['bank', 'number']}
              >
                { isAu
                  ? <Input />
                  : (
                    <MaskedInput
                      mask="00-0000-0000000-000"
                      maskOptions={{ placeholderChar: '0', lazy: true }}
                    />
                  ) }
              </FormItem>

            </InputGroup>
            <InputGroup>
              <FormItem
                label="Solicitor's name"
                name={['solicitor', 'name']}
                rules={[item => validateSolicitor(item, 'Solicitor\'s name')]}
                validateStatus={validateStatus(['solicitor', 'name'], initialValues?.errors)}
              >
                <Input />
              </FormItem>

              <FormItem
                label="Solicitor's company"
                name={['solicitor', 'company']}
                rules={[item => validateSolicitor(item, 'Solicitor\'s company')]}
                validateStatus={validateStatus(['solicitor', 'company'], initialValues?.errors)}
              >
                <Input />
              </FormItem>

              <FormItem
                label="Solicitor's email"
                name={['solicitor', 'email']}
                rules={[item => validateSolicitor(item, 'Solicitor\'s email')]}
                validateStatus={validateStatus(['solicitor', 'email'], initialValues?.errors)}
              >
                <Input type="email" />
              </FormItem>

              <FormItem
                label="Solicitor's phone"
                name={['solicitor', 'phone']}
                rules={[item => validateSolicitor(item, 'Solicitor\'s phone')]}
                validateStatus={validateStatus(['solicitor', 'phone'], initialValues?.errors)}
              >
                <Input type="tel" />
              </FormItem>
            </InputGroup>

            { !isAu && <InformationReleaseToLenders />}

            <Divider />

            <LoanApplicationActions onClickBack={onClickBack} form={form} />

          </Form>
        </Col>
      </Row>
    </div>
  );
};

LoanDetailsStep.propTypes = {
  initialValues: shape({}),
  onClickBack: func,
  onFinish: func,
};

LoanDetailsStep.defaultProps = {
  initialValues: {},
  onClickBack: () => {},
  onFinish: () => {},
};

export default LoanDetailsStep;
