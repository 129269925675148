import React from 'react';
import { string, func } from 'prop-types';
import { useQuery } from '@apollo/client';
import { haveEntityPermission } from '../../Helpers/Helpers';
import { userUuid as _userUuid } from '../../Hooks/useLocalStorage';
import Select from '../../Atoms/Forms/Select/Select';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';
import { INVESTOR_CLIENTS } from '../../GraphQL/Queries/Client';

const InvestorSelect = ({ context, selectedInvestor, setSelectedInvestor }) => {
  const userUuid = _userUuid();

  const { loading, data } = useQuery(INVESTOR_CLIENTS, {
    variables: {
      search: '',
      user: (context !== 'dealer' && userUuid) || null,
    },
  });

  const filterClients = (response) => {
    const investorData = response?.investorClients?.results;
    if (investorData && (investorData !== undefined)) {
      const filteredInvestors = investorData.filter(investor => haveEntityPermission(investor, 'Client can invest'));
      return filteredInvestors.map(
        ({ fieldLmsClientId, name, uuid }) => ({ label: `${name} - ${fieldLmsClientId}`, value: uuid }),
      );
    }
    return [];
  };

  const investorOptions = filterClients(data);

  return (
    <FormItem
      name="investorUuid"
      label="Select investor"
      labelCol={12}
    >
      <Select
        placeholder={loading || investorOptions.length === 0 ? 'Loading...' : 'Select'}
        options={investorOptions}
        value={investorOptions.find(({ value }) => value.toString() === selectedInvestor)}
        onChange={selectedOption => setSelectedInvestor(selectedOption)}
        isLoading={investorOptions.length === 0}
      />
    </FormItem>
  );
};

InvestorSelect.propTypes = {
  context: string.isRequired,
  selectedInvestor: string,
  setSelectedInvestor: func,
};

InvestorSelect.defaultProps = {
  selectedInvestor: '',
  setSelectedInvestor: () => {},
};

export default InvestorSelect;
