import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { useSelector, useDispatch } from 'react-redux';
import Loans from '../Pages/LoanApplications/Loans';
import Loan from '../Templates/Loan/Loan';
import ApplyForALoan from '../Templates/LoanApplication/LoanApplication';
import Clients from '../Pages/Client/Clients';
import Client from '../Pages/Client/Client';
import Investments from '../Pages/Investments/Investments';
import InvestorMatchFormPage from '../Pages/InvestorMatch/InvestorMatchFormPage';
import Opportunities from '../Pages/Investments/Opportunities';
import Opportunity from '../Pages/Investments/Opportunity';
import LegalDocs from '../Pages/Documents/LegalDocs';
import ShareableMedia from '../Templates/DocumentViewPage/ShareableMedia';
import Documents from '../Pages/Documents/Documents';
import DocumentAdd from '../Pages/Documents/DocumentAdd';
import DocumentEdit from '../Pages/Documents/DocumentEdit';
import ManagedFunds from '../Pages/ManagedFunds/ManagedFunds';
import ManagedFund from '../Pages/ManagedFunds/ManagedFund';
import LoanApplications from '../Pages/LoanApplications/LoanApplications';
import InvestorMatch from '../Pages/InvestorMatch/InvestorMatch';
import LoanBook from '../Pages/SearchLoans/LoanBook';
import SearchLoans from '../Pages/SearchLoans/SearchLoans';
import NewLoanToInvestors from '../Pages/NewLoanToInvestors/NewLoanToInvestors';
import TaxStatement from '../Templates/Reporting/TaxStatement/TaxStatement';
import InvestmentStatement from '../Templates/Reporting/InvestmentStatement/InvestmentStatement';
import InvestmentCertificate from '../Templates/Reporting/InvestmentCertificate/InvestmentCertificate';
import MyProfile from '../Pages/Client/MyProfile';
import Dashboard from '../Pages/Client/Dashboard';
import Login from '../Templates/Login/Login';
import SetPassword from '../Templates/SetPassword/SetPasword';
import Signup from '../Templates/Signup/Signup';
import Logout from '../Templates/Logout/Logout';
import ForgotPassword from '../Templates/ForgotPassword/ForgotPassword';
import ActivateLogin from '../Templates/ActivateLogin/ActivateLogin';
import ErrorPage from '../Pages/ErrorPage';
import Skeleton from '../Atoms/Feedback/Skeleton/Skeleton';
import ClientRegistration from '../Templates/ClientRegistration/ClientRegistration';
import WithDraft from '../Helpers/WithDraft';
import Reductions from '../Templates/Accounts/Investments/Reductions';
import Investment from '../Templates/Accounts/Investments/Investment';
import Policies from '../Templates/Policies/Policies';
import LoanOffer from '../Templates/LoanOffer/LoanOffer';
import PortalSwitcher from '../Templates/PortalSwitcher/PortalSwitcher';
import {
  isLoggedIn as _isLoggedIn,
  isDealer as _isDealer,
  isBroker as _isBroker,
  isBorrower as _isBorrower,
  isInvestor as _isInvestor,
  logout,
} from '../Store/userSlice';
import AccessDenied from '../Templates/Error/AccessDenied';
import Sso from '../Pages/Admin/Sso';
import StaticDocument from '../Pages/Documents/StaticDocument';
import Referrals from '../Pages/Client/Broker/Referrals';
import Users from '../Pages/Admin/Users';
import IdentityVerificationLog from '../Pages/Admin/IdentityVerificationLog';
import InvestmentsSummary from '../Templates/Reporting/InvestmentsSummary/InvestmentsSummary';
import DocumentViewPage from '../Templates/DocumentViewPage/DocumentViewPage';
import ShareableViewPage from '../Templates/DocumentViewPage/ShareableViewPage';
import MaintenanceMode from '../Templates/Error/MaintenanceMode';

const allContexts = ':context(dealer|broker|investor|borrower)';

const Routes = () => {
  const isLoggedIn = useSelector(_isLoggedIn);
  const isDealer = useSelector(_isDealer);
  const isBroker = useSelector(_isBroker);
  const isBorrower = useSelector(_isBorrower);
  const isInvestor = useSelector(_isInvestor);
  const dispatch = useDispatch();

  const mustBeAnonymous = (to, from, next) => {
    if (!isLoggedIn) {
      dispatch(logout());
      next();
    }

    next.redirect('/app');
  };

  const mustBeDealer = (to, from, next) => {
    if (!isDealer) {
      next.redirect('/access-denied');
    }

    next();
  };

  const isAuthenticated = (to, from, next) => {
    if (!isLoggedIn) {
      const path = window.location.pathname;
      dispatch(logout());
      next.redirect(`/app/login?destination=${path}`);
    }

    if (to?.match?.params?.context === 'dealer') {
      mustBeDealer(to, from, next);
    }

    next();
  };

  const roleRedirects = (to, from, next) => {
    const { match: { path } } = to;

    if (path === '/app') {
      if (isDealer) {
        next.redirect('/app/dealer/clients');
      }
      if (isBroker) {
        next.redirect('/app/broker/dashboard');
      }
      if (isInvestor) {
        next.redirect('/app/investor/dashboard');
      }
      if (isBorrower) {
        next.redirect('/app/borrower/dashboard');
      }
    }

    next();
  };

  const handleAccess = (to, from, next) => {
    next();
  };

  // @todo Important handle destination redirections and auto logout

  return (
    <>
      <Switch>
        <Route path="/app/activate-login/:token" exact component={ActivateLogin} />
        <Route path="/offer/:token" exact component={LoanOffer} />
        <Route path="/access-denied" exact component={AccessDenied} />
        <Route path="/maintenance-mode" exact component={MaintenanceMode} />
        <Route path="/app/share" component={ShareableMedia} />
        <Route path="/share/:shareableUuid/:shareableToken" exact component={ShareableMedia} />
        <Route path="/share/:shareableUuid/:shareableToken/:fileUuid" exact component={ShareableViewPage} />
        <Route path="/static-document/:documentKey" exact component={StaticDocument} />
        <Route path="/app/logout" exact component={Logout} />
        <Route exact path="/">
          <Redirect to="/app" />
        </Route>
      </Switch>
      <GuardProvider guards={[mustBeAnonymous]} loading={Skeleton} error={ErrorPage}>
        <Switch>
          <GuardedRoute path="/app/join" exact component={Signup} />
          <GuardedRoute path="/app/login" component={Login} />
          <GuardedRoute path="/user/login" exact component={Login} />
          <GuardedRoute path="/app/forgot-password" exact component={ForgotPassword} />
        </Switch>
      </GuardProvider>
      <GuardProvider guards={[isAuthenticated, mustBeDealer]} loading={Skeleton} error={ErrorPage}>
        <Switch>
          <GuardedRoute path="/app/:context(dealer)/investor-match" exact component={InvestorMatch} />
          <GuardedRoute path="/app/:context(dealer)/investor-match/:loanUuid" exact component={InvestorMatchFormPage} />
          <GuardedRoute path="/app/:context(dealer)/reductions" exact component={Reductions} />
          <GuardedRoute path="/app/:context(dealer)/new-loan-to-investors" exact component={NewLoanToInvestors} />
          <GuardedRoute path="/app/:context(dealer)/compliance/policies" exact component={Policies} />
          <GuardedRoute path="/app/:context(dealer)/compliance/identity-verification-logs" exact component={IdentityVerificationLog} />
          <GuardedRoute path="/app/:context(dealer)/investment-funds" exact component={ManagedFunds} />
          <GuardedRoute path="/app/:context(dealer)/sso" exact component={Sso} />
          <GuardedRoute path="/app/:context(dealer)/users" exact component={Users} />
        </Switch>
      </GuardProvider>
      <GuardProvider guards={[isAuthenticated, roleRedirects, handleAccess]} loading={Skeleton} error={ErrorPage}>
        <Switch>
          <GuardedRoute path="/app/:context(borrower|broker)/apply" exact component={ApplyForALoan} />
          <GuardedRoute path="/app/:context(borrower|broker|dealer)/apply/:draftUuid" exact component={WithDraft(ApplyForALoan)} />
          <GuardedRoute path={`/app/${allContexts}/client/add`} exact component={ClientRegistration} />
          <GuardedRoute path={`/app/${allContexts}/client/add/:draftUuid`} exact component={WithDraft(ClientRegistration)} />

          <GuardedRoute path={`/app/${allContexts}/clients/:tabs(clients|drafts)?`} exact component={Clients} />
          <GuardedRoute path={`/app/${allContexts}/client/:uuid`} exact component={Client} />

          <GuardedRoute path={`/app/${allContexts}/client/:clientUuid/edit`} exact />
          <GuardedRoute path={`/app/${allContexts}/registration`} exact />
          <GuardedRoute path={`/app/${allContexts}/loan-book`} exact component={LoanBook} />
          <GuardedRoute path={`/app/${allContexts}/search`} exact component={SearchLoans} />
          <GuardedRoute path={`/app/${allContexts}/loans`} exact component={Loans} />

          <GuardedRoute path="/app/:context(borrower|broker)/applications/:tabs(applications|drafts)?" exact component={LoanApplications} />
          <GuardedRoute path={`/app/${allContexts}/investments/:pageContext(direct|fund|closed|secondary|commitments)?`} exact component={Investments} />

          <GuardedRoute path={`/app/${allContexts}/investment/:uuid`} component={Investment} />
          <GuardedRoute path={`/app/${allContexts}/opportunities`} exact component={Opportunities} />
          <GuardedRoute path={`/app/${allContexts}/opportunity/:loanUuid`} exact component={Opportunity} />
          <GuardedRoute path="/app/:context/opportunities" exact component={Opportunities} />
          <GuardedRoute path="/app/:context/opportunity/:loanUuid" exact component={Opportunity} />

          <GuardedRoute path={`/app/${allContexts}/application/:loanUuid/documents`} component={() => null} />
          <GuardedRoute path={`/app/${allContexts}/application/:loanUuid`} component={Loan} />
          <GuardedRoute path={`/app/${allContexts}/offer/:loanUuid/:paymentType`} component={Loan} />
          <GuardedRoute path={`/app/${allContexts}/legal-docs`} component={LegalDocs} />

          <GuardedRoute path={`/app/${allContexts}/documents`} component={Documents} />
          <GuardedRoute path={`/app/${allContexts}/document/add`} component={DocumentAdd} />
          <GuardedRoute path={`/app/${allContexts}/document/:mediaUuid/:pageContext(edit|duplicate)`} component={DocumentEdit} />
          <GuardedRoute path={`/app/${allContexts}/investment-funds/:fundUuid/:displayAccounts(loans|investments)?`} exact component={ManagedFund} />
          <GuardedRoute path={`/app/${allContexts}/reporting/tax-statements/:uuid`} exact component={TaxStatement} />
          <GuardedRoute path={`/app/${allContexts}/reporting/tax-statements`} exact component={TaxStatement} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investments-summary/:uuid`} exact component={InvestmentsSummary} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investments-summary`} exact component={InvestmentsSummary} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investment-certificates/:uuid`} exact component={InvestmentCertificate} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investment-certificates`} exact component={InvestmentCertificate} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investment-statements/:uuid`} exact component={InvestmentStatement} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investment-statements`} exact component={InvestmentStatement} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investor-portfolios/:uuid`} exact component={TaxStatement} />
          <GuardedRoute path={`/app/${allContexts}/reporting/investor-portfolios`} exact component={TaxStatement} />

          <GuardedRoute path="/app/profile" exact component={MyProfile} />
          <GuardedRoute path="/app/:context(borrower|investor|broker)/dashboard" exact component={Dashboard} />
          <GuardedRoute path={`/app/${allContexts}/profile`} exact component={MyProfile} />
          <GuardedRoute path="/app/set-password" exact component={SetPassword} />
          <GuardedRoute path="/user/password" exact component={ForgotPassword} />
          <GuardedRoute path="/app" exact component={PortalSwitcher} />
          <GuardedRoute path="/app/:context(broker|dealer)/referrals" exact component={Referrals} />
          <GuardedRoute path="/document/:mediaUuid/:fileUuid" exact component={DocumentViewPage} />
        </Switch>
      </GuardProvider>
    </>
  );
};

export default Routes;
