const stopWords = [
  'a', 'the', 'and', 'or', 'an', 'of', 'at', 'by', 'for', 'in', 'is', 'on', 'to', 'with', 'am',
];

export const titleCase = (str = '', supportStopWords = true) => str.replace(/\w\S*/g, (word) => {
  if (supportStopWords && stopWords.includes(word.toLowerCase())) {
    return word.toLowerCase();
  }
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
});

export const sentenceCase = (str) => {
  const words = str.split(' ');
  const firstWord = words[0];
  return `${titleCase(firstWord)} ${words.slice(1).join(' ')}`;
};

export const camelCaseToSentenceCase = (str) => {
  const words = str.split(/(?=[A-Z])/);
  return words.join(' ');
};

export const snakeCaseToCamelCase = str => str.replace(/([-_][a-z])/g,
  group => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));

export const snakeCaseToSentenceCase = (str) => {
  const words = str.split('_');
  return words.join(' ');
};
