import React from 'react';
import {
  string, shape, number, func,
} from 'prop-types';
import { Row, Col } from 'antd';
import Card from '../Card';
import Title from '../../../Atoms/Typography/Title/Title';
import CancelReductionRequestForm from '../../../Organisms/Forms/Accounts/Investments/SecondaryMarket/CancelReductionRequestForm';
import Fields from '../../DataDisplay/Fields/Fields';
import Text from '../../../Atoms/Typography/Text/Text';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import './PendingReduction.less';
import { formatUnixDate } from '../../../Helpers/FormatDate';

const PendingReduction = ({ reduction, title, refetch }) => {
  const {
    fieldStatus, fieldAmount, created, fieldExpiry, fieldBreakFee,
  } = reduction;

  const fields = [
    { label: 'Status', value: fieldStatus.toUpperCase() },
    { label: 'Offer amount', value: <Currency value={fieldAmount} /> },
    { label: 'Break fee', value: <Currency value={fieldBreakFee} /> },
    { label: 'Created', value: formatUnixDate(created) },
    { label: 'Expires', value: formatUnixDate(fieldExpiry) },
  ];

  return (
    <Card
      title={(<Title level={4}>{title}</Title>)}
      className="theme-pending-reduction"
    >
      <Fields fields={fields} />
      <Row>
        <Col span={12}>
          <Text>
            Please wait while we process your request.
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <CancelReductionRequestForm reduction={reduction} refetch={refetch} />
        </Col>
      </Row>
    </Card>
  );
};

PendingReduction.propTypes = {
  title: string.isRequired,
  reduction: shape({
    uuid: string,
    fieldStatus: 'pending',
    fieldAmount: number,
    created: number,
    changed: number,
    fieldExpiry: number,
  }).isRequired,
  refetch: func,
};

PendingReduction.defaultProps = {
  refetch: () => {},
};

export default PendingReduction;
