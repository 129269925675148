import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { dealer } from '../../Hooks/useLocalStorage';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';
import StaticDocumentBoxLink from '../../Molecules/Navigation/Links/StaticDocumentBoxLink';

const LegalDocs = () => {
  const { context } = useParams();

  const documentList = [];

  if (context === 'investor') {
    documentList.push(
      {
        name: 'Investor Agreement',
        dockKey: 'investor-agreement',
      },
    );

    if (dealer() !== 'znz') {
      documentList.push(
        {
          name: 'Trust Deed',
          dockKey: 'trust-deed',
        },
        {
          name: 'Salient Features of the Trust',
          dockKey: 'salient-features-trust',
        },
        {
          name: 'Certificate by Accountant',
          dockKey: 'certificate-accountant',
        },
      );
    }

    if (dealer() === 'znz') {
      documentList.push(
        {
          name: 'Declaration of Trust',
          dockKey: 'declaration-of-trust',
        },
        {
          name: 'Disclosure Statement',
          dockKey: 'disclosure-statement',
        },
        {
          name: 'Secondary Market Rules',
          dockKey: 'secondary-market-rules',
        },
      );
    }
  }

  if (context === 'borrower') {
    documentList.push(
      {
        name: 'Borrower Agreement',
        dockKey: 'borrower-agreement',
      },
      {
        name: 'Statement of Assets and Liabilities',
        dockKey: 'statement-assets-liabilities',
      },
      {
        name: 'Credit Guide',
        dockKey: 'credit-guide',
      },
      {
        name: 'Direct Debit Form',
        dockKey: 'direct-debit-form',
      },
    );
  }

  return (
    <div className="theme-legal-docs page-inner">
      <HeaderTitle title="Legal Documents">
        <Paragraph>
          Below is a list of legal docs.
        </Paragraph>
      </HeaderTitle>
      <Row gutter={[16, 16]} className="legal-documents row">
        {documentList.map(({ dockKey, name }) => (
          <Col key={dockKey} xs={12} sm={6} md={4}>
            <StaticDocumentBoxLink key={dockKey} docKey={dockKey} title={name} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default LegalDocs;
