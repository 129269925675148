import React from 'react';

const Select = (props) => {
  const {
    placeholder,
    options,
    formGroup,
    value,
    onChange,
  } = props;

  let selectElement = (
    <select
      className="form-control form-select"
      value={value || ''}
      onChange={onChange}
    >
      <option value="">{placeholder}</option>
      {
        options.map(({ label, value: optionValue }) => <option key={optionValue} value={optionValue}>{ label }</option>)
      }
    </select>
  );

  if (formGroup) {
    selectElement = <div className="form-group form-type-select">{selectElement}</div>;
  }

  return selectElement;
};

export default Select;
