import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { SettingFilled } from '@ant-design/icons';
import ClientDetails from '../../Organisms/Cards/Clients/ClientDetails';
import { CLIENT_BY_UUID } from '../../GraphQL/Queries/Client';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import Result from '../../Atoms/Feedback/Result/Result';
import Button from '../../Atoms/Forms/Button/Button';
import ClientSettings from '../../Organisms/Drawers/ClientSettings/ClientSettings';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import ClientAccounts from '../../Organisms/Clients/ClientAccounts';
import Members from '../../Organisms/Clients/Members';
import RelatedClients from '../../Organisms/Clients/RelatedClients';
import BrokerDetails from '../../Organisms/Clients/BrokerDetails';
import ClientLoanApplicationButton from '../../Organisms/Control/ClientLoanApplicationButton';
import './Client.less';

const Client = ({ match }) => {
  const { params: { context, uuid } } = match || {};
  const [drawerVisible, setDrawerVisible] = useState(false);
  const {
    loading, error, data, refetch,
  } = useQuery(CLIENT_BY_UUID, {
    variables: { uuid },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <Skeleton loading={loading} paragraph={{ rows: 6 }} />
    );
  }

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { client } = data;

  if (!client) {
    return (<Result status="404" title="Sorry, client not found!" />);
  }

  const {
    name, fieldFirstName, fieldLastName, organisations,
  } = client;

  const operations = (
    <Button shape="circle" onClick={() => setDrawerVisible(true)} icon={<SettingFilled />} />
  );

  return (
    <div className="theme-client page-inner">
      <HeaderTitle title={name || `${fieldFirstName} ${fieldLastName}`} />
      <div className="wrapper">
        <ClientDetails client={client} operations={operations} />
        <Members members={client?.members} />
        <RelatedClients organisations={organisations} />
        <BrokerDetails acting={client?.acting[0]?.fieldBroker} />
        { (context === 'borrower' || context === 'broker') && (
        <ClientAccounts title="Loans" uuid={uuid} accountTypes={['loan']} />
        )}
        { context === 'investor' && <ClientAccounts title="Investments" uuid={uuid} accountTypes={['investment']} />}
        { context === 'dealer' && <ClientAccounts title="Accounts" uuid={uuid} />}
        <ClientLoanApplicationButton client={client} />
        <ClientSettings
          client={client}
          onSuccess={refetch}
          setVisible={setDrawerVisible}
          visible={drawerVisible}
        />
      </div>
    </div>
  );
};

Client.propTypes = {
  match: shape({
    params: shape({
      context: string.isRequired,
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Client;
