import React, { useState } from 'react';
import { func } from 'prop-types';
import { Checkbox } from 'antd';
import StaticDocumentSimpleLink from '../../../Atoms/Navigation/Links/StaticDocumentSimpleLink';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';
import './OfferCheckboxes.less';

const OfferCheckboxes = ({ allCheckedCallback }) => {
  const checkboxes = [
    {
      key: 'borrower_agreement',
      title: (
        <>
          that the terms of the
          {' '}
          <StaticDocumentSimpleLink docKey="borrower-agreement">Borrower Agreement</StaticDocumentSimpleLink>
          {' '}
          (comprising three parts including this Part C), the
          {' '}
          <StaticDocumentSimpleLink docKey="website-terms">Website Terms</StaticDocumentSimpleLink>
          {' '}
          and the
          {' '}
          <StaticDocumentSimpleLink docKey="privacy-policy">Privacy Policy</StaticDocumentSimpleLink>
          {' '}
          (as amended by us from time to time), apply and continue to apply to this Loan
        </>
      ),
      checked: false,
    },
    {
      key: 'credit_contract',
      title: `to enter into a Credit Contract and Security Documents to be provided by ${process.env.REACT_APP_BRAND}`,
      checked: false,
    },
    {
      key: 'recovery_fee',
      title: 'that a Platform Recovery Fee will apply if I/we withdraw after the Loan is fully subscribed',
      checked: false,
    },
    {
      key: 'other_conditions',
      // eslint-disable-next-line
      title: `to comply with any other conditions or requirements specified in this Borrower Loan Terms Sheet or otherwise in writing by ${process.env.REACT_APP_BRAND} within the Offer Period or timeframe specified by ${process.env.REACT_APP_BRAND}.`,
      checked: false,
    },
  ];

  const [checkboxState, setCheckboxState] = useState(checkboxes);

  const clickHandler = (event) => {
    const newCheckboxState = checkboxState.map((checkbox) => {
      const updatedCheckbox = checkbox;
      if (checkbox.key === event.target.name) {
        updatedCheckbox.checked = !updatedCheckbox.checked;
        return updatedCheckbox;
      }
      return checkbox;
    });

    const allChecked = newCheckboxState.reduce((carry, checkbox) => carry && checkbox.checked, true);

    if (allCheckedCallback) {
      allCheckedCallback(allChecked);
    }

    setCheckboxState(newCheckboxState);
  };

  const renderedCheckboxes = checkboxes.map(checkbox => (
    <Checkbox
      key={checkbox.key}
      name={checkbox.key}
      defaultChecked={checkboxState[checkbox.key]}
      onChange={clickHandler}
    >
      {checkbox.title}
    </Checkbox>
  ));

  return (
    <div className="theme-offer-checkboxes">
      <Paragraph><strong>I ACKNOWLEDGE:</strong></Paragraph>
      {renderedCheckboxes}
    </div>
  );
};

OfferCheckboxes.propTypes = {
  allCheckedCallback: func,
};

OfferCheckboxes.defaultProps = {
  allCheckedCallback: null,
};


export default OfferCheckboxes;
