import React from 'react';
import TaxStatementOrganism from '../../../Organisms/Reporting/TaxStatement/TaxStatement';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const TaxStatement = () => (
  <>
    <HeaderTitle
      title={`Reporting / ${brandLabelConfig(dealer(), 'reportingTaxStatements', 'Tax Statements')}`}
      comment="Generate and manage your reports."
    />
    <TaxStatementOrganism />
  </>
);

export default TaxStatement;
