import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  getCountry,
  getRegisterEntities,
  getAllDeclareTaxStepEntities,
  setEntity,
  setSelected,
  getSelectedType,
  getSelected,
  getCurrentStep,
  historyPushStepIndex,
  getSteps,
} from '../../../Store/clientRegistrationSlice';
import TaxNumber from '../../../Molecules/Forms/TaxNumber/TaxNumber';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import EntitiesSelector from '../../../Molecules/EntitiesSelector/EntitiesSelector';
import BankAccountNumber from '../../../Molecules/Forms/BankAccountNumber/BankAccountNumber';
import './DeclareTax.less';
import Text from '../../../Atoms/Typography/Text/Text';
import TaxNZFields from '../../../Molecules/Forms/TaxNZFields/TaxNZFields';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';

const DeclareTax = ({
  onProceed, onGoBack, stepKey,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const country = useSelector(getCountry);

  const entities = useSelector(getRegisterEntities);
  const declareTaxStepEntities = useSelector(getAllDeclareTaxStepEntities);
  const steps = useSelector(getSteps);
  const step = useSelector(getCurrentStep);
  const rootEntityId = entities.length > 0 ? entities[0].id : null;
  const selected = useSelector(getSelected);
  if (!selected && steps[step].component === stepKey) {
    dispatch(setSelected(rootEntityId));
  }
  const currentStep = useSelector(getCurrentStep);
  const [isClient, setIsClient] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const type = useSelector(getSelectedType);

  const onFinish = (values) => {
    const valuesCopy = values;
    if (valuesCopy.id === undefined) {
      valuesCopy.id = uuid();
    }
    dispatch(setEntity(valuesCopy));

    if (entities.length && selected === entities[entities.length - 1].id) {
      onProceed(undefined);
      return;
    }

    dispatch(historyPushStepIndex(currentStep));

    const selectedIndexCopy = selectedIndex;
    dispatch(setSelected(entities[selectedIndexCopy + 1]?.id));
    setSelectedIndex(selectedIndexCopy + 1);
  };

  const isValidTax = entity => entity.tax !== undefined && entity.taxDetails;

  useEffect(() => {
    const updateBankDisplay = (newSelected) => {
      if (newSelected === rootEntityId) {
        setIsClient(true);
        return;
      }
      setIsClient(false);
    };

    const updateFormState = (newSelected, newEntities) => {
      let taxDetails = false;
      const entity = newEntities.find(item => item.parentId === newSelected);
      if (entity) {
        taxDetails = entity;
      }

      if (!taxDetails) {
        form.resetFields();
      }

      if (taxDetails) {
        form.setFieldsValue(taxDetails);
      }
    };

    updateBankDisplay(selected);
    updateFormState(selected, declareTaxStepEntities);
    setSelectedIndex(entities.reduce((carry, entity, index) => {
      if (entity.id === selected) {
        return index;
      }
      return carry;
    }, 0));
  }, [selected, declareTaxStepEntities]);

  useEffect(() => {
    const selectedDeclareTaxStepEntity = declareTaxStepEntities?.find(entity => entity.parentId === selected);
    form.setFieldsValue({ ...selectedDeclareTaxStepEntity, parentId: selected });
  }, [selected]);

  return (
    <div className="theme-declare-tax">
      <HeaderTitle
        title="Declare tax status"
      />
      <Row>
        <Col className="theme-declare-tax-contents-wrapper" span={12}>

          <EntitiesSelector
            label="For each of the following"
            allowNew={false}
            entities={type !== 'individual' ? entities : entities.slice(0, 1)}
            defaultValue={selected}
            isValid={isValidTax}
            onChange={(event) => { console.log('Event: ', event.target.value); dispatch(setSelected(event.target.value)); }}
          />

          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            initialValues={{ countryTaxes: [{}] }}
            name="declareTaxStatusForm"
          >
            <StepInfo id={uuid()} stepKey={stepKey} parentId={selected} />
            <TaxNumber parents={[]} />

            { (country === 'NZ' && isClient) && <TaxNZFields parents={[]} />}

            { isClient && <BankAccountNumber country={country} editable /> }

            { country === 'AU' && (
            <Text type="section-comment">
              For compliance purposes, all Investors are required to complete a Self-Certification Form. We will send you an email with the form for completion.
            </Text>
            )}
            <FormItem hidden noStyle name="errors" />
            <ClientRegistrationActions form={form} onGoBack={onGoBack} />
          </Form>
        </Col>
      </Row>
    </div>
  );
};

DeclareTax.propTypes = {
  onProceed: func,
  onGoBack: func,
  stepKey: string,
};

DeclareTax.defaultProps = {
  onProceed: () => {},
  onGoBack: () => {},
  stepKey: 'declare-tax-status-form',
};

export default DeclareTax;
