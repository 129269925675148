import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import FormItem from '../../Atoms/Forms/FormItem/FormItem';
import Select from '../../Atoms/Forms/Select/Select';
import { USER_BROKER_OPTIONS } from '../../GraphQL/Queries/Client';
import LangContext from '../../Context/Lang/LangContext';
import Input from '../../Atoms/Forms/Input/Input';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
// @todo Important test after redux new uuid.
import {
  userUuid as _userUuid,
  isBroker as _isBroker,
  isIntroducer as _isIntroducer,
} from '../../Store/userSlice';

const BrokerSelect = () => {
  const userUuid = useSelector(_userUuid);
  const [brokers, setBrokers] = useState([]);
  const { t } = useContext(LangContext);
  const isBroker = useSelector(_isBroker);
  const isIntroducer = useSelector(_isIntroducer);

  if (!isBroker && !isIntroducer) {
    return null;
  }

  const {
    loading,
    data,
  } = useQuery(USER_BROKER_OPTIONS, { variables: { user: userUuid } });

  useEffect(() => {
    if (data) {
      setBrokers(data?.clients?.results);
    }
  }, [data]);

  if (loading) {
    return (<Skeleton loading={loading} paragraph={{ rows: 1 }} />);
  }

  if (brokers?.length === 0) {
    return null;
  }

  if (brokers?.length === 1) {
    return (
      <FormItem hidden name="broker" initialValue={brokers[0].value}>
        <Input />
      </FormItem>
    );
  }

  return (
    <FormItem
      label="Use introducer"
      name="broker"
      rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
    >
      <Select
        options={brokers}
        placeholder="Broker client"
        showSearch={brokers.length > 10}
      />
    </FormItem>
  );
};

export default BrokerSelect;
