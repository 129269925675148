import React, { useState } from 'react';
import { Row } from 'antd';
import { shape, string } from 'prop-types';
import { useQuery } from '@apollo/client';
import { SettingFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InvestorDetails from '../../../Organisms/Cards/Clients/InvestorDetails';
import LoanDetails from '../../../Organisms/Cards/Accounts/LoanDetails';
import SecurityDetails from '../../../Organisms/Cards/Accounts/SecurityDetails';
import './Investment.less';
import { INVESTMENT_BY_UUID } from '../../../GraphQL/Queries/Account';
import Skeleton from '../../../Atoms/Feedback/Skeleton/Skeleton';
import Result from '../../../Atoms/Feedback/Result/Result';
import TransferFunds from '../../../Organisms/Cards/Accounts/TransferFunds';
import ReductionControl from '../../../Organisms/Control/ReductionControl';
import LoanHeader from '../../../Pages/Account/Components/LoanHeader';
import { InvestmentPlusLoanDocuments, InvestmentDocuments } from '../../../Components/Account/AccountDocuments';
import Button from '../../../Atoms/Forms/Button/Button';
import Tabs from '../../../Atoms/DataDisplay/Tabs/Tabs';
import TabPane from '../../../Atoms/DataDisplay/TabPane/TabPane';
import {
  haveEntityPermission,
} from '../../../Helpers/Helpers';
import Reductions from './Reductions';
import AccountSettings
  from '../../../Organisms/Drawers/AccountSettings/AccountSettings';
import GenerateReportButton from '../../../Molecules/Forms/Actions/GenerateReportButton';
import InvestmentManagedFundPage from '../../../Pages/Investments/InvestmentManagedFundPage';
import { hasPermission } from '../../../Store/appConfigurationSlice';
import LoanPayments from '../../../Organisms/Cards/Accounts/LoanPayments';
import { dealer } from '../../../Hooks/useLocalStorage';


const Investment = ({ match }) => {
  const { params: { context, uuid } } = match || {};
  const {
    loading, error, data, refetch,
  } = useQuery(INVESTMENT_BY_UUID, { variables: { uuid } });
  const [showRequestReductionForm, setShowRequestReductionForm] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const toggleRequestReductionForm = () => setShowRequestReductionForm(prev => !prev);
  const hasReductionAccess = useSelector(hasPermission('dealer use reductions'));

  if (loading) {
    return (
      <Skeleton loading={loading} />
    );
  }

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { investment, documents } = data;
  const isEditable = haveEntityPermission(investment, 'Can edit account') && context === 'dealer';

  const {
    fieldClient: investor,
    fieldLoan,
    fieldManagedFund,
    fieldPool,
    fieldAmount,
    fieldState,
    fieldFundsReceived,
    fieldLmsAccountId: lmsInvestmentAccountId,
    lmsAccount,
  } = investment;

  const { name } = fieldLoan?.fieldClient || { name: '' };
  const { fieldBpayKey, name: investorName } = investor;
  const lmsAccountData = (lmsAccount && JSON.parse(lmsAccount)) || null;

  const { results: investmentDocuments } = documents;

  const operations = (
    <Button shape="circle" onClick={() => setDrawerVisible(true)} icon={<SettingFilled />} />
  );

  if (fieldManagedFund) {
    return (
      <InvestmentManagedFundPage
        context={context}
        fund={fieldManagedFund}
        investment={investment}
        managedFundLoans={null}
        documents={investmentDocuments}
      />
    );
  }

  return (
    <div className="theme-investment">
      {/* todo the loan header still needs to be refactored to atomic */}
      <LoanHeader
        loanHeaderImage={null}
        loanState={fieldState || fieldLoan?.fieldState || fieldManagedFund?.fieldFundState}
        referenceId={lmsInvestmentAccountId}
        name={fieldLoan?.loanTitle}
        loanId={fieldLoan?.fieldLmsAccountId || fieldManagedFund?.fieldLmsPoolId}
        loanType={name}
        loanAmount={fieldAmount}
        metaTitle={fieldLoan ? `Investment - ${fieldAmount}` : 'Investment'}
        context={context}
      />
      <Row>
        <Tabs defaultActiveKey={`details-${uuid}`} tabBarExtraContent={isEditable && operations} type="card">
          <TabPane tab="Details" key={`details-${uuid}`}>
            <InvestorDetails client={investor} />
            {fieldState === 'live-subscribed' && (
              <TransferFunds
                bpayReference={fieldBpayKey}
                accountReference={`${lmsInvestmentAccountId} ${investorName}`}
                fundsReceived={fieldFundsReceived}
              />
            )}

            {fieldLoan && (<LoanDetails loan={fieldLoan} pool={fieldPool} investment={investment} context={context} />)}
            <LoanPayments
              accountState={fieldState}
              context={context}
              lmsAccount={lmsAccountData}
              pageContext="investment"
            />

            {fieldLoan?.fieldPrimarySecurity && (
            <SecurityDetails lvr={fieldLoan?.fieldLvr} totalValue={fieldLoan?.fieldSecuritiesTotal} data={fieldLoan?.fieldPrimarySecurity} />
            )}

            {hasReductionAccess && (
              <ReductionControl
                showRequestReductionForm={showRequestReductionForm}
                toggleRequestReductionForm={toggleRequestReductionForm}
                investment={investment}
                refetch={refetch}
              />
            )}
          </TabPane>
          <TabPane tab="Documents" key={`documents-${uuid}`}>
            {/* todo the loan header still needs to be refactored to atomic */}
            { dealer() === 'znz' && fieldLoan?.uuid && (
              <InvestmentPlusLoanDocuments media={investmentDocuments} loanUuid={fieldLoan.uuid} />
            )}
            { dealer() !== 'znz' && (
              <InvestmentDocuments media={investmentDocuments} />
            )}
          </TabPane>
          {hasReductionAccess && context === 'dealer' && (
            <TabPane tab="Reductions" key={`reductions-${uuid}`}>
              <Reductions investmentUuid={uuid} />
            </TabPane>
          )}
        </Tabs>
        <AccountSettings
          account={investment}
          context={context}
          entityType="investment"
          onSuccess={refetch}
          setVisible={setDrawerVisible}
          visible={drawerVisible}
        >
          <Row className="drawer-button">
            <GenerateReportButton>

              <Row className="drawer-button">
                <Link to={`/app/${context}/reporting/investment-statements/${uuid}`}>
                  <Button type="primary">
                    Investment Statement
                  </Button>
                </Link>
              </Row>

              <Row className="drawer-button">
                <Link to={`/app/${context}/reporting/investment-certificates/${uuid}`}>
                  <Button type="primary">
                    Investment Certificate
                  </Button>
                </Link>
              </Row>

            </GenerateReportButton>
          </Row>
        </AccountSettings>
      </Row>
    </div>
  );
};

Investment.propTypes = {
  match: shape({
    params: shape({
      context: string.isRequired,
      uuid: string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Investment;
