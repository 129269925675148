import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import ScrollToTop, { getHeaderImage } from '../../Helpers/Helpers';
import { LOAN } from '../../GraphQL/Queries/Loan';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import LoanHeader from '../../Organisms/Headers/LoanHeader';
import AccountTabs from '../../Organisms/Control/AccountTabs';
import { LoanDocuments } from '../../Components/Account/AccountDocuments';
import Card from '../../Molecules/Cards/Card';
import Title from '../../Atoms/Typography/Title/Title';
import LoanPoolsTable from '../../Molecules/Loans/LoanPoolsTable';
import LoanInvestmentsTable from '../../Organisms/Accounts/Loans/LoanInvestmentsTable';
import { isDealer as _isDealer } from '../../Store/userSlice';
import AccountClientDetails from '../../Organisms/Cards/Accounts/AccountClientDetails';
import FundingProgress from '../../Molecules/DataDisplay/FundingProgress/FundingProgress';
import LoanDetails from '../../Organisms/Cards/Accounts/LoanDetails';
import SecurityDetails from '../../Organisms/Cards/Accounts/SecurityDetails';
import Text from '../../Atoms/Typography/Text/Text';
import HasEntityPermission from '../../Molecules/Control/HasEntityPermission';
import LoanPayments from '../../Organisms/Cards/Accounts/LoanPayments';
import {
  loanHasCompleteFunding, loanIsBackfill, loanIsOffer,
} from '../../Helpers/loanShortcuts';
import LoanInvestmentsList from '../../Organisms/Loan/LoanInvestmentsList';
import OfferDetails from '../../Organisms/Cards/Accounts/OfferDetails';
import Offer from '../../Organisms/Forms/Offer/Offer';
import PropsRoute from '../../Helpers/PropsRoute';
import PermissionDeniedMessage from '../../Components/Common/PermissionDeniedMessage';
import CurrentInvestments from '../../Pages/LoanApplications/CurrentInvestments/CurrentInvestments';
import InvestPanel from '../../Organisms/Loan/InvestPanel';
import LoanFundingButton from '../../Molecules/LoanFunding/LoanFundingButton';

const Loan = () => {
  const { context, loanUuid: uuid } = useParams();
  const [display, setDisplay] = useState('details');
  const isDealer = useSelector(_isDealer);
  const { pathname: url } = useLocation();

  ScrollToTop();

  const {
    loading, data,
  } = useQuery(LOAN, { variables: { uuid } });

  if (loading) {
    return <Skeleton loading={loading} />;
  }

  if (!data) {
    return (<div />);
  }

  const {
    loan,
    investments: { results: investments },
    documents: { results: documents },
  } = data;

  const {
    fieldAmount,
    fieldClient,
    fieldCreditRating,
    fieldLmsAccountId,
    fieldLvr,
    fieldPrimarySecurity,
    fieldSecuritiesTotal,
    fieldState,
    currentFundingPool,
    currentPool,
    dealer,
    lmsAccount,
    loanTitle,
  } = loan;
  const lmsAccountData = (lmsAccount && JSON.parse(lmsAccount)) || null;
  const activePool = (currentFundingPool || currentPool);

  return (
    <div className="theme-loan-application page-inner">
      <LoanHeader
        amount={fieldAmount}
        headerImage={getHeaderImage(fieldPrimarySecurity, 'original')}
        loanId={fieldLmsAccountId}
        metaTitle={`Loan - ${fieldLmsAccountId}`}
        name={loanTitle}
        state={fieldState}
      />

      <AccountTabs setDisplay={setDisplay} currentTab={display} account={loan} type="loan" hasInvestments={!!investments?.length} />

      {display === 'details' && (
        <>
          <AccountClientDetails title="Borrower details" client={fieldClient} creditRating={fieldCreditRating} />

          <LoanDetails
            loan={loan}
            pool={activePool}
          >
            <FundingProgress account={loan} />
          </LoanDetails>

          {fieldPrimarySecurity && (
            <HasEntityPermission entity={loan} name="View security">
              <SecurityDetails lvr={fieldLvr} totalValue={fieldSecuritiesTotal} data={fieldPrimarySecurity} />
            </HasEntityPermission>
          )}

          {dealer === 'znz' && loanIsBackfill(loan) && (
            <>
              <Text type="comment">
                Since the original commencement of the loan and its listing as a Secondary Market investment
                opportunity, there may have been events that affect the creditworthiness of the borrower but which are
                not known to Zagga or investors.
              </Text>
              <br />
            </>
          )}

          <LoanPayments
            accountState={fieldState}
            lmsAccount={lmsAccountData}
          />

          {loanHasCompleteFunding(loan) && context === 'dealer' && (
            <LoanInvestmentsList investments={investments} loan={loan} />
          )}

          {loanIsOffer(loan) && (
            <>
              <OfferDetails
                lmsAccount={lmsAccountData}
              />
              <Offer
                loanUuid={uuid}
                lmsAccount={lmsAccountData}
              />
            </>
          )}

          <HasEntityPermission entity={loan} name="Can make commitment">
            <PropsRoute
              exact
              path="/app/:context/application/:loanUuid"
              loan={loan}
              investments={investments}
              component={CurrentInvestments}
            />
            <PropsRoute
              path="/app/:context/application/:loanUuid/fund/:clientUuid?"
              loan={loan}
              investments={investments}
              component={InvestPanel}
            />
            <LoanFundingButton url={url} />
          </HasEntityPermission>
          {context === 'investor' && (
            <PermissionDeniedMessage entity={loan} name="Can make commitment" />
          )}
        </>
      )}

      {isDealer && display === 'pools' && (
        <Card
          className="theme-client-details"
          title={(<Title level={4}>Pools</Title>)}
        >
          <LoanPoolsTable account={loan} />
        </Card>
      )}

      { isDealer && display === 'investments' && (
        <Card
          className="theme-client-details"
          title={(<Title level={4}>Investments</Title>)}
        >
          <LoanInvestmentsTable loan={loan} investments={investments} />
        </Card>
      )}

      { display === 'documents' && (
        <LoanDocuments media={documents} />
      )}
    </div>
  );
};

export default Loan;
