import React from 'react';
import { Input } from 'antd';
import { bool, string } from 'prop-types';
import './InputGroup.less';

const { Group: AntdGroup } = Input;

const InputGroup = (args) => {
  const { className } = args;
  return <AntdGroup {...args} className={className !== undefined ? `theme-input-group ${className}` : 'theme-input-group'} />;
};

InputGroup.propTypes = {
  className: string,
  compact: bool,
  size: string,
};

InputGroup.defaultProps = {
  className: undefined,
  compact: false,
  size: 'default',
};

export default InputGroup;
