import React, { useState } from 'react';
import Title from '../../Atoms/Typography/Title/Title';
import Drawer from '../../Atoms/Feedback/Drawer/Drawer';
import PolicyForm from '../../Organisms/Forms/Policies/PolicyForm';
import PoliciesTable from '../../Organisms/Compliance/PoliciesTable';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import Button from '../../Atoms/Forms/Button/Button';

const Policies = () => {
  const [showForm, setShowForm] = useState(false);
  const [currentPolicy, setCurrentPolicy] = useState(undefined);

  const onOpenEditForm = (policy) => {
    setCurrentPolicy(policy);
    setShowForm(true);
  };

  const onClose = () => {
    setShowForm(false);
  };

  return (
    <div className="theme-policies page-inner">
      <HeaderTitle
        title="Policies"
        comment="A list of policies."
      >
        <Button type="primary" onClick={() => onOpenEditForm(undefined)}>Add a policy</Button>
      </HeaderTitle>
      <PoliciesTable onOpenEditForm={onOpenEditForm} />
      <Drawer
        title={<Title level={2}>{`${!currentPolicy ? 'Create' : 'Edit'} policy`}</Title>}
        size="large"
        destroyOnClose
        open={showForm}
        onClose={() => setShowForm(false)}
      >
        <PolicyForm policy={currentPolicy} onCancel={onClose} />
      </Drawer>
    </div>
  );
};


export default Policies;
