import React from 'react';
import { func, bool } from 'prop-types';
import {
  Col, Row, Form,
} from 'antd';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Input from '../../../Atoms/Forms/Input/Input';
import Select from '../../../Atoms/Forms/Select/Select';
import { brokerLabel } from '../../../Hooks/useRoleLabelFormatter';
import Button from '../../../Atoms/Forms/Button/Button';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import { getRoles } from '../../../Store/appConfigurationSlice';

const ClientSearchForm = (props) => {
  const { updateVariables, showRoles } = props;
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const roles = useSelector(getRoles);

  const defaultRoles = [
    { label: 'Investors', value: 'investor' },
    { label: 'Borrowers', value: 'borrower' },
    { label: brokerLabel(true), value: 'broker' },
  ];

  return (
    <Form
      onValuesChange={(values, all) => updateVariables(all)}
      className="theme-filter"
    >
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={showRoles ? 4 : 5}>
          <FormItem
            label=""
            name="search"
          >
            <Input placeholder="Search" />
          </FormItem>
        </Col>
        <Col xs={12} md={showRoles ? 3 : 5}>
          <FormItem
            label=""
            name="types"
          >
            <Select
              placeholder="Type"
              allowClear
              options={[
                { label: 'Individual', value: 'individual' },
                { label: 'Individuals', value: 'individuals' },
                { label: 'Company', value: 'company' },
                { label: 'Trust', value: 'trust' },
              ]}
            />
          </FormItem>
        </Col>
        { showRoles && (
          <Col xs={12} md={3}>
            <FormItem
              label=""
              name="roles"
            >
              <Select
                placeholder="Role"
                allowClear
                options={roles?.map(item => ({ ...item, label: pluralize(item.label) })) || defaultRoles}
              />
            </FormItem>
          </Col>
        )}
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={() => updateVariables({})}>
            Clear
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

ClientSearchForm.propTypes = {
  updateVariables: func.isRequired,
  showRoles: bool,
};

ClientSearchForm.defaultProps = {
  showRoles: true,
};

export default ClientSearchForm;
