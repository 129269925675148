import React from 'react';
import {
  func,
  string,
  bool,
} from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Button from '../../Atoms/Forms/Button/Button';

const SaveToPortalButton = ({
  btnText, btnTextSuccess, loading, callBack, saved,
}) => (
  <Button
    type="primary"
    onClick={callBack}
    disabled={loading || saved}
  >
    {saved && (
      <>
        <FontAwesomeIcon icon={faCheck} />
        {btnTextSuccess}
      </>
    )}
    {!saved && btnText}
  </Button>
);

SaveToPortalButton.propTypes = {
  btnText: string,
  btnTextSuccess: string,
  loading: bool,
  callBack: func.isRequired,
  saved: bool,
};

SaveToPortalButton.defaultProps = {
  btnText: 'Save to document portal',
  btnTextSuccess: 'Saved to document portal',
  loading: false,
  saved: false,
};

export default SaveToPortalButton;
