import React from 'react';
import {
  arrayOf, number, shape, string,
} from 'prop-types';
import Title from '../../Atoms/Typography/Title/Title';
import Currency from '../../Atoms/DataDisplay/Field/Currency';

const ClientTotalCommitment = ({
  investments, clientUuid, investors, newInvestmentAmount,
}) => {
  if (Array.isArray(investments) && Array.isArray(investors) && !!clientUuid) {
    const existingInvestor = investors.filter(investor => investor.uuid === clientUuid)?.pop();
    const existingInvestment = investments.filter(investment => investment.fieldClient.uuid === clientUuid)?.pop();
    return (
      <div className="theme-client-total-commitment">
        <Title level={5}>
          {existingInvestor?.clientName}
          {' '}
          total:
          {' '}
          <Currency value={(existingInvestment?.fieldAmount || 0) + (newInvestmentAmount || 0)} decimals="2" />
        </Title>
      </div>
    );
  }
  return '';
};

ClientTotalCommitment.propTypes = {
  investments: arrayOf(shape({})),
  clientUuid: string,
  investors: arrayOf({}),
  newInvestmentAmount: number,
};

ClientTotalCommitment.defaultProps = {
  investments: [],
  clientUuid: '',
  investors: [],
  newInvestmentAmount: '',
};

export default ClientTotalCommitment;
