import React from 'react';
import { Form, Slider } from 'antd';

const Term = () => {
  const options = {};
  let index;
  for (index = 3; index < 25; index += 1) {
    options[index] = index;
  }
  return (
    <Form.Item
      label="Term"
      name="term"
      rules={[{ required: true, message: 'Please input loan term.' }]}
      initialValues={{ term: 12 }}
    >
      <Slider
        max={24}
        min={3}
        marks={options}
      />
    </Form.Item>
  );
};

export default Term;
