import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigateToStep } from '../../../Store/clientRegistrationSlice';
import './NavigateToStep.less';

const NavigateToStep = ({ stepEntityId, label, extraHandler }) => {
  const dispatch = useDispatch();

  return (
    <span
      className="ant-list theme-field-step-error"
      tabIndex={0}
      role="button"
      key={`navigate-to-step-${stepEntityId}`}
      onClick={() => { extraHandler(); dispatch(navigateToStep(stepEntityId)); }}
      onKeyPress={() => { extraHandler(); dispatch(navigateToStep(stepEntityId)); }}
    >
      {label}
    </span>
  );
};

NavigateToStep.propTypes = {
  stepEntityId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraHandler: PropTypes.func,
};

NavigateToStep.defaultProps = {
  extraHandler: () => {},
};

export default NavigateToStep;
