import React from 'react';
import {
  string, shape, number,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import Panel from '../../../Atoms/Layout/Panel/Panel';
import PillBadges from '../../../Atoms/Markup/PillBadges/PillBadges';
import Text from '../../../Atoms/Typography/Text/Text';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import Link from '../../../Atoms/Navigation/Links/Link';
import './InvestmentListItem.less';

const InvestmentListItem = ({ investment }) => {
  const { context } = useParams();

  const {
    uuid,
    fieldLmsAccountId: investmentLmsId,
    fieldClient: {
      name: clientName,
    },
    fieldAmount,
    fieldLoan,
    fieldManagedFund,
  } = investment;

  const loanId = (fieldLoan && fieldLoan?.fieldLmsAccountId) || (fieldManagedFund && fieldManagedFund?.fieldLmsPoolId);

  return (
    <Panel className="theme-investment-list-item">
      <Link to={`/app/${context}/investment/${uuid}`}>
        <PillBadges
          key={uuid}
          badges={[
            {
              title: 'Your ref',
              value: investmentLmsId,
            },
            {
              title: 'Loan',
              value: loanId,
            },
          ]}
        />
        <Text type="bold">
          { fieldLoan?.fieldPrimarySecurity?.fieldAddress?.shortAddress || fieldManagedFund?.label }
        </Text>
        <Text>
          {clientName}
          {' '}
          <Currency value={fieldAmount} />
        </Text>
      </Link>
    </Panel>
  );
};

InvestmentListItem.propTypes = {
  investment: shape({
    fieldLmsAccountId: string,
    fieldAmount: number,
    fieldClient: shape({
      name: string,
    }),
    fieldLoan: shape({
      fieldLmsAccountId: string,
      fieldPrimarySecurity: shape({
        fieldAddress: shape({
          shortAddress: string,
        }),
      }),
    }),
  }).isRequired,
};

export default InvestmentListItem;
