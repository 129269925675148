import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { string } from 'prop-types';
import './FileAccessLevel.less';

const FileAccessLevel = ({ fieldAccess }) => {
  const { context } = useParams();
  const isLgScreen = useMediaQuery({ query: '(max-width: 991px)' });

  if (context !== 'dealer') {
    return null;
  }

  return (
    <span className={`theme-file-access-level ${fieldAccess} ${isLgScreen ? 'mobile' : 'desktop'}`}>
      <FontAwesomeIcon icon={faKey} />
      {fieldAccess}
    </span>
  );
};
FileAccessLevel.propTypes = {
  fieldAccess: string,
};
FileAccessLevel.defaultProps = {
  fieldAccess: null,
};

export default FileAccessLevel;
