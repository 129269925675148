import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import {
  Col, Row, Form,
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../Atoms/Forms/Button/Button';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import DatePicker from '../../../Atoms/Forms/DatePicker/DatePicker';
import IdentityVerificationLogDownloadButton from './IdentityVerificationLogDownloadButton';

const IdentityVerificationLog = (props) => {
  const [search, setSearch] = useState();
  const { onChange, showRoles } = props;
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const onChangeLocal = (values) => {
    setSearch(values);
    onChange?.(values);
  };

  return (
    <Form
      onValuesChange={(value, values) => values?.startDate && values.endDate && onChangeLocal(values)}
      className="theme-filter"
    >
      <Row gutter={isMdScreen ? [24, 24] : 24}>
        <Col xs={12} md={showRoles ? 4 : 5}>
          <FormItem
            label=""
            name="startDate"
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Start date"
            />
          </FormItem>
        </Col>
        <Col xs={12} md={showRoles ? 4 : 5}>
          <FormItem
            label=""
            name="endDate"
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="End date"
            />
          </FormItem>
        </Col>
        <Col xs={12} md={2}>
          <Button type="secondary" htmlType="reset" onClick={() => onChangeLocal(undefined)}>
            Clear
          </Button>
        </Col>
        <Col xs={12} md={2}>
          <IdentityVerificationLogDownloadButton startDate={search?.startDate} endDate={search?.endDate} />
        </Col>
      </Row>
    </Form>
  );
};

IdentityVerificationLog.propTypes = {
  onChange: func.isRequired,
  showRoles: bool,
};

IdentityVerificationLog.defaultProps = {
  showRoles: true,
};

export default IdentityVerificationLog;
