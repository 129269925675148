import React from 'react';
import { Form } from 'antd';
import { func, string } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import HeaderTitle from '../../HeaderTitle/HeaderTitle';
import Policy from '../../../Organisms/Forms/Policies/Policy';
import StepInfo from '../StepInfo/StepInfo';
import {
  findStepEntityByStepKey,
  getSelectedRole,
} from '../../../Store/clientRegistrationSlice';
import ClientRegistrationActions from '../Actions/ClientRegistrationActions';
import './NzAgreeAndSubmit.less';

const NzAgreeAndSubmit = ({
  onFinishLastStep, onGoBack, stepKey,
}) => {
  const [form] = Form.useForm();
  const role = useSelector(getSelectedRole);

  const policyKey = `RegistrationPolicy${role?.charAt(0)?.toUpperCase() + role?.slice(1)}`;

  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuidv4() };

  return (
    <div className="theme-nz-agree-and-submit">
      <HeaderTitle title="Agree and Submit" />
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinishLastStep}
        name="nzAgreeAndSubmit"
        initialValues={stepData}
      >
        <StepInfo id={stepData.id} stepKey={stepKey} />
        <Policy policyKey={policyKey} />

        <ClientRegistrationActions form={form} onGoBack={onGoBack} />
      </Form>
    </div>

  );
};

NzAgreeAndSubmit.propTypes = {
  onFinishLastStep: func,
  onGoBack: func,
  stepKey: string,
};

NzAgreeAndSubmit.defaultProps = {
  onFinishLastStep: () => {},
  onGoBack: () => {},
  stepKey: 'nz-agree-and-submit',
};

export default NzAgreeAndSubmit;
