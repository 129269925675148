import React from 'react';
import { string } from 'prop-types';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';

const ParentId = ({ parentId }) => {
  if (!parentId) {
    return '';
  }

  return (
    <FormItem
      name="parentId"
      hidden
      initialValue={parentId}
    >
      <Input />
    </FormItem>
  );
};

ParentId.propTypes = {
  parentId: string,
};

ParentId.defaultProps = {
  parentId: '',
};

export default ParentId;
