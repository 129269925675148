import React from 'react';
import { getPermissionDenyMessage, haveEntityPermission, haveEntityPermissionMessage } from '../../Helpers/Helpers';

const PermissionDeniedMessage = ({ entity, name }) => {
  const havePermission = haveEntityPermission(entity, name);
  if (entity !== null && havePermission !== 1 && haveEntityPermissionMessage(entity, name)) {
    return (
      <div className="alert alert-info text-center error-message-container">
        { getPermissionDenyMessage(entity, name) }
      </div>
    );
  }
  return '';
};

export default PermissionDeniedMessage;
