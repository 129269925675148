import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { getLmsAccount } from '../../../Helpers/loanShortcuts';
import LmsAccount from '../../../Helpers/lmsAccountShortcuts';
import { formatCurrencyFull } from '../../../Hooks/useNumberFormatter';
import Text from '../../../Atoms/Typography/Text/Text';
import Button from '../../../Atoms/Forms/Button/Button';

const BackfillInvestment = (props) => {
  const {
    name, amount, loan,
  } = props;

  const lmsAccount = getLmsAccount(loan);
  const backfillReduction = LmsAccount.getBackfillReduction(lmsAccount);

  return (
  <Row justify="space-between" align="middle">
    <Col>
      <Text type="bold">
        {name}
        {' '}
      </Text>
      <Text>{formatCurrencyFull(amount)}</Text>
    </Col>
    <Col>
      <Button type="default" size="small" disabled>
        Backfill for:
        {' '}
        {formatCurrencyFull(backfillReduction)}
      </Button>
    </Col>
  </Row>
  );
};

BackfillInvestment.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default BackfillInvestment;
