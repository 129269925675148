import React, { useContext } from 'react';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Text from '../../../../Atoms/Typography/Text/Text';
import LangContext from '../../../../Context/Lang/LangContext';
import Input from '../../../../Atoms/Forms/Input/Input';

const InformationReleaseToLenders = () => {
  const { t } = useContext(LangContext);

  return (
    <>
      <div className="theme-legal-details">
        <FormItem
          name="loanInformationRelease"
          rules={[{ required: true, message: t('validation.required', { attribute: 'field' }) }]}
          hidden="1"
        >
          <Input
            type="hidden"
            default="Disclosure"
          />
        </FormItem>

        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev?.loanInformationRelease !== current?.loanInformationRelease && current.loanInformationRelease}
        >
          {({ getFieldValue }) => {
            const value = getFieldValue('loanInformationRelease');
            if (!value) return null;
            const texts = {
              Disclosure: 'Borrowers’ names are disclosed to registered investors. All supporting loan documents are released '
                                + 'to registered investors but borrowers’ bank account details, addresses and contact details are REMOVED. '
                                + 'Borrowers who select disclosure are more likely to attract investors.',
              Partial: 'Borrowers’ names are NOT disclosed to registered lenders. '
                                + 'All supporting loan documents are released to registered lenders but borrowers’'
                                + 'names, bank account details, addresses and contact details are REMOVED. '
                                + 'Borrowers who select partial disclosure are less likely to attract lenders and will incur an additional 0.5% on their interest rate.',
              Anonymous: 'Borrowers’ names are NOT disclosed and NO supporting loan documents are released to registered lenders. '
                                + 'Registered lenders instead receive a basic loan summary. '
                                + 'Borrowers who select anonymous are much less likely to attract lenders and will incur an additional 1.0% on their interest rate.',
            };

            return (<Text type="comment">{texts?.[value]}</Text>);
          }}
        </FormItem>
      </div>
    </>
  );
};

InformationReleaseToLenders.propTypes = {

};

export default InformationReleaseToLenders;
