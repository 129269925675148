/**
 * @file
 */

import React from 'react';
import { Button, Form } from 'antd';
import { withRouter } from 'react-router-dom';
import ScrollToTop from '../../Helpers/Helpers';
import { FormSection, FormSectionTitle } from '../../Components/Form/FormSections';
import LVR from './Components/LVR';
import Term from './Components/Term';
import LoanAmount from './Components/LoanAmount';
import Grade from './Components/Grade';
import InterestRate from './Components/InterestRate';

const NewLoanToInvestorsForm = (props) => {
  const {
    onFormSubmit,
    processing,
    getFieldDecorator,
    form,
  } = props;

  ScrollToTop();

  return (
    <Form form={getFieldDecorator}>
      <FormSectionTitle>Loan Detaila</FormSectionTitle>
      <FormSection>
        <div className="loan-amount-term">
          <LoanAmount {...{ getFieldDecorator }} />
          <InterestRate {...{ getFieldDecorator }} />
        </div>
        <LVR {...{ getFieldDecorator, form }} />
        <Term {...{ getFieldDecorator }} />
        <Grade {...{ getFieldDecorator }} />
        <div className="form-actions">
          <Button
            className="btn btn-primary"
            htmlType="submit"
            onClick={onFormSubmit}
            loading={processing}
          >
            Evaluate
          </Button>
        </div>
      </FormSection>
    </Form>
  );
};

export default withRouter(NewLoanToInvestorsForm);
