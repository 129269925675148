import React from 'react';
import { useParams } from 'react-router-dom';
import {
  string, node, shape, oneOf,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import NoResult from '../../Molecules/NoResult/NoResult';
import InvestmentPanel from '../../Components/Common/InvestmentPanel';
import LoanPanel from '../../Components/Common/LoanPanel';
import { ACCOUNTS_WITH_LOAN_AND_INVESTMENT } from '../../GraphQL/Queries/Account';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import Result from '../../Atoms/Feedback/Result/Result';
import SectionTitle from '../../Molecules/DataDisplay/Sections/SectionTitle';
import './ClientAccounts.less';
import { oldHasRole, userUuid } from '../../Hooks/useLocalStorage';
import InfiniteScroll from '../../Molecules/InfiniteScroll';

const ClientAccounts = ({
  uuid, title, children, accountTypes,
}) => {
  const { context } = useParams();
  const limit = 25;
  const user = !oldHasRole('dealer') ? userUuid() : null;

  const {
    loading, error, data, fetchMore,
  } = useQuery(ACCOUNTS_WITH_LOAN_AND_INVESTMENT, {
    variables: {
      client: uuid,
      user,
      offset: 0,
      limit,
      states: !['investor', 'dealer'].includes(context) ? ['pending', 'assessed', 'funding', 'live', 'live-subscribed', 'live-complete', 'closed_pending', 'backfill'] : [],
      types: accountTypes,
    },
  });

  if (loading) {
    return (
      <Skeleton loading={loading} />
    );
  }

  if (error) {
    return (<Result status="error" title="Sorry something went wrong!" subTitle={`${error.message}`} />);
  }

  const { accounts } = data || {};
  const { results } = accounts || [];

  if (!results?.filter(account => account !== null)?.length) {
    return null;
  }

  return (
    <div className="theme-client-accounts">
      <SectionTitle title={title} level={3} />
      {results?.filter(account => account !== null)?.map(account => (
        account?.type === 'investment' ? (
          <InvestmentPanel
            key={account?.uuid}
            context={context}
            investment={account}
            loan={account.fieldLoan}
          />
        ) : (
          <LoanPanel
            key={account?.uuid}
            context={context}
            loan={account}
            linkPrefix={`/app/${context}/application`}
          />
        )
      ))}
      { !results?.length && <NoResult title={`No ${accountTypes.length === 1 ? accountTypes[0] : 'accounts'} found.`} /> }
      <InfiniteScroll
        pageSize={limit}
        loading={loading}
        data={data}
        fetchMore={fetchMore}
      />
      {children}
    </div>
  );
};

ClientAccounts.propTypes = {
  uuid: string.isRequired,
  title: string.isRequired,
  children: node,
  accountTypes: shape([oneOf(['loan', 'investment'])]),
};

ClientAccounts.defaultProps = {
  children: null,
  accountTypes: ['loan', 'investment'],
};

export default ClientAccounts;
