import React, { useState, useContext } from 'react';
import { Form } from 'antd';
import { useMutation } from '@apollo/client';
import Title from '../../../Atoms/Typography/Title/Title';
import Button from '../../../Atoms/Forms/Button/Button';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import './ForgotPasswordForm.less';
import { USER_REQUEST_PASSWORD_RESET } from '../../../GraphQL/Mutations/User';
import Alert from '../../../Atoms/Feedback/Alert/Alert';
import { getGraphQlErrorMessage } from '../../../Helpers/Error';
import LangContext from '../../../Context/Lang/LangContext';

const ForgotPasswordForm = () => {
  const [form] = Form.useForm();
  const { t } = useContext(LangContext);
  const [userRequestPasswordReset, { loading }] = useMutation(USER_REQUEST_PASSWORD_RESET);
  const [message, setMessage] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [buttonText, setButtonText] = useState('E-mail new password');
  const [success, setSuccess] = useState(false);

  const onFinish = (input) => {
    setSuccess(false);
    setMessage(false);
    userRequestPasswordReset({ variables: { input: { ...input } } })
      .then((result) => {
        const { data: { userRequestPasswordReset: { message: theMessage } } } = result;
        setButtonText('Instructions emailed');
        setDisableButton(true);
        setSuccess(theMessage);
      })
      .catch(error => setMessage(getGraphQlErrorMessage(error)));
  };

  return (
    <div className="theme-forgot-password-form">
      <Title level={1}>Request new password</Title>
      <Form form={form} onFinish={onFinish}>
        { message && <Alert message={message} type="error" /> }
        { success && <Alert message={success} type="success" /> }
        <FormItem
          name="email"
          rules={[
            { type: 'email', message: 'Invalid email address' },
            { required: true, message: t('validation.required', { attribute: 'field' }) },
          ]}
        >
          <Input placeholder="Email address" />
        </FormItem>

        <Button block type="primary" htmlType="submit" loading={loading} disabled={disableButton}>
          {loading ? 'Requesting reset email' : buttonText}
        </Button>
      </Form>
    </div>
  );
};

export default ForgotPasswordForm;
