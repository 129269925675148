import React from 'react';
import {
  bool, func, node, string, oneOfType, number,
} from 'prop-types';

import { Modal as AntdModal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Modal.less';
import { useMediaQuery } from 'react-responsive';

const Modal = (props) => {
  const { children, cancelText, okText } = props;
  const isMdScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const hideCancelButton = !cancelText.length && { style: { display: 'none' } };
  const hideOkButton = !okText.length && { style: { display: 'none' } };

  return (
    <AntdModal
      className="theme-modal"
      centered
      closable
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      cancelText=""
      cancelButtonProps={hideCancelButton}
      okButtonProps={hideOkButton}
      width={isMdScreen ? '80vw' : '40vw'}
      {...props}
    >
      {children}
    </AntdModal>
  );
};

Modal.propTypes = {
  cancelText: node,
  children: node,
  mask: bool,
  okText: node,
  title: node,
  visible: bool,
  width: oneOfType([
    string,
    number,
  ]),
  onCancel: func,
  onOk: func,
};

Modal.defaultProps = {
  cancelText: 'Cancel',
  children: null,
  mask: true,
  okText: 'OK',
  title: undefined,
  visible: false,
  width: null,
  onCancel: () => {},
  onOk: () => {},
};

export default Modal;
