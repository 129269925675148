import React from 'react';
import { string, bool } from 'prop-types';
import { Col, Row } from 'antd';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Bpay from '../../../Molecules/Cards/Accounts/Bpay';
import ElectronicTransfer from '../../../Molecules/Cards/Accounts/ElectronicTransfer';
import './TransferFunds.less';

const TransferFunds = ({
  accountNumber, accountReference, billerCode, bpayReference, brand, bsb, fundsReceived, legalName,
}) => (
  <Card
    title={(<Title level={3}>Transfer funds</Title>)}
    className={!fundsReceived && 'theme-transfer-funds'}
  >
    <Row gutter={[8, 24]}>
      {fundsReceived && (
        <Col span={12}>
          <div className="funds-received">Thank you for your investment. Your funds have been received.</div>
        </Col>
      )}
      {!fundsReceived && (
        <>
          {bpayReference && (
          <Col span={6}>
            <Bpay billerCode={billerCode} bpayReference={bpayReference} />
          </Col>
          )}
          <Col
            span={billerCode && bpayReference && accountNumber && accountReference ? 6 : 12}
          >
            {accountNumber && accountReference && (
            <ElectronicTransfer
              accountNumber={accountNumber}
              accountReference={accountReference}
              brand={brand}
              bsb={bsb}
              legalNameTrust={legalName}
            />
            )}
          </Col>
        </>
      )}
    </Row>
  </Card>
);

TransferFunds.propTypes = {
  accountNumber: string,
  accountReference: string,
  billerCode: string,
  brand: string,
  bsb: string,
  bpayReference: string,
  fundsReceived: bool,
  legalName: string,
};

TransferFunds.defaultProps = {
  accountNumber: process.env.REACT_APP_BRAND_ACCOUNT_NUMBER,
  accountReference: '',
  billerCode: process.env.REACT_APP_BRAND_BPAY_BILLER_CODE,
  bpayReference: '',
  brand: process.env.REACT_APP_BRAND,
  bsb: process.env.REACT_APP_BRAND_ACCOUNT_BSB,
  fundsReceived: false,
  legalName: process.env.REACT_APP_BRAND_LEGAL_TRUST_NAME,
};

export default TransferFunds;
