import React, { useContext } from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Input from '../../../../Atoms/Forms/Input/Input';
import InputGroup from '../../../../Atoms/Forms/InputGroup/InputGroup';
import { getFieldName } from '../../../../Helpers/Forms';
import LangContext from '../../../../Context/Lang/LangContext';
import { useValidationRules } from '../../../../Hooks/useValidationRules';
import DraggerFormItem from '../../DraggerFormItem/DraggerFormItem';
import DatePickerFormItem from '../../DatePickerFormItem/DatePickerFormItem';

const AustralianCitizen = ({
  parents, shouldUpdateComponent, deleteExcept,
}) => {
  const { t } = useContext(LangContext);
  const { softRequired } = useValidationRules;

  return (
    <>
      <InputGroup className="theme-australian-citizen">
        <FormItem
          noStyle
          shouldUpdate={(prev, current) => prev.errors !== current.errors}
        >
          {({ getFieldValue }) => (
            <FormItem
              label={t('entity.person.australian_citizen.evidence_number')}
              name={getFieldName('evidenceNumber', parents)}
              rules={[item => softRequired(item, 'Certificate number', deleteExcept)]}
              validateStatus={useValidationRules.validateStatus(getFieldName('evidenceNumber', parents), getFieldValue('errors'))}
            >
              <Input />
            </FormItem>
          )}
        </FormItem>

        <DatePickerFormItem
          fieldName="certificateDate"
          label={t('entity.person.australian_citizen.certificate_date')}
          parents={parents}
          validationRules={[item => softRequired(item, 'Certificate date', deleteExcept)]}
        />
      </InputGroup>

      <DraggerFormItem
        comment="Please upload a copy of your citizenship certificate."
        fieldName={getFieldName('citizenshipDocument', parents)}
        label="Upload identification"
        rules={[item => softRequired(item, 'Certificate document', deleteExcept)]}
        shouldUpdateComponent={shouldUpdateComponent}
      />
    </>
  );
};

AustralianCitizen.propTypes = {
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
  shouldUpdateComponent: func.isRequired,
  deleteExcept: shape(arrayOf(string)),
};

AustralianCitizen.defaultProps = {
  parents: ['citizenship'],
  deleteExcept: ['personVerification.evidenceNumber', 'personVerification.certificateDate', 'personVerification.citizenshipDocument'],
};

export default AustralianCitizen;
