import React from 'react';
import Title from '../Atoms/Typography/Title/Title';

const ErrorPage = () => (
  <div className="zagga-error">
    <span className="zagga-error_number">
      <Title>404 :(</Title>
    </span>
    <p className="zagga-error_title">
      How did you get here?
    </p>
    <p className="zagga-error_subtitle">
      Sorry we can&apos;t seem to find the page you&apos;re looking for.
    </p>
    <p className="zagga-error_description">
      The page you are looking for may no longer exist, or there may be a typo in the URL entered.
    </p>
  </div>
);

export default ErrorPage;
