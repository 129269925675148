import React from 'react';
import { number, string } from 'prop-types';
import Meta from '../../Atoms/Feedback/Meta/Meta';
import { capitalize } from '../../Helpers/Helpers';
import Badge from '../../Atoms/DataDisplay/Badge/Badge';
import BadgeGroup from '../../Atoms/DataDisplay/BadgeGroup/BadgeGroup';
import Title from '../../Atoms/Typography/Title/Title';
import './Header.less';
import { formatCurrency } from '../../Hooks/useNumberFormatter';

const Header = (props) => {
  const {
    amount,
    headerImage,
    metaTitle,
    name,
    parentId,
    parentLabel,
    referenceId,
    state,
  } = props;

  return (
    <div className={`theme-loan-header loan-header__${state.toLowerCase().replace(/( |_)/g, '-')}`}>
      <Meta title={metaTitle} />
      <div className="theme-loan-header-loan-state">{capitalize(state.replace(/(-|_)/g, ' '))}</div>
      <div className="theme-loan-header-contents-wrapper">
        {
          !!headerImage && (
            <div
              className="theme-loan-header-banner-image"
              style={{
                backgroundImage: `url(${headerImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
          )
        }
        <div className="theme-loan-header-contents">
          <BadgeGroup>
            {referenceId && (
              <Badge>
                Your ref:
                {' '}
                {referenceId}
              </Badge>
            )}
            { parentId && (
              <Badge>
                {parentLabel}
                {' '}
                {parentId}
              </Badge>
            )}
          </BadgeGroup>
          <Title level={3}>{name}</Title>
          <Title level={5} className="theme-currency-wrapper">
            {formatCurrency(amount)}
          </Title>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  amount: number.isRequired,
  headerImage: string,
  metaTitle: string,
  name: string,
  parentLabel: string,
  parentId: string,
  referenceId: string.isRequired,
  state: string.isRequired,
};

Header.defaultProps = {
  headerImage: '',
  metaTitle: '',
  name: '',
  parentLabel: 'Loan',
  parentId: '',
};

export default Header;
