import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoanHeader from '../Account/Components/LoanHeader';
import { LoanDocuments } from '../../Components/Account/AccountDocuments';
import clientService from '../../Services/Clients.service';
import { loanIsFunding as _loanIsFunding, currentFundingPoolSelect } from '../../Helpers/loanShortcuts';
import LoanDetails from '../../Organisms/Cards/Accounts/LoanDetails';
import AccountClientDetails from '../../Organisms/Cards/Accounts/AccountClientDetails';
import SecurityDetails from '../../Organisms/Cards/Accounts/SecurityDetails';
import FundingProgress from '../../Molecules/DataDisplay/FundingProgress/FundingProgress';
import ScrollToTop, { haveEntityPermission } from '../../Helpers/Helpers';
import DeclineOpportunityButton from './Components/DeclineOpportunityButton';
import { LOAN } from '../../GraphQL/Queries/Loan';
import { userUuid } from '../../Hooks/useLocalStorage';
import Skeleton from '../../Atoms/Feedback/Skeleton/Skeleton';
import ButtonGroup from '../../Atoms/Forms/ButtonGroup/ButtonGroup';

const Opportunity = ({ match: { params: { context, loanUuid } } }) => {
  const [investors, setInvestors] = useState(null);
  const [loan, setLoan] = useState(null);
  const [currentTab, setCurrentTab] = useState('details');
  const [documents, setDocuments] = useState([]);
  // @todo Refactor uuid to use redux.
  const uuid = userUuid();

  const { data: loanData } = useQuery(LOAN, {
    variables: {
      uuid: loanUuid,
      user: uuid,
    },
  });

  useEffect(() => {
    setLoan(loanData?.loan ?? null);
    setDocuments(loanData?.documents?.results?.filter(result => result !== null) ?? null);
  }, [loanData]);


  ScrollToTop();

  if (investors === null) {
    clientService.investorClientCollection(uuid).then((response) => {
      const investorData = response?.data?.data?.investorClients?.results || [];
      setInvestors(investorData);
    });
  }

  const display = ({ target: { innerText } }) => {
    setCurrentTab(innerText.toLowerCase());
  };

  let loanIsFunding = false;
  let header = null;
  let loanDocuments = null;
  let loanDetails = null;
  let loanHaveInvite = false;
  const offer = null;

  if (loan === null) {
    return (
        <Skeleton loading="loading" />
    );
  }

  loanIsFunding = _loanIsFunding(loan);

  const {
    fieldAmount,
    fieldState,
    fieldClientName,
    fieldLmsAccountId,
    fieldClient,
    fieldLvr,
    fieldSecuritiesTotal,
    fieldPrimarySecurity,
    haveInvite,
    fieldLoanProduct: {
      label: entityLabel,
    },
  } = loan;
  loanHaveInvite = haveInvite === 'true';

  header = (
      <LoanHeader
        loanState={fieldState}
        name={entityLabel}
        loanId={fieldLmsAccountId}
        loanType={fieldClientName}
        loanAmount={fieldAmount}
        metaTitle={`Opportunity - ${fieldLmsAccountId}`}
        context={context}
      />
  );

  loanDocuments = (
      <LoanDocuments media={documents} />
  );

  const pool = currentFundingPoolSelect(loan);
  const canViewSecurity = haveEntityPermission(loan, 'View security');

  loanDetails = (
      <LoanDetails
        loan={loan}
        pool={pool}
        context={context}
      >
        <FundingProgress account={loan} />
      </LoanDetails>
  );

  const fundUrl = `/app/investor/application/${loanUuid}/fund`;

  return (
      <div id="loan-application" className="react-page">
        <div className="header">
          {header}
        </div>
        <ul className="tabs-container">
          <li className="tab">
            <button className={currentTab === 'details' ? 'active' : ''} type="button" onClick={display}>
              Details
            </button>
          </li>
          <li className="tab">
            <button className={currentTab === 'documents' ? 'active' : ''} type="button" onClick={display}>
              Documents
            </button>
          </li>
        </ul>
        {
            currentTab === 'details' && (
                <div className="sections">
                  { fieldClient && <AccountClientDetails title="Borrower details" client={fieldClient} /> }
                  {loanDetails}
                  { canViewSecurity && fieldPrimarySecurity && (
                      <SecurityDetails lvr={fieldLvr} totalValue={fieldSecuritiesTotal} data={fieldPrimarySecurity} />
                  )}
                  {offer}
                </div>
            )
        }
        {currentTab === 'documents' && loanDocuments}
        {loanIsFunding && (
            <Route
              exact
              path="/app/:context/opportunity/:loanUuid"
              component={() => (
                <ButtonGroup direction="bottom-right">
                  <Link to={`${fundUrl}`} className="ant-btn-primary theme-button">Fund this loan</Link>
                  {loanHaveInvite && (
                    <DeclineOpportunityButton loanUuid={loanUuid} />
                  )}
                </ButtonGroup>
              )}
            />
        )}
      </div>
  );
};

export default Opportunity;
