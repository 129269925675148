import apiGraphQlService from '../Helpers/ApiGraphQLService';
import { eventingParams } from '../Helpers/Helpers';
import axios from '../Vendor/Axios';
import { loanFragmentForPanel } from './Accounts.service';
import { dealer, userUuid } from '../Hooks/useLocalStorage';

const loanStateOptionsBorrower = [
  { label: 'Live', value: 'live,live-subscribed,live-complete' },
  { label: 'Closed', value: 'closed_pending,closed' },
];

const loanStateOptionsBroker = [
  { label: 'Live', value: 'live,live-subscribed,live-complete,backfill' },
  { label: 'Closed', value: 'closed_pending,closed' },
];

const loanStateOptionsInvestor = [
  { label: 'Available', value: 'funding' },
  { label: 'Live', value: 'live,live-subscribed,live-complete,backfill' },
  { label: 'Closed', value: 'closed_pending,closed' },
  { label: 'Unwanted', value: 'unwanted' },
];

const loanStateOptionsDealer = [
  { label: 'Pending', value: 'pending' },
  { label: 'Assessed', value: 'assessed' },
  { label: 'Funding', value: 'funding' },
  { label: 'Backfill', value: 'backfill' },
  { label: 'Live', value: 'live,live-subscribed,live-complete' },
  { label: 'Closed', value: 'closed_pending,closed' },
  { label: 'Unwanted', value: 'unwanted' },
];

const applicationStateOptionsBorrower = [
  { label: 'Pending', value: 'pending' },
  { label: 'Offer', value: 'assessed' },
  { label: 'Funding', value: 'funding,backfill' },
  { label: 'Unwanted', value: 'unwanted' },
];

const applicationStateOptionsBroker = [
  { label: 'Pending', value: 'pending' },
  { label: 'Offer', value: 'assessed' },
  { label: 'Funding', value: 'funding' },
  { label: 'Live', value: 'live,live-subscribed,live-complete,backfill' },
  { label: 'Closed', value: 'closed_pending,closed' },
  { label: 'Unwanted', value: 'unwanted' },
];

const applicationStateOptionsDealer = loanStateOptionsDealer;

const loanProductGroups = {
  pr: 'Personal residential',
  br: 'Business residential',
  bc: 'Business commercial',
  pl: 'Personal loan',
  ru: 'Rural',
};

export const loanAmounts = {
  amtLt200: { max: 199999 },
  amt200To500: { min: 200000, max: 500000 },
  amt500To1: { min: 500000, max: 1000000 },
  amtGt1: { min: 1000000 },
  amtLt1: { max: 999999 },
  amt1To3: { min: 1000000, max: 3000000 },
  amt3To5: { min: 3000000, max: 5000000 },
  amtGt5: { min: 5000000 },
};

export const loanOptions = () => (dealer() === 'znz' ? [
  { value: 'amtLt200', label: 'Less than $200,000' },
  { value: 'amt200To500', label: '$200,000 - $500,000' },
  { value: 'amt500To1', label: '$500,000 - $1 million' },
  { value: 'amtGt1', label: '$1 million plus' },
] : [
  { value: 'amtLt1', label: 'Less than $1 million' },
  { value: 'amt1To3', label: '$1 million - $3 million' },
  { value: 'amt3To5', label: '$3 million - $5 million' },
  { value: 'amtGt5', label: '$5 million plus' },
]);

export const loanTrancheLabel = (pool, loan) => {
  const { fieldNumberOfTranches } = loan;
  const { fieldDelta, fieldPoolType } = pool;
  if (fieldPoolType !== 'Tranche') {
    return '';
  }
  if (fieldNumberOfTranches !== undefined && fieldNumberOfTranches) {
    return `Tranche ${fieldDelta} of ${fieldNumberOfTranches}`;
  }
  if (fieldDelta !== undefined && fieldDelta) {
    return `Tranche ${fieldDelta}`;
  }
  return '';
};

function loansSearchQuery(filters, page, pageSize) {
  const variables = {
    offset: page * pageSize,
    limit: pageSize,
    sort: 'changed',
    sortDirection: 'DESC',
    ...filters,
  };

  const query = `
    query loansQuery(
      $amount: Range
      $client: String
      $showAllForClient: Boolean
      $showAllForLoanBook: Boolean
      $limit: Int = 25
      $offset: Int = 0
      $user: String
      $productGroups: [String]
      $search: String
      $states: [String]
    ) {
      loans(
        amount: $amount
        client: $client
        showAllForClient: $showAllForClient
        showAllForLoanBook: $showAllForLoanBook
        user: $user
        productGroups: $productGroups
        search: $search
        states: $states
        offset: $offset
        limit: $limit
      ) {
        total
        results {
          ${loanFragmentForPanel}
        }
      }
    }`;
  return apiGraphQlService.persistedQuery(query, variables);
}

const getDealerLoans = (filters, page = 0, pageSize = 25) => loansSearchQuery(filters, page, pageSize);

const getInvestorLoans = (filters, page = 0, pageSize = 25) => loansSearchQuery(filters, page, pageSize);

const getBrokerLoans = (filters, page = 0, pageSize = 25) => {
  // @todo Refactor uuid to use redux.
  const uuid = userUuid();
  const allFilters = {
    user: filters.user || uuid,
    ...filters,
  };
  return loansSearchQuery(allFilters, page, pageSize);
};

const getBorrowerLoans = (filters, pageNumber = 0, pageSize = 25) => {
  // @todo Refactor uuid to use redux.
  const uuid = userUuid();
  const allFilters = {
    user: filters.user || uuid,
    ...filters,
  };
  return loansSearchQuery(allFilters, pageNumber, pageSize);
};

const logLoanView = accountUuid => axios
  .post(`/eventing/fintech_loan_viewed${eventingParams()}`,
    {
      // eslint-disable-next-line
        account_uuid: accountUuid,
    });

const loanQuery = (uuid, context) => {
  const variables = { uuid };

  // @todo Refactor uuid to use redux.
  if (context === 'investor') {
    variables.user = userUuid();
  }
  const query = `
    query loanQuery($uuid: String $user: String) {
      loan(uuid: $uuid) {
        id
        dealer
        fundingAvailable
        lmsAccount
        lastCreditAssessmentDate
        loanConditions
        repaymentsToDate
        type
        uuid
        entityPermissions {
          label
          permitted
        }
        fieldAmount
        fieldCreditRating
        fieldCurrentRaiseAmount
        fieldHideForClient
        fieldHideForLoanBook
        fieldNoSecondaryMarket
        fieldDealerAssociations {
          fieldDealer
          fieldForInvestment
          fieldTotalCommitments
        }
        fieldFundingProviders
        fieldFundsRequiredDate
        fieldInvestmentRate
        fieldInvestorReturn
        fieldLineFee
        fieldLmsAccountId
        fieldLvr
        fieldMaturityDate
        fieldMaximumTerm
        fieldMinimumTerm
        fieldNumberOfTranches
        fieldPurpose
        fieldRate
        fieldRisk
        fieldState
        fieldSecuritiesTotal
        fieldTitleOverride
        fieldLoanProduct {
          label
          fieldRepaymentReturn
        }
        currentPool {
          fieldAmount
          fieldDelta
          fieldInvestmentRate
          fieldOpenDate
          fieldPoolTotal
          fieldRate
          fundingAvailable
          term
          totalFunded
        }
        currentFundingPool {
          fieldAmount
          fieldDelta
          fieldInvestmentRate
          fieldOpenDate
          fieldPoolTotal
          fieldRate
          fundingAvailable
          term
          totalFunded
        }
        fieldPools {
          uuid
          fieldAmount
          fieldDelta
          fieldInvestmentRate
          fieldOpenDate
          fieldRate
          fieldTermOverride
          term
         }
        fieldPrimarySecurity {
          fieldAddress {
            shortAddress
            value
          }
          fieldImages {
            propertyThumbnail
            original
          }
        }
        fieldClient {
          fieldLmsClientId
          name
          type
        }
        initialPool {
          fieldAmount
          fieldInvestmentRate
          fieldOpenDate
          fieldPoolTotal
          fieldRate
          term
        }
        loanTitle
        offerExpiryDate
        owner {
          uuid
          email
        }
      }
      investments(
        loanUuid: $uuid
        user: $user
        limit: -1
        sort: changed
        sortDirection: DESC
      ) {
        total
        results {
          uuid
          borrowerRate
          commencementDate
          investmentRate
          fieldAmount
          fieldCommencementDate
          fieldRate
          fieldLmsAccountId
          fieldState
          fieldClient {
            name
          }
          fieldPool {
            fieldDelta
          }
        }
      }
      documents(accountUuid: $uuid) {
        results {
          uuid
          label
          fieldMediaFiles {
            filename
            filemime
            url
            uuid
          }
        }
      }
    }
  `;
  return apiGraphQlService.persistedQuery(query, variables);
};

const loanInvestmentsQuery = (uuid, context) => {
  const variables = { uuid };

  // @todo Refactor uuid to use redux.
  if (context === 'investor') {
    variables.user = userUuid();
  }
  const query = `
    query loanInvestmentsQuery($uuid: String $user: String) {
      investments(
        loanUuid: $uuid
        user: $user
        limit: -1
        sort: changed
        sortDirection: DESC
      ) {
        total
        results {
          uuid
          borrowerRate
          commencementDate
          investmentRate
          fieldAmount
          fieldCommencementDate
          fieldRate
          fieldLmsAccountId
          fieldState
          fieldClient {
            name
          }
          fieldPool {
            fieldDelta
          }
        }
      }
    }
  `;
  return apiGraphQlService.persistedQuery(query, variables);
};

const loanService = {
  applicationStateOptionsBorrower,
  applicationStateOptionsBroker,
  applicationStateOptionsDealer,
  getBorrowerLoans,
  getBrokerLoans,
  getDealerLoans,
  getInvestorLoans,
  loanQuery,
  loanInvestmentsQuery,
  loanProductGroups,
  loanStateOptionsBorrower,
  loanStateOptionsBroker,
  loanStateOptionsDealer,
  loanStateOptionsInvestor,
  logLoanView,
};

export default loanService;
