import React, { useContext, useEffect } from 'react';
import {
  arrayOf, func, number, oneOfType, string,
} from 'prop-types';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import HeaderTitle from '../../../Molecules/HeaderTitle/HeaderTitle';
import EntitiesSelector from '../../../Molecules/EntitiesSelector/EntitiesSelector';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import RadioGroup from '../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../Atoms/Forms/Radio/Radio';
import LangContext from '../../../Context/Lang/LangContext';
import Policy from '../Policies/Policy';
import DraggerFormItem from '../../../Molecules/Forms/DraggerFormItem/DraggerFormItem';
import { getFieldName } from '../../../Helpers/Forms';
import {
  findStepEntityByStepKey,
  getCurrentStep,
  getPrimaryClientEntity, getSteps,
  setSelected,
} from '../../../Store/clientRegistrationSlice';
import StepInfo from '../../../Molecules/Forms/StepInfo/StepInfo';
import ClientRegistrationActions from '../../../Molecules/Forms/Actions/ClientRegistrationActions';
import useShouldUpdateFiles from '../../../Hooks/useShouldUpdateFiles';
import Paragraph from '../../../Atoms/Typography/Paragraph/Paragraph';

const VerificationOfInvestorStatus = ({
  onFinishLastStep, onGoBack, stepKey, parents,
}) => {
  const dispatch = useDispatch();
  const { t } = useContext(LangContext);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const entities = useSelector(getPrimaryClientEntity);
  const steps = useSelector(getSteps);
  const step = useSelector(getCurrentStep);
  if (steps[step]?.component === stepKey) {
    dispatch(setSelected(entities[0]?.id));
  }

  const stepData = useSelector(findStepEntityByStepKey(stepKey)) || { id: uuid() };

  useEffect(() => {
    setFieldsValue(stepData);
  }, [stepData]);

  return (
    <Row className="theme-verification-of-investor-status">
      <HeaderTitle
        title="Confirm investor status"
        commentType="grey-box"
        comment={(
          <Paragraph>
            Please upload the applicable documents to confirm that you meet the criteria of a
            {' '}
            <b>wholesale/sophisticated</b>
            {' '}
            investor as defined under the Corporations Act 2001 (Cth). If you do not have this available,
            you can still complete this registration and provide it at a later stage. You will not be able to
            invest until this condition is met.
          </Paragraph>
        )}
      />
      <Col className="gutter-row" span={12}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinishLastStep}
          name="verificationOfInvestorStatusForm"
          initialValues={stepData}
        >
          <StepInfo id={uuid()} stepKey={stepKey} parentId={entities[0]?.id} />
          <EntitiesSelector
            label="For the following"
            allowNew={false}
            entities={entities}
            defaultValue={entities[0]?.id}
          />
          <FormItem
            label="I am a"
            name={getFieldName('investorClassification', parents)}
            rules={[{ required: true, message: t('validation.required_option') }]}
            className="theme-form-row-style"
          >
            <RadioGroup className="has-3-columns box-style">
              <div className="theme-radio-wrapper"><Radio className="entity-radio" value="sophisticated">Wholesale/Sophisticated investor</Radio></div>
              <div className="theme-radio-wrapper"><Radio className="entity-radio" value="professional">Professional investor</Radio></div>
              <div className="theme-radio-wrapper"><Radio className="entity-radio" value="afsl">AFSL holder</Radio></div>
            </RadioGroup>
          </FormItem>
          <DraggerFormItem
            comment="Please upload a copy of your certificate issued by a qualified accountant."
            fieldName={getFieldName('investorVerificationDocuments', parents)}
            shouldUpdateComponent={
              (
                { errors: prevErrors, investorStatus: prevInvestorStatus },
                { errors: currentErrors, investorStatus: currentInvestorStatus },
              ) => useShouldUpdateFiles(prevInvestorStatus, currentInvestorStatus, 'investorVerificationDocuments')
                || (prevErrors !== currentErrors)
          }
          />
          <Policy policyKey="InvestorWholesaleStatusPolicy" parents={parents} />

          <FormItem hidden noStyle name="errors" />
          <ClientRegistrationActions form={form} onGoBack={onGoBack} />
        </Form>
      </Col>
    </Row>
  );
};

VerificationOfInvestorStatus.propTypes = {
  onFinishLastStep: func,
  onGoBack: func,
  stepKey: string,
  parents: oneOfType([
    arrayOf(string),
    arrayOf(number),
  ]),
};

VerificationOfInvestorStatus.defaultProps = {
  onFinishLastStep: () => {},
  onGoBack: () => {},
  parents: ['investorStatus'],
  stepKey: 'verification-of-investor-status-form',
};

export default VerificationOfInvestorStatus;
