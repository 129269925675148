import VisibilitySensor from 'react-visibility-sensor';
import { bool, func, number } from 'prop-types';
import React from 'react';
import Skeleton from '../Atoms/Feedback/Skeleton/Skeleton';

const InfiniteScrollLegacy = (
  {
    loading,
    page,
    pageSize,
    totalResults,
    onClick,
  },
) => {
  const onChange = (isVisible) => {
    if (isVisible) {
      onClick();
    }
  };

  if ((page + 1) * pageSize < totalResults) {
    return (
      <div className="load-more">
        <VisibilitySensor onChange={onChange}>
          <Skeleton loading={!loading} />
        </VisibilitySensor>
      </div>
    );
  }

  return null;
};

InfiniteScrollLegacy.propTypes = {
  loading: bool,
  page: number,
  pageSize: number,
  totalResults: number,
  onClick: func,
};

InfiniteScrollLegacy.defaultProps = {
  loading: false,
  page: 0,
  pageSize: 0,
  totalResults: 0,
  onClick: () => {
  },
};
export default InfiniteScrollLegacy;
