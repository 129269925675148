import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoResult from '../../Molecules/NoResult/NoResult';
import {
  loanListGetLoans,
  useDebouncedLoanFilter,
} from '../LoanApplications/Components/LoanFilter';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import LoanList from '../LoanApplications/Components/LoanList';
import LoanSearchFilter from '../LoanApplications/Components/LoanSearchFilter';
import { getAppInvestmentConditions } from '../../Store/appConfigurationSlice';
import useLoanProductGroups from '../../Hooks/useLoanProductGroups';

const SearchLoans = (props) => {
  const { match: { params: { context } } } = props;
  const [searchStateOptions, setSearchStateOptions] = useState([]);
  const dealerConfig = useSelector(getAppInvestmentConditions);

  const {
    pageNumber,
    pageSize,
    searchAmount,
    searchProductGroup,
    searchState,
    setContext,
    setSearchAmount,
    setSearchProductGroup,
    setSearchState,
    setPageNumber,
    search,
  } = useDebouncedLoanFilter(loanListGetLoans, true, (context !== 'investor'));
  const { data: searchProductOptions } = useLoanProductGroups(['funding', 'backfill']);

  useEffect(() => setContext(context), [context, setContext]);

  useEffect(() => {
    if (dealerConfig?.secondaryMarketEnabled) {
      setSearchStateOptions([
        { value: 'funding,:backfill', label: 'Available' },
        { value: ':secondary_market', label: 'Secondary market' },
      ]);
    }
  }, [setSearchStateOptions, dealerConfig]);

  const clearFilter = () => {
    setSearchProductGroup('');
    setSearchAmount('');
    setSearchState('funding,backfill');
  };

  const noResultBody = (
    <React.Fragment>
      Check the
      {' '}
      <Link to={`/app/${context}/loan-book`}>
        Loan book
      </Link>
      {' '}
      for a full list of recent loans.
    </React.Fragment>
  );
  const noResult = (
    <NoResult
      title="No loans found."
      body={noResultBody}
    />
  );

  return (
    <div className="theme-search-loans page-inner">
      <HeaderTitle
        title="Available opportunities"
        comment="Search and view all investments currently available for funding via our platform."
      />
      <LoanSearchFilter
        clearFilter={clearFilter}
        searchAmount={searchAmount}
        searchProductGroup={searchProductGroup}
        searchProductOptions={searchProductOptions}
        searchState={searchState}
        searchStateOptions={searchStateOptions}
        setSearchAmount={setSearchAmount}
        setSearchProductGroup={setSearchProductGroup}
        setSearchState={setSearchState}
      />
      <LoanList
        context={context}
        noResult={noResult}
        page={pageNumber}
        pageSize={pageSize}
        search={search}
        setPage={setPageNumber}
      />
    </div>
  );
};

export default SearchLoans;
