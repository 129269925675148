import React from 'react';
import {
  bool, shape, string, oneOf, oneOfType, func, arrayOf,
} from 'prop-types';
import { Table as AntdTable } from 'antd';
import './Table.less';

const Table = (args) => {
  const { className, type } = args;
  return <AntdTable {...args} className={`theme-table theme-table-${type} ${className || ''}`} />;
};

Table.propTypes = {
  bordered: bool,
  columns: arrayOf(shape({
    title: string,
    dataIndex: oneOfType([string, arrayOf(string)]),
  })),
  dataSource: arrayOf(shape({})),
  pagination: oneOfType([bool, shape({})]),
  type: oneOf(['default', 'inner']),
  loading: bool,
  defaultSortOrder: oneOf(['ascend', 'descend']),
  sorter: oneOfType([func, bool]),
};

Table.defaultProps = {
  bordered: false,
  columns: [],
  dataSource: [],
  pagination: true,
  type: 'default',
  loading: false,
  defaultSortOrder: undefined,
  sorter: undefined,
};

export default Table;
