import React from 'react';
import { Tag as AntdTag } from 'antd';
import PropTypes, {
  bool, node, func, string,
} from 'prop-types';
import './Tag.less';

const Tag = (args) => {
  const { className, type } = args;
  return <AntdTag {...args} className={className !== undefined ? `theme-tag theme-tag-${type} ${className}` : `theme-tag theme-tag-${type}`} />;
};

Tag.propTypes = {
  cloasable: bool,
  closeIcon: node,
  color: string,
  icon: node,
  visible: bool,
  onClose: func,
  type: PropTypes.oneOf(['complete', 'incomplete']),
};

Tag.defaultProps = {
  cloasable: false,
  closeIcon: undefined,
  color: undefined,
  icon: undefined,
  visible: true,
  onClose: undefined,
  type: 'incomplete',
};

export default Tag;
