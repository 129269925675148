import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import './Password.less';

const { Password: AntdPassword } = Input;

const Password = props => <AntdPassword {...props} className="theme-password" />;

Password.propTypes = {
  iconRender: PropTypes.func,
  visibilityToggle: PropTypes.bool,
  // From Input.jsx
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
  allowClear: PropTypes.bool,
  autoComplete: PropTypes.string,
  bordered: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  prefix: PropTypes.node,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  suffix: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
};

Password.defaultProps = {
  // eslint-disable-next-line react/display-name
  iconRender: visible => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />),
  visibilityToggle: true,
  // From Input.jsx
  addonAfter: undefined,
  addonBefore: undefined,
  allowClear: false,
  autoComplete: 'off',
  bordered: true,
  defaultValue: undefined,
  disabled: false,
  id: undefined,
  maxLength: undefined,
  prefix: undefined,
  size: 'middle',
  suffix: undefined,
  type: 'text',
  value: undefined,
  onChange: undefined,
  onPressEnter: undefined,
};


export default Password;
