/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { userUuid, oldHasRole } from '../Hooks/useLocalStorage';
import { isBrokerRole } from '../Hooks/useRoleLabelFormatter';

const registrationAdapter = createEntityAdapter({
  selectId: item => item.id,
});

const initialState = registrationAdapter.getInitialState({
  configuration: {},
  steps: [
    { title: 'Create account' },
    { title: 'Register' },
  ],
  step: 0,
  subStep: 0,
  showAccountOverviewDrawer: false,
  selected: 'new',
  stepHistory: [0],
  draftUuid: null,
  draftLoading: false,
  draftDeleting: false,
  createLoading: false,
  pageContext: null,
});

const findStepEntityByField = (field, value, state) => {
  if (state === undefined || value === undefined) {
    return undefined;
  }
  let stateSlice = state;
  if (state.clientRegistration) {
    stateSlice = state.clientRegistration;
  }
  const steps = registrationAdapter
    .getSelectors()
    .selectAll(stateSlice)
    .filter(entity => entity[field] === value);
  if (steps.length > 0) {
    return steps[0];
  }
  return undefined;
};

export const findStepEntityByStepKey = key => state => findStepEntityByField('stepKey', key, state);
export const findStepEntityByStepId = id => state => findStepEntityByField('id', id, state);
export const getSelectedRole = state => findStepEntityByStepKey('set-account-structure-form')(state)?.role;
export const getSelectedType = state => findStepEntityByStepKey('set-account-structure-form')(state)?.type;

export const getClientStructureStepData = (state) => {
  if (state === undefined) {
    return [];
  }
  let stateSlice = state;
  if (state.clientRegistration) {
    stateSlice = state.clientRegistration;
  }
  const total = registrationAdapter
    .getSelectors()
    .selectTotal(stateSlice);

  if (total > 0) {
    const clientStructureSteps = registrationAdapter
      .getSelectors()
      .selectAll(stateSlice)
      .filter(entity => entity.stepKey === 'set-account-structure-form');

    if (clientStructureSteps.length > 0) {
      return clientStructureSteps[0];
    }
  }
  return {};
};
export const getRegisterPersons = (state) => {
  if (state === undefined) {
    return [];
  }

  let stateSlice = state;

  if (state.clientRegistration) {
    stateSlice = state.clientRegistration;
  }
  return registrationAdapter
    .getSelectors()
    .selectAll(stateSlice)
    .filter(entity => entity.entityBundle === 'individual');
};


const getStepIndexByKey = (state, stepKey) => {
  let stateSlice = state;
  if (state.clientRegistration) {
    stateSlice = state.clientRegistration;
  }
  return stateSlice.steps.reduce(
    (carry, { component }, index) => (component === stepKey ? index : carry), undefined,
  );
};
const updateHistoryOnSetEntity = (state, stepEntity) => {
  const { stepKey, id } = stepEntity;
  const stepKeyIndex = getStepIndexByKey(state, stepKey);
  if (state.stepHistory[state.stepHistory.length - 1] === stepKeyIndex) {
    state.stepHistory.pop();
  }
  if (state.stepHistory[state.stepHistory.length - 1] !== id) {
    state.stepHistory.push(id);
  }
};
const updateHistoryOnSetStep = (state, stepIndex) => {
  if (!state.steps[stepIndex]) {
    return;
  }
  const { component: stepKey } = state.steps[stepIndex];
  const existingStepEntity = findStepEntityByStepKey(stepKey)(state);
  if (!existingStepEntity) {
    state.stepHistory.push(stepIndex);
  }
};
export const getEntities = state => state.clientRegistration.entities;
export const getAllStepEntities = (state) => {
  if (state === undefined) {
    return [];
  }
  return registrationAdapter.getSelectors().selectAll(state.clientRegistration);
};

const isCorporateTrust = (state) => {
  const { trusteeType } = getClientStructureStepData(state);
  return trusteeType === 'corporate';
};

export const clientRegistrationSlice = createSlice({
  name: 'clientRegistration',
  initialState,
  reducers: {
    setSelected: (state, action) => {
      const { payload } = action;
      state.selected = payload;
    },
    setSelectedByEntityId: (state, action) => {
      const { payload: parentId } = action;
      const persons = getRegisterPersons(state);
      state.selected = persons.reduce((carry, person, index) => {
        if (person.id === parentId) {
          return index;
        }
        return carry;
      }, undefined);
    },
    setConfiguration: (state, action) => {
      const { payload } = action;
      state.configuration = payload;
    },
    setStep: (state, action) => {
      const { payload } = action;
      updateHistoryOnSetStep(state, payload);
      state.selected = null;
      state.step = payload;
    },
    setSteps: (state, action) => {
      const { payload } = action;
      state.steps = payload;
    },
    navigateToStep: (state, action) => {
      const { payload: stepEntityId } = action;
      const stepEntity = registrationAdapter.getSelectors().selectAll(state).find(entity => entity.id === stepEntityId);
      const stepIndex = getStepIndexByKey(state, stepEntity.stepKey);
      state.step = stepIndex;
      state.selected = !stepEntity?.entityBundle ? stepEntity.parentId : stepEntity.id;
      if (stepEntity.stepKey === 'register-and-verify-entities-form') {
        const { subSteps } = state.steps[stepIndex];
        let subStepIndex = subSteps.reduce((carry, subStep, index) => {
          if (subStep.component === stepEntity.subStepKey) {
            return index;
          }
          return carry;
        }, 0);
        if (isCorporateTrust(state)) {
          subStepIndex += 1;
        }
        state.subStep = subStepIndex;
      }
    },
    setReduxSubStep: (state, action) => {
      const { payload } = action;
      state.subStep = payload;
    },
    setShowAccountOverviewDrawer: (state, action) => {
      state.showAccountOverviewDrawer = action.payload;
    },
    setEntity: (state, action) => {
      const values = action.payload;
      // It is no values in EntityConfirmation step.
      if (values === undefined) {
        return;
      }
      const { stepKey } = values;

      if (stepKey === 'set-account-structure-form') {
        const { role: roleValue, type: typeValue } = values;
        const { role: roleData, type: typeData } = getClientStructureStepData(state);
        const prev = `Role:${roleData}|Type:${typeData}`;
        const next = `Role:${roleValue}|Type:${typeValue}`;

        if (prev !== 'Role:undefined|Type:undefined' && prev !== next) {
          registrationAdapter.removeAll(state);
          state.selected = 'new';
          state.stepHistory = [0];
        }
      }
      updateHistoryOnSetEntity(state, values);
      registrationAdapter.setOne(state, action);

      // When registering an individual we want to push the person one on to the stack.
      if (values?.type === 'individual') {
        if (getRegisterPersons(state)?.length === 0) {
          const parentId = values.id;
          const personOne = {
            ...values,
            id: uuid(),
            role: undefined,
            type: undefined,
            name: undefined,
            entityType: 'client',
            entityBundle: 'individual',
            parentId,
            stepKey: 'register-and-verify-entities-form',
            subStepKey: 'person-form',
            errors: [],
          };
          registrationAdapter.setOne(state, personOne);
        }
        state.stepHistory.push(1);
      }

      // Non-dealers - push a dummy attach-to step to the stack.
      // If a dealer is asked to complete, attach-to form will be pre-populated.
      // Brokers registering themselves - attach-to not displayed, existing = true (set to current user).
      // Brokers registering a different role, attach-to is displayed, existing = false.
      // @todo Important test isDealer.
      if (!oldHasRole('dealer') && !findStepEntityByField('stepKey', 'attach-to-user', state)) {
        const pagContext = state.pageContext;
        const attachTo = {
          id: uuid(),
          parentId: values.id,
          existingUser: !isBrokerRole(pagContext) || isBrokerRole(getSelectedRole(state)),
          // @todo Implement test after redux new uuid.
          forUser: userUuid(),
          stepKey: 'attach-to-user',
          errors: [],
        };

        registrationAdapter.setOne(state, attachTo);
      }
    },
    removeEntity: (state, action) => {
      const { payload: id } = action;
      const entities = registrationAdapter.getSelectors().selectAll(state);

      const ids = entities.reduce((carry, item) => {
        if (item.id === id) {
          carry.push(item.id);
        }
        if (item.parentId === id) {
          carry.push(item.id);
        }
        return carry;
      }, []);

      registrationAdapter.removeMany(state, ids);
    },
    historyPop: (state) => {
      const currentStep = state.stepHistory.pop();
      const previousStep = state.stepHistory[state.stepHistory.length - 1];
      const previousStepEntity = findStepEntityByStepId(previousStep)(state);
      if (previousStepEntity) {
        const {
          stepKey, id, parentId, entityType,
        } = previousStepEntity;
        if (entityType) {
          state.selected = id;
        } else {
          state.selected = parentId;
        }
        state.step = getStepIndexByKey(state, stepKey);
      } else {
        state.step = currentStep;
        state.selected = 'new';
      }
    },
    historyPushStepIndex: (state, action) => {
      const stepIndex = action.payload;
      state.stepHistory.push(stepIndex);
    },
    initFromDraft: (state, action) => {
      const draftData = action.payload;
      registrationAdapter.setAll(state, draftData);
    },
    setDraftUuid: (state, action) => {
      state.draftUuid = action.payload;
    },
    setDraftLoading: (state, action) => {
      state.draftLoading = action.payload;
    },
    setDraftDeleting: (state, action) => {
      state.draftDeleting = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
    setPageContext: (state, action) => {
      state.pageContext = action.payload;
    },
    reset: (state) => {
      registrationAdapter.removeAll(state);
      state.configuration = initialState.configuration;
      state.steps = initialState.steps;
      state.step = initialState.step;
      state.showAccountOverviewDrawer = initialState.showAccountOverviewDrawer;
      state.selected = initialState.selected;
      state.subStep = initialState.subStep;
      state.stepHistory = initialState.stepHistory;
      state.selected = initialState.selected;
      state.draftUuid = initialState.draftUuid;
      state.draftLoading = initialState.draftLoading;
      state.draftDeleting = initialState.draftDeleting;
      state.createLoading = initialState.createLoading;
      state.pageContext = initialState.pageContext;
    },
  },
});

export const {
  setSelected,
  setSelectedByEntityId,
  setConfiguration,
  setStep,
  setSteps,
  setEntity,
  setShowAccountOverviewDrawer,
  historyPop,
  historyPushStepIndex,
  removeEntity,
  initFromDraft,
  setDraftUuid,
  setDraftLoading,
  setDraftDeleting,
  setCreateLoading,
  setPageContext,
  navigateToStep,
  setReduxSubStep,
  reset,
} = clientRegistrationSlice.actions;

export const getRegisterEntities = (state) => {
  if (state === undefined) {
    return [];
  }
  return registrationAdapter
    .getSelectors()
    .selectAll(state.clientRegistration)
    .filter(entity => entity.entityType !== undefined && entity.entityBundle !== 'omit');
};

export const getPrimaryClientEntity = (state) => {
  if (state === undefined) {
    return [];
  }
  const allRegisterEntities = getRegisterEntities(state);
  if (allRegisterEntities.length > 0) {
    return [allRegisterEntities[0]];
  }
  return [];
};

const getStepIdByField = (field, value, state) => {
  if (state === undefined) {
    return undefined;
  }
  const steps = registrationAdapter
    .getSelectors()
    .selectAll(state.clientRegistration)
    .filter(entity => entity[field] === value);
  if (steps.length > 0) {
    return steps[0].id;
  }
  return undefined;
};
export const getStepIdByStepKey = key => state => getStepIdByField('stepKey', key, state);
const getStepIdBySubStepKey = (key, state) => getStepIdByField('subStepKey', key, state);
export const getTrustEntity = state => getStepIdByField('entityBundle', 'trust', state);

export const getAllDeclareTaxStepEntities = (state) => {
  if (state === undefined) {
    return [];
  }
  const steps = registrationAdapter
    .getSelectors()
    .selectAll(state.clientRegistration)
    .filter(entity => entity.stepKey === 'declare-tax-status-form');
  if (steps.length > 0) {
    return steps;
  }
  return [];
};

export const getSubStepEntity = (parentId, subStepKey) => (state) => {
  if (state === undefined || parentId === undefined) {
    return undefined;
  }
  const steps = registrationAdapter
    .getSelectors()
    .selectAll(state.clientRegistration)
    .filter(entity => entity.parentId === parentId && entity.subStepKey === subStepKey);
  if (steps.length > 0) {
    return steps[0];
  }
  return undefined;
};

export const getInitialFormValuesForSubStep = (parentId, subStepKey) => (state) => {
  const existingSubStepEntity = getSubStepEntity(parentId, subStepKey)(state);
  if (existingSubStepEntity !== undefined) {
    return existingSubStepEntity;
  }
  const parentStepEntity = findStepEntityByStepId(parentId)(state);
  return {
    ...parentStepEntity,
    id: undefined,
    stepKey: undefined,
    subStepKey: undefined,
    errors: undefined,
    parentId: parentStepEntity?.id,
  };
};

export const getParentId = key => (state) => {
  if (state === undefined) {
    return undefined;
  }
  const { entityType, trusteeType, id } = getClientStructureStepData(state.clientRegistration);
  switch (key) {
    case 'person-form':
      if (entityType !== undefined) {
        if (trusteeType && trusteeType === 'corporate') {
          return getStepIdBySubStepKey('company-verification-form', state);
        }
        return getStepIdByStepKey('set-account-structure-form')(state);
      }
      return undefined;
    case 'company-verification-form':
      return id;
    case 'identity-verification-form':
      if (!!state.clientRegistration.selected && state.clientRegistration.selected !== 'new') {
        return state.clientRegistration.selected;
      }
      return getStepIdBySubStepKey('person-form', state);
    default:
      return undefined;
  }
};

export const getSelected = state => state.clientRegistration.selected;
export const getCurrentStep = state => state.clientRegistration.step;
export const getSubStep = state => state.clientRegistration.subStep;

export const getSteps = state => state.clientRegistration.steps;

const stepHaveErrors = (stepKey, state) => {
  const entities = registrationAdapter.getSelectors().selectAll(state.clientRegistration);
  const stepEntities = entities.filter(entity => entity.stepKey === stepKey);
  return stepEntities.reduce((carry, entity) => {
    if (carry) {
      return carry;
    }
    const { errors } = entity;
    return errors?.length;
  }, false);
};
export const registrationHaveAnyErrors = (state) => {
  if (state === undefined) {
    return undefined;
  }
  const entities = registrationAdapter.getSelectors().selectAll(state.clientRegistration);
  return entities.reduce((carry, entity) => {
    if (carry) {
      return carry;
    }
    const { errors } = entity;
    return errors?.length > 0;
  }, false);
};

const getRegisterStepStatus = (step, state) => {
  const entities = registrationAdapter.getSelectors().selectAll(state.clientRegistration);
  const subStepKeys = step.subSteps.map(subStep => subStep.component);
  const subStepsHaveErrors = entities.reduce((carry, entity) => {
    if (carry || subStepKeys.indexOf(entity.subStepKey) === -1) {
      return carry;
    }
    const { errors } = entity;
    return !!errors?.length;
  }, false);
  const personStepEntitiesCount = entities.reduce((carry, entity) => {
    if (entity.subStepKey === 'person-form') {
      return carry + 1;
    }
    return carry;
  }, 0);

  if (personStepEntitiesCount === 0) {
    return 'wait';
  }
  const personVerificationStepEntitiesCount = entities.reduce((carry, entity) => {
    if (entity.subStepKey === 'identity-verification-form') {
      return carry + 1;
    }
    return carry;
  }, 0);
  const notAllSubStepsComplete = personStepEntitiesCount !== personVerificationStepEntitiesCount;

  return (subStepsHaveErrors || notAllSubStepsComplete) ? 'error' : 'finish';
};
const getDeclareTaxStepStatus = (state) => {
  const entities = registrationAdapter.getSelectors().selectAll(state.clientRegistration);
  const entitiesShouldHaveDeclareTax = getRegisterEntities(state);
  const declareTaxStepEntitiesCount = entities.reduce((carry, entity) => {
    if (entity.stepKey === 'declare-tax-status-form') {
      return carry + 1;
    }
    return carry;
  }, 0);
  if (declareTaxStepEntitiesCount === 0) {
    return 'wait';
  }
  if (
    entitiesShouldHaveDeclareTax.length !== declareTaxStepEntitiesCount
    || stepHaveErrors('declare-tax-status-form', state)
  ) {
    return 'error';
  }
  return 'finish';
};
export const getStepsStatus = (state) => {
  if (state === undefined) {
    return undefined;
  }

  // ['wait', 'process', 'finish', 'error']
  return getSteps(state).map((step, index) => {
    if (index === state.clientRegistration.step) {
      return 'process';
    }
    if (step.component === 'register-and-verify-entities-form') {
      return getRegisterStepStatus(step, state);
    }
    if (step.component === 'declare-tax-status-form') {
      return getDeclareTaxStepStatus(state);
    }
    if (step.component === 'review' && (state.clientRegistration.step > index)) {
      return 'finish';
    }

    const entities = registrationAdapter.getSelectors().selectAll(state.clientRegistration);
    const stepEntities = entities.filter(entity => entity.stepKey === step.component);
    return stepEntities.reduce((carry, entity) => {
      if (carry === 'error') {
        return carry;
      }
      const { errors } = entity;
      return errors?.length ? 'error' : 'finish';
    }, 'wait');
  });
};

export const allStepsFinished = (state) => {
  if (state === undefined) {
    return undefined;
  }
  const stepsStatus = getStepsStatus(state);
  const notFinishedStep = stepsStatus.find(step => (step !== 'finish' && step !== 'process'));
  return notFinishedStep === undefined;
};

export const showAccountOverviewDrawer = state => state.clientRegistration.showAccountOverviewDrawer;

export const getConfiguration = (state, key = null) => (!key ? state?.clientRegistration?.configuration : state?.clientRegistration?.configuration[key]);

export const getCountry = state => getConfiguration(state, 'country');
export const getClientStructureConfig = state => getConfiguration(state, 'configurations');
export const getRoles = (state) => {
  const roles = getClientStructureConfig(state)?.map(({ role }) => role);
  return roles?.filter(({ value }, index, self) => self.findIndex(({ value: v }) => v === value) === index);
};

export const trustCompanyCreated = state => getAllStepEntities(state).reduce((carry, stepEntity) => (carry || stepEntity.entityBundle === 'company'), false);
export const getDraftUuid = state => state.clientRegistration.draftUuid;
export const getDraftLoading = state => state.clientRegistration.draftLoading;
export const getDraftDeleting = state => state.clientRegistration.draftDeleting;
export const getCreateLoading = state => state.clientRegistration.createLoading;

export default clientRegistrationSlice.reducer;
