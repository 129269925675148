import { gql } from '@apollo/client';

export const GET_DRAFT = gql`
  query draft($uuid: String!) {
    draft(uuid: $uuid) {
      uuid
      name
      data 
    }
  }
`;

export const CLIENT_DRAFTS_BY_USER = gql`
  query drafts($owner: String, $offset: Int, $limit: Int, $sort: DraftSortOrder, $sortDirection: SortDirection) {
    drafts(
      types: [client]
      owner: $owner
      offset: $offset
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        name
    }
  }
}
`;

export const CLIENT_DRAFTS_BY_DEALER = gql`
  query drafts($offset: Int, $limit: Int, $sort: DraftSortOrder, $sortDirection: SortDirection) {
    drafts(
      types: [client]
      offset: $offset
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        name
    }
  }
}
`;

export const APPLICATION_DRAFTS = gql`
  query drafts($owner: String, $offset: Int, $limit: Int, $sort: DraftSortOrder, $sortDirection: SortDirection) {
    drafts(
      types: [application]
      owner: $owner
      offset: $offset
      limit: $limit
      sort: $sort
      sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        name
        data 
    }
  }
}
`;

const Draft = {
  GET_DRAFT,
  CLIENT_DRAFTS_BY_DEALER,
  CLIENT_DRAFTS_BY_USER,
  APPLICATION_DRAFTS,
};

export default Draft;
