import { gql } from '@apollo/client';

export const CREATE_LOAN = gql`
  mutation loanCreate($input: NewLoanInput!) {
    loanCreate(input: $input) {
      success
      message
      application {
        uuid
      }
    }
  }  
`;

// eslint-disable-next-line import/prefer-default-export
export const DELETE_LOAN = gql`
  mutation loanDelete($uuid: String!) {
    loanDelete(uuid: $uuid) {
      ... on SimpleResponse {
        success
        message
      }
    }
  }
`;

export const OFFER_SEND = gql`
    mutation BrokerSendOffer(
        $uuid: String!
        $platformPaymentType: PlatformPaymentType!
    ) {
        loanBrokerOfferSend(
            uuid: $uuid
            platformPaymentType: $platformPaymentType
        ) {
            success
            message
        }
    }
`;

export const OFFER_DECLINE = gql`
    mutation DeclineOffer($uuid: String! $role: ClientRole!) {
        loanClientOfferDecline(uuid: $uuid role: $role) {
            success
            message
        }
    }
`;

export const OFFER_ACCEPT = gql`
    mutation AcceptOffer(
        $uuid: String!
        $platformPaymentType: PlatformPaymentType!
    ) {
        loanBorrowerOfferAccept(
            uuid: $uuid
            platformPaymentType: $platformPaymentType
        ) {
            success
            message
            agreementToken
        }
    }
`;

export const OFFER_ACCEPT_GUEST = gql`
  mutation AcceptOffer($token: String!) {
    loanOfferAccept(token: $token) {
      success
      message
    }
  }
`;

export const OFFER_DECLINE_GUEST = gql`
  mutation DeclineOffer($token: String!) {
    loanOfferDecline(token: $token) {
      success
      message
    }
  }
`;

export const VERIFY_NEW_BORROWER = gql`
  mutation VerifyNewBorrower($token: String!) {
    verifyNewBorrower(token: $token) {
      success
      message
      loginToken
      agreementToken
    }
  }
`;

export const UPDATE_LOAN_INVESTMENT_PROFILE = gql`
  mutation UpdateLoanInvestmentProfile($loanUuid: String! $input: InvestmentProfileInput!) {
    investmentProfileUpdate(loanUuid: $loanUuid, input: $input) {
      success
      message
    }
  }
`;

export const CREATE_INVESTMENT = gql`
  mutation CreateInvestment(
    $investorUuid: String!
    $loanUuid: String!
    $amount: Float!
    $investmentOption: InvestmentOptionType!
  ) {
    investmentCreate(investorUuid: $investorUuid, loanUuid: $loanUuid, amount: $amount, investmentOption: $investmentOption) {
      success
      message
      investment {
        uuid
      }
    }
  }
`;

export default {
  CREATE_LOAN,
  DELETE_LOAN,
  OFFER_ACCEPT,
  OFFER_ACCEPT_GUEST,
  OFFER_DECLINE,
  OFFER_DECLINE_GUEST,
  OFFER_SEND,
  UPDATE_LOAN_INVESTMENT_PROFILE,
  VERIFY_NEW_BORROWER,
  CREATE_INVESTMENT,
};
