/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  ApolloClient, ApolloLink, ApolloProvider as ApolloClientProvider, createHttpLink, from, InMemoryCache, defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import { node } from 'prop-types';
import { useSelector } from 'react-redux';
import { accessToken } from '../../Store/userSlice';
import { getSchemaDateFields } from '../../Store/schemaSlice';
import { convertMomentsInInputData, convertOutputDatesToMoment } from '../../Hooks/useCommonConvertedEntityData';

const ApolloProvider = ({ children }) => {
  const token = useSelector(accessToken);

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_FINTECH_URL}/graphql${process.env.REACT_APP_BRAND_ENABLE_DEBUG === '1' ? '?XDEBUG_SESSION_START=PHPSTORM' : ''}`,
    credentials: 'include',
  });

  const convertInputMomentVariables = new ApolloLink((operation, forward) => {
    const newOperation = operation;
    const mainOperation = newOperation.query.definitions.find(definition => 'operation' in definition);
    if (mainOperation && newOperation.variables && 'operation' in mainOperation) {
      if (mainOperation.operation === 'mutation') {
        newOperation.variables = convertMomentsInInputData(operation.variables);
      }
    }

    return forward(newOperation).map(data => data);
  });

  const convertOutputMomentVariables = new ApolloLink(
    (operation, forward) => forward(operation).map((response) => {
      const { data, ...theRest } = response;
      return { data: convertOutputDatesToMoment(data, getSchemaDateFields()), ...theRest };
    }),
  );

  const authLink = setContext((_, { headers: prevHeaders }) => {
    const context = {
      headers: {
        ...prevHeaders,
        'x-dealer': process.env.REACT_APP_X_DEALER,
      },
    };

    if (token) context.headers.authorization = `Bearer ${token}`;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      context.headers['x-client-id'] = process.env.REACT_APP_X_CLIENT_KEY;
      context.headers['x-client-secret'] = process.env.REACT_APP_X_CLIENT_SECRET;
    }

    return context;
  });

  const errorLink = onError((errorResponse) => {
    const { graphQLErrors, networkError, operation } = errorResponse;

    if ((
      networkError?.statusCode === 503
        || networkError?.message.includes('Failed to fetch')
    ) && !window.location.pathname.includes('maintenance-mode')
    ) {
      const path = window.location.pathname && !window.location.pathname.includes('maintenance-mode') ? window.location.pathname : '/app';
      window.location.href = `/maintenance-mode?destination=${path}`;
    }

    if (networkError?.statusCode === 401 && operation?.operationName !== 'auth') {
      const path = window.location.pathname && !window.location.pathname.includes('app/login') ? window.location.pathname : '/app';
      window.location.href = `/app/logout?destination=${path}`;
    }

    if (graphQLErrors && operation?.operationName !== 'auth') {
      graphQLErrors.forEach(() => {
        notification.error({ message: 'Sorry, your request could not be executed.', duration: 10 });
      });
    }
  });

  // @todo Fallback URL.
  // A note on possibleTypes: for any GQL that contains "... on SomeInterface", Apollo
  // needs to know that it can map the actual returned type back to the expected interface.
  const client = new ApolloClient({
    cache: new InMemoryCache({
      dataIdFromObject(responseObject) {
        if (responseObject?.__typename && responseObject?.uuid) {
          return `${responseObject.__typename}:${responseObject?.uuid}`;
        }
        return defaultDataIdFromObject(responseObject);
      },
      typePolicies: {
        ConfigurationCollection: {
          keyFields: ['results'],
        },
      },
      possibleTypes: {
        ActingClientInterface: [
          'ActingCompanyBorrower',
          'ActingCompanyBroker',
          'ActingCompanyInvestor',
          'ActingIndividualBorrower',
          'ActingIndividualBroker',
          'ActingIndividualInvestor',
          'ActingIndividualsBorrower',
          'ActingIndividualsInvestor',
          'ActingPartnershipBorrower',
          'ActingPartnershipInvestor',
          'ActingTrustBorrower',
          'ActingTrustInvestor',
        ],
        ClientInterface: [
          'ClientCompany',
          'ClientIndividual',
          'ClientIndividuals',
          'ClientPartnership',
          'ClientTrust',
        ],
        DraftInterface: [
          'AccountDraft',
          'ClientDraft',
        ],
        IdentificationInterface: [
          'IdentificationAuCitizenshipCertificate',
          'IdentificationAuDriverLicence',
          'IdentificationAuPassport',
          'IdentificationBirthCertificate',
          'IdentificationOverseasPassport',
          'IdentificationNzCitizenshipCertificate',
          'IdentificationNzDriverLicence',
          'IdentificationNzPassport',
        ],
      },
    }),
    link: from([convertInputMomentVariables, convertOutputMomentVariables, errorLink, authLink.concat(httpLink)]),
    credentials: 'omit',
  });

  return (
    <ApolloClientProvider client={client}>
      {children}
    </ApolloClientProvider>
  );
};


ApolloProvider.propTypes = {
  children: node.isRequired,
};
export default ApolloProvider;
