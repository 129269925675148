import { useState } from 'react';
import { useAsync } from 'react-async-hook';
import useConstant from 'use-constant';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const useDebounceSearch = (searchFunction) => {
  const [inputText, setInputText] = useState('');

  const debouncedSearch = useConstant(() => AwesomeDebouncePromise(searchFunction, 200 + Math.random() * 800));

  const search = useAsync(
    async () => {
      if (inputText.length === 0) {
        return [];
      }
      return debouncedSearch(inputText);
    },
    [inputText],
  );

  return {
    inputText,
    setInputText,
    search,
  };
};

export default useDebounceSearch;
