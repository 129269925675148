import React from 'react';
import {
  string, bool, number, oneOf,
} from 'prop-types';
import { Steps as AntdSteps } from 'antd';
import './Steps.less';

const Steps = args => <AntdSteps {...args} className="theme-steps" />;

Steps.propTypes = {
  className: string,
  current: number,
  direction: oneOf(['horizontal', 'vertical']),
  initial: number,
  labelPlacement: oneOf(['horizontal', 'vertical']),
  percent: number,
  responsive: bool,
  size: oneOf(['default', 'small']),
  type: oneOf(['default', 'navigation']),
};

Steps.defaultProps = {
  className: undefined,
  current: 0,
  direction: 'horizontal',
  initial: 0,
  labelPlacement: 'horizontal',
  percent: undefined,
  responsive: true,
  size: 'default',
  type: 'default',
};

export default Steps;
