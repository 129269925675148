import React, { useEffect, useState } from 'react';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';
import Select from '../../../Atoms/Forms/Select/Select';
import documentsService from '../../../Services/Documents.service';

export const documentTypesSelectList = (setList) => {
  documentsService.getCategories()
    .then((query) => {
      const docList = query?.data?.data?.documentCategories?.results;
      if (docList !== null) {
        setList(docList);
        return;
      }
      setList([]);
    });
};

const DocumentTypeSelect = (props) => {
  const {
    list,
    value,
    setValue,
    setIsMultiDoc,
  } = props;

  const [hiddenValues, setHiddenValues] = useState([]);
  /* eslint-disable-next-line  no-unused-vars */
  const [displayedValues, setDisplayedValues] = useState([]);

  useEffect(() => {
    if (value === null || list === null) {
      return;
    }
    const hiddenVals = value.filter(val => list.filter(row => (row.value === val)).length === 0);
    const displayVals = value.filter(val => list.filter(row => (row.value === val)).length > 0);
    setHiddenValues(hiddenVals);
    setDisplayedValues(displayVals);
    setIsMultiDoc(displayVals.filter(val => list.filter(row => (!!row.fieldSupportsMultidoc && row.value === val)).length > 0).length > 0);
  }, [list, value]);

  const setAllValues = (e) => {
    if (Array.isArray(e)) {
      let newVal = e;
      if (Array.isArray(hiddenValues) && hiddenValues.length > 0) {
        newVal = e.concat(hiddenValues);
      }
      setValue(newVal);
      setIsMultiDoc(newVal.filter(val => list.filter(row => (!!row.fieldSupportsMultidoc && row.value === val)).length > 0).length > 0);
    }
  };

  return (
    <>
      { list && (
        <FormItem
          name="documentTypeValue"
          rules={[{ required: true, message: 'Please select at least one document category.' }]}
          label="Document type"
          labelCol={12}
        >
          <Select
            mode="multiple"
            showSearch
            placeholder="Please select document type"
            onChange={e => setAllValues(e)}
            options={list}
          />
        </FormItem>
      )}
    </>
  );
};

export default DocumentTypeSelect;
