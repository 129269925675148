const ensureObject = (account) => {
  if (typeof account === 'string') {
    return JSON.parse(account);
  }
  return account;
};

export const nextRepayment = account => ensureObject(account)?.repayments?.account_status?.nnext;

export const platformPaymentType = account => ensureObject(account)?.platform_payment_type;

export const defaultPlatformPaymentType = currentDealer => (currentDealer === 'znz' ? 'capitalise' : '');

export const monthlyRepayments = (account, paymentType) => {
  if (!paymentType) {
    return ensureObject(account)?.monthly_repayment;
  }
  const workingPlatform = ensureObject(account)?.platform.map(p => ({ ...p, type: p.type === 'capitalised' ? 'capitalise' : p.type }));
  return workingPlatform.filter(p => p.type === paymentType)
    ?.pop()
    ?.monthly_repayments;
};

const paymentOptionData = {
  full: {
    calc: (loanData, amt) => amt,
    option: 'Pay all fees with own funds',
  },
  capitalise: {
    calc: (loanData, amt) => parseInt(amt, 10) + loanData?.legal_fee + loanData?.platform_fee,
    option: 'Add all fees to loan',
  },
};
export const paymentOptions = paymentType => paymentOptionData[paymentType];

export const originalLoanAmount = lmsAccount => (
  lmsAccount.platform_payment_type === 'capitalise'
    ? parseInt(lmsAccount.amount, 10) - lmsAccount.legal_fee - lmsAccount.platform_fee
    : lmsAccount.amount
);

export const readableTerm = (termInMonths) => {
  try {
    const yearNumber = Math.floor(termInMonths / 12);
    const monthNumber = termInMonths % 12;
    const term = [];

    if (yearNumber > 0) {
      term.push(`${yearNumber} Year${yearNumber > 1 ? 's' : ''} `);
    }
    if (monthNumber > 0) {
      term.push(`${monthNumber} Month${monthNumber > 1 ? 's' : ''} `);
    }

    return term;
  } catch (error) {
    return '';
  }
};

const backfillData = account => ensureObject(account)?.backfill;

export const backfillReduction = (account) => {
  const data = backfillData(account);
  return (data?.desired_reduction && parseInt(data.desired_reduction, 10)) || 0;
};

export const backfillInvestmentId = account => backfillData(account)?.backfill_account_id;

export default {
  backfillData,
  backfillInvestmentId,
  backfillReduction,
  defaultPlatformPaymentType,
  monthlyRepayments,
  nextRepayment,
  originalLoanAmount,
  platformPaymentType,
  readableTerm,
};
