import { notification } from 'antd';
import { firstKey } from '../Helpers/Object/Object';
import { getGraphQlErrorMessage } from '../Helpers/Error';

const useGraphQlNotifications = () => {
  const notificationFromResponse = (response, successMessage, errorMessage) => {
    const key = firstKey(response?.data);
    const result = response?.data?.[key];
    const success = result?.success;
    notification.open({
      type: success ? 'success' : 'error',
      message: success ? successMessage : errorMessage ?? result?.message,
      duration: 2,
    });
  };

  const notificationFromError = error => notification.error({
    message: getGraphQlErrorMessage(error),
    duration: 2,
  });

  return { notificationFromResponse, notificationFromError };
};

useGraphQlNotifications.propTypes = {

};

export default useGraphQlNotifications;
