import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  bool, func, element, shape, string,
} from 'prop-types';
import { useParams } from 'react-router-dom';
import ButtonGroup from '../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import Button from '../../../Atoms/Forms/Button/Button';
import LangContext from '../../../Context/Lang/LangContext';
import {
  getCurrentStep,
  getAllStepEntities,
  setDraftUuid,
  getDraftUuid,
  getDraftLoading,
  getDraftDeleting,
  setDraftDeleting,
  setDraftLoading,
  getCreateLoading,
} from '../../../Store/clientRegistrationSlice';
import Popconfirm from '../../../Atoms/Forms/Popconfirm/Popconfirm';
import useDraft from '../../../Hooks/useDraft';

const ClientRegistrationActions = ({
  form, onGoBack, customSubmit, canProceed, alternateButtonText,
}) => {
  const { context } = useParams();
  const dispatch = useDispatch();
  const { t } = useContext(LangContext);
  const entities = useSelector(getAllStepEntities);
  const currentStep = useSelector(getCurrentStep);
  const draftUuid = useSelector(getDraftUuid);
  const draftLoading = useSelector(getDraftLoading);
  const draftDeleting = useSelector(getDraftDeleting);
  const createLoading = useSelector(getCreateLoading);
  const isFirstStep = currentStep === 0;

  const {
    onSaveDraft,
    onDeleteDraft,
    updateDraftLoading,
    deleteDraftLoading,
    createDraftLoading,
  } = useDraft('client', draftUuid, context, form, entities, setDraftUuid);

  dispatch(setDraftLoading(createDraftLoading || updateDraftLoading));
  dispatch(setDraftDeleting(deleteDraftLoading));

  return (
    <ButtonGroup direction="bottom-right" className="set-3-style">
      {!isFirstStep && onGoBack && (
        <Button ghost onClick={onGoBack} className="mr-auto">{t('forms.back')}</Button>
      )}
      {draftUuid && (
        <Popconfirm
          title="Are you sure you want to delete this draft?"
          okText="Yes"
          cancelText="No"
          onConfirm={onDeleteDraft}
        >
          <Button
            ghost
            type="secondary"
            Cancel
            loading={draftDeleting}
          >
            {draftDeleting ? 'Please wait' : t('forms.discard_draft')}
          </Button>
        </Popconfirm>
      )}
      <Button ghost type="secondary" loading={draftLoading} onClick={onSaveDraft('client')}>{t('forms.save_draft')}</Button>
      { customSubmit || (
      <Button
        loading={createLoading}
        disabled={!canProceed}
        type={canProceed ? 'secondary' : 'ghost'}
        htmlType="submit"
      >
        {canProceed ? alternateButtonText || t('forms.proceed') : t('forms.can_not_proceed')}
      </Button>
      )}
    </ButtonGroup>
  );
};

ClientRegistrationActions.propTypes = {
  form: shape([]),
  canProceed: bool,
  onGoBack: func,
  customSubmit: element,
  alternateButtonText: string,
};

ClientRegistrationActions.defaultProps = {
  form: {},
  canProceed: true,
  onGoBack: () => {},
  customSubmit: undefined,
  alternateButtonText: '',
};

export default ClientRegistrationActions;
