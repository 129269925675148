import React from 'react';
import PropTypes from 'prop-types';

import FormItem from '../FormItem/FormItem';
import './ButtonGroup.less';

const ButtonGroup = ({ className, direction, children }) => (
  <FormItem
    className={className !== undefined ? `theme-button-group theme-button-group-${direction} ${className}` : `theme-button-group theme-button-group-${direction}`}
  >
    { children }
  </FormItem>
);

ButtonGroup.propTypes = {
  direction: PropTypes.oneOf(['left', 'right', 'center', 'bottom-right', 'bottom-left', 'vertical', 'space-between']),
  children: PropTypes.node,
  className: PropTypes.string,
};

ButtonGroup.defaultProps = {
  direction: null,
  children: null,
  className: undefined,
};

export default ButtonGroup;
