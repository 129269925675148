/* eslint-disable */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @todo switch to methods in useStringFormatter file.
export const snakeToCamel = str => str.replace(/([-_][a-z])/g,
  group => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const ucwords = (str) => {
  return (str + '').replace(/^([a-z])|\s+([a-z])/g, function ($s) {
    return $s.toUpperCase();
  });
};

export const dealName = (loan) => `${loan?.fieldLmsAccountId} ${loan?.fieldPrimarySecurity?.fieldAddress?.locality ?? ''}`;

export const unstrailedUrl = url => url.replace(/\/$/, '');

export const getDataSet = (dataSetKey, localStorageKey = null) => {
  const root = document.getElementById('reactApp');
  if (root) {
    const { [dataSetKey]: targetField } = root.dataset;
    if (targetField) {
      return targetField;
    }
  }

  if (localStorageKey) {
    return localStorage.getItem(localStorageKey);
  }

  return null;
};

const getEntityPermission = (entity, permissionName) => {
  if (
    entity !== null
    && typeof entity === 'object'
    && Object.prototype.hasOwnProperty.call(entity, 'entityPermissions')
  ) {
    const { entityPermissions } = entity;
    const permissions = entityPermissions.filter(item => item.label === permissionName);
    if (permissions.length > 0) {
      return permissions[0].permitted;
    }
  }
  return false;
};

export const haveEntityPermission = (entity, permissionName) => !!getEntityPermission(entity, permissionName);

export const getPermissionDenyMessage = (entity, permissionName) => !!getEntityPermission(entity, permissionName).message;

export const haveEntityPermissionMessage = (entity, permissionName) => Object.prototype.hasOwnProperty.call(getEntityPermission(entity, permissionName), 'message');

export const filterNulls = (node) => {
  if (Array.isArray(node)) {
    const filteredNodes = node.filter(item => (item !== null));
    return filteredNodes.map(item => filterNulls(item));
  }
  if (node instanceof Object) {
    const newNode = node;
    const keys = Object.keys(node);
    let ind = null;
    for (ind = 0; ind < keys.length; ind += 1) {
      newNode[keys[ind]] = filterNulls(newNode[keys[ind]], keys[ind]);
    }
    return newNode;
  }
  return node;
};

export const conditionOr = (source, targets) => (
  targets.some(target => source === target)
);

export const conditionAnd = (source, targets) => (
  targets.every(target => source === target)
);

export const axiosParams = () => (getDataSet('enableDebug', 'enable_debug') === '1'
  ? '?XDEBUG_SESSION_START=PHPSTORM' : '');

export const eventingParams = () => (
  `?_format=json${getDataSet('enableDebug', 'enable_debug') === '1'
    ? '&XDEBUG_SESSION_START=PHPSTORM' : ''}`
);

export const errorMessage = (error) => {
  const contactLink = <a href={`mailto:${process.env.REACT_APP_BRAND_EMAIL}`}>contact us via email</a>;
  return (
    <React.Fragment>
      {error}
      . We seem to be experiencing a server error. Please try again later or
      {' '}
      {contactLink}
    </React.Fragment>
  );
};

export const getContextLoanState = (context, loanState) => {

  if (context === 'dealer') {
    return loanState;
  }

  if (conditionOr(loanState, ['live-complete', 'live-subscribed', 'live'])) {
    return 'live';
  }
  if (conditionOr(loanState, ['closed_pending', 'closed'])) {
    return 'closed';
  }

  if (context === 'investor') {
    if (conditionOr(loanState, ['funding', 'backfill'])) {
      return 'available';
    }
    if (loanState === 'awaiting settlement') {
      return 'Funds received';
    }
  }

  if (conditionOr(context, ['borrower', 'broker', undefined])) {
    if (loanState === 'assessed') {
      return 'offer';
    }

    if (loanState === 'backfill') {
      return 'live';
    }
  }

  return loanState || '';
};

export const getContextInvestmentState = (context, state) => {
  if (context === 'dealer') {
    return state;
  }

  if (conditionOr(state, ['live-complete', 'live-subscribed', 'live'])) {
    return 'live';
  }
  if (conditionOr(state, ['closed_pending', 'closed'])) {
    return 'closed';
  }
  if (context === 'investor' && conditionOr(state, ['funding', 'backfill'])) {
    return 'funding';
  }

  return state || '';
};

export const getImage = (imageEntity, style) => {
  if (imageEntity && imageEntity[style]) {
    const { [style]: { url } } = imageEntity;
    return url;
  }

  return null;
};

export const getHeaderImage = (field, style, imageFieldName = 'fieldImages') => {
  if (field) {
    const fieldImages = field[imageFieldName];
    if (Array.isArray(fieldImages) && fieldImages.length > 0) {
      const [first] = fieldImages;
      return first[style];
    }
  }

  return null;
};

export const convertByteCount = (bytes) => {
  let type = 'B';
  let workingBytes = bytes;

  if (workingBytes > 1024) {
    workingBytes /= 1024;
    type = 'KB';
  }
  if (workingBytes > 1024) {
    workingBytes /= 1024;
    type = 'MB';
  }
  return `${Math.floor(workingBytes * 10) / 10}${type}`;
};

export const getExtension = (filename) => {
  const [, fileExtension] = filename.split(/\.(?=[^.]+$)/);
  if (fileExtension === undefined) {
    return 'pdf';
  }
  return fileExtension.toLowerCase();
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const moneyFormatterParser = () => ({
  formatter: value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: value => value.replace(/\$\s?|(,*)/g, ''),
});
