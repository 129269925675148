import React from 'react';
import { number, shape, string } from 'prop-types';
import moment from 'moment';
import Title from '../../../Atoms/Typography/Title/Title';
import Card from '../../../Molecules/Cards/Card';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import { formatCurrency, formatPercentage } from '../../../Hooks/useNumberFormatter';
import { dealName, ucwords } from '../../../Helpers/Helpers';
import { brandLabelConfig } from '../../../Resources/BrandLabelConfigs';
import { dealer } from '../../../Hooks/useLocalStorage';

const InvestmentCertificateCard = (props) => {
  const { investment } = props;
  let type = 'loan';

  if (investment?.fieldManagedFund) {
    type = 'fund';
  }

  const investmentLoanPrincipal = () => {
    if (!investment) {
      return '';
    }
    const investmentTotal = investment?.fieldAmount ?? 0;
    const loanTotal = investment?.fieldLoan?.fieldAmount ?? 0;
    const percent = loanTotal && ((investmentTotal * 100) / loanTotal);
    return `${formatCurrency(investmentTotal)} (${formatPercentage(percent)} of total)`;
  };

  const investmentFundPrincipal = () => {
    if (!investment) {
      return '';
    }
    const investmentTotal = investment?.fieldAmount ?? 0;
    return `${formatCurrency(investmentTotal)}`;
  };

  const fields = [
    { label: 'Loan Investment', value: type === 'loan' ? dealName(investment?.fieldLoan) : investment?.fieldManagedFund?.fieldLmsPoolId },
    { label: 'Investment Principal', value: type === 'loan' ? investmentLoanPrincipal() : investmentFundPrincipal() },
    { label: 'Commencement Date', value: moment(investment?.commencementDate).format('DD MMMM YYYY') },
    { label: 'Investment ID', value: investment?.fieldLmsAccountId },
    { label: 'Investor Return (p.a.)', value: formatPercentage(type === 'loan' ? investment?.investmentRate : investment?.fieldManagedFund?.fieldTargetRate) },
    { label: 'Term', value: investment?.fieldTerm },
    { label: 'Investment Entity', value: investment?.fieldClient?.name },
  ];

  return (
    <Card title={(<Title level={4}>{ucwords(brandLabelConfig(dealer(), 'reportingInvestmentCertificate', 'Certificate of Investment'))}</Title>)}>
      <Fields fields={fields} />
    </Card>
  );
};

InvestmentCertificateCard.propTypes = {
  investment: shape({
    commencementDate: string,
    fieldLmsAccountId: string,
    fieldClient: shape({
      name: string,
    }),
    fieldLoan: shape({
      fieldAmount: number,
    }),
    fieldPool: shape({
      fieldAmount: number,
      amountFunded: number,
      term: string,
    }),
  }).isRequired,
};

export default InvestmentCertificateCard;
