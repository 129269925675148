import React, { useState } from 'react';
import { Form, Skeleton } from 'antd';
import {
  func, shape, string,
} from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { getGraphQlErrorMessage } from '../../../../Helpers/Error';
import Alert from '../../../../Atoms/Feedback/Alert/Alert';
import FormItem from '../../../../Atoms/Forms/FormItem/FormItem';
import Button from '../../../../Atoms/Forms/Button/Button';
import ButtonGroup from '../../../../Atoms/Forms/ButtonGroup/ButtonGroup';
import RadioGroup from '../../../../Atoms/Forms/RadioGroup/RadioGroup';
import Radio from '../../../../Atoms/Forms/Radio/Radio';
import { ACCOUNT_DOCUMENTS } from '../../../../GraphQL/Queries/Documents';
import { INVESTMENT_ATTACH_IM } from '../../../../GraphQL/Mutations/Account';
import './InvestorMemorandumForm.less';

const InvestorMemorandumForm = ({ investment, onSuccess }) => {
  const [form] = Form.useForm();
  const [message, setMessage] = useState('');
  const { loading: loanImLoading, data: loanIms } = useQuery(ACCOUNT_DOCUMENTS, { variables: { accountUuid: investment.fieldLoan.uuid, categories: ['investor_memorandum'] } });
  const { loading: invImLoading, data: currentIm } = useQuery(ACCOUNT_DOCUMENTS, { variables: { accountUuid: investment.uuid, categories: ['investor_memorandum'] } });
  const [UpdateInvestorMemorandum, { loading }] = useMutation(INVESTMENT_ATTACH_IM);

  const submitForm = (values) => {
    UpdateInvestorMemorandum({
      variables: { uuid: investment.uuid, ...values },
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setMessage(getGraphQlErrorMessage(error));
      });
  };

  if (invImLoading || loanImLoading) {
    return <Skeleton paragraph={2} />;
  }

  const investorIm = (data) => {
    const docs = data?.documents?.results;
    if (docs.length > 0) {
      return docs[0].uuid;
    }
    return null;
  };

  return (
    <Form
      form={form}
      name="investorMemorandum"
      onFinish={submitForm}
      className="theme-loan-edit-form investor-memorandum-form"
    >
      { message && <Alert message={message} type="error" /> }
      <div>
        <FormItem
          name="mediaUuid"
        >
          <RadioGroup
            defaultValue={investorIm(currentIm)}
          >
            {
              loanIms && loanIms?.documents?.results?.map(item => (
                <>
                  <Radio key={`im-check-${item.uuid}`} value={item.uuid}>
                    <div className="filename">{item?.fieldMediaFiles[0]?.filename}</div>
                    <div className="media">{item.label}</div>
                  </Radio>
                </>
              ))
            }
          </RadioGroup>
        </FormItem>
      </div>

      <ButtonGroup direction="bottom-right">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          Save
        </Button>
      </ButtonGroup>
    </Form>
  );
};

InvestorMemorandumForm.propTypes = {
  investment: shape({
    uuid: string.isRequired,
  }).isRequired,
  onSuccess: func,
};

InvestorMemorandumForm.defaultProps = {
  onSuccess: () => {},
};

export default InvestorMemorandumForm;
