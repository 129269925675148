import React, { useState } from 'react';
import {
  Form, Table,
} from 'antd';
import NewLoanToInvestorsForm from './NewLoanToInvestorsForm';
import HeaderTitle from '../../Molecules/HeaderTitle/HeaderTitle';
import { FormSectionTitle } from '../../Components/Form/FormSections';
import clientService from '../../Services/Clients.service';
import Paragraph from '../../Atoms/Typography/Paragraph/Paragraph';

const NewLoanToInvestors = () => {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);
  const [recommendedInvestors, setRecommendedInvestors] = useState([]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      setProcessing(true);
      clientService.newLoanToInvestorsSubmit(values).then(
        (response) => {
          setProcessing(false);
          setRecommendedInvestors(response.data);
        },
        () => {
          setProcessing(false);
        },
      );
    });
  };

  const columns = [
    {
      title: 'Estimated probability',
      dataIndex: 'similarity',
      key: 'similarity',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => { // eslint-disable-line
        const url = `/app/dealer/client/${record.key}`;
        return (<a href={url}>{text}</a>);
      },
    },
    {
      title: 'Type',
      dataIndex: 'entityType',
      key: 'entityType',
    },
  ];

  return (
    <div id="new-loan-to-investors" className="react-form">
      <HeaderTitle
        title="New Loan Prediction"
      >
        <Paragraph>Let the machine do the job.</Paragraph>
      </HeaderTitle>

      <NewLoanToInvestorsForm
        accessValue="internal"
        getFieldDecorator={form}
        {...{ onFormSubmit, processing, form }}
      />

      <FormSectionTitle>Results</FormSectionTitle>
      <Paragraph>Here is a list of investors that are most likely to invest.</Paragraph>
      <Table dataSource={recommendedInvestors} columns={columns} loading={processing} />
    </div>
  );
};

export default NewLoanToInvestors;
