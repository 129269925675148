import React from 'react';
import { Col, Row } from 'antd';
import { shape } from 'prop-types';
import {
  defaultPlatformPaymentType,
  monthlyRepayments as _monthlyRepayments,
  originalLoanAmount as _originalLoanAmount,
  platformPaymentType as _platformPaymentType,
  paymentOptions as _paymentOptions,
} from '../../../Helpers/lmsAccountShortcuts';
import Card from '../../../Molecules/Cards/Card';
import Title from '../../../Atoms/Typography/Title/Title';
import Currency from '../../../Atoms/DataDisplay/Field/Currency';
import Fields from '../../../Molecules/DataDisplay/Fields/Fields';
import './OfferDetails.less';
import { dealer } from '../../../Hooks/useLocalStorage';

const OfferDetails = ({
  lmsAccount,
}) => {
  const platformPaymentType = _platformPaymentType(lmsAccount) || defaultPlatformPaymentType(dealer());
  const originalLoanAmount = _originalLoanAmount(lmsAccount, platformPaymentType);
  const paymentOptions = _paymentOptions(platformPaymentType);
  const monthlyRepayments = _monthlyRepayments(lmsAccount, platformPaymentType);

  const classes = ['theme-offer-option'];

  const offerOptionFields = [
    { label: 'Loan term', value: lmsAccount?.term },
    { label: 'Interest rate', value: `${lmsAccount?.interest_rate}%` },
    { label: 'Loan amount', value: <Currency value={originalLoanAmount} decimals={2} /> },
    { label: 'Platform fee', value: <Currency value={lmsAccount?.platform_fee} decimals={2} /> },
    { label: 'Legal fee', value: <Currency value={lmsAccount?.legal_fee} decimals={2} /> },
    { label: 'Monthly repayments', value: <Currency value={monthlyRepayments} decimals={2} /> },
    { label: 'Total loan', className: 'total-loan', value: <Currency value={paymentOptions.calc(lmsAccount, originalLoanAmount)} decimals={2} /> },
  ];

  return (
    <>
      <Card
        className="theme-offer-details"
        title={(<Title level={4}>Platform fees</Title>)}
      >
        <Row gutter={32} align="top">
          <Col xs={12} md={6}>
            <div
              className={classes.join(' ')}
            >
              <Fields numberOfColumns={1} fields={offerOptionFields} />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

OfferDetails.propTypes = {
  lmsAccount: shape({}).isRequired,
};

export default OfferDetails;
