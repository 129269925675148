/**
@deprecated This file should not be longer implementing new functions or updating the existing ones.
 All functions related should be moved to useDateFormatter hook
*/

import moment from 'moment';

function formatDate(date) {
  let usedDate = date;
  if (typeof usedDate === 'string') {
    usedDate = moment(date, 'YYYY-MM-DD');
  }
  return (usedDate && usedDate.format('DD/MM/YYYY')) || '';
}

export const formatDateTime = (dateTime) => {
  let usedDate = dateTime;
  if (typeof usedDate === 'string') {
    usedDate = moment(dateTime, 'YYYY-MM-DDTHH:NN:SS');
  }
  return (usedDate && usedDate.format('DD/MM/YYYY')) || '';
};

export const formatUnixDate = timestamp => timestamp && formatDate(moment.unix(timestamp));

export const formatUnixInternalDate = timestamp => timestamp && moment.unix(timestamp).format('YYYY-MM-DD');

/**
 * @deprecated Replace with timestampToDateTime function from useDateFormatter hook.
 */
export const formatUnixDateTime = timestamp => timestamp && formatDateTime(moment.unix(timestamp));

export const formatDateAsTimestamp = date => moment(date, 'YYYY-MM-DD').unix();

export const formatFromMoment = date => (date && date.format('YYYY-MM-DD')) || '';

export const formatRelativeDays = (date) => {
  if (!date) {
    return '';
  }
  const diff = moment().diff(moment(date, 'YYYY-MM-DD'), 'days');
  if (diff > 1) {
    return `${diff} days ago`;
  }
  if (diff === 1) {
    return '1 day ago';
  }
  if (diff === -1) {
    return 'in 1 day';
  }
  if (diff < -1) {
    return `in ${diff} days`;
  }
  return 'today';
};

export default formatDate;
