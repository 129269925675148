import React from 'react';
import { string } from 'prop-types';
import Input from '../../../Atoms/Forms/Input/Input';
import FormItem from '../../../Atoms/Forms/FormItem/FormItem';

const EntityType = ({ type, bundle }) => (
  <>
    <FormItem
      noStyle
      shouldUpdate={(previous, next) => previous.type !== next.type}
    >
      { ({ getFieldValue, setFieldValue }) => {
        setFieldValue('entityType', type);
        setFieldValue('entityBundle', bundle);
        return (
          <>
            <FormItem hidden name="entityType" value={getFieldValue('entityType')}>
              <Input />
            </FormItem>
            <FormItem hidden name="entityBundle" value={getFieldValue('entityBundle')}>
              <Input />
            </FormItem>
          </>
        );
      }}
    </FormItem>
  </>
);

EntityType.propTypes = {
  type: string.isRequired,
  bundle: string.isRequired,
};

export default EntityType;
