import { gql } from '@apollo/client';

export const POLICIES = gql`
  query Policies(
    $keys: [PolicyKey]
    $search: String
    $sortBy: PoliciesSortBy
    $offset: Int = 0
    $limit: Int = 25
    $sortDirection: SortDirection
  ) {
    policies(
      keys: $keys
      search: $search
      sortBy: $sortBy
      offset: $offset
      limit: $limit
      sortDirection: $sortDirection
    ) {
      total
      results {
        uuid
        fieldAccepted
        fieldExpiry
        fieldPolicyKey
        fieldPolicyItemKey
        fieldPolicyItemText
        fieldAccount {
          uuid
          fieldLmsAccountId
        }
        fieldClient {
          uuid
          name
        }
        fieldDocument {
          uuid
          label
        }
        owner {
          uuid
          email
        }
      }
    }
  }
`;

export const Policies = {
  POLICIES,
};

export default Policies;
