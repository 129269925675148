import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

const Meta = ({ title, brand }) => (
  <Helmet>
    <title>
      {`${title} | ${brand}`}
    </title>
  </Helmet>
);

Meta.propTypes = {
  title: string.isRequired,
  brand: string,
};

Meta.defaultProps = {
  brand: process.env.REACT_APP_BRAND,
};

export default Meta;
