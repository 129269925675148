import React from 'react';
import {
  string, node, bool, number, oneOfType,
} from 'prop-types';
import { Statistic as AntdStatistic } from 'antd';
import './Statistic.less';

const Statistic = args => <AntdStatistic {...args} className="theme-statistic" />;

Statistic.propTypes = {
  value: oneOfType([string, number]).isRequired,
  decimalSeparator: string,
  formatter: node,
  groupSeparator: string,
  loading: bool,
  precision: number,
  prefix: node,
  suffix: node,
  title: node,
};

Statistic.defaultProps = {
  decimalSeparator: '.',
  formatter: undefined,
  groupSeparator: ',',
  loading: false,
  precision: undefined,
  prefix: undefined,
  suffix: undefined,
  title: undefined,
};

export default Statistic;
